import React from 'react'

export default function RPECard({athlete}) {
const text = athlete?.athlete_name;
let  athleteName = text.split(" ");

let athleteFirstName = athleteName?.[0];
let athleteLastName = '';
athleteName?.map((name,index)=>{
  if(index != 0){
    athleteLastName += ` ${name}`; 
  }
})
    return (
        <li class="players-list__item player-card">
            <div class="player-card__img">
                <img 
                src={`images/${athlete?.athlete_id}.svg`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src="images/user/default_athlete.png";
                }}
                 alt="Athlete Image" />
            </div>
            <div class="player-card__name">{athleteFirstName} <br/> {athleteLastName}</div>
        </li>
    )
}
