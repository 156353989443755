import {createSlice} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
const authInitialstate = {
    isAuthenticated : false,
    userData : {},
    // userData: {
    //     first_name: "John",
    //     last_name: "Doe",
    //     middle_name: null,
    //     status: "1",
    //     user_id: 4,
    //     role_id: 4,
    //     role: "athlete",
    //     user_guid: "96ff0014-c07d-49ae-b8db-60b4707aa5d8"
    // }
    // userData: {
    //     first_name: "Rakesh",
    //     last_name: "Singh",
    //     middle_name: "A",
    //     status: "1",
    //     user_id: 1,
    //     role_id: 1,
    //     user_role: "admin",
    //     user_guid: "96ff0014-b07c-47ae-b8db-60b4707aa5d9"
    // }
    // userData: {
    //     first_name: "Rahul",
    //     last_name: "B",
    //     middle_name: null,
    //     status: "1",
    //     user_id: 2,
    //     role_id: 3,
    //     user_role: "technical coach",
    //     user_guid: "e7157310-28ef-4011-b195-b77fca3a62c2"
    // }
    // userData: {
    //     first_name: "Shubham",
    //     last_name: "Suryawanshi",
    //     middle_name: null,
    //     status: "1",
    //     user_id: 2,
    //     role_id: 2,
    //     user_role: "snc coach",
    //     user_guid: "031411d8-75c2-45ad-9dfd-3ea759ae4e69"
    // }
};

const authSlice = createSlice({
    name: 'auth',
    initialState : authInitialstate,
    reducers :{
        login(state,action){
            let data = {
                first_name: action?.payload?.data?.first_name,
                last_name: action?.payload?.data?.last_name,
                middle_name: action?.payload?.data?.middle_name,
                status: action?.payload?.data?.status,
                user_id: action?.payload?.data?.user_id,
                role_id: action?.payload?.data?.role_id,
                role_name: action?.payload?.data?.role_name,
                user_guid: action?.payload?.data?.user_guid
            }
            state.userData = data;
            state.isAuthenticated = true;  
            if(action?.payload?.callback) action?.payload?.callback();
        },
        logout(state, action) {
            Cookies.remove('userdetails');
            localStorage?.removeItem("guid");
            state.userData = {};
            state.isAuthenticated = false;
            if(action?.payload?.callback) action?.payload?.callback();
        },
        setCookies(state,action){
            var d = new Date;
            d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * action?.payload?.days);
            document.cookie = action?.payload?.name + "=" + action?.payload?.value + ";path=/;expires=" + d.toGMTString();
            state.isAuthenticated = true;
        }
    }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;