import { Chart } from "chart.js";
// import "chartjs-plugin-labels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useSelector } from "react-redux";
import * as Gauge from "chartjs-gauge-v3"; 
import { useEffect, useState } from "react";
import { ATHELETE_ROLE_ID} from "../../../utility/constants";


export default function RpeSpeedoMeterC({ selectedId,selectSaveData, feebackTooltips, isEditable }) {
    const userDetails = useSelector(state => state?.auth?.userData?.role_id);
    useEffect(() => {
        var data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        var value = selectedId ? selectedId - 0.5 : 0;

        var config = {
            type: "gauge",
            data: { 
                labels: ["1 Very Light", "2 Light", "3 Light", "4 Moderate", "5 Moderate", "Moderate 6", "Rigorous 7", "Rigorous 8", "Very Hard 9", "Max Effort 10"],
                datasets: [
                    {
                        label: "Current Appeal Risk",
                        data: data,
                        value: value,
                        minValue: 0,
                        backgroundColor: [
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                        ],
                        borderWidth: 2,
                        datalabels: {
                            rotation: [10, 30, 45, 60, 80, -80, -60, -45, -30, -10]
                        }
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                        generateLabels: {},
                    },
                },
                hover: userDetails !== ATHELETE_ROLE_ID ? {mode:null}  : {mode:'nearest', intersect:true},
                responsive: true,
                layout: {
                    padding: {
                        top:0,
                        bottom: 29,
                    },    
                },
                needle: {
                    radius: "8.3%",
                    width: "8%",
                    length: "43%",
                    color: "rgba(0, 0, 0, 1)",
                },
                valueLabel: {
                    display: false,
                    fontSize: 12,
                    formatter: function (value, context) {
                        return value + "X";
                    },
                },
                plugins: {
                    datalabels: {
                        display: true,
                        formatter: function (value, context) {
                            return context.chart.data.labels[context.dataIndex];
                        },
                        color: ["#1670E2", "#00BDBB", "#00BDBB", "#00B137", "#00B137", "#00B137", "#D48F14", "#D48F14", "#FA7537", "#F43426"]
                    },
                },
            },
        };
      
        if(selectedId) {
            config.data.datasets[0].backgroundColor[selectedId - 1] = feebackTooltips[selectedId]?.color;
            config.options.plugins.datalabels.color[selectedId - 1] = "black";
        };

        // if(!myGauge) {
            console.log(myGauge, "here")
            var ctx = document.getElementById("myChart");
            Chart.register(ChartDataLabels);
            var myGauge = new Chart(ctx, config);

            const clickHandler = (click) => {
                if(isEditable) {
                    const slice = myGauge?.getElementsAtEventForMode(click, 'nearest', { intersect: true }, true);
                    console.log('slice--------', slice)
                    if(slice?.length) {
                        selectSaveData(slice[0].index+1);
                        // myGauge.options.plugins.datalabels.color = ["#2470a0", "#3AF4D5", "#3AF4D5", "#00a03e", "#00a03e", "#00a03e", "#FFE74C", "#FFE74C", "#dd5f32", "#e74700"];
                        myGauge.options.plugins.datalabels.color = ["#1670E2", "#00BDBB", "#00BDBB", "#00B137", "#00B137", "#00B137", "#D48F14", "#D48F14", "#FA7537", "#F43426"]
                        myGauge.data.datasets[slice[0].datasetIndex].backgroundColor = [
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                            "#EAEAEA",
                        ];
                        myGauge.options.plugins.datalabels.color[slice?.[0]?.index] = "black";
                        myGauge.data.datasets[slice[0].datasetIndex].backgroundColor[slice?.[0]?.index] = feebackTooltips[slice?.[0]?.index + 1]?.color;
                        myGauge.data.datasets[slice[0].datasetIndex].value = (slice?.[0]?.index + 1) - 0.5;
                        myGauge.update();
                    };
                };
            };
            ctx.onclick = clickHandler;
        // };

        return () => myGauge.destroy();
    }, []);

    useEffect(() => {

    }, [selectedId]);

    return (
        <div className="meter" >
            <canvas id="myChart"></canvas>
        </div> 
    );
}