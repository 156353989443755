import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../../../utility/api';
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import SubHeader from '../../Common/SubHeader';
import ListingTable from '../../Common/ListingTableMUI';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import EditExercisePopup from '../Common/EditExercise/EditExercisePopup';
import AddExercisePopup from '../Common/AddExercise/AddExercisePopup';
import { toast } from 'react-toastify';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { loaderActions } from '../../../../reducer/loader';
import FixedButton from '../../Common/FixedButton';
import { fetchTechnicalExerciseMaster,fetchSportsList, fetchTechnicalTagsList } from '../../../../utility/technicalTemplateApiActions';
import { technicalTemplatesActions } from '../../../../reducer/technicalTemplates';
import { updateTechnicalExerciseMaster , deleteTechnicalExerciseMaster } from '../../../../utility/technicalExerciseMasterApiActions';
import { ADMIN_ROLE_ID } from '../../../../utility/constants';

export default function ExerciseListing() {
  let filterOptions = {
    is_sport_filter:true,
    is_search_filter:true,
    is_tag_filter:true
}
    const APILib = new API();
    const selectedMenuId = useSelector(state => state?.sideMenu?.selectedMenuId);
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedTag, setSelectedTag] = useState("");
    const [selectedSportIds, setSelectedSportIds] = useState([]);
    const [confirmationPopup, setConfirmationPopup] = useState({isShow: false});
    const [isPopupActive, setIsPopupActive] = useState({status:false,type:''});
    const [exerciseCount ,setExerciseCount] = useState(0);
    const [perPage , setPerPage] = useState(20);
    const [isNewExerciseAdded,setIsNewExerciseAdded] = useState(false);
    const [exerciseToEdit , setExerciseToEdit] = useState('');
    const userDate = useSelector((state)=> state?.auth?.userData)
    const [headerData ,setHeaderData] = useState({
        heading : 'Exercise Library',
        isHeadingEditable :false,
        isbackBtn : false,
        isCount : false,
        isCountEditable : false,
        dataCountHeading : 'Exercises'
    });
    const exerciseListing = useSelector(state => state?.templates?.exerciseMasterList);
    const pagination = useSelector(state => state?.templates?.paginationObject);
    const [isActionActive, setIsActionActive] = useState(Array(exerciseListing?.length)?.fill(false));
    let AddExercisePayload = {
      "opt_type": 1,
      "added_by": 1,
      "exercise_id": 0,
      "exercise_name": "",
      "description": "",
      "sets": 0,
      "sport_id": [],
      "is_active": 1,
      "tempo": 0,
      "metrics_units": [],
      "athlete_notes": "",
      "activity_tags": null,
      "media": []
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      window?.addEventListener("click", handleKeydownEvent);
      return () => window?.removeEventListener("click", handleKeydownEvent);
  }, []);

  const handleKeydownEvent = (event) => {
      setIsActionActive(Array(exerciseListing?.length)?.fill(false));
  };

    const editExerciseHandler = (e, id)=>{
      e?.stopPropagation();
      setExerciseToEdit('');
        console.log("edit exer:",id);
        let exercies = exerciseListing?.find(exercise => exercise?.exercise_id === id) ;
      dispatch(loaderActions?.showLoader());
                  let data = { ...exercies }
                  console.log("edit exercise master: ",data);
                  // delete data["selected_tempo"];
                  dispatch(
                      technicalTemplatesActions?.seletedExercise({
                          type: "master",
                          action: "edit",
                          data,
                          callback: () => setTimeout(() => {
                              dispatch(
                                technicalTemplatesActions?.setEditExerciseId({
                                      exerciseId: id,
                                      callback: () => setTimeout(() => {
                                          dispatch(loaderActions?.hideLoader());
                                          setIsPopupActive({status:true,type:'editExercise'})
                                      })
                                  })
                              );
                          })
                      })
                  )
    }

    const deleteExerciseHandler = (e, id)=>{
        e?.stopPropagation();
        setIsActionActive(false);
        setConfirmationPopup({
          isShow: true,
          isActionBtns: true,
          warning: "Are you sure you want to delete this exercise?",
          warningMsg: "",
          cancelHandler: () => setConfirmationPopup({isShow: false}),
          okHandler: () => {
            setConfirmationPopup({isShow: false});
            deleteExercise(id);
          }
        });
    }

    const deleteExercise = async(id)=>{
      dispatch(loaderActions?.showLoader());
        dispatch(
            deleteTechnicalExerciseMaster({
                id: id,
                callback: () => {
                    setTimeout(() => {
                      dispatch(fetchTechnicalExerciseMaster(
                        {
                          moduleId: selectedMenuId?.moduleId,
                          perPage,
                          pageNo,
                          searchTerm,
                          selectedTag,
                          selectedSportIds,
                            callback: () => setTimeout(() => {
                                dispatch(loaderActions?.hideLoader());
                            })
                        }));
                        toast.success("Exercise deleted successfully.", {toastId: "exercise_delete_success"});
                    });
                }
            })
        );
    }

    const getSports = (sportNames)=>{
      let sportsCount = sportNames?.length;
      let sportName =  sportsCount > 2 ? `${sportNames?.slice(0, 2)?.join(", ")}` : `${sportNames?.slice(0, 2)?.join(", ")}`;
      let otherCount = sportsCount > 2 ? `+${sportsCount - 2}` : "";
      return {
        'sports_name':sportName,
        'other_count':otherCount
      };
    }
    const handleActionHandler = (e,idx)=>{
      e?.stopPropagation();
        setIsActionActive(prevState => {
          let newState = Array(exerciseListing?.length)?.fill(false);
          newState[idx] = !prevState[idx];
          return [ ...newState ];
        });
    }

    const addNewExerciseHandler = (exerciseData)=>{
      exerciseData = {...exerciseData , "opt_type":1};
      dispatch(
        updateTechnicalExerciseMaster({
          exerciseData:exerciseData,
            callback: () => {
                setTimeout(() => {
                  setPageNo(1);
                  getExerciseList();
                  setIsPopupActive({...isPopupActive , status : false});
                  toast.success("Exercise added successfully.");
                });
            }
        })
      )
    }

    const updateExerciseHandler = (exerciseData)=>{
      exerciseData = {...exerciseData , "opt_type":2};
      dispatch(
        updateTechnicalExerciseMaster({
          exerciseData:exerciseData,
            callback: () => {
                setTimeout(() => {
                  dispatch(fetchTechnicalExerciseMaster(
                    {
                      moduleId: selectedMenuId?.moduleId,
                      perPage,
                      pageNo,
                      searchTerm,
                      selectedTag,
                      selectedSportIds,
                        callback: () => setTimeout(() => {
                            dispatch(loaderActions?.hideLoader());
                        })
                    }));
                  setIsPopupActive({...isPopupActive , status : false});
                  toast.success("Exercise updated successfully.", {toastId: "exercise_update_success"});
                });
            }
        })
    );
      
    }

    const getExerciseList = ()=>{
      dispatch(
        fetchTechnicalExerciseMaster({
          moduleId:selectedMenuId?.moduleId,
            perPage,
            pageNo,
            searchTerm,
            selectedTag,
            selectedSportIds,
            callback: () => setTimeout(() => {
              dispatch(loaderActions?.hideLoader());
          })
        })
    );
    }

    useEffect(()=>{
      dispatch(fetchSportsList());
    },[])


    useEffect(() => {
        let timeOut = searchTerm ? 1000 : 0;
        let exerciseListTimeOut = setTimeout(() => {
          if(selectedMenuId?.id){
            dispatch(loaderActions?.showLoader());
            getExerciseList();
          }
        }, timeOut);

        return () => clearTimeout(exerciseListTimeOut);
    }, [selectedMenuId,pageNo, selectedTag,searchTerm,selectedSportIds]);

    useEffect(()=>{
        getTableBody();
        if(exerciseListing) setExerciseCount(exerciseListing.length);
        setIsNewExerciseAdded(false);
    },[exerciseListing]);

    useEffect(() => {
       dispatch(fetchTechnicalTagsList(selectedSportIds));
    }, [selectedSportIds]);

    const tableHeaders = [
      {
          label: "Excercise Name",
          align: "left"
      },
      {
          label: "Sports",
          align: "left"
      },
      {
          label: "Default Sets",
          align: "center"
      },
      {
          label: "Default Reps",
          align: "center"
      },
      // {
      //     label: "Media",
      //     align: "center"
      // },
      {
          label: "",
          align: "right",
          stickyClass: "stickyCell-right" 
      }
  ];
    const getTableBody = ()=>{
      return (
        <TableBody>
            {
                exerciseListing?.length
                ?
                exerciseListing?.map((exercise, idx) => {
                  let sports = getSports(exercise.sport_name);
                  let reps = exercise?.metric_json?.find(obj => obj?.metric_id==9);
                    return (
                        <Fragment key={exercise.exercise_id}>
                            <TableRow>
                                <TableCell align={"left"} sx={{cursor: "pointer", minWidth: 150}}
                                  //  className="stickyCell-left"
                                  onClick={(e) => editExerciseHandler(e, exercise.exercise_id)}
                                 >
                                    {exercise.exercise_name}
                                </TableCell>
                                <TableCell align={"left"} >
                                {sports.sports_name}
                                {
                                  sports.other_count && 
                                  <span className="si-custom-table si-count">{ sports.other_count }</span>
                                }
                                </TableCell>
                                <TableCell align={"center"} >
                                    { exercise.default_sets ? exercise.default_sets : '-'}
                                </TableCell>
                                <TableCell align={"center"} >
                                    { reps?.value ? reps?.value : '-'}
                                </TableCell>
                                {/* <TableCell align={"center"} >
                                  <button type="button" className="si-icon__button si-icon__media"></button>
                                </TableCell> */}
                                <TableCell align={"right"} className="stickyCell-right">
                                    <div className="si-custom-table__cell si-wrap" style={{margin:"auto"}}>
                                        <button 
                                            type="button" 
                                            className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                                            onClick={(e) => handleActionHandler(e, idx)}
                                        ></button>
                                        <div className="si-action-icons">
                                            <button 
                                                type="button" 
                                                className="si-icon-button"
                                                onClick={(e)=>{editExerciseHandler(e, exercise.exercise_id)}}
                                            >
                                                <span className="icon-edit"></span>edit
                                            </button>
                                            {userDate?.role_id == ADMIN_ROLE_ID ? 
                                            <button 
                                                type="button" 
                                                className="si-icon-button"
                                                onClick={(e)=>{deleteExerciseHandler(e, exercise.exercise_id)}}
                                            >
                                                <span className="icon-delete"></span>delete
                                            </button>
                                            :null}
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </Fragment>
                    )
                })
                :
                <TableRow className="nodata-tableRow">
                    <TableCell align={"center"} colSpan={tableHeaders.length} >
                        No data found
                    </TableCell>
                </TableRow>
            }
        </TableBody>
    )
    }
    
    const createBtnClickHandler = (id)=>{
        console.log("Create Exercise btn click");
        setIsPopupActive({status:true,type:'addExercise'});
    }

    return (
        <Fragment>
             <Header headerData={headerData} dataCount={pagination?.total}/>
             <SubHeader 
              filterOptions={filterOptions}
              searchTerm={ searchTerm }
              setSearchTerm={ setSearchTerm }
              setSelectedTag = {setSelectedTag}
              selectedSportIds = {selectedSportIds}
              setSelectedSportIds = {setSelectedSportIds}
              pageNo={ pageNo }
              setPageNo={ setPageNo }
              isBtn= {true}
              btnName="Add Exercise"
              onBtnClick={createBtnClickHandler}
              moduleType={2}
            />
            <div className="si-main-content">
              <ListingTable 
                  tableHeaders={tableHeaders}
                  getTableBody={getTableBody}
                  pageNo={pageNo}
                  setPageNo={setPageNo} 
                  pagination={pagination}
                  perPage={perPage}
              />

              <ConfirmationPopup 
                    isActive={confirmationPopup?.isShow}
                    isActionBtns={confirmationPopup?.isActionBtns}
                    warning={confirmationPopup?.warning}
                    warningMsg={confirmationPopup?.warningMsg}
                    cancelHandler={confirmationPopup?.cancelHandler}
                    okHandler={confirmationPopup?.okHandler}
              />
              {
                isPopupActive.status && isPopupActive.type === 'addExercise' &&
                <AddExercisePopup isActive={isPopupActive.status} cancelAction={() => setIsPopupActive({type:'addExercise' , status : false})} setIsActive={setIsPopupActive} exercisePayload={AddExercisePayload} onSubmitAction={addNewExerciseHandler}/>
              }
              {
                isPopupActive.status && isPopupActive.type === 'editExercise' &&
                <EditExercisePopup
                  isActive={isPopupActive.status} 
                  isEditable={true}
                  cancelAction={() => setIsPopupActive({type:'editExercise' , status : false})}
                  saveAction={updateExerciseHandler} 
                  tabLists={["specs", "notes_media"]}
                  isExerciseMaster={true}
                />
              }
            </div>
            {/* <ListingTable tableHeaders={tableHeaders} getTableBody={getTableBody} listData={exerciseListing} onEdit={editExerciseHandler} onDelete={deleteExerciseHandler}/> */}
            {/* <Footer 
              pagination={pagination} 
              setPageNo={setPageNo} 
              pageNo={pageNo} 
              perPage={perPage} 
              btnclassName={"si-button si-button--red"} 
            /> */}
            <FixedButton btnName="Add Exercise"
                        onBtnClick={createBtnClickHandler} iconClass='add'/>
          </Fragment>
    )
};