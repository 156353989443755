import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setUpdateEventSuccessfulFalse } from "../../../../reducer/tournamentsEvent.reducer";
import { fetchSportsList } from "../../../../utility/templateApiActions";
import { fetchEventType, fetchGenderList, getEventLists } from "../../../../utility/tournamentEventsApiAction";
import FixedButton from "../../Common/FixedButton";
import Header from "../../Common/Header";
import EventListingContent from "./EventListingContent";
import EventListingSubHeader from "./EventListingSubHeader";

function EventListing() {
  const navigate = useNavigate();
  const paginationObject = useSelector((state) => state?.tournamentEvents?.paginationObject);
  const eventListing = useSelector((state) => state?.tournamentEvents?.eventsList);
  const eventTypes = useSelector((state) => state?.tournamentEvents?.eventTypes);
  const genderLists = useSelector((state) => state?.tournamentEvents?.genderList);
  let [selectedSports, setSelectedSports] = useState([]);
  let [selectedGenderIds, setSelectedGenderIds] = useState([]);
  let [selectedEventTypeIds, setSelectedEventTypeIds] = useState([]);
  let [reload, setReload] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const updateLoading = useSelector((state) => state?.tournamentEvents?.updateEventLoading);
  const userData = useSelector((state) => state?.auth?.userData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSportsList());
    dispatch(
      getEventLists({
        user_id: userData?.user_id ? userData.user_id : null,
      })
    );
    dispatch(fetchGenderList());
    dispatch(fetchEventType());
    dispatch(setUpdateEventSuccessfulFalse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reload) {
      setReload(false);
      dispatch(fetchSportsList());
      dispatch(
        getEventLists({
          user_id: userData?.user_id ? userData.user_id : null,
        })
      );
      dispatch(fetchGenderList());
      dispatch(fetchEventType());
      dispatch(setUpdateEventSuccessfulFalse());
    }
  }, [reload, dispatch, userData]);

  useEffect(() => {
    dispatch(
      getEventLists({
        user_id: userData?.user_id ? userData.user_id : null,
        sport_id: selectedSports.length ? selectedSports : null,
        searchText: searchTerm,
        page_no: pageNo,
        gender: selectedGenderIds.length ? selectedGenderIds : null,
        event_type: selectedEventTypeIds.length ? selectedEventTypeIds : null,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSports, selectedGenderIds, selectedEventTypeIds, pageNo, dispatch]);

  useEffect(() => {
    if(searchTerm !== null){
      let EventListTimeOut = setTimeout(() => {
        dispatch(
          getEventLists({
            user_id: userData?.user_id ? userData.user_id : null,
            sport_id: selectedSports.length ? selectedSports : null,
            searchText: searchTerm,
            gender: selectedGenderIds.length ? selectedGenderIds : null,
            event_type: selectedEventTypeIds.length ? selectedEventTypeIds : null,
            page_no: pageNo,
          })
          );
        }, 1000);
        return () => clearTimeout(EventListTimeOut);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, dispatch]);

  return (
    <>
      {updateLoading && <div className="spin"></div>}
      <Header
        headerData={{
          heading: "Events List",
          isHeadingEditable: false,
        }}
      />
      {/* {tournamentListing?.length || selectedSports?.length || searchTerm ?  */}
      <EventListingSubHeader
        selectedSports={selectedSports}
        setSelectedSports={setSelectedSports}
        pageNo={pageNo}
        setPageNo={setPageNo}
        onClick={() => navigate(`new`)}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        genderLists={genderLists}
        eventTypes={eventTypes}
        setSelectedGenderIds={setSelectedGenderIds}
        setSelectedEventTypeIds={setSelectedEventTypeIds}
      />
      {/* : null} */}
      {/* {tournamentListing?.length || selectedSports?.length || searchTerm ?  */}
      <div class="si-main-content">
        <EventListingContent
          pageNo={pageNo}
          setPageNo={setPageNo}
          pagination={paginationObject}
          perPage={20}
          eventListing={eventListing}
          setReload={setReload}
        />
      </div>
      <FixedButton btnName="Add Event" onBtnClick={() => navigate(`new`)} iconClass="add" />
      {/* :
            <div className="si-main-content">
                    <div className="si-firstStep si-align-center">
                        <div className="si-text">
                            <span>Click on “Add Tournament”  to add a new Tournament</span>
                        </div>
                        <button 
                            type="button" 
                            className="si-button si-button--green si-icon--add"
                            onClick={() => navigate(`new`)}
                        >
                            <span className="icon-add"></span>Add Tournament
                        </button>
                    </div>
                </div> */}
      {/* } */}
    </>
  );
}

export default EventListing;
