import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loaderActions } from '../../../../reducer/loader';
import { deleteAthleteFromProgram, fetchAthletesList, fetchProgramAthletesList } from '../../../../utility/programApiActions';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import {TableBody, TableRow, TableCell} from '@mui/material';
import ListingTable from '../../Common/ListingTableMUI';
import ConfirmationPopup from '../../Common/ConfirmationPopup';

function AthleteContent({ pageNo, searchTerm, selectedGroups, setIsFiltered, isPerformance}) {
    const {id} = useParams();
    const programAthleteListing = useSelector(state => state?.programs?.programAthleteListing);
    const [isActionActive, setIsActionActive] = useState(Array(programAthleteListing?.length)?.fill(false));
    const [warningPopup, setWarningPopup] = useState({isShow: false});
    const userDetails = useSelector(state => state?.auth?.userData);
    const activeTab = useSelector(state => state?.programs?.activeTab);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window?.addEventListener("click", handleKeydownEvent);

        return () => window?.removeEventListener("click", handleKeydownEvent);
    }, []);

    useEffect(() => {
        setIsActionActive(Array(programAthleteListing?.length)?.fill(false));
    }, [programAthleteListing]);

    const handleKeydownEvent = (event) => {
        setIsActionActive(Array(programAthleteListing?.length)?.fill(false));
    };

    const handleAction = (e, idx) => {
        e?.stopPropagation();
        setIsActionActive(prevState => {
            let newState = Array(programAthleteListing?.length)?.fill(false);
            newState[idx] = !prevState[idx];
            return [ ...newState ];
        });
    };

    const handleDelete = () => {
        dispatch(loaderActions?.showLoader());
        dispatch(
            deleteAthleteFromProgram({
                athleteId: warningPopup?.athleteId,
                programId: id,
                callback: () => {
                    setTimeout(() => {
                        dispatch(
                            fetchProgramAthletesList({
                                pageNo,
                                searchTerm,
                                // selectedSports,
                                // userId:userDetails.user_id,
                                userguid:userDetails.user_guid,
                                // userId:1,
                                programId: id,
                                groupFilter: selectedGroups,
                                callback: () => {
                                    setTimeout(() => {
                                        dispatch(loaderActions?.hideLoader());
                                        setIsFiltered(false);
                                        setWarningPopup({isShow: false});
                                        toast.success("Athlete removed successfully.", {toastId: "athlete_remove_success"});
                                    });
                                }
                            })
                        );
                    });
                }
            })
        );
    };

    const handlePopupCancel = () => {
        setWarningPopup({isShow: false});
        setIsActionActive(Array(programAthleteListing?.length)?.fill(false));
    };

    const tableHeaders = 
                            activeTab===3 ? 
                            [
                                {
                                    label: "Athlete Name",
                                    align: "left"
                                },
                                {
                                    label: "Sport",
                                    align: "left"
                                },
                                {
                                    label: "Group",
                                    align: "center"
                                },
                                {
                                    label: "Overall Performance",
                                    align: "center"
                                },
                                {
                                    label: "",
                                    align: "center",
                                    stickyClass: "stickyCell-right" 
                                }
                            ] 
                            :
                            [
                                {
                                    label: "Athlete Name",
                                    align: "left"
                                },
                                {
                                    label: "Sport",
                                    align: "left"
                                },
                                {
                                    label: "Group",
                                    align: "center"
                                },
                                {
                                    label: "Overall Performance",
                                    align: "center"
                                },
                                {
                                    label: "",
                                    align: "right"
                                },
                                {
                                    label: "",
                                    align: "center",
                                    stickyClass: "stickyCell-right"
                                }
                            ];

    const getTableBody = () => {
        return (
            <TableBody sx={{backgroundColor:"#181818"}}>
                {
                    programAthleteListing?.filter(obj => obj?.is_selected)?.length
                    ?
                    programAthleteListing?.filter(obj => obj?.is_selected)?.map((athlete, idx) => {
                        let name = `${athlete?.first_name ? athlete?.first_name : ""} ${athlete?.last_name ? athlete?.last_name : ""}`;
                        let sports = athlete?.sport_name;
                        let sportsCount = sports?.length;
                        let sportName = sportsCount > 2 ? `${sports?.slice(0, 2)?.join(", ")}` : sports?.join(", ");
                        let otherCount = sportsCount > 2 ? `+${sportsCount - 2}` : "";
                        let group = athlete?.group_name;
                        let groupCount = group?.length;
                        let groupName = groupCount > 2 ? `${group?.slice(0, 2)?.join(", ")}` : group?.join(", ");
                        let groupOtherCount = groupCount > 2 ? `+${groupCount - 2}` : "";
                        return (
                            <TableRow key={uuidv4()}>
                                <TableCell 
                                    align={"left"} 
                                    sx={{color:"#fff", cursor: "pointer"}}
                                    onClick={() => navigate(`/snc/programs/${id}/calendar/progressCalendar/${athlete?.athlete_id}`,{ state: { backUrl: '-1', currentUrl:"completedprograms" } })}
                                >
                                    {name ? name : ""}
                                </TableCell>
                                <TableCell align={"left"} sx={{color: "#fff"}}>
                                    { sportName ? sportName : "-" }
                                    {
                                        otherCount && 
                                        <span className="si-custom-table si-count">{ otherCount }</span>
                                    }
                                </TableCell>
                                <TableCell align={"center"} sx={{color: "#fff"}}>
                                    { groupName ? groupName : "-" }
                                    {
                                        groupOtherCount && 
                                        <span className="si-custom-table si-count">{ groupOtherCount }</span>
                                    }
                                </TableCell>
                                <TableCell align={"center"} sx={{color: "#fff"}}>
                                    {athlete?.overall_performance ? athlete?.overall_performance + "%" : "0%"}
                                </TableCell>
                                <TableCell align={activeTab !== 3 ? "right" : "center"} sx={{color: "#fff"}}
                                className={activeTab !== 3 ? "": "stickyCell-right"}
                                >
                                    <button 
                                        type="button" 
                                        className="si-viewBtn" 
                                        onClick={() => navigate(`/snc/programs/${id}/calendar/progressCalendar/${athlete?.athlete_id}`, { state: { backUrl: '-1',currentUrl:"completedprograms"  } })}
                                        // disabled={(!athlete?.overall_performance || athlete?.overall_performance <= 0) ? true : false}
                                    >
                                        <span className="si-icon__view"></span>
                                        <span className="si-txt">View Performance</span>
                                    </button>
                                </TableCell>
                                {
                                    activeTab!==3 && 
                                    <TableCell 
                                        align={"center"} 
                                        sx={{color: "#fff"}}
                                        className="stickyCell-right"
                                    >
                                        <div className="si-custom-table__cell si-wrap" style={{margin:"auto"}}>
                                            <button 
                                                type="button" 
                                                className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                                                onClick={(e) => handleAction(e, idx)}
                                            ></button>
                                            <div className="si-action-icons">
                                                <button 
                                                    type="button" 
                                                    className="si-icon-button"
                                                    onClick={() => setWarningPopup(({isShow: true, athleteId: athlete?.athlete_id}))}
                                                >
                                                    <span className="icon-delete"></span>Remove
                                                </button>
                                            </div>
                                        </div>
                                    </TableCell>
                                }
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={tableHeaders.length} sx={{color: "#fff"}}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };


    return (
        <div className="si-main-content atheletes-tab--main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
            />

            <ConfirmationPopup 
                isActive={warningPopup?.isShow}
                isActionBtns={true}
                warning={"Are you sure you want to remove this athlete?"}
                okHandler={handleDelete}
                cancelHandler={handlePopupCancel}
            />
        </div>
    );
};

export default memo(AthleteContent);