import React, { useEffect, useLayoutEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  resetSportsList,
  setPerformanceUpdateEventSuccessfulFalse,
  setRecordUpdatedFalse,
} from "../../../../reducer/tournamentsPerformance.reducer";
import {
  ADMIN_ROLE_ID,
  TOURNAMENT_MODULE_ROUTE,
  TOURNAMENT_PERFORMANECE_LISTING_ROUTE,
} from "../../../../utility/constants";
import { fetchSportsList } from "../../../../utility/templateApiActions";
import {
  deleteTournamentPerformanceEvents,
  fetchTournamentEventDetails,
  fetchTournamentSportsList,
  getEventAthleteData,
  getTournamentDetailsById,
  getTournamentPerformanceEvents,
  updateAthleteRecordApi,
} from "../../../../utility/tournamentPerformanceApiAction";
import ConfirmationPopup from "../../Common/ConfirmationPopup";
import Dropdown from "../../Common/Dropdown";
import Header from "../../Common/Header";
import AddAthletePopup from "./AddAthletePopup";
import AddEventPopup from "./AddEventPopup";
import AthletePerformanceListing from "./AthletePerformanceListing";

function AddNewRecord() {
  let selectedTournament = useSelector((state) => state?.tournamentPerformance?.selectedTournament);
  let mappedEvents = useSelector((state) => state?.tournamentPerformance?.mappedEvents);
  const performanceRecordDetails = useSelector((state) => state?.tournamentPerformance?.performanceRecordDetails);
  const athleteEventRecordDetailsRedux = useSelector((state) => state?.tournamentPerformance?.eventAthleteData);
  const loading = useSelector((state) => state?.tournamentPerformance?.loading);
  const recordUpdated = useSelector((state) => state?.tournamentPerformance?.recordUpdated);
  const sportList = useSelector((state) => state?.tournamentPerformance?.sportsList);
  let [athleteEventRecordDetails, setAthleteEventRecordDetails] = useState(null);
  let [selectedSports, setSelectedSports] = useState();
  let [selectedEvent, setSelectedEvent] = useState(null);
  let [showEventPopup, setShowEventPopup] = useState(false);
  let [showAthletePopup, setShowAthletePopup] = useState(false);
  let [showDeletePopup, setShowDeletePopup] = useState({ show: false });
  let [showEditPopup, setShowEditPopup] = useState({ show: false, callback: null });
  let [updateAthleteRecord, setUpdateAthleteRecord] = useState(null);
  let [viewMode, setViewMode] = useState(true);
  let [viewModeOnly, setViewModeOnly] = useState(false);
  let { performanceId, sportsId, eventId } = useParams();
  let [athleteRecordDetails, setAthleteDetails] = useState([]);
  let [showList, setShowList] = useState(false);
  let [headerClass, setHeaderClass] = useState(false)
  let [showEditDeleteBtn, setShowEditDeleteBtn] = useState({ editBtn: false, deleteBtn: false, isBtnActive: false });
  const searchRef = useRef(null);
  const userData = useSelector((state) => state?.auth?.userData);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let { pathname } = useLocation();

  // useEffect(() => {
  //   if (!selectedTournament) {
  //     console.log("hit------", selectedTournament);
  //     // dispatch(getTournamentDetailsById({ id: null }));
  //   }
  // }, [dispatch, selectedTournament]);

  useEffect(() => {
    window?.addEventListener("click", handleKeydownEvent);
    return () => window?.removeEventListener("click", handleKeydownEvent);
  }, []);

  const handleKeydownEvent = () => {
    setShowEditDeleteBtn({ isBtnActive: false });
  };

  const isAdmin = () => {
    return Number(userData?.role_id) === ADMIN_ROLE_ID ? true : false;
  };

  useEffect(() => {
    let isAthlete = pathname?.split("/")[1] === "athleteprofile" ? true : false;
    if (isAthlete) {
      setViewModeOnly(true);
    }
  }, [pathname]);
  useEffect(() => {
    if(performanceId){
      dispatch(fetchTournamentSportsList({performance_record_id:performanceId}));
      setSelectedSports(null)
    }
    // dispatch(fetchTournamentEventDetails({ id: performanceId }));
    dispatch(setPerformanceUpdateEventSuccessfulFalse());
    return ()=>{
      setSelectedSports(null)
      dispatch(resetSportsList())
    }
  }, [dispatch, performanceId]);

  useEffect(() => {
    if (recordUpdated) {
      setViewMode(true);
      setRecordUpdatedFalse();
    }
  }, [recordUpdated]);

  //   select first sport in the list
  useEffect(() => {
    if (sportsId && !selectedSports?.value && sportList?.length) {
      let getSport = sportList?.filter((sports) => sports.sport_id == sportsId);
      if (getSport.length) {
        getSport = getSport[0];
      } else {
        getSport = sportList[0];
      }
      setSelectedSports({
        value: getSport?.sport_id,
        label: getSport?.sport_name,
      });
    } else if (!selectedSports?.value && sportList?.length) {
      setSelectedSports({
        value: sportList[0]?.sport_id,
        label: sportList[0]?.sport_name,
      });
    }
  }, [sportList, selectedSports, sportsId, performanceId]);

  // get events if sports changed
  useEffect(() => {
    if (selectedSports?.value && userData?.user_guid) {
      dispatch(
        getTournamentPerformanceEvents({
          performance_record_id: performanceId,
          sport_id: selectedSports?.value,
          user_guid: userData?.user_guid ? userData?.user_guid :null
        })
      );
      setSelectedEvent(null);
    }
  }, [selectedSports, dispatch, performanceId, userData]);

  // select first event if none selected
  useEffect(() => {
    if (mappedEvents?.length && !selectedEvent) {
      if (eventId) {
        let exists = mappedEvents.filter((event) => Number(event.event_id) === Number(eventId));
        if (exists.length) {
          setSelectedEvent(exists[0]);
        } else {
          setSelectedEvent(mappedEvents[0]);
        }
      } else {
        setSelectedEvent(mappedEvents[0]);
      }
    } else if (mappedEvents?.length && selectedEvent) {
      let exists = mappedEvents.filter((event) => Number(event.event_id) === Number(selectedEvent.event_id));
      if (!exists.length) {
        setSelectedEvent(mappedEvents[0]);
      }
    } else if (!mappedEvents) {
      setSelectedEvent(null);
    }
  }, [mappedEvents, selectedEvent, eventId]);

  useEffect(() => {
    if (selectedEvent?.sport_id) {
      let sportId = selectedEvent?.sport_id;
      let selectedEventAthletesRecord = performanceRecordDetails?.[sportId]?.filter(
        (events) => events.event_id === selectedEvent.event_id
      );
      setAthleteDetails(selectedEventAthletesRecord);
    }
  }, [performanceRecordDetails, selectedEvent]);

  useEffect(() => {
    if (userData && userData?.user_guid) {
      let payload = {
        page_no: 1,
        item_number: 20,
        performance_record_id: Number(performanceId),
        event_id: selectedEvent?.event_id,
        sport_id: selectedEvent?.sport_id,
        user_guid: userData?.user_guid,
      };
      dispatch(getEventAthleteData({ payload }));
    }
  }, [dispatch, performanceId, selectedEvent, userData]);

  useEffect(() => {
    if (athleteEventRecordDetailsRedux) {
      setAthleteEventRecordDetails(athleteEventRecordDetailsRedux);
    }
  }, [athleteEventRecordDetailsRedux]);
  const handleSportsChange = ({ event }) => {
    if (event.value) {
      setSelectedSports({
        ...event,
      });
      setViewMode(true);
    }
  };

  const handleEventPopup = (show) => {
    if (selectedSports?.value) {
      setShowEventPopup(show);
    }
  };

  const changeSelectedEvent = ({ event }) => {
    if (event) {
      setSelectedEvent(event);
      setViewMode(true);
    }
  };

  const handleShowDeletePopup = ({ show }) => {
    setShowDeletePopup({
      show: show ? true : false,
    });
  };

  const handleEventDelete = () => {
    let payload = {
      opt_type: 2,
      // tournament_id: Number(tournamentId),
      event_id: [String(selectedEvent?.event_id)],
      sport_id: selectedSports?.value,
      te_map_id: selectedEvent?.te_map_id,
      performance_record_id: Number(performanceId),
      user_guid: userData?.user_guid,
    };
    dispatch(deleteTournamentPerformanceEvents({ payload, id: performanceId, sport_id: selectedSports?.value, user_guid:userData?.user_guid }));
    setShowList(false);
    setViewMode(true);
    setShowDeletePopup(false);
  };

  const handleAthletePopup = ({ show }) => {
    setShowAthletePopup(show);
  };

  const handleDiscardChanges = () => {
    setShowEditPopup((old) => {
      if (old.callback) {
        old.callback();
      }
      return { show: false, callback: null };
    });
    setViewMode(true);
  };
  const handleUpdateValue = ({ updateAthleteRecord, selectedEvent, selectedSports }) => {
    let payload = updateAthleteRecord
      ? Object.keys(updateAthleteRecord)?.map((id) => ({
          ...updateAthleteRecord[id],
          user_guid: userData?.user_guid,
        }))
      : [];

    if (!payload.length) {
      setViewMode(true);
      setRecordUpdatedFalse();
    } else {
      dispatch(
        updateAthleteRecordApi({
          payload,
          id: performanceId,
          sport_id: selectedSports?.value,
          event_id: selectedEvent?.event_id,
          // tournament_id: tournamentId,
        })
      );
    }
  };

  const handleBackBtn = () => {
    if (viewModeOnly) {
      navigate(-1);
    } else if (!viewMode) {
      setShowEditPopup({
        show: true,
        callback: () => {
          navigate(`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_PERFORMANECE_LISTING_ROUTE}`);
        },
      });
    } else {
      navigate(`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_PERFORMANECE_LISTING_ROUTE}`);
    }
  };

  const handleAthleteSearch = (event) => {
    let searchTerm = event.target.value;
    let athleteDataList = athleteEventRecordDetailsRedux?.data?.length
      ? athleteEventRecordDetailsRedux?.data
          ?.filter((athleteRecord) => (!viewModeOnly && !viewMode ? Boolean(athleteRecord?.is_selected === 1) : true))
          .filter((athlete) => {
            return !String(athlete?.athlete_name).toLocaleLowerCase().search(String(searchTerm).toLocaleLowerCase());
          })
      : [];
    setAthleteEventRecordDetails((preState) => ({
      ...preState,
      data: athleteDataList,
    }));
  };

  useLayoutEffect(()=>{
    if(selectedTournament?.name){
      setTimeout(()=>{
        let element = document.querySelectorAll(".si-headwrp .si-headcover .si-Title")
        if(element?.length){
          let height = element[0].clientHeight 
          if(height> 30){
            setHeaderClass("height-65")
          } else {
            setHeaderClass(false)
          }
        }
      })
  }
    return ()=> setHeaderClass(false)
  },[selectedTournament?.name])

  return (
    <>
      {loading && <div className="spin"></div>}
      <Header
        headerData={{
          heading: selectedTournament?.name ? selectedTournament?.name : "",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => handleBackBtn(),
          headerClass:headerClass ? headerClass : false
        }}
      />
      <div className="si-main-content si-main-tournament si-main-testAss">
        <section className="tournament-subheader">
          <div className="tournament-subheader__date">
            <span className="date-icon">
              <img src={process.env.PUBLIC_URL + "/images/icons/calendar-icon.svg"} alt="calendar icon" />
            </span>
            <span className="date-text">Start Date:</span>
            <span className="date-digit">
              {selectedTournament?.start_date
                ? selectedTournament?.start_date.split("T")[0]?.split("-").reverse().join("/")
                : ""}
            </span>
          </div>
          <div className="tournament-subheader__location">
            <span className="location-icon">
              <img src={process.env.PUBLIC_URL + "/images/icons/location-icon.svg"} alt="location icon" />
            </span>
            <span className="location-text">Location:</span>
            <span className="location-name">{selectedTournament?.location ? selectedTournament?.location : ""}</span>
          </div>
        </section>
        <section className="tournament-body">
          <div className="tournament-body__left">
            <div className="tournament-body__left-header">
              {viewModeOnly ? (
                <span className="si-selected">{selectedSports?.label}</span>
              ) : (
                <>
                  <div className="si-select">
                    <Dropdown
                      value={{
                        label: selectedSports?.label ? selectedSports?.label : "Select Sports",
                        value: selectedSports?.value ? selectedSports?.value : "",
                      }}
                      options={sportList ?  sportList?.map((obj) => {
                        return {
                          value: obj?.sport_id,
                          label: obj?.sport_name,
                        };
                      }) :[]}
                      changeHandler={(data) => {
                        if (!viewMode) {
                          setShowEditPopup({
                            show: true,
                            callback: () => {
                              handleSportsChange({ event: { value: data.value, label: data.label }, key: "sportsObj" });
                            },
                          });
                        } else {
                          handleSportsChange({ event: { value: data.value, label: data.label }, key: "sportsObj" });
                        }
                      }}
                    />
                  </div>
                  {isAdmin() ? (
                    <button
                      type="button"
                      className="si-button si-add add-new-report"
                      onClick={() => {
                        if (!viewMode) {
                          setShowEditPopup({
                            show: true,
                            callback: () => {
                              handleEventPopup(true);
                            },
                          });
                        } else {
                          handleEventPopup(true);
                        }
                      }}
                    >
                      <span className="icon-add"></span>Add event
                    </button>
                  ) : null}
                </>
              )}
            </div>
            <div className="tournament-body__left-body">
              {mappedEvents ? (
                mappedEvents?.map((events) => {
                  return (
                    <div
                      key={events?.event_id}
                      className={
                        selectedEvent?.event_id === events?.event_id
                          ? "left-tournament-name active-tournament"
                          : "left-tournament-name"
                      }
                      onClick={() => {
                        if (!viewMode) {
                          setShowEditPopup({
                            show: true,
                            callback: () => {
                              changeSelectedEvent({ event: events });
                              setShowList(true);
                            },
                          });
                        } else {
                          changeSelectedEvent({ event: events });
                          setShowList(true);
                        }
                      }}
                    >
                      {events.event_name}
                    </div>
                  );
                })
              ) : (
                <div className="empty-contain-wrapper-left">
                  <span>
                    {isAdmin() ? "Click on add event to add events of the tournament" : "Events will be displayed here"}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div
            className={showList ? "tournament-body__right tournament-table-active" : "tournament-body__right"}
            id="tournament-table-data"
          >
            <div
              className="empty-contain-wrapper tournament-empty-container"
              style={{ display: selectedEvent ? "none" : "" }}
            >
              <div className="nodata-img-wrp ">
                <img src={process.env.PUBLIC_URL + "/images/tournaments-nodata-img.svg"} alt="no data found img" />
              </div>
              <span>Event score will be displayed here</span>
            </div>
            {selectedEvent && (
              <>
                <div className="tournament-body__right-header">
                  <div className="header-inner-wrp">
                    <span className="right-heading">{selectedEvent?.event_name}</span>
                    {/* <span
                      className="icon-close"
                      id="closeTableData"
                      onClick={() => {
                        if (!viewMode) {
                          setShowEditPopup({
                            show: true,
                            callback: () => {
                              setShowList(false);
                            },
                          });
                        } else {
                          setShowList(false);
                        }
                      }}
                    ></span> */}
                  </div>
                  <div className="add-action-btn-wrp">
                    {isAdmin() ? (
                      !viewModeOnly ? (
                        viewMode && athleteEventRecordDetails ? (
                          <>
                            <div className="si-custom-table__cell si-wrap">
                              <button
                                type="button"
                                className={`si-icon__button icon-options ${
                                  showEditDeleteBtn.isBtnActive ? "active" : ""
                                }`}
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  setShowEditDeleteBtn({ isBtnActive: true, editBtn: true, deleteBtn: true });
                                }}
                              ></button>
                              <div className="si-action-icons">
                                {showEditDeleteBtn.isBtnActive && showEditDeleteBtn.editBtn ? (
                                  <button
                                    type="button"
                                    className="si-icon-button"
                                    onClick={() => setViewMode((old) => !old)}
                                  >
                                    <span className="icon-edit"></span>edit
                                  </button>
                                ) : null}
                                {showEditDeleteBtn.isBtnActive && showEditDeleteBtn.deleteBtn ? (
                                  <button
                                    type="button"
                                    className="si-icon-button"
                                    onClick={() => handleShowDeletePopup({ show: true })}
                                  >
                                    <span className="icon-delete"></span>delete
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* <button
                              type="button"
                              className="si-button si-add add-new-report"
                              onClick={() => handleAthletePopup({ show: true })}
                            >
                              <span className="icon-add"></span>Add Athletes
                            </button> */}
                            <div className="si-custom-table__cell si-wrap">
                              <button
                                type="button"
                                className={`si-icon__button icon-options ${
                                  showEditDeleteBtn.isBtnActive ? "active" : ""
                                }`}
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  setShowEditDeleteBtn({ isBtnActive: true, editBtn: false, deleteBtn: true });
                                }}
                              ></button>
                              <div className="si-action-icons">
                                {showEditDeleteBtn.isBtnActive && showEditDeleteBtn.editBtn ? (
                                  <button
                                    type="button"
                                    className="si-icon-button"
                                    onClick={() => setViewMode((old) => !old)}
                                  >
                                    <span className="icon-edit"></span>edit
                                  </button>
                                ) : null}
                                {showEditDeleteBtn.isBtnActive && showEditDeleteBtn.deleteBtn ? (
                                  <button
                                    type="button"
                                    className="si-icon-button"
                                    onClick={() => handleShowDeletePopup({ show: true })}
                                  >
                                    <span className="icon-delete"></span>delete
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </>
                        )
                      ) : null
                    ) : null}
                  </div>
                </div>
                <div className="tournament-body__right-subheader">
                  <div className="si-subHeader ">
                    <div
                      className="form-control si-searchbox"
                      onClick={(e) => {
                        if (searchRef.current) {
                          searchRef.current.className = "si-input animateIn";
                          searchRef.current.focus();
                        }
                      }}
                    >
                      <input
                        ref={searchRef}
                        type="text"
                        placeholder="Search"
                        className={`si-input ${!viewModeOnly && !viewMode ? "" : "animateIn"}`}
                        onBlur={(e) => {
                          if (searchRef.current) {
                            searchRef.current.value = "";
                            if (!viewModeOnly && !viewMode) {
                              searchRef.current.className = "si-input";
                            }
                          }
                        }}
                        onChange={(e) => handleAthleteSearch(e)}
                      />
                      <span className="si-icon icon-search"></span>
                    </div>
                  </div>
                  {isAdmin() ? (
                    !viewModeOnly ? (
                      viewMode && athleteEventRecordDetails ? null : (
                        <button
                          type="button"
                          className="si-button si-add add-new-report"
                          onClick={() => handleAthletePopup({ show: true })}
                        >
                          <span className="icon-add"></span>Add Athletes
                        </button>
                      )
                    ) : null
                  ) : null}
                </div>
                <div className="table-body__wrapper">
                  <AthletePerformanceListing
                    viewMode={viewMode}
                    updateAthleteRecord={updateAthleteRecord}
                    setUpdateAthleteRecord={setUpdateAthleteRecord}
                    setViewMode={setViewMode}
                    athleteRecordDetails={athleteRecordDetails}
                    viewModeOnly={viewModeOnly}
                    selectedSports={selectedSports}
                    selectedEvent={selectedEvent}
                    athleteEventRecordDetails={athleteEventRecordDetails}
                  />
                  {/* {!viewModeOnly ? ( */}
                    <div className="tournament-body__right-footer">
                      {/* <div
                        className="footer-left"
                        onClick={() => handleShowDeletePopup({ show: true })}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="footer-left__delete-icon">
                          <img src="../../images/icons/delete-icon.svg" alt="" />
                        </span>
                        <span className="footer-left__text">Delete Event</span>
                      </div> */}
                      <div className="footer-left">
                        {!viewModeOnly && !viewMode ? (
                          <span className="footer-left__text">
                            You are editing the scores of athletes who are currently enrolled in your academy.
                          </span>
                        ) : (
                          <span className="footer-left__text">
                            <span className="icon-academic-player"></span> Indicates athletes present in your academy.
                          </span>
                        )}
                      </div>
                      <div className="footer-right form-control popup-btn-wrp">
                        {!viewMode ? (
                          <>
                            <button
                              type="button"
                              className="si-button si-icon--add si-clearbtn"
                              onClick={() =>
                                setShowEditPopup({
                                  show: true,
                                  callback: () => {
                                    // setShowList(false);
                                  },
                                })
                              }
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="si-button si-button--green si-icon--add"
                              onClick={() => {
                                handleUpdateValue({ updateAthleteRecord, selectedEvent, selectedSports });
                              }}
                            >
                              SAVE
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            className="close-btn"
                            onClick={() => {
                              if (!viewMode) {
                                setShowEditPopup({
                                  show: true,
                                  callback: () => {
                                    setShowList(false);
                                  },
                                });
                              } else {
                                setShowList(false);
                              }
                            }}
                          >
                            close
                            {console.log('viewModeOnly, viewMode', viewModeOnly, viewMode)}
                          </button>
                        )}
                      </div>
                    </div>
                  {/* ) : null} */}
                </div>
              </>
            )}
          </div>
        </section>
      </div>

      {showEventPopup ? (
        <AddEventPopup
          closePopup={handleEventPopup}
          selectedSports={selectedSports}
          changeSelectedEvent={changeSelectedEvent}
        />
      ) : null}
      {showAthletePopup ? (
        <AddAthletePopup
          closePopup={handleAthletePopup}
          selectedSports={selectedSports}
          selectedEvent={selectedEvent}
          setViewMode={setViewMode}
        />
      ) : null}
      <ConfirmationPopup
        isActive={showDeletePopup?.show}
        isActionBtns={true}
        warning={"Are you sure you want to delete this event?"}
        okHandler={handleEventDelete}
        cancelHandler={handleShowDeletePopup}
      />
      <ConfirmationPopup
        isActive={showEditPopup?.show}
        isActionBtns={true}
        warning={"Are you sure you want to discard this changes?"}
        okHandler={handleDiscardChanges}
        cancelHandler={() => setShowEditPopup({ show: false, callback: null })}
      />
    </>
  );
}

export default AddNewRecord;
