import React, { useState, useMemo, memo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SubMenu from './SubMenu';
import { ATHLETES_MODULE_ID, HOME_MODULE_ID, SNC_MODULE_ID, TECHNICAL_MODULE_ID, TEST_AND_ASSESSMENT_MODULE_ID, PROFIE_MODULE_ID, TOURNAMENT_MODULE_ROUTE, TOURNAMENT_MODULE_ID, INJURY_MODULE_ROUTE, INJURY_MODULE_ID, PSYCHOLOGY_MODULE_ROUTE, PSYCHOLOGY_MODULE_ID, ATHLETE_MODULE_ROUTE, NUTRITION_MODULE_ROUTE, NUTRITION_MODULE_ID } from '../../utility/constants';
import { useDispatch } from 'react-redux';
import { templatesActions } from '../../reducer/templates';

function MainMenu({ menuObj, activeMenu, setActiveMenu, index, userGuid }) {
    const memoizedMenuObj = useMemo(() => menuObj, []);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeMenu && activeMenu.isActive) return
        let routeMenu = location?.pathname?.split("/")?.[1]?.toLowerCase();
        // let navigateTo = !routeMenu ? getNavigation(subMenuObj) : location?.href?.split("#")?.[1];
        let defaultModuleId;
        if (!routeMenu) defaultModuleId = HOME_MODULE_ID;
        else if (routeMenu == "profile") defaultModuleId = PROFIE_MODULE_ID;
        else if (routeMenu == "snc") defaultModuleId = SNC_MODULE_ID;
        else if (routeMenu == "technical") defaultModuleId = TECHNICAL_MODULE_ID;
        else if (routeMenu == "athletes") defaultModuleId = ATHLETES_MODULE_ID;
        else if (routeMenu == "testfunctions") defaultModuleId = TEST_AND_ASSESSMENT_MODULE_ID;
        else if (routeMenu == TOURNAMENT_MODULE_ROUTE) defaultModuleId = TOURNAMENT_MODULE_ID;
        else if (routeMenu == INJURY_MODULE_ROUTE) defaultModuleId = INJURY_MODULE_ID;
        else if (routeMenu == PSYCHOLOGY_MODULE_ROUTE) defaultModuleId = PSYCHOLOGY_MODULE_ID;
        else if (routeMenu == NUTRITION_MODULE_ROUTE) defaultModuleId = NUTRITION_MODULE_ID;

        setActiveMenu(() => {
            let isActive = true;
            return {
                isActive,
                menuId: defaultModuleId,
            };
        });
       
    }, [MainMenu, activeMenu])


    const activeMenuHandler = (memoizedMenuObj) => {
        let menuId = memoizedMenuObj?.menu_id;
        let subMenu = memoizedMenuObj?.sub_menu_action;
        if (!subMenu) {
            dispatch(templatesActions?.setIsActiveOnMob());
            console.log(memoizedMenuObj);
        }

        if (menuId === HOME_MODULE_ID) navigate("/");
        if (menuId === ATHLETES_MODULE_ID) navigate(`/${ATHLETE_MODULE_ROUTE}`);
        if (menuId === TEST_AND_ASSESSMENT_MODULE_ID) navigate("/testfunctions");
        if (menuId === PROFIE_MODULE_ID) navigate("/profile");
        if (menuId === INJURY_MODULE_ID) navigate(INJURY_MODULE_ROUTE)
        if (menuId === PSYCHOLOGY_MODULE_ID) navigate(PSYCHOLOGY_MODULE_ROUTE)
        if (menuId === NUTRITION_MODULE_ID) navigate(NUTRITION_MODULE_ROUTE)

        setActiveMenu(prev => {
            let isActive = true;
            if (menuId !== HOME_MODULE_ID) {
                isActive = prev?.menuId === menuId ? !prev?.isActive : true;
            };
            return {
                isActive,
                menuId
            };
        });
    };

    return (
        <div
            className={ `si-menu ${activeMenu?.isActive && activeMenu?.menuId == memoizedMenuObj?.menu_id ? "activeIn" : "activeOut"}`}>

            <div
                className="si-menu-title si-Training"
                onClick={() => activeMenuHandler(memoizedMenuObj)}
            >
                <div className="si-image"><span className={`icon-${memoizedMenuObj?.menu_id}`}></span></div>
                <span className="si-Menuehead">{memoizedMenuObj?.menu_name}</span>
                {/* <span className="si-drparrow"></span> */}
                {
                    memoizedMenuObj?.sub_menu_action &&
                    <span className="si-drparrow"></span>

                }
            </div>
            {
                memoizedMenuObj?.sub_menu_action &&
                <SubMenu
                    subMenu={memoizedMenuObj?.sub_menu_action}
                    menuId={memoizedMenuObj?.menu_id}
                    moduleId={memoizedMenuObj?.module_id}
                    activeMenu = {activeMenu}
                    index={index}
                />
            }

        </div>
    );
};

export default memo(MainMenu);