import { TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react'
import {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListingTable from '../../Common/ListingTableMUI';
import { ATHELETE_ROLE_ID} from '../../../../utility/constants';
import moment from 'moment/moment';

export default function ProgressNotesListingContent({caseLogList, pageNo, setPageNo, pagination}) {
    const navigate = useNavigate();
    const userData = useSelector((state) => state?.auth?.userData);
    const [matchAthRoleId , setMatchAthRoleId] = useState(false);
 

    const tableHeaders = [
      {
        label: "Session",
        align: "left",
        width:'30%',
        stickyClass:matchAthRoleId ? "stickyCell-left" : ""
      },

      {
        label: "Conducted By",
        align: "left",
        width:'20%'
      },

      {
        label: "Conducted Date",
        align: "center",
        width:'20%'
      },

    ];

    const viewHeader = [
      {
        label: "",
        align: "right",
        stickyClass: "stickyCell-right",
      },
    ]

    useEffect(()=>{
    let matchAthRole= userData && userData?.role_id == ATHELETE_ROLE_ID;
    setMatchAthRoleId(matchAthRole);
    },[userData])

    const formatDate = (date) => {
      return moment(date).format("DD/MM/YYYY");
    };
  
    const handleClick = (caseD) => {
      // localStorage.setItem("guid", caseD.athlete_guid);
      navigate(`${caseD?.session_log_id}`)
  }
  console.log(caseLogList);
    const getTableBody = () => {
      return (
        <TableBody>
          {caseLogList?.length ? (
            caseLogList?.map((caseD, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell
                    align={"left"}
                    style= {!matchAthRoleId? {cursor:"pointer"} : {cursor :"auto"}}
                    onClick={(e)=> !matchAthRoleId? handleClick(caseD):""}
                    width={'30%'}
                    className={ matchAthRoleId ? "stickyCell-left" :""}
                    >
                  {caseD?.session}
                  </TableCell>
                  <TableCell align={"left"}  width={'20%'}>{caseD?.user_name ? caseD?.user_name : "-"}</TableCell>
                  <TableCell align={"center"}  width={'20%'}>{caseD?.case_date ? formatDate(caseD?.case_date) : "-"}</TableCell>
              {  !matchAthRoleId ? 
                  <TableCell align={"right"} className="stickyCell-right"  >
                
                       <button
                        type="button"
                        className="si-viewBtn"
                        onClick={(e) => handleClick(caseD)}
                        >
                        <span className="si-icon__view"></span>
                        <span className="si-txt">View details</span>
                        </button> 
                  </TableCell>
                        : null

               }
                </TableRow>
              );
            })
          ) : (
            <TableRow className="nodata-tableRow">
              <TableCell align={"center"} colSpan={matchAthRoleId ? tableHeaders.length : [...tableHeaders, ...viewHeader].length}>
                No data found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      );
    };
  
    useEffect(() => {
      getTableBody();
    }, [caseLogList]);
  
    return (
      <>
        <ListingTable
          tableHeaders={ matchAthRoleId ? tableHeaders : [...tableHeaders, ...viewHeader]}
          getTableBody={getTableBody}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pagination={pagination}
          perPage={20}
        />
      </>
    )
}
