import React from 'react'
import { Fragment } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../Common/Header';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchEducationList, fetchInformantList, fetchMaritalStatusList, getCaseLog, insertUpdatePsychologyCase } from '../../../../utility/PsychologyApiActions';
import CaseLogContent from './CaseLogContent';
import { fetchGenderList } from '../../../../utility/tournamentEventsApiAction';
import moment from 'moment/moment';
import Footer from '../../Common/Footer';
import { ATHELETE_ROLE_ID, ATHLETES_MODULE_ID, ATHLETE_PROFILE_ROUTE, PROFIE_MODULE_ID, PROFILE_MODULE_ROUTE, PSYCHOLOGY_MODULE_ID, PSYCHOLOGY_MODULE_ROUTE } from '../../../../utility/constants';
import { toast } from 'react-toastify';
import { fetchAthleteDetails } from '../../../../utility/userManagementApiActions';
import { loaderActions } from '../../../../reducer/loader';

export default function ViewUpdateCaseLog() {
  let { athleteId, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const athlete_guid = localStorage.getItem("guid") ? localStorage.getItem("guid") : '';
  const [caseLogDetails, setCaseLogDetails] = useState();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const userData = useSelector((state) => state?.auth?.userData);
  const [isError, setIsError] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditOption, setIsEditOption] = useState(athleteId ? false : true);
  const casefileDetails = useSelector((state) => state?.psychology?.casefileDetails);
  const selectedPsychologyCaseId = useSelector((state) => state?.psychology?.selectedPsychologyCaseId);
  const athleteDetails = useSelector(state => state?.userManagement?.athleteDetails);
  const selectedMenu = useSelector(state => state?.sideMenu?.selectedMenuId);
  const loading = useSelector((state) => state?.psychology?.loading);
  
  useEffect(() => {
    if (loading) {
      dispatch(loaderActions.showLoader());
    } else {
      dispatch(loaderActions.hideLoader());
    }
  }, [loading]);

  useEffect(() => {
    if (userData && userData?.role_id == ATHELETE_ROLE_ID) {
      setIsEditOption(false);
    }
  }, [userData]);

  useEffect(() => {
    if (isAuth && !athleteDetails) {
      if (athlete_guid) {
        dispatch(fetchAthleteDetails({
          id: athlete_guid
        }));
      } else {
        // navigate(-1);
      }
    }
  }, [isAuth, athlete_guid, athleteDetails]);

  useEffect(() => {
    if (isAuth && athleteDetails) {
      if (!id) {
        navigate(-1);
      } else {
        getCaseDetails();
        dispatch(fetchGenderList());
        dispatch(fetchEducationList());
        dispatch(fetchMaritalStatusList());
        dispatch(fetchInformantList());
      }
    }
  }, [id, isAuth, athleteDetails]);

  useEffect(() => {
    if (casefileDetails) {
      let data = { ...casefileDetails }
      setCaseLogDetails(data);
    }
  }, [casefileDetails]);

  const getCaseDetails = () => {
    let payload = {
      athlete_guid: '',
      case_id: id
    }
    dispatch(getCaseLog({ payload }));
  }


  const saveHandler = () => {
    toast?.success("Case log updated successfully.");
    setIsEditMode(false);
    // getCaseDetails();
  }

  const cancelHandler = () => {
    getCaseDetails();
    setIsEditMode(false);
  }

  const onBackBtnClickHandler = () => {
    if(selectedMenu?.moduleId){
      if(selectedMenu?.moduleId == PSYCHOLOGY_MODULE_ID){
      navigate(`/${PSYCHOLOGY_MODULE_ROUTE}`);
      return;
      }
      if(selectedMenu?.moduleId == PROFIE_MODULE_ID){
        navigate(`/${PROFILE_MODULE_ROUTE}?tab=psychology`);
        return;
      }
      if(selectedMenu?.moduleId == ATHLETES_MODULE_ID){
        navigate(`/${ATHLETE_PROFILE_ROUTE}/${athleteId}?tab=psychology`);
        return;
      }
    } 
  }

  return (
    <Fragment>
      <Header
        headerData={{
          heading: "Psychology Case Log",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: onBackBtnClickHandler
        }}
      />
      <CaseLogContent isEditOption={isEditOption} caseLogDetails={caseLogDetails}
        setCaseLogDetails={setCaseLogDetails} isError={isError} setIsError={setIsError}
        isEditMode={isEditMode} setIsEditMode={setIsEditMode} saveHandler={saveHandler}
        cancelHandler={cancelHandler} />
    </Fragment>
  )
}
