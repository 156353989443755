import React from 'react';
import DatePicker from 'react-datepicker';
export default function DatePickerComponent({isEditMode,maxDate,minDate,selectedDate,dateChangeHandler}) {
    return (
        <DatePicker
            calendarClassName="si-calendar"
            dateFormat="dd/MM/yyyy"
            className="si-datepicker"
            showIcon
            disabled={isEditMode}
            maxDate ={maxDate}
            minDate = {minDate}
            selected={selectedDate ? new Date(selectedDate) : new Date()}
            onChange={(date) => dateChangeHandler(date)} />
    )
}
