import { createAsyncThunk } from '@reduxjs/toolkit';
import API from "./api";
import dashboard from '../reducer/dashboard';
import { authActions } from '../reducer/auth';

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchAthleteDashboard = createAsyncThunk("dashboard/getdailyuserdashboard", async (callback, { getState, dispatch,rejectWithValue }) => {
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            callback,
            apiResponse: await getAPIDetails(`common/getdailyuserdashboard?userguid=${userData.user_guid}`)
        }
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchCoachDashboard = createAsyncThunk("dashboard/getcoachdailydashboard", async (callback, { getState, dispatch, rejectWithValue }) => {
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            callback,
            apiResponse: await getAPIDetails(`common/getcoachdailydashboard?userguid=${userData.user_guid}`)
        }
    }
    catch (error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
}
);

export const fetchCoachProgramPerformanceData = createAsyncThunk("dashboard/getprogramperformancegraph", async (data, { getState,dispatch, rejectWithValue }) => {
    let {program_id,callback} = data;
    let state = getState();
    let userData = state?.auth?.userData;
    let body = {
        program_id: program_id,
        user_guid: userData.user_guid
    };
    try {
        return {
            callback,
            apiResponse: await postAPIDetails("common/getprogramperformancegraph", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});