import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {TableBody, TableRow, TableCell, Radio} from '@mui/material';
import ListingTable from '../../Common/ListingTableMUI';

function Content({selectedTemplate, setSelectedTemplate, pageNo, setPageNo, pagination, perPage}) {
    const templateListing = useSelector(state => state?.templates?.templateListing);

    const tableHeaders = [
        {
            label: "",
            align: "left",
            stickyClass: "stickyCell-left" 
        },
        {
            label: "Template Name",
            align: "left",
            // stickyClass: "stickyCell-left" 
        },
        {
            label: "Sports",
            align: "center"
        },
        {
            label: "Duration",
            align: "center"
        },
        {
            label: "Created By",
            align: "center"
        }
    ];

    const getTableBody = () => {
        return (
            <TableBody sx={{backgroundColor:"#181818"}}>
                {
                    templateListing?.length
                    ?
                    templateListing?.map(template => {
                        let isSelected = selectedTemplate?.program_templateid === template?.program_templateid;
                        return (
                            <TableRow
                                hover
                                key={uuidv4()}
                                tabIndex={-1}
                                selected={isSelected ? true : false}
                            >
                                <TableCell padding="checkbox" className="stickyCell-left">
                                    <Radio
                                        name="radio-buttons"
                                        checked={isSelected}
                                        value={selectedTemplate?.program_templateid}
                                        onClick={() => setSelectedTemplate({ ...template })}
                                    />
                                </TableCell>
                                <TableCell 
                                    align={"left"} 
                                    role="radio" 
                                    // className="stickyCell-left"
                                    sx={{cursor: "pointer", minWidth: 150}}
                                    aria-checked={isSelected ? true : false}
                                    selected={isSelected ? true : false}
                                    onClick={() => setSelectedTemplate({ ...template })}
                                >
                                    {template?.program_template_name}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {template?.sport_name}
                                </TableCell>
                                <TableCell align={"center"}>
                                    { 
                                        `${template?.duration > 1 
                                        ? (template?.duration + " Weeks") 
                                        : (template?.duration + " Week") }` 
                                    }
                                </TableCell>
                                <TableCell align={"center"}>
                                    Admin
                                </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={5}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };


    return (
        <div className="si-main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={pagination}
                perPage={perPage}
            />
        </div>
    );
};

export default memo(Content);