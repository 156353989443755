import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setUpdateTournamentSuccessfulFalse } from "../../../../reducer/tournaments.reducer";
import { fetchSportsList } from "../../../../utility/templateApiActions";
import { fetchTournamentLevelMaster, getTournamentLists } from "../../../../utility/tournamentsApiActions";
import FixedButton from "../../Common/FixedButton";
import Header from "../../Common/Header";
import TournamentListingContent from "./TournamentListingContent";
import TournamentListingSubHeader from "./TournamentListingSubHeader";

function TournamentListing() {
  const navigate = useNavigate();
  const paginationObject = useSelector((state) => state?.tournaments?.paginationObject);
  const tournamentListing = useSelector((state) => state?.tournaments?.tournamentList);
  let [selectedSports, setSelectedSports] = useState([]);
  let [reload, setReload] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const updateLoading = useSelector((state) => state?.tournaments?.updateTournamentLoading);
  const userData = useSelector((state) => state?.auth?.userData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSportsList());
    dispatch(fetchTournamentLevelMaster())
    dispatch(
      getTournamentLists({
        user_id: userData?.user_id ? userData.user_id : null,
      })
    );
    dispatch(setUpdateTournamentSuccessfulFalse());
  }, []);

  useEffect(() => {
    if (reload) {
      setReload(false);
      dispatch(fetchSportsList());
      dispatch(
        getTournamentLists({
          user_id: userData?.user_id ? userData.user_id : null,
        })
      );
      dispatch(setUpdateTournamentSuccessfulFalse());
    }
  }, [reload, dispatch]);

  useEffect(() => {
    dispatch(
      getTournamentLists({
        sport_id: selectedSports.length ? selectedSports : null,
        searchText: searchTerm,
        page_no: pageNo,
        user_id: userData?.user_id ? userData.user_id : null,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSports, pageNo, dispatch]);

  useEffect(() => {
    if(searchTerm !== null){
      let programListTimeOut = setTimeout(() => {
        dispatch(
          getTournamentLists({
            sport_id: selectedSports.length ? selectedSports : null,
            searchText: searchTerm,
            page_no: pageNo,
            user_id: userData?.user_id ? userData.user_id : null,
          })
          );
        }, 1000);
        return () => clearTimeout(programListTimeOut);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, dispatch]);

  return (
    <>
      {updateLoading && <div className="spin"></div>}
      <Header
        headerData={{
          heading: "Tournaments List",
          isHeadingEditable: false,
        }}
      />
      {/* {tournamentListing?.length || selectedSports?.length || searchTerm ?  */}
      <TournamentListingSubHeader
        selectedSports={selectedSports}
        setSelectedSports={setSelectedSports}
        pageNo={pageNo}
        setPageNo={setPageNo}
        onClick={() => navigate(`new`)}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      {/* : null} */}
      {/* {tournamentListing?.length || selectedSports?.length || searchTerm ?  */}
      <div className="si-main-content">
        <TournamentListingContent
          pageNo={pageNo}
          setPageNo={setPageNo}
          pagination={paginationObject}
          perPage={20}
          tournamentListing={tournamentListing}
          setReload={setReload}
        />
      </div>
      <FixedButton btnName="Add Tournament" onBtnClick={() => navigate(`new`)} iconClass="add" />
      {/* :
            <div className="si-main-content">
                    <div className="si-firstStep si-align-center">
                        <div className="si-text">
                            <span>Click on “Add Tournament”  to add a new Tournament</span>
                        </div>
                        <button 
                            type="button" 
                            className="si-button si-button--green si-icon--add"
                            onClick={() => navigate(`new`)}
                        >
                            <span className="icon-add"></span>Add Tournament
                        </button>
                    </div>
                </div> */}
      {/* } */}
    </>
  );
}

export default TournamentListing;
