import React, { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ATHELETE_ROLE_ID, NUTRITION_MODULE_ROUTE } from "../../../../utility/constants";
import { getAthleteWiseReportListing, getSelectedAthleteDetails } from "../../../../utility/nutritionApiAction";
import Header from "../../Common/Header";
import AthleteInformationNutrition from "./AthleteInformationNutrition";
import GroupNutritionPlan from "./GroupNutritionPlan";
import IndividualNutritionPlan from "./IndividualNutritionPlan";
import InternalNotesNutrition from "./InternalNotes/InternalNotesNutrition";
import NutritionProfileTabs from "./NutritionProfileTabs";

const tabListOptions = {
  information: {
    name: "Information",
    id: "information",
  },
  groupNutritionPlan: {
    name: "Group Nutrition Plan",
    id: "groupNutritionPlan",
  },
  internalNotes: {
    name: "Internal Notes",
    id: "internalNotes",
  },
  individualNutritionPlan: {
    name: "Individual Nutrition Plan",
    id: "individualNutritionPlan",
  },
};
function AthleteProfileNutrition() {
  let { athleteId } = useParams();
  const [tab] = useSearchParams();
  let location = useLocation()
  const VIEWMODEONLY = location.pathname.split("/")[1] ? location.pathname.split("/")[1] !== NUTRITION_MODULE_ROUTE :false
  const ATHLETEVIEWMODEONLY = location.pathname.split("/")[2] ? false :true
  const ATHLETE_PROFILE_VIEW = location.pathname.split("/")[1] ? location.pathname.split("/")[1] === "athleteprofile" : false;
  // const selectedAthleteGuid = "c2a49b6f-8856-46ed-bf5e-a61a22292200";
  const dispatch = useDispatch();
  let reportList = useSelector((state) => state.nutrition.athleteReportListing);
  let athleteDetails = useSelector((state) => state.nutrition.athleteDetails);
  let selectedAthleteDetails = useSelector((state) => state.nutrition.selectedAthleteDetails);
  let loading = useSelector((state) => state.nutrition.loading);
  let userData = useSelector((state) => state.auth.userData);
  const navigate = useNavigate();
  let [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (activeTab) return;
    if (tab.get("tab") && !activeTab) {
      setActiveTab(tab.get("tab"));
    } else {
      setActiveTab(tabListOptions.information.id);
    }
  }, [tab, activeTab]);

  // Get athlete guid if not find in routing state
  useEffect(() => {
    if ((athleteId && selectedAthleteDetails.athlete_id === athleteId) || (userData?.user_id && selectedAthleteDetails.athlete_id === userData?.user_id)) return;
    if (userData?.user_guid && (Number(userData?.role_id) === ATHELETE_ROLE_ID || athleteId) && activeTab) {
      // hit api
      let id = Number(userData?.role_id) === ATHELETE_ROLE_ID ? userData?.user_id : athleteId
      dispatch(getSelectedAthleteDetails({ athlete_id: id, user_guid: userData?.user_guid }));
    }
  }, [selectedAthleteDetails, athleteId, userData, dispatch, activeTab]);

  useEffect(() => {
    if (userData && selectedAthleteDetails?.athlete_guid && activeTab) {
      dispatch(
        getAthleteWiseReportListing({
          athlete_guid: Number(userData?.role_id) === ATHELETE_ROLE_ID ?  userData?.user_guid : selectedAthleteDetails?.athlete_guid,
          user_guid: userData?.user_guid,
        })
      );
      // dispatch(fetchAthleteDetails({athlete_guid:selectedAthleteGuid, user_guid:userData?.user_guid}))
    }
  }, [selectedAthleteDetails, dispatch, userData, userData?.user_guid, activeTab]);
  useEffect(() => {
    if (activeTab) {
      navigate(`?tab=${activeTab}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  return (
    <>
      {loading ? <div className="spin"></div> : null}
      <Header
        headerData={{
          heading: athleteDetails?.athlete_name
            ? `${athleteDetails?.athlete_name.split(" ")[0]}'s Nutrition Log`
            : "Nutriton Log",
          isHeadingEditable: false,
          isbackBtn: !ATHLETEVIEWMODEONLY,
          backBtnAction: () => navigate(-1),
        }}
      />
      <div className="si-tabs">
        <NutritionProfileTabs 
          VIEWMODEONLY={Boolean(VIEWMODEONLY || ATHLETEVIEWMODEONLY)} 
          ATHLETE_PROFILE_VIEW={ATHLETE_PROFILE_VIEW}
          tabListOptions={tabListOptions} 
          activeTab={activeTab} 
          setActiveTab={setActiveTab} />
        <main className="si-main-content">
          {activeTab === tabListOptions.information.id ? (
            <AthleteInformationNutrition
              athleteDetails={selectedAthleteDetails}
              reportList={reportList}
              activeTab={activeTab}
              athleteId={ATHLETEVIEWMODEONLY ?  userData?.user_id : athleteId}
            />
          ) : activeTab === tabListOptions.groupNutritionPlan.id ? (
            <GroupNutritionPlan athleteId={ATHLETEVIEWMODEONLY ?  userData?.user_id : athleteId} userData={userData}/>
          ) : activeTab === tabListOptions.individualNutritionPlan.id ? (
            <IndividualNutritionPlan VIEWMODEONLY={Boolean(ATHLETEVIEWMODEONLY || VIEWMODEONLY)} />
          ) : activeTab === tabListOptions.internalNotes.id ? (
            <InternalNotesNutrition athlete_guid={selectedAthleteDetails?.athlete_guid} athleteId={athleteId} />
          ) : null}
        </main>
      </div>
    </>
  );
}

export default AthleteProfileNutrition;
