import React, {useState, useEffect} from 'react';
import PopupFooter from './PopupFooter';

function ExerciseDeletePopup({hideShowEntireSchedule, type, isActive, isActionBtns, isRecurred, cancelHandler, okHandler}) {
    const [selectedAction, setSelectedAction] = useState("current");

    useEffect(() => {
        setSelectedAction("current");
    }, [isActive]);

    return (
        <div className={`si-popup zindex-10005 ${isActive ? "active" : ""}`} >
            <div className={`si-popup-content ${isRecurred ? "si-remove remove-exercise" : ""}`}>
                <div className="si-popup-body">
                    <div className="content-wrapper">
                        <div className="content-title">
                            {
                                type==="edit" 
                                ? <h5>Do you want to edit this exercise?</h5>
                                : <h5>Do you want to remove this exercise?</h5>
                            }
                        </div>
                        {
                            isRecurred &&
                            <div className="content-description">
                                <div className="si-form-radio">
                                    <div className="form-control">
                                        <input 
                                            type="radio" 
                                            id="test1" 
                                            name="radio-group" 
                                            checked={selectedAction==="current"}
                                            onChange={() => setSelectedAction("current")}
                                        />
                                        <label htmlFor="test1">Only for this day</label>
                                    </div>
                                    <div className="form-control">
                                        <input 
                                            type="radio" 
                                            id="test2" 
                                            name="radio-group"
                                            checked={selectedAction==="proceeding"}
                                            onChange={() => setSelectedAction("proceeding")}
                                        />
                                        <label htmlFor="test2">This and all future days</label>
                                    </div>
                                    {hideShowEntireSchedule ? 
                                        null :
                                    <div className="form-control">
                                        <input 
                                            type="radio" 
                                            id="test3" 
                                            name="radio-group"
                                            checked={selectedAction==="allRecurrence"}
                                            onChange={() => setSelectedAction("allRecurrence")}
                                        />
                                        <label htmlFor="test3">From the entire schedule</label>
                                    </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                
                <PopupFooter 
                    isActionBtns={isActionBtns}
                    btn1Class={"si-action-btn"}
                    actionBtn1Text={"Cancel"}
                    actionBtn1Handler={cancelHandler}
                    btn2Class={"si-action-btn"}
                    actionBtn2Text={"Ok"}
                    actionBtn2Handler={() => okHandler(selectedAction)}
                />
            </div>
        </div>
    );
};

export default ExerciseDeletePopup;