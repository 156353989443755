import React from "react";

export default function GeneralInfo({ details }) {
  let name = `${details?.athlete_name}`;
  return (
    <div className="si-tab-pane si-tab-pane__generalinfo">
      <div className="si-informationsection">
        <div className="si-Left">
          {/* <span className="si-view">View all</span> */}
          <div className="si-top">
            <div className="si-heading">Personal Information</div>
            <div className="si-infowrp">
              <div className="si-section">
                <span className="si-title">Full Name</span>
                <span className="si-info">{name}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Gender</span>
                <span className="si-info">{details?.gender ? details?.gender : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Age</span>
                <span className="si-info">{details?.age ? details?.age : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Phone No</span>
                <span className="si-info">{details?.mobile_number ? "+91 - "+details?.mobile_number : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Khleo India ID</span>
                <span className="si-info">{details?.khelo_india_id ? details?.khelo_india_id : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Training since</span>
                <span className="si-info">{details?.joining_year ? details?.joining_year : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Highest education</span>
                <span className="si-info">{details?.highest_education ? details?.highest_education : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Languages fluent in</span>
                <span className="si-info">{details?.languages ? details?.languages?.join(", ") : "-"}</span>
              </div>
            </div>
          </div>
          <div className="si-bottom">
            <div className="si-heading">Permanent Address</div>
            <div className="si-infowrp">
              <div className="si-section">
                <span className="si-title">City/Town</span>
                <span className="si-info">{details?.city ? details?.city : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">District</span>
                <span className="si-info">{details?.district ? details?.district : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Zip code</span>
                <span className="si-info">{details?.zip_code ? details?.zip_code : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">State</span>
                <span className="si-info">{details?.state ? details?.state : "-"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="si-Right">
          {/* <span className="si-view">View all</span> */}
          <div className="si-top">
            <div className="si-heading">Sports Information</div>
            <div className="si-infowrp">
              <div className="si-section">
                <span className="si-title">Sport</span>
                <span className="si-info">{details?.sport_name?.length ? details?.sport_name?.join(", ") : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Discipline</span>
                <span className="si-info">{details?.discipline ? details?.discipline : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Event Type</span>
                <span className="si-info">{details?.event_type ? details?.event_type : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Current training center</span>
                <span className="si-info">{details?.current_training_center ? details?.current_training_center : "-"}</span>
              </div>
            </div>
          </div>
          <div className="si-bottom">
            <div className="si-heading">Sports Kitting details</div>
            <div className="si-infowrp">
              <div className="si-section">
                <span className="si-title">Federation registration number</span>
                <span className="si-info">{details?.fed_registration_number ? details?.fed_registration_number : "-"}</span>
              </div>
              <div className="si-section">
                <span className="si-title">Track T-shirt size in inches</span>
                <span className="si-info">{details?.track_t_shirt_size ? details?.track_t_shirt_size : "-"}</span>
              </div>
              {/* <div className="si-section">
                <span className="si-title">Training competitively since</span>
                <span className="si-info">2015</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
