import { createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "../reducer/auth";
import API from "./api";
let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchNutritionSportsList = createAsyncThunk(
  "nutrition/fetchNutritionSportsList",
  async ({user_guid}, { dispatch, rejectWithValue }) => {
    try {
      let sportsList = await (await getAPIDetails("common/getnutritionistwisesportlisting?user_guid="+user_guid)).data?.data?.value;
      return { sportsList };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchNutritionReportLogListing = createAsyncThunk(
  "nutrition/fetchNutritionReportLogListing",
  async ({ payload }, { dispatch, rejectWithValue }) => {
    try {
      let nutritionReportLogRes = await (
        await postAPIDetails("common/getnutritionloglisting", payload)
      ).data?.data?.value;
      return { nutritionReportLogRes };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getNutritionLogDetails = createAsyncThunk(
  "nutrition/getNutritionLogDetails",
  async ({ type, id, user_guid, group_id,athlete_id }, { dispatch, rejectWithValue }) => {
    try {
      let payload = {
        nutrition_id: id ? id : null,
        group_id: group_id ? group_id : null,
        type: type ? type : null,
        athlete_id:athlete_id ? athlete_id : null,
        user_guid:
          // group_id ? null :
          user_guid ? user_guid : null,
      };
      let nutritionLogDetails = await (
        await postAPIDetails(`common/getnutritionlogdetails`, payload)
      ).data?.data?.value;
      return { nutritionLogDetails };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const updateLogDetails = createAsyncThunk(
  "nutrition/updateLogDetails",
  async ({ payload, getDetailsPayload }, { dispatch, rejectWithValue }) => {
    try {
      let updatedLogDetailsRes = await (
        await postAPIDetails(`common/insertupdatenutritionlog`, payload)
      ).data?.data?.value;
      let nutritionLogDetails = await (
        await postAPIDetails(`common/getnutritionlogdetails`, getDetailsPayload)
      ).data?.data?.value;
      return { updatedLogDetailsRes, nutritionLogDetails };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getAthleteWiseReportListing = createAsyncThunk(
  "nutrition/getAthleteWiseReportListing",
  async ({ athlete_guid,user_guid }, { dispatch, rejectWithValue }) => {
    try {
      let athleteReportListing = await (
        await getAPIDetails(`common/getathletewisereportlisting?user_guid=${athlete_guid}`)
      ).data?.data?.value;
      let athleteDetails = await (
        await getAPIDetails(`common/getathletedetails?athleteguid=${athlete_guid}&userguid=${user_guid}`)
      ).data?.data?.value;
      return { athleteReportListing,athleteDetails };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchAthleteDetails = createAsyncThunk(
  "nutrition/fetchAthleteDetails",
  async ({ athlete_guid, user_guid }, { dispatch, rejectWithValue }) => {
    try {
      let athleteDetails = await (
        await getAPIDetails(`common/getathletedetails?athleteguid=${athlete_guid}&userguid=${user_guid}`)
      ).data?.data?.value;
      return { athleteDetails };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);


export const fetchInternalNotesListing = createAsyncThunk(
  "nutrition/fetchInternalNotesListing",
  async ({ payload }, { dispatch, rejectWithValue }) => {
    try {
      let notesListing = await (
        await postAPIDetails(`common/getnutritionnoteslisting`, payload)
      ).data?.data?.value;
      return { notesListing };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);


export const fetchNotesDetails = createAsyncThunk(
  "nutrition/fetchNotesDetails",
  async ({ noteId, user_guid }, { dispatch, rejectWithValue }) => {
    try {
      let noteDetails = await (
        await getAPIDetails(`common/getnutritionnotesdetail?note_id=${noteId}&user_guid=${user_guid} `)
      ).data?.data?.value;
      return { noteDetails };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const insertUpdateInternalNote = createAsyncThunk(
  "nutrition/insertUpdateInternalNote",
  async ({ payload }, { dispatch, rejectWithValue }) => {
    try {
      let noteUpdated = await (
        await postAPIDetails(`common/insertupdatenutritionnotes`, payload)
      ).data?.data?.value;
      noteUpdated = {...noteUpdated, updated_by: payload.updated_by}
      return { noteUpdated };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);


export const getSelectedAthleteDetails = createAsyncThunk(
  "nutrition/getSelectedAthleteDetails",
  async ({ athlete_id, user_guid }, { dispatch, rejectWithValue }) => {
    try {
      let athleteGuid = await (
        await getAPIDetails(`common/getathleteguid?user_guid=${user_guid}&athlete_id=${athlete_id}`)
      ).data?.data?.value;
      let athleteDetails = await (
        await getAPIDetails(`common/getathletedetails?athleteguid=${athleteGuid}&userguid=${user_guid}`)
      ).data?.data?.value?.data;
      // let healthDetails =   await (
      //     await getAPIDetails(`common/getanthropometricdetails?athlete_guid=${athleteGuid}&user_guid=${user_guid}`)
      //   ).data?.data?.value?.data;
      //   if(healthDetails){
      //     athleteDetails = {...athleteDetails, ...healthDetails}
      //   }
      return { athleteDetails };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);


export const getAthleteProfileNutritionListing = createAsyncThunk(
  "nutrition/getAthleteProfileNutritionListing",
  async ({ payload }, { dispatch, rejectWithValue }) => {
    try {
      let listingData = await (
        await postAPIDetails(`common/getathletenutritionloglisting`, payload)
      ).data?.data?.value;
      return { listingData };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);