import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSportsList } from '../../../../utility/templateApiActions';
import { fetchAthletesList, fetchGroupListing } from '../../../../utility/programApiActions';
import SubHeader from '../SubHeader';
import Content from './Content';
import Footer from '../PopupFooter';
import { useParams } from 'react-router-dom';
import { loaderActions } from '../../../../reducer/loader';

function AddAthletePopup({isActive, setIsActive, actionBtnHandler,setIsFiltered,alreadyAddedAthletes}) {
    const {id} = useParams();
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedSports, setSelectedSports] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [athleteIds, setAthleteIds] = useState([]);
    const [allreadyAddedAthleteIds, setAlreadyAddedAthleteIds] = useState([]);
    const athleteListing = useSelector(state => state?.programs?.athleteListing);
    const paginationObject = useSelector(state => state?.programs?.paginationObject);
    const userDetails = useSelector(state => state?.auth?.userData);
    const dispatch = useDispatch();
    let filterOptions = {
        is_search_filter: true,
        is_group_filter: true,
        is_sport_filter: true
    };

    useEffect(() => {
        let athleteListTimeOut = setTimeout(() => {
            if(searchTerm!==null) dispatch(loaderActions?.showLoader());            
            dispatch(
                fetchAthletesList({
                    pageNo,
                    searchTerm,
                    programId: id,
                    // userId: userDetails.user_id,
                    userguid: userDetails.user_guid,
                    selectedSports,
                    groupFilter: selectedGroups,
                    callback: () => setTimeout(() =>{
                        dispatch(loaderActions?.hideLoader());
                    })
                })
            );
        }, 1000);

        if(pageNo!==1 || searchTerm || selectedGroups?.length) setIsFiltered(true);

        return () => clearTimeout(athleteListTimeOut);
    }, [searchTerm]);

    useEffect(() => {
        let athleteListTimeOut = setTimeout(() => {
            dispatch(loaderActions?.showLoader());
            dispatch(
                fetchAthletesList({
                    pageNo,
                    searchTerm,
                    programId: id,
                    // userId:userDetails.user_id,
                    userguid:userDetails.user_guid,
                    selectedSports,
                    groupFilter: selectedGroups,
                    callback: () => setTimeout(() =>{
                        dispatch(loaderActions?.hideLoader());
                    })
                })
            );
        }, 0);

        if(pageNo!==1 || selectedGroups?.length) setIsFiltered(true);

        return () => clearTimeout(athleteListTimeOut);
    }, [pageNo, selectedGroups, selectedSports]);

    useEffect(() => {
        dispatch(fetchSportsList());
        dispatch(fetchGroupListing());
    }, []);

    useEffect(() => {
        let selectedAthletes = athleteListing?.filter(obj => obj?.is_selected === 1)?.map(athlete => athlete?.athlete_id);
        // let selectedAthletes = alreadyAddedAthletes?.map(athlete => athlete?.athlete_id);
        console.log(selectedAthletes);
        if(selectedAthletes)  setAlreadyAddedAthleteIds([...selectedAthletes]);
        // if(selectedAthletes) setAthleteIds([...selectedAthletes]);
    }, [athleteListing]);


    const changeHandler = (e, isSelected, id) => {
        e?.preventDefault();
        console.log("athleteIds: ",athleteIds,id);
        let isDisabled = allreadyAddedAthleteIds?.includes(id);
        if(!isDisabled){
        setAthleteIds(prev => {
            if(isSelected) return [...prev, id];
            return [...prev?.filter(athleteId => athleteId!=id)];
        });
    }
    };

    return (
        <div className={`si-custom-popup si-popup ${isActive ? "active" : ""}`}>
            <div className="si-popup-content si-Athletes"> 
                <div className="si-popup-head">
                    <div className="si-popup__title">
                        <div className="form-control">
                            <input 
                                type="text" 
                                value="Athletes" 
                                className="si-input" 
                                placeholder="Athletes" 
                                disabled={true}
                            />
                        </div>
                        <button 
                            type="button" 
                            className="si-icon__button si-button__close"
                            onClick={() => setIsActive(false)}
                        >
                            <span className="icon-close"></span>
                        </button>
                    </div>

                    <SubHeader 
                        filterOptions={filterOptions}
                        searchTerm={ searchTerm }
                        setSearchTerm={ setSearchTerm }
                        selectedSports={ selectedSports }
                        setSelectedSportIds={ setSelectedSports }
                        selectedGroupsIds={ selectedGroups }
                        setSelectedGroupIds={ setSelectedGroups }
                        pageNo={ pageNo }
                        setPageNo={ setPageNo }
                    />
                </div>

             
                    <Content
                        pageNo={ pageNo }
                        setPageNo={ setPageNo } 
                        pagination={ paginationObject }
                        perPage={ 20 }
                        searchTerm={ searchTerm }
                        athleteIds={ athleteIds }
                        allreadyAddedAthleteIds={ allreadyAddedAthleteIds }
                        changeHandler={ changeHandler }
                    />

                    <Footer 
                        // pageNo={pageNo} 
                        // setPageNo={setPageNo} 
                        // pagination={paginationObject}
                        // perPage={20}
                        isActionBtn={true}
                        btnClass={`si-button ${athleteIds?.length ? "si-button--green" : "si-button--green"}`}
                        isBtnDisabled={(!athleteIds?.length 
                            // || alreadyAddedAthletes?.length===athleteIds?.length
                            ) 
                            ? true : false }
                        actionBtnText={"Add Athletes"}
                        actionBtnHandler={() => actionBtnHandler(athleteIds)}
                    />
                </div>
              </div>
     
    );
};

export default AddAthletePopup;