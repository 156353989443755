import React, { Fragment, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../Header';
import AthleteDetailsHeader from './AthleteDetailsHeader';
import ReportListingTable from './ReportListingTable';
import SubHeader from './SubHeader';
import { useState } from 'react';
import { testAssessmentActions } from '../../../../reducer/testAssessment';
import { useDispatch } from 'react-redux';
import { ATHELETE_ROLE_ID } from '../../../../utility/constants';
import FixedButton from '../FixedButton';

function AthleteReportListing({ from }) {
    const { state } = useLocation();
    const { backUrl } = state ? state : '';
    const { id } = useParams();
    const dispatch = useDispatch();
    const [heading, setHeading] = useState('');
    const userData = useSelector(state => state?.auth?.userData);
    const [userguid, setUserguid] = useState('');
    const [isAddBtn, setIsAddBtn] = useState(false);
    const navigate = useNavigate();
    const athleteDetails = useSelector(state => state?.testAssessment?.athleteDetails);
    const nextTestDate = useSelector(state => state?.testAssessment?.nextTestDate);
    const selectedAthleteFunction = useSelector(state => state?.testAssessment?.selectedAthleteFunction);

    useEffect(() => {
        if (userData && userData?.role_id == ATHELETE_ROLE_ID) {
            setUserguid(userData?.user_guid);
        } else {
            setUserguid(localStorage?.getItem("guid") ? localStorage?.getItem("guid") : "");
        }
        setIsAddBtn(userData?.role_id == ATHELETE_ROLE_ID ? false : from !== "profile" ? true : false);
    }, [userData]);

    useEffect(() => {
        if (athleteDetails) {
            setHeading(`${athleteDetails?.athlete_name}'s Reports`);
        }
    }, [athleteDetails])

    const getNavigation = (from) => {
        let navigateTo = `/testfunctions/${id}/athletelisting`;
        if (backUrl) {
            navigateTo = backUrl;
        } else if (from === "profile") {
            navigateTo = `/athleteprofile/${athleteDetails?.athlete_id}?tab=test`;
        } else if (userData.role_id == ATHELETE_ROLE_ID) {
            navigateTo = '/testfunctions';
        };
        navigate(navigateTo);
    };
    //visitsubheader.js
    const createBtnClickHandler = () => {
        console.log("changedFunctionId", selectedAthleteFunction);
        navigate(`/testfunctions/${selectedAthleteFunction}/newreport`, { state: { backUrl: `/testfunctions/${id}/athletereports` } })
    }

    const viewReportclickHandler = (e, report, isEdit) => {
        e?.preventDefault();
        console.log(report);
        let backUrl = `/testfunctions/${id}/athletereports`;
        if (from === "profile") {
            backUrl = `/athleteprofile/${id}/reports`;
        } 
        if(isEdit){
            navigate(`/testfunctions/${report?.tests_functionid}/report/${report?.test_reports_id}/edit`, { state: { backUrl: backUrl } })
        } else{
            navigate(`/testfunctions/${report?.tests_functionid}/report/${report?.test_reports_id}`, { state: { backUrl: backUrl } })
        }
        // navigate(`/athleteprofile/${report?.tests_functionid}/report/${report?.test_reports_id}`,{state:{backUrl:backUrl}})
    }

    useEffect(() => {
        console.log("selectedAthleteFunction--->", selectedAthleteFunction);
        if (!selectedAthleteFunction) {
            dispatch(testAssessmentActions.setAthleteFunction({ id }));
        }
    }, [selectedAthleteFunction]);
    return (
        <Fragment>
            <Header
                headerData={{
                    heading: heading,
                    isHeadingEditable: false,
                    isbackBtn: true,
                    backBtnAction: () => getNavigation(from)
                }}
            />

            <AthleteDetailsHeader
                details={athleteDetails}
            />

            <SubHeader
                nextConductedDate={nextTestDate ? nextTestDate : ""}
                userguid={userguid}
                isBtn={isAddBtn}
                btnName="ADD NEW REPORT"
                onBtnClick={createBtnClickHandler}
            />

            <ReportListingTable
                userguid={userguid}
                functionId={selectedAthleteFunction}
                viewReportclickHandler={viewReportclickHandler}
            />
            {/* {isAddBtn &&
                <FixedButton
                    btnName="ADD NEW REPORT"
                    onBtnClick={createBtnClickHandler}
                    iconClass="add"
                /> 
            } */}
        </Fragment>
    );
};

export default AthleteReportListing;