import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import ListingTable from "../../Common/ListingTableMUI";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import { getTournamentPerformanceEvents } from "../../../../utility/tournamentPerformanceApiAction";
import { useParams } from "react-router";

function EventPopupContent({ pagination, eventListing, setPageNo, pageNo, eventIds, setEventIds, mappedEvents, userData }) {
  const dispatch = useDispatch();
  const { id, sportId } = useParams();

  //   get events if sports changed
  useEffect(() => {
    if (sportId && userData?.user_guid) {
      dispatch(
        getTournamentPerformanceEvents({
          sport_id: sportId,
          performance_record_id: id,
          user_guid: userData?.user_guid ? userData?.user_guid : null
        })
      );
    }
  }, [sportId, id, dispatch, userData]);

  const tableHeaders = [
    {
      // label: "checkboxInput",
      align: "left",
      stickyClass: "stickyCell-left",
    },
    {
      label: "Event Name",
      align: "left",
    },
    {
      label: "Sports",
      align: "center",
    },

    {
      label: "Gender",
      align: "center",
    },

    {
      label: "Type",
      align: "center",
    },
  ];

  const handleEventSelect = (e, isSelected, id) => {
    e?.preventDefault();
    let isDisabled = mappedEvents?.includes(id);
    if (!isDisabled) {
      setEventIds((prev) => {
        if (isSelected) return [...prev, id];
        // eslint-disable-next-line eqeqeq
        return [...prev?.filter((eventId) => eventId != id)];
      });
    }
  };

  useEffect(() => {
    getTableBody();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventListing]);

  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {eventListing?.length ? (
          eventListing?.map((event, idx) => {
            let isSelected = eventIds?.includes(event?.event_id);
            let isDisabled = mappedEvents?.map((ev) => ev.event_id)?.includes(event?.event_id);
            return (
              <TableRow key={uuidv4()} hover selected={isSelected || isDisabled ? true : false}>
                <TableCell padding="checkbox" className="stickyCell-left">
                  <Checkbox
                    color="warning"
                    checked={isSelected || isDisabled ? true : false}
                    disabled={isDisabled ? true : false}
                    onClick={(e) => handleEventSelect(e, !isSelected, event?.event_id)}
                  />
                </TableCell>
                <TableCell
                  onClick={(e) => handleEventSelect(e, !isSelected, event?.event_id)}
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                >
                  {event?.event_name}
                </TableCell>
                <TableCell align={"center"}>{event?.sport_name ? event?.sport_name : "-"}</TableCell>

                <TableCell align={"center"}>{event?.gender ? event.gender : "-"}</TableCell>
                <TableCell align={"center"}>{event?.event_type ? event?.event_type : "-"}</TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <div className="si-main-content">
      <ListingTable
        tableHeaders={tableHeaders}
        getTableBody={getTableBody}
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={pagination}
        perPage={20}
      />
    </div>
  );
}

export default EventPopupContent;
