import React, {useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { format, addWeeks, addDays } from 'date-fns';

const dayShortName = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun"
};

function CalenderHeader({ activeDay, setActiveDay , setDayToAddExercise, navigateTo, startDate, selectedWeek=1 }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { id } = useParams();

    useEffect(() => {
        if(startDate) {
            let dayToSet = 1;
            Object?.keys(dayShortName)?.forEach(day => {
                let addDay = addDays(new Date(startDate), day - 1);
                let addWeek = addWeeks(addDay, selectedWeek - 1);
                if(format(addWeek, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
                    dayToSet = day;
                };
            });
            setActiveDay(dayToSet);
        };
    }, [startDate, selectedWeek]);

    const isAddExercise = (day, week) => {
        if(startDate && day && week) {
            let addDay = addDays(new Date(startDate), day - 1);
            let addWeek = addWeeks(addDay, week - 1);
            return format(addWeek, "yyyy-MM-dd") > format(new Date(), "yyyy-MM-dd");
        };
        return true;
    };

    const addExerciseHandler = (day, id, isAddExercise) => {
        if(isAddExercise) {
            dispatch(
                setDayToAddExercise({day, navigate, id, navigateTo})
            );
        };
    };
    
    return (
        <div className="si-calheader">
            <div className="si-weekcalender">
                <div className="si-tblheader">
                    {
                        Object?.keys(dayShortName)?.map(day => {
                            let currentActiveDay = activeDay ? activeDay : 1 ; 
                            let date = null , addDay, addWeek, dateDay = '' ,month = '',year= '', isCurrentDay=false;
                            if(startDate) {
                                addDay = addDays(new Date(startDate), day - 1);
                                addWeek = addWeeks(addDay, selectedWeek - 1);
                                dateDay = format(addWeek, 'EEE');
                                date = format(addWeek, 'dd');
                                month = format(addWeek, 'MMM');
                                year = format(addWeek, 'yy');
                                if(format(addWeek, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
                                    isCurrentDay = true;
                                };
                            };
                            // else if(selectedWeek==1 && day==1) {
                            //     isCurrentDay = true;
                            // };

                            return (
                                <div 
                                    key={ uuidv4() }
                                    className={
                                        `si-column 
                                        ${day == currentActiveDay ? "active" : ""} 
                                        ${isCurrentDay ? "activeday" : ""}`
                                    }
                                    onClick={ () => setActiveDay(day) }
                                >
                                    {
                                        date 
                                        ? <span className="si-day si-fullName">
                                        <span className="si-Daytxt">{dateDay},</span> 
                                        <div className="si-daywrp">
                                          <span className="si-dateno">{date} </span>
                                          <span className="si-month">{month}</span>
                                        </div>   
                                           <span className="si-year">{year}</span>
                                        </span>
                                        : <span className="si-day si-fullName">Day <span className="si-no">{ day }</span></span>
                                    }
                                    <span className="si-day si-shortName">{dateDay}</span>
                                    <button 
                                        className="si-exerise"
                                        onClick={() => addExerciseHandler(day, id, isAddExercise(day, selectedWeek))}
                                        disabled={!isAddExercise(day, selectedWeek)}
                                    >
                                        <span className="icon-add"></span>
                                        <span className="si-addtxt">Add Exercise</span>
                                    </button>
                                    <span className="si-indication"></span>
                                </div>
                            )
                        })
                    }

                    {/* mobile view */}
                    <div className="si-mobileview">
                        <button 
                            className="si-exerise"
                            onClick={() => addExerciseHandler(activeDay || 1, id, isAddExercise(activeDay || 1, selectedWeek))}
                            disabled={!isAddExercise(activeDay || 1, selectedWeek)}
                        >
                            <span className="icon-add"></span>
                            <span className="si-addtxt">Add Exercise</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalenderHeader;