import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

function InjuryReportLogTextArea({ handleSubmit }) {
  let [inputTextMsg, setInputTextMsg] = useState("");
  let [focused, setFocused] = useState(false);
  let inputBoxRef = useRef(null);

  useEffect(()=>{
    const handleEnter = (e)=>{
      if (e.key === "Enter") {
        inputBoxRef.current.value = "";
        handleSubmit(inputTextMsg);
        setInputTextMsg("")
      }
    }
    if(focused){
      window.addEventListener("keypress",handleEnter)
    } 
    return ()=>{ window.removeEventListener("keypress", handleEnter)}
  },[focused,inputTextMsg,handleSubmit])


  const inputTextChange = (event) => {
    setInputTextMsg(event.target.value);
  };

  return (
    <div className={`send-wrp ${inputTextMsg?.length ? "" :"gray-icon"}`}>
      <input
        type="text"
        className="actual-text"
        placeholder="Add a comment..."
        onChange={(e) => inputTextChange(e)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)} 
        ref={inputBoxRef}
      />
      <span
        className="icon-msg-send"
        onClick={() => {
          inputBoxRef.current.value = "";
          handleSubmit(inputTextMsg);
        }}
      ></span>
    </div>
  );
}

export default InjuryReportLogTextArea;
