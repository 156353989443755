import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import ExerciseActionPopup from '../../Common/CalendarActionPopup';
import EditExercisePopup from '../../Common/EditExercise/EditExercisePopup';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import { programsActions } from '../../../../reducer/programs';
import { toast } from 'react-toastify';
import { postTemplateExercises, fetchExerciseDetails, fetchTemoList,fetchUnitsList, publishCalendarSequence } from '../../../../utility/templateApiActions';
import CalendarView from '../../Common/Calendar/CalendarView';
import AthleteView from './AthleteView';
import { loaderActions } from '../../../../reducer/loader';
import { fetchWeekWiseProgramCalendarData, fetchWholeProgramCalendarData } from '../../../../utility/programApiActions';
import { addDays, addWeeks, format } from 'date-fns';

function CalenderContent({confirmationPopup, IS_PROGRAM_COMPLETED, setConfirmationPopup, headerData, setHeaderData, activeTab, startDate, isPerformance, setIsUpdateDisabled}) {
    const [isPopupActive, setIsPopupActive] = useState(false);
    const [exerciseActionPopup, setExerciseActionPopup] = useState({isShow: false});
    const selectedWeek = useSelector(state => state?.programs?.selectedWeek);
    const weekWiseProgramCalendarData = useSelector(state => state?.programs?.weekWiseProgramCalendarData);
    const tempoList = useSelector(state => state?.templates?.tempoList);
    const unitList = useSelector(state => state?.templates?.unitsList);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(!tempoList) dispatch(fetchTemoList());
        if(!unitList) dispatch(fetchUnitsList());
    }, []);

    const popupSaveHandler = (data) => {
        let isRecurred = data?.recurrence?.find(obj => obj?.week_no!=exerciseActionPopup?.week || obj?.day_no!=exerciseActionPopup?.day);
        if(isRecurred) {
            setExerciseActionPopup({
                ...exerciseActionPopup, 
                isShow: true,  
                data,
                isRecurred
            });
        } 
        else {
            setTimeout(() => {
                dispatch(loaderActions?.showLoader());
                dispatch(
                    postTemplateExercises({
                        id,
                        type: 2, 
                        callback: () => {
                            setTimeout(() => {
                                dispatch(fetchWeekWiseProgramCalendarData({ 
                                    id,
                                    week: selectedWeek ? selectedWeek : 1,
                                    callback: () => setTimeout(() =>{
                                        dispatch(loaderActions?.hideLoader());
                                        setIsPopupActive(false);
                                        toast.success("Exercise updated successfully.", {
                                            toastId: "program-calendar-exercise-update-success"
                                        });
                                    })
                                }));
                                dispatch(fetchWholeProgramCalendarData({id}));
                                dispatch(setIsUpdateDisabled(false));
                            });
                        }
                    })
                );
            });
        };
    };

    const editConfirmationHandler = (selectedAction) => {
        dispatch(loaderActions?.showLoader());
        dispatch(
            programsActions?.seletedExercise({
                type: "calendar",
                action: "edit",
                week: exerciseActionPopup?.week, 
                day: exerciseActionPopup?.day,
                selectedAction,
                data: exerciseActionPopup?.data,
                callback: () => setTimeout(() => {
                    dispatch(
                        postTemplateExercises({
                            id,
                            type: 2, 
                            callback: () => {
                                setTimeout(() => {  
                                    setExerciseActionPopup({isShow: false});
                                    dispatch(fetchWeekWiseProgramCalendarData({ 
                                        id,
                                        week: selectedWeek,
                                        callback: () => setTimeout(() =>{
                                            dispatch(loaderActions?.hideLoader());
                                            setIsPopupActive(false);
                                            toast.success("Exercise updated successfully.", {
                                                toastId: "program-calendar-exercise-update-success"
                                            });
                                        })
                                    }));
                                    dispatch(fetchWholeProgramCalendarData({id}));
                                    dispatch(setIsUpdateDisabled(false));
                                });
                            }
                        })
                    );
                })
            })
        );
    };

    const deleteConfirmationHandler = (selectedAction) => {
        dispatch(loaderActions?.showLoader());
        setExerciseActionPopup({isShow: false});
        dispatch(
            fetchExerciseDetails({
                id: exerciseActionPopup?.exerciseId,
                type: 2,
                callback: (data) => setTimeout(() => {
                    data = { ...data, athlete_notes: data?.notes, tempo: data?.selected_tempo }
                    delete data["selected_tempo"];
                    dispatch(
                        programsActions?.seletedExercise({
                            type: "calendar",
                            action: "delete",
                            week: exerciseActionPopup?.week, 
                            day: exerciseActionPopup?.day,
                            selectedAction,
                            data,
                            callback: () => setTimeout(() => {
                                dispatch(
                                    postTemplateExercises({
                                        id,
                                        type: 2,
                                        callback: () => setTimeout(() => {
                                            dispatch(
                                                fetchWeekWiseProgramCalendarData({
                                                    id,
                                                    week: selectedWeek,
                                                    callback: () => setTimeout(() => {
                                                        dispatch(loaderActions?.hideLoader());
                                                    })
                                                })
                                            );
                                            dispatch(fetchWholeProgramCalendarData({id}));
                                            dispatch(setIsUpdateDisabled(false));
                                            toast.success("Exercise removed successfully.", {
                                                toastId: "program-calendar-exercise-delete-success"
                                            });
                                        })
                                    })
                                );
                            })
                        })
                    );
                })
            })
        )
    };

    const editExerciseHandler = (id, week, day, exerciseMasterId) => {
        setExerciseActionPopup({
            type: "edit", 
            isShow: false, 
            exerciseId: id, 
            exerciseMasterId,
            week, 
            day
        });
        dispatch(loaderActions?.showLoader());
        dispatch(
            fetchExerciseDetails({
                id,
                type: 2,
                callback: (data) => setTimeout(() => {
                    data = { ...data, athlete_notes: data?.notes, tempo: data?.selected_tempo }
                    delete data["selected_tempo"];
                    dispatch(
                        programsActions?.seletedExercise({
                            type: "calendar",
                            action: "edit",
                            week, 
                            day,
                            data,
                            callback: () => setTimeout(() => {
                                dispatch(
                                    programsActions?.setEditExerciseId({
                                        day,
                                        exerciseId: exerciseMasterId,
                                        callback: () => setTimeout(() => {
                                            dispatch(loaderActions?.hideLoader());
                                            setIsPopupActive(true);
                                        })
                                    })
                                );
                            })
                        })
                    )
                })
            })
        );
    };

    const deleteExerciseHandler = (id, week, day) => {
        dispatch(
            fetchExerciseDetails({
                id,
                type: 2,
                callback: (data) => {
                    let isRecurred = data?.recurrence?.find(obj => obj?.week_no!=week || obj?.day_no!=day);
                    setExerciseActionPopup({type: "delete", isShow: true, exerciseId: id, week, day, isRecurred});
                }
            })
        );
    };

    const handleDragEnd = (data, week, day) => {
        if(data?.destination?.droppableId !== data?.source?.droppableId) return;
        // setHeaderData({
        //     ...headerData,
        //     backBtnAction: () => {
        //         setConfirmationPopup({
        //             isShow: true,
        //             isActionBtns: true,
        //             warning: "Are you sure you want to cancel?",
        //             warningMsg: "Your have not published your changes. if you close this page, your changes will be lost.",
        //             cancelHandler: () => setConfirmationPopup({isShow: false}),
        //             okHandler: () => {
        //                 setConfirmationPopup({isShow: false});
        //                 navigate("/snc/programs");
        //             }
        //         });
        //     }
        // });
        dispatch(programsActions?.setExerciseSequence({week,day,data}));
        dispatch(setIsUpdateDisabled(false));
        dispatch(
            publishCalendarSequence({
              id, 
              type: 2
            })
        );
    };

    const isAddExercise = (day, week) => {
        if(startDate && day && week) {
            let addDay = addDays(new Date(startDate), day - 1);
            let addWeek = addWeeks(addDay, week - 1);
            return format(addWeek, "yyyy-MM-dd") > format(new Date(), "yyyy-MM-dd");
        };
        return true;
    };
    
    return (
        <div className="si-main-content si-athletebtn">
            {
                isPopupActive &&
                <EditExercisePopup 
                    isActive={isPopupActive} 
                    cancelAction={() => setIsPopupActive(false)}
                    saveAction={popupSaveHandler} 
                    tabLists={IS_PROGRAM_COMPLETED || !isAddExercise(exerciseActionPopup?.day, exerciseActionPopup?.week)  ? ["specs", "notes_media"] : ["specs", "recurrence", "notes_media"]}
                    type={2}
                    startDate={startDate}
                    isActionBtn={!Boolean(IS_PROGRAM_COMPLETED || !isAddExercise(exerciseActionPopup?.day, exerciseActionPopup?.week))}
                    isViewOnly={IS_PROGRAM_COMPLETED || !isAddExercise(exerciseActionPopup?.day, exerciseActionPopup?.week)}
                />
            }

            <div className="si-tab-content">
                {activeTab == 'schedule' &&
                    <CalendarView 
                        calendarClass="si-calprogram"
                        handleDragEnd={handleDragEnd}
                        editExerciseHandler={editExerciseHandler}
                        deleteExerciseHandler={deleteExerciseHandler}
                        navigateTo={`/snc/programs/`}
                        startDate={startDate}
                        selectedWeek={selectedWeek}
                        weekWiseCalendarData={weekWiseProgramCalendarData}
                        setDayToAddExercise={programsActions?.setDayToAddExercise}
                        tempoList={tempoList}
                        unitsList={unitList}
                        IS_PROGRAM_COMPLETED={IS_PROGRAM_COMPLETED}
                    />
                }
                {activeTab == 'athletes' &&
                    <AthleteView 
                        isPerformance={isPerformance} 
                        setIsUpdateDisabled={setIsUpdateDisabled}
                    />
                }
            </div>

            <ExerciseActionPopup 
                type={exerciseActionPopup?.type}
                isActive={exerciseActionPopup?.isShow}
                isActionBtns={true}
                isRecurred={exerciseActionPopup?.isRecurred}
                cancelHandler={() => setExerciseActionPopup({isShow: false})}
                hideShowEntireSchedule ={IS_PROGRAM_COMPLETED || !isAddExercise(1, 1) ? true : false}
                okHandler={exerciseActionPopup?.type === "edit" ? editConfirmationHandler : deleteConfirmationHandler}
            />

            <ConfirmationPopup 
                isActive={confirmationPopup?.isShow}
                isActionBtn={confirmationPopup?.isActionBtn}
                isActionBtns={confirmationPopup?.isActionBtns}
                warning={confirmationPopup?.warning}
                warningMsg={confirmationPopup?.warningMsg}
                cancelHandler={confirmationPopup?.cancelHandler}
                okHandler={confirmationPopup?.okHandler}
            />
        </div>
    )
};

export default CalenderContent;