import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { fetchAthleteNotes, insertUpdateAthleteProgress, fetchWeekWiseProgressCalendarData } from '../../../../utility/programApiActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RangeSlider from '../RangeSlider';
import { format, addWeeks, addDays } from 'date-fns';
import { loaderActions } from '../../../../reducer/loader';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ATHELETE_ROLE_ID } from '../../../../utility/constants';

function CalenderExercies({ day, exercies, tempoList,unitsList, showExerciseDetails, startDate, selectedWeek, setFeedbackPopup, isCurrentDay, reviewId, review }) {
    const {id, userId} = useParams();
    const [progress, setProgress] = useState(null);
    const userData = useSelector(state => state?.auth?.userData);
    const dispatch = useDispatch();
    let isEditable = false;
    let addDay = addDays(new Date(startDate), day - 1);
    let addWeek = addWeeks(addDay, selectedWeek - 1);
    if(format(addWeek, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd") && userData?.role_id == ATHELETE_ROLE_ID) {
        isEditable = true;
    };

    useEffect(() => {
        if(exercies?.length) {
            let newProgress = exercies?.map(obj => {
                return {
                    id: obj?.id,
                    progressValue: obj?.progress_value ? obj?.progress_value : 0,
                    isDragging: false
                };
            });
            setProgress([...newProgress]);
        };
        // if(!unitsList?.length) dispatch(fetchUnitsList());
    }, [exercies]);

    const changeStartHandler = useCallback((scheduleId) => {
        setProgress(prev => {
            let newProgress = prev?.map(obj => {
                if(obj?.id===scheduleId) {
                    return {
                        ...obj,
                        isDragging: true
                    }
                };
                return obj;
            });
            return [...newProgress];
        });
    }, [exercies]);

    const changeHandler = useCallback((scheduleId, value) => {
        setProgress(prev => {
            let newProgress = prev?.map(obj => {
                if(obj?.id===scheduleId) {
                    return {
                        ...obj,
                        progressValue: value
                    }
                };
                return obj;
            });
            return [...newProgress];
        });
    }, [exercies]);

    const changeCompleteHandler = useCallback((scheduleId, value) => {
        console.log(value, scheduleId, "Complete");
        let isUpdated = false;
        setProgress(prev => {
            let newProgress = prev?.map(obj => {
                if(obj?.id===scheduleId) {
                    let preValue = exercies?.find(obj => obj?.id==scheduleId)?.progress_value;
                    if(preValue!=value) isUpdated = true;
                    return {
                        ...obj,
                        progressValue: value,
                        isDragging: false
                    }
                };
                return obj;
            });
            return [...newProgress];
        });

        // dispatch(loaderActions?.showLoader());
        if(isUpdated) {
            dispatch(
                insertUpdateAthleteProgress({
                    optType: 1, 
                    programId: id, 
                    userId, 
                    scheduleId, 
                    progressValue: value,
                    callback: () => setTimeout(() => {
                        dispatch(
                            fetchWeekWiseProgressCalendarData({
                                id,
                                week: selectedWeek,
                                userId,
                                callback: () => setTimeout(() => {
                                    // dispatch(loaderActions?.hideLoader());
                                    toast?.success("Workout logged successfully.", {
                                        toastId: "progress-calendar-workout-log-success"
                                    });
                                })
                            })
                        )
                    })
                })
            );
        };
    }, [exercies]);

    const feedbackPopupHandler = () => {
        dispatch(
            fetchAthleteNotes({
                userId, 
                programId: id, 
                week: selectedWeek, 
                day, 
                callback: (data) => {
                    setFeedbackPopup({
                        isShow: true, 
                        isEditable, 
                        day, 
                        week: selectedWeek,
                        notes: data?.selected_range?.notes,
                        noteId: data?.selected_range?.note_id,
                        emotionId: data?.selected_range?.review_id,
                        optType: (data?.selected_range?.review_id || data?.selected_range?.notes) ? 2 : 1,
                        isReason: userData?.role_id == ATHELETE_ROLE_ID ? true : false,
                        btnText: userData?.role_id == ATHELETE_ROLE_ID ? "Save" : "Ok"
                    });
                }
            })
        );
    };

    return (
        <Fragment>
            {
                exercies?.map((obj, idx) => {
                    let tempo = tempoList?.find(tempo => tempo?.tempo_id==obj?.tempo);
                    let progressValue = progress?.[idx]?.progressValue ? progress?.[idx]?.progressValue : obj?.progress_value;
                    let isDragging = progress?.[idx]?.isDragging;

                    return (
                        <div 
                            className={`si-card__box ${isDragging ? "si-card-dragging" : ""} ${isEditable ? "active" : ""}`}
                            key={obj?.id}
                        >
                            <div className={`si-card__container ${(progressValue != 100 && !isDragging) ? "si-incomplete" : ""}`}>
                                <div className="si-Left">
                                    <div className="si-exeriseName" onClick={(e) => showExerciseDetails(e, obj?.id, day, obj?.exercise_id)}>
                                        <span>{ obj?.exercise_name }</span>
                                    </div>
                                    <div className="si-exerise">
                                        <div className="text-wrap">
                                            {
                                                obj?.sets && 
                                                <span>{`${obj?.sets} sets `}</span> 
                                            }
                                            {
                                                obj?.metrics_units?.filter(metric => metric?.is_enabled && metric?.value)?.map(obj => {
                                                    let metricObj = unitsList?.filter?.(data => data?.metric_id==obj?.metric_id)[0];
                                                    let unitObj = metricObj?.unit?.filter?.(data => data?.unit_id==obj?.unit_id)[0];
                                                    return <span key={uuidv4()}>{`${obj?.value} ${unitObj?.unit}`}</span>
                                                })
                                            }
                                        </div>
                                        <span>{tempo ? tempo?.name : ""}</span>
                                    </div>
                                    <div className={`si-progressbar ${progressValue && progressValue==100 ? "si-green" : progressValue>0 || (isEditable && isCurrentDay) ? "si-red" : ""}`}>
                                        <div className="si-progressInfo">
                                            <div className="si-Label">{`${userData?.role_id == ATHELETE_ROLE_ID ? "Mark Progress" : "Progress"}`}</div>
                                            <div className="si-percent">{progressValue ? progressValue + "%" : "0%"}</div>
                                        </div>
                                        <RangeSlider 
                                            id={obj?.id} 
                                            isEditable={isEditable}
                                            progressValue={progressValue}
                                            isDragging={isDragging}
                                            changeStartHandler={changeStartHandler}
                                            changeHandler={changeHandler}
                                            changeCompleteHandler={changeCompleteHandler}
                                        />
                                    </div>
                                </div>
                                <div className={"si-Right"}>
                                    <button 
                                        type="button" 
                                        className="si-icon__button icon-chev-right"
                                    ></button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {
                exercies?.length && 
                <div 
                    className="si-card__box"
                    onClick={feedbackPopupHandler}
                >
                    {/* <div className="si-card__container">
                        <div className="si-Left">
                            <div className="si-exeriseName">
                                <span>Workout Feedback</span>
                            </div>
                        </div>
                        <div className="si-Right">
                            <button 
                                type="button" 
                                className="si-icon__button icon-chev-right"
                            ></button>
                        </div>
                    </div> */}
                    <div className="si-card__container feedback-container">
                        <div className="si-card__header">
                            <span className="si-card__header-title">Workout feedback</span>
                            <span className="si-card__header-icon">
                                <img src="../../images/icons/right-arrow.svg" alt=""/></span>
                        </div>
                        {
                            reviewId && 
                            review && 
                            <div className="si-feedback-remark">
                            <span className="si-feedback-remark__num">{reviewId}</span>:
                            <span className="si-feedback-remark__text">{review}</span> 
                          </div>
                        }
                    </div>

                </div>
            }
        </Fragment>
    )
};

export default CalenderExercies;