import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import ListingTable from "../../Common/ListingTableMUI";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import { getTournamentPerformanceEvents } from "../../../../utility/tournamentPerformanceApiAction";

function AthletePopupContent({ pagination, athleteListing, selectedAthletes, setSelectedAthletes, setPageNo, pageNo,userData }) {
  const dispatch = useDispatch();
  const { id, sportId } = useParams();

  //   get events if sports changed
  useEffect(() => {
    if (sportId && userData?.user_guid) {
      dispatch(
        getTournamentPerformanceEvents({
          sport_id: sportId,
          performance_record_id: id,
          user_guid : userData?.user_guid ? userData?.user_guid : null
        })
      );
    }
  }, [sportId, id, dispatch, userData]);

  const tableHeaders = [
    {
      // label: "checkboxInput",
      align: "left",
      stickyClass: "stickyCell-left",
    },
    {
      label: "Athlete Name",
      align: "left",
    },
    {
      label: "Groups",
      align: "center",
    },
  ];

  const handleAthleleSelect = (e, isSelected, athleteObj) => {
    e?.preventDefault();
    let id = athleteObj.athlete_id;
    // let isDisabled = mappedEvents?.includes(id);
    // if(!isDisabled){
    setSelectedAthletes((prev) => {
      if (isSelected) return [...prev, athleteObj];
      // eslint-disable-next-line eqeqeq
      return [...prev?.filter((athlete) => athlete.athlete_id != id)];
    });
    // }
  };

  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {athleteListing?.length ? (
          athleteListing?.map((athlete, idx) => {
            let isSelected = selectedAthletes?.map((athlete) => athlete.athlete_id).includes(athlete?.athlete_id);
            return (
              <TableRow key={uuidv4()} hover selected={athlete.is_selected || isSelected ? true : false}>
                <TableCell padding="checkbox" className="stickyCell-left">
                  <Checkbox
                    color="warning"
                    checked={athlete.is_selected || isSelected ? true : false}
                    // checked={isSelected || isDisabled ? true : false}
                    disabled={athlete.is_selected ? true : false}
                    // disabled={isDisabled ? true : false}
                    onClick={(e) => handleAthleleSelect(e, !isSelected, athlete)}
                  />
                </TableCell>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                  onClick={(e) => (!athlete.is_selected ? handleAthleleSelect(e, !isSelected, athlete) : null)}
                >
                  {athlete?.athlete_name}
                </TableCell>
                <TableCell align={"center"}>{athlete?.group_name ? athlete?.group_name?.join(", ") : "-"}</TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  useEffect(() => {
    getTableBody();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [athleteListing]);
  return (
    <div className="si-main-content">
      <ListingTable
        tableHeaders={tableHeaders}
        getTableBody={getTableBody}
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={pagination}
        perPage={20}
      />
    </div>
  );
}

export default AthletePopupContent;
