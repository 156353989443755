import { createSlice } from "@reduxjs/toolkit";
import { fetchAllTests, fetchAthleteReportList,getFunctionWiseAthleteListing,getReportTemplate,getSubmittedReport, insertUpdateNewReport } from "../utility/testAssessmentApiActions";
import { toast } from 'react-toastify';

const initialState = {
    testsList: null,
    athleteReportList: null,
    paginationObject: null,
    athleteDetails: null,
    nextTestDate: null,
    atheleteList :null,
    functionDetails:null,
    reportDetails:null,
    selectedAthlete:null,
    selectedAthleteFunction:null,
    reportTemplateDetails:null
};

const testAssessmentSlice = createSlice({
    name: "testAssessment",
    initialState,
    reducers: {
        setAthlete(state,action){
            state.selectedAthlete = action?.payload?.data?.athlete;
        },
        setAthleteFunction(state,action){
            console.log(action?.payload);
            state.selectedAthleteFunction = action?.payload?.id;
        },
        clearReportTemplateDetails(state){
            state.reportTemplateDetails = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllTests?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.testsList = apiResponse?.data?.data?.value?.data;
            state.functionDetails=null;
            if(callback) callback();
        });
        builder.addCase(fetchAllTests?.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            state.testsList = null;
            toast?.error(`Unable to fetch tests. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch tests Error: ", error);
            if(callback) callback();
        });
        builder.addCase(fetchAthleteReportList?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.athleteReportList = apiResponse?.reportListing?.data?.data?.value?.data?.test_reports;
            state.paginationObject = apiResponse?.reportListing?.data?.data?.value?.pagination;
            state.athleteDetails = apiResponse?.reportListing?.data?.data?.value?.data?.athlete_detail;
            state.nextTestDate = apiResponse?.reportListing?.data?.data?.value?.data?.next_test_date;
            state.testsList = apiResponse?.functionListing?.data?.data?.value?.data;
            if(callback) callback();
        });
        builder.addCase(fetchAthleteReportList?.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            state.athleteReportList = null;
            state.paginationObject = null;
            state.athleteDetails = null;
            state.nextTestDate = null;
            state.testsList = null;
            toast?.error(`Unable to fetch reports. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch reports Error: ", error);
            if(callback) callback();
        });

        builder.addCase(getFunctionWiseAthleteListing?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.atheleteList = apiResponse?.data?.data?.value?.data;
            state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            state.functionDetails = apiResponse?.data?.data?.value?.function_details;
            if(callback) callback();
        });

        builder.addCase(getFunctionWiseAthleteListing?.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            state.atheleteList = null;
            state.paginationObject = null;
            state.functionDetails = null;
            toast?.error(`Unable to fetch athletes. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch reports Error: ", error);
            if(callback) callback();
        });
        
        builder.addCase(getSubmittedReport?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.reportDetails=apiResponse?.data?.data?.value?.data;
            if(callback) callback();
        });
        
        builder.addCase(getSubmittedReport?.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            state.reportDetails = '';
            toast?.error(`Unable to fetch athletes. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch reports Error: ", error);
            if(callback) callback();
        });
        builder.addCase(insertUpdateNewReport?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            if(apiResponse?.data?.data?.value){
                if(action.meta.arg?.newReport?.report_id){
                    toast?.success("Report updated successfully.");
                } else{
                    toast?.success("Report submitted successfully.");
                }
            }
            if(callback) callback(true);
        });
        
        builder.addCase(insertUpdateNewReport?.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            if(error?.response?.data?.status == 400){
                toast?.error(error?.response?.data?.detail,{toastId:"apiErrorToast"});
            }else{
                toast?.error(`Unable to add report. Please try again later.`,{toastId:"apiErrorToast"});
            }
            console.log("Add new Report Error: ", error);
            if(callback) callback(false);
        });
        builder.addCase(getReportTemplate?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.reportTemplateDetails=apiResponse?.data?.data?.value?.data;
            if(callback) callback();
        });
        
        builder.addCase(getReportTemplate?.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            state.reportTemplateDetails = '';
            toast?.error(`Unable to fetch report template. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch reports Template Error: ", error);
            if(callback) callback();
        });

    }
  
    
});

export const testAssessmentActions = testAssessmentSlice?.actions;
export default testAssessmentSlice?.reducer;