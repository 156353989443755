import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPerformanceTournamentDetails } from "../../../../reducer/tournamentsPerformance.reducer";
import { NUTRITION_MODULE_ROUTE } from "../../../../utility/constants";
import { getAthleteProfileNutritionListing } from "../../../../utility/nutritionApiAction";
import SubHeader from "../../Common/SubHeader";
import NutritionListContent from "./NutritionListContent";

function NutritionTab({ activeTab }) {
  const athleteDetails = useSelector((state) => state?.userManagement?.athleteDetails);
  const dispatch = useDispatch();
  let [pageNo, setPageNo] = useState(1);
  let [searchTerm, setSearchTerm] = useState(null);
  let loading = useSelector((state) => state?.nutrition?.loading);
  let nutritionList = useSelector((state) => state?.nutrition?.athleteProfileNutritionListing);
  let paginationObject = useSelector((state) => state?.nutrition?.athleteProfileNutritionPagination);
  let userData = useSelector((state) => state?.auth?.userData);
  const navigate = useNavigate();

  let filterOptions = {
    is_program_status_filter: false,
    is_search_filter_for_athlete: true,
    is_injury_status_filter: false,
    is_date_range_filter: false,
    is_tag_filter: false,
    is_tab: false,
  };

  useEffect(() => {
    if (searchTerm !== null) {
      let injuryListTimeOut = setTimeout(() => {
        let payload = {
          user_guid: userData?.user_guid,
          athlete_guid: athleteDetails?.athlete_guid,
          page_no: 1,
          item_number: 20,
          search: searchTerm,
        };
        dispatch(getAthleteProfileNutritionListing({ payload }));
      }, 500);
      return () => clearTimeout(injuryListTimeOut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, dispatch]);

  useEffect(() => {
    if (activeTab === "nutrition" && athleteDetails) {
      let payload = {
        user_guid: userData?.user_guid,
        athlete_guid: athleteDetails?.athlete_guid,
        page_no: pageNo,
        item_number: 20,
        search: searchTerm,
      };
      dispatch(getAthleteProfileNutritionListing({ payload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, athleteDetails, dispatch, pageNo, userData]);

  const handleNutritionLog = (event, logDetails) => {
    let navigateUrl = null;
    if (logDetails?.group_id) {
      navigateUrl = `/athleteprofile/${NUTRITION_MODULE_ROUTE}/1/${logDetails.nutrition_id}`;
      console.log("log", logDetails);
    } else if (logDetails?.athlete_id) {
      navigateUrl = `/athleteprofile/${NUTRITION_MODULE_ROUTE}/2/${logDetails.nutrition_id}/${logDetails?.athlete_id}`;
      console.log("athlte", logDetails);
    }
    // dispatch(setPerformanceTournamentDetails(logDetails));
    if (navigateUrl) {
      navigate(navigateUrl);
    }
  };

  console.log("nutritionLists", nutritionList);
  return (
    <>
      {loading ? <div className="spin"></div> : null}
      <div className="si-tab-pane si-tab-pane__Training">
        <SubHeader
          filterOptions={filterOptions}
          OtherClass={"athelete-profile-subheader"}
          pageNo={pageNo}
          setPageNo={setPageNo}
          isBtn={false}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <div className="si-main-content myprofile-maincontent">
          <NutritionListContent
            pagination={paginationObject}
            nutritionList={nutritionList}
            setPageNo={setPageNo}
            pageNo={pageNo}
            handleNutritionLog={handleNutritionLog}
          />
        </div>
      </div>
    </>
  );
}

export default NutritionTab;
