import axios from "axios";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";

function UploadFiles({ file, setFile }) {
  const [fileUploading, setFileUploading] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_BASE_PATH;

  const progressRef = React.useRef();
  const ICON_SVG = {
    "pdf":"/images/icons/pdf2-icon.svg",
    "jpeg":"/images/icons/jpeg-icon.svg",
    "txt":"/images/icons/txt-icon.svg",
    "docx":"/images/icons/docx-icon.svg",
    "png":"/images/icons/png-icon.svg",
    "jpg":"/images/icons/jpg-icon.svg",
    "others":"/images/icons/pdf2-icon.svg"
  }
  const ProgressHandler = (e) => {
    let percent = (e.loaded / e.total) * 100;
    progressRef.current.value = Math.round(percent);
  };
  const Deletefile = (name) => {
    axios
      .post(`${apiBaseUrl}common/deletefile`, {filename:name})
      .then((res) => {
        setFile((prevstate) => (prevstate ? prevstate.filter((file) => file.name !== name) : []));
        toast.success("File deleted successfully.");
      })
      .catch((error) => {
        toast.error("Unable to delete the file. Please try again.");
      });
  };

  const handleUpload = async (fileS) => {
    const formData = new FormData();
    Array.from(fileS).forEach((item) => {
      formData.append("file", item);
    });
    const url = apiBaseUrl + "common/uploadfiles";
    let config = {
      onUploadProgress: (e) => {
        ProgressHandler(e);
      },
      headers: { "Content-Type": "multipart/form-data" },
    };
    axios
      .post(url, formData, config)
      .then((res) => {
        setFile((prevstate) => {
          return prevstate
            ? [...prevstate, { name: res.data.data.filenames[0], file: fileS[0] }]
            : [{ name: res.data.data.filenames[0], file: fileS[0] }];
        });
        setFileUploading((old) => (old?.length ? old.filter((oldFiles) => oldFiles.name !== fileS[0].name) : []));
        toast.success("File uploaded successfully.");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "Error");
        setLoading(false);
        setFileUploading((old) => (old?.length ? old.filter((oldFiles) => oldFiles.name !== fileS[0].name) : []));
        toast.error("Unable to upload the file. Please try again.");
      });
  };

  const handleInputChange = (e) => {
    setFileUploading((oldList) => [...oldList, e]);
    setLoading(true);
  };

  const getFileTypeIcon = ({fileName})=>{
    let fileType = fileName.split(".")[fileName.split(".").length-1]
    if(ICON_SVG[fileType]){
      return ICON_SVG[fileType]
    } else {
      return ICON_SVG.others
    }
  }
  console.log('files', fileUploading,file)
  return (
    <div className={`upload-popup__body ${file?.length > 2 ? "attachment-disabled":""}`}>
      <FileUploader
        multiple={false}
        handleChange={(e) => {
          handleInputChange(e);
          handleUpload([e]);
        }}
        children={
          <div>
            <div className="drag-drop-container drag-drop">
              <div className="drag-drop__icon">
                <i className="fas fa-cloud-upload-alt" />
              </div>
              <div className="drag-drop__text-wrp">
                <p className="title-text">
                  Drag and Drop or
                  <input type="file" className="drag-drop__input" />
                  to upload
                </p>
                <p className="info-text"> *Only 3 attachments allowed (jpg, jpeg, png, pdf, txt, docx) - Limit 10 MB</p>
              </div>
            </div>
          </div>
        }
        name="upload_files"
        types={["JPEG", "PNG", "JPG", "PDF", "TXT", "DOCX"]}
        maxSize={10}
        disabled={loading ? true : file?.length > 2 ? true : false}
        onSizeError={() => toast.error("File size is greater than 10MB.")}
      />

      <div className="uploaded-file-wrp">
        {fileUploading?.length ? (
          fileUploading.map((item, index, filename) => {
            return (
              <React.Fragment key={index}>
                <div className="uploaded-file">
                  <span class="icon-pdf">
                    <img
                      src={
                        process.env.PUBLIC_URL + getFileTypeIcon({fileName:item.name})
                        // item
                        //   ? item.type == "application/pdf"
                        //     ? process.env.PUBLIC_URL + "/images/Pdf_img.png"
                        //     : URL.createObjectURL(item)
                        //   : null
                      }
                      alt="Uploadimage"
                    />
                  </span>

                  <span class="file-name">{item.name}</span>
                  <span class="file-name-tooltip">{item.name}</span>
                  <div className="progress-wrp">
                    <progress id="file" ref={progressRef} value="0" max="100" data-label="32%">
                      {" "}
                      0%{" "}
                    </progress>
                  </div>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
        {file?.length ? (
          file.map((item, index, filename) => {
            return (
              <React.Fragment key={index}>
                <div className="uploaded-file">
                  <span class="icon-pdf">
                    <img src={item ? 
                      process.env.PUBLIC_URL + getFileTypeIcon({fileName:item.name})
                    // process.env.PUBLIC_URL + "/images/Pdf_img.png"
                   : null} alt="Uploadimage" />
                  </span>

                  <span class="file-name">
                    <p>{item.name}</p>
                  </span>
                  <span class="file-name-tooltip">{item.name}</span>
                  <span class="icon-delete-2" onClick={() => Deletefile(item.name)}>
                    {/* <img
                      src={process.env.PUBLIC_URL + "/images/icons/delete-icon.svg"}
                    //   onClick={() => Deletefile(item.name)}
                    alt="temp"
                    /> */}
                  </span>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default UploadFiles;
