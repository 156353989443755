import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListingTable from "../../Common/ListingTableMUI";
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from "../../Common/ConfirmationPopup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { deleteEvents } from "../../../../utility/tournamentEventsApiAction";

function EventListingContent({ pagination, eventListing, setPageNo, pageNo, setReload }) {
  const [isActionActive, setIsActionActive] = useState(Array(eventListing?.length)?.fill(false));
  const [warningPopup, setWarningPopup] = useState({ isShow: false });
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.auth?.userData);
  const navigate = useNavigate();

  useEffect(() => {
    window?.addEventListener("click", handleKeydownEvent);
    return () => window?.removeEventListener("click", handleKeydownEvent);
  }, []);

  const tableHeaders = [
    {
      label: "Event Name",
      align: "left",
    },
    {
      label: "Sports",
      align: "left",
    },

    {
      label: "Gender",
      align: "left",
    },

    {
      label: "Type",
      align: "left",
    },
    {
      label: "",
      align: "center",
      stickyClass: "stickyCell-right",
    },
  ];

  const handleKeydownEvent = (event) => {
    setIsActionActive(Array(eventListing?.length)?.fill(false));
  };
  const handleAction = (e, idx) => {
    e?.stopPropagation();
    setIsActionActive((prevState) => {
      let newState = Array(eventListing?.length)?.fill(false);
      newState[idx] = !prevState[idx];
      return [...newState];
    });
  };

  const handleDelete = () => {
    let payload = {
      event_id: warningPopup?.eventId,
      user_guid: userData?.user_guid,
    };
    dispatch(deleteEvents({ ...payload }));
    setWarningPopup({ isShow: false });
    setReload(true);
  };
  const handleDeleteClick = (e, id) => {
    e?.stopPropagation();
    setWarningPopup({ isShow: true, eventId: id });
  };

  const handlePopupCancel = () => {
    setWarningPopup({ isShow: false });
    setIsActionActive(Array(eventListing?.length)?.fill(false));
  };

  const handleEdit = (e, id) => {
    e?.stopPropagation();
    navigate(`new/${id}`);
  };
  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {eventListing?.length ? (
          eventListing?.map((event, idx) => {
            return (
              <TableRow key={uuidv4()}>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                  onClick={(e) => handleEdit(e, event?.event_id)}
                  >
                  {event?.event_name}
                </TableCell>
                <TableCell align={"left"}>{event?.sport_name ? event?.sport_name : "-"}</TableCell>

                <TableCell align={"left"}>{event?.gender ? event.gender : "-"}</TableCell>
                <TableCell align={"left"}>{event?.event_type ? event?.event_type : "-"}</TableCell>

                <TableCell align={"center"} className="stickyCell-right">
                  <div className="si-custom-table__cell si-wrap" style={{ margin: "auto" }}>
                    <button
                      type="button"
                      className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                      onClick={(e) => handleAction(e, idx)}
                    ></button>
                    <div className="si-action-icons">
                      <button type="button" className="si-icon-button" 
                      onClick={(e) => handleEdit(e, event?.event_id)}
                      >
                        <span className="icon-edit"></span>edit
                      </button>
                      {/* <button 
                                              type="button" 
                                              className="si-icon-button"
                                              onClick={(e) => handleDeleteClick(e, event?.event_id)}>
                                              <span className="icon-delete"></span>delete
                                          </button> */}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  useEffect(() => {
    getTableBody();
  }, [eventListing]);
  return (
    <>
      <ListingTable
        tableHeaders={tableHeaders}
        getTableBody={getTableBody}
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={pagination}
        perPage={20}
      />
      <ConfirmationPopup
        isActive={warningPopup?.isShow}
        isActionBtns={true}
        warning={"Are you sure you want to delete this tournament?"}
        okHandler={handleDelete}
        cancelHandler={handlePopupCancel}
      />
    </>
  );
}

export default EventListingContent;
