import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchTechnicalExerciseMaster } from '../../../../../utility/technicalTemplateApiActions';

function ExerciseHead({exerciseToEdit, setExerciseToEdit,onCancel, isEditable=false ,selectedExerciseNameError , setSelectedExerciseNameError, isViewOnly=false}) {
    const dispatch = useDispatch();
    const [currentExerciseName , setCurrentExerciseName] = useState('');
    const selectedMenuId = useSelector(state => state?.sideMenu?.selectedMenuId);
    useEffect(()=>{
        if(exerciseToEdit){
            setCurrentExerciseName(exerciseToEdit?.exercise_id);
        };
    },[exerciseToEdit]);

    const checkExerciseExist =(e)=>{
        let value = e?.target?.value;
        setSelectedExerciseNameError({
            is_error : false,
            msg:''
        });
        if(value){
            dispatch(
                fetchTechnicalExerciseMaster({
                    perPage:100,
                    pageNo:1,
                    moduleId:selectedMenuId?.moduleId,
                    searchTerm:value,
                    selectedTag:null,
                    selectedSportIds:null,
                    callback: (res)=>{
                        if(res){
                            console.log("res",res,value);
                            if(res.length){
                                let exactMatch = res.filter((obj)=>{
                                    return obj.exercise_name.toLowerCase() === value.toLowerCase() && obj.exercise_id != exerciseToEdit.exercise_id;
                                })[0];
                                if(exactMatch){
                                    setSelectedExerciseNameError({
                                        is_error : true,
                                        msg:'Exercise with the same name already exists.'
                                    });
                                }
                            }
                        }
                    }
                })
            );
        }
    }
    const exerciseNameChangeHandler = (e)=>{
        if(isEditable){
            setExerciseToEdit(prevState=>{
                return { ...prevState , exercise_name:e?.target?.value }
            });
        };
        setSelectedExerciseNameError({
            is_error : false,
            msg:''
        });
    }
    const exerciseDescChangeHandler = (e)=>{
        if((isEditable || !isViewOnly)){
            setExerciseToEdit(prevState=>{
                return { ...prevState , description:e?.target?.value }
            });
        }
    }
    return (
        <div className="si-popup-head">
            <div className="si-popup__title">
            <div className={`form-control ${selectedExerciseNameError?.is_error ? "si-error" : ""}`}>
                <input 
                        type="text" 
                        value={ exerciseToEdit?.exercise_name ? exerciseToEdit?.exercise_name : "" }
                        placeholder="Add Exercise Name"
                        className="si-input" 
                        disabled = {!isEditable}
                        onChange={exerciseNameChangeHandler}
                        onBlur={checkExerciseExist}
                    />
                    <span className={ selectedExerciseNameError?.is_error ? "si-error-message" : "" }>{ selectedExerciseNameError?.msg }</span>
                </div>
                <button 
                type="button" 
                className="si-icon__button si-button__close"
                onClick={onCancel}>
                <span className="icon-close"></span>
            </button>
                
            </div>
            <div 
                className={`
                    si-popup__description 
                    ${!(exerciseToEdit?.description || exerciseToEdit?.exercise_desc) && !(isEditable || !isViewOnly) ? "d-none" : ""}`
                }
            >
                <label className="si-label">Description</label>
                <textarea 
                    id="" 
                    name="" 
                    rows={exerciseToEdit?.description || exerciseToEdit?.exercise_desc || isEditable ? 2 : 1} 
                    cols="" 
                    className="si-input" 
                    placeholder="Add a short description of exercise"
                    value={ exerciseToEdit?.description ? exerciseToEdit?.description : exerciseToEdit?.exercise_desc ? exerciseToEdit?.exercise_desc : "" }
                    disabled={!(isEditable || !isViewOnly)}
                    onChange={exerciseDescChangeHandler}
                >   
                    { exerciseToEdit?.description? exerciseToEdit?.description : exerciseToEdit?.exercise_desc ? exerciseToEdit?.exercise_desc : ""}                   
                </textarea>
            </div>
        </div>
    );
};

export default ExerciseHead;