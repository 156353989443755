import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddNewNutritionLog from '../components/RightPannel/Nutrition/AddNewNutritionLog'
import AthleteProfileNutrition from '../components/RightPannel/Nutrition/AthleteProfile/AthleteProfileNutrition'
import Nutrition from '../components/RightPannel/Nutrition/Nutrition'
import NutritionLogListing from '../components/RightPannel/Nutrition/NutritionLogListing'
import { NUTRITION_MODULE_ROUTE } from '../utility/constants'
import ProtectedRoute from './ProtectedRoute'

function NutritionRouting() {
  return (
    <Routes>
        <Route exact path={`/${NUTRITION_MODULE_ROUTE}`} element={
            <ProtectedRoute Component={Nutrition}/>
        }  />
         <Route exact path={`/${NUTRITION_MODULE_ROUTE}/:sportId`} element={
            <ProtectedRoute Component={NutritionLogListing}/>
        }  />
         <Route exact path={`/${NUTRITION_MODULE_ROUTE}/:sportId/selectLog`} element={
            <ProtectedRoute Component={NutritionLogListing}/>
        }  />
        <Route exact path={`/${NUTRITION_MODULE_ROUTE}/:sportId/:typeId/:logId`} element={
            <ProtectedRoute Component={AddNewNutritionLog}/>
        }  />
        <Route exact path={`/${NUTRITION_MODULE_ROUTE}/:groupId/:typeId/new`} element={
            <ProtectedRoute Component={AddNewNutritionLog}/>
        }  />
        {/* <Route exact path={`/${NUTRITION_MODULE_ROUTE}/athlete/:sportId/:typeId/:logId`} element={
            <ProtectedRoute Component={AthleteProfileNutrition}/>
        }  /> */}
        <Route exact path={`/${NUTRITION_MODULE_ROUTE}/athlete/:athleteId/:typeId`} element={
            <ProtectedRoute Component={AthleteProfileNutrition}/>
        }  />
         <Route exact path={`/${NUTRITION_MODULE_ROUTE}/athlete/:athleteId/:typeId/:noteId`} element={
            <ProtectedRoute Component={AddNewNutritionLog}/>
        }  />
         <Route exact path={`/${NUTRITION_MODULE_ROUTE}/athlete/:athleteId/:typeId/newNote`} element={
            <ProtectedRoute Component={AddNewNutritionLog}/>
        }  />
    </Routes>
  )
}

export default NutritionRouting