import { createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "../reducer/auth";
import API from "./api";

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchInjruyMasters = createAsyncThunk(
  "injury/fetchInjruyMasters",
  async (data, { dispatch, rejectWithValue }) => {
    try {
        console.log('data', data)
      let bodyParts = await (await getAPIDetails("common/getbodypartmaster")).data;
      let injuryTypes = await (await getAPIDetails("common/getinjurytypemaster")).data;
      let icdInjuryCodes = await (await getAPIDetails("common/geticdinjurycodemaster")).data;
      let injuryDetails 
      if(data?.injuryId){
        injuryDetails = await (await getAPIDetails(`common/getinjurydetails?injuryId=${data?.injuryId}&user_guid=${data?.user_guid}`)).data;
      }
      return { bodyParts, injuryTypes, icdInjuryCodes, injuryDetails };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);


export const fetchInjruyList = createAsyncThunk(
    "injury/fetchInjruyList",
    async ({payload}, { dispatch, rejectWithValue }) => {
      try {
        let injuryList = await (await postAPIDetails("common/getinjurylisting",payload)).data;
        // let injuryTypes = await (await getAPIDetails("common/getinjurytypemaster")).data;
        // let icdInjuryCodes = await (await getAPIDetails("common/geticdinjurycodemaster")).data;
        return { injuryList };
      } catch (error) {
        let statusCode = error?.response?.status;
        if (statusCode === 401) {
          dispatch(authActions.logout());
          error.message = error?.response?.data?.title && error?.response?.data?.title;
          return rejectWithValue({ error });
        } else {
          return rejectWithValue({ error });
        }
      }
    }
  );
export const updateInjruyDetails = createAsyncThunk(
    "injury/updateInjruyDetails",
    async ({payload,injuryId}, { dispatch, rejectWithValue }) => {
      try {
        let athleteInjuryId = await (await postAPIDetails("common/insertupdateinjurydetails", payload)).data;
        let injuryDetails
        if(injuryId){
          injuryDetails = await (await getAPIDetails(`common/getinjurydetails?injuryId=${injuryId}&user_guid=${payload?.user_guid}`)).data;
        }
        return { athleteInjuryId,injuryDetails };
      } catch (error) {
        let statusCode = error?.response?.status;
        if (statusCode === 401) {
          dispatch(authActions.logout());
          error.message = error?.response?.data?.title && error?.response?.data?.title;
          return rejectWithValue({ error });
        } else {
          return rejectWithValue({ error });
        }
      }
    }
  );

  export const getInjruyAthleteList = createAsyncThunk(
    "injury/getInjruyAthleteList",
    async ({payload,injuryId}, { dispatch, rejectWithValue }) => {
      try {
        let athleteList = await (await postAPIDetails("program/getallcoachathletelisting", payload)).data?.data;
        return { athleteList };
      } catch (error) {
        let statusCode = error?.response?.status;
        if (statusCode === 401) {
          dispatch(authActions.logout());
          error.message = error?.response?.data?.title && error?.response?.data?.title;
          return rejectWithValue({ error });
        } else {
          return rejectWithValue({ error });
        }
      }
    }
  );

  export const getInjruyReportLogs = createAsyncThunk(
    "injury/getInjruyReportLogs",
    async ({payload}, { dispatch, rejectWithValue }) => {
      try {
        let injuryLogs = await (await postAPIDetails("common/getinjuryreportlogs", payload)).data?.data?.value;
        return { injuryLogs };
      } catch (error) {
        let statusCode = error?.response?.status;
        if (statusCode === 401) {
          dispatch(authActions.logout());
          error.message = error?.response?.data?.title && error?.response?.data?.title;
          return rejectWithValue({ error });
        } else {
          return rejectWithValue({ error });
        }
      }
    }
  );

  export const sendInjuryLogMsg = createAsyncThunk(
    "injury/sendInjuryLogMsg",
    async ({payload,jsTimeStamp,is_recovered,injuryId, user_guid}, { dispatch, rejectWithValue }) => {
      try {
        let injuryLogMsg = await (await postAPIDetails("common/insertupdateinjuryreportlogs", payload[jsTimeStamp])).data?.data?.value;
        let injuryDetails
        if(is_recovered ){
          injuryDetails = await (await getAPIDetails(`common/getinjurydetails?injuryId=${injuryId}&user_guid=${user_guid}`)).data;
        }
        let newInjuryLogsMsg = injuryLogMsg
        return { newInjuryLogsMsg, injuryDetails };
      } catch (error) {
        let statusCode = error?.response?.status;
        if (statusCode === 401) {
          dispatch(authActions.logout());
          error.message = error?.response?.data?.title && error?.response?.data?.title;
          return rejectWithValue({ error });
        } else {
          return rejectWithValue({ error });
        }
      }
    }
  );
  
  export const deleteInjuryLogMsg = createAsyncThunk(
    "injury/deleteInjuryLogMsg",
    async ({payload}, { dispatch, rejectWithValue }) => {
      try {
        let injuryLogMsg = await (await postAPIDetails("common/insertupdateinjuryreportlogs", payload)).data?.data?.value;
        let newInjuryLogsMsg = injuryLogMsg
        return { newInjuryLogsMsg };
      } catch (error) {
        let statusCode = error?.response?.status;
        if (statusCode === 401) {
          dispatch(authActions.logout());
          error.message = error?.response?.data?.title && error?.response?.data?.title;
          return rejectWithValue({ error });
        } else {
          return rejectWithValue({ error });
        }
      }
    }
  );
