import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTechnicalWeekWiseData } from '../../../../utility/technicalTemplateApiActions';
import Dropdown from '../../Common/Dropdown';

function CalenderDropdown({weeks}) {
    const [selectedWeek, setSelectedWeek] = useState(null);
    const dispatch = useDispatch();
    const {id} = useParams();
    const selectOptions = Array(Number(weeks))?.fill("")?.map((val,idx) => {
        return {
            label: `Week ${idx + 1}`,
            value: idx + 1
        };
    });

    useEffect(() => {
        if(weeks) setSelectedWeek({label: "Week 1", value: 1});
    }, [weeks]);

    const changeHandler = (data) => {
        setSelectedWeek({...data});
        dispatch(
            fetchTechnicalWeekWiseData({
                id,
                week: data?.value
            })
        );
    };
    
    return (
        <div className="si-subHeader">
            <div className="form-control si-calender-week">
                <Dropdown 
                    value={selectedWeek ? selectedWeek : selectOptions?.[0]}
                    options={selectOptions}
                    changeHandler={changeHandler}
                />
            </div>
        </div>
    )
};

export default CalenderDropdown;