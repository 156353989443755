import React from 'react';

function Tablists({ activeTab, setActiveTab, exerciseToEdit, setWarningPopup, tabLists, isViewOnly }) {

    return (
        <div className="si-tab-list">
            <div className={
                    `si-tab-item 
                    ${activeTab === "specs"  ? "active" : ""} 
                    ${!tabLists?.includes("specs") ? "d-none" : ""}`
                }
                onClick={() => setActiveTab("specs")}
            >
                <span className="si-icon-tab icon-edit"></span>
                <span>{isViewOnly ? "specs" : "specs"}</span>
            </div>
            <div className={
                    `si-tab-item 
                    ${activeTab === "recurrence" ? "active" : ""} \
                    ${!tabLists?.includes("recurrence") ? "d-none" : ""}` 
                }
                onClick={() => setActiveTab("recurrence")}
            >
                <span className="si-icon-tab icon-page"></span>
                <span>frequency</span>
            </div>
            <div className={
                    `si-tab-item 
                    ${activeTab === "notes_media" ? "active" : ""} 
                    ${!tabLists?.includes("notes_media") ? "d-none" : ""}`
                }
                onClick={() => setActiveTab("notes_media")}
            >
                <span className="si-icon-tab icon-notes"></span>
                <span>notes &amp; media</span>
            </div>
            <div className={
                    `si-tab-item 
                    ${activeTab === "sports_tags" ? "active" : ""} 
                    ${!tabLists?.includes("sports_tags") ? "d-none" : ""}`
                }
                onClick={() => setActiveTab("sports_tags")}
            >
                <span className="si-icon-tab icon-tags"></span>
                <span>sports &amp; tags</span>
            </div>
        </div>
    );
};

export default Tablists;