import React, { useState, useEffect } from "react";
import PopupFooter from "./PopupFooter";
import RpeSpeedoMeter from "./RpeSpeedoMeter";
import { useSelector } from "react-redux";
import { ATHELETE_ROLE_ID, ATHELET_RPE_MSG, COACH_RPE_MSG } from "../../../utility/constants";
import RpeSpeedoMeterC from "./RpeSpeedoMeterC";

var feebackTooltips = {
  1: {
    color: "#2470a0",
    text: "Very Light Activity",
    desc: "Hardly any exertion, but more than sleeping, watching TV, etc"
  },
  2: {
    color: "#3AF4D5",
    text: "Light Activity",
    desc: "Feels like you can maintain for hours. Easy to breathe and carry a conversation."
  },
  3: {
    color: "#3AF4D5",
    text: "Light Activity",
    desc: "Feels like you can maintain for hours. Easy to breathe and carry a conversation."
  },
  4: {
    color: "#00a03e",
    text: "Moderate Activity",
    desc: "Breathing heavily, can hold a short conversation. Still somewhat comfortable,but becoming noticeably more challenging."
  },
  5: {
    color: "#00a03e",
    text: "Moderate Activity",
    desc: "Breathing heavily, can hold a short conversation. Still somewhat comfortable, but becoming noticeably more challenging."
  },
  6: {
    color: "#00a03e",
    text: "Moderate Activity",
    desc: "Breathing heavily, can hold a short conversation. Still somewhat comfortable, but becoming noticeably more challenging."
  },
  7: {
    color: "#FCBD4E",
    text: "Rigorous Activity",
    desc: "Borderline uncomfortable. Short of breath, can speak a sentence."
  },
  8: {
    color: "#FCBD4E",
    text: "Rigorous Activity",
    desc: "Borderline uncomfortable. Short of breath, can speak a sentence."
  },
  9: {
    color: "#dd5f32",
    text: "Very Hard Activity",
    desc: " Very difficult to maintain exercise intensity. Can barely breathe and speak only a few words."
  },
  10: {
    color: "#e74700",
    text: "Max Effort Activity",
    desc: "Feels almost impossible to keep going. Completely out of breath and unable to talk. Cannot maintain a more than a very short time."
  }
};

function FeedbackPopup({isActive,isEditable,isReason,id,isActionBtn,actionBtnText,actionBtnHandler,closeHandler}) {
  const [selectedId, setSelectedId] = useState(0);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const userData = useSelector(state => state?.auth?.userData);
  let headerText = userData?.role_id == ATHELETE_ROLE_ID ? ATHELET_RPE_MSG : COACH_RPE_MSG;
  useEffect(() => {
      setSelectedId(id);
    },[isActive, id]);
 

  const selectSaveData = (id) => {
    setSelectedId(id);
    if (id != selectedId);
    setIsBtnDisabled(false);
  };
  // for mobile or ios scroll fix
  window.scrollTo(0,0)
  console.log(isActive, selectedId, "myConsole");
 
  return (
    <div className={`si-popup ${isActive ? "active" : ""}`}>
      <div className="rpe-popup">
        <div className="rpe-popup__header">
          <p className="heading">{headerText}</p>
          <button
            type="button"
            className="si-icon__button si-button__close"
            onClick={() => { closeHandler(); setIsBtnDisabled(true)}}
          >
            <span className="icon-close" />
          </button>
        </div>
        
        <div className="tooltip-wrapper">
        {selectedId
          ? 
              <span className="rpe-popup__tooltip">
                {feebackTooltips[selectedId].text}
              </span>
          : ""}
          </div>

        <div className="rpe-popup__wrapper">
          {
            isActive && selectedId !==undefined && 
            // <RpeSpeedoMeter 
            //   isEditable={isEditable}
            //   feebackTooltips={feebackTooltips}
            //   selectedId={selectedId}
            //   selectSaveData={selectSaveData}
            // />
            <RpeSpeedoMeterC 
              isEditable={isEditable}
              feebackTooltips={feebackTooltips}
              selectedId={selectedId}
              selectSaveData={selectSaveData}
            />
          }
        </div>
        <p className="rpe-popup__desc">
          {selectedId ? feebackTooltips[selectedId].desc : ""}
        </p>
        {
          isEditable && 
          <button
            className="si-button si-button--green si-icon--add"
            disabled={!selectedId || isBtnDisabled}
            onClick={() => { actionBtnHandler(selectedId); setIsBtnDisabled(true);}}
          >
            {actionBtnText}
          </button>
        }
      </div>
    </div>
  );
}

export default FeedbackPopup;
