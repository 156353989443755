import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { deleteEvents, fetchEventType, fetchGenderList, fetchSportsList, getEventLists, updateEvents } from "../utility/tournamentEventsApiAction"

const initialState = {
    eventsList: null,
    paginationObject: null,
    sportList: null,
    genderList:null,
    eventTypeList:null,
    selectedEvent:null,
    updateEventLoading:false,
    updateEventSuccessful:false,
    allGenderList:null,
    eventErrorMsg:null,
}

const EventsListingSlice = createSlice({
    name:"EventsListing",
    initialState,
    reducers:{
        setUpdateEventSuccessfulFalse : (state, action) =>{
            state.updateEventSuccessful = false
        },
        resetEventErrorMsg : (state, action)=>{
            state.eventErrorMsg  = false
            return state
        }
    },
    extraReducers:{
        [fetchSportsList.pending] : (state, action)=>{
            
        },
        [fetchSportsList.fulfilled]:(state, action)=>{
            state.sportList = action?.payload?.data?.data?.value?.sport;
        },
        [fetchSportsList.rejected] : (state, action) =>{
            toast?.error(`Unable to fetch sport list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching sport list: ", action?.error);
        },
        [fetchGenderList.pending] : (state, action)=>{
            
        },
        [fetchGenderList.fulfilled]:(state, action)=>{
            state.allGenderList = action?.payload?.data?.data?.value?.data;
        },
        [fetchGenderList.rejected] : (state, action) =>{
            toast?.error(`Unable to fetch gender list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching gender list: ", action?.error);
        },
        [fetchEventType.pending] : (state, action)=>{
            
        },
        [fetchEventType.fulfilled]:(state, action)=>{
            state.eventTypes = action?.payload?.data?.data?.value?.data;
        },
        [fetchEventType.rejected] : (state, action) =>{
            toast?.error(`Unable to fetch event types. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching event types: ", action?.error);
        },
        [getEventLists.pending] : (state,action)=>{
            state.updateEventLoading = true
            return state
        },
        [getEventLists.fulfilled] : (state,action)=>{
            state.eventsList =  action?.payload?.data?.data?.value?.data
            state.paginationObject = action?.payload?.data?.data?.value?.pagination
            state.genderList = action?.payload?.data?.data?.value?.gender
            state.updateEventLoading = false
            return state
        },
        [getEventLists.rejected] : (state,action) => {
            toast?.error(`Unable to fetch events. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching events list: ", action?.error);
            state.updateEventLoading = false
            return state
        },
        [updateEvents.pending]:(state, action) => {
           state.updateEventLoading = true
           state.updateEventSuccessful = false
           return state
        },
        [updateEvents.fulfilled]:(state,action) =>{
            if(action?.payload?.data?.status === 201){
                state.eventErrorMsg = action?.payload?.data?.detail
                state.updateEventLoading = false
            } else {
            state.updateEventLoading = false
            state.updateEventSuccessful = true
            }
            return state
        },
        [updateEvents.rejected]:(state,action) =>{
            state.updateEventLoading = false
            state.updateEventSuccessful = false
            if(action.meta.arg.id){
                toast?.error(`Unable to update event. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
                console.log("Error while updating event: ", action?.error);
            }else {
                toast?.error(`Unable to add event. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
                console.log("Error while updating event: ", action?.error);
            }
        },
        [deleteEvents.pending] :(state)=>{
            state.updateEventLoading = true
            return state
        },
        [deleteEvents.fulfilled] :(state)=>{
            state.updateEventLoading = false
            state.updateEventSuccessful = true
            return state
        },
        [deleteEvents.rejected] : (state,action)=>{
            state.updateEventLoading = false
                toast?.error(`Unable to delete event. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
                console.log("Error while updating Event: ", action?.error);
        }
    }
})

export const { setUpdateEventSuccessfulFalse,resetEventErrorMsg } = EventsListingSlice.actions
export default EventsListingSlice.reducer