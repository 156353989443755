import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loaderActions } from '../../../../reducer/loader';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import {TableBody, TableRow, TableCell} from '@mui/material';
import ListingTable from '../../Common/ListingTableMUI';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import { deleteTechnicalTemplateById,fetchTechnicalTemplateList } from '../../../../utility/technicalTemplateApiActions';

function Content({ pageNo, setPageNo, pagination, perPage, searchTerm, selectedSports, setIsFiltered}) {
    const templateListing = useSelector(state => state?.technicalTemplates?.templateListing);
    const selectedMenuId = useSelector(state => state?.sideMenu?.selectedMenuId);
    const [isActionActive, setIsActionActive] = useState(Array(templateListing?.length)?.fill(false));
    const [warningPopup, setWarningPopup] = useState({isShow: false});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window?.addEventListener("click", handleKeydownEvent);

        return () => window?.removeEventListener("click", handleKeydownEvent);
    }, []);

    useEffect(() => {
        setIsActionActive(Array(templateListing?.length)?.fill(false));
    }, [templateListing]);

    const handleKeydownEvent = (event) => {
        setIsActionActive(Array(templateListing?.length)?.fill(false));
    };

    const handleAction = (e, idx) => {
        e?.stopPropagation();
        setIsActionActive(prevState => {
            let newState = Array(templateListing?.length)?.fill(false);
            newState[idx] = !prevState[idx];
            return [ ...newState ];
        });
    };

    const handleDelete = () => {
        dispatch(loaderActions?.showLoader());
        dispatch(
            deleteTechnicalTemplateById({
                id: warningPopup?.templateId,
                callback: () => {
                    setTimeout(() => {
                        dispatch(
                            fetchTechnicalTemplateList({
                                pageNo,
                                searchTerm,
                                selectedSports,
                                moduleId:selectedMenuId?.id
                            })
                        );
                        dispatch(loaderActions?.hideLoader());
                        setIsFiltered(false);
                        setWarningPopup({isShow: false});
                        toast.success("Template deleted successfully.", {toastId: "template_delete_success"});
                    });
                }
            })
        );
    };

    const handleEdit = (e, id) => {
        e?.stopPropagation();
        if(id){
            navigate(`/technical/templates/${id}/calendar`);
        }else{
            toast.warn("Something went wrong");
        }
    };

    const handleDeleteClick = (e, id) => {
        e?.stopPropagation();
        setWarningPopup(({isShow: true, templateId: id}))
    };

    const handlePopupCancel = () => {
        setWarningPopup({isShow: false});
        setIsActionActive(Array(templateListing?.length)?.fill(false));
    };

    const tableHeaders = [
        {
            label: "Template Name",
            align: "left"
        },
        {
            label: "Sports",
            align: "center"
        },
        {
            label: "Duration",
            align: "center"
        },
        {
            label: "Created By",
            align: "center"
        },
        {
            label: "",
            align: "center",
            stickyClass: "stickyCell-right" 
        }
    ];

    const getTableBody = () => {
        return (
            <TableBody sx={{backgroundColor:"#181818"}}>
                {
                    templateListing?.length
                    ?
                    templateListing?.map((template, idx) => {
                        return (
                            <TableRow 
                                key={uuidv4()}
                            >
                                <TableCell 
                                    align={"left"} sx={{color:"#fff", cursor: "pointer", minWidth: 150}}
                                    onClick={(e) => handleEdit(e, template.program_templateid)}
                                >
                                    {template?.program_template_name}
                                </TableCell>
                                <TableCell align={"center"} sx={{color: "#fff"}}>
                                    {template?.sport_name}
                                </TableCell>
                                <TableCell align={"center"} sx={{color: "#fff"}}>
                                    { 
                                        `${template?.duration > 1 
                                        ? (template?.duration + " Weeks") 
                                        : (template?.duration + " Week") }` 
                                    }
                                </TableCell>
                                <TableCell align={"center"} sx={{color: "#fff"}}>
                                    Admin
                                </TableCell>
                                <TableCell align={"center"} sx={{color: "#fff"}} className="stickyCell-right">
                                    <div className="si-custom-table__cell si-wrap" style={{margin:"auto"}}>
                                        <button 
                                            type="button" 
                                            className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                                            onClick={(e) => handleAction(e, idx)}
                                        ></button>
                                        <div className="si-action-icons">
                                            <button 
                                                type="button" 
                                                className="si-icon-button"
                                                onClick={(e) => handleEdit(e, template.program_templateid)}
                                            >
                                                <span className="icon-edit"></span>edit
                                            </button>
                                            <button 
                                                type="button" 
                                                className="si-icon-button"
                                                onClick={(e) => handleDeleteClick(e, template?.program_templateid)}
                                            >
                                                <span className="icon-delete"></span>delete
                                            </button>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={5} sx={{color: "#fff"}}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };


    return (
        <div className="si-main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={pagination}
                perPage={perPage}
            />

            <ConfirmationPopup 
                isActive={warningPopup?.isShow}
                isActionBtns={true}
                warning={"Are you sure you want to delete this template?"}
                okHandler={handleDelete}
                cancelHandler={handlePopupCancel}
            />
        </div>
    );
};

export default memo(Content);