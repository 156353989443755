import { Fragment, useEffect, useState } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  redirect
} from "react-router-dom";
import UserProfile from "./components/UserProfile";
import Auth from "./components/Auth";
import Home from "./components/Home";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { authActions } from "./reducer/auth";
import Routing from "./routes/Routing";
import ProtectedRoute from "./routes/ProtectedRoute";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  console.log(isAuth);

  const checkUserToken = () => {
    let data = Cookies.get("userdetails");
    let userDetails = data ? JSON.parse(decodeURIComponent(data)) : "";
    console.log(userDetails?.Value?.user_info);
    if (userDetails?.Value?.user_info) {
      dispatch(authActions.login({ data: userDetails?.Value?.user_info }));
    } else {
      dispatch(authActions.logout());
    }
  };
  useEffect(() => {
    checkUserToken();
  }, []);

  return (
    <div className="si-main">
      <div className="si-wrapper">
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          // style={{textTransform:"capitalize"}}
        />
        <Router>
          {!isAuth && !Cookies.get("userdetails") ? (
            <Routes>
              <Route exact path="/login" element={<Auth />} />
              <Route exact path="/login/:id" element={<Auth />} />
              <Route exact path="/" element={<Auth />} />
              {/* Redirect to login if logout */}
              <Route path="/*" element={<Navigate to="/login" replace />} />
              {/* /**For future use */}
              {/* <Route
                exact
                path="/"
                element={
                  <ProtectedRoute Component={Dashboard} />
                }
              /> */}
            </Routes>
          ) : (
            <ProtectedRoute Component={Home} />
          )}
          {/* {!isAuth && <Auth/>}  */}
        </Router>
      </div>
    </div>
  );
}

export default App;
