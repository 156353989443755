import { createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "../reducer/auth";
import API from './api';

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const createProgramByTemplateId = createAsyncThunk("program/createProgramByTemplateId", async (data, {getState, rejectWithValue, dispatch}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let userId = userData ? userData?.user_id : null;
    let userguid = userData ? userData?.user_guid : null;
    let {templateId, callback} = data;
    console.log(data);
    try{
        return {
            callback,
            apiResponse: await getAPIDetails(`program/getcoachprogramtemplatedetail?p_template_id=${templateId}&user_guid=${userguid}`)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchProgramsList = createAsyncThunk("program/fetchProgramList", async (data, {getState,rejectWithValue,dispatch}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let { pageNo,activeTab, searchTerm, selectedSports, callback } = data;
    let body = {
        "page_no": pageNo,
        "item_number": 20,
        "type": activeTab ? activeTab : 1,/**0-all , 1- currently active ,2-upcoming, 3-completed */
        "search": searchTerm,
        // "user_id": userData ? userData?.user_id : null,
        "user_guid": userData ? userData?.user_guid : null,
        "sport_id": selectedSports?.length ? selectedSports : null
    };

    try {
        return {
            callback,
            activeTab,
            apiResponse: await postAPIDetails("program/getprogramlisting", body)
        };  
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };

});

export const fetchAthletesList = createAsyncThunk("program/fetchAthletesList", async (data, {getState, rejectWithValue,dispatch}) => {
    let state = getState();
    let {pageNo, searchTerm, programId,userId, selectedSports, userguid ,groupFilter,callback} = data;

    let body = {
        "page_no": pageNo,
        "item_number": 20,
        "search": searchTerm,
        // "user_id": userId, /**For all athlete irrespective of user id */
        "user_guid": userguid, /**For all athlete irrespective of user guid */
        "program_id": programId,
        "sport_id": selectedSports?.length ? selectedSports : null,
        "group_filter": groupFilter?.length ? groupFilter : null
    };
    try {
        return {
            callback,
            apiResponse: await postAPIDetails("program/getatheletlisting", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchCoachAthletesList = createAsyncThunk("program/fetchCoachAthletesList", async (data, {getState, rejectWithValue, dispatch}) => {
    let state = getState();
    let {pageNo, searchTerm, selectedSports, userguid ,groupFilter, type, callback} = data;
    let body = {
        "page_no": pageNo,
        "item_number": 20,
        "search": searchTerm,
        "user_guid": userguid, 
        "sport_id": selectedSports?.length ? selectedSports : null,
        "group_filter": groupFilter?.length ? groupFilter : null,
        "opt_type": type
    };
    try {
        return {
            callback,
            apiResponse: await postAPIDetails("program/getallcoachathletelisting", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchGroupListing = createAsyncThunk("programs/fetchGroupListing", async (data, {rejectWithValue,dispatch}) => {
    try {
        return await getAPIDetails("program/getgrouplisting");
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({ error})
        } else {
            return rejectWithValue({ error});
        }
    };
});

export const fetchProgramAthletesList = createAsyncThunk("program/fetchProgramAthletesList", async (data, {getState, rejectWithValue,dispatch}) => {
    let state = getState();
    // let userData = state?.auth?.userData;
    let {pageNo, searchTerm, programId, userId, selectedSports, userguid,groupFilter ,callback} = data;

    let body = {
        "page_no": pageNo,
        "item_number": 20,
        "search": searchTerm,
        // "user_id": userData ? userData?.user_id : null,
        // "user_id": userId, /**For all athlete irrespective of user id */
        "user_guid": userguid, /**For all athlete irrespective of user guid */
        "program_id": programId,
        "sport_id": selectedSports?.length ? selectedSports : null,
        "group_filter": groupFilter?.length ? groupFilter : null
    };
    try {
        return {
            callback,
            apiResponse: await postAPIDetails("program/getatheletlisting", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const deleteAthleteFromProgram = createAsyncThunk("program/deleteAthleteFromProgram", async (data, {getState, rejectWithValue, dispatch}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData ? userData?.user_guid : null;
    let {athleteId, programId, callback} = data;
    try {
        return {
            callback,
            apiResponse: await getAPIDetails(`program/deleteatheletefromprogram?p_athlete_id=${athleteId}&p_program_id=${programId}&p_user_guid=${userguid}`)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const deleteProgram = createAsyncThunk("program/deleteProgram", async ({id, callback}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData ? userData?.user_guid : null;
    try {
        return {
            callback,
            apiResponse: await getAPIDetails(`program/deleteprogram?p_program_id=${id}&user_guid=${userguid}`)
        };
    } catch (error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
}); 

export const updateProgramDetails = createAsyncThunk("program/insertupdateprogramdetails", async (data, {getState, rejectWithValue, dispatch}) => {
    let {programId,name, duration,startDate,endDate ,moduleId, sportId,assignedAthletes, callback, isPublish} = data;
    let state = getState();
    // name = optType === 1 ? state?.templates?.programName : name;
    // duration = optType === 1 ? state?.templates?.duration : duration;
    let userData = state?.auth?.userData;
    try {
        let body = {
            // "user_id": userData ? userData?.user_id : null,
            "user_guid": userData ? userData?.user_guid : null,
            "sport_id": sportId,
            "program_id": programId,
            "program_name": name.trim(),
            "start_date": startDate ? startDate : null,
            "end_date": endDate ? endDate : null,
            "program_duration": duration,
            "schedule_type_id": 0,
            "assigned_athlete_ids": assignedAthletes?.length ? assignedAthletes : null,
            "is_publish": isPublish
          };
          console.log(body);
        return {
            callback,
            apiResponse: await postAPIDetails("program/insertupdateprogramdetails", body)
        }; 
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchProgramDetailsById = createAsyncThunk("templates/fetchProgramDetails", async ({id,type ,callback}, {getState,dispatch, rejectWithValue}) => {
    // dispatch(loaderActions?.showLoader());
    let state = getState();
    console.log(state);
    let userData = state?.auth?.userData;
    let userguid = userData ? userData?.user_guid : null;
    try {
        return {
            callback,
            apiResponse: await getAPIDetails(`program/getprogramtemplatedetails?programid=${id}&p_opt_type=${type}&user_guid=${userguid}`)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchWeekWiseProgramCalendarData = createAsyncThunk("programs/fetchWeekWiseProgramCalendarData", async ({id, week, callback}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            week,
            callback,
            apiResponse: await getAPIDetails(`program/getcoachprogramcalendardetails?p_program_id=${id}&p_week_id=${week}&user_guid=${userData?.user_guid}`)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchWholeProgramCalendarData = createAsyncThunk("programs/fetchWholeProgramCalendarData", async ({callback, id}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;

    try{
        if(state?.programs?.selectedProgram) {
            return {
                callback,
                apiResponse: await getAPIDetails(`program/getcoachprogramcalendardetails?p_program_id=${id}&user_guid=${userData?.user_guid}`)
            };
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchAthletePrograms = createAsyncThunk("programs/fetchAthletePrograms", async (data, {getState, rejectWithValue, dispatch}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let { pageNo, searchTerm, activeTab, userguid, callback } = data;
    try {
        let body = {
            "page_no": pageNo,
            // "user_id": userData ? userData?.user_id : null,
            "user_guid": userguid ? userguid : null,
            "item_number": 20,
            "search": searchTerm,
            "type": activeTab ? activeTab : 0
        };
        return {
            callback,
            apiResponse: await postAPIDetails("program/getatheletprogramprogresslisting", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchWeekWiseProgressCalendarData = createAsyncThunk("programs/fetchWeekwiseProgressCalendarData", async (data, {getState,rejectWithValue, dispatch}) => {
    let {week, id, userId, callback} = data;
    let state = getState();
    let userData = state?.auth?.userData;
    try {
        let body = {
            "week_no": week,
            "user_guid": userData ? userData?.user_guid : null,
            "user_id": userId ? userId : null,
            "program_id": id
        };

        return {
            week,
            callback,
            apiResponse: await postAPIDetails("program/getatheletprogramscheduledetail", body)
        };
    }
    catch (error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const inserUpdateAthleteNotes = createAsyncThunk("programs/inserUpdateAthleteNotes", async (data, {getState,rejectWithValue, dispatch}) => {
    let { optType, week, day, notes, noteId, reviewId, programId, userId, callback } = data;
    let state = getState();
    let userData = state?.auth?.userData;
    try {
        let body = {
            "athlete_notes": {
                "opt_type": optType,
                "user_guid": userData ? userData?.user_guid : null,
                "week_no": week,
                "day_no": day,
                "note_id": noteId ? noteId : null,
                "notes": notes,
                "review_id": reviewId ? reviewId : null,
                "program_id": programId
            }
        };
        return {
            callback,
            apiResponse: await postAPIDetails("program/insertupdateathleteprogressnotes", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchAthleteNotes = createAsyncThunk("programs/fetchAthleteNotes", async (data, {getState,rejectWithValue, dispatch}) => {
    let { userId, programId, week, day, callback } = data;
    let state = getState();
    let userData = state?.auth?.userData;
    try {
        let body = {
            "program_id": programId,
            "user_guid": userData ? userData?.user_guid : null,
            "user_id": userId ? userId : null,
            "week_no": week,
            "day_no": day
        };
        return {
            callback,
            apiResponse: await postAPIDetails("program/getatheletprogressnotes", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const insertUpdateAthleteProgress = createAsyncThunk("programs/insertUpdateAthleteProgress", async (data, {getState,rejectWithValue, dispatch}) => {
    let {optType, programId, userId, scheduleId, progressValue, callback} = data;
    let state = getState();
    let userData = state?.auth?.userData;
    try {
        let body = {
            "athlete_progress": {
              "opt_type": optType,
              "program_id": programId,
              "user_guid": userData ? userData?.user_guid : null,
              "program_scheduleid": scheduleId,
              "progress_value": progressValue
            }
        };
        return {
            callback,
            apiResponse: await postAPIDetails("program/insertupdateathleteprogramprogress", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});