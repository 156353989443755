import { createAsyncThunk } from '@reduxjs/toolkit';
import { authActions } from '../reducer/auth';
import API from './api';
import { loaderActions } from '../reducer/loader';

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchTechnicalTemplateList = createAsyncThunk("templates/fetchTechnicalTemplates", async (data, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    
    let body = {
        "page_no": data?.pageNo,
        "search": data?.searchTerm,
        "item_number": 20,
        "module_id": data?.moduleId,
        // "user_id": userData ? userData?.user_id : null,
        "user_guid": userData.user_guid,
        "sport_id": data?.selectedSports
    };
    try{
        return {
            callback: data?.callback,
            apiResponse: await postAPIDetails("template/gettechnicaltemplatelisting", body)
        }
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({ error})
        } else {
            return rejectWithValue({ error});
        }
    }
});

export const postTechnicalProgramTemplate = createAsyncThunk("templates/postTechnicalTemplate", async (data, {getState, dispatch, rejectWithValue}) => {
    let {optType, name, duration ,moduleId, sportId, callback, isNewTemplate, isPublish} = data;
    let state = getState();
    name = optType === 1 ? state?.technicalTemplates?.programName : name;
    duration = optType === 1 ? state?.technicalTemplates?.duration : duration;
    let userData = state?.auth?.userData;
    let selectedSportId = optType === 1 ? state?.technicalTemplates?.selectedSportId : sportId;
    let programId = optType === 1 ? 0 : state?.technicalTemplates?.selectedTemplate?.program_templateid;
    
   
    let body = {
        "template":{
            "opt_type": optType,
            "module_id": moduleId,
            // "user_id": userData ? userData?.user_id : null,
            "user_guid": userData ? userData?.user_guid : null,
            "sport_id": selectedSportId,
            "program_name": name.trim(),
            "duration": duration,
            "program_id": programId,
            "publish": isPublish
        }
    };
    try{
        return {
            callback,
            isNewTemplate,
            apiResponse: await postAPIDetails("template/insertupdatetechnicaltemplate", body)
        }; 
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchTechnicalProgramTemplateDetailsById = createAsyncThunk("templates/fetchTechnicalTemplate", async ({id,type ,callback}, {getState,dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            callback,
            apiResponse: await getAPIDetails(`template/gettechnicaltemplatedetails?templateid=${id}&opttype=${type}&user_guid=${userData?.user_guid}`)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const deleteTechnicalTemplateById = createAsyncThunk("templates/deleteTechnicalTemplateById", async ({id, callback}, {getState,dispatch,rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            callback,
            apiResponse: await postAPIDetails(`template/deletetechnicaltemplatebyid?template_id=${id}&user_guid=${userData.user_guid}`)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchTechnicalExerciseMaster = createAsyncThunk("templates/fetchExerciseMaster", async (data, {dispatch,rejectWithValue}) => {
    
    let body = {
        "page_no": data?.pageNo,
        "module_id": data?.moduleId,
        "item_number": 20,
        "name": data?.searchTerm,
        "tags": data?.selectedTag?.length ? data?.selectedTag : null,
        "sport_id": data?.selectedSportIds?.length ? data?.selectedSportIds : null
    };
    try{
        return {
            callback: data?.callback,
            apiResponse: await postAPIDetails("exercise/gettechnicalexercisemasters", body)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback:data?.callback, error})
        } else {
            return rejectWithValue({callback:data?.callback, error});
        }
    }
});

export const fetchTechnicalExerciseMasterFilter = createAsyncThunk("templates/fetchExerciseMasterFilter", async (data, {dispatch,rejectWithValue}) => {
    let body = {
        "page_no": data?.pageNo,
        "item_number": data?.perPage,
        "module_id": data?.moduleId,
        "name": data?.searchTerm,
        "tags": data?.selectedTag?.length ? data?.selectedTag : null,
        "sport_id": data?.selectedSportIds?.length ? data?.selectedSportIds : null
    };
    try{
        return {
            callback: data?.callback,
            apiResponse: await postAPIDetails("exercise/gettechnicalexercisemasters", body)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback:data?.callback, error})
        } else {
            return rejectWithValue({callback:data?.callback, error});
        }
    }
});

export const fetchTechnicalExerciseDetails = createAsyncThunk("template/fetchTechnicalExerciseDetails", async ({id, type, callback}, {dispatch, rejectWithValue, getState}) => {
    try{
        let state = getState();
        let userData = state?.auth?.userData;
        return {
            callback,
            apiResponse: await getAPIDetails(`exercise/gettechnicalexercisedetails?id=${id}&type=${type}&user_guid=${userData?.user_guid}`)
        };
    }catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const postTechnicalTemplateExercises = createAsyncThunk("templates/postTechnicalTemplateExercises", async ({type, id, callback}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let selectedExerciseList = type===2 ? state?.technicalPrograms?.selectedExerciseList : state?.technicalTemplates?.selectedExerciseList;
    if(selectedExerciseList?.length) {
        // update description key value into exercise_desc key 
        selectedExerciseList = selectedExerciseList.map((exercies)=>{
            if(exercies.description){
                return {...exercies, exercise_desc:exercies.description}
            } else {
                return exercies
            }
        })
        let body = {
            "exercies": selectedExerciseList
        };
        try{
            return {
                callback,
                apiResponse: await postAPIDetails(`template/insertupdatetechnicaltemplateschedule?type=${type}&id=${id}&user_guid=${userData.user_guid}`, body)
            };
        } catch(error){
            dispatch(loaderActions?.hideLoader());
            let statusCode = error?.response?.status
            if(statusCode === 401){
                dispatch(authActions.logout())
                error.message = error?.response?.data?.title && error?.response?.data?.title
                return rejectWithValue({callback, error})
            } else {
                return rejectWithValue({callback, error});
            }
        }
    };
});

export const fetchTechnicalWholeCalendarData = createAsyncThunk("templates/fetchTechnicalWholeCalendarData", async ({callback, id}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    
    try{
        if(state?.technicalTemplates?.selectedTemplate) {
            return {
                callback,
                apiResponse: await getAPIDetails(`template/gettechnicaltemplatescheduledetail?templateid=${id}&user_guid=${userData.user_guid}`)
            };
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchTechnicalWeekWiseData = createAsyncThunk("templates/fetchTechnicalWeekWiseData", async ({id, week, callback}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            week,
            callback,
            apiResponse: await getAPIDetails(`template/gettechnicaltemplatescheduledetail?templateid=${id}&weekid=${week}&user_guid=${userData.user_guid}`)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchTemoList = createAsyncThunk("templates/fetchTempoList", async (data, {dispatch,rejectWithValue}) => {
    try{
        return await getAPIDetails("program/gettempolisting");
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({ error})
        } else {
            return rejectWithValue({ error});
        }
    }
});

export const fetchUnitsList = createAsyncThunk("templates/fetchUnitsList", async (data, {dispatch,rejectWithValue}) => {
    try{
        return await getAPIDetails("program/getunitlisting");
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({error})
        } else {
            return rejectWithValue({error});
        }
    }
});

export const fetchSportsList = createAsyncThunk("templates/fetchSportsList", async (data, {dispatch, rejectWithValue}) => {
    try{
        return await getAPIDetails("exercise/getsportslisting");
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({error})
        } else {
            return rejectWithValue({error});
        }
    }
});

export const fetchTechnicalTagsList = createAsyncThunk("templates/fetchTechnicalTagsList", async (sportsIds, {dispatch, rejectWithValue}) => {
    
    let body = {
        "sport_id": sportsIds?.length ? sportsIds : null
    };
    try{
        return await postAPIDetails("exercise/gettechnicaltaglisting", body);
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({error})
        } else {
            return rejectWithValue({error});
        }
    }
});

export const publishTechnicalCalendarSequence = createAsyncThunk("templates/publishTechnicalSequence", async ({id, type, callback}, {getState,dispatch,rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    /**type:1 - Template , type:2 - Program */
    let wholeCalendarData = type===2 ? state?.technicalPrograms?.wholeProgramCalendarData : state?.technicalTemplates?.wholeCalendarData;
    let newWholeCalendarData = wholeCalendarData?.map(weekObj => {
        let daysData = weekObj?.days?.map(dayObj => {
            let newExercises = dayObj?.exercises?.map((obj, idx) => {
                let isRecurrence = obj?.recurrence?.lenght > 1;
                return {
                    "id": obj?.id,
                    "sequence_no": !isRecurrence ? idx + 1 : 0
                };
            });
            return { ...dayObj, exercises: newExercises?.length ? newExercises : [] };
        });
        return { ...weekObj, days: daysData };
    });

    let body = {
        "calendar": newWholeCalendarData
    };
    try{
        return {
            callback,
            apiResponse: await postAPIDetails(`template/publishtechnicaltemplateorprogram?type=${type}&id=${id}&user_guid=${userData.user_guid}`, body)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});