import { Radio, TableBody, TableCell, TableRow } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListingTable from '../Common/ListingTableMUI';
export default function SelectAthleteContent({ pageNo, setPageNo, pagination, perPage,selectedAthlete,setSelectedAthlete }) {
    const athleteList = useSelector((state) => state?.programs?.athleteListing);
    useEffect(()=>{
        console.log("athleteList:",athleteList);
    },[athleteList])
   
    const handleIndividualSelect = (e, athlete) => {
        e?.stopPropagation();
        setSelectedAthlete({
            ...athlete,
        });
    };

    const tableHeaders = [
        {
            label: "",
            align: "center",
            stickyClass: "stickyCell-left",
        },
        {
            label: "Athlete Name",
            align: "left",
        },
        {
            label: "Sport",
            align: "left",
        },
        {
            label: "Group",
            align: "left",
        },
    ];
    const getTableBody = () => {
        return (
            <TableBody>
                {athleteList?.length ? (
                    athleteList?.map((athlete, idx) => {
                        let name = `${athlete?.first_name ? athlete?.first_name : ""} ${athlete?.last_name ? athlete?.last_name : ""
                            }`;
                        let sports = athlete?.sport_name;
                        let sportsCount = sports?.length;
                        let sportName = sportsCount > 2 ? `${sports?.slice(0, 2)?.join(", ")}` : sports?.join(", ");
                        let otherCount = sportsCount > 2 ? `+${sportsCount - 2}` : "";
                        let group = athlete?.group_name;
                        let groupCount = group?.length;
                        let groupName = groupCount > 2 ? `${group?.slice(0, 2)?.join(", ")}` : group?.join(", ");
                        let groupOtherCount = groupCount > 2 ? `+${groupCount - 2}` : "";
                        return (
                            <TableRow
                                key={idx}
                                selected={selectedAthlete ? selectedAthlete.athlete_id === athlete.athlete_id : false}
                            >
                                <TableCell padding="checkbox" className="stickyCell-left">
                                    <Radio
                                        name="radio-buttons"
                                        checked={selectedAthlete ? selectedAthlete.athlete_id === athlete.athlete_id : false}
                                        onClick={(e) => handleIndividualSelect(e, athlete)}
                                    />
                                </TableCell>
                                <TableCell
                                    align={"left"}
                                    sx={{ cursor: "pointer" }}
                                    onClick={(e) => handleIndividualSelect(e, athlete)}
                                >
                                    {name ? name : ""}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {sportName ? sportName : "-"}
                                    {otherCount && <span className="si-custom-table si-count">{otherCount}</span>}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {groupName ? groupName : "-"}
                                    {groupOtherCount && <span className="si-custom-table si-count">{groupOtherCount}</span>}
                                </TableCell>
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={tableHeaders.length}>
                            No data found
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        );
    };
    return (
        <Fragment>
            <div className="si-main-content">
                <ListingTable
                    tableHeaders={tableHeaders}
                    getTableBody={getTableBody}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    pagination={pagination}
                    perPage={perPage}
                />
            </div>
        </Fragment>
    )
}
