import React, { useEffect, memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ATHLETES_MODULE_ID, HOME_MODULE_ID, SNC_MODULE_ID, TECHNICAL_MODULE_ID, TEST_AND_ASSESSMENT_MODULE_ID, PROFIE_MODULE_ID } from '../../utility/constants';
import MainMenu from '../LeftPannel/MainMenu';
import { sideMenuActions } from '../../reducer/sidemenu';
import { templatesActions } from '../../reducer/templates';

function Logo() { 
  const dispatch = useDispatch();
  const isActiveOnMob = useSelector(state=> state.templates.isActiveOnMob)

  function logoClickHandler() {
    if(isActiveOnMob){
      dispatch(templatesActions?.setIsActiveOnMob());
    }
    dispatch(sideMenuActions?.setHomeRedirect(true));
  }

  return (
    <div className="si-header">
      <div className="si-left" onClick={() => logoClickHandler()} style={{ cursor: "pointer" }}>

        <div className="si-Logo si-desklogo">
          <img src="../../images/Logo.png" />
        </div>

        <div className="si-Logo si-mobilelogo">
          <img src="../../images/mobileLogo.png" />
        </div>

      </div>
    </div>
  );
};

export default Logo;