import React from 'react'
import { Route, Routes } from 'react-router'
import InjuryDetails from '../components/RightPannel/InjuryTracker/InjuryDetails'
import InjuryListing from '../components/RightPannel/InjuryTracker/InjuryListing'
import InjuryReportLogDetails from '../components/RightPannel/InjuryTracker/InjuryReportLogDetails'
import SelectAthlete from '../components/RightPannel/InjuryTracker/SelectAthlete'
import { INJURY_MODULE_ROUTE } from '../utility/constants'
import ProtectedRoute from './ProtectedRoute'

function InjuryRoutes() {
  return (
    <Routes>
        <Route exact path={`/${INJURY_MODULE_ROUTE}`} element={
            <ProtectedRoute Component={InjuryListing}/>
        }  />
        <Route exact path={`/${INJURY_MODULE_ROUTE}/athletes`} element={
            <ProtectedRoute Component={SelectAthlete}/>
        }  />
        <Route exact path={`/${INJURY_MODULE_ROUTE}/athletes/:athleteId`} element={
            <ProtectedRoute Component={InjuryDetails}/>
        }  />
        <Route exact path={`/${INJURY_MODULE_ROUTE}/details`} element={
            <ProtectedRoute Component={InjuryDetails}/>
        }  />
         <Route exact path={`/${INJURY_MODULE_ROUTE}/edit/:injuryId`} element={
            <ProtectedRoute Component={InjuryDetails}/>
        }  />
        <Route exact path={`/${INJURY_MODULE_ROUTE}/details/:injuryId`} element={
            <ProtectedRoute Component={InjuryReportLogDetails}/>
        }  />
    </Routes>

  )
}

export default InjuryRoutes