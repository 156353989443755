import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { resetEventErrorMsg } from "../../../../reducer/tournamentsEvent.reducer";
import { updateEvents } from "../../../../utility/tournamentEventsApiAction";
import Dropdown from "../../Common/Dropdown";

function EventListingAddNew() {
  const navigate = useNavigate();
  let [tournamentObj, setTournament] = useState();
  let [eventObj, setEventObj] = useState();
  let [changeHandle, setChangeHandle] = useState({});
  let [showErrors, setShowErrors] = useState({
    event_name: { show: false, msg: "" },
    sportsObj: { show: false, msg: "" },
    genderObj: { show: false, msg: "" },
    eventTypeObj: { show: false, msg: "" },
  });
  const sportList = useSelector((state) => state?.templates?.sportList);
  const eventTypes = useSelector((state) => state?.tournamentEvents?.eventTypes);
  const genderLists = useSelector((state) => state?.tournamentEvents?.allGenderList);
  const updateEventLoading = useSelector((state) => state?.tournamentEvents?.updateEventLoading);
  const updateEventSuccessfull = useSelector((state) => state?.tournamentEvents?.updateEventSuccessful);
  const eventErrorMsg = useSelector((state) => state?.tournamentEvents?.eventErrorMsg);
  const userData = useSelector((state) => state?.auth?.userData);
  const eventListing = useSelector((state) => state?.tournamentEvents?.eventsList);
  let dispatch = useDispatch();
  let { id } = useParams();


  useEffect(()=>{
    if(eventErrorMsg){
      let msgString = eventErrorMsg?.split(":")[1]
        setShowErrors((old)=>({
            ...old,
            event_name:{show:true, msg: msgString}
        }))
        dispatch(resetEventErrorMsg())
    }
},[dispatch, eventErrorMsg])

  useEffect(() => {
    if (eventListing?.length && id) {
      let eventObj = eventListing.filter((event) => Number(event.event_id) === Number(id))[0];
      console.log("eventobj", eventObj);
     eventObj = {
        ...eventObj,
        sportsObj: { value: eventObj.sport_id, label: eventObj.sport_name },
        genderObj: { value: eventObj.gender_id, label: eventObj.gender },
        eventTypeObj: { value: eventObj.event_type_id, label: eventObj.event_type },
      };
      setEventObj(eventObj);
    }
  }, [eventListing, id, sportList]);

  const handleChange = ({ event, key }) => {
    console.log("data", event);
    if(key === "event_name"){
      if(event.target.value?.length >= 75){
        setShowErrors((old) => ({ ...old, event_name: { show: true, msg: "Event name should be of 75 characters or less" } }));
      } else {
        setChangeHandle((preState) => ({
          ...preState,
          [key]: event.value ? { ...event } : event.target.value?.trim(),
        }));
        setShowErrors((old) => ({
          ...old,
          [key]: {
            show: false,
            msg: "",
          },
        }));
      }
    } else {
      setChangeHandle((preState) => ({
        ...preState,
        [key]: event.value ? { ...event } : event.target.value?.trim(),
      }));
      setShowErrors((old) => ({
        ...old,
        [key]: {
          show: false,
          msg: "",
        },
      }));
    }
  };

  const handleSportsChange = (event) => {
    setChangeHandle((preState) => ({
      ...preState,
      sport_id: event,
    }));
    setShowErrors((old) => ({
      ...old,
      sports: {
        show: false,
        msg: "",
      },
    }));
  };

  // const handleSaveTournament = ({ tournamentObj, id }) => {
  //   console.log("name", tournamentObj, "payload");
  //   if (!tournamentObj.tournament_name?.trim())
  //     return setShowErrors((old) => ({ ...old, name: { show: true, msg: "*Tournament name is required." } }));
  //   if (!tournamentObj.sport_id || !tournamentObj.sport_id?.length)
  //     return setShowErrors((old) => ({ ...old, sports: { show: true, msg: "*Sport is required" } }));
  //   if (!tournamentObj.start_date)
  //     return setShowErrors((old) => ({ ...old, startDate: { show: true, msg: "Please enter a valid Date" } }));
  //   if (!tournamentObj.end_date)
  //     return setShowErrors((old) => ({ ...old, endDate: { show: true, msg: "Please enter a valid Date" } }));
  //   if (!tournamentObj.location || !tournamentObj.location?.trim())
  //     return setShowErrors((old) => ({ ...old, location: { show: true, msg: "*Location is required" } }));
  //   let payload = {
  //     id: id ? id : null,
  //     name: tournamentObj.tournament_name?.trim(),
  //     sport_id: tournamentObj?.sport_id?.map((sport) => sport.value),
  //     user_guid: userData?.user_guid ? userData?.user_guid : null,
  //     location: tournamentObj.location?.trim(),
  //     startDate: tournamentObj.start_date,
  //     endDate: tournamentObj.end_date,
  //   };
  //   console.log("name", tournamentObj, "payload", payload, id);
  //   dispatch(updateTournament({ ...payload }));
  // };

  const handleSaveEvent = ({ eventObj, id }) => {
    console.log("name", eventObj, "payload");
    if (!eventObj || !eventObj?.event_name?.trim())
      return setShowErrors((old) => ({ ...old, event_name: { show: true, msg: "Event name is required." } }));
    if (!eventObj?.sportsObj || !eventObj?.sportsObj?.value)
      return setShowErrors((old) => ({ ...old, sportsObj: { show: true, msg: "Sport is required" } }));
    if (!eventObj?.genderObj || !eventObj?.genderObj?.value)
      return setShowErrors((old) => ({ ...old, genderObj: { show: true, msg: "Gender is required" } }));
    if (!eventObj?.eventTypeObj || !eventObj?.eventTypeObj?.value)
      return setShowErrors((old) => ({ ...old, eventTypeObj: { show: true, msg: "Event Type is required" } }));
    let payload = {
      user_guid: userData?.user_guid ? userData?.user_guid : null,
      id: id ? id : null,
      name: eventObj.event_name?.trim(),
      sport_id: eventObj?.sportsObj?.value,
      gender: eventObj.genderObj?.value,
      event_type: eventObj.eventTypeObj?.value,
    };
    // console.log("name", eventObj, "payload", payload, id);
    dispatch(updateEvents({ ...payload }));
  };

  useEffect(() => {
    if (Object.keys(changeHandle).length) {
      setTournament((old) => ({ ...old, ...changeHandle }));
      setEventObj((old) => ({ ...old, ...changeHandle }));
    }
  }, [changeHandle]);

  useEffect(() => {
    if (updateEventSuccessfull) {
      navigate(-1);
    }
    console.log("ddd", updateEventSuccessfull);
  }, [updateEventSuccessfull]);

  // useEffect(()=>{
  //     if(updateLoading){
  //         dispatch(loaderActions.showLoader())
  //     }else {
  //         dispatch(loaderActions.hideLoader())
  //     }
  //     return ()=>dispatch(loaderActions.hideLoader())

  // },[updateLoading])
  useEffect(() => {
    console.log("obj", eventObj);
  }, [eventObj]);
  console.log(eventObj?.sport_id);
  return (
    <div className="si-popup active ">
      {updateEventLoading && <div className="spin"></div>}
      <div class="event-popup">
        <div class="event-popup__header">
          <span class="header-title">{id ? "Event Details" : "New Event"} </span>
          <button type="button" onClick={() => navigate(-1)} class="si-icon__button si-button__close">
            <span class="icon-close"></span>
          </button>
        </div>
        <div class="event-popup__body">
          <div class="si-form">
            <div class={showErrors.event_name.show ? "form-control si-error" : "form-control"}>
              <div class="form-group">
                <label for="name" class="si-label">
                  <span>*</span>Event Name
                </label>
                <input
                  class="si-input"
                  type="text"
                  placeholder="Event Name "
                  maxLength="75"
                  defaultValue={eventObj?.event_name}
                  onChange={(event) => handleChange({ event, key: "event_name" })}
                />
              </div>
              <span class="si-error-message">{showErrors?.event_name?.show ? showErrors?.event_name?.msg : "Event name is required."}</span>
            </div>
            <div class="form-control-outer-wrap">
              <div class={showErrors.sportsObj.show ? "form-control si-error selectSport-form-control" : "form-control selectSport-form-control" }>
                <div class="form-group">
                  <label for="selectSport" class="si-label">
                    <span>*</span>Sport
                  </label>
                  <Dropdown
                    value={{
                      label: eventObj?.sportsObj?.label ? eventObj?.sportsObj?.label : "Select Sports",
                      value: eventObj?.sportsObj?.value ? eventObj?.sportsObj?.value : "",
                    }}
                    options={sportList?.map((obj) => {
                      return {
                        value: obj?.sport_id,
                        label: obj?.sport_name,
                      };
                    })}
                    changeHandler={(data) => {
                      handleChange({ event: { value: data.value, label: data.label }, key: "sportsObj" });
                    }}
                  />
                  {/* <MultiSelectDropdown
                    className="si-sports"
                    placeholder={"Select Sports"}
                    options={sportList?.map((obj) => {
                      return {
                        value: obj?.sport_id,
                        label: obj?.sport_name,
                      };
                    })}
                    changeHandler={handleSportsChange}
                    alreadySelectedValues={preSelectedSports}
                    dropdownfor="sports"
                  /> */}
                </div>
                <span class="si-error-message">Sport name is required</span>
              </div>
              <div class="form-control-inner-wrap">
                <div class={showErrors.genderObj.show ? "form-control si-error " : "form-control"}>
                  <div class="form-group">
                    <label for="selectGender" class="si-label">
                      <span>*</span>Gender
                    </label>
                    {console.log("gender", genderLists)}
                    <Dropdown
                      value={{
                        label: eventObj?.genderObj?.label ? eventObj?.genderObj?.label : "Select Gender",
                        value: eventObj?.genderObj?.value ? eventObj?.genderObj?.value : "",
                      }}
                      options={genderLists?.map((obj) => {
                        return {
                          value: obj?.gender_id,
                          label: obj?.gender,
                        };
                      })}
                      changeHandler={(data) => {
                        handleChange({ event: { value: data.value, label: data.label }, key: "genderObj" });
                      }}
                    />
                  </div>
                  <span class="si-error-message">Gender is required</span>
                </div>
                <div class={showErrors.eventTypeObj.show ? "form-control si-error " : "form-control"}>
                  <div class="form-group">
                    <label for="selectEventType" class="si-label">
                      <span>*</span>Event Type
                    </label>
                    <Dropdown
                      value={{
                        label: eventObj?.eventTypeObj?.label ? eventObj?.eventTypeObj?.label : "Select Event Type",
                        value: eventObj?.eventTypeObj?.value ? eventObj?.eventTypeObj?.value : "",
                      }}
                      options={eventTypes?.map((obj) => {
                        return {
                          value: obj?.event_type_id,
                          label: obj?.event_type,
                        };
                      })}
                      changeHandler={(data) => {
                        handleChange({ event: { value: data.value, label: data.label }, key: "eventTypeObj" });
                      }}
                    />
                  </div>
                  <span class="si-error-message">Event type name is required</span>
                </div>
              </div>
            </div>

            <div class="form-control popup-btn-wrp">
              <button
                type="button"
                onClick={() => handleSaveEvent({ eventObj, id })}
                class="si-button si-button--green si-icon--add"
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
}

export default EventListingAddNew;
//  <div className="tournament-popup" style={{ display: "none" }}>
//         <div className="tournament-popup__header">
//           <span className="header-title">New Tournament </span>
//           <button type="button" className="si-icon__button si-button__close" onClick={() => navigate(-1)}>
//             <span className="icon-close"></span>
//           </button>
//         </div>
//         <div className="tournament-popup__body">
//           <div className="si-form">
//             <div className={showErrors.name.show ? "form-control si-error" : "from-control"}>
//               <div className="form-group">
//                 <label for="name" className="si-label">
//                   <span>*</span>Tournament Name
//                 </label>
//                 <input
//                   defaultValue={tournamentObj?.tournament_name}
//                   maxLength="50"
//                   onChange={(event) => handleChange({ event, key: "tournament_name" })}
//                   className="si-input"
//                   type="text"
//                   placeholder="Tournament Name"
//                 />
//               </div>
//               {showErrors.name.show ? (
//                 <span className="si-error-message">
//                   {showErrors.name.msg ? showErrors.name.msg : "*Tournament name is required."}
//                 </span>
//               ) : null}
//             </div>
//             <div className="form-control-outer-wrap">
//               <div
//                 className={
//                   showErrors.sports.show
//                     ? "form-control si-error selectSport-form-control"
//                     : "form-control selectSport-form-control"
//                 }
//               >
//                 <div className="form-group">
//                   <div className="custom-label si-sports">
//                     <label htmlFor="sports" className="si-label">
//                       Select Sports
//                     </label>
//                   </div>
//                   {/* <div className='form-control si-error'> */}
//                   <MultiSelectDropdown
//                     className="si-sports"
//                     placeholder={"Select Sports"}
//                     options={sportList?.map((obj) => {
//                       return {
//                         value: obj?.sport_id,
//                         label: obj?.sport_name,
//                       };
//                     })}
//                     changeHandler={handleSportsChange}
//                     alreadySelectedValues={preSelectedSports}
//                     dropdownfor="sports"
//                   />
//                   {/* </div> */}
//                   {/* <label for="selectSport" className="si-label"><span>*</span>Sport</label>
//                             <select name="" id="selectSport" className="si-input si-select" onChange={(event)=>handleChange({event, key:"sports"})}>
//                                 <option value="">select sport</option>
//                                 <option value=""></option>
//                                 <option value=""></option>
//                                 <option value=""></option>
//                                 <option value=""></option>
//                             </select> */}
//                 </div>
//                 {showErrors.sports.show ? <span className="si-error-message">Sport is required</span> : null}
//               </div>
//               <div className="form-control-inner-wrap">
//                 <div className={showErrors.startDate.show ? "form-control si-error" : "form-control"}>
//                   <div className="form-group">
//                     <label for="start-date" className="si-label">
//                       <span>*</span>Start Date
//                     </label>
//                     <div className="form-group-wrap si-location date-form-control">
//                       <input
//                         type="date"
//                         placeholder="Add Location"
//                         id="start-date"
//                         className="si-input"
//                         defaultValue={tournamentObj?.start_date?.split("T")[0]}
//                         onChange={(event) => handleChange({ event, key: "start_date" })}
//                       />
//                     </div>
//                   </div>
//                   {showErrors.startDate.show ? (
//                     <span className="si-error-message">Please enter a valid Date</span>
//                   ) : null}
//                 </div>
//                 <div
//                   className={
//                     showErrors.endDate.show
//                       ? "form-control si-error date-form-control"
//                       : "form-control date-form-control"
//                   }
//                 >
//                   <div className="form-group">
//                     <label for="end-date" className="si-label">
//                       <span>*</span>End Date
//                     </label>
//                     <div className="form-group-wrap si-location">
//                       <input
//                         type="date"
//                         placeholder="Add Location"
//                         id="end-date"
//                         min={tournamentObj?.startDate ? tournamentObj?.startDate : null}
//                         className="si-input"
//                         defaultValue={tournamentObj?.end_date?.split("T")[0]}
//                         onChange={(event) => handleChange({ event, key: "end_date" })}
//                       />
//                     </div>
//                   </div>
//                   {showErrors.endDate.show ? <span className="si-error-message">Please enter a valid Date</span> : null}
//                 </div>
//               </div>
//             </div>
//             <div
//               className={
//                 showErrors.location.show
//                   ? "form-control si-error location-form-control"
//                   : "form-control location-form-control"
//               }
//             >
//               <div className="form-group">
//                 <label for="location" className="si-label">
//                   <span>*</span>Location
//                 </label>
//                 <div className="form-group-wrap si-location">
//                   <input
//                     type="text"
//                     placeholder="Add Location"
//                     id="location"
//                     maxLength="50"
//                     className="si-input"
//                     defaultValue={tournamentObj?.location}
//                     onChange={(event) => handleChange({ event, key: "location" })}
//                   />
//                 </div>
//               </div>
//               {showErrors.location.show ? <span className="si-error-message">Location is required</span> : null}
//             </div>

//             <div className="form-control popup-btn-wrp">
//               <button
//                 type="button"
//                 className="si-button si-button--green si-icon--add"
//                 // onClick={() => handleSaveTournament({ tournamentObj, id })}
//               >
//                 SAVE
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>