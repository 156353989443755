import React, { useEffect, useState } from 'react';
import NotesMediaTab from './NotesMediaTab';
import RecurrenceTab from './RecurrenceTab';
import SpecsTab from './SpecsTab';
import SportsTagsTab from './SportsTagsTab';
import Tablists from './Tablists';

function ExerciseBody({ activeTab, setActiveTab, exerciseToAdd, setExerciseToAdd, setWarningPopup, sportSetError, setSportSetError }) {
    useEffect(()=>{
        console.log("activeTab:",activeTab);
    },[activeTab]);
    return (
        <div className="si-popup-body">
            <div className="si-custom-tabs">
                <Tablists 
                    activeTab={ activeTab } 
                    setActiveTab={ setActiveTab }
                    exerciseToAdd={ exerciseToAdd }
                    setWarningPopup={ setWarningPopup }
                />
                <div className="si-tab-content">
                    <SpecsTab 
                        activeTab={activeTab} 
                        exerciseToAdd={exerciseToAdd}
                        setExerciseToAdd={setExerciseToAdd}
                        sportSetError={sportSetError}
                        setSportSetError={setSportSetError}
                    />
                    {/* <RecurrenceTab 
                        activeTab={activeTab} 
                        exerciseToAdd={exerciseToAdd}
                        setExerciseToAdd={setExerciseToAdd}
                    /> */}
                    <NotesMediaTab 
                        activeTab={activeTab} 
                        exerciseToAdd={exerciseToAdd}
                        setExerciseToAdd={setExerciseToAdd}
                    />
                    <SportsTagsTab 
                        activeTab={activeTab} 
                        exerciseToAdd={exerciseToAdd}
                        setExerciseToAdd={setExerciseToAdd}
                    />
                </div>
            </div>
        </div>
    );
};

export default ExerciseBody;