import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import CalenderExercies from './CalendarExercies';
import CalenderHeader from './CalendarHeader';
import { fetchTemoList, fetchUnitsList } from '../../../../utility/templateApiActions';
import { format, addWeeks, addDays } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

function CalendarView({calendarClass,handleDragEnd,IS_PROGRAM_COMPLETED, editExerciseHandler, deleteExerciseHandler, navigateTo, startDate, selectedWeek, 
    weekWiseCalendarData, setDayToAddExercise,tempoList,unitsList}) {
    const [activeDay, setActiveDay] = useState(null);
    // const tempoList = useSelector(state => state?.templates?.tempoList);
    // const unitsList = useSelector(state => state?.templates?.unitsList);
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     if(!tempoList) dispatch(fetchTemoList());
    //     if(!unitsList) dispatch(fetchUnitsList());
    // }, []);

    return (
        <div className={`si-calender ${calendarClass ? calendarClass : "si-caltemplate"}`}>
            <div className="si-calenderTop">
                <CalenderHeader 
                    activeDay={activeDay} 
                    setActiveDay={setActiveDay} 
                    setDayToAddExercise={setDayToAddExercise}
                    navigateTo={navigateTo}
                    startDate={startDate}
                    selectedWeek={selectedWeek}
                    IS_PROGRAM_COMPLETED={IS_PROGRAM_COMPLETED}
                />
                <div className="si-calenderBottom">
                    <div className="scroll-pane">
                        <div className="si-tableBox">
                            {
                                weekWiseCalendarData?.length 
                                ?
                                weekWiseCalendarData?.slice()?.sort((obj1, obj2) => obj1.day_no - obj2.day_no)?.map(obj => {
                                    let currentActiveDay = activeDay ? activeDay : 1 ; 
                                    let addDay, addWeek;
                                    let isCurrentDay = false;
                                    if(startDate) {
                                        addDay = addDays(new Date(startDate), obj?.day_no - 1);
                                        addWeek = addWeeks(addDay, selectedWeek - 1);
                                        if(format(addWeek, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
                                            isCurrentDay = true;
                                        };
                                    } else if(obj?.week_no==1 && obj?.day_no==1) {
                                        isCurrentDay = true;
                                    };

                                    return (
                                        <DragDropContext key={uuidv4()} onDragEnd={(data) => handleDragEnd(data, obj?.week_no, obj?.day_no)}>
                                            <Droppable droppableId={`calendarExercises_${obj?.week_no}_${obj?.day_no}`}>
                                                {
                                                    (provided) => (
                                                        <div 
                                                            className={
                                                                `si-card__list ${obj?.day_no == (currentActiveDay) ? "active" : ""}`
                                                            } 
                                                            {...provided?.droppableProps} 
                                                            ref={provided?.innerRef}
                                                        >
                                                            <CalenderExercies 
                                                                day={obj?.day_no} 
                                                                week={obj?.week_no} 
                                                                exercies={obj?.exercises} 
                                                                tempoList={tempoList}
                                                                unitsList={unitsList}
                                                                editCallback={editExerciseHandler}
                                                                deleteCallback={deleteExerciseHandler}
                                                                IS_PROGRAM_COMPLETED={IS_PROGRAM_COMPLETED}
                                                                startDate={startDate}
                                                            />
                                                            {provided?.placeholder}
                                                        </div>
                                                    )
                                                }
                                            </Droppable>
                                        </DragDropContext>
                                    )
                                })
                                :
                                Array(7)?.fill("")?.map(obj => {
                                    return (
                                        <div 
                                            key={uuidv4()} 
                                            className={`si-card__list ${obj?.day_no == (activeDay ? activeDay : 1) ? "active" : ""}`}
                                        >
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalendarView;