import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchSportsList} from '../../../../utility/templateApiActions';
import Header from '../../Common/Header';
import SubHeader from '../../Common/SubHeader';
import Content from './Content';
import Footer from '../../Common/Footer';
import { loaderActions } from '../../../../reducer/loader';
import FixedButton from '../../Common/FixedButton';
import { fetchTechnicalExerciseMaster, fetchTechnicalTagsList } from '../../../../utility/technicalTemplateApiActions';

function SelectExerciseList({type}) {
    let { id } = useParams();
    const sportList = useSelector(state => state?.technicalTemplates?.sportList);
    const paginationObject = useSelector(state => state?.technicalTemplates?.paginationObject);
    const selectedExerciseList = useSelector(state => type===2 ? state?.technicalPrograms?.selectedExerciseList : state?.technicalTemplates?.selectedExerciseList);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedMenuId = useSelector(state => state?.sideMenu?.selectedMenuId);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedSportIds, setSelectedSportIds] = useState(null);
    const [selectedTag, setselectedTag] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [pageDetails, setPageDetails] = useState();
    const [headerData, setHeaderData] = useState({
        heading: "Select Exercises",
        isHeadingEditable: false,
        isbackBtn: true,
        backBtnAction: () => '',
        isCount: true,
        isCountEditable: false,
        dataCountHeading: ""
    });
    let filterOptions = {
        is_sport_filter:true,
        is_search_filter:true,
        is_tag_filter:true
    }

    useEffect(()=>{
        let data = {};
        switch(type){
            case 1:
                data.navigateTo = `/technical/templates`;
                break;
            case 2:
                data.navigateTo = `/technical/programs`;
                break;
        }
        setPageDetails(data);
        window.addEventListener("load", () => navigate(`${data?.navigateTo}/${id}/calendar`));
        return () => window.removeEventListener("load", () => navigate(`${data?.navigateTo}/${id}/calendar`));
    },[type]);

    useEffect(()=>{
        if(pageDetails?.navigateTo){
            setHeaderData({...headerData,backBtnAction:() => navigate(`${pageDetails?.navigateTo}/${id}/calendar`)})
        }
    },[pageDetails]);

    useEffect(() => {
        // dispatch(loaderActions?.showLoader());
        const searchTimeOut = setTimeout(() => {
            if(searchTerm!==null) dispatch(loaderActions?.showLoader());
            dispatch(fetchTechnicalExerciseMaster(
                {
                    moduleId:selectedMenuId?.moduleId,
                    pageNo, searchTerm, 
                    selectedTag, selectedSportIds,
                    callback: () => setTimeout(() => {
                        dispatch(loaderActions?.hideLoader());
                    })
                }));
        }, 1000);

        return () => clearTimeout(searchTimeOut);
    }, [searchTerm]);

    useEffect(() => {
        const searchTimeOut = setTimeout(() => {
            if(selectedMenuId?.id){
                dispatch(fetchTechnicalExerciseMaster({
                    moduleId:selectedMenuId?.moduleId,
                    pageNo, searchTerm, 
                    selectedTag, selectedSportIds,
                    callback: () => setTimeout(() => {
                        dispatch(loaderActions?.hideLoader());
                    })
                }));
            }
        }, 0);

        return () => clearTimeout(searchTimeOut);
    }, [selectedMenuId,pageNo, selectedTag, selectedSportIds]);

    useEffect(() => {
        if(!sportList?.length) dispatch(fetchSportsList());
    }, []);

    useEffect(() => {
        dispatch(fetchTechnicalTagsList(selectedSportIds));
    }, [selectedSportIds]);

    const actionBtnHandler = (e) => {
        e.preventDefault();
        if(selectedExerciseList?.length) {
            navigate(`${pageDetails?.navigateTo}/${id}/calendar/exercise`);
        };
    };

    return (
        <Fragment>
            <Header 
                headerData={headerData} 
                dataCount={""} 
            />
            <SubHeader 
                filterOptions={filterOptions}
                searchTerm={searchTerm} 
                setSearchTerm={setSearchTerm} 
                pageNo={pageNo}
                setPageNo={setPageNo}
                selectedTag={selectedTag}
                setSelectedTag={setselectedTag}
                selectedSportIds={selectedSportIds}
                setSelectedSportIds={setSelectedSportIds}
                isBtn= {false}
                moduleType={2}
            />
            <Content 
                type={type} 
                pageNo={pageNo} 
                setPageNo={setPageNo} 
                pagination={paginationObject}
                perPage={20}
            />
            <Footer 
                isActionBtn={true}
                btnClass={`si-button ${selectedExerciseList?.length ? "si-button--green" : "si-button--green"}`}
                btnDisabled={!selectedExerciseList?.length ? true : false }
                actionBtnText={"Next"}
                actionBtnHandler={actionBtnHandler}
            />
        </Fragment>
    );
};

export default SelectExerciseList;