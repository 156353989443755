import React, { Fragment, useEffect, useState } from 'react'
import Header from '../../Common/Header'
import SubHeader from '../../Common/SubHeader'
import FixedButton from '../../Common/FixedButton'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ATHELETE_ROLE_ID, PSYCHOLOGY_MODULE_ID, PSYCHOLOGY_MODULE_ROUTE } from '../../../../utility/constants';
import ProgressNotesListingContent from './ProgressNotesListingContent';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../reducer/loader';
import { fetchAthleteDetails } from '../../../../utility/userManagementApiActions';
import { fetchCaseLogList } from '../../../../utility/PsychologyApiActions';
import { resetCaseSessionDetails, resetSelectedPsychologySession } from '../../../../reducer/psychology';

export default function ProgressNotesListing() {
    const { athleteId, id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const userData = useSelector((state) => state?.auth?.userData);
    const athleteDetails = useSelector(state => state?.userManagement?.athleteDetails);
    const loading = useSelector((state) => state.psychology?.loading);
    const athlete_guid = localStorage.getItem("guid") ? localStorage.getItem("guid") : '';
    const [isAddBtn, setIsAddBtn] = useState(athleteId ? false : true);
    // const userData = useSelector((state) => state?.auth?.userData);
    const caseLogList = useSelector((state) => state?.psychology?.caseLogList?.data);
    const paginationObject = useSelector((state) => state?.psychology?.caseLogList?.pagination);
    const [heading, setHeading] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    let filterOptions = {
        is_tab: false,
        is_sport_filter: false,
        is_search_filter_for_athlete: true,
        is_group_filter: false,
    };

    useEffect(() => {
        if (loading) {
            dispatch(loaderActions?.showLoader());
        } else {
            dispatch(loaderActions?.hideLoader());
        }
    }, [loading]);

    useEffect(() => {
        if (userData && userData?.role_id == ATHELETE_ROLE_ID) {
            setIsAddBtn(false);
        }
    }, [userData]);

    useEffect(() => {
        dispatch(resetSelectedPsychologySession());
        dispatch(resetCaseSessionDetails());
    }, []);

    useEffect(() => {
        if (id) {
            if (athleteDetails) {
                setHeading(`${athleteDetails?.athlete_name}'s Progress Notes`);
            }
        } else {
            navigate(`/${PSYCHOLOGY_MODULE_ID}`);
        }
    }, [athleteDetails, id]);

    useEffect(() => {
        if (isAuth && !athleteDetails) {
            if (athlete_guid) {
                dispatch(fetchAthleteDetails({
                    id: athlete_guid
                }));
            } else {
                navigate(-1);
            }
        }
    }, [isAuth, athleteDetails]);

    useEffect(() => {
        if (isAuth && athleteDetails && id && userData?.user_guid) {
            let payload = {
                page_no: pageNo,
                item_number: 20,
                search: searchTerm,
                case_id: id,
                user_guid:userData?.user_guid
            };
            dispatch(fetchCaseLogList({ payload }));
        }
    }, [isAuth, athleteDetails, id, dispatch, userData]);

    useEffect(() => {
        if (searchTerm !== null) {
            let caseLogListTimeout = setTimeout(() => {
                let payload = {
                    page_no: pageNo,
                    item_number: 20,
                    search: searchTerm,
                    case_id: id,
                    user_guid:userData?.user_guid
                };
                dispatch(fetchCaseLogList({ payload }));
            }, 1000);
            return () => clearTimeout(caseLogListTimeout);
        }
    }, [searchTerm, dispatch]);
    return (
        <Fragment>
            <Header
                headerData={{
                    heading: heading,
                    isHeadingEditable: false,
                    isbackBtn: true,
                    backBtnAction: () => athleteId ? navigate(-1) : navigate(`/${PSYCHOLOGY_MODULE_ROUTE}/case/${id}`)
                }}
            />
            <SubHeader
                filterOptions={filterOptions}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                pageNo={pageNo}
                setPageNo={setPageNo}
                navigate={navigate}
                isBtn={isAddBtn}
                btnName='Add New Note'
                onBtnClick={() => navigate("new")}
            />
            <div className="si-main-content">
                <ProgressNotesListingContent
                    caseLogList={caseLogList}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    pagination={paginationObject}
                />
            </div>
            {userData && userData?.role_id === String(ATHELETE_ROLE_ID) ? null : 
                <FixedButton btnName="Add New Note" onBtnClick={() => navigate(`new`)} iconClass="add" />
            }
        </Fragment>
    )
}
