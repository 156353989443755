import React from 'react'
import { Route, Routes } from 'react-router'
import { PSYCHOLOGY_MODULE_ROUTE } from '../utility/constants'
import ProtectedRoute from './ProtectedRoute'
import PsychologCaseListing from '../components/RightPannel/Psychology/PsychologCaseListing'
import SelectAthlete from '../components/RightPannel/Psychology/SelectAthlete'
import NewCaseLog from '../components/RightPannel/Psychology/CaseLog/NewCaseLog'
import ViewUpdateCaseLog from '../components/RightPannel/Psychology/CaseLog/ViewUpdateCaseLog'
import ProgressNotesListing from '../components/RightPannel/Psychology/ProgressNotes/ProgressNotesListing'
import AddUpdateProgressNotes from '../components/RightPannel/Psychology/ProgressNotes/ViewUpdateProgressNotes'
import NewProgressNote from '../components/RightPannel/Psychology/ProgressNotes/NewProgressNote'
import ViewUpdateProgressNotes from '../components/RightPannel/Psychology/ProgressNotes/ViewUpdateProgressNotes'

function PsychologyRoutes() {
  return (
    <Routes>
        <Route exact path={`/${PSYCHOLOGY_MODULE_ROUTE}`} element={
            <ProtectedRoute Component={PsychologCaseListing}/>
        }  />
        <Route exact path={`/${PSYCHOLOGY_MODULE_ROUTE}/:athleteId?/case/:id`} element={
            <ProtectedRoute Component={ViewUpdateCaseLog}/>
        }  />
        <Route exact path={`/${PSYCHOLOGY_MODULE_ROUTE}/athletes`} element={
            <ProtectedRoute Component={SelectAthlete}/>
        }  />
        <Route exact path={`/${PSYCHOLOGY_MODULE_ROUTE}/:athleteId/caselog/new`} element={
            <ProtectedRoute Component={NewCaseLog}/>
        }  />
        <Route exact path={`/${PSYCHOLOGY_MODULE_ROUTE}/:athleteId?/case/:id/progressnotes`} element={
            <ProtectedRoute Component={ProgressNotesListing}/>
        }  />
        <Route exact path={`/${PSYCHOLOGY_MODULE_ROUTE}/case/:id/progressnotes/new`} element={
            <ProtectedRoute Component={NewProgressNote}/>
        }  />
        <Route exact path={`/${PSYCHOLOGY_MODULE_ROUTE}/:athleteId?/case/:id/progressnotes/:sessionId`} element={
            <ProtectedRoute Component={ViewUpdateProgressNotes} type={'view'}/>
        }  />
    </Routes>

  )
}

export default PsychologyRoutes