import React from 'react'
import { useState } from 'react';

export default function ReportHeader(props) {
    let {isHeadingEditable,heading,setHeading,backBtnAction,isbackBtn,error,setError} = props.headerData;
  return (
    <div className={`si-headwrp`}>
      {
        isbackBtn && 
        <button 
            type="button" 
            className="si-icon__button icon-back"
            onClick={backBtnAction}
        >
            <span className="icon-chev-left"></span>
        </button>
      }
      <div className="si-headcover">
        <div className="si-heading">
          {
            isHeadingEditable
            ?
            <div className="si-Disable">
              <div 
                className={`form-control form-control__title ${error?.isShow ? "si-error" : ""}`} 
                style={{width: '35%'}}
              >
                <input 
                  type="text" 
                  className="si-input" 
                  placeholder="Add Test Name"
                  value={heading ? heading : ""} 
                  onChange={(e) => {
                    if(e?.target?.value?.trim()?.length > 50) return;
                    setHeading(e?.target?.value);
                    setError({isShow: false});
                  }}
                  disabled = {!isHeadingEditable}
                />
                <span className={ error?.isShow ? "si-error-message" : "" }>{ error?.message }</span>
              </div>
            </div>
            :
            <div className="si-Disabled">
              <div 
                className="si-Title">
                {heading ? heading : ""}
              </div>
            </div>
          }
        </div>      
      </div>
    </div>
  )
}
