import React, { Fragment } from "react";
import TableHeader from "../NewReport/TableHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAthleteDetails } from "../../../../utility/userManagementApiActions";
import { loaderActions } from "../../../../reducer/loader";
import { getSubmittedReport } from "../../../../utility/testAssessmentApiActions";
import moment from "moment";
import Test from "./Test";
import { ATHELETE_ROLE_ID } from "../../../../utility/constants";
import DownloadMediaPopup from "../../Common/DownloadMediaPopup";
import { getSelectedAthleteDetails } from "../../../../utility/nutritionApiAction";
import { testAssessmentActions } from "../../../../reducer/testAssessment";
import { userManagementActions } from "../../../../reducer/userManagement";

export default function ViewReport() {
  const { id } = useParams();
  const { reportid, athleteId } = useParams();
  const { state } = useLocation();
  const [reportDate, setReportDate] = useState("");
  const [newReport, setNewReport] = useState();
  const { backUrl } = state ? state : "";
  const [userguid, setUserguid] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state?.auth?.isAuthenticated);
  const athleteDetails = useSelector((state) => state?.userManagement?.athleteDetails);
  const reportDetails = useSelector((state) => state?.testAssessment?.reportDetails);
  // nutrition state ref
  let selectedAthleteDetails = useSelector((state) => state.nutrition.selectedAthleteDetails);
  const userData = useSelector((state) => state?.auth?.userData);
  const [openDownloadPopup, setOpenDownloadPopup] = useState(false);
  
  
  // Get athlete guid if not find in routing state
  useEffect(() => {
    if (!athleteId) return
    if (selectedAthleteDetails.athlete_id === athleteId) return;
    console.log('athleteId', athleteId)
      if (athleteId && userData?.user_guid ) {
        // hit api
        dispatch(getSelectedAthleteDetails({ athlete_id: athleteId, user_guid: userData?.user_guid }));
      }
    }, [selectedAthleteDetails, athleteId, userData, dispatch]);

  useEffect(() => {
    if (userData && userData?.role_id == ATHELETE_ROLE_ID) {
      setUserguid(userData?.user_guid);
    } else if( athleteId) {
      if(selectedAthleteDetails?.athlete_guid){
        setUserguid(selectedAthleteDetails?.athlete_guid)
      }
    }  else {
      // TODO: need to update to get athlete guid from api and remove localstorage 
      setUserguid(localStorage?.getItem("guid") ? localStorage?.getItem("guid") : "");
    }
  }, [userData, selectedAthleteDetails, athleteId]);

  const getNavigation = (from) => {
    let navigateTo = backUrl ? backUrl : `/testfunctions/${id}/athletereports`;
    if (from === "profile") {
      navigateTo = `/testfunctions/${id}/athletelisting`;
    }
    dispatch(testAssessmentActions.clearReportTemplateDetails())
    dispatch(userManagementActions.resetAthleteDetails())
    navigate(navigateTo);
  };

  useEffect(() => {
    if (reportDetails) {
      console.log("reportDetails--->", reportDetails);
      setReportDate(moment(new Date(reportDetails?.conducted_date)).format("DD-MM-YYYY"));
      //   setNewReport({
      //     ...newReport,
      //     "test_name": heading ? heading : '',
      //     "conducted_date": reportDate ? reportDate : "",
      //     "athlete_guid": userguid,
      //     "function_id": id,
      //     "file_name": [],
      //     "opt_type": 1,
      //     "sport_id": athleteDetails?.sport_id ? athleteDetails?.sport_id[0] : '',
      //     "test_reports_id": 0
      //   });
      //   setTimeout(() => {
      //     createNewReportObject();
      //   }, 1000);
    }
  }, [reportDetails]);

  //   const createNewReportObject = () => {
  //     let subTest = [];
  //     reportDetails?.tests.map((report) => {
  //       // console.log(report.tests_parameter);
  //       let test = {
  //         id: report.id,
  //         // remark:'',
  //         tests_parameter: []
  //       };
  //       report?.tests_parameter?.map((param) => {
  //         let parameters = {
  //           parameter_id: param.parameter_id
  //         }
  //         reportDetails?.test_headers.map((header) => {
  //           if (header.type != 'label') parameters[header.key] = param[header.ker] ? param[header.ker] : "";
  //         });
  //         test.tests_parameter.push(parameters);
  //       });
  //       subTest.push(test);
  //     });
  //     setNewReport(prevState => {
  //       return { ...prevState, 'tests': subTest }
  //     });
  //   }

  //   useEffect(() => {
  //     setNewReport(prevState => {
  //       return { ...prevState, 'test_name': heading ? heading : "", 'conducted_date': reportDate }
  //     });
  //   }, [heading, reportDate]);

  //third step

  useEffect(() => {
    if (isAuth && userguid) {
      dispatch(
        fetchAthleteDetails({
          id: userguid,
          callback: () =>
            setTimeout(() => {
              dispatch(loaderActions?.hideLoader());
            }, 0),
        })
      );
    }
  }, [isAuth, userguid]);

  useEffect(() => {
    if (athleteDetails) {
      let viewReportTimeout = setTimeout(() => {
        getReportDetails();
        return () => clearTimeout(viewReportTimeout);
      }, 0);
    }
  }, [athleteDetails]);

  const getReportDetails = () => {
    dispatch(loaderActions?.showLoader());
    dispatch(
      getSubmittedReport({
        sportId: [],
        athlete_guid: "",
        functionId: id,
        reportId: reportid,
        callback: () =>
          setTimeout(() => {
            dispatch(loaderActions?.hideLoader());
          }),
      })
    );
  };

  const HandleDownloadPopup = () => {
    setOpenDownloadPopup(true);
  };
  const closePopupHandler = () => {
    setOpenDownloadPopup(false);
  };
  return (
    <Fragment>
      <div className="si-headwrp si-margin">
        <button type="button" className="si-icon__button icon-back" onClick={() => getNavigation()}>
          <span className="icon-chev-left"></span>
        </button>
        <div className="si-headcover">
          <div className="si-heading ">
            <div className="si-Disabled">
              <div className="si-Title">{reportDetails?.test_name}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="si-subHeader">
        <div className="si-testcover">
          <div className="si-namewrp">
            <div className="si-Image">
              <img
                alt="pdf preview"
                src={`../../images/profile/${athleteDetails?.athlete_id}.png`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "../../images/profile/default.png";
                }}
              />
            </div>
            <div className="si-name">{athleteDetails?.athlete_name}</div>
          </div>
          <div class="si-conductdate">
            <div class="si-text">
              Conducted on
              <span class="form-control si-start-date edit-date disable-calender">
                <span class="icon-calender"></span>
                <span>{reportDate}</span>
              </span>
              {/* <span class="form-control si-start-date  edit-date ">
                <input type="date" class="si-input test-date-input input-disable" value={reportDate} />
              </span> */}
            </div>
            <button type="button"
              className="si-button si-download"
              // class={reportDetails?.file_name?.length ? "si-button si-download" : "si-button si-download button-disabled"} 
              onClick={HandleDownloadPopup}
            // disabled ={reportDetails?.file_name?.length ? false : true}
            >
              <span class="icon-download"></span>
              <span>Attachments</span>
            </button>
            {reportDetails && reportDetails.file_name?.length ? (
              <span className="si-download-count">
                {reportDetails && reportDetails.file_name.length ? reportDetails.file_name.length : 0}{" "}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="si-main-content si-reportdetails-wrapper">
        <div className="si-reportdetails">
          <div className="si-tbls si-viewtbls">
            {reportDetails?.test_headers && <TableHeader headers={reportDetails?.test_headers} />}
            <div className="si-tbls__body">
              <div className="si-resultwrp">
                {reportDetails?.tests.map((test) => {
                  return (
                    <Test
                      testDetails={test}
                      headers={reportDetails?.test_headers}
                      key={test.id}
                      newReport={newReport}
                      setNewReport={setNewReport}
                    />
                  );
                })}
                {reportDetails?.additional_information &&
                  <Fragment>
                    <div className="si-head">Additional Information</div>
                    <div className="si-tblwrp">
                      <div className="si-tableleft">
                        <div className="si-tbls__row ">
                          {/* <div class="si-tbls__cell">
                            <span>{reportDetails?.additional_information?.key ? reportDetails?.additional_information?.key : '-'}</span>
                          </div> */}
                          <div className="si-tbls__cell">
                            <span class="si-interpretation si-additional-information">{reportDetails?.additional_information?.value ? reportDetails?.additional_information?.value : '-'}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {openDownloadPopup ? (
        <DownloadMediaPopup isShow={openDownloadPopup} closePopupHandler={closePopupHandler} files={reportDetails.file_name} />
      ) : null}
    </Fragment>
  );
}
