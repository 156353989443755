import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import ListingTable from "../../Common/ListingTableMUI";

function TournamentListContent({ pagination, performanceLists, setPageNo, pageNo, handleTrackPerformance }) {
  const tableHeaders = [
    {
      label: "Tournament Name",
      align: "left",
    },
    {
      label: "Date",
      align: "center",
    },
    {
      label: "Location",
      align: "left",
    },
    {
      label: "Event Name",
      align: "left",
    },
    {
      label: "",
      align: "right",
      stickyClass: "stickyCell-right",
    },
  ];
  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {performanceLists?.length ? (
          performanceLists?.map((performance, idx) => {
            return (
              <TableRow key={uuidv4()}>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                  onClick={(e) => handleTrackPerformance(e, performance)}
                >
                  {performance?.tournament_name}
                </TableCell>

                <TableCell align={"center"}>
                  {performance?.start_date ? performance.start_date.split("T")[0]?.split("-").reverse().join("/") : "-"}
                </TableCell>
                <TableCell align={"left"}>{performance?.location ? performance.location : "-"}</TableCell>
                <TableCell align={"left"}>
                  {performance?.event_name
                    ? //   performance?.sport_name
                      performance?.event_name
                    : "-"}
                </TableCell>

                <TableCell align={"right"} className="stickyCell-right">
                  <button type="button" className="si-viewBtn" onClick={(e) => handleTrackPerformance(e, performance)}>
                    <span className="si-icon__view"></span>
                    <span className="si-txt">View Details</span>
                  </button>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <ListingTable
      tableHeaders={tableHeaders}
      getTableBody={getTableBody}
      pageNo={pageNo}
      setPageNo={setPageNo}
      pagination={pagination}
      perPage={20}
    />
  );
}

export default TournamentListContent;
