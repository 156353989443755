import { createAsyncThunk } from '@reduxjs/toolkit';
import { authActions } from '../reducer/auth';
import API from './api';
import { loaderActions } from '../reducer/loader';

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchTemplateList = createAsyncThunk("templates/fetchTemplates", async (data, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    // dispatch(loaderActions?.showLoader());
    let body = {
        "page_no": data?.pageNo,
        "search": data?.searchTerm,
        "item_number": 20,
        "module_id": data?.moduleId,
        // "user_id": userData ? userData?.user_id : null,
        "user_guid": userData.user_guid,
        "sport_id": data?.selectedSports
    };
    try{
        return {
            callback: data?.callback,
            apiResponse: await postAPIDetails("template/gettemplatelisting", body)
        }
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback: data?.callback, error})
        } else {
            return rejectWithValue({callback: data?.callback, error});
        }
    }
});

export const postProgramTemplate = createAsyncThunk("templates/postTemplate", async (data, {getState, dispatch, rejectWithValue}) => {
    let {optType, name, duration ,moduleId, sportId, callback, isNewTemplate, isPublish} = data;
    let state = getState();
    name = optType === 1 ? state?.templates?.programName : name;
    duration = optType === 1 ? state?.templates?.duration : duration;
    let userData = state?.auth?.userData;
    let selectedSportId = optType === 1 ? state?.templates?.selectedSportId : sportId;
    let programId = optType === 1 ? 0 : state?.templates?.selectedTemplate?.program_templateid;
    // dispatch(loaderActions?.showLoader());
   
    let body = {
        "template":{
            "opt_type": optType,
            "module_id": moduleId,
            // "user_id": userData ? userData?.user_id : null,
            "user_guid": userData ? userData?.user_guid : null,
            "sport_id": selectedSportId,
            "program_name": name.trim(),
            "duration": duration,
            "program_id": programId,
            "publish": isPublish
        }
    };
    try{
        return {
            callback,
            isNewTemplate,
            apiResponse: await postAPIDetails("template/insertupdatetemplate", body)
        }; 
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchProgramTemplateDetailsById = createAsyncThunk("templates/fetchTemplate", async ({id,type ,callback}, {getState,dispatch, rejectWithValue}) => {
    // dispatch(loaderActions?.showLoader());
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            callback,
            apiResponse: await getAPIDetails(`program/getprogramtemplatedetails?programid=${id}&p_opt_type=${type}&user_guid=${userData?.user_guid}`)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const deleteTemplateById = createAsyncThunk("templates/deleteTemplateById", async ({id, callback}, {getState,dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            callback,
            apiResponse: await postAPIDetails(`template/deletetemplatebyid?template_id=${id}&user_guid=${userData.user_guid}`)
        };
    } catch (error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchExerciseMaster = createAsyncThunk("templates/fetchExerciseMaster", async (data, {dispatch, rejectWithValue}) => {
    // dispatch(loaderActions?.showLoader());
    let body = {
        "page_no": data?.pageNo,
        "module_id": data?.moduleId,
        "item_number": 20,
        "name": data?.searchTerm,
        "tags": data?.selectedTag?.length ? data?.selectedTag : null,
        "sport_id": data?.selectedSportIds?.length ? data?.selectedSportIds : null
    };
    try{
        return {
            callback: data?.callback,
            apiResponse: await postAPIDetails("exercise/getexercisemasters", body)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback: data?.callback, error})
        } else {
            return rejectWithValue({callback: data?.callback, error});
        }
    }
});

export const fetchExerciseMasterFilter = createAsyncThunk("templates/fetchExerciseMasterFilter", async (data, {dispatch, rejectWithValue}) => {
    let body = {
        "page_no": data?.pageNo,
        "item_number": data?.perPage,
        "module_id": data?.moduleId,
        "name": data?.searchTerm,
        "tags": data?.selectedTag?.length ? data?.selectedTag : null,
        "sport_id": data?.selectedSportIds?.length ? data?.selectedSportIds : null
    };
    try{
        return {
            callback: data?.callback,
            apiResponse: await postAPIDetails("exercise/getexercisemasters", body)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback: data?.callback, error})
        } else {
            return rejectWithValue({callback: data?.callback, error});
        }
    }
});

export const fetchExerciseDetails = createAsyncThunk("template/fetchExerciseDetails", async ({id, type, callback}, {dispatch, rejectWithValue, getState}) => {
    // dispatch(loaderActions?.showLoader());
    try{
        let state = getState();
        let userData = state?.auth?.userData;
        return {
            callback,
            apiResponse: await getAPIDetails(`exercise/getexercisedetails?id=${id}&type=${type}&user_guid=${userData?.user_guid}`)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const postTemplateExercises = createAsyncThunk("templates/postTemplateExercises", async ({type, id, callback}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let selectedExerciseList = type===2 ? state?.programs?.selectedExerciseList : state?.templates?.selectedExerciseList;
    if(selectedExerciseList?.length) {
        // dispatch(loaderActions?.showLoader());
        // dispatch(fetchWeekWiseData({ week: 1 }));
        // update description key value into exercise_desc key 
        selectedExerciseList = selectedExerciseList.map((exercies)=>{
            if(exercies.description){
                return {...exercies, exercise_desc:exercies.description}
            } else {
                return exercies
            }
        })
        let body = {
            "exercies": selectedExerciseList
        };
        console.log(type,id);
        try{
            return {
                callback,
                apiResponse: await postAPIDetails(`exercise/insertupdateexercise?type=${type}&id=${id}&user_guid=${userData.user_guid}`, body)
            };
        } catch(error){
            let statusCode = error?.response?.status
            dispatch(loaderActions?.hideLoader());
            if(statusCode === 401){
                dispatch(authActions.logout())
                error.message = error?.response?.data?.title && error?.response?.data?.title
                return rejectWithValue({callback, error})
            } else {
                return rejectWithValue({callback, error});
            }
        }
    };
});

export const fetchWholeCalendarData = createAsyncThunk("templates/fetchWholeCalendarData", async ({callback, id}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    // dispatch(loaderActions?.showLoader());
    try{
        if(state?.templates?.selectedTemplate) {
            return {
                callback,
                apiResponse: await getAPIDetails(`program/getprogramscheduledetail?programid=${id}&user_guid=${userData.user_guid}`)
            };
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchWeekWiseData = createAsyncThunk("templates/fetchWeekWiseData", async ({id, week, callback}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    // dispatch(loaderActions?.showLoader());
    try{
        return {
            week,
            callback,
            apiResponse: await getAPIDetails(`program/getprogramscheduledetail?programid=${id}&weekid=${week}&user_guid=${userData.user_guid}`)
            // apiResponse: await getAPIDetails(`program/getprogramscheduledetail?programid=${1}&weekid=${1}`)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchTemoList = createAsyncThunk("templates/fetchTempoList", async (data, {dispatch, rejectWithValue}) => {
    // dispatch(loaderActions?.showLoader());
    try{
        return await getAPIDetails("program/gettempolisting");
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({error})
        } else {
            return rejectWithValue({error});
        }
    }
});

export const fetchUnitsList = createAsyncThunk("templates/fetchUnitsList", async (data, {dispatch, rejectWithValue}) => {
    // dispatch(loaderActions?.showLoader());
    try{
        return await getAPIDetails("program/getunitlisting");
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({error})
        } else {
            return rejectWithValue({error});
        }
    }
});

export const fetchSportsList = createAsyncThunk("templates/fetchSportsList", async (data, {dispatch, rejectWithValue}) => {
    // dispatch(loaderActions?.showLoader());
    try{
        return await getAPIDetails("exercise/getsportslisting");
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({error})
        } else {
            return rejectWithValue({error});
        }
    }
});

export const fetchTagsList = createAsyncThunk("templates/fetchTagsList", async (sportsIds, {dispatch, rejectWithValue}) => {
    // dispatch(loaderActions?.showLoader());
    let body = {
        "sport_id": sportsIds?.length ? sportsIds : null
    };
    try{
        return await postAPIDetails("exercise/gettaglisting", body);
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({error})
        } else {
            return rejectWithValue({error});
        }
    }
});

export const publishCalendarSequence = createAsyncThunk("templates/publishSequence", async ({id, type, callback}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let wholeCalendarData = type===2 ? state?.programs?.wholeProgramCalendarData : state?.templates?.wholeCalendarData;
    let newWholeCalendarData = wholeCalendarData?.map(weekObj => {
        let daysData = weekObj?.days?.map(dayObj => {
            let newExercises = dayObj?.exercises?.map((obj, idx) => {
                let isRecurrence = obj?.recurrence?.lenght > 1;
                return {
                    "id": obj?.id,
                    "sequence_no": !isRecurrence ? idx + 1 : 0
                };
            });
            return { ...dayObj, exercises: newExercises?.length ? newExercises : [] };
        });
        return { ...weekObj, days: daysData };
    });

    let body = {
        "calendar": newWholeCalendarData
    };
    try{
        return {
            callback,
            apiResponse: await postAPIDetails(`template/publishtemplateorprogram?type=${type}&id=${id}&user_guid=${userData.user_guid}`, body)
        };
    } catch (error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchAllAthletesList = createAsyncThunk("program/fetchAllAthletesList", async (data, {getState, rejectWithValue, dispatch}) => {
    let state = getState();
    let {pageNo, searchTerm, selectedSports, userguid ,groupFilter,callback} = data;

    let body = {
        "page_no": pageNo,
        "item_number": 20,
        "search": searchTerm,
        "user_guid": userguid,
        "sport_id": selectedSports?.length ? selectedSports : null,
        "group_id": groupFilter?.length ? groupFilter : null
    };
    try {
        return {
            callback,
            apiResponse: await postAPIDetails("program/getalladminathletelisting", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});