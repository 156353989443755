import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loaderActions } from '../../../../reducer/loader';
import { deleteProgram, fetchProgramsList } from '../../../../utility/programApiActions';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import {TableBody, TableRow, TableCell} from '@mui/material';
import ListingTable from '../../Common/ListingTableMUI';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import { deleteTemplateById } from '../../../../utility/templateApiActions';

function Content({ pageNo, setPageNo, pagination, perPage, searchTerm, selectedSports, activeTab,subModule}) {
    const programListing = useSelector(state => state?.programs?.programListing);
    const [isActionActive, setIsActionActive] = useState(Array(programListing?.length)?.fill(false));
    const [warningPopup, setWarningPopup] = useState({isShow: false});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window?.addEventListener("click", handleKeydownEvent);
        return () => window?.removeEventListener("click", handleKeydownEvent);
    }, []);

    useEffect(() => {
        setIsActionActive(Array(programListing?.length)?.fill(false));
    }, [programListing]);

    const handleKeydownEvent = (event) => {
        setIsActionActive(Array(programListing?.length)?.fill(false));
    };

    const handleAction = (e, idx) => {
        e?.stopPropagation();
        setIsActionActive(prevState => {
            let newState = Array(programListing?.length)?.fill(false);
            newState[idx] = !prevState[idx];
            return [ ...newState ];
        });
    };

    const handleDelete = () => {
        dispatch(loaderActions?.showLoader());
        dispatch(
            deleteProgram({
                id: warningPopup?.programId,
                callback: () => {
                    setTimeout(() => {
                        dispatch(
                            fetchProgramsList({
                                pageNo,
                                searchTerm,
                                selectedSports,
                                activeTab,
                                // moduleId: 1
                            })
                        );
                        dispatch(loaderActions?.hideLoader());
                        setWarningPopup({isShow: false});
                        toast.success("Program deleted successfully.", {toastId: "program_delete_success"});
                    });
                }
            })
        );
    };

    const handleEdit = (e, id) => {
        e?.stopPropagation();
        navigate(`/snc/${subModule}/${id}/calendar`,{state:{backUrl:`/snc/${subModule}`}})
    };

    const handleDeleteClick = (e, id) => {
        e?.stopPropagation();
        setWarningPopup(({isShow: true, programId: id}))
    };

    const handleTrackPerformance = (e, id) => {
        e?.stopPropagation();
        navigate(`/snc/${subModule}/${id}/calendar?performance=true`,{state:{backUrl:`/snc/${subModule}`}});
    };

    const handlePopupCancel = () => {
        setWarningPopup({isShow: false});
        setIsActionActive(Array(programListing?.length)?.fill(false));
    };

    const tableHeaders = [
        {
            label: "Program Name",
            align: "left"
        },
        {
            label: "Sports",
            align: "left"
        },
        {
            label: "Duration",
            align: "left"
        },
        {
            label: "Created By",
            align: "center"
        },
        {
            label: "Start Date",
            align: "left"
        },
        {
            label: "Athletes",
            align: "center"
        },
        {
            label: "",
            align: "right"
        },
        {
            label: "",
            align: "center",
            stickyClass: "stickyCell-right" 
        }
    ];

    const formatDate = (date)=>{
        let objectDate = new Date(date);

        let day = objectDate.getDate();

        let month = objectDate.getMonth() + 1;

        let year = objectDate.getFullYear();
         return `${day}/${month}/${year}`;
    }

    const getTableBody = () => {
        return (
            <TableBody sx={{backgroundColor:"#181818"}}>
                {
                    programListing?.length
                    ?
                    programListing?.map((program, idx) => {
                        return (
                            <TableRow 
                                key={uuidv4()}
                            >
                                <TableCell 
                                    align={"left"} 
                                    sx={{cursor: "pointer", minWidth: 150}}
                                    onClick={(e) => handleEdit(e, program.program_id)}
                                >
                                    {program?.program_name}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {program?.sport_name ? program?.sport_name : '-'}
                                </TableCell>
                                <TableCell align={"left"}>
                                    { 
                                        `${program?.duration > 1 
                                        ? (program?.duration + " Weeks") 
                                        : (program?.duration + " Week") }` 
                                    }
                                </TableCell>
                                <TableCell align={"center"}>
                                    Admin
                                </TableCell>
                                <TableCell align={"left"}>
                                    {program?.start_date ? formatDate(program?.start_date) : '-'}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {program?.no_of_athletes ? program?.no_of_athletes : 0}
                                </TableCell>
                                <TableCell align={"right"}>
                                    {
                                        (activeTab===1 || activeTab===3)
                                        ? 
                                        <button 
                                            type="button" 
                                            className="si-treckBtn"
                                            onClick={(e) => handleTrackPerformance(e, program?.program_id)}
                                        >
                                            <span className="si-treckicon"></span>
                                            <span className="si-txt">Track Performance</span>
                                        </button>
                                        : ''
                                    }
                                </TableCell>
                                <TableCell align={"center"} className="stickyCell-right">
                                    <div className="si-custom-table__cell si-wrap" style={{margin:"auto"}}>
                                        <button 
                                            type="button" 
                                            className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                                            onClick={(e) => handleAction(e, idx)}
                                        ></button>
                                        <div className="si-action-icons">
                                            {subModule == "completedprograms" ? null : 
                                            <button 
                                                type="button" 
                                                className="si-icon-button"
                                                onClick={(e) => handleEdit(e, program.program_id)}
                                            >
                                                <span className="icon-edit"></span>edit
                                            </button> }
                                            <button 
                                                type="button" 
                                                className="si-icon-button"
                                                onClick={(e) => handleDeleteClick(e, program?.program_id)}
                                            >
                                                <span className="icon-delete"></span>delete
                                            </button>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={tableHeaders.length}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };


    return (
        <div className="si-main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={pagination}
                perPage={perPage}
            />
            <ConfirmationPopup 
                isActive={warningPopup?.isShow}
                isActionBtns={true}
                warning={"Are you sure you want to delete this program?"}
                okHandler={handleDelete}
                cancelHandler={handlePopupCancel}
            />
        </div>
    );
};

export default memo(Content);