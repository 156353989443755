import React from 'react';

function CalendarTabs({activeTab, setActiveTab}) {
    return (
        <div className="si-tab-list">
            <div 
                className={`si-tab-item ${activeTab==="schedule" ? "active" : ""}`}
                onClick={() => setActiveTab("schedule")}
            >
                <span>schedule</span>
            </div>
            <div 
                className={`si-tab-item ${activeTab==="athletes" ? "active" : ""}`}
                onClick={() => setActiveTab("athletes")}
            >
                <span>athletes</span>
            </div>
        </div>
    );
};

export default CalendarTabs;