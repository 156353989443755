import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_BASE_PATH;
const loginBaseUrl = process.env.REACT_APP_LOGIN_PATH;
const apiInstance = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
});
const loginInstance = axios.create({
  baseURL: loginBaseUrl,
  withCredentials: true,
});

export default class API {
  getAPIDetails(url) {
    return apiInstance.get(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  postAPIDetails(url, data) {
    return apiInstance.post(url, data, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  postLoginDetails(url,data){
    return loginInstance.post(url, data, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
};
