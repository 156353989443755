import React from 'react'
import SubHeader from '../../Common/SubHeader';
function PerformanceListingSubHeader({searchTerm, setSearchTerm,showBtn, selectedSports,setSelectedSports,pageNo, setPageNo, onClick}) {
    let filterOptions = {
      is_sport_filter: true,
      is_search_filter: true,
      is_tab: false,
      is_group_filter: false,
  };
    return (
      <SubHeader 
      filterOptions={filterOptions}
      searchTerm={ searchTerm }
      setSearchTerm={ setSearchTerm }
      selectedSports={ selectedSports }
      setSelectedSportIds={ setSelectedSports }
      pageNo={ pageNo }
      setPageNo={ setPageNo }
      isBtn={showBtn}
      btnName="Record Results"
      onBtnClick={onClick}
  />
    )
  }
  

export default PerformanceListingSubHeader