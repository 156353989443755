import React, { useState } from "react";
import moment from "moment/moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

function AthleteInformationNutrition({activeTab, athleteId, reportList, athleteDetails }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [phoneActiveTab, setPhoneActiveTab] = useState({ info: true, report: false });
  const formatDate = (date) => {
    if (date) return moment(date).format("DD/MM/YYYY");
    return "-";
  };

  useEffect(()=>{
    if(searchParams.get("report")){
      setPhoneActiveTab({info:false, report:true})
    } else {
      setPhoneActiveTab({info:true, report:false})
    }
  },[])

  useEffect(()=>{
      setSearchParams(()=>{
        return {
          tab:activeTab,
          report:phoneActiveTab.report
        }
      }, {replace:true})
  }, [phoneActiveTab])
  return (
    <div className="information-main">
      <div className="information-main__left">
        <div className="atheletes-details">
          <div
            className={`si-subheader ${phoneActiveTab.info ? "details-active" : ""}`}
            onClick={() => setPhoneActiveTab((old) => ({ report: false, info: !old.info }))}
          >
            <span className="si-subheader__text">Anthropometric Information</span>
            <span className="icon-chev-right"></span>
          </div>
          <div className="atheletes-details-content">
            <div className="injury-athelet-profile">
              <div className="injury-athelet-profile-img">
                <img src="../../images/person.png" alt="" />
              </div>
              <h3 className="injury-athelet-profile-name">
                {athleteDetails?.athlete_name ? athleteDetails?.athlete_name : ""}
              </h3>
            </div>
            <div className="Anthropometric-Information">
              <div className="Anthropometric-Information__content">
                <div className="content-row">
                  <span className="si-title">Gender:</span>
                  <span className="text">{athleteDetails?.gender ? athleteDetails?.gender : "-"}</span>
                </div>
                <div className="content-row">
                  <span className="si-title">Age:</span>
                  <span className="text">{athleteDetails?.age ? athleteDetails?.age : "-"}</span>
                </div>
                <div className="content-row">
                  <span className="si-title">Height:</span>
                  <span className="text">{athleteDetails?.height ? athleteDetails?.height : "-"}</span>
                </div>
                <div className="content-row">
                  <span className="si-title">Weight:</span>
                  <span className="text">{athleteDetails?.weight ? athleteDetails?.weight : "-"}</span>
                </div>
                <div className="content-row">
                  <span className="si-title">BMI: </span>
                  <span className="text">{athleteDetails?.bmi ? athleteDetails?.bmi : "-"}</span>
                </div>
                <div className="content-row">
                  <span className="si-title">Body fat percentage:</span>
                  <span className="text">{athleteDetails?.body_fat_perc ? athleteDetails?.body_fat_perc : "-"}</span>
                </div>
                <div className="content-row">
                  <span className="si-title">Past injuries:</span>
                  <span className="text">{athleteDetails?.past_injury ? athleteDetails?.past_injury : "-"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="information-main__right">
        <div className="report-details">
          <div
            className={`si-subheader ${phoneActiveTab.report ? "report-active" : ""}`}
            onClick={() => setPhoneActiveTab((old) => ({ report: !old.report, info: false }))}
          >
            <span className="si-subheader__text">Reports</span>
            <span className="icon-chev-right"></span>
          </div>
          <div className="report-details-content">
            <div className="table-body__wrapper">
              <div className="report-body__right-body">
                <table className="report-tabl">
                  <thead className="report-table-header">
                    <tr>
                    <th>Latest Reports</th>
                    <th>Date</th>
                    <th>View</th>
                    </tr>
                  </thead>
                  <tbody className="report-table-body data-tbody">
                    {reportList
                      ? reportList?.map((report) => {
                          return (
                            <tr key={report.function_id}>
                              <td>
                                <span className=""> {report.latest_report}</span>
                              </td>
                              <td>
                                <span className="">{formatDate(report.conducted_date)}</span>
                              </td>
                              <td>
                                {report.function_id && report.report_id ? (
                                  <button
                                    type="button"
                                    className="si-viewBtn"
                                    onClick={() =>
                                      navigate(`/testfunctions/${report.function_id}/report/${report.report_id}/${athleteId}`, {
                                        state: { backUrl: -1 },
                                      })
                                    }
                                  >
                                    <span className="si-icon__view"></span>
                                    <span className="si-txt">View Report</span>
                                  </button>
                                ) : (
                                  <span>-</span>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AthleteInformationNutrition;
