import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ExerciseHead from './ExerciseHead';
import ExerciseBody from './ExerciseBody';
import Footer from '../../Common/PopupFooter';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import API from '../../../../utility/api';
import { fetchExerciseMasterFilter } from '../../../../utility/templateApiActions';

export default function AddExercisePopup({ isActive,cancelAction, setIsActive ,exercisePayload,onSubmitAction }) {
    const APILib = new API();
    const selectedMenuId = useSelector(state => state?.sideMenu?.selectedMenuId);
    // const selectedExerciseList = useSelector(state => state?.templates?.selectedExerciseList);
    // const editExercise = useSelector(state => state?.templates?.editExercise);
    const [ activeTab, setActiveTab ] = useState("specs");
    const [exerciseToAdd, setExerciseToAdd] = useState(exercisePayload);
    const [warningPopup, setWarningPopup] = useState({isShow: false});
    const [selectedExerciseNameError , setSelectedExerciseNameError] = useState({
        is_error : false,
        msg:''
    });
    const [sportSetError, setSportSetError] = useState({
        is_error : false,
        msg:''
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(()=>{
    //     if(selectedExerciseNameError.is_error){
    //         toast.warn(selectedExerciseNameError.msg);
    //     }
    // },[selectedExerciseNameError])
    
    useEffect(() => {
        console.log("isActive:",isActive);
        // setExerciseToAdd({ 
        //     ...selectedExerciseList?.find(exercise => exercise?.exercise_id === editExercise?.id) 
        // });
    }, [isActive]);

     useEffect(()=>{
        console.log("Add exercise:",exerciseToAdd);
    },[exerciseToAdd]);

    const addExercise = async() => {
        console.log("Add Excercise: ",exerciseToAdd);
        onSubmitAction(exerciseToAdd);
    };

    const actionBtnHandler = (e) => {
        e.preventDefault();
        if(!exerciseToAdd?.exercise_name) {
            setSelectedExerciseNameError({
                is_error: true,
                msg: "Please add exercise name"
            });
        } else if(!exerciseToAdd?.sport_id?.length) {
            setSportSetError({
                type: 1,
                is_error: true,
                msg: "Please select sport for exercise"
            })
        } else if(!exerciseToAdd?.sets) {
            setSportSetError({
                type: 2,
                is_error: true,
                msg: "Please add exercise sets"
            })
        } else if(!selectedExerciseNameError.is_error) {
            addExercise();
        };
    };

    const checkExerciseExistHandler = (value)=>{
        setSelectedExerciseNameError({
            is_error : false,
            msg:''
        });
        if(value){
            dispatch(
                fetchExerciseMasterFilter({
                    perPage:100,
                    pageNo:1,
                    moduleId:selectedMenuId?.moduleId,
                    searchTerm:value,
                    selectedTag:null,
                    selectedSportIds:null,
                    callback: (res)=>{
                    if(res){
                        console.log("res",res,value);
                        if(res.length){
                            let exactMatch = res.filter((obj)=>{
                                return obj.exercise_name.toLowerCase() === value.toLowerCase();
                            })[0];
                            if(exactMatch){
                                setSelectedExerciseNameError({
                                    is_error : true,
                                    msg:'Exercise with the same name already exists.'
                                });
                            }
                        }
                    }
                    }
                })
            );
        }
    }

    return (
        <div className={`si-custom-popup si-popup ${isActive ? "active" : ""}`}>
            <div className="si-popup-content">
                <ExerciseHead 
                    exerciseToAdd={exerciseToAdd}
                    setExerciseToAdd={setExerciseToAdd}
                    isEditable = {true}
                    onCancel={cancelAction}
                    selectedExerciseNameError={selectedExerciseNameError}
                    setSelectedExerciseNameError={setSelectedExerciseNameError}
                    checkExerciseExist={checkExerciseExistHandler}
                />

                <ExerciseBody 
                    activeTab={activeTab} 
                    setActiveTab={setActiveTab} 
                    exerciseToAdd={exerciseToAdd}
                    setExerciseToAdd={setExerciseToAdd}
                    setWarningPopup={setWarningPopup}
                    sportSetError={sportSetError}
                    setSportSetError={setSportSetError}
                />

                <Footer 
                    isEditExercisePopup={true}
                    isActionBtn={true}
                    btnClass={
                        `si-button 
                        ${activeTab==="notes_media" 
                        ? "si-button--green" : 
                        "si-button si-button--green"}`
                    }
                    actionBtnText={"Save"}
                    actionBtnHandler={actionBtnHandler}
                    selectedTab={activeTab}
                    isExerciseMaster={true}
                />
                {
                    warningPopup && 
                    <ConfirmationPopup 
                        isActive={warningPopup?.isShow}
                        isActionBtn={true}
                        warning={warningPopup?.warning}
                        warningMsg={warningPopup?.warningMsg}
                        okHandler={warningPopup?.okHandler}
                    />
                }
            </div>
        </div>
    );
};
