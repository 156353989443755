import { createAsyncThunk } from '@reduxjs/toolkit';
import { authActions } from '../reducer/auth';
import API from "./api";

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchAthleteGroupList = createAsyncThunk("templates/fetchAthleteGroupList", async (data, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let body = {
        "page_no": data?.pageNo,
        "search": data?.searchTerm,
        "item_number": 20,
        "user_guid": userData.user_guid,
        "sport_id": data?.selectedSports
    };
    try{
        return {
            callback: data?.callback,
            apiResponse: await postAPIDetails("exercise/getathletegrouplisting", body)
        }
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback: data?.callback, error})
        } else {
            return rejectWithValue({callback: data?.callback, error});
        }
    }
});

export const insertUpdateAthleteGroup= createAsyncThunk("templates/insertUpdateAthleteGroup", async (data, {getState, dispatch, rejectWithValue}) => {
    let {optType, name, assignedAthleteIds ,sportId, callback} = data;
    let state = getState();
    name = optType === 1 ? state?.userManagement?.groupName : name;
    let userData = state?.auth?.userData;
    let selectedSportId = optType === 1 ? state?.userManagement?.selectedSportId : sportId;
    let groupId = optType === 1 ? null : state?.userManagement?.selectedGroup?.group_id;
    assignedAthleteIds = optType === 1 ? [] : assignedAthleteIds
    let body = {
        "group_name":name,
        "assigned_athlete_ids": assignedAthleteIds,
        "user_guid": userData.user_guid,
        "sport_id": selectedSportId,
        "group_id": groupId
    };
    try{
        return {
            callback: callback,
            apiResponse: await postAPIDetails("exercise/insertupdateathletegroup", body)
        }
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});
export const fetchGroupDetailsById = createAsyncThunk("templates/fetchGroupDetails", async ({id ,callback}, {getState,dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            callback,
            apiResponse: await getAPIDetails(`program/getgroupdetailsbyid?group_id=${id}&user_guid=${userData.user_guid}`)
        };
    } catch (error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});
export const fetchAllAthletes = createAsyncThunk("templates/fetchAllAthletes", async (data, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let {pageNo, searchTerm, groupId, userguid,selectedSports,type ,callback} = data;
    let body = {
        "page_no": pageNo,
        "search": searchTerm,
        "item_number": 20,
        "user_guid": userData?.user_guid,
        "group_id": groupId,
        "sport_id":selectedSports,
        "type": type
      };
      try{
          return {
              callback,
              apiResponse: await postAPIDetails(`program/getallathletelisting`,body)
            };
        } catch (error){
            let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
        }
});
export const fetchGroupAthletes = createAsyncThunk("templates/fetchGroupAthletes", async ({pageNo,searchTerm,selectedSportId,id, callback}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let body = {
        "page_no": pageNo,
        "search": searchTerm,
        "item_number": 20,
        "sport_id": selectedSportId ? selectedSportId : null,
        "group_id": id,
        "user_guid": userData.user_guid,
      };
      try{
          return {
              callback,
              apiResponse: await postAPIDetails(`exercise/getaddedathletetogroupandshowlisting`,body)
            };
        } catch (error){
            let statusCode = error?.response?.status
            if(statusCode === 401){
                dispatch(authActions.logout())
                error.message = error?.response?.data?.title && error?.response?.data?.title
                return rejectWithValue({callback, error})
            } else {
                return rejectWithValue({callback, error});
            }
        }
});

export const deleteAthleteFromGroup = createAsyncThunk("exercise/deleteAthleteFromGroup", async (data, {getState,dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData ? userData?.user_guid : null;
    let {athleteId, groupId, callback} = data;
    try {
        return {
            callback,
            apiResponse: await getAPIDetails(`exercise/deleteathletefromgroup?athlete_id=${athleteId}&group_id=${groupId}&user_guid=${userguid}`)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const deleteGroupofAthlete = createAsyncThunk("exercise/deleteGroupofAthlete", async (data, {getState,dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData ? userData?.user_guid : null;
    let {groupId, callback} = data;
    try {
        return {
            callback,
            apiResponse: await getAPIDetails(`exercise/deletegroupofathlete?p_group_id=${groupId}&p_user_guid=${userguid}`)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchAthleteDetails = createAsyncThunk("common/getathletedetails", async ({id ,callback}, {getState,dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        let apiResponse =  await getAPIDetails(`common/getathletedetails?athleteguid=${id}&userguid=${userData.user_guid}`)
        // let healthDetails =   await (
        //     await getAPIDetails(`common/getanthropometricdetails?athlete_guid=${id}&user_guid=${userData?.user_guid}`)
        //   ).data?.data?.value?.data;
        //   if(healthDetails && apiResponse?.data?.data?.value?.data){
        //     apiResponse.data.data.value.data = {...apiResponse.data.data.value.data, ...healthDetails}
        //   }
        return {
            callback,
            apiResponse: apiResponse
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});