import React from "react";
import { useNavigate } from "react-router-dom";

function NutritionSportsListingContent({sports}) {
  const navigate = useNavigate()

  const handleViewDetails = (sportsId) => {
    navigate(String(sportsId))
  };
  return (
    <main className="si-main-content">
      <div className="nutrition-main-content">
        {sports ? sports?.map((sport) => {
          return (
            <div key={sport.sport_id} className="card nutrition-card">
              <div className="card-header">
                <div className="card-header--img">
                  <img src={`${process.env.PUBLIC_URL}/images/nutrition-images/hockey-img.svg`} alt="Sport" />
                </div>
                <div className="card-header--heading">{sport?.sport_name}</div>
              </div>
              <div className="card-body">
                <p className="card-body--content">Meal plans and recommendations for players</p>
              </div>
              <div className="card-footer">
                <button
                  type="button"
                  className="si-viewBtn"
                  onClick={() => {
                    handleViewDetails(sport?.sport_id);
                  }}
                >
                  <span className="si-icon__view"></span>
                  <span className="si-txt">View Details</span>
                </button>
              </div>
            </div>
          );
        }) : null}
      </div>
    </main>
  );
}

export default NutritionSportsListingContent;
