import React, {Fragment, useState, useEffect, useMemo} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loaderActions } from '../../../../reducer/loader';
import { toast } from 'react-toastify';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import CalenderContent from './CalendarContent';
import CalenderDropdown from './CalendarDropdown';
import { fetchSportsList,fetchTechnicalProgramTemplateDetailsById, fetchTechnicalWeekWiseData, fetchTechnicalWholeCalendarData, postTechnicalProgramTemplate, publishTechnicalCalendarSequence } from '../../../../utility/technicalTemplateApiActions';
import { technicalTemplatesActions } from '../../../../reducer/technicalTemplates';

function ProgramCalender() {
  let { id } = useParams();
  const navigate = useNavigate();
  const isAuth = useSelector(state => state?.auth?.isAuthenticated);
  const selectedWeek = useSelector(state => state?.technicalTemplates?.selectedWeek);
  const programNameErrorMsg = useSelector(state => state?.technicalTemplates?.programNameErrorMsg);
  const programNameError = useSelector(state => state?.technicalTemplates?.programNameError);
  const wholeCalendarData = useSelector(state => state?.technicalTemplates?.wholeCalendarData);
  const selectedTemplate = useSelector(state => state?.technicalTemplates?.selectedTemplate);
  const sportList = useSelector(state => state?.technicalTemplates?.sportList);
  const selectedMenuId = useSelector(state => state?.sideMenu?.selectedMenuId);
  const isUpdateDisabled = useSelector(state => state?.technicalTemplates?.isUpdateDisabled);
  const { setIsUpdateDisabled } = technicalTemplatesActions;
  const [headerData, setHeaderData] = useState({
      headerClass:"si-margin si-calheader",
      heading: selectedTemplate?.program_template_name || selectedTemplate?.program_name,
      isHeadingEditable: false,
      isbackBtn: true,
      backBtnAction: () => navigate("/technical/templates"),
      isCalendar: true,
      isCountEditable: false,
      dataCountHeading: selectedTemplate?.duration > 1 ? "Weeks" : "Week",
      isDropdownEditable: false
  });
  const [confirmationPopup, setConfirmationPopup] = useState({isShow: false});
  const dispatch = useDispatch();

  useEffect(() => {
    if(isAuth){
    dispatch(loaderActions?.showLoader());
    dispatch(
      fetchTechnicalProgramTemplateDetailsById({
        id, 
        type:1,
        callback: (data) => setTimeout(() => {
          if(data) {
            dispatch(
              fetchTechnicalWeekWiseData({
                id,
                week: 1,
                callback: () => setTimeout(() => {
                  dispatch(
                    fetchTechnicalWholeCalendarData({id})
                  );
                  dispatch(loaderActions?.hideLoader());
                })
              })
            );
          }
          else {
            toast.warn("No template data.");
            navigate("/technical/templates");
          };
        })
      })
    )
    // if(!selectedTemplate?.duration) navigate("/technical/templates/new");
    // else {
    //   dispatch(fetchWholeCalendarData(() => navigate("/technical/templates")));
    // };
    if(!sportList?.length) dispatch(fetchSportsList());
    }
  }, [isAuth]);

  useEffect(()=>{
    if(programNameError){
      dispatch(loaderActions?.hideLoader());
      toast.warn(programNameErrorMsg);
    }
  },[programNameError,programNameErrorMsg]);

  useEffect(() => {
    setHeaderData({
      ...headerData,
      dataCountHeading: selectedTemplate?.duration > 1 ? "Weeks" : "Week",
      heading: selectedTemplate?.program_template_name || selectedTemplate?.program_name
    });
  }, [selectedTemplate]);

  const isPublish = useMemo(() => () => {
    let isExercise = false;
    for(let i=0; i<wholeCalendarData?.length; i++) {
      for(let j=0; j<wholeCalendarData[i]?.days?.length; j++) {
        if(wholeCalendarData[i]?.days[j]?.exercises?.length) {
          isExercise = true;
          break;
        };
      };
      if(isExercise) break;
    };

    return isExercise;
  }, [wholeCalendarData]);

  const sportOptions = sportList?.map(obj => {
    return {
        value: obj?.sport_id,
        label: obj?.sport_name
    };
  });

  const actionBtn1Handler = () => navigate("/technical/templates");
  const actionBtn2Handler = () => {
    // if(weekWiseCalendarData?.filter(obj => obj?.exercises)?.length){
    if(isPublish()){
      setConfirmationPopup({
        isShow: true,
        isActionBtns: true,
        warning: "Are you sure you want to publish template?",
        warningMsg: "",
        cancelHandler: () => setConfirmationPopup({isShow: false}),
        okHandler: () => {
          setConfirmationPopup({isShow: false});
          dispatch(
            publishTechnicalCalendarSequence({
              id, 
              type: 1,
              callback: () => setTimeout(() => {
                dispatch(
                  postTechnicalProgramTemplate({
                    optType: 2,
                    moduleId: selectedMenuId?.id,
                    type: 1,
                    name: selectedTemplate?.program_template_name || selectedTemplate?.program_name,
                    duration: selectedTemplate?.duration,
                    sportId: selectedTemplate?.sport_id,
                    isPublish: 1
                  })
                )
              })
            })
          );
          toast.success("Template published successfully");
          navigate("/technical/templates");
        }
      })
    } 
    else {
      toast.warning("Please add an exercise to publish the template.")
    }
  };

  const editAction = (e) => {
    e?.preventDefault();
    setHeaderData({
      ...headerData, 
      isHeadingEditable: true,
      isCountEditable: true,
      isDropdownEditable: true
    });
  };

  const saveAction = (e, templateName, duration, selectedSport) => {
    e?.preventDefault();
    dispatch(technicalTemplatesActions.setDefault());
    if(templateName && duration && selectedSport?.value) {
      setConfirmationPopup({
        isShow: true,
        isActionBtns: true,
        warning: "Are you sure you want to save the data?",
        warningMsg: "",
        cancelHandler: () => setConfirmationPopup({isShow: false}),
        okHandler: () => {
          dispatch(loaderActions?.showLoader());
          setConfirmationPopup({isShow: false});
          dispatch(postTechnicalProgramTemplate({
            optType: 2,
            moduleId: selectedMenuId?.id,
            name: templateName,
            duration,
            sportId: selectedSport?.value,
            isPublish: selectedTemplate?.is_publish,
            callback: () => setTimeout(() => {
              dispatch(fetchTechnicalWeekWiseData({
                  id,
                  week: selectedWeek ? selectedWeek : "1",
                  callback: () => setTimeout(() => {
                    dispatch(loaderActions?.hideLoader());
                  })
              }));
              setHeaderData({
                ...headerData, 
                isHeadingEditable: false,
                isCountEditable: false,
                isDropdownEditable: false
              });
              dispatch(setIsUpdateDisabled(false));
              toast.success("Template updated successfully.", {
                toastId: "template-calendar-details-update-success"
            });
            })
          }));
        }
      });
    } else {
      console.log("Post Template Error");
    };
  };

  const cancelAction = () => {
    setConfirmationPopup({
      isShow: true,
      isActionBtns: true,
      warning: "Are you sure you want to discard your changes?",
      warningMsg: "",
      cancelHandler: () => setConfirmationPopup({isShow: false}),
      okHandler: () => {
        setConfirmationPopup({isShow: false});
        setHeaderData({
          headerClass:"si-margin si-calheader",
          heading: selectedTemplate?.program_template_name || selectedTemplate?.program_name,
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate("/technical/templates"),
          isCalendar: true,
          isCountEditable: false,
          dataCountHeading: selectedTemplate?.duration > 1 ? "Weeks" : "Week",
          isDropdownEditable: false
        });
      }
    });
  };

  // console.log(weekWiseCalendarData, wholeCalendarData, selectedTemplate, isNewTemplate, "<====weekwiseData")

  return (
    <Fragment>
      <Header
        headerData={headerData}
        dataCount={selectedTemplate?.duration ? selectedTemplate?.duration : ""}
        dropdownPlaceHolder={"Select Sport"}
        dropdownValue={sportOptions?.find(obj => (obj?.label?.toLowerCase() === selectedTemplate?.sport_name?.toLowerCase()) || obj?.value == selectedTemplate?.sport_id)}
        dropdownOptions={sportOptions}
        editAction={editAction}
        saveAction={saveAction}
        cancelAction={cancelAction}
      />
      <CalenderDropdown 
        weeks={selectedTemplate?.duration ? selectedTemplate?.duration : ""} 
      />
      <CalenderContent 
        confirmationPopup={confirmationPopup} 
        setConfirmationPopup={setConfirmationPopup}
        headerData={headerData}
        setHeaderData={setHeaderData}
        setIsUpdateDisabled={setIsUpdateDisabled}
      />
      {/* <Footer 
        isActionBtns={true}
        btn1Class={"si-button si-button--transparent d-none"}
        actionBtn1Text={"Cancel"}
        actionBtn1Handler={actionBtn1Handler}
        btn2Class={"si-button si-button--green"}
        btn2Disabled= {selectedTemplate?.is_publish ? isUpdateDisabled : !isPublish()}
        actionBtn2Text={!selectedTemplate?.is_publish ? "publish" : "update"}
        actionBtn2Handler={actionBtn2Handler}
      /> */}
      {/* <FixedButton btnName="Publish"
                        onBtnClick={actionBtn2Handler}/> */}
    </Fragment>
  );
};

export default ProgramCalender;