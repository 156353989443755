import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListingTable from "../../Common/ListingTableMUI";
import { v4 as uuidv4 } from "uuid";

function PerformanceTrackerContent({
  pagination,
  performanceLists,
  setPageNo,
  pageNo,
  handleTrackPerformance,
  isAdmin,
}) {
  const [isActionActive, setIsActionActive] = useState(Array(performanceLists?.length)?.fill(false));

  useEffect(() => {
    window?.addEventListener("click", handleKeydownEvent);
    return () => window?.removeEventListener("click", handleKeydownEvent);
  }, []);

  const handleKeydownEvent = (event) => {
    setIsActionActive(Array(performanceLists?.length)?.fill(false));
  };
  const handleAction = (e, idx) => {
    e?.stopPropagation();
    setIsActionActive((prevState) => {
      let newState = Array(performanceLists?.length)?.fill(false);
      newState[idx] = !prevState[idx];
      return [...newState];
    });
  };
  const tableHeaders = [
    {
      label: "Tournament Name",
      align: "left",
    },
    {
      label: "Sports",
      align: "left",
    },
    {
      label: "Athletes",
      align: "center",
    },
    {
      label: "",
      align: "center",
      stickyClass: "stickyCell-right",
    }
  ];

  const addMoreActionBtnHeader = {
                label: "",
                align: "center",
                stickyClass: "stickyCell-right",
              }
  const getHeaders = ()=>{
    return isAdmin() ? [...tableHeaders, addMoreActionBtnHeader] : tableHeaders
  }
  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {performanceLists?.length ? (
          performanceLists?.map((performance, idx) => {
            return (
              <TableRow key={uuidv4()}>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                  onClick={(e) => handleTrackPerformance(e, performance)}
                >
                  {performance?.tournament_name}
                </TableCell>
                <TableCell align={"left"}>
                  {performance?.sport_name?.length ? (
                    //   performance?.sport_name
                    performance?.sport_name?.length > 2 ? (
                      <>
                        {`${performance?.sport_name[0]}, ${performance?.sport_name[1]}`}
                        <span className="si-custom-table si-count"> +{performance?.sport_name?.length - 2}</span>
                      </>
                    ) : (
                      performance?.sport_name
                        ?.map((name, index) => (index + 1 === performance.sport_name.length ? name : name + ","))
                        .join(" ")
                    )
                  ) : (
                    "-"
                  )}
                </TableCell>

                <TableCell align={"center"}>{performance?.athletes ? performance.athletes : "-"}</TableCell>

                <TableCell align={"right"} className="stickyCell-right">
                  <button type="button" className="si-viewBtn" onClick={(e) => handleTrackPerformance(e, performance)}>
                    <span className="si-icon__view"></span>
                    <span className="si-txt">View Score</span>
                  </button>
                </TableCell>
                {isAdmin() ? (
                  <TableCell align={"center"} className="stickyCell-right">
                    <div className="si-custom-table__cell si-wrap" style={{ margin: "auto" }}>
                      <button
                        type="button"
                        className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                        onClick={(e) => handleAction(e, idx)}
                      ></button>
                      <div className="si-action-icons">
                        <button
                          type="button"
                          className="si-icon-button"
                          onClick={(e) => handleTrackPerformance(e, performance)}
                        >
                          <span className="icon-edit"></span>edit
                        </button>
                      </div>
                    </div>
                  </TableCell>
                ) : null}
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  return (
    <>
      <ListingTable
        tableHeaders={getHeaders()}
        getTableBody={getTableBody}
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={pagination}
        perPage={20}
      />
    </>
  );
}

export default PerformanceTrackerContent;
