import React, {useState, useEffect} from 'react';
import PopupFooter from './PopupFooter';

function NotesPopup({isActive, isEditable, subtitle, noteDescription, isActionBtn, actionBtnText, actionBtnHandler, closeHandler}) {
    const [notes, setNotes] = useState(null);

    useEffect(() => {
        setNotes(noteDescription?.trim() ? noteDescription?.trim() : null);
    }, [isActive, noteDescription]);

    return (
        <div className={`si-popup ${isActive ? "active" : ""}`}>
            <div className="si-popup-content si-notes">
                <button 
                    type="button" 
                    className="si-icon__button si-button__close"
                    onClick={closeHandler}
                >
                    <span className="icon-close"></span>
                </button>
                <div className="si-popup-body">
                    <div className="content-wrapper">
                        
                        <div className="content-title">
                            <h5>Notes</h5>
                        </div>
                        <div className="si-subTitle">{subtitle}</div>
                        
                        <div className="content-description">
                            <textarea 
                                className="si-txtArea" 
                                disabled={!isEditable}
                                value={notes ? notes : ""}
                                onChange={(e) => setNotes(e?.target?.value)}
                            >
                            </textarea>
                        </div>
                    </div>
                </div>
                <PopupFooter 
                    isActionBtn={isActionBtn}
                    isBtnDisabled={!notes && !noteDescription}
                    btnClass={"si-button si-button--green"}
                    actionBtnText={actionBtnText}
                    actionBtnHandler={() => actionBtnHandler(notes?.trim())}
                />
            </div>
        </div>
    );
};

export default NotesPopup;