import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListingTable from '../../Common/ListingTableMUI';
import EditExercisePopup from '../../TechnicalTraining/Common/EditExercise/EditExercisePopup';
import { templatesActions } from '../../../../reducer/templates';
import { programsActions } from '../../../../reducer/programs';
import { technicalProgramsActions } from '../../../../reducer/technicalPrograms';
import { technicalTemplatesActions } from '../../../../reducer/technicalTemplates';

function Content({id,type,pageDetails}) {
    const selectedExerciseList = useSelector(state => type===2 ? state?.technicalPrograms?.selectedExerciseList : state?.technicalTemplates?.selectedExerciseList);
    const setEditExerciseId = type===2 ? technicalProgramsActions?.setEditExerciseId : technicalTemplatesActions?.setEditExerciseId;
    const seletedExercise = type===2 ? technicalProgramsActions?.seletedExercise : technicalTemplatesActions?.seletedExercise;
    const [isActionActive, setIsActionActive] = useState(Array(selectedExerciseList?.length)?.fill(false));
    const [isPopupActive, setIsPopupActive] = useState(false);
    const [exerciseToEdit,setExerciseToEdit] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window?.addEventListener("click", handleKeydownEvent);

        return () => window?.removeEventListener("click", handleKeydownEvent);
    }, []);

    useEffect(() => {
        if(pageDetails && !selectedExerciseList?.length) {
            navigate(`${pageDetails.navigateTo}/${id}/calendar/exercisemaster`);
        };
    }, [pageDetails]);

    useEffect(() => {
        setIsActionActive(Array(selectedExerciseList?.length)?.fill(false));
    }, [selectedExerciseList]);

    const handleKeydownEvent = (event) => {
        setIsActionActive(Array(selectedExerciseList?.length)?.fill(false));
    };

    const handleAction = (e, idx) => {
        e?.stopPropagation();
        setIsActionActive(prevState => {
            let newState = Array(selectedExerciseList?.length)?.fill(false);
            newState[idx] = !prevState[idx];
            return [ ...newState ];
        });
    };

    const editExerciseHandler = (e, exercise)=> {
        e?.stopPropagation();
        dispatch(
            setEditExerciseId({
                exerciseId: exercise?.exercise_id,
                // callback: () => navigate("/programtemplates/editexercise")
                callback: () => setIsPopupActive(true)
            })
        );
    };

    const deleteExerciseHandler = (e, exercise) => {
        e?.stopPropagation();
        dispatch(
            seletedExercise({
                type: "individual",
                isSelected: false,
                selectedLength: selectedExerciseList?.length,
                data: exercise
            })
        );
    };

    const tableHeaders = [
        {
            label: "Exercise Name",
            align: "left"
        },
        {
            label: "Sports",
            align: "center"
        },
        {
            label: "Tags",
            align: "center"
        },
        {
            label: "Sets",
            align: "center"
        },
        {
            label: "",
            align: "center",
            stickyClass: "stickyCell-right" 
        }
    ];

    const getTableBody = () => {
        return (
            <TableBody sx={{backgroundColor:"#181818", maxHeight:"30vh", overflow:"scroll"}}>
                {
                    selectedExerciseList?.length
                    ?
                    selectedExerciseList?.map((exercise, idx) => {
                        let isSelected = selectedExerciseList?.find(
                            obj => obj?.exercise_id === exercise?.exercise_id
                        );
                        let reps = exercise?.metrics_units?.find(metric => metric?.metric_id==9);
                        let sports = exercise?.sport_name;
                        let sportsCount = sports?.length;
                        let sportName = sportsCount > 2 ? `${sports?.slice(0, 2)?.join(", ")}` : sports?.join(", ");
                        let otherCount = sportsCount > 2 ? `+${sportsCount - 2}` : "";
                        let tags = exercise?.activity_tags;
                        let tagsCount = tags?.length;
                        let tagsName = tagsCount > 2 ? `${tags?.slice(0, 2)?.join(", ")}` : tags?.join(", ");
                        let tagsOtherCount = tagsCount > 2 ? `+${tagsCount - 2}` : "";
                            
                        return (
                            <TableRow 
                                key={uuidv4()}
                            >
                                <TableCell 
                                    align={"left"}
                                    sx={{cursor: "pointer", minWidth: 150}}
                                    onClick={(e) => editExerciseHandler(e, exercise)}
                                >
                                    { exercise?.exercise_name }
                                </TableCell>
                                <TableCell align={"center"}>
                                    { sportName ? sportName : "-" }
                                    {
                                        otherCount && 
                                        <span className="si-custom-table si-count">{ otherCount }</span>
                                    }
                                </TableCell>
                                <TableCell align={"center"}>
                                    { tagsName ? tagsName : "-" }
                                    {
                                        tagsOtherCount && 
                                        <span className="si-custom-table si-count">{ tagsOtherCount }</span>
                                    }
                                </TableCell>
                                <TableCell align={"center"}>
                                    { exercise?.sets ? exercise?.sets : "-" }
                                </TableCell>
                                {/* <TableCell align={"center"}>
                                    { reps?.value ? reps?.value : "-" }
                                </TableCell> */}
                                {/* <TableCell align={"center"}>
                                    <button 
                                        type="button" 
                                        className="si-icon__button si-icon__media" style={{margin:"auto"}}
                                    ></button>
                                </TableCell> */}
                                <TableCell align={"center"} className="stickyCell-right">
                                    <div className="si-custom-table__cell si-wrap" style={{margin:"auto"}}>
                                        <button 
                                            type="button" 
                                            className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                                            onClick={(e) => handleAction(e, idx)}
                                        ></button>
                                        <div className="si-action-icons">
                                            <button 
                                                type="button" 
                                                className="si-icon-button"
                                                onClick={(e) => editExerciseHandler(e, exercise)}
                                            >
                                                <span className="icon-edit"></span>edit
                                            </button>
                                            <button 
                                                type="button" 
                                                className="si-icon-button"
                                                onClick={(e) => deleteExerciseHandler(e, exercise)}
                                            >
                                                <span className="icon-delete"></span>remove
                                            </button>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={tableHeaders.length} >
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };

    return (
        <Fragment>
            <EditExercisePopup 
                isActive={isPopupActive} 
                cancelAction={() => setIsPopupActive(false)}
                saveAction={() => setIsPopupActive(false)} 
                tabLists={["specs", "recurrence", "notes_media"]}
                type={type}
            />

            <div className="si-main-content">
                <ListingTable 
                    tableHeaders={tableHeaders}
                    getTableBody={getTableBody}
                />
            </div>
        </Fragment>
    );
};

export default Content;