import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink, PDFViewer, usePDF } from "@react-pdf/renderer";
import PdfDocument from "./PdfDocument";
import { format, addWeeks, addDays } from "date-fns";
import { toast } from "react-toastify";

const buttonStyle = StyleSheet.create({
  downloadBtn: {
    fontSize: "12px",
    fontWeight: "500",
    // display:"inline-block",
    border: "1px solid #282828",
    background: "#fff",
    padding: "6px 8px",
    borderRadius: "5px",
    cursor: "pointer",
    textDecoration: "none",
    color: "#282828",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
    height: "100%",
  },
});
// const dayShortName = {
//   1: "Mon",
//   2: "Tue",
//   3: "Wed",
//   4: "Thu",
//   5: "Fri",
//   6: "Sat",
//   7: "Sun",
// };
const CurrentProgramPdfButton = ({ programDetails, unitList, startDate, userData, selectedProgramDetails }) => {
  let [userClick, setUserClick] = useState(false);
  let [weeksDateArray, setWeeksDateArray] = useState([]);
  const [instance, update] = usePDF({
    document: (
      <PdfDocument
        programDetails={programDetails}
        unitList={unitList}
        weeksDateArray={weeksDateArray}
        userData={userData}
        selectedProgramDetails = {selectedProgramDetails}
      />
    ),
  });
  useEffect(() => {
    if (!instance.loading && instance.url && userClick) {
      let link = document.createElement("a");
      link.href = instance.url;
      link.download = `${selectedProgramDetails.program_name}.pdf`;
      link.click();
      setUserClick(false);
    }
  }, [instance, selectedProgramDetails]);

  useEffect(() => {
    if (userClick) {
      console.log("usercliked", userClick);
      // allow ui to updated based on clicked
      setTimeout(() => {
        update();
      });
    }
  }, [userClick]);

  useEffect(() => {
    let weeksDateArray = [];
    if (startDate) {
      let weeksArray = programDetails?.map((weeks, weekIndex) => {
        return weeks?.days?.map((days, daysIndex) => {
          let addDay = addDays(new Date(startDate), daysIndex);
          let addWeek = addWeeks(addDay, weekIndex);
          let dateDay = format(addWeek, "EEE");
          let date = format(addWeek, "dd");
          let month = format(addWeek, "MMM");
          let monthNumber = format(addWeek, "MM");
          let year = format(addWeek, "yy");
          return { dateDay, date, month, year, monthNumber };
        });
      });
      weeksDateArray = weeksArray ? [...weeksArray] : [];
    }
    setWeeksDateArray(weeksDateArray);
  }, [startDate, programDetails]);

  const clicked = () => {
    console.log("user click", instance, userClick
    ,programDetails ,
    programDetails?.length ,
    unitList?.length ,
    userData?.first_name ,
    weeksDateArray.length ,
    selectedProgramDetails?.program_name
    );
    // check all the require values before exporting
    if (
      programDetails &&
      programDetails.length &&
      unitList?.length &&
      userData?.first_name &&
      weeksDateArray.length &&
      selectedProgramDetails.program_name
    ) {
      setUserClick(true);
    } else {
      toast.error("Unable to export the file. Please try again.", { toastId: "exportToast" });
    }
  };

  return (
    <button class= {instance.loading || !instance.url || userClick ? "export-btn si-loading" : "export-btn"}
    onClick={() => {
      clicked();
    }}
    disabled={instance.loading}>
            <div class="export-wrapper">
                <span>
                    <img src={process.env.PUBLIC_URL + "/images/pdf-icon.png"} alt="dream 11 icon"/>
                </span>
                <span class="btn-text">Export</span>
            </div>
            <div class="loading-wrapper">
                {/* <div class="loading">
                    <span class="colored-circle"></span>
                    <span class="colored-circle"></span>
                    <span class="colored-circle"></span>
                </div>
                <span class="btn-text">Loading</span> */}
                <img src={process.env.PUBLIC_URL + "/images/gif/loading-btn.gif"} alt="gif-loader"/>
            </div>
        </button>
       
       );
  // return <PdfDocument programDetails = {programDetails} unitList={unitList} weeksDateArray={weeksDateArray} userData={userData}/>
};

export default CurrentProgramPdfButton;
