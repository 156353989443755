import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import GoalsCard from './GoalsCard';
import NoDailyGoals from './NoDailyGoals';
import { NO_SNC_MSG, NO_TECHNICAL_MSG } from '../../../utility/constants';

export default function DailyGoals({ goal }) {
  const navigate = useNavigate();
  const userData = useSelector(state => state?.auth?.userData);
  const onCardClickHandler = (program) => {
    console.log(program);
    let url = '';
    switch (program.module_id) {
      case 1:
        url = `/snc/assignedPrograms/${program.program_id}/progressCalendar/${userData?.user_id}`;
        break;
      case 2:
        url = `/technical/assignedPrograms/${program.program_id}/progressCalendar/${userData?.user_id}`;
        break;
      default:
        break;
    }
    navigate(url, { state: { backUrl: '/' } });
  }

  return (
    <Fragment>
      <div className="si-dailygoal-heading">Daily Goal - {moment(goal?.todays_date).format("Do MMM YY")}</div>
      <div className="si-card-wrapper">
        {
          goal?.snc_goals?.map((program) => {
            return <GoalsCard goal={program} key={program.program_id} onCardClickHandler={onCardClickHandler} />
          })
        }
        {
          goal?.technical_goals?.map((program) => {
            return <GoalsCard goal={program} key={program.program_id} onCardClickHandler={onCardClickHandler} />
          })
        }
        {goal?.snc_goals == null &&
          <NoDailyGoals
            title="SnC Training"
            message={NO_SNC_MSG}/>
        }
        {
          goal?.technical_goals == null &&
          <NoDailyGoals
            title="Technical Training"
            message={NO_TECHNICAL_MSG}/>
        }
      </div>

    </Fragment>
  )
}
