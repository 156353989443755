import React from 'react';
import { toast } from 'react-toastify';

function Tablists({ activeTab, setActiveTab, exerciseToAdd, setWarningPopup }) {

    const TabClickHandler = (name)=>{
        let isError = false;
        let errorData = {
            'title':'',
            'msg':''
        }
        if(name != 'specs'){
            if(!exerciseToAdd?.exercise_name) {
                errorData.title = 'Warning';
                errorData.msg = 'Please add exercise name.';
                isError = true;
            }else if(!exerciseToAdd?.sport_id?.length) {
                errorData.title = 'Warning';
                errorData.msg = 'Please select sport for exercise.';
                isError = true;
            }else if(!exerciseToAdd?.sets){
                errorData.title = 'Warning';
                errorData.msg = 'Please add exercise sets.';
                isError = true;
            };
        }
        if(isError){
            // setWarningPopup({
            //     isShow: true,
            //     warning: errorData.title,
            //     warningMsg: errorData.msg,
            //     okHandler: () => {
            //         setWarningPopup({isShow: false})
            //     }
            // });
            toast.warn(errorData.msg);
            return;
        }
        setActiveTab(name);
    }

    return (
        <div className="si-tab-list">
            <div 
                className={`si-tab-item ${activeTab === "specs" ? "active" : ""}`}
                onClick={() => setActiveTab("specs")}
            >
                <span className="si-icon-tab icon-edit"></span>
                <span>SPECS</span>
            </div>
            <div className={`si-tab-item ${activeTab === "notes_media" ? "active" : ""}`
                }
                onClick={() => setActiveTab('notes_media')}
            >
                <span className="si-icon-tab icon-notes"></span>
                <span>NOTES &amp; MEDIA</span>
            </div>
        </div>
    );
};

export default Tablists;