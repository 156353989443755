import { useEffect, useState } from "react";
function useIntersectionObserver(ref, orignalObj, items = 2,InjuryReportLogsPagination, callback, lastChildCallback) {
  let lastChildCardElement = ref;
  let [visibleItems, setVisibleItems] = useState([]);
  let [observerInit, setObserver] = useState(false)
  let [lastChildVisible, setLastChildVisible] = useState(true)
  let [firstChildVisible, setFirstChildVisible] = useState(false)
  // setting formData
  useEffect(() => {
    if (orignalObj && Object.keys(orignalObj).length > 0 && observerInit) {
      setVisibleItems(Object.keys(orignalObj));
      // setVisibleItems(Object.keys(orignalObj).slice(0, items));
      // console.log('orignalObj', orignalObj)
    }
  }, [orignalObj, items, observerInit]);

  useEffect(() => {
    let lastChildRef = lastChildCardElement;
    // return if visible items length is zero
    if (visibleItems.length === 0 || !lastChildRef.current) return;

    // get last child of the visible items
    // let lastItem = visibleItems.length - 1;
    let lastItem = lastChildRef.current.children?.length -1;
    // need to use when user scroll down back to last
    const lastChild = lastChildRef.current.children[lastItem];
    // get the first child of the visible items
    const firstChild = lastChildRef.current.children[1];

    // creating new Observer
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          callback()
          setFirstChildVisible(true)
        } else {
          setFirstChildVisible(false)
        }
      },
      { root: null, rootMargin: "0px", threshold: 1 }
    );
     // creating new Observer for last child
     const lastChildObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // console.log('inster last child', entries[0], entries)
          setLastChildVisible(true)
        } else {
          // console.log('last child hide' )
          setLastChildVisible(false)
        }
      },
      { root: null, rootMargin: "0px", threshold: 0.9 }
    );
    // disconnect observer if all items are loaded
    // if (Object.keys(orignalObj).length >= InjuryReportLogsPagination?.total) {
      // if (Object.keys(orignalObj).length <= visibleItems.length) {
      // return observer.disconnect();
    // } // else observe first child
    // else 
    if (firstChild) {
      observer.observe(firstChild);
    }
    if(lastChild) {
      // console.log('lastChild Element ', lastChild )
      lastChildObserver.observe(lastChild)
    }
    // cleanup fo loaded items and unobserve
    return () => {
      if (firstChild) return observer.unobserve(firstChild); lastChildObserver.unobserve(lastChild); 
    };
  }, [orignalObj, visibleItems, lastChildCardElement, items]);
  return [setObserver,firstChildVisible, lastChildVisible];
}

export default useIntersectionObserver;
