import { createSlice } from '@reduxjs/toolkit';
import { 
    fetchTemplateList,
    postProgramTemplate,
    fetchProgramTemplateDetailsById,
    fetchExerciseMaster,
    fetchExerciseMasterFilter,
    fetchExerciseDetails,
    postTemplateExercises,
    deleteTemplateById,
    fetchWholeCalendarData,
    fetchWeekWiseData,
    fetchTemoList,
    fetchUnitsList,
    fetchSportsList,
    fetchTagsList,
    publishCalendarSequence,
    fetchAllAthletesList
} from '../utility/templateApiActions';
import { updateExerciseMaster,deleteExerciseMaster} from '../utility/exerciseMasterApiActions';
import { toast } from 'react-toastify';

const initialState = { 
    isUpdateDisabled: true,
    isNewTemplate: false,
    programName: "",
    duration: "",
    selectedSportId: "",
    programNameError: false,
    programNameErrorMsg: "",
    durationError: false,
    durationErrorMsg: "",
    isActiveOnMob: false,
    selectedSportError: false,
    selectedSportErrorMsg: "",
    templateListing: null,
    exerciseMasterList: null,
    exerciseMasterFilteredList:null,
    selectedExerciseList: null,
    paginationObject: null,
    selectedTemplate: null,
    wholeCalendarData: null,
    addExerciseDay: null,
    selectedWeek: 1,
    weekWiseCalendarData: null,
    editExercise: null,
    tempoList: null,
    unitsList: null,
    sportList: null,
    tagList: null,
    athleteListing: null
};

const isPositiveInteger = (num) => {
    return num > 0 && Number.isInteger(num);
};

const templatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers : {
        setIsUpdateDisabled: (state, action) => {
            state.isUpdateDisabled = action?.payload;
        },
        
        setIsActiveOnMob: (state) => {
            state.isActiveOnMob = !state?.isActiveOnMob;
        },
        setProgramName: (state, action) => {
            state.programName = action?.payload?.name?.trim()?.length && action?.payload?.name?.trim()?.length <= 50
                                    ? action?.payload?.name
                                    : action?.payload?.name?.trim()?.length && action?.payload?.name?.trim()?.length > 50
                                    ? state?.programName
                                    : "";
            state.programNameError = action?.payload?.name?.trim()?.length && action?.payload?.name?.trim()?.length <= 50 
                                    ? false 
                                    : true;
            state.programNameErrorMsg = action?.payload?.name?.trim()?.length && action?.payload?.name?.trim()?.length > 50 
                                    ? "Template name should be of 50 characters or less."
                                    : !action?.payload?.name?.trim()?.length
                                    ? "Template name is required."
                                    : "";
            if(action?.payload?.callback) action?.payload?.callback();
        },
        setDuration: (state, action) => {
            let duration = parseInt(action?.payload?.duration);
            state.duration = isPositiveInteger(duration) && duration < 100
                                ? duration 
                                : isPositiveInteger(duration) && duration >= 100
                                ? state.duration
                                : "";
            state.durationError = action?.payload?.duration?.trim() ? false : true;
            state.durationErrorMsg = !action?.payload?.duration?.trim() ? "Duration is required." : "";
            if(action?.payload?.callback) action?.payload?.callback();
        },
        setSelectedSportId: (state, action) => {
            state.selectedSportId = action?.payload?.sportId;
            state.selectedSportError = action?.payload?.sportId?.trim() ? false : true;
            state.selectedSportErrorMsg = !action?.payload?.sportId?.trim() ? "Sport is required." : "";
            if(action?.payload?.callback) action?.payload?.callback();
        },
        setErrors: (state) => {
            if(!state?.programName) {
                state.programNameError = true;
                state.programNameErrorMsg = "Template name is required.";
            };
            if(!state?.duration) {
                state.durationError = true;
                state.durationErrorMsg = "Duration is required.";
            };
            if(!state?.selectedSportId) {
                state.selectedSportError = true;
                state.selectedSportErrorMsg = "Sport is required.";
            };
        },
        setDefault: (state) => {
            state.programName = "";
            state.duration = "";
            state.selectedSportId = "";
            state.programNameError = false;
            state.durationError = false;
            state.selectedSportError = false;
            state.programNameErrorMsg = '';
            state.durationErrorMsg = '';
            state.selectedSportErrorMsg = '';
        },
        setDayToAddExercise: (state, action) => {
            if(action?.payload?.day) {
                state.selectedExerciseList = null;
                state.addExerciseDay = action?.payload?.day;
                let navigateUrl = action?.payload?.navigateTo ? `${action?.payload?.navigateTo}${action?.payload?.id}/calendar/exercisemaster` : `/snc/templates/${action?.payload?.id}/calendar/exercisemaster`;
                action?.payload?.navigate(navigateUrl);
            };
        },
        seletedExercise: (state, action) => {
            let newData;
            let newSelectedList = [];
            let sequence_no = action?.payload?.selectedLength ? action?.payload?.selectedLength + 1 : 1;
            let targetDay = state.addExerciseDay;
            let targetDayObj = state.weekWiseCalendarData?.find(obj => obj?.day_no == targetDay);
            if(targetDayObj?.exercises?.length) {
                sequence_no += targetDayObj?.exercises?.length;
            };
            if(action?.payload?.type==="individual") {
                newData = {
                    ...action?.payload?.data,
                    "sequence_no": sequence_no,
                    "description": action?.payload?.data?.exercise_desc,
                    "sets": action?.payload?.data?.default_sets,
                    "metrics_units": action?.payload?.data?.metric_json,
                    "activity_tags": action?.payload?.data?.exercise_tag,
                    "media": action?.payload?.data?.media_json,
                    // "added_by_id": 1,
                    "isSelected": true,
                    "recurrence": [
                        {
                            "week_no": state?.selectedWeek,
                            "day_no": parseInt(state?.addExerciseDay) || 1,
                            "id": null,
                            "optype": "1"
                        }
                    ],
                };
                delete newData["exercise_desc"];
                delete newData["default_sets"];
                delete newData["metric_json"];
                delete newData["media_json"];
                delete newData["exercise_tag"];
            } else {
                if(action?.payload?.type==="calendar" && action?.payload?.action === "edit") {
                    let updatedRecurrence = null;
                    if(action?.payload?.selectedAction === "current"){
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            if(obj?.week_no==action?.payload?.week && obj?.day_no==action?.payload?.day) {
                                return {
                                    ...obj,
                                    optype: "2"
                                };
                            };
                            return { ...obj, optype: obj?.optype==="1" ? "1" : "4" };
                        });
                    } 
                    else if(action?.payload?.selectedAction === "allRecurrence") {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            return { ...obj, optype: obj?.optype==="1" ? "1" : "2" };
                        });
                    }
                    else if(action?.payload?.selectedAction === "proceeding") {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            if((obj?.week_no==action?.payload?.week && obj?.day_no>=action?.payload?.day) || (obj?.week_no > action?.payload?.week)) {
                                return {
                                    ...obj,
                                    optype: obj?.optype==="1" ? "1" : "2"
                                };
                            };
                            return { ...obj, optype: obj?.optype==="1" ? "1" : "4" };
                        });
                    }
                    else {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            if(obj?.week_no==action?.payload?.week && obj?.day_no==action?.payload?.day) {
                                return {
                                    ...obj,
                                    optype: "2"
                                };
                            };
                            return { ...obj };
                        });
                    };
                    state.selectedExerciseList?.forEach(exercise => {
                        if(exercise?.id==action?.payload?.data?.id) {
                            exercise?.recurrence?.forEach(recObj => {
                                let isExist = updatedRecurrence?.find(obj => obj?.week_no==recObj?.week_no && obj?.day_no==recObj?.day_no);
                                if(!isExist) updatedRecurrence?.push({ ...recObj, optype: "3" });
                            });
                        };
                    });
                    newData = { ...action?.payload?.data, recurrence: updatedRecurrence ? [ ...updatedRecurrence ] : null };
                    // let updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                    //     if(obj?.week_no==action?.payload?.week && obj?.day_no==action?.payload?.day) {
                    //         return {
                    //             ...obj,
                    //             optype: "2"
                    //         };
                    //     };
                    //     return { ...obj };
                    // });
                    // newData = { ...action?.payload?.data, recurrence: updatedRecurrence ? [ ...updatedRecurrence ] : null };
                };
                if(action?.payload?.type==="calendar" && action?.payload?.action === "delete") {
                    let updatedRecurrence = null;
                    if(action?.payload?.selectedAction === "current"){
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            // add id check to delete only one exercise
                            if(obj?.week_no==action?.payload?.week && obj?.day_no==action?.payload?.day  && obj?.id == action.payload.data.id) {
                                return {
                                    ...obj,
                                    optype: "3"
                                };
                            };
                            return { ...obj, optype: "2" };
                        });
                    } 
                    else if(action?.payload?.selectedAction === "allRecurrence") {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            return { ...obj, optype: "3" };
                        });
                    }
                    else {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            if(obj?.week_no==action?.payload?.week && obj?.day_no>=action?.payload?.day) {
                                return {
                                    ...obj,
                                    optype: "3"
                                };
                            };
                            return { ...obj, optype: "2" };
                        });
                    };
                    newData = { ...action?.payload?.data, recurrence: updatedRecurrence ? [ ...updatedRecurrence ] : null };
                };
                if(action?.payload?.type === "master" && action?.payload?.action === 'edit') {
                    newData = {
                        ...action?.payload?.data,
                        "description": action?.payload?.data?.exercise_desc,
                        "sets": action?.payload?.data?.default_sets,
                        "metrics_units": action?.payload?.data?.metric_json,
                        "activity_tags": action?.payload?.data?.exercise_tag,
                        "media": action?.payload?.data?.media_json,
                        // "added_by_id": 1,
                        "is_active": 1
                    };
                    delete newData["exercise_desc"];
                    delete newData["default_sets"];
                    delete newData["metric_json"];
                    delete newData["media_json"];
                    delete newData["exercise_tag"];
                    delete newData["sport_name"];
                    delete newData["rno"];
                };
            };

            if(action?.payload?.type === "all") {
                newSelectedList = action?.payload?.isSelected 
                                    ? [ 
                                        ...state?.exerciseMasterList?.map((obj, idx) => {
                                            let newObj = {
                                                ...obj,
                                                "sequence_no": idx + 1,
                                                "description": obj?.exercise_desc,
                                                "sets": obj?.default_sets,
                                                "metrics_units": obj?.metric_json,
                                                "activity_tags": obj?.exercise_tag,
                                                "media": obj?.media_json,
                                                // "added_by_id": 1,
                                                "isSelected": true,
                                                "recurrence": [
                                                    {
                                                      "week_no": state?.selectedWeek,
                                                      "day_no": parseInt(state?.addExerciseDay) || 1,
                                                      "id": "",
                                                      "optype": "1"
                                                    }
                                                ],
                                            };
                                            delete newObj["exercise_desc"];
                                            delete newObj["default_sets"];
                                            delete newObj["metric_json"];
                                            delete newObj["media_json"];
                                            delete newObj["exercise_tag"];

                                            return newObj;
                                        })
                                    ] 
                                    : null;
            }
            else if(state.selectedExerciseList?.length && action?.payload?.type!=="calendar" && action?.payload?.type!=="master") {
                if(action?.payload?.isSelected) {
                    newSelectedList = [ 
                        ...state?.selectedExerciseList, 
                        newData
                    ];
                } else {
                    newSelectedList = state?.selectedExerciseList?.filter(
                        exercise => exercise?.exercise_id !== action?.payload?.data?.exercise_id
                    );
                    sequence_no -= 1;
                    newSelectedList?.map((obj, idx) => {
                        return { ...obj, sequence_no: idx + 1 };
                    });
                }
            }
            else {
                newSelectedList?.push(newData);
            };
            state.selectedExerciseList = newSelectedList;
            if(action?.payload?.callback) action?.payload?.callback();
        },
        setSelectedTemplate: (state, action) => {
            state.selectedTemplate = action?.payload?.data;
            state.programName = action?.payload?.data?.program_template_name;
            state.duration = action?.payload?.data?.duration;
            if(action?.payload?.isNewTemplate) state.isNewTemplate = action?.payload?.isNewTemplate;
        },
        setEditExerciseId: (state, action) => {
            state.editExercise = { 
                day: action?.payload?.day,
                id: action?.payload?.exerciseId
            };
            console.log(state.editExercise);
            if(action?.payload?.callback) action?.payload?.callback();
        },
        saveEditedExercise: (state, action) => {
            let selectedExercise = action?.payload?.selectedExercise;
            let editedExercise = action?.payload?.editedExercise;
            // let isSequenceZero = false;
           
            let editedSelectedExercise = selectedExercise?.map(obj => {
                if(obj?.exercise_id == editedExercise?.exercise_id) {
                    let recurrenceObj = [];
                    obj?.recurrence?.forEach(selectedExe => {
                        let matchedExe = editedExercise?.recurrence?.find(editedExe => selectedExe?.week_no==editedExe?.week_no && selectedExe?.day_no==editedExe?.day_no);
                        if(matchedExe) recurrenceObj?.push({ ...selectedExe, optype: selectedExe?.id ? selectedExe?.optype : "1" });
                        else if(!matchedExe && selectedExe?.id) recurrenceObj?.push({ ...selectedExe, optype: "3" });
                    });
                    editedExercise?.recurrence?.forEach(editedExe => {
                        let matchedExe = recurrenceObj?.find(obj => obj?.week_no==editedExe?.week_no && obj?.day_no==editedExe?.day_no);
                        if(!matchedExe) recurrenceObj?.push({ ...editedExe });
                    });
                    // if(recurrenceObj?.length > 1) {
                    //     isSequenceZero = true;
                    //     return { ...editedExercise, recurrence: [ ...recurrenceObj ], sequence_no: 0 };
                    // };
                    let sequence_no = (recurrenceObj?.length > 1) ? 0 : editedExercise?.sequence_no; 
                    return { ...editedExercise, sequence_no, recurrence: [ ...recurrenceObj ] };
                };
                return { ...obj };
            });

            state.selectedExerciseList = [ ...editedSelectedExercise ];
            if(action?.payload?.callback) action?.payload?.callback();
        },
        setExerciseSequence: (state, action) => {
            let { week, day, data } = action?.payload;
            let newWholeCalendarData = state.wholeCalendarData?.map(weekObj => {
                let newDays = [];
                if(weekObj?.week_no==week) {
                    newDays = weekObj?.days?.map(dayObj => {
                        if(dayObj?.day_no==day) {
                            let exercises = dayObj?.exercises;
                            let [draggedExecise] = exercises?.splice(data?.source?.index, 1);
                            exercises?.splice(data?.destination?.index, 0, draggedExecise);
                        };
                        return dayObj;
                    });
                    return { ...weekObj, days: newDays};
                };
                return { ...weekObj};
            });
            let newWeekwiseCalendarData = state.weekWiseCalendarData?.map(dayObj => {
                if(dayObj?.day_no==day) {
                    let exercises = dayObj?.exercises;
                    let [draggedExecise] = exercises?.splice(data?.source?.index, 1);
                    exercises?.splice(data?.destination?.index, 0, draggedExecise);
                };
                return dayObj;
            });
            state.wholeCalendarData = [ ...newWholeCalendarData ];
            state.weekWiseCalendarData = [ ...newWeekwiseCalendarData ];
        }
    },
    extraReducers: (builder) => {
        builder.addCase("auth/logout", (state) => {
            state.isActiveOnMob = false;
        });
        builder.addCase(postProgramTemplate.fulfilled, (state, action) => {
            const { callback, apiResponse } = action?.payload;
            if(apiResponse?.data?.data?.value?.template?.length) {
                state.selectedTemplate = { 
                    ...apiResponse?.data?.data?.value?.template[0], 
                    program_templateid: apiResponse?.data?.data?.value?.template[0]?.program_id
                };
            };  
            if(action?.payload?.isNewTemplate) state.isNewTemplate = action?.payload?.isNewTemplate;
            if(callback) callback(apiResponse?.data?.data?.value?.template[0]?.program_id);
            // console.log(apiResponse?.data);
        });
        builder.addCase(postProgramTemplate.rejected, (state, action) => {
            state.programNameError = true;
            state.programNameErrorMsg = "Template with the same name already exist.";
            // toast?.error(`Error while creating template: \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Post Template Error: ", action?.error);
        });
        builder.addCase(fetchProgramTemplateDetailsById.fulfilled, (state, action) => {
            let { apiResponse, callback } = action?.payload;
            if(apiResponse?.data?.data?.value?.data?.[0]) {
                let data = apiResponse?.data?.data?.value?.data?.[0];
                state.selectedTemplate = {
                    ...data,
                    program_templateid: data?.program_id,
                    duration: data?.program_duration
                };
            };
            if(callback) callback(apiResponse?.data?.data?.value?.data?.[0]);
        });
        builder.addCase(fetchProgramTemplateDetailsById.rejected, (state, action) => {
            toast?.error(`Unable to fetch template. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch Template Error: ", action?.error);
        });
        builder.addCase(deleteTemplateById.fulfilled, (state, action) => {
            if(action?.payload?.callback) action?.payload?.callback();
            console.log("Template Deleted Successfully.");
        });
        builder.addCase(deleteTemplateById.rejected, (state, action) => {
            toast?.error(`Unable to delete template. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Delete Template Error: ", action?.error);
        });
        builder.addCase(fetchTemplateList.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            let templateData = apiResponse?.data?.data?.value;
            state.selectedExerciseList = null;
            state.templateListing = templateData?.data ? templateData?.data : null;
            state.paginationObject = templateData?.pagination;
            if(callback) callback();
        });
        builder.addCase(fetchTemplateList.rejected, (state, action) => {
            toast?.error(`Unable to fetch templates. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch Template List Error: ", action?.error);
        });
        builder.addCase(fetchExerciseMaster.fulfilled, (state, action) => {
            let { apiResponse, callback } = action?.payload;
            state.exerciseMasterList = apiResponse?.data?.data?.value?.data;
            state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            if(callback) callback(state.exerciseMasterList);
        });
        builder.addCase(fetchExerciseMaster.rejected, (state, action) => {
            state.selectedExerciseList = null;
            toast?.error(`Unable to fetch exercise master list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch Exercise Master List Error: ", action?.error);
        });
        builder.addCase(fetchExerciseMasterFilter.fulfilled, (state, action) => {
            let { apiResponse, callback } = action?.payload;
            state.exerciseMasterFilteredList = apiResponse?.data?.data?.value?.data;
            if(callback) callback(state.exerciseMasterFilteredList);
        });
        builder.addCase(fetchExerciseMasterFilter.rejected, (state, action) => {
            state.exerciseMasterFilteredList=[];
            toast.warn(`Unable to fetch exercise master list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`);
            console.log("Fetch Exercise Master List Error: ", action?.error);
        });
        builder.addCase(fetchExerciseDetails.fulfilled, (state, action) => {
            let {apiResponse, callback} = action?.payload;
            if(apiResponse?.data?.data?.value?.data) {
                if(callback) callback({ 
                    ...apiResponse?.data?.data?.value?.data,
                    tempo: apiResponse?.data?.data?.value?.data?.selected_tempo
                });
            };
        });
        builder.addCase(fetchExerciseDetails.rejected, (state, action) => {
            toast?.error(`Unable to fetch exercise details. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch Exercise Details Error: ", action?.error);
        });
        builder.addCase(postTemplateExercises.fulfilled, (state, action) => {
            let { apiResponse, callback } = action?.payload;
            state.selectedExerciseList = null;
            if(callback) callback();
            console.log("Post Exercises Resp===>", apiResponse, callback);
        });
        builder.addCase(postTemplateExercises.rejected, (state, action) => {
            toast?.error(`Unable to add exercises to template. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Post Template Exercises Error: ", action?.error);
        });
        builder.addCase(fetchWholeCalendarData.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            console.log(apiResponse?.data?.data?.value, "<=====wholeCalendar");
            state.wholeCalendarData = apiResponse?.data?.data?.value?.week;
        });
        builder.addCase(fetchWholeCalendarData.rejected, (state, action) => {
            // toast?.error(`Error while fetching whole calendar data: \n${action?.payload?.error?.message}`,{toastId:"apiErrorToast"});
            // if(action?.payload?.callback) action?.payload?.callback();
            console.log("Fetch Whole Calendar Error: ", action?.payload?.error);
        });
        builder.addCase(fetchWeekWiseData.fulfilled, (state, action) => {
            let {week, callback, apiResponse} = action?.payload;
            state.selectedWeek = week;
            if(apiResponse?.data?.data?.value) {
                state.weekWiseCalendarData = apiResponse?.data?.data?.value?.week[0]?.days;
                // if(callback) callback();
            } else {
                state.weekWiseCalendarData = null;
                // toast?.error(`No data for calendar from api for week ${week}`,{toastId:"apiErrorToast"});
            };
            if(callback) callback();
        });
        builder.addCase(fetchWeekWiseData.rejected, (state, action) => {
            toast?.error(`Unable to fetch weekwise data. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch Weekwise Calendar Error: ", action?.error);
        });
        builder.addCase(fetchTemoList.fulfilled, (state, action) => {
            state.tempoList = action?.payload?.data?.data?.value?.tempo;
        });
        builder.addCase(fetchTemoList.rejected, (state, action) => {
            toast?.error(`Unable to fetch tempo list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching temo list: ", action?.error);
        });
        builder.addCase(fetchUnitsList.fulfilled, (state, action) => {
            state.unitsList = action?.payload?.data?.data?.value?.data;
        });
        builder.addCase(fetchUnitsList.rejected, (state, action) => {
            toast?.error(`Unable to fetch units. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching units: ", action?.error);
        });
        builder.addCase(fetchSportsList.fulfilled, (state, action) => {
            state.sportList = action?.payload?.data?.data?.value?.sport;
        });
        builder.addCase(fetchSportsList.rejected, (state, action) => {
            toast?.error(`Unable to fetch sport list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching sport list: ", action?.error);
        });
        builder.addCase(fetchTagsList.fulfilled, (state, action) => {
            state.tagList = action?.payload?.data?.data?.value?.tag;
        });
        builder.addCase(fetchTagsList.rejected, (state, action) => {
            toast?.error(`Unable to fetch tag list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching tag list: ", action?.error);
        });
        builder.addCase(publishCalendarSequence.fulfilled, (state, action) => {
            let {callback} = action?.payload;
            if(callback) callback();
        });
        builder.addCase(publishCalendarSequence.rejected, (state, action) => {
            toast?.error(`Unable to fetch tag list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching tag list: ", action?.error);
        });
        builder.addCase(updateExerciseMaster.fulfilled, (state, action) => {
            let { apiResponse, callback } = action?.payload;
            state.selectedExerciseList = null;
            if(callback) callback();
            // console.log("Post Exercises Resp===>", apiResponse, callback);
        });
        builder.addCase(updateExerciseMaster.rejected, (state, action) => {
            toast.warn("Unable to update exercise master list. Please try again later.", {toastId: "exercise_update_warn"});
            // toast?.error(`Error while updating master exercises : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Updating master exercise Error: ", action?.error);
        });
        builder.addCase(deleteExerciseMaster.fulfilled, (state, action) => {
            if(action?.payload?.callback) action?.payload?.callback();
            console.log("Exercise Deleted Successfully.");
        });
        builder.addCase(deleteExerciseMaster.rejected, (state, action) => {
            toast?.error(`Unable to delete template. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Delete Exercise Error: ", action?.error);
        });
        builder.addCase(fetchAllAthletesList.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.athleteListing = apiResponse?.data?.data?.value?.data;
            state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            if(callback) callback();
        });
        builder.addCase(fetchAllAthletesList.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            toast?.error(`Unable to fetch athletes. Please try again later : \n${error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch Athletes Error: ", error);
            if(callback) callback();
        });
    }
});

export const templatesActions = templatesSlice.actions;
export default templatesSlice.reducer;