import { createSlice } from "@reduxjs/toolkit";
import {
    createProgramByTemplateId,
    fetchProgramsList,
    fetchAthletesList,
    fetchCoachAthletesList,
    deleteAthleteFromProgram,
    deleteProgram,
    updateProgramDetails,
    fetchProgramAthletesList,
    fetchGroupListing,
    fetchProgramDetailsById,
    fetchWeekWiseProgramCalendarData,
    fetchWholeProgramCalendarData,
    fetchAthletePrograms,
    fetchWeekWiseProgressCalendarData,
    inserUpdateAthleteNotes,
    fetchAthleteNotes,
    insertUpdateAthleteProgress,
} from '../utility/programApiActions';
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    selectedProgram: null,
    programListing: null,
    paginationObject: null,
    athleteListing: null,
    groupList: null,
    programAthleteListing: null,
    selectedWeek: 1,
    addExerciseDay: null,
    selectedExerciseList: null,
    editExercise: null,
    weekWiseProgramCalendarData: null,
    wholeProgramCalendarData: null,
    athleteProgramsListing: null,
    weekWiseProgressCalendarData: null,
    athleteOverallProgressData: null,
    activeTab: 1
};

const programsSlice = createSlice({
    name: "programs",
    initialState,
    reducers: {
        setDayToAddExercise: (state, action) => {
            if (action?.payload?.day) {
                state.selectedExerciseList = null;
                state.addExerciseDay = action?.payload?.day;
                let navigateUrl = action?.payload?.navigateTo ? `${action?.payload?.navigateTo}${action?.payload?.id}/calendar/exercisemaster` : `/snc/templates/${action?.payload?.id}/calendar/exercisemaster`;
                action?.payload?.navigate(navigateUrl);
            };
        },
        seletedExercise: (state, action) => {
            let newData;
            let newSelectedList = [];
            let sequence_no = action?.payload?.selectedLength ? action?.payload?.selectedLength + 1 : 1;
            let targetDay = state.addExerciseDay;
            let targetDayObj = state.weekWiseProgramCalendarData?.find(obj => obj?.day_no == targetDay);
            if (targetDayObj?.exercises?.length) {
                sequence_no += targetDayObj?.exercises?.length;
            };
            if (action?.payload?.type === "individual") {
                newData = {
                    ...action?.payload?.data,
                    "sequence_no": sequence_no,
                    "description": action?.payload?.data?.exercise_desc,
                    "sets": action?.payload?.data?.default_sets,
                    "metrics_units": action?.payload?.data?.metric_json,
                    "activity_tags": action?.payload?.data?.exercise_tag,
                    "media": action?.payload?.data?.media_json,
                    // "added_by_id": 1,
                    "isSelected": true,
                    "recurrence": [
                        {
                            "week_no": state?.selectedWeek,
                            "day_no": parseInt(state?.addExerciseDay) || 1,
                            "id": null,
                            "optype": "1"
                        }
                    ],
                };
                delete newData["exercise_desc"];
                delete newData["default_sets"];
                delete newData["metric_json"];
                delete newData["media_json"];
                delete newData["exercise_tag"];
            } else {
                if (action?.payload?.type === "calendar" && action?.payload?.action === "edit") {
                    let updatedRecurrence = null;
                    if (action?.payload?.selectedAction === "current") {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            if (obj?.week_no == action?.payload?.week && obj?.day_no == action?.payload?.day) {
                                return {
                                    ...obj,
                                    optype: "2"
                                };
                            };
                            return { ...obj, optype: obj?.optype === "1" ? "1" : "4" };
                        });
                    }
                    else if (action?.payload?.selectedAction === "allRecurrence") {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            return { ...obj, optype: obj?.optype === "1" ? "1" : "2" };
                        });
                    }
                    else if (action?.payload?.selectedAction === "proceeding") {
                        console.log('action.payload', action.payload )
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            if ((obj?.week_no == action?.payload?.week && obj?.day_no >= action?.payload?.day) || (obj?.week_no > action?.payload?.week)) {
                                return {
                                    ...obj,
                                    optype: obj?.optype === "1" ? "1" : "2"
                                };
                            };
                            return { ...obj, optype: obj?.optype === "1" ? "1" : "4" };
                        });
                    }
                    else {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            if (obj?.week_no == action?.payload?.week && obj?.day_no == action?.payload?.day) {
                                return {
                                    ...obj,
                                    optype: "2"
                                };
                            };
                            return { ...obj };
                        });
                    };
                    state.selectedExerciseList?.forEach(exercise => {
                        if (exercise?.id == action?.payload?.data?.id) {
                            exercise?.recurrence?.forEach(recObj => {
                                let isExist = updatedRecurrence?.find(obj => obj?.week_no == recObj?.week_no && obj?.day_no == recObj?.day_no);
                                if (!isExist) updatedRecurrence?.push({ ...recObj, optype: "3" });
                            });
                        };
                    });
                    newData = { ...action?.payload?.data, recurrence: updatedRecurrence ? [...updatedRecurrence] : null };
                    // let updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                    //     if(obj?.week_no==action?.payload?.week && obj?.day_no==action?.payload?.day) {
                    //         return {
                    //             ...obj,
                    //             optype: "2"
                    //         };
                    //     };
                    //     return { ...obj };
                    // });
                    // newData = { ...action?.payload?.data, recurrence: updatedRecurrence ? [ ...updatedRecurrence ] : null };
                };
                if (action?.payload?.type === "calendar" && action?.payload?.action === "delete") {
                    let updatedRecurrence = null;
                    if (action?.payload?.selectedAction === "current") {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            // add id check to delete only one exercise
                            if (obj?.week_no == action?.payload?.week && obj?.day_no == action?.payload?.day && obj?.id == action.payload.data.id) {
                                return {
                                    ...obj,
                                    optype: "3"
                                };
                            };
                            return { ...obj, optype: "2" };
                        });
                    }
                    else if (action?.payload?.selectedAction === "allRecurrence") {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            return { ...obj, optype: "3" };
                        });
                    }
                    else {
                        updatedRecurrence = action?.payload?.data?.recurrence?.map(obj => {
                            if (obj?.week_no == action?.payload?.week && obj?.day_no >= action?.payload?.day) {
                                return {
                                    ...obj,
                                    optype: "3"
                                };
                            };
                            return { ...obj, optype: "2" };
                        });
                    };
                    newData = { ...action?.payload?.data, recurrence: updatedRecurrence ? [...updatedRecurrence] : null };
                };
                if (action?.payload?.type === "calendar" && action?.payload?.action === "view") {
                    newData = { ...action?.payload?.data };
                };
                if (action?.payload?.type === "master" && action?.payload?.action === 'edit') {
                    newData = {
                        ...action?.payload?.data,
                        "description": action?.payload?.data?.exercise_desc,
                        "sets": action?.payload?.data?.default_sets,
                        "metrics_units": action?.payload?.data?.metric_json,
                        "activity_tags": action?.payload?.data?.exercise_tag,
                        "media": action?.payload?.data?.media_json,
                        // "added_by_id": 1,
                        "is_active": 1
                    };
                    delete newData["exercise_desc"];
                    delete newData["default_sets"];
                    delete newData["metric_json"];
                    delete newData["media_json"];
                    delete newData["exercise_tag"];
                    delete newData["sport_name"];
                    delete newData["rno"];
                };
            };

            if (action?.payload?.type === "all") {
                newSelectedList = action?.payload?.isSelected
                    ? [
                        ...state?.exerciseMasterList?.map((obj, idx) => {
                            let newObj = {
                                ...obj,
                                "sequence_no": idx + 1,
                                "description": obj?.exercise_desc,
                                "sets": obj?.default_sets,
                                "metrics_units": obj?.metric_json,
                                "activity_tags": obj?.exercise_tag,
                                "media": obj?.media_json,
                                // "added_by_id": 1,
                                "isSelected": true,
                                "recurrence": [
                                    {
                                        "week_no": state?.selectedWeek,
                                        "day_no": parseInt(state?.addExerciseDay) || 1,
                                        "id": "",
                                        "optype": "1"
                                    }
                                ],
                            };
                            delete newObj["exercise_desc"];
                            delete newObj["default_sets"];
                            delete newObj["metric_json"];
                            delete newObj["media_json"];
                            delete newObj["exercise_tag"];

                            return newObj;
                        })
                    ]
                    : null;
            }
            else if (state.selectedExerciseList?.length && action?.payload?.type !== "calendar" && action?.payload?.type !== "master") {
                if (action?.payload?.isSelected) {
                    newSelectedList = [
                        ...state?.selectedExerciseList,
                        newData
                    ];
                } else {
                    newSelectedList = state?.selectedExerciseList?.filter(
                        exercise => exercise?.exercise_id !== action?.payload?.data?.exercise_id
                    );
                    sequence_no -= 1;
                    newSelectedList?.map((obj, idx) => {
                        return { ...obj, sequence_no: idx + 1 };
                    });
                }
            }
            else {
                newSelectedList?.push(newData);
            };
            state.selectedExerciseList = newSelectedList;
            if (action?.payload?.callback) action?.payload?.callback();
        },
        setEditExerciseId: (state, action) => {
            state.editExercise = {
                day: action?.payload?.day,
                id: action?.payload?.exerciseId
            };
            if (action?.payload?.callback) action?.payload?.callback();
        },
        saveEditedExercise: (state, action) => {
            let selectedExercise = action?.payload?.selectedExercise;
            let editedExercise = action?.payload?.editedExercise;
            // let isSequenceZero = false;

            let editedSelectedExercise = selectedExercise?.map(obj => {
                if (obj?.exercise_id == editedExercise?.exercise_id) {
                    let recurrenceObj = [];
                    obj?.recurrence?.forEach(selectedExe => {
                        let matchedExe = editedExercise?.recurrence?.find(editedExe => selectedExe?.week_no == editedExe?.week_no && selectedExe?.day_no == editedExe?.day_no);
                        if (matchedExe) recurrenceObj?.push({ ...selectedExe, optype: selectedExe?.id ? selectedExe?.optype : "1" });
                        else if (!matchedExe && selectedExe?.id) recurrenceObj?.push({ ...selectedExe, optype: "3" });
                    });
                    editedExercise?.recurrence?.forEach(editedExe => {
                        let matchedExe = recurrenceObj?.find(obj => obj?.week_no == editedExe?.week_no && obj?.day_no == editedExe?.day_no);
                        if (!matchedExe) recurrenceObj?.push({ ...editedExe });
                    });
                    // if(recurrenceObj?.length > 1) {
                    //     isSequenceZero = true;
                    //     return { ...editedExercise, recurrence: [ ...recurrenceObj ], sequence_no: 0 };
                    // };
                    let sequence_no = (recurrenceObj?.length > 1) ? 0 : editedExercise?.sequence_no;
                    return { ...editedExercise, sequence_no, recurrence: [...recurrenceObj] };
                };
                return { ...obj };
            });

            state.selectedExerciseList = [...editedSelectedExercise];
            if (action?.payload?.callback) action?.payload?.callback();
        },
        setExerciseSequence: (state, action) => {
            let { week, day, data } = action?.payload;
            let newWholeProgramCalendarData = state.wholeProgramCalendarData?.map(weekObj => {
                let newDays = [];
                if (weekObj?.week_no == week) {
                    newDays = weekObj?.days?.map(dayObj => {
                        if (dayObj?.day_no == day) {
                            let exercises = dayObj?.exercises;
                            let [draggedExecise] = exercises?.splice(data?.source?.index, 1);
                            exercises?.splice(data?.destination?.index, 0, draggedExecise);
                        };
                        return dayObj;
                    });
                    return { ...weekObj, days: newDays };
                };
                return { ...weekObj };
            });
            let newWeekWiseProgramCalendarData = state.weekWiseProgramCalendarData?.map(dayObj => {
                if (dayObj?.day_no == day) {
                    let exercises = dayObj?.exercises;
                    let [draggedExecise] = exercises?.splice(data?.source?.index, 1);
                    exercises?.splice(data?.destination?.index, 0, draggedExecise);
                };
                return dayObj;
            });
            state.wholeProgramCalendarData = [...newWholeProgramCalendarData];
            state.weekWiseProgramCalendarData = [...newWeekWiseProgramCalendarData];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createProgramByTemplateId?.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            if (callback) callback(apiResponse?.data?.data?.value);
        });
        builder.addCase(createProgramByTemplateId.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            toast?.error(`Unable to create a program. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            console.log("Create Program Error: ", error);
            if (callback) callback(null);
        });
        builder.addCase(fetchProgramsList?.fulfilled, (state, action) => {
            let { callback, activeTab, apiResponse } = action?.payload;
            state.programAthleteListing = null;
            state.programListing = apiResponse?.data?.data?.value?.data;
            state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            state.activeTab = activeTab;
            if (callback) callback();
        });
        builder.addCase(fetchProgramsList.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            state.programAthleteListing = null;
            toast?.error(`Unable to fetch programs. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            console.log("Fetch Programs Error: ", error);
            if (callback) callback();
        });
        builder.addCase(fetchAthletesList?.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            state.athleteListing = apiResponse?.data?.data?.value?.data;
            state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            if (callback) callback();
        });
        builder.addCase(fetchAthletesList.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            toast?.error(`Unable to fetch athletes. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            console.log("Fetch Athletes Error: ", error);
            if (callback) callback();
        });
        builder.addCase(fetchCoachAthletesList?.pending, (state, action) => {
            state.loading = true;
            return state;
        });
        builder.addCase(fetchCoachAthletesList?.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            state.loading = false;
            state.athleteListing = apiResponse?.data?.data?.value?.data;
            state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            if (callback) callback();
        });
        builder.addCase(fetchCoachAthletesList.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            state.loading = false;
            toast?.error(`Unable to fetch athletes. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            console.log("Fetch Athletes Error: ", error);
            if (callback) callback();
        });
        builder.addCase(fetchProgramAthletesList?.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            // state.programAthleteListing = apiResponse?.data?.data?.value?.data.filter(obj => obj?.is_selected);
            state.programAthleteListing = apiResponse?.data?.data?.value?.data;
            state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            console.log(state.programAthleteListing);
            if (callback) callback(apiResponse?.data?.data?.value?.data);
        });
        builder.addCase(fetchProgramAthletesList.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            toast?.error(`Unable to fetch program athletes. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            console.log("Fetch Program Athletes Error: ", error);
            if (callback) callback();
        });
        builder.addCase(deleteAthleteFromProgram?.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            if (apiResponse?.data?.data?.value) {
                let data = apiResponse?.data?.data?.value;
                state.selectedProgram = {
                    ...data,
                    athlete_id: data.assigned_athlete_ids
                };
            };

            if (apiResponse?.data?.meta?.success) toast.success("Athlete removed successfully.", { toastId: "athlete_remove_success" });
            else toast.warn("Something went wrong.");
            if (callback) callback();
        });
        builder.addCase(deleteAthleteFromProgram.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            toast?.error(`Unable to remove athletes. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            console.log("Remove Athletes Error: ", error);
            if (callback) callback();
        });
        builder.addCase(deleteProgram?.fulfilled, (state, action) => {
            if (action?.payload?.callback) action?.payload?.callback();
            console.log("Program Deleted Successfully.");
        });
        builder.addCase(deleteProgram.rejected, (state, action) => {
            toast?.error(`Unable to delete program. Please try again: \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
            console.log("Delete Program Error: ", action?.error);
        });
        builder.addCase(fetchGroupListing?.fulfilled, (state, action) => {
            state.groupList = action?.payload?.data?.data?.value?.group;
        });
        builder.addCase(fetchGroupListing.rejected, (state, action) => {
            toast?.error(`Unable to fetch groups. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
            console.log("Fetch groups Error: ", action?.payload?.error);
        });
        builder.addCase(updateProgramDetails?.fulfilled, (state, action) => {
            const { callback, apiResponse } = action?.payload;
            if (apiResponse?.data?.data?.value) {
                let data = apiResponse?.data?.data?.value;
                state.selectedProgram = {
                    ...data,
                    athlete_id: data.assigned_athlete_ids
                };
            };
            console.log(state.selectedProgram);
            if (callback) callback(apiResponse?.data?.data?.value);
        });
        builder.addCase(updateProgramDetails?.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            if (callback) callback();
            toast?.error(`Unable to update program details. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            console.log("Update Program Details Error: ", error);
        });
        builder.addCase(fetchProgramDetailsById.fulfilled, (state, action) => {
            let { apiResponse, callback } = action?.payload;
            if (apiResponse?.data?.data?.value?.data?.[0]) {
                let data = apiResponse?.data?.data?.value?.data?.[0];
                state.selectedProgram = {
                    ...data,
                    athlete_id: data?.assigned_athlete_ids,
                };
            };
            if (callback) callback(apiResponse?.data?.data?.value?.data?.[0]);
        });
        builder.addCase(fetchProgramDetailsById.rejected, (state, action) => {
            toast?.error(`Unable to fetch program. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
            console.log("Fetch Program Error: ", action?.error);
        });
        builder.addCase(fetchWeekWiseProgramCalendarData.fulfilled, (state, action) => {
            let { week, callback, apiResponse } = action?.payload;
            state.selectedWeek = week;
            if (apiResponse?.data?.data?.value) {
                state.weekWiseProgramCalendarData = apiResponse?.data?.data?.value?.week[0]?.days;
            } else {
                state.weekWiseProgramCalendarData = null;
            };
            if (callback) callback();
        });
        builder.addCase(fetchWeekWiseProgramCalendarData.rejected, (state, action) => {
            toast?.error(`Unable to fetch program weekwise data. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
            console.log("Fetch Weekwise Program Calendar Error: ", action?.error);
        });
        builder.addCase(fetchWholeProgramCalendarData.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            console.log(apiResponse?.data?.data?.value, "<=====wholeCalendar");
            state.wholeProgramCalendarData = apiResponse?.data?.data?.value?.week;
        });
        builder.addCase(fetchWholeProgramCalendarData.rejected, (state, action) => {
            console.log("Fetch Whole Program Calendar Error: ", action?.payload?.error);
            toast?.error(`Unable to fetch program schedule data. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
        });
        builder.addCase(fetchAthletePrograms.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            console.log(apiResponse?.data?.data?.value?.data, "<=====Athlete Programs");
            state.athleteProgramsListing = apiResponse?.data?.data?.value?.data;
            state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            if (callback) callback();
        });
        builder.addCase(fetchAthletePrograms.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            state.athleteProgramsListing = null;
            console.log("Fetch Athlete Programs Error: ", error);
            toast?.error(`Unable to fetch Athlete programs. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            if (callback) callback();
        });
        builder.addCase(fetchWeekWiseProgressCalendarData.fulfilled, (state, action) => {
            let { week, callback, apiResponse } = action?.payload;
            console.log(apiResponse?.data?.data?.value?.week?.[0]?.days, "<=====weekwise Programs Progress");
            state.weekWiseProgressCalendarData = apiResponse?.data?.data?.value?.week?.[0]?.days;
            state.athleteOverallProgressData = apiResponse?.data?.data?.value?.week?.[0]?.athlete_detail;
            state.selectedWeek = week;
            if (callback) callback();
        });
        builder.addCase(fetchWeekWiseProgressCalendarData.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            state.weekWiseProgressCalendarData = null;
            state.athleteOverallProgressData = null;
            console.log("Fetch Weekwise Programs Progress Error: ", error);
            toast?.error(`Unable to fetch weekwise progress data. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            if (callback) callback();
        });
        builder.addCase(inserUpdateAthleteNotes.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            if (callback) callback();
        });
        builder.addCase(inserUpdateAthleteNotes.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            console.log("InsertUpdate Athlete Notes/Feedback Error: ", error);
            toast?.error(`Unable to save feedback. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            if (callback) callback();
        });
        builder.addCase(fetchAthleteNotes.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            let data = null;
            if (apiResponse?.data?.data?.value?.data) {
                data = apiResponse?.data?.data?.value?.data?.[0];
            };
            if (callback) callback(data);
        });
        builder.addCase(fetchAthleteNotes.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            console.log("InsertUpdate Athlete Notes Error: ", error);
            toast?.error(`Unable to save notes. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            if (callback) callback(null);
        });
        builder.addCase(insertUpdateAthleteProgress.fulfilled, (state, action) => {
            let { callback, apiResponse } = action?.payload;
            if (callback) callback();
        });
        builder.addCase(insertUpdateAthleteProgress.rejected, (state, action) => {
            let { callback, error } = action?.payload;
            console.log("InsertUpdate Athlete Progress Error: ", error);
            toast?.error(`Unable to save progress. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`, { toastId: "apiErrorToast" });
            if (callback) callback();
        });
    }
});

export const programsActions = programsSlice?.actions;
export default programsSlice?.reducer;