import moment from "moment";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { clearNutritionLogDetails, resetLogDetailsUpdated, resetNoteDetailsUpdated } from "../../../reducer/nutrition.reducer";
import { NUTRITION_MODULE_ROUTE } from "../../../utility/constants";
import {
  fetchNotesDetails,
  getNutritionLogDetails,
  getSelectedAthleteDetails,
  insertUpdateInternalNote,
  updateLogDetails,
} from "../../../utility/nutritionApiAction";
import DatePickerComponent from "../Common/DatePickerComponent";
import Header from "../Common/Header";

function AddNewNutritionLog() {
  const navigate = useNavigate();
  let { logId, typeId, groupId, athleteId, noteId } = useParams();
  let location = useLocation()
  const VIEWMODEONLY = location.pathname.split("/")[1] ? location.pathname.split("/")[1] !== NUTRITION_MODULE_ROUTE :false
  const NEWNOTE = location.pathname.split("/")[5] ? location.pathname.split("/")[5] === "newNote" ? true : false : false
  let [viewMode, setViewMode] = useState(true);
  let [logData, setLogData] = useState({ date: new Date(), data: null });
  let [logView, setLogView] = useState(logId || groupId || VIEWMODEONLY ? true : false);
  let [noteData, setNoteData] = useState({ date: new Date(), data: null, updated_by:null });
  let [errorState,setErrorState] = useState(false)

  let userData = useSelector((state) => state?.auth?.userData);
  let loading = useSelector((state) => state.nutrition.loading);
  let logDetailsUpdated = useSelector((state) => state.nutrition.logDetailsUpdated);
  let logDetails = useSelector((state) => state?.nutrition?.nutritionLogDetails);
  let noteDetailsRedux = useSelector((state) => state?.nutrition?.noteDetails);
  let noteDetailsUpdated = useSelector((state) => state?.nutrition?.noteUpdated);
  let selectedAthleteDetails = useSelector((state) => state.nutrition.selectedAthleteDetails);
  const dispatch = useDispatch();


  // Get athlete guid if not find in routing state
  useEffect(() => {
    if (!groupId && athleteId && userData?.user_guid) {
      // hit api
      dispatch(getSelectedAthleteDetails({ athlete_id: athleteId, user_guid: userData?.user_guid }));
    }
  }, [athleteId, userData, groupId, dispatch]);

  useEffect(() => {
    if (!logView && !noteId) {
      setViewMode(false);
    }
    return ()=> setViewMode(true)
  }, [logView, noteId]);

  useEffect(() => {
    if (logDetailsUpdated) {
      setViewMode(true);
      dispatch(resetLogDetailsUpdated());
    }
  }, [logDetailsUpdated, dispatch]);

  useEffect(() => {
    if (userData && userData?.user_guid && logView) {
      dispatch(getNutritionLogDetails({ type: typeId, id: logId, user_guid: userData?.user_guid, group_id: groupId }));
    } else if (userData && userData?.user_guid && !logView && noteId) {
      dispatch(fetchNotesDetails({ noteId: noteId, user_guid:userData?.user_guid }));
    }
    return () => dispatch(clearNutritionLogDetails());
  }, [dispatch, logId, typeId, userData, groupId, athleteId, logView, noteId]);

  // for group nutrition log
  useEffect(() => {
    if (!logView) return;
    if (logDetails?.log_data) {
      setLogData({
        date: logDetails.log_date ? new Date(logDetails.log_date) : new Date(),
        data: logDetails.log_data ? logDetails.log_data : null,
      });
    }
    if (!logDetails ||!logDetails?.log_data) {
      setViewMode(false);
    }else {
      setViewMode(true)
    }
  }, [logDetails, logView]);

  // for internal notes
  useEffect(() => {
    if (logView) return;
    if (NEWNOTE) return 
    if (noteDetailsRedux) {
      setNoteData({
        date: noteDetailsRedux.note_date ? new Date(noteDetailsRedux.note_date) : new Date(),
        data: noteDetailsRedux.note_data ? noteDetailsRedux.note_data : null,
        updated_by: noteDetailsRedux.updated_by ? noteDetailsRedux.updated_by : null
      });
      if (!noteDetailsRedux?.note_data) {
        setViewMode(false);
      }
    }
    return ()=> setViewMode(true)
  }, [noteDetailsRedux, logView, NEWNOTE]);

  const onChangeHandle = ({ key, event }) => {
    if(errorState){
      setErrorState(false)
    }
    if (key === "date") {
      if (logView) {
        setLogData((old) => ({ ...old, date: moment(event).format("YYYY-MM-DD") }));
      } else {
        setNoteData((old) => ({ ...old, date: moment(event).format("YYYY-MM-DD") }));
      }
    } else {
      if (logView) {
        setLogData((old) => ({ ...old, data: event?.target?.value }));
      } else {
        setNoteData((old) => ({ ...old, data: event?.target?.value }));
      }
    }
  };


  useEffect(() => {
    if (noteDetailsUpdated && !logView) {
      setViewMode(true);
      navigate(`/${NUTRITION_MODULE_ROUTE}/athlete/${athleteId}/${typeId}/${noteDetailsUpdated}`,{replace:true})
    }
    return ()=> dispatch(resetNoteDetailsUpdated())
  }, [noteDetailsUpdated, dispatch, logView, navigate, athleteId, typeId]);


  const submitData = () => {
    if (logView) {
      if(!logData || !logData?.data){
        setErrorState(true)
        return 
      }
      let payload = {
        nutrition_id: logId ? Number(logId) : null,
        user_guid: userData?.user_guid ? userData?.user_guid : null,
        log_data: logData?.data,
        log_date: moment(logData?.date).format("YYYY-MM-DD"),
        group_id: typeId === "1" ? (groupId ? groupId : null) : null,
        athlete_id: typeId === "2" ? (groupId ? groupId : null) : null,
      };
      // type: typeId, id: logId, user_guid: userData?.user_guid, group_id: groupId }));
      let getDetailsPayload = {
        nutrition_id: logId ? logId : null,
        group_id: groupId ? groupId : null,
        type: typeId ? typeId : null,
        user_guid:
          // groupId ? null :
          userData?.user_guid ? userData?.user_guid : null,
      };
      dispatch(updateLogDetails({ payload, getDetailsPayload }));
    } else {
      if(!noteData || !noteData?.data){
        setErrorState(true)
        return 
      }
      let payload = {
        note_id: noteId ? Number(noteId) : null,
        user_guid: userData?.user_guid ? userData?.user_guid : null,
        note_data: noteData.data,
        note_date: moment(noteData?.date).format("YYYY-MM-DD"),
        athlete_id: athleteId ? athleteId : null,
        updated_by: noteData?.updated_by ? noteData?.updated_by : null
      };
      dispatch(insertUpdateInternalNote({ payload }))
    }
  };

  return (
    <>
      {loading ? <div className="spin"></div> : null}
      <Header
        headerData={{
          heading:
            logView && logDetails.log_name
              ? logDetails.log_name
              : athleteId && selectedAthleteDetails?.athlete_name
              ? `${selectedAthleteDetails?.athlete_name?.split(" ")[0]}'s Internal Note `
              : "Nutriton Log",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate(-1),
        }}
      />
      <main className="si-main-content">
        <div className="nutrition-log">
          <div className="nutrition-log--header">
            <div className="title-icon-wrp">
              <div className="log-header--title">
                {logView ? (
                  <>
                    <span className="title-text">Dietary Recommendations </span>
                    <span className="additional-text">(As per the assigned meal plans)</span>
                  </>
                ) : !logView && noteId && noteDetailsRedux?.note_name ? (
                  <span className="title-text">{noteDetailsRedux?.note_name} </span>
                ) : (
                  <span className="title-text">Note </span>
                )}
              </div>
              {viewMode && !VIEWMODEONLY ? <span className="icon-edit" onClick={() => setViewMode((old) => !old)}></span> : null}
            </div>
          </div>
          <div className="nutrition-log--subheader">
            <div className="form-group">
              <div className="form-group-wrap si-location date-form-control">
                <label className="si-label">Recommended on</label>
                <DatePickerComponent
                  isEditMode={viewMode ? true : false}
                  selectedDate={
                    logView ? logData.date : (noteId || NEWNOTE) && (noteDetailsRedux?.note_date || noteData?.date) ? noteData.date : new Date()
                  }
                  maxDate={new Date()}
                  dateChangeHandler={(event) => {
                    onChangeHandle({ event, key: "date" });
                  }}
                />{" "}
              </div>
            </div>
            {/* {logView ? ( */}
              <div className="Recommended-wrp">
                <div className=" icon-label-wrp">
                  <span className="icon-doctor-bag"></span>
                  <label className="si-label">Recommended by</label>
                </div>
                {logView ? 
                <span className={`doctor-name ${viewMode?"doctor-name-view":""}`}>{logDetails && logDetails.updated_by ? logDetails?.updated_by : ""}</span>
                : null}
                {!logView ? 
                <span className={`doctor-name ${viewMode?"doctor-name-view":""}`}>{noteData && noteData.updated_by ? noteData?.updated_by : ""}</span>
                : null}
              </div>
            {/* ) : null} */}
          </div>
          <div className={`nutrition-log-content-wrp ${errorState ? "error-active": ""} ${viewMode ? "view-mode-active" : "edit-mode-active"}`}>
            <div className="nutrition-log-content edit-mode ">
              <label className="si-label">Recommendation</label>
              <textarea
                placeholder="Start typing here"
                key={logView
                  ? logDetails && logDetails.log_id
                    ? (logDetails?.log_id + viewMode)
                    : ("" + viewMode)
                  : noteDetailsRedux?.note_id && noteId
                  ? (noteDetailsRedux?.note_id + viewMode)
                  : (""+viewMode)}
                defaultValue={
                  logView
                    ? logDetails && logDetails.log_data
                      ? logDetails?.log_data
                      : ""
                    : noteDetailsRedux?.note_data && noteId
                    ? noteDetailsRedux?.note_data
                    : ""
                }
                onKeyUp={(event) => onChangeHandle({ event, key: "data" })}
                // onChange={(event) => onChangeHandle({ event, key: "data" })}
              />
            </div>
            <div className="nutrition-log-content view-mode">
              <label className="si-label">Recommendation</label>
              <textarea
                readOnly
                key={logView
                  ? logDetails && logDetails.log_id
                    ? logDetails?.log_id
                    : ""
                  : noteDetailsRedux?.note_id && noteId
                  ? noteDetailsRedux?.note_id
                  : ""}
                value={
                  logView
                    ? logDetails && logDetails.log_data
                      ? logDetails?.log_data
                      : ""
                    : noteDetailsRedux?.note_data && noteId
                    ? noteDetailsRedux?.note_data
                    : ""
                }
              />
            </div>
          </div>
        </div>
        <div className="nutrition-footer">
          {viewMode ? null : (
            <>
              <button
                type="button"
                className="si-button si-icon--add si-clearbtn"
                onClick={() => {setViewMode((old) => !old); setErrorState(false)}}
              >
                cancel
              </button>
              <button type="button" className="si-button si-button--green" onClick={submitData}>
                save
              </button>
            </>
          )}
        </div>
      </main>
    </>
  );
}

export default AddNewNutritionLog;
