import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {TableBody, TableRow, TableCell} from '@mui/material';
import ListingTable from '../../../Common/ListingTableMUI';
import Checkbox from '@mui/material/Checkbox';

function Content({allreadyAddedAthleteIds, athleteIds, changeHandler,pageNo,setPageNo,pagination,perPage}) {
    const athleteListing = useSelector(state => state?.technicalPrograms?.athleteListing);
    // console.log(athleteListing,athleteIds,allreadyAddedAthleteIds);

    const tableHeaders = [
        {
            // label: "checkboxInput",
            align: "left",
            stickyClass: "stickyCell-left"
        },
        {
            label: "Athlete Name",
            align: "left"
        },
        {
            label: "Sport",
            align: "left"
        },
        {
            label: "Group",
            align: "left"
        }
    ];

    const getTableBody = () => {
        return (
            <TableBody sx={{backgroundColor:"#181818"}}>
                {
                    // athleteListing?.filter(obj => obj?.is_selected)?.length
                    // ?
                    // athleteListing?.filter(obj => obj?.is_selected)?.map(athlete => {
                        athleteListing?.length
                        ?
                        athleteListing?.map(athlete => {
                        let name = `${athlete?.first_name ? athlete?.first_name : ""} ${athlete?.last_name ? athlete?.last_name : ""}`;
                        let sports = athlete?.sport_name;
                        let sportsCount = sports?.length;
                        let sportName = sportsCount > 2 ? `${sports?.slice(0, 2)?.join(", ")}` : sports?.join(", ");
                        let otherCount = sportsCount > 2 ? `+${sportsCount - 2}` : "";
                        let group = athlete?.group_name;
                        let groupCount = group?.length;
                        let groupName = groupCount > 2 ? `${group?.slice(0, 2)?.join(", ")}` : group?.join(", ");
                        let groupOtherCount = groupCount > 2 ? `+${groupCount - 2}` : "";
                        let isSelected = athleteIds?.includes(athlete?.athlete_id);
                        console.log(isSelected,athlete?.athlete_id);
                        // let isSelected = allreadyAddedAthleteIds?.includes(athlete?.athlete_id);
                        let isDisabled = allreadyAddedAthleteIds?.includes(athlete?.athlete_id);
                        // console.log(isDisabled,athlete?.athlete_id);
                        return (
                            <TableRow 
                                key={uuidv4()} 
                                hover 
                                selected={isSelected || isDisabled ? true : false}>
                                <TableCell padding="checkbox" className="stickyCell-left">
                                    <Checkbox 
                                        color="warning"
                                        checked={isSelected || isDisabled ? true : false}    
                                        disabled={isDisabled}
                                        onClick={(e) => changeHandler(e,!isSelected, athlete?.athlete_id)}
                                    />
                                </TableCell>
                                <TableCell 
                                    align={"left"} 
                                    sx={{color:"#fff", minWidth: 150, cursor: "pointer"}}
                                    onClick={(e) => changeHandler(e,!isSelected, athlete?.athlete_id)}
                                >
                                    {name ? name : ""}
                                </TableCell>
                                <TableCell align={"left"} sx={{color: "#fff"}}>
                                    { sportName ? sportName : "-" }
                                    {
                                        otherCount && 
                                        <span className="si-custom-table si-count">{ otherCount }</span>
                                    }
                                </TableCell>
                                <TableCell align={"left"} sx={{color: "#fff"}}>
                                    { groupName ? groupName : "-" }
                                    {
                                        groupOtherCount && 
                                        <span className="si-custom-table si-count">{ groupOtherCount }</span>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={5} sx={{color: "#fff"}}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };

    return (
        <div className="si-main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={pagination}
                perPage={perPage}
            />
        </div>
    );
};

export default memo(Content);