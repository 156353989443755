import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { getSessionLog } from '../../../../utility/PsychologyApiActions';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import Header from '../../Common/Header';
import ProgressNotesContent from './ProgressNotesContent';
import Footer from '../../Common/Footer';
import { toast } from 'react-toastify';
import { loaderActions } from '../../../../reducer/loader';
import { ATHELETE_ROLE_ID, PSYCHOLOGY_MODULE_ROUTE } from '../../../../utility/constants';

export default function ViewUpdateProgressNotes({ type }) {
  const { athleteId, id, sessionId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state?.auth?.isAuthenticated);
  const userData = useSelector((state) => state?.auth?.userData);
  const [isEditMode, setIsEditMode] = useState(type == 'view' ? false : true);
  const [isEditOption, setIsEditOption] = useState(athleteId ? false : true);
  const caseSessionDetails = useSelector((state) => state?.psychology?.sessionDetails);
  const selectedPsychologySessionId = useSelector((state) => state?.psychology?.selectedPsychologySessionId);
  const [sessionDetails, setSessionDetails] = useState({});
  const loading = useSelector((state) => state?.psychology?.loading);
  useEffect(() => {
    if (loading) {
      dispatch(loaderActions.showLoader());
    } else {
      dispatch(loaderActions.hideLoader());
    }
  }, [loading])
  useEffect(() => {
    if (userData && userData?.role_id == ATHELETE_ROLE_ID) {
      setIsEditOption(false);
    }
  }, [userData]);
  useEffect(() => {
    if (isAuth && id && sessionId && userData?.user_guid) {
      let payload = {
        session_id: sessionId,
        user_guid: userData?.user_guid
      }
      dispatch(getSessionLog({ payload }));
    }
  }, [isAuth, id, sessionId, userData, dispatch]);

  useEffect(() => {
    if (caseSessionDetails) {
      setSessionDetails({ ...caseSessionDetails });
    } else if(caseSessionDetails === undefined){
      toast?.error("Error while fetching session details")
      navigate(-1)
    }
  }, [caseSessionDetails])

  // useEffect(() => {
  //     if (selectedPsychologySessionId) {
  //       toast?.success("Case session details updated successfully.");
  //       setIsEditMode(false);
  //     }
  //   }, [selectedPsychologySessionId]);

  const saveHandler = (session_id) => {
    if(session_id){
      toast?.success("Case session details updated successfully");
      setIsEditMode(false);
    } else {
      toast?.error("Error while saving psychology session details")
    }
  }

  const cancelHandler = () => {
    let payload = {
      session_id: sessionId,
      user_guid:userData?.user_guid
    }
    dispatch(getSessionLog({ payload }));
    setIsEditMode(false);
  }
  return (
    <Fragment>
      <Header
        headerData={{
          heading: caseSessionDetails?.session_name,
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => athleteId ? navigate(-1) : navigate(`/${PSYCHOLOGY_MODULE_ROUTE}/case/${id}/progressnotes`)
        }}
      />
      <ProgressNotesContent isEditOption={isEditOption} isEditMode={isEditMode}
        setIsEditMode={setIsEditMode} sessionDetails={sessionDetails}
        setSessionDetails={setSessionDetails} saveHandler={saveHandler}
        cancelHandler={cancelHandler} />
    </Fragment>
  )
}
