import React, {Fragment, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../Common/Header';
import Dropdown from '../../Common/Dropdown';
import { toast } from 'react-toastify';
import { fetchSportsList,postTechnicalProgramTemplate } from '../../../../utility/technicalTemplateApiActions';
import { technicalTemplatesActions } from '../../../../reducer/technicalTemplates';

function ProgramCreationForm() {
  const sportList = useSelector(state => state?.technicalTemplates?.sportList);
  const programName = useSelector(state => state?.technicalTemplates?.programName);
  const duration = useSelector(state => state?.technicalTemplates?.duration);
  const selectedSportId = useSelector(state => state?.technicalTemplates?.selectedSportId);
  const programNameError = useSelector(state => state?.technicalTemplates?.programNameError);
  const durationError = useSelector(state => state?.technicalTemplates?.durationError);
  const selectedSportError = useSelector(state => state?.technicalTemplates?.selectedSportError);
  const programNameErrorMsg = useSelector(state => state?.technicalTemplates?.programNameErrorMsg);
  const durationErrorMsg = useSelector(state => state?.technicalTemplates?.durationErrorMsg);
  const selectedSportErrorMsg = useSelector(state => state?.technicalTemplates?.selectedSportErrorMsg);
  const selectedMenuId = useSelector(state => state?.sideMenu?.selectedMenuId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(technicalTemplatesActions?.setDefault());
    if(!sportList?.length) dispatch(fetchSportsList());

    return () => dispatch(technicalTemplatesActions?.setDefault());
  }, []);

  const sportsChangeHandler = (e) => {
    dispatch(technicalTemplatesActions?.setSelectedSportId(e?.target?.value));
  };

  const sportOptions = sportList?.map(obj => {
    return {
        value: obj?.sport_id,
        label: obj?.sport_name
    };
  });

  const changeHandler = (data) => {
      dispatch(technicalTemplatesActions?.setSelectedSportId({sportId: data?.value?.toString()}));
  };

  const clickHandler = (e) => {
    e.preventDefault();
    if(programName && duration && selectedSportId) {
      dispatch(
        postTechnicalProgramTemplate({
          optType: 1,
          moduleId: selectedMenuId?.id,
          isNewTemplate: true,
          isPublish: 0,
          callback: (templateId) => {
            navigate(`/technical/templates/${templateId}/calendar`);
            toast.success("Template created successfully.");
          }
        })
      );
    } else {
      console.log("Post Template Errors");
      dispatch(technicalTemplatesActions?.setErrors());
    };
  };
  
  return (
    <Fragment>
      <Header 
        headerData={{
          heading: "Program Templates",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate("/technical/templates")
        }}
      />
      <div className="si-main-content">
        <div className="si-secondStep si-align-center">
          <div className="si-form">
            <div className={`form-control ${programNameError ? 'si-error' : ''}`}>
              <div className="form-group">
                <label htmlFor="name" className="si-label">*Template Name</label>
                <input 
                  className="si-input"
                  type="text" 
                  placeholder="Add template name" 
                  value={ programName }
                  onChange={ (e) => dispatch(technicalTemplatesActions?.setProgramName({name: e.target.value})) }
                />
              </div>
              <span className={ programNameError ? "si-error-message" : "" }>{ programNameErrorMsg }</span>
            </div>
            <div className={`form-control ${durationError ? 'si-error' : ''}`}>
              <div className="form-group">
                <label htmlFor="duration" className="si-label">*Template Duration</label>
                <div className="form-group-wrap si-duration">
                  <input 
                    className="si-input"
                    type="text" 
                    placeholder="Add duration" 
                    value={ duration }
                    onChange={ (e) => dispatch(technicalTemplatesActions?.setDuration({duration: e.target.value})) }
                  />
                  <span className="si-label-append">Weeks</span>
                </div>
              </div>
              <span className={ durationError ? "si-error-message" : "" }>{ durationErrorMsg }</span>
            </div>
            <div className={`form-control ${selectedSportError ? 'si-error' : ''}`}>
              <div className="form-group">
                <label htmlFor="selectSport" className="si-label">*Select Sport</label>
                <Dropdown 
                  placeholder={"Select Sport"} 
                  options={sportOptions}
                  changeHandler={changeHandler}
                />
              </div>
              <span className={ selectedSportError ? "si-error-message" : "" }>{ selectedSportErrorMsg }</span>
            </div>
            <div className="form-control">
              <button 
                type="button" 
                className="si-button si-button--green si-icon--add"
                onClick={clickHandler}
                style={{marginTop: "5px"}}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <FixedButton btnName="Next"
                        onBtnClick={clickHandler}/> */}
    </Fragment>
  );
};

export default ProgramCreationForm;