import React from 'react';
import Slider from 'react-rangeslider';

function SubHeader({details}) {
    let name = `${details?.athlete_name ? details?.first_name : ""} ${details?.last_name ? details?.last_name : ""}`;
    return (
        <div className="si-Performanceheader">
            <div className="si-Left">
                <div className="si-infoWrp">
                    <div className="si-Image">
                        {/* <img src="../../images/person.png" /> */}
                        <img src={`../../images/profile/${details?.athlete_id}.png`} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src="../../images/profile/default.png";
                        }}/>
                    </div>
                    <div className="si-infoDetails">
                    <div className="si-name">{details?.athlete_name ? details?.athlete_name  : ""}</div>
                    <div className="si-otherInfo">
                        {/* <div className="si-profession">
                            <span>Athlete  </span>
                            <span>| </span>
                            <span>{details?.sport_name ? details?.sport_name : ""}</span>
                        </div> */}
                        <div className="si-groupWrp" style={{marginRight: "10px"}}>
                            <div className="si-group">{`KID000${details?.athlete_id ? details?.athlete_id : "0"}`}</div>
                        </div>
                        <div className="si-profession">
                            <span>|</span>
                        </div>
                        <div className="si-groupWrp">
                            {
                                details?.group_name?.map((name, idx) => {
                                    return <div className="si-group" key={idx}>{name}</div>
                                })
                            }
                        </div>
                    </div>
                    </div>
                </div>

            </div>
            <div className="si-Right">
                <div className="si-top">
                    <span className="si-head">Overall Performance </span>
                    <span className="si-percent">{details?.overall_progress ? details?.overall_progress + "%" : "0%"}</span>
                </div>
                <div className="si-progressbar si-red">
                    <Slider 
                        min={0}
                        max={100}
                        step={1}
                        value={details?.overall_progress ? details?.overall_progress : 0}
                        orientation="horizontal"
                    />
                </div>
            </div>
        </div>
    );
};

export default SubHeader;