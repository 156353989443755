import React, {memo} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { templatesActions } from '../../../../reducer/templates';
import { programsActions } from '../../../../reducer/programs';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import ListingTable from '../../Common/ListingTableMUI';
import { v4 as uuidv4 } from 'uuid';

function Content({type, pageNo, setPageNo, pagination, perPage}) {
    const exerciseMasterList = useSelector(state => state?.templates?.exerciseMasterList);
    const selectedExerciseList = useSelector(state => type===2 ? state?.programs?.selectedExerciseList : state?.templates?.selectedExerciseList);
    const seletedExercise = type===2 ? programsActions?.seletedExercise : templatesActions?.seletedExercise;
    const dispatch = useDispatch();

    console.log(exerciseMasterList, "allExercise======>");
    console.log(selectedExerciseList, "selectedExercise======>");

    const selectAllCheckboxHandler = (e) => {
        e?.stopPropagation();
        dispatch(
            seletedExercise({
                type: "all",
                isSelected: e?.target?.checked,
                selectedLength: selectedExerciseList?.length
            })
        );
    };

    const handleIndividualSelect = (e, isSelected, exercise) => {
        e?.stopPropagation();
        dispatch(
            seletedExercise({
                type: "individual",
                isSelected,
                selectedLength: selectedExerciseList?.length,
                data: exercise
            })
        )
    };

    const tableHeaders = [
        {
            // label: "checkboxInput",
            align: "left",
            stickyClass: "stickyCell-left" 
        },
        {
            label: "Exercise Name",
            align: "left",
            // stickyClass: "stickyCell-left" 
        },
        {
            label: "Sports",
            align: "left"
        },
        {
            label: "Sets",
            align: "center"
        },
        {
            label: "Reps",
            align: "center"
        },
        // {
        //     label: "Media",
        //     align: "center"
        // }
    ];

    const getTableBody = () => {
        return (
            <TableBody>
                {
                    exerciseMasterList?.length
                    ?
                    exerciseMasterList?.map(exercise => {
                        let isSelected = selectedExerciseList?.find(
                            obj => obj?.exercise_id === exercise?.exercise_id
                        );
                        let reps = exercise?.metric_json?.find(metric => metric?.metric_id==9);
                        let sports = exercise?.sport_name;
                        let sportsCount = sports?.length;
                        let sportName = sportsCount > 2 ? `${sports?.slice(0, 2)?.join(", ")}` : sports?.join(", ");
                        let otherCount = sportsCount > 2 ? `+${sportsCount - 2}` : "";

                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={uuidv4()}
                                selected={isSelected ? true : false}
                            >
                                <TableCell padding="checkbox" className="stickyCell-left">
                                    <Checkbox 
                                        color="warning"
                                        checked={isSelected ? true : false}
                                        onClick={(e) => handleIndividualSelect(e, !isSelected, exercise)}
                                    />
                                </TableCell>
                                <TableCell 
                                    // className="stickyCell-left"
                                    align={"left"}
                                    sx={{minWidth: 150, cursor: "pointer"}}
                                    onClick={(e) => handleIndividualSelect(e, !isSelected, exercise)}
                                >
                                    { exercise?.exercise_name }
                                </TableCell>
                                <TableCell align={"left"}>
                                    { sportName ? sportName : "-" }
                                    {
                                        otherCount && 
                                        <span className="si-custom-table si-count">{ otherCount }</span>
                                    }
                                </TableCell>
                                <TableCell align={"center"}>
                                    { exercise?.default_sets ? exercise?.default_sets : "-" }
                                </TableCell>
                                <TableCell align={"center"}>
                                    { reps?.value ? reps?.value : "-" }
                                </TableCell>
                                {/* <TableCell align={"center"}>
                                    <button 
                                        type="button" 
                                        className="si-icon__button si-icon__media"
                                        style={{margin:"auto"}}
                                    ></button>
                                </TableCell> */}
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={tableHeaders.length}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };

    return (
        <div className="si-main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                rowCount={exerciseMasterList?.length}
                selectedCount={selectedExerciseList?.length} 
                checkboxHandler={selectAllCheckboxHandler}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={pagination}
                perPage={perPage}
            />
        </div>
    );
};

export default memo(Content);