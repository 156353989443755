import { authActions } from '../reducer/auth';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import API from '../utility/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
const Auth = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate  = useNavigate();
  const location = useLocation();
  const isAuth = useSelector(state => state.auth.isAuthenticated)
  const APILib = new API();
  const [errorMessages, setErrorMessages] = useState({});
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [username,setUsername]=useState('');
  const [password,setPassword]=useState('');

  // const redirectPath = location.state?.path || '/';

  const checkUserToken = () => {
    let data = Cookies.get('userdetails');
    console.log(data);
    let userDetails = data ? JSON.parse(decodeURIComponent(data))  :'';
    console.log(userDetails , userDetails?.Value?.user_info);
    if(userDetails?.Value?.user_info){
        dispatch(
          authActions.login({
            data: userDetails?.Value?.user_info, 
            callback: () => navigate("/",{ replace: true })
          })
        );
    }
  }
  useEffect(() => {
      checkUserToken();
  }, []);

  const loginHandler = async(event)=>{
    event.preventDefault();
    console.log(username,password);
    if(!username || !password){
      setErrorMessages({
        'msg':"Invalid login credentials"
      });
      return;
    }
    let data = {
      "user_name": username,
      "password": password
    };
    try{
      let res = await APILib.postLoginDetails('attemptlogin',data);
      if(res.status === 200){
        console.log(res);
        setErrorMessages({});
        // toast.success("Login Successfully");
        checkUserToken();
      }
    }catch(err){
      console.log("Error while login:",err);
      setErrorMessages({
        'msg':"Invalid login credentials"
      });
      setUsername('');
      setPassword('');
    };
  };

  useEffect(()=>{
    if(errorMessages?.msg){
      toast.warn(errorMessages.msg);
    };
  },[errorMessages]);

 return (
      <div className="si-page-bg si-login__page">
        <div className="si-main__container">
              <div className="si-login__form">
                  <div className="si-login__left"></div>
                  <div className="si-login__Logo">
                      <img src="../../images/login-logo.png"/>
                  </div>
                  <div className="si-login__right">
                      <div className="si-login__head">
                          <span className="si-title">Login</span>
                          <span className="si-subtitle">to your account</span>
                      </div>
                      <div className="si-login__input">
                          <form onSubmit={loginHandler}>
                              <div className="si-username">
                                  <input type="text" name='uname'  id="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" required/>
                              </div>
                              <div className="si-password">
                                  <input type="password" name='pass' id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required/>
                              </div>
                              <div className="si-login__button">
                                  <span>Login</span>
                                  <button type="submit"></button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
        </div>
      </div>
  );
};

export default Auth;
