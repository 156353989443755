import React from 'react';

function NotesMediaTab({ activeTab, exerciseToEdit, setExerciseToEdit, isViewOnly }) {
    const notesChangeHandler = (e) => {
        setExerciseToEdit(prevState => {
            return { ...prevState, athlete_notes: e?.target?.value }
        });
    };

    const mediaChangeHandler = (e, idx) => {
        let prevMedia = exerciseToEdit?.media;
        let media = Array(idx+1)?.fill("")?.map((obj, id) => {
            return {
                "id": prevMedia?.[id] ? prevMedia?.[id]?.id : 0,
                "url": idx===id ? e?.target?.value : prevMedia?.[id]?.url ? prevMedia?.[id]?.url : ""
            };
        });
        
        setExerciseToEdit(prevState => {
            return { ...prevState, media }
        });
    };

    return (
        <div className={
                `si-tab-pane si-tab-pane__notesmedia 
                ${ isViewOnly ? "si-viewmode" : "" } 
                ${ activeTab !== "notes_media" ? "d-none" : "" }`
            }
        >
            <div className="content-wrapper">
                <div className="custom-label">
                    <label htmlFor="notes" className="si-label">notes</label>
                    {
                        !isViewOnly && 
                        <span className="custom-label__text"> Any additional instructions for the athlete</span>
                    }
                </div>
                <div className="form-control">
                    <textarea 
                        id="notes" 
                        name="" 
                        rows={exerciseToEdit?.athlete_notes ? 2 : 2}
                        cols="" 
                        className="si-input"
                        placeholder={isViewOnly ? "" : "Add notes"}
                        value={exerciseToEdit?.athlete_notes ? exerciseToEdit?.athlete_notes : ""}
                        onChange={(e) => notesChangeHandler(e)}
                        disabled={isViewOnly}
                    />
                </div>

                <div className="custom-label">
                    <span className="si-label">media</span>
                    {
                        !isViewOnly && 
                        <span className="custom-label__text"> Links to videos or other media</span>
                    }
                </div>
                <div className="si-form-inline">
                    <div className="form-control">
                        <label htmlFor="media1" className="si-label">Media 1</label>
                        {
                            !isViewOnly 
                            ?
                            <input 
                                type="text" 
                                name="" id="media1" 
                                className="si-input"
                                placeholder={isViewOnly ? "" : "Add Url"}
                                value={exerciseToEdit?.media ? exerciseToEdit?.media?.[0]?.url : ""}
                                onChange={(e) => mediaChangeHandler(e, 0)}
                                disabled={isViewOnly}
                            />
                            :
                            <span className="media-url si-input">
                                {
                                    exerciseToEdit?.media && exerciseToEdit?.media?.[0]?.url &&
                                    <a 
                                        target="_blank"
                                        href={exerciseToEdit?.media?.[0]?.url}
                                    >
                                        {exerciseToEdit?.media?.[0]?.url}
                                    </a>
                                }
                            </span>
                        }
                    </div>
                    <div className="form-control">
                        <label htmlFor="media2" className="si-label">Media 2</label>
                        {
                            !isViewOnly 
                            ?
                            <input 
                                type="text" 
                                name="" id="media2" 
                                className="si-input" 
                                placeholder={isViewOnly ? "" : "Add Url"}
                                value={exerciseToEdit?.media ? exerciseToEdit?.media?.[1]?.url : ""}
                                onChange={(e) => mediaChangeHandler(e, 1)}
                                disabled={isViewOnly}
                            />
                            :
                            <span className="media-url si-input">
                                {
                                    exerciseToEdit?.media && exerciseToEdit?.media?.[1]?.url && 
                                    <a 
                                        target="_blank"
                                        href={exerciseToEdit?.media?.[1]?.url}
                                    >
                                        {exerciseToEdit?.media?.[1]?.url}
                                    </a>
                                }
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotesMediaTab;