import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCoachAthletesList, fetchGroupListing } from '../../../../utility/programApiActions';
import { fetchAllAthletesList, fetchSportsList } from '../../../../utility/templateApiActions';
import { loaderActions } from '../../../../reducer/loader';
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import SubHeader from '../../Common/SubHeader';
import Content from './Content';
import { ADMIN_ROLE_ID, SNC_COACH_ROLE_ID, TECHNICAL_COACH_ROLE_ID } from '../../../../utility/constants';

export default function AthleteListing() {
    const navigate = useNavigate(); 
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedSports, setSelectedSports] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const isAuth = useSelector(state => state?.auth?.isAuthenticated);
    const userDetails = useSelector(state => state?.auth?.userData);
    const paginationObject = useSelector(state => (state?.auth?.userData?.role_id== ADMIN_ROLE_ID) 
                                                    ? state?.templates?.paginationObject
                                                    : state?.programs?.paginationObject );
    const fetchAthletesListing = (userDetails?.role_id== ADMIN_ROLE_ID) 
                                  ? fetchAllAthletesList
                                  : fetchCoachAthletesList ;
    const dispatch = useDispatch();
    let filterOptions = {
        is_tab:false,
        is_sport_filter: true,
        is_search_filter: true,
        is_group_filter: true
    };

    useEffect(() => {
        if(isAuth) {
            let timeOut = searchTerm!==null ? 1000 : 0;
            let athleteListTimeOut = setTimeout(() => {
                dispatch(loaderActions?.showLoader());
                dispatch(
                    fetchAthletesListing({
                        pageNo,
                        searchTerm,
                        userguid: userDetails?.user_guid,
                        selectedSports,
                        type: userDetails?.role_id== SNC_COACH_ROLE_ID ? 1 : 2,
                        groupFilter: selectedGroups,
                        callback: () => setTimeout(() =>{
                            dispatch(loaderActions?.hideLoader());
                        })
                    })
                );
            }, timeOut);

            return () => clearTimeout(athleteListTimeOut);
        };
    }, [isAuth, searchTerm, pageNo, selectedGroups, selectedSports]);

    useEffect(() => {
        dispatch(fetchSportsList());
        dispatch(fetchGroupListing());
    }, []);

    return (
        <Fragment>
            <Header
                headerData={{
                    heading: "Athletes",
                    isHeadingEditable: false
                }}
            />
            <SubHeader
                OtherClass='si-completed-subheader'
                filterOptions={filterOptions}
                searchTerm={ searchTerm }
                setSearchTerm={ setSearchTerm }
                selectedSports={ selectedSports }
                setSelectedSportIds={ setSelectedSports }
                selectedGroupsIds={ selectedGroups }
                setSelectedGroupIds={ setSelectedGroups }
                pageNo={ pageNo }
                setPageNo={ setPageNo }
                navigate={ navigate }
                isBtn={false}
            />
            <Content
                pageNo={ pageNo }
                setPageNo={ setPageNo } 
                pagination={ paginationObject }
                perPage={ 20 }
            />
        </Fragment>
    );
}
