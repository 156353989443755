import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import { v4 as uuidv4 } from 'uuid';

export default function ListingTableHeader({tableHeaders, rowCount, selectedCount, checkboxHandler}) {
    
  return (
    <TableHead>
        <TableRow>
            {
                tableHeaders?.map((data,index) => {
                    if(data?.label == "checkboxInput") {
                        return (
                            <TableCell 
                                key={uuidv4()} 
                                padding="checkbox"
                                align={data?.align}
                                className={data?.stickyClass ? data?.stickyClass :''}
                                colSpan={data?.colSpan ? data?.colSpan : ''}
                                // sx={{position: "sticky",
                                // left: 0,
                                // background: "white",
                                // boxShadow: "5px 2px 5px grey",
                                // borderRight: "2px solid black"}}
                            >
                                <Checkbox 
                                    // color="warning"
                                    indeterminate={selectedCount > 0 && selectedCount < rowCount}
                                    checked={rowCount > 0 && selectedCount === rowCount}
                                    onChange={checkboxHandler}
                                    // sx={{"& input":{position:"absolute"}}}
                                />
                            </TableCell>
                        );
                    };
                    return (
                        <TableCell 
                            key={uuidv4()}
                            align={data?.align}
                            colSpan={data?.colSpan ? data?.colSpan : ''}
                            className={data?.stickyClass ? data?.stickyClass :''}
                            width={data?.width ? data.width : ''}
                        >
                            {data?.label}
                        </TableCell>
                    );
                })
            }
        </TableRow>
    </TableHead>
    // <div className="si-custom-table__head">
    //   <div className="si-custom-table__row">
    //     {
    //       tableHeaders.map((element,index) => {
    //         return <div className={`si-custom-table__cell ${element.toLowerCase() === 'sports' ? 'flex-1':''}`} key={index}>{element}</div>
    //       })
    //     }
    //   </div>
    // </div>
  )
}
