import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ATHELETE_ROLE_ID } from '../../../utility/constants';

function FunctionListing({ testsList, handleClick, btnText,from }) {
    // const userData = useSelector(state => state?.auth?.userData);
    // let btnText = from=="profile" || userData?.role_id == ATHELETE_ROLE_ID  ? "View Reports" : "View Athletes";
  
    return (
        <div className="si-main-testAss si-assesment">
            <div className="si-sections">
                {
                    testsList?.map(test => {
                        return <div key={uuidv4()} className={`si-testwrp ${!test?.is_acessible ? "si-disabled" : ""}`}>
                            <div className="si-top">
                               <div className="si-testimg"> 
                                <img src={`../../images/test-assesment-icons/${test?.function_id}.svg`} alt="img" />
                                </div>
                                <div className="si-testName">
                                    <span>{ test?.function_name }</span>
                                </div>
                            </div>
                            <div className="si-middle">
                                <span>
                                    { test?.function_desc }
                                </span>
                            </div>
                            <div className="si-bottom">
                                <button 
                                    type="button" 
                                    className="si-button si-button--green" 
                                    disabled={!test?.is_acessible ? true : false}
                                    onClick={(e) => handleClick(e, test?.function_id)}
                                >
                                    <span className="icon-eye"></span>{ btnText }
                                </button>
                            </div>
                        </div>
                    })
                }
                
                {/* <div className="si-testwrp si-disabled">
                    <div className="si-top">
                        <div className="si-testimg">
                            <img src="../../images/laboratory-test.png" />
                        </div>
                        <div className="si-testName">
                            <span>Biochemistry Tests</span>
                        </div>
                    </div>
                    <div className="si-middle">
                        <span>
                            Biochemical tests are most often applied to samples of serum, plasma and
                            urine where levels of specific chemicals are measured and the results
                            compared with those representative of a healthy individual.
                        </span>
                    </div>
                    <div className="si-bottom">
                        <button type="button" className="si-button si-button--green " disabled={true}>
                            <span className="icon-eye"></span>View Athletes
                        </button>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default FunctionListing;