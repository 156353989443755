import React from 'react';

function NotesMediaTab({ activeTab, exerciseToAdd, setExerciseToAdd }) {
    const notesChangeHandler = (e) => {
        setExerciseToAdd(prevState => {
            return { ...prevState, athlete_notes: e?.target?.value }
        });
    };

    const mediaChangeHandler = (e, idx) => {
        let prevMedia = exerciseToAdd?.media;
        let media = Array(idx+1)?.fill("")?.map((obj, id) => {
            return {
                "id": prevMedia?.[id] ? prevMedia?.[id]?.id : 0,
                "url": idx===id ? e?.target?.value : prevMedia?.[id]?.url ? prevMedia?.[id]?.url : ""
            };
        });
        
        setExerciseToAdd(prevState => {
            return { ...prevState, media }
        });
    };

    return (
        <div className={`si-tab-pane si-tab-pane__notesmedia ${ activeTab !== "notes_media" ? "d-none" : "" }`}>
            <div className="content-wrapper">
                <div className="custom-label">
                    <label htmlFor="notes" className="si-label">notes</label>
                    <span className="custom-label__text"> Any additional instructions for the athlete</span>
                </div>
                <div className="form-control">
                    <textarea 
                        id="notes" 
                        name="" 
                        rows={exerciseToAdd?.athlete_notes ? 2 : 1}
                        cols="" 
                        className="si-input"
                        placeholder="Add notes"
                        value={exerciseToAdd?.athlete_notes ? exerciseToAdd?.athlete_notes : ""}
                        onChange={(e) => notesChangeHandler(e)}
                    />
                </div>

                <div className="custom-label">
                    <span className="si-label">media</span>
                    <span className="custom-label__text"> Links to videos or other media</span>
                </div>
                <div className="si-form-inline">
                    <div className="form-control">
                        <label htmlFor="media1" className="si-label">Media 1</label>
                        <input 
                            type="text" 
                            name="" id="media1" 
                            className="si-input"
                            placeholder="Add Url"
                            value={exerciseToAdd?.media ? exerciseToAdd?.media?.[0]?.url : ""}
                            onChange={(e) => mediaChangeHandler(e, 0)}
                        />
                    </div>
                    <div className="form-control">
                        <label htmlFor="media2" className="si-label">Media 2</label>
                        <input 
                            type="text" 
                            name="" id="media2" 
                            className="si-input" 
                            placeholder="Add Url"
                            value={exerciseToAdd?.media ? exerciseToAdd?.media?.[1]?.url : ""}
                            onChange={(e) => mediaChangeHandler(e, 1)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotesMediaTab;