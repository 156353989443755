import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect } from "react";

import { useNavigate } from "react-router";
import ListingTable from "../Common/ListingTableMUI";
import { v4 as uuidv4 } from "uuid";
import { ATHELETE_ROLE_ID } from "../../../utility/constants";

function InjuryListingContaint({ injuryList, userData, pageNo, setPageNo, pagination }) {
  const navigate = useNavigate();

  const tableHeaders = [
    // {
    //   label: "Injury Name/ID",
    //   align: "left",
    // },
    {
      label: "Injury Type",
      align: "left",
    },

    {
      label: "Injured on",
      align: "left",
    },

    {
      label: "Injury Duration",
      align: "left",
    },
    {
      label: "",
      align: "right",
      stickyClass: "stickyCell-right",
    },
  ];
  const athleteHeaderCol = {
    label: "Injury Name",
    align: "left",
  };
  const coachHeaderCol = {
    label: "Athlete Name",
    align: "left",
  };
  const formatDate = (date) => {
    let formatedDate = date?.split("T")[0]?.split("-").reverse().join("/");
    return formatedDate;
  };

  const getHeaders = () => {
    return Number(userData.role_id) === ATHELETE_ROLE_ID
      ? [athleteHeaderCol, ...tableHeaders]
      : [coachHeaderCol, athleteHeaderCol, ...tableHeaders];
  };

  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {injuryList?.length ? (
          injuryList?.map((injury, idx) => {
            return (
              <TableRow key={uuidv4()}>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                  onClick={(e) => navigate(`details/${injury?.athlete_injury_id}`)}
                >
                  {Number(userData.role_id) === ATHELETE_ROLE_ID ? injury?.injury_name : injury?.athlete_name}
                </TableCell>
                {Number(userData.role_id) === ATHELETE_ROLE_ID ? null : (
                  <TableCell align={"left"}>{injury?.injury_name ? injury?.injury_name : "-"}</TableCell>
                )}
                <TableCell align={"left"}>{injury?.injury_type_name ? injury?.injury_type_name : "-"}</TableCell>

                <TableCell align={"left"}>{injury?.injury_date ? formatDate(injury.injury_date) : "-"}</TableCell>
                <TableCell align={"left"}>
                  {(()=>{
                    let textString = "";
                    if(injury?.duration || injury?.duration == "0"){
                      let days = Number(String((injury?.duration % 7))?.split(".")[0])
                      let weeks = Number(String((injury?.duration / 7))?.split(".")[0])
                      if(weeks && injury?.duration > 7){
                        if(weeks > 1){
                          textString += (weeks + " Weeks ")
                        } else {
                          textString += (weeks + " Week ")
                        }
                      }
                      if(days){
                        if(days > 1){
                          textString += (days + " Days")
                        } else {
                          textString += (days + " Day")
                        }
                      }
                      return textString
                    } else {
                      return "-"
                    }
                  })()}
                </TableCell>

                <TableCell align={"right"} className="stickyCell-right">
                  <button
                    type="button"
                    className="si-viewBtn"
                    onClick={(e) => navigate(`details/${injury?.athlete_injury_id}`)}
                  >
                    <span className="si-icon__view"></span>
                    <span className="si-txt">View details</span>
                  </button>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={getHeaders().length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  useEffect(() => {
    getTableBody();
  }, [injuryList]);
  return (
    <>
      <ListingTable
        tableHeaders={getHeaders()}
        getTableBody={getTableBody}
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={pagination}
        perPage={20}
      />
    </>
  );
}

export default InjuryListingContaint;
