import moment from "moment";
import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { clearNutritionLogDetails, resetLogDetailsUpdated } from "../../../../reducer/nutrition.reducer";
import { ATHELETE_ROLE_ID } from "../../../../utility/constants";
import { getNutritionLogDetails, updateLogDetails } from "../../../../utility/nutritionApiAction";
import DatePickerComponent from "../../Common/DatePickerComponent";

function IndividualNutritionPlan({VIEWMODEONLY}) {
  let { logId, typeId, groupId, athleteId } = useParams();
  let [viewMode, setViewMode] = useState(true);
  let [logData, setLogData] = useState({ date: new Date(), data: null });
  let [errorState, setErrorState] = useState(false)
  let userData = useSelector((state) => state?.auth?.userData);
  let loading = useSelector((state) => state.nutrition.loading);
  let logDetailsUpdated = useSelector((state) => state.nutrition.logDetailsUpdated);
  let logDetails = useSelector((state) => state?.nutrition?.nutritionLogDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (logDetailsUpdated) {
      setViewMode(true);
      dispatch(resetLogDetailsUpdated());
    }
  }, [logDetailsUpdated, dispatch]);
  useEffect(() => {
    if (userData && userData?.user_guid) {
      dispatch(
        getNutritionLogDetails({
          type: Number(userData?.role_id) === ATHELETE_ROLE_ID ? "2" : typeId,
          id: logId,
          user_guid: userData?.user_guid,
          group_id: groupId,
          athlete_id: Number(userData?.role_id) === ATHELETE_ROLE_ID ? userData?.user_id : athleteId,
        })
      );
    }
    return () => dispatch(clearNutritionLogDetails());
  }, [dispatch, logId, typeId, userData, groupId, athleteId]);

  useEffect(() => {
    if (logDetails) {
      setLogData({
        date: logDetails.log_date ? new Date(logDetails.log_date) : new Date(),
        data: logDetails.log_data ? logDetails.log_data : null,
      });
    }
    if ((!logDetails || !logDetails?.log_data) && !VIEWMODEONLY) {
      setViewMode(false);
    } else {
      setViewMode(true)
    }
  }, [logDetails, VIEWMODEONLY]);

  const onChangeHandle = ({ key, event }) => {
    if(errorState){
      setErrorState(false)
    }
    if (key === "date") {
      setLogData((old) => ({ ...old, date: moment(event).format("YYYY-MM-DD") }));
    } else {
      setLogData((old) => ({ ...old, data: event?.target?.value }));
    }
  };
  const submitData = () => {
    if(!logData || !logData?.data){
      setErrorState(true)
      return 
    }
    let payload = {
      nutrition_id: logId ? Number(logId) : logDetails?.nutrition_id ? logDetails?.nutrition_id : null,
      user_guid: userData?.user_guid ? userData?.user_guid : null,
      log_data: logData?.data,
      log_date: moment(logData?.date).format("YYYY-MM-DD"),
      group_id: typeId === "1" ? (groupId ? groupId : null) : null,
      athlete_id: typeId === "2" ? (athleteId ? athleteId : null) : null,
    };
    // type: typeId, id: logId, user_guid: userData?.user_guid, group_id: groupId }));
    let getDetailsPayload = {
      nutrition_id: logId ? logId : logDetails?.nutrition_id ? logDetails?.nutrition_id : null,
      group_id: groupId ? groupId : null,
      type: typeId ? typeId : null,
      athlete_id: athleteId ? athleteId : null,
      user_guid:
        // groupId ? null :
        userData?.user_guid ? userData?.user_guid : null,
    };
    dispatch(updateLogDetails({ payload, getDetailsPayload }));
  };

  return (
    <>
      {loading ? <div className="spin"></div> : null}
        <div className="nutrition-log individual-nutrition-plan">
          <div className="nutrition-log--header">
            <div className="title-icon-wrp">
              <div className="log-header--title">
                <span className="title-text">Dietary Recommendations </span>
                <span className="additional-text">(Individual)</span>
              </div>
              {VIEWMODEONLY ? null : viewMode ? <span className="icon-edit" onClick={() => setViewMode((old) => !old)}></span> : null}
            </div>
          </div>
          <div className="nutrition-log--subheader">
            <div className="form-group">
              <div className="form-group-wrap si-location date-form-control">
                <label className="si-label">Recommended on</label>
                <DatePickerComponent
                  isEditMode={VIEWMODEONLY ? true : viewMode ? true : false}
                  selectedDate={logData.date}
                  dateChangeHandler={(event) => {
                    onChangeHandle({ event, key: "date" });
                  }}
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="Recommended-wrp">
              <div className=" icon-label-wrp">
                <span className="icon-doctor-bag"></span>
                <label className="si-label">Recommended by</label>
              </div>
              <span className={`doctor-name ${viewMode ? "doctor-name-view":""}`}>
                {" "}
                {logDetails && logDetails.updated_by ? "By: " + logDetails?.updated_by : ""}
              </span>
            </div>
          </div>
          <div className={`nutrition-log-content-wrp ${errorState ? "error-active":""} ${viewMode ? "view-mode-active" : "edit-mode-active"}`}>
            <div className="nutrition-log-content edit-mode" >
              <label className="si-label">Recommendation</label>
              <textarea
                key={viewMode}
                placeholder="Start typing here"
                defaultValue={logDetails && logDetails.log_data ? logDetails?.log_data : ""}
                // onChange={(event) => onChangeHandle({ event, key: "data" })}
                onKeyUp={(event)=>onChangeHandle({ event, key: "data" })}
              />
            </div>
            <div className="nutrition-log-content view-mode">
              <label className="si-label">Recommendation</label>
              <textarea readOnly value={logDetails && logDetails.log_data ? logDetails?.log_data : ""} />
            </div>
          </div>
        </div>
        <div className="nutrition-footer">
          {viewMode ? null : (
            <>
              <button
                type="button"
                className="si-button si-icon--add si-clearbtn"
                onClick={() => {setViewMode((old) => !old); setErrorState(false)}}
              >
                cancel
              </button>
              <button type="button" className="si-button si-button--green" onClick={submitData}>
                save
              </button>
            </>
          )}
        </div>
    </>
  );
}

export default IndividualNutritionPlan;
