import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loaderActions } from '../../../../reducer/loader';
import Content from './Content';
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import SubHeader from '../../Common/SubHeader';
import { fetchTechnicalAthletePrograms } from '../../../../utility/technicalProgramApiActions';

function AthleteProgramListing() {
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const isAuth = useSelector(state => state?.auth?.isAuthenticated);
    const paginationObject = useSelector(state => state?.technicalPrograms?.paginationObject);
    const userDetails = useSelector(state => state?.auth?.userData);
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    let filterOptions = {
        is_search_filter: true,
        is_tab:true
    };

    useEffect(() => {
        if(isAuth){
            let programListTimeOut = setTimeout(() => {
                if(searchTerm!==null) dispatch(loaderActions?.showLoader());                
                dispatch(
                    fetchTechnicalAthletePrograms({
                        pageNo,
                        activeTab,
                        searchTerm,
                        userguid: userDetails?.user_guid,
                        callback: () => setTimeout(() =>{
                            dispatch(loaderActions?.hideLoader());
                        })
                    })
                );
            }, 1000);

            return () => clearTimeout(programListTimeOut);
        }
    }, [isAuth, searchTerm]);

    useEffect(() => {
        if(isAuth){
            let programListTimeOut = setTimeout(() => {
                dispatch(loaderActions?.showLoader());
                dispatch(
                    fetchTechnicalAthletePrograms({
                        pageNo,
                        activeTab,
                        searchTerm,
                        userguid: userDetails?.user_guid,
                        callback: () => setTimeout(() =>{
                            dispatch(loaderActions?.hideLoader());
                        })
                    })
                );
            });

            return () => clearTimeout(programListTimeOut);
        }
    }, [isAuth,activeTab]);

    return (
        <Fragment>
            <Header 
                headerData={{
                    heading: "Current Programs",
                    isHeadingEditable: false
                }}
            />

            <>
                <SubHeader 
                    filterOptions={filterOptions}
                    searchTerm={ searchTerm }
                    setSearchTerm={ setSearchTerm }
                    pageNo={ pageNo }
                    setPageNo={ setPageNo }
                    navigate={ navigate }
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <Content 
                    pageNo={ pageNo } 
                    setPageNo={ setPageNo }
                    pagination={ paginationObject }
                    perPage={ 20 }
                    searchTerm={ searchTerm }
                    // selectedSports={ selectedSports }
                    // setIsFiltered={ setIsFiltered }
                    activeTab={activeTab}
                    subModule='assignedprograms'
                    backUrl='/technical/assignedprograms'
                />
                {/* <Footer 
                    pageNo={pageNo} 
                    setPageNo={setPageNo} 
                    pagination={paginationObject}
                    perPage={20}
                /> */}
            </>
        </Fragment>
    )
};

export default AthleteProgramListing;