import React from "react";
import { ATHELETE_ROLE_ID } from "../../../utility/constants";
import Dropdown from "../Common/Dropdown";

function InjuryDetailsCoachSection({
  injuryTypes,
  icdInjuryCodes,
  injuryDetails,
  setInjuryDetails,
  setShowErrors,
  userData,
  showError,
}) {
  const onChangeHandle = ({ event, key }) => {
    if (key === "bodyParts") {
      setInjuryDetails((old) => {
        return {
          ...old,
          selectedBodyPartName: event?.label,
          selectedBodyPartId: event?.value,
        };
      });
      setShowErrors((old) => ({
        ...old,
        selectedBodyPartId: { show: false },
      }));
    } else if (key === "IcdInjuryCode") {
      setInjuryDetails((old) => {
        return {
          ...old,
          selectedIcdInjuryCodeName: event?.label,
          selectedIcdInjuryCodeId: event?.value,
        };
      });
      setShowErrors((old) => ({
        ...old,
        selectedIcdInjuryCodeId: { show: false },
      }));
    } else if (key === "InjuryType") {
      setInjuryDetails((old) => {
        return {
          ...old,
          selectedInjuryTypeName: event?.label,
          selectedInjuryTypeId: event?.value,
        };
      });
      setShowErrors((old) => ({
        ...old,
        selectedInjuryTypeId: { show: false },
      }));
    } else {
      setInjuryDetails((old) => {
        return {
          ...old,
          [key]: event?.target?.value,
        };
      });
      setShowErrors((old) => ({
        ...old,
        [key]: { show: false },
      }));
    }
  };
  return (
    <div
      className={`for-physiotherapist-only ${
        Number(userData?.role_id) === ATHELETE_ROLE_ID ? "for-physiotherapist-disabled" : ""
      }`}
    >
      <div className="si-title">For Physiotherapist only</div>
      <div className="physiotherapist-content-wrp">
        <div className="drpdwn-wrp">
          <div className={`form-control ${showError?.selectedIcdInjuryCodeId?.show ? "si-error" : ""} selectSport-form-control`}>
            <div className="form-group">
              <label for="selectSport" className="si-label">
                <span>*</span>ICD Injury Code
              </label>
              <Dropdown
                isDisabled={Number(userData?.role_id) === ATHELETE_ROLE_ID}
                value={{
                  value: injuryDetails?.selectedIcdInjuryCodeId ? injuryDetails?.selectedIcdInjuryCodeId : "",
                  label: injuryDetails?.selectedIcdInjuryCodeName ? injuryDetails?.selectedIcdInjuryCodeName : "Code",
                }}
                options={icdInjuryCodes?.map((obj) => {
                  return {
                    value: obj?.icd_injury_code_id,
                    label: obj?.icd_injury_code,
                  };
                })}
                changeHandler={(data) => {
                  onChangeHandle({ event: { value: data.value, label: data.label }, key: "IcdInjuryCode" });
                }}
              />
            </div>
            <span className="si-error-message">
              {showError?.selectedIcdInjuryCodeId?.show ? showError?.selectedIcdInjuryCodeId?.msg : ""}
            </span>
          </div>
          <div className={`form-control ${showError?.InjuryType?.show ? "si-error" : ""} selectSport-form-control`}>
            <div className="form-group">
              <label for="selectSport" className="si-label">
                Injury Type
              </label>
              <Dropdown
                isDisabled={Number(userData?.role_id) === ATHELETE_ROLE_ID}
                value={{
                  value: injuryDetails?.selectedInjuryTypeId ? injuryDetails?.selectedInjuryTypeId : "",
                  label: injuryDetails?.selectedInjuryTypeName ? injuryDetails?.selectedInjuryTypeName : "Type",
                }}
                options={injuryTypes?.map((obj) => {
                  return {
                    value: obj?.injury_type_id,
                    label: obj?.injury_type_name,
                  };
                })}
                changeHandler={(data) => {
                  onChangeHandle({ event: { value: data.value, label: data.label }, key: "InjuryType" });
                }}
              />
            </div>
            <span className="si-error-message">{showError?.InjuryType?.show ? showError?.InjuryType?.msg : ""}</span>
          </div>
        </div>
        <div className="injury-description">
          <div className={`form-control ${showError?.coachDescription?.show ? "si-error" : ""}`}>
            <label for="" className="si-label">
            Cause of Injury
            </label>
            <textarea
              id="test"
              name=""
              rows="2"
              cols=""
              disabled={Number(userData?.role_id) === ATHELETE_ROLE_ID}
              className="si-input"
              placeholder="Add a short description of injury"
              defaultValue={injuryDetails?.coachDescription ? injuryDetails?.coachDescription : ""}
              onChange={(event) => {
                onChangeHandle({ event, key: "coachDescription" });
              }}
            ></textarea>
            <span className="si-error-message">
              {showError?.coachDescription?.show ? showError?.coachDescription?.msg : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InjuryDetailsCoachSection;
