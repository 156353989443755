import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchNutritionSportsList } from "../../../utility/nutritionApiAction";
import Header from "../Common/Header";
import SubHeader from "../Common/SubHeader";
import NutritionSportsListingContent from "./NutritionSportsListingContent";

function NutritionSprotsListing() {
  let [searchTerm, setSearchTerm] = useState(null);
  let [shortedSports, setShortedSports] = useState([]);
  let loading = useSelector((state) => state.nutrition.loading);
  let sports = useSelector((state) => state.nutrition.sportsList);
  let userData = useSelector((state)=> state.auth.userData)
  const dispatch = useDispatch();
  let filterOptions = {
    is_search_filter_for_athlete: true,
  };

  useEffect(() => {
    if(userData?.user_guid)
    dispatch(fetchNutritionSportsList({user_guid:userData?.user_guid}));
  }, [userData, dispatch]);

  useEffect(() => {
    if (searchTerm !== null) {
      setShortedSports((old) =>
        sports?.length
          ? sports.filter((sport) => {
              return !String(sport.sport_name).toLocaleLowerCase().search(String(searchTerm).toLocaleLowerCase());
            })
          : []
      );
    } else {
      setShortedSports(sports);
    }
  }, [searchTerm, sports]);

  useEffect(() => {
    if (sports?.length) {
      setShortedSports(sports);
    }
  }, [sports]);

  return (
    <>
      {loading ? <div className="spin"></div> : null}
      <Header
        headerData={{
          heading: "Nutrition",
          isHeadingEditable: false,
        }}
      />
      <SubHeader
        filterOptions={filterOptions}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        pageNo={1}
        setPageNo={() => {}}
        // navigate={navigate}
        // isBtn={isAddBtn}
        // btnName="Add New Case"
        // onBtnClick={() => navigate("athletes")}
      />
      <NutritionSportsListingContent sports={shortedSports} />
    </>
  );
}

export default NutritionSprotsListing;
