import { Radio, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Header from "../../Common/Header";
import ListingTable from "../../Common/ListingTableMUI";
import SubHeader from "../../Common/SubHeader";
import { v4 as uuidv4 } from "uuid";
import Footer from "../../Common/Footer";
import { useDispatch } from "react-redux";
import { resetSelectedPerformanceId, setSelectedTournament } from "../../../../reducer/tournamentsPerformance.reducer";
import { TOURNAMENT_MODULE_ROUTE, TOURNAMENT_PERFORMANECE_LISTING_ROUTE } from "../../../../utility/constants";
import { getTournamentLists } from "../../../../utility/tournamentsApiActions";
import { updatePerformaceRecord } from "../../../../utility/tournamentPerformanceApiAction";

function SelectTournament() {
  let [selectedSports, setSelectedSports] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const tournamentListing = useSelector((state) => state?.tournaments?.tournamentList);
  const paginationObject = useSelector((state) => state?.tournaments?.paginationObject);
  let selectedTournament = useSelector((state) => state?.tournamentPerformance?.selectedTournament);
  let selectedPerformaceRecordId = useSelector((state) => state?.tournamentPerformance?.selectedPerformaceRecordId);
  const updateLoading = useSelector((state)=> state?.tournaments?.updateTournamentLoading)
  const userData = useSelector((state) => state?.auth?.userData);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getTournamentLists({
        user_id: userData?.user_id ? userData.user_id : null,
        page_no: pageNo ? pageNo : 1,
        search: searchTerm ? searchTerm : null,
        sport_id: selectedSports?.length ? selectedSports : null,
        item_number: 20,
      })
    );
    dispatch(resetSelectedPerformanceId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getTournamentLists({
        sport_id: selectedSports.length ? selectedSports : null,
        searchText: searchTerm,
        page_no: pageNo,
        user_id: userData?.user_id ? userData.user_id : null,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSports, pageNo, dispatch]);

  useEffect(() => {
    if(searchTerm !== null){
      let programListTimeOut = setTimeout(() => {
        dispatch(
          getTournamentLists({
          sport_id: selectedSports.length ? selectedSports : null,
          searchText: searchTerm,
          page_no: pageNo,
          user_id: userData?.user_id ? userData.user_id : null,
        })
        );
      }, 1000);
      return () => clearTimeout(programListTimeOut);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, dispatch]);

  let filterOptions = {
    is_sport_filter: true,
    is_search_filter: true,
    is_tab: false,
    is_group_filter: false,
  };
  const tableHeaders = [
    {
      // label: "checkboxInput",
      stickyClass: "stickyCell-left",
      align: "left",
    },
    {
      label: "Tournament Name",
      align: "left",
    },
    {
      label: "Sports",
      align: "left",
    },

    {
      label: "Start Date",
      align: "center",
    },

    {
      label: "Location",
      align: "center",
    },
  ];
  const formatDate = (date) => {
    let objectDate = new Date(date);

    let day = objectDate.getDate();

    let month = objectDate.getMonth() + 1;

    let year = objectDate.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const handleIndividualSelect = (e, tournament) => {
    e?.stopPropagation();
    dispatch(
      setSelectedTournament({
        data: tournament,
      })
    );
  };
  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {tournamentListing?.length ? (
          tournamentListing?.map((tournament, idx) => {
            return (
              <TableRow
                key={uuidv4()}
                selected={selectedTournament ? selectedTournament.tournament_id === tournament.tournament_id : false}
              >
                <TableCell
                  align={"left"}
                  padding="checkbox"
                  className="stickyCell-left"
                  sx={{ cursor: "pointer"}}
                  // onClick={(e) => handleEdit(e, program.program_id)}
                >
                  <Radio
                    color="warning"
                    // checked={isSelected ? true : false}
                    checked={selectedTournament ? selectedTournament.tournament_id === tournament.tournament_id : false}
                    onClick={(e) => handleIndividualSelect(e, tournament)}
                  />
                </TableCell>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                  onClick={(e) => handleIndividualSelect(e, tournament)}
                >
                  {tournament?.tournament_name}
                </TableCell>
                <TableCell align={"left"}>
                  {tournament?.sport_name
                    ? tournament?.sport_name?.length > 2
                      ? (<>
                        {`${tournament?.sport_name[0]}, ${tournament?.sport_name[1]}`}
                      <span className="si-custom-table si-count"> +{tournament?.sport_name?.length - 2}</span>
                      </>)
                      : tournament?.sport_name
                          ?.map((name, index) => (index + 1 === tournament.sport_name.length ? name : name + ","))
                          .join(" ")
                    : "-"}
                </TableCell>

                <TableCell align={"center"}>
                  {tournament?.start_date ? formatDate(tournament?.start_date) : "-"}
                </TableCell>
                <TableCell align={"center"}>{tournament?.location ? tournament?.location : "-"}</TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  const handleNext = (selectedTournament) => {
    if (selectedTournament?.tournament_id) {
      dispatch(
        updatePerformaceRecord({
          tournament_id: selectedTournament?.tournament_id,
          user_guid: userData?.user_guid,
        })
      );
    }
  };

  useEffect(() => {
    if (selectedPerformaceRecordId) {
      navigate(
        `/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_PERFORMANECE_LISTING_ROUTE}/${selectedPerformaceRecordId}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPerformaceRecordId]);
  return (
    <>
      {updateLoading && <div className="spin"></div>}
      <Header
        headerData={{
          heading: "Select a Tournament",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate(-1),
        }}
      />
      <SubHeader
        filterOptions={filterOptions}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedSports={selectedSports}
        setSelectedSportIds={setSelectedSports}
        pageNo={pageNo}
        setPageNo={setPageNo}
        OtherClass={"si-completed-subheader"}
        isBtn={false}
      />
            <div class="si-main-content tournament-main-content">
      <ListingTable
        tableHeaders={tableHeaders}
        getTableBody={getTableBody}
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={paginationObject}
        perPage={20}
      />
      </div>
      <Footer
        // pageNo={pageNo}
        // setPageNo={setPageNo}
        // pagination={paginationObject}
        // perPage={20}
        isActionBtn={true}
        btnClass={`si-button si-button--green`}
        // btnClass={`si-button ${selectedExerciseList?.length ? "si-button--green" : "si-button--green"}`}
        btnDisabled={selectedTournament?.tournament_id ? false : true}
        // btnDisabled={!selectedExerciseList?.length ? true : false }
        actionBtnText={"Next"}
        actionBtnHandler={() => handleNext(selectedTournament)}
      />
    </>
  );
}

export default SelectTournament;
