import { Login } from "@mui/icons-material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { authActions } from "../reducer/auth";

export default function ProtectedRoute({Component , type, from}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const  [isLoggedIn,setIsLoggedIn] = useState(false);
    const isAuth = useSelector(state => state.auth.isAuthenticated);
    const checkUserToken = () => {
        let data = Cookies.get('userdetails');
        let userDetails = data ? JSON.parse(decodeURIComponent(data))  :'';
        console.log(userDetails?.Value?.user_info);
        if(userDetails?.Value?.user_info){
            dispatch(authActions.login({data: userDetails?.Value?.user_info}));
            setIsLoggedIn(true);
        }else{
            dispatch(authActions.logout({callback: () => navigate("/login",{state:{
                path:location.pathname
                }})
            }));
        };
    };
    useEffect(() => {
        console.log("isLoggedIn: ",isLoggedIn);
        checkUserToken();
    }, [Component]);
  
    return (
        <React.Fragment>
            {
               <Component type={type} from={from}/>
            }
        </React.Fragment>
    );
}
