import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store from './store';
// import { BrowserRouter , HashRouter as Router} from 'react-router-dom';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<BrowserRouter basename={baseUrl}><Provider store={store}><App /></Provider></BrowserRouter>);
// root.render(<Router><Provider store={store}><App /></Provider></Router>);
root.render(<Provider store={store}><App /></Provider>);
