import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import TechnicalTemplates from '../components/RightPannel/TechnicalTraining/TemplateListing/TemplateListing';
import TechnicalTemplateCreationForm from '../components/RightPannel/TechnicalTraining/TemplateCreationForm/TemplateCreationForm';
import TechnicalTemplateCalendar from '../components/RightPannel/TechnicalTraining/TemplateCalendar/TemplateCalendar';
import TechnicalSelectExerciseList from '../components/RightPannel/TechnicalTraining/SelectExerciseList/SelectExerciseList';
import TechnicalSelectedExerciseList from '../components/RightPannel/TechnicalTraining/SelectedExerciseList/SelectedExerciseList';
import TechnicalExerciseMasterListing from '../components/RightPannel/TechnicalTraining/ExerciseMasterListing/ExerciseListing';
import ProtectedRoute from './ProtectedRoute';
import TechnicalProgramListing from '../components/RightPannel/TechnicalTraining/ProgramListing/ProgramListing';
import TechnicalCompletedAthleteProgramListing from '../components/RightPannel/TechnicalTraining/AthleteProgramListing/CompletedAthleteProgramListing';
import TechnicalCompletedPrograms from '../components/RightPannel/TechnicalTraining/ProgramListing/CompletedProgramListing';
import TechnicalProgramTemplates from '../components/RightPannel/TechnicalTraining/ProgramSelectList/ProgramSelectList';
import TechnicalProgramCalendar from '../components/RightPannel/TechnicalTraining/ProgramCalendar/ProgramCalendar';
import TechnicalProgramProgressCalendar from '../components/RightPannel/TechnicalTraining/ProgramProgressCalendar/ProgramProgressCalendar';
import TechnicalAthleteProgramListing from '../components/RightPannel/TechnicalTraining/AthleteProgramListing/AthleteProgramListing';
import AthleteReportListing from '../components/RightPannel/Common/AthleteReportListing/AthleteReportListing';
import { ATHELETE_ROLE_ID } from '../utility/constants';

export default function TechnicalRouting() {
    const userData = useSelector(state => state?.auth?.userData);

    return (
        <Routes>
            <Route 
                exact 
                path="/technical/templates" 
                element={ 
                    <ProtectedRoute Component={TechnicalTemplates}/>
                }
            />
            <Route 
                exact 
                path="/technical/templates/new" 
                element={ 
                    <ProtectedRoute Component={TechnicalTemplateCreationForm}/>
                }
            />
            <Route 
                exact 
                path="/technical/templates/:id/calendar" 
                element={ 
                    <ProtectedRoute Component={TechnicalTemplateCalendar}/>
                }
            />
            <Route 
                exact 
                path="/technical/templates/:id/calendar/exercisemaster" 
                element={ 
                    <ProtectedRoute Component={TechnicalSelectExerciseList} type={1}/>
                }
            />
            <Route 
                exact 
                path="/technical/templates/:id/calendar/exercise" 
                element={ 
                    <ProtectedRoute Component={TechnicalSelectedExerciseList} type={1}/>
                }
            />
            <Route exact path='/technical/exercisemaster' 
                element={ 
                    <ProtectedRoute Component={TechnicalExerciseMasterListing}/>
                }
            />
            <Route 
                exact 
                path="/technical/programs" 
                element={ 
                    <ProtectedRoute Component={TechnicalProgramListing}/>
                }
            />
            <Route 
                exact 
                path="/technical/completedprograms" 
                element={ 
                    <ProtectedRoute Component={userData?.role_id == ATHELETE_ROLE_ID
                        ? TechnicalCompletedAthleteProgramListing
                        : TechnicalCompletedPrograms} status={2} />
                }
            />
            <Route 
                exact 
                path="/technical/completedprograms/:id/calendar" 
                element={ 
                    <ProtectedRoute Component={TechnicalProgramCalendar} />
                }
            />
            <Route 
                exact 
                path="/technical/completedprograms/:id/progressCalendar/:userId" 
                element={ 
                    <ProtectedRoute Component={TechnicalProgramProgressCalendar}/>
                }
            />
            <Route 
                exact 
                path="/technical/programs/templates" 
                element={ 
                    <ProtectedRoute Component={TechnicalProgramTemplates}/>
                }
            />
            <Route 
                exact 
                path="/technical/programs/:id/calendar" 
                element={ 
                    <ProtectedRoute Component={TechnicalProgramCalendar} />
                }
            />
            <Route 
                exact 
                path="/technical/programs/:id/calendar/exercisemaster" 
                element={ 
                    <ProtectedRoute Component={TechnicalSelectExerciseList} type={2}/>
                }
            />
            <Route 
                exact 
                path="/technical/programs/:id/calendar/exercise" 
                element={ 
                    <ProtectedRoute Component={TechnicalSelectedExerciseList} type={2}/>
                }
            />
            <Route 
                exact 
                path="/technical/assignedprograms" 
                element={ 
                    <ProtectedRoute Component={TechnicalAthleteProgramListing}/>
                }
            />
            <Route 
                exact 
                path="/technical/assignedprograms/:id/progressCalendar/:userId" 
                element={ 
                    <ProtectedRoute Component={TechnicalProgramProgressCalendar}/>
                }
            />
            <Route 
                exact 
                path="/technical/programs/:id/calendar/progressCalendar/:userId" 
                element={ 
                    <ProtectedRoute Component={TechnicalProgramProgressCalendar}/>
                }
            />
        </Routes>
    )
}

