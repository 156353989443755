import React, { Fragment, useEffect, useState } from "react";
import { REMARK_ID } from "../../../../utility/constants";

export default function Test({ testDetails, headers, newReport, setNewReport }) {
  const [remark, setRemark] = useState('');
  const [remarkText, setRemarkText] = useState({});
  useEffect(() => {
    if (headers) {
      // let data = headers.filter((h) => h.id == REMARK_ID);
      let data = headers.filter((h) => h.is_grouped);
      if (data && data.length) {
        setRemark(data);
      }
    }
  }, [headers]);
  const getTestParamValue = (e, headerKey, subTestId, parameterId) => {
    let value = e?.target?.value;
    console.log(newReport, value, headerKey, subTestId, parameterId);
    setNewReport(oldState => {
      let prevState = {...oldState}
      let tests = prevState?.tests.map((test) => {
        if (test.id == subTestId) {
          if (parameterId != 'remark') {
            let testParam = test?.tests_parameter?.map((param) => {
              if (param.parameter_id == parameterId) {
                param ={...param, [headerKey]: value};
                return param
              } else{
                return param
              }
            })
            return {...test,tests_parameter:testParam }
          } else {
            test ={...test, remarks: value};
            return test
          }
        } else {
          return test
        }
      })
      return { ...prevState, tests };
    })
  }

  useEffect(() => {
    if (newReport && newReport.tests?.length) {
      let newObj = {}
      newReport.tests.forEach(test => {
        newObj = { ...newObj, [test.id]: test }
      });
      setRemarkText(newObj)
    }
  }, [newReport]);

  


  const getSubTestFromObject = (id, paramId) => {
    let subParam = {};
    let subTest = newReport?.tests?.filter((test) => {
      return test.id == id;
    })[0];
    if (subTest) {
      subParam = subTest?.tests_parameter?.filter((param) => {
        return param.parameter_id == paramId;
      })[0];
    }
    return subParam;
  }

  return (
    <Fragment>
      <div className="si-head">{testDetails?.test_name}</div>
      <div className="si-tblwrp">
        <div className="si-tableleft">
          {testDetails?.tests_parameter.map((param) => {
            return <div className="si-tbls__row " key={param.parameter_id}>

              {headers?.map((header) => {
                if (!header.is_grouped) {
                  // if (header.id != REMARK_ID) {
                  if (header.type == 'label') {
                    return <div className="si-tbls__cell" key={header.id}>
                      <span>{param?.parameter_name} ({param?.units})</span>
                    </div>
                  } else if (header.type == 'input') {
                    let subParam = getSubTestFromObject(testDetails?.id, param?.parameter_id);
                    return <div className="si-tbls__cell" key={header.id}>
                      <input type="text" className="si-score" placeholder="-" value={subParam[header.key]} onChange={(e) => { getTestParamValue(e, header.key, testDetails?.id, param?.parameter_id) }} />
                    </div>
                  } else if (header.type == 'textarea') {
                    let subParam = getSubTestFromObject(testDetails?.id, param?.parameter_id);
                    return <div className="si-tbls__cell" key={header.id}>
                      <textarea className="si-textarea" placeholder="-" value={subParam[header.key]} onChange={(e) => { getTestParamValue(e, header.key, testDetails?.id, param?.parameter_id) }} />
                    </div>
                  }
                  else if (header.type == 'span') {
                    let subParam = getSubTestFromObject(testDetails?.id, param?.parameter_id);
                    return <div className="si-tbls__cell" key={header.id}>
                      <span>{subParam[header.key] ? subParam[header.key] : 0}</span>
                    </div>
                  }
                }
              })}
            </div>
          })}
        </div>
        {remark ?
          <div className="si-tableright">
            {remark.map((r) => {
              return <div className="si-tbls__cell" key={r.key}>
                <textarea className="si-textarea"
                  placeholder="Add Remarks"
                  value={remarkText?.[testDetails.id]?.remarks ? remarkText[testDetails.id]?.remarks : ""}
                  onChange={(e) => { getTestParamValue(e, r.key, testDetails?.id, 'remark') }}>
                </textarea>
              </div>
            })}
          </div> : null
        }
      </div>
    </Fragment>
  );
}
