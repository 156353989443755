import React, {Fragment, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Header from '../../Common/Header';
import Dropdown from '../../Common/Dropdown';
import { templatesActions } from '../../../../reducer/templates';
import { fetchSportsList, postProgramTemplate } from '../../../../utility/templateApiActions';
import { toast } from 'react-toastify';
import FixedButton from '../../Common/FixedButton';

function ProgramCreationForm() {
  const sportList = useSelector(state => state?.templates?.sportList);
  const programName = useSelector(state => state?.templates?.programName);
  const duration = useSelector(state => state?.templates?.duration);
  const selectedSportId = useSelector(state => state?.templates?.selectedSportId);
  const programNameError = useSelector(state => state?.templates?.programNameError);
  const durationError = useSelector(state => state?.templates?.durationError);
  const selectedSportError = useSelector(state => state?.templates?.selectedSportError);
  const programNameErrorMsg = useSelector(state => state?.templates?.programNameErrorMsg);
  const durationErrorMsg = useSelector(state => state?.templates?.durationErrorMsg);
  const selectedSportErrorMsg = useSelector(state => state?.templates?.selectedSportErrorMsg);
  const selectedMenuId = useSelector(state => state?.sideMenu?.selectedMenuId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(templatesActions?.setDefault());
    if(!sportList?.length) dispatch(fetchSportsList());

    return () => dispatch(templatesActions?.setDefault());
  }, []);

  const sportsChangeHandler = (e) => {
    dispatch(templatesActions?.setSelectedSportId(e?.target?.value));
  };

  const sportOptions = sportList?.map(obj => {
    return {
        value: obj?.sport_id,
        label: obj?.sport_name
    };
  });

  const changeHandler = (data) => {
      dispatch(templatesActions?.setSelectedSportId({sportId: data?.value?.toString()}));
  };

  const clickHandler = (e) => {
    e.preventDefault();
    if(programName && duration && selectedSportId) {
      dispatch(
        postProgramTemplate({
          optType: 1,
          moduleId: selectedMenuId?.id,
          isNewTemplate: true,
          isPublish: 0,
          callback: (templateId) => {
            navigate(`/snc/templates/${templateId}/calendar`);
            toast.success("Template created successfully.");
          }
        })
      );
    } else {
      console.log("Post Template Errors");
      dispatch(templatesActions?.setErrors());
    };
  };
  
  return (
    <Fragment>
      <Header 
        headerData={{
          heading: "Program Templates",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate("/snc/templates")
        }}
      />
      <div className="si-main-content">
        <div className="si-secondStep si-align-center">
          <div className="si-form">
            <div className={`form-control ${programNameError ? "si-error" : ""}`}>
              <div className="form-group">
                <label htmlFor="name" className="si-label"><span>*</span>Template Name</label>
                <input 
                  className="si-input"
                  type="text" 
                  placeholder="Add template name" 
                  value={ programName }
                  onChange={ (e) => dispatch(templatesActions?.setProgramName({name: e.target.value})) }
                />
              </div>
              <span className={ programNameError ? "si-error-message" : "" }>{ programNameErrorMsg }</span>
            </div>
            <div className={`form-control ${durationError ? "si-error" : ""}`}>
              <div className="form-group">
                <label htmlFor="duration" className="si-label"><span>*</span>Template Duration</label>
                <div className="form-group-wrap si-duration">
                  <input 
                    className="si-input"
                    type="text" 
                    placeholder="Add duration" 
                    value={ duration }
                    onChange={ (e) => dispatch(templatesActions?.setDuration({duration: e.target.value})) }
                  />
                  <span className="si-label-append">Weeks</span>
                </div>
              </div>
              <span className={ durationError ? "si-error-message" : "" }>{ durationErrorMsg }</span>
            </div>
            <div className={`form-control ${selectedSportError ? "si-error" : ""}`}>
              <div className="form-group">
                <label htmlFor="selectSport" className="si-label"><span>*</span>Select Sport</label>
                <Dropdown 
                  placeholder={"Select Sport"} 
                  options={sportOptions}
                  changeHandler={changeHandler}
                />
              </div>
              <span className={ selectedSportError ? "si-error-message" : "" }>{ selectedSportErrorMsg }</span>
            </div>
            <div className="form-control">
              <button 
                type="button" 
                className="si-button si-button--green si-icon--add"
                onClick={clickHandler}
                style={{marginTop: "5px"}}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <FixedButton btnName="Next"
                        onBtnClick={clickHandler}/> */}
    </Fragment>
  );
};

export default ProgramCreationForm;