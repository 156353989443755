import React from 'react'
import { Route, Routes } from 'react-router'
import AddEventPopup from '../components/RightPannel/Tournament/PerformanceTracker/AddEventPopup'
import EventListing from '../components/RightPannel/Tournament/EventListing/EventListing'
import EventListingAddNew from '../components/RightPannel/Tournament/EventListing/EventListingAddNew'
import AddNewRecord from '../components/RightPannel/Tournament/PerformanceTracker/AddNewRecord'
import PerformanceTrackerListing from '../components/RightPannel/Tournament/PerformanceTracker/PerformanceTrackerListing'
import SelectTournament from '../components/RightPannel/Tournament/PerformanceTracker/SelectTournament'
import TournamentListing from '../components/RightPannel/Tournament/TournamentListing/TournamentListing'
import TournamentListingAddNew from '../components/RightPannel/Tournament/TournamentListing/TournamentListingAddNew'
import { TOURNAMENT_EVENTS_LISTING_ROUTE, TOURNAMENT_LISTING_ROUTE, TOURNAMENT_MODULE_ROUTE, TOURNAMENT_PERFORMANECE_LISTING_ROUTE } from '../utility/constants'
import ProtectedRoute from './ProtectedRoute'

function TournamentRouting() {
  return (
    <Routes>
        <Route exact path={`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_PERFORMANECE_LISTING_ROUTE}`} element={
            <ProtectedRoute Component={PerformanceTrackerListing}/>
        }  />
        <Route exact path={`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_PERFORMANECE_LISTING_ROUTE}/selectTournament`} element={
            <ProtectedRoute Component={SelectTournament}/>
        }  />
        <Route exact path={`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_PERFORMANECE_LISTING_ROUTE}/:performanceId`} element={
            <ProtectedRoute Component={AddNewRecord}/>
        }  />
        <Route exact path={`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_PERFORMANECE_LISTING_ROUTE}/:id/:sportId/addEvent`} element={
            <ProtectedRoute Component={AddEventPopup}/>
        }  />
        <Route exact path={`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_LISTING_ROUTE}`} element={
            <ProtectedRoute Component={TournamentListing}/>
        }  />
        <Route exact path={`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_LISTING_ROUTE}/new`} element={
          <ProtectedRoute Component={TournamentListingAddNew}/>
        }  />
        <Route exact path={`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_LISTING_ROUTE}/new/:id`} element={
          <ProtectedRoute Component={TournamentListingAddNew}/>
        }  />
        <Route exact path={`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_EVENTS_LISTING_ROUTE}`} element={
            <ProtectedRoute Component={EventListing}/>
        }  />
        <Route exact path={`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_EVENTS_LISTING_ROUTE}/new`} element={
            <ProtectedRoute Component={EventListingAddNew}/>
        }  />
        <Route exact path={`/${TOURNAMENT_MODULE_ROUTE}/${TOURNAMENT_EVENTS_LISTING_ROUTE}/new/:id`} element={
            <ProtectedRoute Component={EventListingAddNew}/>
        }  />
    </Routes>
  )
}

export default TournamentRouting