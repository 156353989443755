import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import { authActions } from "../reducer/auth";

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchAllTests = createAsyncThunk(
  "testAssessment/fetchAllTests",
  async ({ callback }, { getState, rejectWithValue, dispatch }) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData?.user_guid ? userData?.user_guid : null;

    try {
      return {
        callback,
        apiResponse: await getAPIDetails(`common/getallrolewisefunctions?userguid=${userguid}`),
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ callback, error });
      } else {
        return rejectWithValue({ callback, error });
      }
    }
  }
);
export const fetchAthleteReportList = createAsyncThunk(
  "testAssessment/fetchAthleteReportList",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { callback } = data;
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData?.user_guid ? userData?.user_guid : null;
    let body = {
      page_no: data?.pageNo,
      item_number: data?.perPage,
      user_guid: data?.userguid,
      function_id: data?.functionId,
    };
    try {
      let functionListing = await getAPIDetails(`common/getallrolewisefunctions?userguid=${userguid}`);
      let reportListing = await postAPIDetails(`common/getathletereportlisting`, body);
      return {
        callback,
        apiResponse: { functionListing, reportListing },
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ callback, error });
      } else {
        return rejectWithValue({ callback, error });
      }
    }
  }
);

export const getFunctionWiseAthleteListing = createAsyncThunk(
  "testAssessment/getFunctionWiseAthleteListing",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { callback } = data;
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData?.user_guid ? userData?.user_guid : null;
    let body = {
      page_no: data?.pageNo,
      item_number: data?.perPage,
      user_guid: userguid,
      function_id: data?.functionId,
      search: data?.searchTerm,
      sport_id: data?.selectedSports?.length ? data?.selectedSports : null,
      group_id: data?.groupFilter?.length ? data?.groupFilter : null,
    };
    try {
      //api call to get list(actual); call api from here
      return {
        callback,
        apiResponse: await postAPIDetails(`common/getfunctionwiseathletelisting`, body),
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ callback, error });
      } else {
        return rejectWithValue({ callback, error });
      }
    }
  }
);
//get new report template
export const getSubmittedReport = createAsyncThunk(
  "testAssessment/getSubmittedReport",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { callback } = data;
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData?.user_guid ? userData?.user_guid : null;
    let body = {
      sport_id: data?.sportId?.[0],
      athlete_guid: data?.athlete_guid,
      user_guid: userguid,
      function_id: data?.functionId,
      report_id: data?.reportId ? data?.reportId : null,
    };
    try {
      return {
        callback,
        apiResponse: await postAPIDetails(`common/getsubmittedreport`, body),
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ callback, error });
      } else {
        return rejectWithValue({ callback, error });
      }
    }
  }
);

export const insertUpdateNewReport = createAsyncThunk(
  "common/insertupdatenewreport",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { callback } = data;
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData?.user_guid ? userData?.user_guid : null;
    let body = data.newReport;
    body.user_guid = userguid;
    try {
      return {
        callback,
        apiResponse: await postAPIDetails(`common/insertupdatenewreport`, body),
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ callback, error });
      } else {
        return rejectWithValue({ callback, error });
      }
    }
  }
);

export const getReportTemplate = createAsyncThunk(
  "common/getreporttemplate",
  async (data, { getState, rejectWithValue, dispatch }) => {
    let { callback } = data;
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData?.user_guid ? userData?.user_guid : null;
    let body = {
      sport_id: data?.sportId?.[0],
      athlete_guid: data?.athlete_guid,
      user_guid: userguid,
      function_id: data?.functionId,
      report_id: data?.reportId == null ? null : data?.reportId ? data?.reportId : 0,
    };
    try {
      return {
        callback,
        apiResponse: await postAPIDetails(`common/getreporttemplate`, body),
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ callback, error });
      } else {
        return rejectWithValue({ callback, error });
      }
    }
  }
);
