import React from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loaderActions } from "../../../../../reducer/loader";
import { fetchSportsList } from "../../../../../utility/templateApiActions";
import { deleteGroupofAthlete, fetchAthleteGroupList } from "../../../../../utility/userManagementApiActions";
import FixedButton from "../../../Common/FixedButton";
import Header from "../../../Common/Header";
import SubHeader from "../../../Common/SubHeader";
import Content from "./Content";

export default function GroupListing() {
  const isAuth = useSelector((state) => state?.auth?.isAuthenticated);
  const [pageNo, setPageNo] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedSports, setSelectedSports] = useState([]);
  const [isFetchFulfilled, setIsFetchFulfilled] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState({ isShow: false });
  const paginationObject = useSelector(
    (state) => state?.userManagement?.paginationObject
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let filterOptions = {
    is_search_filter: true,
    is_sport_filter: true,
  };

  useEffect(() => {
    dispatch(fetchSportsList());
 }, []);

  useEffect(() => {
    if (isAuth) {
      let geoupListTimeOut = setTimeout(() => {
        dispatch(loaderActions?.showLoader());
        fetchGroupListing();
      }, 0);

      if (pageNo !== 1 || selectedSports?.length) setIsFiltered(true);

      return () => clearTimeout(geoupListTimeOut);
    }
  }, [isAuth, pageNo, selectedSports]);

  useEffect(() => {
    if (isAuth) {
      let geoupListTimeOut = setTimeout(() => {
        dispatch(loaderActions?.showLoader());
        fetchGroupListing();
      }, 0);

      if (pageNo !== 1 || selectedSports?.length) setIsFiltered(true);

      return () => clearTimeout(geoupListTimeOut);
    }
  }, [isAuth, searchTerm]);

  const createBtnClickHandler = (id)=> {
    navigate("/athletegroups/new")
};

const deleteGroupHandler = (groupId)=>{
  dispatch(
    deleteGroupofAthlete({
      groupId: groupId,
      callback: () => {
        setTimeout(() => {
          fetchGroupListing();
        });
      },
    })
  );
}

const fetchGroupListing = ()=>{
  dispatch(
    fetchAthleteGroupList({
      pageNo,
      searchTerm,
      selectedSports,
      callback: () =>
        setTimeout(() => {
          dispatch(loaderActions?.hideLoader());
          setIsFetchFulfilled(true);
        }),
    })
  );
}
  return (
    <Fragment>
      <Header
        headerData={{
          heading: "Athlete Groups",
          isHeadingEditable: false,
        }}
      />
      <SubHeader
        filterOptions={filterOptions}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSelectedSportIds={setSelectedSports}
        pageNo={pageNo}
        setPageNo={setPageNo}
        navigate={navigate}
        isBtn={true}
        btnName="Create Group"
        onBtnClick={createBtnClickHandler}
      />
      <Content
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={paginationObject}
        perPage={20}
        searchTerm={searchTerm}
        confirmationPopup={confirmationPopup}
        setConfirmationPopup={setConfirmationPopup}
        deleteGroupHandler={deleteGroupHandler}
      />
      <FixedButton btnName="Create Group"
                        onBtnClick={createBtnClickHandler} iconClass='add'/>
    </Fragment>
  );
}
