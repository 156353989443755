import { createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "../reducer/auth";
import API from "./api";
let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchTournamentSportsList = createAsyncThunk(
  "TournamentPerformance/fetchTournamentSportsList",
  async ({ performance_record_id }, { dispatch, rejectWithValue }) => {
    // dispatch(loaderActions?.showLoader());
    try {
      let sportsList = (
        await getAPIDetails("common/getrecordsportslisting?performanceRecordId=" + performance_record_id)
      ).data.data?.value;
      return {sportsList}
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getperformancerecordlisting = createAsyncThunk(
  "TournamentPerformance/getperformancerecordlisting",
  async ({ sport_id, page_no, item_number, search, athlete_guid }, { rejectWithValue, dispatch }) => {
    let payloadToSend = {
      sport_id: sport_id ? sport_id : null,
      page_no: page_no ? page_no : 1,
      item_number: item_number ? item_number : 20,
      search: search ? search : null,
      athlete_guid: athlete_guid ? athlete_guid : null,
    };
    try {
      return await postAPIDetails("common/getperformancerecordlisting", payloadToSend);
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getEventsPerformanceRecordAthleteListing = createAsyncThunk(
  "TournamentPerformance/getEventsPerformanceRecordAthleteListing",
  async ({ sport_id, page_no, item_number, search, athlete_guid }, { rejectWithValue, dispatch }) => {
    let payloadToSend = {
      sport_id: sport_id ? sport_id : null,
      page_no: page_no ? page_no : 1,
      item_number: item_number ? item_number : 20,
      search: search ? search : null,
      athlete_guid: athlete_guid ? athlete_guid : null,
    };
    try {
      return await postAPIDetails("common/getperformancerecordlistingnew", payloadToSend);
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getEventListsPerformance = createAsyncThunk(
  "TournamentPerformance/getEventLists",
  async (
    { page_no, searchText, sport_id, item_number, user_id, gender, event_type },
    { rejectWithValue, dispatch }
  ) => {
    let payloadToSend = {
      page_no: page_no ? page_no : 1,
      item_number: item_number ? item_number : 20,
      search: searchText ? searchText : null,
      user_id: user_id ? user_id : null,
      sport_id: sport_id ? sport_id : null,
      gender: gender ? gender : null,
      event_type: event_type ? event_type : null,
    };
    try {
      let genderMaster = await getAPIDetails("/common/getgendermaster");
      let response = await postAPIDetails("/common/geteventlisting", payloadToSend);
      return {
        genderMaster,
        response,
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);
export const updatePerformaceRecord = createAsyncThunk(
  "TournamentPerformance/updatePerformaceRecord",
  async ({ tournament_id, performance_record_id, user_guid }, { rejectWithValue, dispatch }) => {
    let payloadToSend = {
      tournament_id: tournament_id ? tournament_id : null,
      performance_record_id: performance_record_id ? performance_record_id : null,
      user_guid: user_guid ? user_guid : null,
    };
    try {
      return await postAPIDetails("common/insertupdateperformancerecord", payloadToSend);
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getTournamentPerformanceEvents = createAsyncThunk(
  "TournamentPerformance/getTournamentPerformanceEvents",
  async ({ performance_record_id, sport_id, user_guid }, { rejectWithValue, dispatch }) => {
    let payloadToSend = {
      performance_record_id: performance_record_id ? performance_record_id : 1,
      sport_id: sport_id ? sport_id : null,
      user_guid: user_guid ? user_guid : null
    };
    try {
      return await postAPIDetails("common/gettournamenteventmap", payloadToSend);
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const addTournamentPerformanceEvents = createAsyncThunk(
  "TournamentPerformance/addTournamentPerformanceEvents",
  async ({ payload, getPayload }, { rejectWithValue, dispatch }) => {
    let payloadToSend = {
      ...payload,
      opt_type: 1,
    };
    try {
      let updatedRes = await postAPIDetails("common/insertupdatetournamentevent", payloadToSend);
      let events;
      if (updatedRes?.data?.data?.value == "1") {
        events = await postAPIDetails("common/gettournamenteventmap", payloadToSend);
      }
      return {
        events,
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const deleteTournamentPerformanceEvents = createAsyncThunk(
  "TournamentPerformance/deleteTournamentPerformanceEvents",
  async ({ payload, id, sport_id, user_guid }, { rejectWithValue, dispatch }) => {
    let payloadToSend = {
      ...payload,
      opt_type: 2,
    };
    try {
      let res = await postAPIDetails("common/insertupdatetournamentevent", payloadToSend);
      let eventDetails;
      let events;
      if (res?.data?.data?.value == "1") {
        // eventDetails = await getAPIDetails("common/gettournamenteventdetails?recordId=" + id);
      }
      let payload = {
        performance_record_id: id,
        sport_id: sport_id ? sport_id : null,
        user_guid: user_guid ? user_guid :null
      };
      if (res?.data?.data?.value == "1") {
        events = await postAPIDetails("common/gettournamenteventmap", payload);
      }
      return {
        // eventDetails: eventDetails?.data?.data?.value,
        events,
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);
export const fetchPerformanceAthletePopup = createAsyncThunk(
  "TournamentPerformance/fetchPerformanceAthletePopup",
  async ({ payload }, { rejectWithValue, dispatch }) => {
    try {
      let groupsList = await getAPIDetails("program/getgrouplisting");
      let athleteDetails = await postAPIDetails("common/getrecordathletelisting", payload);
      console.log("gere", payload, groupsList, athleteDetails);

      return {
        groupList: groupsList?.data?.data?.value,
        athleteDetails: athleteDetails?.data?.data?.value,
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const addPerformanceAthletePopup = createAsyncThunk(
  "TournamentPerformance/addPerformanceAthletePopup",
  async ({ payload, id }, { rejectWithValue, dispatch }) => {
    try {
      //   let groupsList = await getAPIDetails("program/getgrouplisting");
      let athletePayload = {
        page_no: 1,
        item_number: 20,
        performance_record_id: payload?.performance_record_id,
        event_id: payload?.event_id,
        tournament_id: payload?.tournament_id,
        sport_id: payload?.sport_id,
        user_guid: payload?.user_guid,
      };
      let athleteRes = await postAPIDetails("common/insertupdaterecordathletemap", payload);
      // let eventDetails = await getAPIDetails("common/gettournamenteventdetails?recordId=" + id);
      let eventAthleteData = await postAPIDetails("common/geteventathletedata", athletePayload);

      return {
        // eventDetails: eventDetails?.data?.data?.value,
        eventAthleteData: eventAthleteData?.data?.data?.value,
        athleteRes: athleteRes,
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchTournamentEventDetails = createAsyncThunk(
  "TournamentPerformance/fetchTournamentEventDetails",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      let eventDetails = await getAPIDetails("common/gettournamenteventdetails?recordId=" + id);
      let medalMaster = await getAPIDetails("common/getmedalmaster");
      // console.log("gere", eventDetails);

      return {
        eventDetails: eventDetails?.data?.data?.value,
        medalMaster: medalMaster?.data?.data?.value,
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchMedalMaster = createAsyncThunk(
  "TournamentPerformance/fetchMedalMaster",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let medalMaster = await getAPIDetails("common/getmedalmaster");

      return {
        medalMaster: medalMaster?.data?.data?.value,
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const updateAthleteRecordApi = createAsyncThunk(
  "TournamentPerformance/updateAthleteRecord",
  async ({ payload, id, sport_id, event_id, tournament_id }, { rejectWithValue, dispatch }) => {
    try {
      let updateRecord = await postAPIDetails("common/updaterecordathletemap", payload);
      let eventAthleteData;
      if (updateRecord?.data?.data?.value == "1" || updateRecord?.data?.data?.value == "2") {
        let athletePayload = {
          page_no: 1,
          item_number: 20,
          performance_record_id: Number(id),
          event_id: Number(event_id),
          tournament_id: Number(tournament_id),
          sport_id: Number(sport_id),
          user_guid: payload[0]?.user_guid,
        };
        eventAthleteData = await postAPIDetails("common/geteventathletedata", athletePayload);
      }
      return {
        eventAthleteData: eventAthleteData?.data?.data?.value,
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getTournamentDetailsById = createAsyncThunk(
  "TournamentPerformance/getTournamentDetailsById",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      let selectedTournament = await getAPIDetails("common/gettournamentdetails?tournament_id=" + id);
      return {
        selectedTournament: selectedTournament?.data?.data?.value,
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getEventAthleteData = createAsyncThunk(
  "TournamentPerformance/getEventAthleteData",
  async ({ payload }, { rejectWithValue, dispatch }) => {
    try {
      let eventAthleteData = await postAPIDetails("common/geteventathletedata", payload);
      return {
        eventAthleteData: eventAthleteData?.data?.data?.value,
      };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);
