import React, { useEffect, useState } from 'react';

function ExerciseHead({exerciseToAdd,checkExerciseExist, setExerciseToAdd,onCancel,isEditable=false, type,selectedExerciseNameError , setSelectedExerciseNameError }) {
    const [searchExerciseName , setSearchExerciseName] = useState('');

      const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results);
        exerciseNameChangeHandler(string);
        setSearchExerciseName(string);
      }
    
      const handleOnHover = (result) => {
        // the item hovered
        console.log(result)
      }
    
      const handleOnSelect = (item) => {
        // the item selected
        console.log(item);
        exerciseNameChangeHandler(item?.name ? item?.name : '');
      }
    
      const handleOnFocus = () => {
        console.log('Focused')
      }
    
      const formatResult = (item) => {
        return (
          <>
            <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span>
            <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span>
          </>
        )
      }

    const exerciseNameChangeHandler = (e)=>{
        let value = e?.target?.value;
        setExerciseToAdd(prevState => {
            return { ...prevState, 'exercise_name':value }
        });
        setSelectedExerciseNameError({
            is_error : false,
            msg:''
        });
    }
    const exerciseDescChangeHandler = (e)=>{
        let value = e?.target?.value;
        setExerciseToAdd(prevState =>{
            return { ...prevState, 'description':value }
        });
    }
    return (
        <div className="si-popup-head">
            <div className="si-popup__title">
                <div className={`form-control ${selectedExerciseNameError?.is_error ? "si-error" : ""}`}>
                    <input 
                        type="text" 
                        value={exerciseToAdd.exercise_name ? exerciseToAdd.exercise_name : ''}
                        placeholder="Add Exercise Name"
                        className="si-input" 
                        disabled = {!isEditable}
                        onChange={exerciseNameChangeHandler}
                        onBlur={(e)=>checkExerciseExist(e?.target?.value)}
                    />
                    <span className={ selectedExerciseNameError?.is_error ? "si-error-message" : "" }>{ selectedExerciseNameError?.msg }</span>
                </div>
                <button 
                type="button" 
                className="si-icon__button si-button__close"
                onClick={onCancel}>
                <span className="icon-close"></span>
            </button>
                
            </div>
            <div className={`si-popup__description`}>
                <label className="si-label">Description</label>
                <textarea 
                    id="" 
                    name="" 
                    rows={2} 
                    cols="" 
                    className="si-input" 
                    placeholder="Add a short description of exercise"
                    value={exerciseToAdd.description ? exerciseToAdd.description : exerciseToAdd.exercise_desc ? exerciseToAdd.exercise_desc : ''}
                    disabled={!isEditable}
                    onChange={exerciseDescChangeHandler}
                >   
                    { exerciseToAdd.description ? exerciseToAdd.description : exerciseToAdd.exercise_desc ? exerciseToAdd.exercise_desc : '' }                   
                </textarea>
            </div>
        </div>
    );
};

export default ExerciseHead;