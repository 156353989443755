import moment from "moment";
import React, { useEffect } from "react";
import DatePickerComponent from "../Common/DatePickerComponent";
import Dropdown from "../Common/Dropdown";
import UploadFiles from "./UploadFiles";
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";
function InjuryDetailsAthleteSection({
  injuryDetails,
  showError,
  setInjuryDetails,
  bodyParts,
  setShowErrors,
  file,
  setFile,
}) {
  const onChangeHandle = ({ event, key }) => {
    if (key === "bodyParts") {
      setInjuryDetails((old) => {
        return {
          ...old,
          selectedBodyPartName: event?.label,
          selectedBodyPartId: event?.value,
        };
      });
      setShowErrors((old) => ({
        ...old,
        selectedBodyPartId: { show: false },
      }));
    } else if (key === "injuryDate") {
      let selected_date = moment(event).format("YYYY-MM-DD");
      setInjuryDetails((old) => {
        return {
          ...old,
          [key]: selected_date,
        };
      });
      setShowErrors((old) => ({
        ...old,
        [key]: { show: false },
      }));
    } else if( key === "injuryName"){
      if (event?.target?.value && event?.target?.value?.length >= 50)
        return setShowErrors({ injuryName: { show: true, msg: "Injury name should be of 50 characters or less." } });
    
      setInjuryDetails((old) => {
        return {
          ...old,
          [key]: event?.target?.value,
        };
      });
      setShowErrors((old) => ({
        ...old,
        [key]: { show: false },
      }));
    } else {
      setInjuryDetails((old) => {
        return {
          ...old,
          [key]: event?.target?.value,
        };
      });
      setShowErrors((old) => ({
        ...old,
        [key]: { show: false },
      }));
    }
    console.log("event", event, key);
  };

  useEffect(() => {
    console.log("injuryDetails", injuryDetails);
  }, [injuryDetails]);
  return (
    <div className="for-athelets-section">
      <div className="si-title">For Athletes</div>
      <div className="for-athelets-content">
        <div className="for-athelets-section__left">
          <div className="left-outer-wrp">
            <div className="left-inner-wrp">
              <div className={`form-control ${showError?.injuryDate?.show ? "si-error" : ""}`}>
                <div className="form-group">
                  <label for="start-date" className="si-label">
                    <span>*</span>Injured on
                  </label>
                  <div className="form-group-wrap  date-form-control">
                    {/* <input
                      type="date"
                      placeholder=""
                      id="start-date"
                      className="si-input"
                      max={new Date().toISOString().split("T")[0]}
                      defaultValue={injuryDetails?.injuryDate ? injuryDetails?.injuryDate.split("T")[0] : ""}
                      onChange={(event) => onChangeHandle({ event, key: "injuryDate" })}
                    /> */}
                    <DatePickerComponent
                      isEditMode={false}
                      selectedDate={injuryDetails?.injuryDate ? injuryDetails?.injuryDate.split("T")[0] : ""}
                      dateChangeHandler={(event) => {
                        onChangeHandle({ event, key: "injuryDate" });
                      }}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <span className="si-error-message">
                  {showError?.injuryDate?.show ? showError?.injuryDate?.msg : ""}
                </span>
              </div>
              <div className={`form-control ${showError?.injuryName?.show ? "si-error" : ""}`}>
                <div className="form-group">
                  <label for="injury" className="si-label ">
                    <span>*</span>Injury
                  </label>
                  <input
                    className="si-input injury-input"
                    type="text"
                    placeholder="Injury Name"
                    value={injuryDetails?.injuryName ? injuryDetails?.injuryName : ""}
                    onChange={(event) => onChangeHandle({ event, key: "injuryName" })}
                    defaultValue={injuryDetails?.injuryName ? injuryDetails?.injuryName : ""}
                  />
                </div>
                <span className="si-error-message">
                  {showError?.injuryName?.show ? showError?.injuryName?.msg : ""}
                </span>
              </div>
            </div>
            <div
              className={`form-control ${
                showError?.selectedBodyPartId?.show ? "si-error" : ""
              } selectSport-form-control`}
            >
              <div className="form-group">
                <label for="selectSport" className="si-label">
                <span>*</span>Body Part
                </label>
                <Dropdown
                  value={{
                    value: injuryDetails?.selectedBodyPartId ? injuryDetails?.selectedBodyPartId : "",
                    label: injuryDetails?.selectedBodyPartName ? injuryDetails?.selectedBodyPartName : "Select Type",
                  }}
                  options={bodyParts?.map((obj) => {
                    return {
                      value: obj?.body_part_id,
                      label: obj?.body_part_name,
                    };
                  })}
                  changeHandler={(data) => {
                    onChangeHandle({ event: { value: data.value, label: data.label }, key: "bodyParts" });
                  }}
                />
              </div>
              <span className="si-error-message">
                {showError?.selectedBodyPartId?.show ? showError?.selectedBodyPartId?.msg : ""}
              </span>
            </div>
          </div>
          <div className="injury-description">
            <div className={`form-control ${showError?.injuryDescription?.show ? "si-error" : ""}`}>
              <label for="" className="si-label">
                <span>*</span>Description
              </label>
              <textarea
                id=""
                name=""
                rows="3"
                cols=""
                className="si-input"
                placeholder="Add a short description of exercise"
                defaultValue={injuryDetails?.injuryDescription ? injuryDetails?.injuryDescription : ""}
                onChange={(event) => onChangeHandle({ event, key: "injuryDescription" })}
              ></textarea>
              <span className="si-error-message">
                {showError?.injuryDescription?.show ? showError?.injuryDescription?.msg : ""}
              </span>
            </div>
          </div>
        </div>

        <div className="for-athelets-section__right">
          <label for="" className="si-label">
            Upload Files
          </label>
          <UploadFiles file={file} setFile={setFile} />
          {/* <div className="drag-drop-container drag-drop">
            <div className="drag-drop__icon">
              <i className="fas fa-cloud-upload-alt"></i>
            </div>
            <div className="drag-drop__text-wrp">
              <p className="title-text">
                Drag and Drop or <input type="file" className="drag-drop__input" /> to upload
              </p>
            </div>
                </div> */}
          {/*  <div className="uploaded-file-wrp">
            <div className="uploaded-file">
              <span className="icon-pdf">
                <img src="../../images/pdf-img.svg" alt="" />
              </span>
              <span className="file-name">nutrition report 100523.pdf</span>
              <span className="icon-delete-2"></span>
            </div>
            <div className="uploaded-file">
              <span className="icon-pdf">
                <img src="../../images/pdf-img.svg" alt="" />
              </span>
              <span className="file-name">nutrition report 100523.pdf</span>
              <span className="icon-delete-2"></span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default InjuryDetailsAthleteSection;
