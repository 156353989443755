import React , { useEffect } from 'react'
import TestAssessment from '../Athletes/AthleteProfile/TestAssessment';
import Header from '../Common/Header';
import { useSelector, useDispatch } from 'react-redux';
import { loaderActions } from '../../../reducer/loader'
import { fetchAllTests } from '../../../utility/testAssessmentApiActions';
import { useNavigate } from 'react-router-dom';
import FunctionListing from '../Common/FunctionListing';
import { ATHELETE_ROLE_ID, ATHELET_BTN_MSG, COACH_BTN_MSG } from '../../../utility/constants';
import { testAssessmentActions } from '../../../reducer/testAssessment';


const FunctionListingData = () => {
    const testsList = useSelector((state) => state?.testAssessment?.testsList);
    const userData = useSelector(state => state?.auth?.userData);
    let btnText =  userData?.role_id == ATHELETE_ROLE_ID  ? ATHELET_BTN_MSG : COACH_BTN_MSG;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTestsTimeOut = setTimeout(() => {
            dispatch(loaderActions?.showLoader());
            dispatch(fetchAllTests({
                callback : () => setTimeout(() => {
                    dispatch(loaderActions?.hideLoader());
                })
            }));
        });

        return () => clearTimeout(fetchTestsTimeOut);
    }, []);

    useEffect(()=>{
        localStorage.removeItem("guid");
    },[]);

       const handleClick = (e, id) => {
        e?.preventDefault();
        dispatch(testAssessmentActions.setAthleteFunction({ id }));
        let backUrl = '/testfunctions';
        if(userData?.role_id == ATHELETE_ROLE_ID){
            navigate(`/testfunctions/${id}/athletereports`,{state:{backUrl:backUrl}});
        }else{
            navigate(`/testfunctions/${id}/athletelisting`,{state:{backUrl:backUrl}});
        }
       };
       
  return (
    <div>
           <Header 
            headerData={{
                    heading: "Tests & Assessments",
                    isHeadingEditable: false,
            }}/>
     
            <FunctionListing 
                testsList={testsList} 
                handleClick={handleClick} 
                btnText={btnText}
            />
             
    </div>
  )
}

export default FunctionListingData
