import axios from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { clearInjuryReportLogs, resetInjuryDetailsUpdate } from "../../../reducer/injury.reducer";
import { ATHELETE_ROLE_ID, INJURY_MODULE_ROUTE, PHYSIOTHERAPIST_ROLE_ID } from "../../../utility/constants";
import {
  deleteInjuryLogMsg,
  fetchInjruyMasters,
  getInjruyReportLogs,
  sendInjuryLogMsg,
} from "../../../utility/injuryApiActions";
import useIntersectionObserver from "../../CustomHook/InterSectionObserverHook.js";
import ConfirmationPopup from "../Common/ConfirmationPopup";
import Header from "../Common/Header";
import TextBoxPopUp from "../Common/TextBoxPopUp";
import InjuryReportLogCommentUi from "./InjuryReportLogCommentUi";
import InjuryReportLogTextArea from "./InjuryReportLogTextArea";
import scrollIntoView from "scroll-into-view-if-needed";
import InjuryRecoveredTextBoxPopup from "../Common/InjuryRecoveredTextBoxPopup";

function InjuryReportLogDetails() {
  const navigate = useNavigate();
  const { injuryId } = useParams();
  const dispatch = useDispatch();
  const selectedInjuryDetails = useSelector((state) => state?.injury?.selectedInjuryDetails);
  const InjuryReportLogsLoading = useSelector((state) => state?.injury?.injuryReportLogs?.loading);
  const InjuryReportLogs = useSelector((state) => state?.injury?.injuryReportLogs?.logsObj);
  const InjuryReportLogsPagination = useSelector((state) => state?.injury?.injuryReportLogs?.pagination);
  const injuryDetailsUpdated = useSelector((state) => state?.injury?.injuryDetailsUpdated);
  const loading = useSelector((state) => state?.injury?.loading);
  const userData = useSelector((state) => state?.auth?.userData);
  const ICON_SVG = {
    pdf: "/images/icons/pdf2-icon.svg",
    jpeg: "/images/icons/jpeg-icon.svg",
    txt: "/images/icons/txt-icon.svg",
    docx: "/images/icons/docx-icon.svg",
    png: "/images/icons/png-icon.svg",
    jpg: "/images/icons/jpg-icon.svg",
    others: "/images/icons/pdf2-icon.svg",
  };
  let [activetabs, setActiveTabs] = useState({ details: false, report: false });
  // let [inputTextMsg, setInputTextMsg] = useState("");
  let [pageNo, setPageNo] = useState(1);
  let [showRecovered, setShowRecovered] = useState(false);
  let [showDelete, setShowDelete] = useState({ show: false, data: null });
  let [showOptions, setShowOptions] = useState(false);
  let chatDivRef = useRef(null);
  let msgDivRef = useRef(null);
  let mobileClickArrow = useRef(null);
  const onePageMsgCount = 15;

  const callAPI = () => {
    if (InjuryReportLogsPagination?.total > pageNo * onePageMsgCount && !InjuryReportLogsLoading) {
      if (injuryId) {
        let payload = {
          page_no: pageNo + 1,
          item_number: onePageMsgCount,
          injury_id: injuryId,
          user_guid: userData?.user_guid
        };
        dispatch(getInjruyReportLogs({ payload }));
      }
    }
  };

  useEffect(() => {
    if (InjuryReportLogsPagination?.current_page) {
      setPageNo(InjuryReportLogsPagination?.current_page);
    }
  }, [InjuryReportLogsPagination]);

  // intersectionObserverHook
  let [setObserver, isFirstChildVisible, isLastChildVisible] = useIntersectionObserver(
    msgDivRef,
    InjuryReportLogs,
    onePageMsgCount,
    InjuryReportLogsPagination,
    callAPI
  );

  const apiBaseUrl = process.env.REACT_APP_BASE_PATH;

  const DownloadFile = (name) => {
    let url = `${apiBaseUrl}common/getfile`;
    axios
      .post(url,{
        filename: name,
      }, {
        responseType: "blob",
      })
      .then((res) => {
        const urlObject = window.URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = urlObject;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("File downloaded successfully.");
      })
      .catch((error) => {
        console.log("err0", error);
        toast.error("Unable to download the file. Please try again.");
      });
  };

  useEffect(() => {
    if (injuryId && userData?.user_guid) {
      dispatch(fetchInjruyMasters({ injuryId, user_guid: userData?.user_guid }));
    }
    return () => dispatch(clearInjuryReportLogs());
  }, [dispatch, injuryId, userData]);

  useEffect(() => {
    if (injuryId && userData?.user_guid) {
      let payload = {
        page_no: pageNo,
        item_number: onePageMsgCount,
        injury_id: injuryId,
        user_guid:userData?.user_guid
      };
      dispatch(getInjruyReportLogs({ payload }));
    }
  }, [dispatch, injuryId, userData]);

  useEffect(() => {
    if (injuryDetailsUpdated) {
      dispatch(resetInjuryDetailsUpdate());
      setShowRecovered(false);
    }
  }, [dispatch, injuryDetailsUpdated]);

  useEffect(() => {
    // scroll to bottom when report log loaded
    if (msgDivRef.current && InjuryReportLogsPagination?.current_page === 1) {
      scrollIntoView(msgDivRef.current, { scrollMode: "if-needed", behavior: "smooth", block: "end" });
      // chatDivRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      setTimeout(() => {
        setObserver(true);
      }, 500);
    }
  }, [InjuryReportLogs, InjuryReportLogsPagination, setObserver]);

  const goToLastMsg = (type) => {
    if (msgDivRef.current) {
      scrollIntoView(msgDivRef.current, {
        scrollMode: "always",
        behavior: type === "auto" ? "auto" : "smooth",
        block: "end",
      });
      // chatDivRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    // For go to last chat when user change tab in mobile device
    if (mobileClickArrow?.current) {
      try {
        let isMobile = getComputedStyle(mobileClickArrow.current).display === "block" ? true : false;
        if (isMobile) return goToLastMsg("auto");
      } catch {
        return goToLastMsg("auto");
      }
    }
  }, [activetabs]);

  const getFileTypeIcon = ({ fileName }) => {
    let fileType = fileName.split(".")[fileName.split(".").length - 1];
    if (ICON_SVG[fileType]) {
      return ICON_SVG[fileType];
    } else {
      return ICON_SVG.others;
    }
  };

  const renderLogs = ({ InjuryReportLogs }) => {
    if (!InjuryReportLogs || !Object.keys(InjuryReportLogs)?.length) {
      return (
        <p className="no-comment-center">
          {Number(userData?.role_id) === PHYSIOTHERAPIST_ROLE_ID || Number(userData?.role_id) === ATHELETE_ROLE_ID
            ? "All of your comments will be recorded here"
            : "Comments will be displayed here"}
        </p>
      );
    }
    let injuryLogIds = Object.keys(InjuryReportLogs);
    return injuryLogIds.map((injuryLogID, index) => {
      let item = InjuryReportLogs[injuryLogID];
      let preDate = null;
      if (InjuryReportLogs[injuryLogIds[index - 1]]) {
        let dateObj = InjuryReportLogs[injuryLogIds[index - 1]].timestamp
          ? new Date(InjuryReportLogs[injuryLogIds[index - 1]].timestamp)
              .toISOString()
              .split("T")[0]
              .replaceAll("-", "")
          : new Date(Number(injuryLogIds[index - 1])).toISOString().split("T")[0].replaceAll("-", "");
        preDate = dateObj;
      }
      let dateObj = item.timestamp ? new Date(item.timestamp) : new Date(Number(injuryLogID));
      let newDate = new Date(dateObj).toISOString().split("T")[0].replaceAll("-", "");
      let getDate = dateObj ? moment(dateObj).format("Do") : "";
      let getMonth = dateObj.toLocaleString("default", { month: "long" });
      let dayFormat = dateObj.toLocaleDateString("en-US", { weekday: "long" });
      let dateLableString = `${dayFormat}, ${getMonth} ${getDate}`;
      let timeFormat = dateObj.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
      let isLastRecovered = item.is_recovered ? item.is_recovered : false;
      let authUserName = "";
      if (userData.first_name) authUserName = userData.first_name;
      if (userData.middle_name) authUserName = authUserName + " " + userData.middle_name;
      if (userData.last_name) authUserName = authUserName + " " + userData.last_name;
      return (
        <InjuryReportLogCommentUi
          key={item.injury_log_id ? item.injury_log_id : injuryLogID}
          date={preDate === newDate && !isLastRecovered ? null : dateLableString}
          isRecovered={isLastRecovered}
          userName={item.sender_name}
          time={item.timestamp ? timeFormat : "sending"}
          textMsg={item.message}
          data={item}
          index={index}
          onePageMsgCount={onePageMsgCount}
          setShowOptions={setShowOptions}
          showOptions={showOptions}
          isDeleting={item.isDeleting}
          showConfirmDeletePopup={setShowDelete}
          authUserName={authUserName}
          authUserGuid={userData?.user_guid}
        />
      );
    });
  };

  const handleSubmit = (inputTextMsg, is_recovered) => {
    if (!inputTextMsg || !inputTextMsg.trim() || !inputTextMsg.trim().length) return null;
    let sender_name = [userData.first_name, userData.middle_name, userData.last_name]
      .filter((name) => (name ? true : false))
      .join(" ");
    let jsTimeStamp = new Date().getTime();
    let payload = {
      [jsTimeStamp]: {
        injury_id: injuryId,
        message: inputTextMsg,
        attachment: null,
        user_guid: userData.user_guid,
        injury_log_id: null,
        sender_name: sender_name,
        sender_guid: userData?.user_guid,
        is_recovered: is_recovered ? is_recovered : null,
      },
    };
    goToLastMsg();
    dispatch(sendInjuryLogMsg({ payload, jsTimeStamp, is_recovered, injuryId, user_guid: userData?.user_guid }));
  };

  const handleLogDelete = () => {
    let payload = {
      ...showDelete.data,
      message: null,
      attachment: null,
      user_guid: userData?.user_guid,
    };
    dispatch(deleteInjuryLogMsg({ payload }));
    setShowDelete({ show: false, data: null });
  };
  const loadMoreTag = ({ text, showBtn, btnCallback }) => {
    return (
      <button className="load-btn" onClick={showBtn ? btnCallback : null}>
        <span>{text}</span>
        {showBtn ? <span className="icon-chev-down"></span> : null}
      </button>
    );
  };

  return (
    <>
      {/* Inline css to show loader top of popup */}
      {loading && <div className="spin" style={{ zIndex: "1051" }}></div>}
      {showRecovered ? (
        <InjuryRecoveredTextBoxPopup
          isActive={showRecovered}
          isEditable={true}
          // isInjruyPopup={true}
          subtitle={"Add notes for coach to view"}
          title={"Are you sure you want to mark this athlete as recovered from the injury?"}
          noteDescription={""}
          isActionBtns={true}
          actionBtnText={"Ok"}
          showCloseIcon={false}
          actionBtnHandler={(text) => handleSubmit(text, 1)}
          closeHandler={() => setShowRecovered(false)}
        />
      ) : null}
      {showDelete.show ? (
        <ConfirmationPopup
          isActive={showDelete?.show}
          isActionBtns={true}
          warning={"Are you sure you want to delete this log?"}
          okHandler={handleLogDelete}
          cancelHandler={() => setShowDelete({ show: false, data: null })}
        />
      ) : null}
      <Header
        headerData={{
          heading: "Injury Details",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate(-1, { replace: true }),
        }}
      />
      <div className="si-main-content details-reportlog-main">
        <div className="details-reportlog-main__left">
          <div className="atheletes-details">
            <div
              className={`si-subheader ${activetabs.details ? "details-active" : ""}`}
              onClick={() => setActiveTabs((old) => ({ report: false, details: !old.details }))}
            >
              <span className="si-subheader__text">Athlete Details</span>
              <span className="icon-chev-right"></span>
            </div>
            <div className="atheletes-details-content">
              <div className="injury-athelet-profile">
                <div className="injury-athelet-profile-img">
                  <img src={`${process.env.PUBLIC_URL}/images/person.png`} alt="athlete profile pic" />
                </div>
                <h3 className="injury-athelet-profile-name">
                  {selectedInjuryDetails ? selectedInjuryDetails[0]?.athlete_name : "-"}
                </h3>
              </div>
              <div className="shoulder-dislocation">
                <div className="si-subheader">
                  <span>{selectedInjuryDetails ? selectedInjuryDetails[0]?.injury_name : "-"}</span>
                  {(Number(userData?.role_id) === PHYSIOTHERAPIST_ROLE_ID ||
                    Number(userData?.role_id) === ATHELETE_ROLE_ID) &&
                  selectedInjuryDetails &&
                  !selectedInjuryDetails[0]?.is_recovered ? (
                    <span
                      className="icon-edit"
                      onClick={() => navigate(`/${INJURY_MODULE_ROUTE}/edit/${injuryId}`)}
                    ></span>
                  ) : null}
                </div>
                <div className="shoulder-dislocation__content">
                  <div className="content-row">
                    <span className="si-title">Injured on: </span>
                    <span className="text">
                      {selectedInjuryDetails
                        ? selectedInjuryDetails[0]?.injury_date?.split("T")[0]?.split("-").reverse().join("/")
                        : "-"}
                    </span>
                  </div>
                  <div className="content-row">
                    <span className="si-title"> Body Part: </span>
                    <span className="text">
                      {" "}
                      {selectedInjuryDetails ? selectedInjuryDetails[0]?.body_part_name : "-"}
                    </span>
                  </div>
                  <div className="content-row">
                    <span className="si-title">Description: </span>
                    <span className="text">{selectedInjuryDetails ? selectedInjuryDetails[0]?.injury_desc : "-"}</span>
                  </div>
                  <div className="content-row attachment-row">
                    <span className="si-title">
                      Attachment:
                      {selectedInjuryDetails
                        ? selectedInjuryDetails[0]?.file_name?.length
                          ? selectedInjuryDetails[0]?.file_name?.map((fileName) => {
                              return (
                                <div className="uploaded-file" key={fileName}>
                                  <span className="pdf-img-wrp">
                                    <img
                                      src={process.env.PUBLIC_URL + getFileTypeIcon({ fileName: fileName })}
                                      alt="Uploaded File"
                                    />
                                  </span>
                                  <span className="file-name">{fileName}</span>
                                  <span className="icon-download" onClick={() => DownloadFile(fileName)}></span>
                                </div>
                              );
                            })
                          : null
                        : "-"}
                    </span>
                  </div>
                  <div className="content-row">
                    <span className="si-title">ICD Injury Code: </span>
                    <span className="text">
                      {" "}
                      {selectedInjuryDetails ? selectedInjuryDetails[0]?.icd_injury_code : "-"}
                    </span>
                  </div>
                  <div className="content-row">
                    <span className="si-title">Injury Type: </span>
                    <span className="text">
                      {selectedInjuryDetails ? selectedInjuryDetails[0]?.injury_type_name : "-"}
                    </span>
                  </div>
                  <div className="content-row">
                    <span className="si-title">Cause of Injury: </span>
                    <span className="text">{selectedInjuryDetails ? selectedInjuryDetails[0]?.injury_cause : "-"}</span>
                  </div>
                </div>
              </div>
              {Number(userData?.role_id) === PHYSIOTHERAPIST_ROLE_ID ? (
                <div className="atheletes-details-content__footer">
                  <button
                    type="button"
                    className="si-button si-add add-new-report"
                    disabled={selectedInjuryDetails ? selectedInjuryDetails[0]?.is_recovered : true}
                    // onClick={() => markRecovered()}
                    onClick={() => setShowRecovered(true)}
                  >
                    {selectedInjuryDetails
                      ? selectedInjuryDetails[0]?.is_recovered
                        ? "Recovered"
                        : "Mark Recovered"
                      : "Mark Recovered"}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="details-reportlog-main__right report-log">
          <div className="report-details">
            <div
              className={`si-subheader ${activetabs.report ? "report-active" : ""}`}
              onClick={() => setActiveTabs((old) => ({ details: false, report: !old.report }))}
            >
              <span className="si-subheader__text">Report Log</span>
              <span className="icon-chev-right" ref={mobileClickArrow}></span>
            </div>
            <div className="reportlog-content-wrp">
              <div className="reportlog-content" ref={chatDivRef}>
                <div className="comment-container-wrp" ref={msgDivRef}>
                  <div className="load-btn-wrp">
                    {isLastChildVisible
                      ? null
                      : InjuryReportLogsLoading
                      ? loadMoreTag({ text: "Loading...", showBtn: false })
                      : isFirstChildVisible
                      ? loadMoreTag({ text: "No more logs", showBtn: true, btnCallback: goToLastMsg })
                      : loadMoreTag({ text: "Go to last log", showBtn: true, btnCallback: goToLastMsg })}
                  </div>
                  {renderLogs({ InjuryReportLogs: InjuryReportLogs })}
                </div>
              </div>
              {Number(userData?.role_id) === PHYSIOTHERAPIST_ROLE_ID ||
              Number(userData?.role_id) === ATHELETE_ROLE_ID ? (
                <InjuryReportLogTextArea handleSubmit={handleSubmit} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InjuryReportLogDetails;
