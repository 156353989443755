import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from '../utility/api';
 
const sideMenuInitialState = {
    selectedMenuId: "",
    menuList: [],
    userAccessData: null,
    redirectToHome:false
};

export const fetchSideMenu = createAsyncThunk("sideMenu/fetchSideMenu", async (data, {getState}) => {
    let state = getState();
    let guid = state?.auth?.userData?.user_guid
    return await new API()?.getAPIDetails(`common/getleftsidepanel?userguid=${guid}`);
});

const sideMenuSlice = createSlice({
    name: 'sideMenu',
    initialState : sideMenuInitialState,
    reducers :{
        setSelectedMenu: (state, action) => {
            state.selectedMenuId = { ...action.payload };
            if(action?.payload?.callback) action?.payload?.callback();
        },

        setUserAccessData: (state, action) => {
            state.userAccessData = action?.payload?.data;
        },

        setHomeRedirect : (state,action)=>{
            state.redirectToHome = action?.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(fetchSideMenu.fulfilled, (state, action) => {
            state.menuList = action?.payload?.data?.data?.value?.menu;
        });

        builder.addCase(fetchSideMenu.rejected, (state, action) => {
            state.menuList = [];
            alert(`Error while fetching sidemenu: \n${action?.error?.message}`);
            console.log("fetchSideMenu error: ",action?.payload?.error);
        });

        builder.addCase("auth/logout", (state) => {
            state.selectedMenuId = "";
            state.menuList = [];
            state.userAccessData = null;
        });
    }
});

export const sideMenuActions = sideMenuSlice.actions;
export default sideMenuSlice.reducer; 
