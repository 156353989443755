import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListingTable from "../../Common/ListingTableMUI";
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from "../../Common/ConfirmationPopup";
import { useDispatch } from "react-redux";
import { deleteTournament } from "../../../../utility/tournamentsApiActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

function TournamentListingContent({ pagination, tournamentListing, setPageNo, pageNo, setReload }) {
  const [isActionActive, setIsActionActive] = useState(Array(tournamentListing?.length)?.fill(false));
  const [warningPopup, setWarningPopup] = useState({ isShow: false });
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.auth?.userData);
  const navigate = useNavigate();

  useEffect(() => {
    window?.addEventListener("click", handleKeydownEvent);
    return () => window?.removeEventListener("click", handleKeydownEvent);
  }, []);

  const tableHeaders = [
    {
      label: "Tournament Name",
      align: "left",
    },
    {
      label: "Sports",
      align: "left",
    },

    {
      label: "Start Date",
      align: "left",
    },

    {
      label: "Location",
      align: "left",
    },
    {
      label: "",
      align: "center",
      stickyClass: "stickyCell-right",
    },
  ];
  const formatDate = (date) => {
    let formatedDate = date?.split("T")[0]?.split("-").reverse().join("/");
    return formatedDate;
  };
  const handleKeydownEvent = (event) => {
    setIsActionActive(Array(tournamentListing?.length)?.fill(false));
  };
  const handleAction = (e, idx) => {
    e?.stopPropagation();
    setIsActionActive((prevState) => {
      let newState = Array(tournamentListing?.length)?.fill(false);
      newState[idx] = !prevState[idx];
      return [...newState];
    });
  };

  const handleDelete = () => {
    let payload = {
      tournament_id: warningPopup?.tournamentId,
      user_guid: userData?.user_guid,
    };
    console.log("warr", warningPopup, payload);
    dispatch(deleteTournament({ ...payload }));
    setWarningPopup({ isShow: false });
    setReload(true);
  };
  const handleDeleteClick = (e, id) => {
    e?.stopPropagation();
    setWarningPopup({ isShow: true, tournamentId: id });
  };

  const handlePopupCancel = () => {
    setWarningPopup({ isShow: false });
    setIsActionActive(Array(tournamentListing?.length)?.fill(false));
  };

  const handleEdit = (e, id) => {
    e?.stopPropagation();
    navigate(`new/${id}`);
  };
  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {tournamentListing?.length ? (
          tournamentListing?.map((tournament, idx) => {
            return (
              <TableRow key={uuidv4()}>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                  onClick={(e) => handleEdit(e, tournament?.tournament_id)}
                  >
                  {tournament?.tournament_name}
                </TableCell>
                <TableCell align={"left"}>
                  {tournament?.sport_name
                    ? tournament?.sport_name?.length > 2
                      ? (<>
                        {`${tournament?.sport_name[0]}, ${tournament?.sport_name[1]}`}
                      <span className="si-custom-table si-count"> +{tournament?.sport_name?.length - 2}</span>
                      </>)
                      : tournament?.sport_name
                          ?.map((name, index) => (index + 1 === tournament.sport_name.length ? name : name + ","))
                          .join(" ")
                    : "-"}
                </TableCell>

                <TableCell align={"left"}>
                  {tournament?.start_date ? formatDate(tournament?.start_date) : "-"}
                </TableCell>
                <TableCell align={"left"}>{tournament?.location ? tournament?.location : "-"}</TableCell>

                <TableCell align={"center"} className="stickyCell-right">
                  <div className="si-custom-table__cell si-wrap" style={{ margin: "auto" }}>
                    <button
                      type="button"
                      className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                      onClick={(e) => handleAction(e, idx)}
                    ></button>
                    <div className="si-action-icons">
                      <button
                        type="button"
                        className="si-icon-button"
                        onClick={(e) => handleEdit(e, tournament?.tournament_id)}
                      >
                        <span className="icon-edit"></span>edit
                      </button>
                      {/* <button 
                                              type="button" 
                                              className="si-icon-button"
                                              onClick={(e) => handleDeleteClick(e, tournament?.tournament_id)}>
                                              <span className="icon-delete"></span>delete
                                          </button> */}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  useEffect(() => {
    getTableBody();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournamentListing]);
  return (
    <>
      <ListingTable
        tableHeaders={tableHeaders}
        getTableBody={getTableBody}
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={pagination}
        perPage={20}
      />
      <ConfirmationPopup
        isActive={warningPopup?.isShow}
        isActionBtns={true}
        warning={"Are you sure you want to delete this tournament?"}
        okHandler={handleDelete}
        cancelHandler={handlePopupCancel}
      />
    </>
  );
}

export default TournamentListingContent;
