import React, { useState } from "react";
import axios from "axios";
import { Fragment } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";


function UploadMediaPopup({ isShow, closePopupHandler, file, setFile }) {
  const [fileUploading, setFileUploading] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_BASE_PATH;
  const progressRef = React.useRef();

  const ICON_SVG = {
    "pdf":"/images/icons/pdf2-icon.svg",
    "jpeg":"/images/icons/jpg-icon.svg",
    "txt":"/images/icons/txt-icon.svg",
    "docx":"/images/icons/docx-icon.svg",
    "png":"/images/icons/png-icon.svg",
    "jpg":"/images/icons/jpg-icon.svg",
    "others":"/images/icons/pdf2-icon.svg"
  }
  const ProgressHandler = (e) => {
    let percent = (e.loaded / e.total) * 100;
    progressRef.current.value = Math.round(percent);
  };

  const Deletefile = (name) => {
    axios.post(`${apiBaseUrl}common/deletefile`, {filename:name}).then((res) => {
      setFile((prevstate) => (prevstate ? prevstate.filter((file) => {console.log('prevstate', prevstate); return file.name ? file.name !== name : file ? file !== name : false}) : []));
      toast.success("File deleted successfully.");
    }).catch((error) => {
      toast.error("Unable to delete the file. Please try again.")
    }
    )
  };

  const handleUpload = async (fileS) => {
    const formData = new FormData();
    Array.from(fileS).forEach((item) => {
      formData.append("file", item);
    });
    const url = apiBaseUrl + "common/uploadfiles";
    let config = {
      onUploadProgress: (e) => {
        ProgressHandler(e);
      },
      headers: { "Content-Type": "multipart/form-data" },
    };
    axios
      .post(url, formData, config)
      .then((res) => {
        setFile((prevstate) => {
          return prevstate
            ? [...prevstate, { name: res.data.data.filenames[0], file: fileS[0] }]
            : [{ name: res.data.data.filenames[0], file: fileS[0] }];
        });
        setFileUploading((old) => (old?.length ? old.filter((oldFiles) => oldFiles.name !== fileS[0].name) : []));
        toast.success("File uploaded successfully.");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "Error");
        setLoading(false);
        setFileUploading((old) => (old?.length ? old.filter((oldFiles) => oldFiles.name !== fileS[0].name) : []));
        toast.error("Unable to upload the file. Please try again.");
      });
  };

  const handleInputChange = (e) => {
    setFileUploading((oldList) => [...oldList, e]);
    setLoading(true);
  };

  const getFileTypeIcon = ({fileName})=>{
    let fileType = fileName?.split(".")[fileName?.split(".").length-1]
    if(ICON_SVG[fileType]){
      return ICON_SVG[fileType]
    } else {
      return ICON_SVG.others
    }
  }


console.log('fileUploading', fileUploading, file)
  return (
    <Fragment>
      <div className={`si-popup ${isShow ? "active" : ""}`}>
        <div className="upload-popup">
          <div className="upload-popup__header">
            <div className="header-top">
              <h3 className="header-top__title">Upload Files</h3>
              <button type="button" className="si-icon__button si-button__close" onClick={closePopupHandler}>
                <span className="icon-close" />
              </button>
            </div>
            <p className="header-bottom">Upload your files/images/documents</p>
          </div>
          <div className={`upload-popup__body ${file?.length > 2 ? "attachment-disabled":""}`}>
            <FileUploader
              multiple={false}
              handleChange={(e) => {
                handleInputChange(e);
                handleUpload([e]);
              }}
              children={
                <div>
                  <div className="drag-drop-container drag-drop">
                    <div className="drag-drop__icon">
                      <i className="fas fa-cloud-upload-alt" />
                    </div>
                    <div className="drag-drop__text-wrp">
                      <p className="title-text">Drag and Drop or
                        <input type="file" className="drag-drop__input" />
                        to upload</p>
                      <p className="info-text">  *Only 3 attachments allowed (jpg, jpeg, png, pdf, txt, docx) - Limit 10 MB</p>
                    </div>
                  </div>
                </div>}

              name="upload_files"
              types={["JPEG", "PNG", "JPG", "PDF", "TXT", "DOCX"]}
              maxSize={10}
              disabled={loading ? true : file?.length > 2 ? true : false}
              onSizeError={() => toast.error("File size is greater than 10MB.")}
            />

            <div className="uploading-state">
              {fileUploading?.length ? (
                fileUploading.map((item, index, filename) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="file-card">
                        <div className="file-img-wrp">
                          <img
                            src={
                              process.env.PUBLIC_URL + getFileTypeIcon({fileName:item.name})
                              // item
                              //   ? item.type == "application/pdf"
                              //     ? process.env.PUBLIC_URL + "/images/Pdf_img.png"
                              //     : URL.createObjectURL(item)
                              //   : null
                            }
                            alt="Uploadimage"
                          />
                        </div>

                        <div className="file-name-wrp">
                          <span className="img-name">
                            <p>{item.name}</p>
                          </span>
                        </div>
                        <div className="progress-wrp">
                          <progress id="file" ref={progressRef} value="0" max="100" data-label="32%">
                            {" "}
                            0%{" "}
                          </progress>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })
              ) : (
                <></>
              )}
              {file?.length ? (
                file?.map((item, index) => {
                  let fileName = item.name ? item.name : item 
                  return (
                    <React.Fragment key={index}>
                      <div className="file-card">
                        <div className="file-img-wrp">
                          <img
                            src={
                              process.env.PUBLIC_URL + getFileTypeIcon({fileName:fileName})
                              // item && item.file
                              //   ? item.file.type == "application/pdf"
                              //     ? process.env.PUBLIC_URL + "/images/Pdf_img.png"
                              //     : URL.createObjectURL(item.file)
                              //   : null
                            }
                            alt="Uploadimage"
                          />
                        </div>

                        <div className="file-name-wrp">
                          <span className="img-name">
                            <p>{fileName}</p>
                          </span>
                          <span className="del-icon" >
                            <img
                              alt="pdf preview"
                              src={process.env.PUBLIC_URL + "/images/icons/delete-icon.svg"}
                              onClick={() => Deletefile(fileName)}
                            />
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UploadMediaPopup;
