import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import MultiSelectDropdown from "../../Common/MultiSelectDropdown";
import { WithContext as ReactTags } from "react-tag-input";
import Dropdown from "../../Common/Dropdown";
import { v4 as uuidv4 } from "uuid";
import {
  fetchTemoList,
  fetchUnitsList,
  fetchTagsList,
} from "../../../../utility/templateApiActions";

const KeyCodes = {
  comma: 188,
  enter: 13,
  tab: 9
};
function SpecsTab({
  activeTab,
  exerciseToAdd,
  setExerciseToAdd,
  sportSetError,
  setSportSetError,
}) {
  const tempoList = useSelector((state) => state?.templates?.tempoList);
  const unitsList = useSelector((state) => state?.templates?.unitsList);
  const [metricUnitList, setMetricUnitList] = useState([]);
  const dispatch = useDispatch();

  console.log("sportSetError--->",sportSetError);
  /**Sports & Tag Tab */
  const [selectedSports, setSelectedSports] = useState([]);
  const [tags, setTags] = useState([]);
  const sportList = useSelector((state) => state?.templates?.sportList);
  const tagList = useSelector((state) => state?.templates?.tagList);
  const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab];
  const suggestions = tagList?.map((tag) => {
    return {
      id: tag.tag,
      text: tag.tag,
    };
  });

  const sportOptions = sportList?.map((obj) => {
    return {
      value: obj?.sport_id,
      label: obj?.sport_name,
    };
  });

  const sportChangeHandler = (data) => {
    let sport_id = data?.map((obj) => obj?.value);
    setExerciseToAdd((prevState) => {
      return { ...prevState, sport_id };
    });
    setSelectedSports(sport_id);
    setSportSetError({
      is_error: false,
      msg: "",
    });
  };

  const handleChange = () => {
    console.log("change");
  };

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  useEffect(() => {
    dispatch(fetchTagsList(selectedSports));
  }, [selectedSports]);

  useEffect(() => {
    // if(tags?.length){
    let activity_tags = tags?.map((obj) => obj?.text);
    console.log("activity_tags: ", activity_tags);
    setExerciseToAdd((prevState) => {
      return { ...prevState, activity_tags };
    });
    // }
  }, [tags]);
  /**Sports & Tag Tab */

  useEffect(() => {
    console.log("activeTab:", activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (!tempoList?.length) dispatch(fetchTemoList());
    if (!unitsList?.length) dispatch(fetchUnitsList());
  }, []);

  useEffect(() => {
    if (unitsList) {
      let list = unitsList.map((data) => {
        return {
          id: 0,
          is_enabled: 1,
          metric_id: data.metric_id,
          label: data.metric_type,
          unit_id: data.unit[0].unit_id,
          value: null,
        };
      });
      setMetricUnitList(list);
    }
  }, [unitsList]);

  useEffect(() => {
    let exerciseUnits = [...metricUnitList];
    // exerciseUnits = exerciseUnits.filter((data)=> data.value);
    // console.log("updated data:",exerciseUnits);
    setExerciseToAdd((prevState) => {
      return { ...prevState, metrics_units: exerciseUnits };
    });
  }, [metricUnitList]);

  const tempoChangeHandlerbkp = (data) => {
    setExerciseToAdd((prevState) => {
      return { ...prevState, tempo: data.value };
    });
  };

  const isPositiveInteger = useMemo(
    () => (num) => {
      return num > 0 && Number.isInteger(num);
    },
    []
  );

  const setsChangeHandler = (e) => {
    setExerciseToAdd((prevState) => {
      let value = parseInt(e?.target?.value);
      let sets =
        isPositiveInteger(value) && value < 1000
          ? value
          : isPositiveInteger(value) && value >= 1000
          ? prevState?.sets
          : 0;
      return { ...prevState, sets };
    });
    setSportSetError({
      is_error: false,
      msg: "",
    });
  };

  // const metricUnitsChangeHandler = (type, metricId, unitId, e) => {
  //     var value = parseInt(e?.target?.value);
  //     let updatedMetricsUnits = '';
  //     setExerciseToAdd(prevState => {
  //         if(prevState?.metrics_units.length){
  //             updatedMetricsUnits = prevState?.metrics_units?.map(obj => {
  //                 if(obj?.id === metricId) {
  //                     if(type==="metric") {
  //                         var metricValue = isPositiveInteger(value) && value < 1000
  //                                 ? value
  //                                 : isPositiveInteger(value) && value >= 1000
  //                                 ? obj?.value
  //                                 : "";
  //                         return { ...obj, value: metricValue };
  //                     };
  //                     return { ...obj, unit_id: parseInt(unitId) };
  //                 }else{
  //                     console.log("new matric",type);
  //                 };
  //                 return obj;
  //             });
  //             return { ...prevState, metrics_units: [ ...updatedMetricsUnits ] }
  //         }else{
  //             updatedMetricsUnits = {
  //                 "id": metricId,
  //                 "value": value,
  //                 "unit_id": parseInt(unitId)
  //             }
  //             return { ...prevState, metrics_units: [ updatedMetricsUnits ] }
  //         }

  //     });
  // };

  const metricValueChangeHandler = (metricId, e) => {
    console.log("Value", metricId, e.target.value);
    e.preventDefault();
    var value = e?.target?.value ? parseInt(e?.target?.value) : "";
    let list = [...metricUnitList];
    list.map((data) => {
      if (data.metric_id === metricId) {
        var metricValue =
          isPositiveInteger(value) && value < 1000
            ? value
            : isPositiveInteger(value) && value >= 1000
            ? data?.value
            : null;
        data.value = metricValue;
        return data;
      }
    });
    setMetricUnitList(list);
  };
  const metricUnitChangeHandler = (metricId, e) => {
    e.preventDefault();
    var value = parseInt(e?.target?.value);
    let list = [...metricUnitList];
    list.map((data) => {
      return data.metric_id === metricId ? (data.unit_id = value) : data;
    });
    console.log("list:", list);
    setMetricUnitList(list);
  };

  const metricUnitChangebkp = (data, metricId) => {
    var value = parseInt(data?.value);
    let list = [...metricUnitList];
    list.map((obj) => {
      return obj.metric_id === metricId ? (obj.unit_id = value) : obj;
    });
    console.log("list:", list);
    setMetricUnitList(list);
  };

  const metricIsEnableHandler = (metricId, e) => {
    e?.preventDefault();
    let list = [...metricUnitList];
    let newList = list.map((data) => {
      console.log(data.metric_id === metricId, data.is_enabled);
      return data.metric_id === metricId
        ? { ...data, is_enabled: data?.is_enabled === 0 ? 1 : 0 }
        : data;
    });
    console.log("list:", newList);
    setMetricUnitList(newList);
  };

  const getMetricValueById = (id) => {
    let metricObj = metricUnitList?.filter((obj) => obj?.metric_id == id)[0];
    // console.log("getMetricValueById: ",metricObj);
    return metricObj?.value ? metricObj?.value : "";
  };

  const isDisabled = useMemo(
    () => (id) => {
      let metricObj = metricUnitList?.find((obj) => obj?.metric_id == id);
      return metricObj ? !metricObj?.is_enabled : false;
    },
    [metricUnitList]
  );

  const getMetricUnitById = (id) => {
    // let metricObj = metricUnitList?.metrics_units?.find(obj => obj?.metric_id==id);
    // console.log(metricObj);
    let metricObj = metricUnitList?.find((obj) => obj?.metric_id == id);
    // console.log("getMetricUnitById: ",metricObj);
    return metricObj?.unit_id ? metricObj?.unit_id : "";
  };

  // console.log(tempoList, unitsList, exerciseToAdd);

  const unitsOptions = (metricId) => {
    let units = unitsList
      ?.find((obj) => obj?.metric_id == metricId)
      ?.unit?.map((obj) => {
        return { value: obj?.unit_id, label: obj?.unit };
      });
    return units;
  };

  const tempoOptions = tempoList?.map((obj) => {
    return {
      value: obj?.tempo_id,
      label: obj?.name,
    };
  });

  return (
    <div
      className={`si-tab-pane si-tab-pane__editspecs ${
        activeTab !== "specs" ? "d-none" : ""
      }`}
    >
      <>
        <div className="content-wrapper si-sportstags">
          <span className="si-title">Sports &amp; Tags</span>
          <span class="si-info-text">Fill in the required details</span>

          <div
            className={`custom-label si-sports ${
              sportSetError?.type === 1 && sportSetError?.is_error
                ? "si-error"
                : ""
            }`}
          >
            <label htmlFor="sport" className="si-label">
              <span>*</span>Select Sports
            </label>
            <MultiSelectDropdown
              className="custom-select"
              placeholder={"Select Sports"}
              options={sportOptions}
              changeHandler={sportChangeHandler}
              dropdownfor="sports"
            />
            {sportSetError?.type === 1 && sportSetError?.is_error && (
              <span className="si-error-message">{sportSetError?.msg}</span>
            )}
          </div>
          <div className="form-control si-tagControl">
            <label htmlFor="tags" className="si-label">
              Tags
            </label>
            <span className="custom-label__text" style={{ display: "none" }}>
              Add multiple tags to define your exercise. eg.- Arms,Abs,Chest
            </span>
            <ReactTags
              tags={tags}
              suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              // handleDrag={handleDrag}
              // handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              autocomplete
              allowDragDrop={false}
              allowDeleteFromEmptyInput={false}
              placeholder="Add tag"
              autofocus={false}
              // editable
            />
          </div>
        </div>

        <div className="content-wrapper">
          <div className="si-custom-row">
            <div className="si-custom-col">
              <div className="si-label-wrap">
                <h5 className="label-head">Default Specifications</h5>
                <span className="label-info">Fill in the default values</span>
              </div>
              <div className="si-wrp">
                <div className="form-group">
                    <div
                        className={`form-control si-sets 
                                    ${
                                      sportSetError?.type === 2 &&
                                      sportSetError?.is_error
                                        ? "si-error"
                                        : ""
                                    }`}
                    >
                        <label htmlFor="" className="si-label">
                        sets
                        </label>
                        <input
                        className="si-input si-input-sets"
                        type="text"
                        placeholder="-"
                        value={exerciseToAdd?.sets ? exerciseToAdd?.sets : ""}
                        onChange={setsChangeHandler}
                        />
                        {sportSetError?.type === 2 && sportSetError?.is_error && (
                          <span className="si-errormsg">{sportSetError?.msg}</span>
                        )}
                    </div>
                
                    <div 
                        className={`form-control si-reps 
                        ${isDisabled(9) ? "si-Disable" : ""}`} 
                    >
                        <div className="si-labelwrp">
                            <label htmlFor="" className="si-label">reps</label>
                            <span 
                                className="si-view"
                                onClick={(e) => metricIsEnableHandler(9, e)}
                            ></span>
                        </div>
                        <input 
                            id={9}
                            placeholder='-'
                            className="si-input si-input-sets" 
                            type="text"
                            value={getMetricValueById(9)}
                            onChange={(e)=>{
                                metricValueChangeHandler(9, e);
                            }}
                            disabled={isDisabled(9)}
                        />
                    </div>

                  {unitsList
                    ?.filter((obj) => obj?.metric_id != 9)
                    ?.map((metric, index) => {
                      return (
                        <div
                          className={`form-control ${
                            isDisabled(metric.metric_id) ? "si-Disable" : ""
                          }`}
                          key={metric.id}
                        >
                          <div className="si-labelwrp">
                            <label htmlFor="" className="si-label">
                              {metric.metric_type}
                            </label>
                            <span
                              className="si-view"
                              onClick={(e) =>
                                metricIsEnableHandler(metric.metric_id, e)
                              }
                            ></span>
                          </div>
                          <div
                            className="form-control-wrap"
                            disabled={isDisabled(metric.metric_id)}
                          >
                            <input
                              id={metric.metric_id}
                              placeholder="-"
                              className="si-input"
                              type="text"
                              value={getMetricValueById(metric.metric_id)}
                              onChange={(e) => {
                                metricValueChangeHandler(metric.metric_id, e);
                              }}
                              // onChange={(e) => metricUnitsChangeHandler("metric", metric.metric_id, "", e?.target?.value)}
                            />
                            <Dropdown
                              value={
                                getMetricUnitById(metric.metric_id)
                                  ? unitsOptions(metric?.metric_id)?.find(
                                      (obj) =>
                                        obj?.value ==
                                        getMetricUnitById(metric.metric_id)
                                    )
                                  : unitsOptions(metric?.metric_id)?.[0]
                              }
                              options={unitsOptions(metric.metric_id)}
                              changeHandler={(data) =>
                                metricUnitChangebkp(data, metric?.metric_id)
                              }
                              menuPlacement="top"
                              isDisabled={isDisabled(metric.metric_id)}
                            />
                            {/* <select 
                                                        className="custom-select"
                                                        value={getMetricUnitById(metric.metric_id)}
                                                        onChange={(e) => metricUnitChangeHandler(metric.metric_id, e)}
                                                    >
                                                        {
                                                            metric?.unit?.map(obj => {
                                                                return <option key={uuidv4()} value={obj?.unit_id}>{obj?.unit}</option>
                                                            })
                                                        }
                                                    </select> */}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="form-control si-tempo">
                <label htmlFor="" className="si-label">
                  tempo
                </label>
                <Dropdown
                  placeholder="Select Tempo"
                  value=""
                  options={tempoOptions}
                  changeHandler={tempoChangeHandlerbkp}
                  isDisabled={false}
                  menuPlacement="top"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="content-wrapper">
                <div className="si-custom-row">
                    <div className="si-custom-col">
                        <div className='si-label-wrap'>
                            <h5 className="label-head">specs</h5>
                            <span className="label-info">Fill in the required specs info</span>
                        </div>
                        <div className="si-form">
                        <div className="form-control">
                            <label htmlFor="" className='si-label'>sets</label>
                            <input 
                                className="si-input si-input-sets" 
                                type="text"
                                placeholder='0'
                                value={exerciseToAdd?.sets ? exerciseToAdd?.sets : ""}
                                onChange={setsChangeHandler}
                            />
                        </div>

                        <div className="form-control">
                            <label htmlFor="" className='si-label'>tempo</label>
                            <Dropdown 
                                placeholder='Select Tempo' 
                                value=''
                                options={tempoOptions}
                                changeHandler={tempoChangeHandlerbkp}
                                isDisabled={false}
                            />
                        </div>
                        </div>
                    </div>
                    <div className="si-custom-col">
                        <div className="si-label-wrap">
                            <h5 className="label-head">metric and units</h5>
                            <span className="label-info">Fill in the required measurement units info</span>
                        </div>
                        <div className="form-group">
                            {
                                unitsList?.map((metric)=>{
                                    return 
                                        <div className="form-control" key={metric.metric_id}>
                                            <label htmlFor="" className='si-label'>{metric.metric_type}</label>
                                            <div className="form-control-wrap" disabled={isDisabled(metric.metric_id)}>
                                                <input 
                                                    id={metric.metric_id}
                                                    placeholder='0'
                                                    className="custom-input" 
                                                    type="text"
                                                    value={isDisabled(metric.metric_id) ? "-" : getMetricValueById(metric.metric_id)}
                                                    onChange={(e)=>{metricValueChangeHandler(metric.metric_id,e)}}
                                                    // onChange={(e) => metricUnitsChangeHandler("metric", metric.metric_id, "", e?.target?.value)}
                                                />
                                                <select 
                                                    className="custom-select"
                                                    value={getMetricUnitById(metric.metric_id)}
                                                    onChange={(e) => metricUnitChangeHandler(metric.metric_id, e)}
                                                >
                                                    {
                                                        metric?.unit?.map(obj => {
                                                            return <option key={uuidv4()} value={obj?.unit_id}>{obj?.unit}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div> */}
      </>
    </div>
  );
}

export default SpecsTab;
