import { TableBody, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../../reducer/loader';
import ListingTable from '../../../Common/ListingTableMUI';

export default function Content({setConfirmationPopup, pageNo, setPageNo, pagination, perPage, 
    searchTerm, selectedSports, setIsFiltered,deleteAthleteHandler}) {
  const groupAthleteListing = useSelector(state => state?.userManagement?.groupAthleteListing);
  const [isActionActive, setIsActionActive] = useState(Array(groupAthleteListing?.length)?.fill(false));
  const dispatch = useDispatch();

    useEffect(() => {
      window?.addEventListener("click", handleKeydownEvent);
  
      return () => window?.removeEventListener("click", handleKeydownEvent);
  }, []);
  
  useEffect(() => {
    setIsActionActive(Array(groupAthleteListing?.length)?.fill(false));
  }, [groupAthleteListing]);
  
  const handleKeydownEvent = (event) => {
    setIsActionActive(Array(groupAthleteListing?.length)?.fill(false));
  };
  
  const handleAction = (e, idx) => {
    e?.stopPropagation();
    setIsActionActive(prevState => {
        let newState = Array(groupAthleteListing?.length)?.fill(false);
        newState[idx] = !prevState[idx];
        return [ ...newState ];
    });
  };

  const handleDeleteClick = (e, id) => {
    e?.stopPropagation();
    setConfirmationPopup({
        isShow: true,
        isActionBtns: true,
        warning: "Are you sure you want to remove this athlete?",
        warningMsg: "",
        cancelHandler: () => setConfirmationPopup({ isShow: false }),
        okHandler: () => {
          dispatch(loaderActions?.showLoader());
          setConfirmationPopup({ isShow: false });
          deleteAthleteHandler(id);
        },
      });
  };
    const tableHeaders = [
      {
          label: "Athlete Name",
          align: "left"
      },
      {
          label: "Sport",
          align: "left"
      },
      {
          label: "Group",
          align: "center"
      },
      {
          label: "",
          align: "center",
          stickyClass: "stickyCell-right" 
      }
    ];
    
    const getTableBody = () => {
      return (
          <TableBody sx={{backgroundColor:"#181818"}}>
              {
                  groupAthleteListing?.length
                  ?
                  groupAthleteListing?.map((athlete, idx) => {
                      return (
                          <TableRow 
                              key={idx}
                          >
                              <TableCell 
                                  align={"left"} 
                                  sx={{minWidth: 150}}
                              >
                                  {athlete?.first_name ? athlete?.first_name : ""} {athlete?.last_name ? athlete?.last_name : ""}
                              </TableCell>
                              <TableCell align={"left"}>
                                  {athlete?.sport_name ? athlete?.sport_name.join(', ') : '-'}
                              </TableCell>
                              <TableCell align={"center"}>
                                  {athlete?.group_name ? athlete?.group_name.join(', ') : ''}
                              </TableCell>
                              <TableCell align={"center"} className="stickyCell-right">
                                  <div className="si-custom-table__cell si-wrap" style={{margin:"auto"}}>
                                      <button 
                                          type="button" 
                                          className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                                          onClick={(e) => handleAction(e, idx)}
                                      ></button>
                                      <div className="si-action-icons">
                                          <button 
                                              type="button" 
                                              className="si-icon-button"
                                              onClick={(e) => handleDeleteClick(e, athlete?.athlete_id)}
                                          >
                                              <span className="icon-delete"></span>remove
                                          </button>
                                      </div>
                                  </div>
                              </TableCell>
                          </TableRow>
                      )
                  })
                  :
                  <TableRow className="nodata-tableRow">
                      <TableCell align={"center"} colSpan={tableHeaders.length}>
                          No data found
                      </TableCell>
                  </TableRow>
              }
          </TableBody>
      )
    };
  return (
    <div className="si-main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={pagination}
                perPage={perPage}
            />
        </div>
  )
}
