import React, { useState } from "react";
import axios from "axios";
import { Fragment } from "react";
import { toast } from "react-toastify";
import API from "../../../utility/api";


function DownloadMediaPopup({ isShow, closePopupHandler, files }) {
  const apiBaseUrl = process.env.REACT_APP_BASE_PATH;
//   let getAPIDetails = new API()?.getAPIDownloadPDF;
const ICON_SVG = {
  pdf: "/images/icons/pdf2-icon.svg",
  jpeg: "/images/icons/jpeg-icon.svg",
  txt: "/images/icons/txt-icon.svg",
  docx: "/images/icons/docx-icon.svg",
  png: "/images/icons/png-icon.svg",
  jpg: "/images/icons/jpg-icon.svg",
  others: "/images/icons/pdf2-icon.svg",
};

const getFileTypeIcon = ({ fileName }) => {
  let fileType = fileName.split(".")[fileName.split(".").length - 1];
  if (ICON_SVG[fileType]) {
    return ICON_SVG[fileType];
  } else {
    return ICON_SVG.others;
  }
};

  const DownloadFile = (name) => {
    let url = `${apiBaseUrl}common/getfile`
    axios.post(url,{
      "filename":name
    } ,{
        responseType: "blob",
    }).then((res)=>{
        const urlObject = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = urlObject;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("File downloaded successfully.")
    }).catch((error)=>{
        console.log("err0", error)
        toast.error("Unable to download the file. Please try again.")
    })

//    let apiCall = getAPIDetails(url)
//    toast.promise( apiCall,
//    { pending : { render : ()=> "Downloading"},
//     success : { render :(res)=> {
//         const urlObject = window.URL.createObjectURL(res.data.data);
//         const link = document.createElement('a');
//         link.href = urlObject;
//         link.setAttribute('download', name);
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//         return "File Downloaded"
//     }},
//     error : { render : (error)=> "Downloading Failed"}
//     }
//    )
  };

  return (
    <Fragment>
      <div className={`si-popup ${isShow ? "active" : ""}`}>
        <div className="upload-popup">
          <div className="upload-popup__header">
            <div className="header-top">
              <h3 className="header-top__title">Download Attachments</h3>
              <button type="button" className="si-icon__button si-button__close" onClick={closePopupHandler}>
                <span className="icon-close" />
              </button>
            </div>
            {/* <p className="header-bottom">Upload your files/images/documents</p> */}
          </div>
          <div className="upload-popup__body">

            <div className="uploading-state">
              {files?.length ? (
                files.map((item, index, filename) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="file-card">
                        <div className="file-img-wrp">
                          <img
                            src={process.env.PUBLIC_URL + getFileTypeIcon({ fileName: item })}
                            alt="Uploadimage"
                          />
                        </div>

                        <div className="file-name-wrp">
                          <span className="img-name">
                            <p>{item}</p>
                          </span>
                          <span class="icon-download" onClick={()=>DownloadFile(item)}></span>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })
              ) : (
                <div className="no-attachement-txtwrp">
                    <p className="no-attachement-txt">No Attachments Found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default DownloadMediaPopup;
