import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ListingTable from '../Common/ListingTableMUI';
import { TableBody, TableRow, TableCell } from '@mui/material';
import { loaderActions } from '../../../reducer/loader';
import { getFunctionWiseAthleteListing } from '../../../utility/testAssessmentApiActions';
import Header from '../Common/Header';
import SubHeader from '../Common/SubHeader';
import moment from 'moment';
import { fetchGroupListing } from '../../../utility/programApiActions';
import { fetchSportsList } from '../../../utility/templateApiActions';
import Dropdown from '../Common/Dropdown';
import Content from '../Snc/AthleteProgramListing/Content';
import Select from 'react-select';
import { testAssessmentActions } from '../../../reducer/testAssessment';

const AthleteListing = () => {
    const { id } = useParams();
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedSports, setSelectedSports] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const isAuth = useSelector(state => state?.auth?.isAuthenticated);
    const functionDetails = useSelector(state => state?.testAssessment?.functionDetails);
    const atheletelist = useSelector(state => state?.testAssessment?.atheleteList);
    const paginationObject = useSelector(state => state?.testAssessment?.paginationObject);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let filterOptions = {
        is_search_filter: true,
        is_tab: false,
        is_sport_filter: true,
        is_group_filter: true
    };

    useEffect(() => {
        if (isAuth) {
            let timeOut = (searchTerm !== null) ? 1000 : 0
            let atheleteListTimeOut = setTimeout(() => {
                if (searchTerm !== null) dispatch(loaderActions?.showLoader());
                dispatch(
                    getFunctionWiseAthleteListing({
                        pageNo,
                        perPage: 20,
                        // userguid,
                        functionId: id,
                        searchTerm,
                        selectedSports,
                        groupFilter: selectedGroups,
                        callback: () => setTimeout(() => {
                            dispatch(loaderActions?.hideLoader());
                        })
                    })
                );
            }, timeOut);
            return () => clearTimeout(atheleteListTimeOut);
        };
    }, [isAuth, searchTerm, pageNo, selectedGroups, selectedSports]);

    useEffect(() => {
        dispatch(fetchSportsList());
        dispatch(fetchGroupListing());
        localStorage.removeItem("guid");
    }, []);

    const handleTrackPerformance = (e, guid) => {
        e?.stopPropagation();
        dispatch(testAssessmentActions.setAthleteFunction({ id }));
        localStorage.setItem("guid", guid);
        navigate(`/testfunctions/${id}/athletereports`);
    };

    const tableHeaders = [
        {
            label: "Athlete Name",
            align: "left",
            stickyClass: "stickyCell-left"
        },
        {
            label: "Sport",
            align: "left"
        },
        {
            label: "Group",
            align: "left"
        },
        {
            label: "Conducted On",
            align: "center"
        },
        {
            label: "Next Schedule",
            align: "center"
        },
        {
            label: "",
            align: "right"
        }
    ];

    const getTableBody = () => {
        return (
            <TableBody>
                {
                    atheletelist?.length
                        ?
                        atheletelist?.map((report, idx) => {
                            let name = report?.athlete_name ? report?.athlete_name : "";
                            let sports = report?.sport_name;
                            let sportsCount = sports?.length;
                            let sportName = sportsCount > 2 ? `${sports?.slice(0, 2)?.join(", ")}` : sports?.join(", ");
                            let otherCount = sportsCount > 2 ? `+${sportsCount - 2}` : "";
                            let group = report?.group_name;
                            let groupCount = group?.length;
                            let groupName = groupCount > 2 ? `${group?.slice(0, 2)?.join(", ")}` : group?.join(", ");
                            let groupOtherCount = groupCount > 2 ? `+${groupCount - 2}` : "";

                            return (
                                <TableRow
                                    key={idx}
                                >
                                    <TableCell align={"left"}
                                        className="stickyCell-left"
                                        sx={{ cursor: "pointer" }}
                                        onClick={(e) => handleTrackPerformance(e, report?.user_guid)}>
                                        {name ? name : ""}
                                    </TableCell>

                                    <TableCell align={"left"}>
                                        {sportName ? sportName : "-"}
                                        {
                                            otherCount &&
                                            <span className="si-custom-table si-count">{otherCount}</span>
                                        }
                                    </TableCell>

                                    <TableCell align={"left"}>
                                        {groupName ? groupName : "-"}
                                        {
                                            groupOtherCount &&
                                            <span className="si-custom-table si-count">{groupOtherCount}</span>
                                        }
                                    </TableCell>

                                    <TableCell align={"center"}>
                                        {report?.conducted_date ? moment(report?.conducted_date)?.format("DD/MM/yyyy") : '-'}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        {report?.scheduled_date ? moment(report?.scheduled_date)?.format("DD/MM/yyyy") : '-'}
                                    </TableCell>

                                    <TableCell align={"right"}>
                                    <button 
                                            type="button" 
                                            className="si-viewBtn"
                                            onClick={(e) => handleTrackPerformance(e,  report ?.user_guid)}
                                    >
                                        <span className="si-icon__view"></span>
                                        <span className="si-txt">View Reports</span>
                                    </button> 
                                    </TableCell>
                                </TableRow>
                            )
                        })
                        :
                        <TableRow className="nodata-tableRow">
                            <TableCell align={"center"} colSpan={tableHeaders.length}>
                                No data found
                            </TableCell>
                        </TableRow>
                }
            </TableBody>
        );
    };

    return (
        <div>
            <Header
                headerData={{
                    heading: functionDetails ? functionDetails.function_name : "",
                    isHeadingEditable: false,
                    isbackBtn: true,
                    backBtnAction: () => navigate("/testfunctions"),
                }} />
            <div>
                <SubHeader
                    OtherClass='si-completed-subheader'
                    filterOptions={filterOptions}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    pageNo={pageNo}
                    selectedSports={selectedSports}
                    setSelectedSportIds={setSelectedSports}
                    selectedGroupsIds={selectedGroups}
                    setSelectedGroupIds={setSelectedGroups}
                    setPageNo={setPageNo}
                    navigate={navigate}
                />
            </div>
            <div className="si-main-content">
                <ListingTable
                    tableHeaders={tableHeaders}
                    getTableBody={getTableBody}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    pagination={paginationObject}
                    perPage={20}
                />
            </div>
        </div>
    )
}


export default AthleteListing;


