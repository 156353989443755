import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addPerformanceAthletePopup, fetchMedalMaster } from "../../../../utility/tournamentPerformanceApiAction";
import ConfirmationPopup from "../../Common/ConfirmationPopup";
import Dropdown from "../../Common/Dropdown";

function AthletePerformanceListing({
  athleteRecordDetails,
  updateAthleteRecord,
  setUpdateAthleteRecord,
  viewMode,
  viewModeOnly,
  selectedSports,
  selectedEvent,
  athleteEventRecordDetails,
}) {
  const medalMaster = useSelector((state) => state.tournamentPerformance.medalMaster);
  const userData = useSelector((state) => state?.auth?.userData);
  let [athleteRecordsDetailsState, setAthleteRecordDetailsState] = useState(null);
  let [selectedathleteMedal, setSelectedAthleteMedal] = useState(null);
  let [showDeletePopup, setShowDeletePopup] = useState({ show: false, callback: null });
  let { performanceId, tournamentId } = useParams();
  const dispatch = useDispatch();
  const medalsImgSrc = {
    1: "gold-medal-icon.svg",
    2: "silver-medal-icon.svg",
    3: "bronze-medal-icon.svg",
  };
  useState(() => {
    if (viewMode) {
      setUpdateAthleteRecord(null);
    }
  }, [viewMode]);
  useEffect(() => {
    setUpdateAthleteRecord(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!medalMaster && !medalMaster?.length) {
      dispatch(fetchMedalMaster());
    }
  }, [dispatch, medalMaster]);
  useEffect(() => {
    if (athleteRecordDetails?.length) {
      setAthleteRecordDetailsState(athleteRecordDetails);
    }
  }, [athleteRecordDetails]);

  useEffect(() => {
    if (athleteEventRecordDetails?.data) {
      let obj = {};
      athleteEventRecordDetails?.data?.forEach((athlete) => {
        obj = {
          ...obj,
          [athlete?.te_athlete_map_id]: {
            label:
              athlete?.value?.medal && medalMaster?.length
                ? medalMaster.filter((medals) => medals.medal_id === athlete?.value?.medal)[0]?.medal
                : "Select Medal",
            value:
              athlete?.value?.medal && medalMaster?.length
                ? medalMaster.filter((medals) => medals.medal_id === athlete?.value?.medal)[0]?.medal_id
                : "Select Medal",
          },
        };
      });
      setSelectedAthleteMedal(obj);
    }
  }, [viewMode, athleteEventRecordDetails, medalMaster]);

  useEffect(() => {
    let obj = {};
    athleteRecordsDetailsState?.[0].event_details?.athlete_details?.forEach((athlete) => {
      obj = {
        ...obj,
        [athlete?.te_athlete_map_id]: {
          label:
            athlete?.value?.medal && medalMaster?.length
              ? medalMaster.filter((medals) => medals.medal_id === athlete?.value?.medal)[0]?.medal
              : "Select Medal",
          value:
            athlete?.value?.medal && medalMaster?.length
              ? medalMaster.filter((medals) => medals.medal_id === athlete?.value?.medal)[0]?.medal_id
              : "Select Medal",
        },
      };
    });
    setSelectedAthleteMedal(obj);
  }, [athleteRecordsDetailsState, medalMaster]);

  const handleUpdateChange = ({ data, event, athleteMapId, key, valueObj }) => {
    let value = "";
    if (data) {
      value = data;
      setSelectedAthleteMedal((old) => ({
        ...old,
        [athleteMapId]: data,
      }));
      value = data.value;
    } else {
      value = event.target.value;
      if (value === "") {
        value = null;
      }
    }
    setUpdateAthleteRecord((oldState) => {
      let obj = {};
      if (oldState) {
        obj = {
          ...oldState,
          [athleteMapId]: {
            athlete_map_id: athleteMapId,
            value: {
              ...valueObj,
              ...oldState?.[athleteMapId]?.value,
              [key]: value,
            },
          },
        };
      } else {
        obj = {
          [athleteMapId]: {
            athlete_map_id: athleteMapId,
            value: {
              ...valueObj,
              [key]: value,
            },
          },
        };
      }
      return obj;
    });
  };

  const deleteAthlete = ({ athlete }) => {
    let payload = {
      opt_type: 2,
      performance_record_id: performanceId ? Number(performanceId) : null,
      event_id: selectedEvent?.event_id ? Number(selectedEvent?.event_id) : null,
      tournament_id: tournamentId ? Number(tournamentId) : null,
      sport_id: selectedSports?.value ? Number(selectedSports?.value) : null,
      athlete_guid: null,
      user_guid: userData?.user_guid ? userData?.user_guid : null,
      te_athlete_map_id: athlete?.te_athlete_map_id ? Number(athlete?.te_athlete_map_id) : null,
      value: null,
    };
    console.log("first", athlete, payload, selectedEvent, selectedSports);
    dispatch(addPerformanceAthletePopup({ payload, id: performanceId }));
  };

  const cancelHandler = () => {
    setShowDeletePopup({ show: false, callback: null });
  };

  const deleteOkHandler = () => {
    setShowDeletePopup((old) => {
      if (old.callback) {
        old.callback();
      }
      return { show: false, callback: null };
    });
  };

  return (
    <>
      {/* {athleteEventRecordDetails ? ( */}
      <div className="table-body__inner-wrapper">
        <div className="tournament-body__right-body">
          <table
            className={
              viewMode
                ? "tournament-tabl view-tournament-table nodata-tbody-active"
                : "tournament-table nodata-tbody-active"
            }
          >
            <thead className="tournament-table-header">
              {athleteEventRecordDetails?.header_details?.length ? (
                <>
                  <td></td>
                  {athleteEventRecordDetails?.header_details.map((header) => {
                    return <td key={header.id}>{header.label}</td>;
                  })}
                  <td></td>
                </>
              ) : (
                <>
                  <td></td>
                  <td>Athlete Name</td>
                  <td>Total Score</td>
                  <td>Rank</td>
                  <td>Medal</td>
                  <td></td>
                </>
              )}
            </thead>
            {athleteEventRecordDetails?.data?.length ? (
              athleteEventRecordDetails?.data
                ?.filter((athleteRecord) =>
                  !viewModeOnly && !viewMode ? Boolean(athleteRecord?.is_selected === 1) : true
                )
                .map((athlete) => {
                  return (
                    <tbody className="tournament-table-body">
                      <React.Fragment key={athlete.user_id}>
                        <tr>
                          <td>{athlete?.is_selected ? <span class="icon-academic-player"></span> : null}</td>
                          <td>{athlete?.athlete_name ? athlete?.athlete_name : "-"}</td>
                          <td>
                            <input
                              type="text"
                              className="tbl-input total-score"
                              placeholder="-"
                              onChange={(event) =>
                                handleUpdateChange({
                                  event,
                                  athleteMapId: athlete?.te_athlete_map_id,
                                  key: "score",
                                  valueObj: athlete?.value,
                                })
                              }
                              defaultValue={
                                updateAthleteRecord?.[athlete?.te_athlete_map_id]
                                  ? updateAthleteRecord[athlete?.te_athlete_map_id]?.value?.score
                                    ? updateAthleteRecord[athlete?.te_athlete_map_id]?.value?.score
                                    : ""
                                  : athlete?.value?.score
                                  ? athlete?.value?.score
                                  : ""
                              }
                            />
                            <span className="tbl-viewdata view-total-score">
                              {athlete?.value?.score ? athlete?.value?.score : "-"}
                            </span>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="tbl-input rank"
                              placeholder="-"
                              onChange={(event) =>
                                handleUpdateChange({
                                  event,
                                  athleteMapId: athlete?.te_athlete_map_id,
                                  key: "rank",
                                  valueObj: athlete?.value,
                                })
                              }
                              defaultValue={athlete?.value?.rank ? athlete?.value?.rank : ""}
                            />
                            <span className="tbl-viewdata">{athlete?.value?.rank ? athlete?.value?.rank : "-"}</span>
                          </td>
                          <td>
                            {!viewMode ? (
                              <Dropdown
                                value={{ ...selectedathleteMedal?.[athlete?.te_athlete_map_id] }}
                                options={[
                                  { label: "Select Medal", value: 0 },
                                  ...medalMaster?.map((obj) => {
                                    return {
                                      value: obj?.medal_id,
                                      label: obj?.medal,
                                    };
                                  }),
                                ]}
                                changeHandler={(data) => {
                                  console.log("data", data);
                                  handleUpdateChange({
                                    data,
                                    athleteMapId: athlete?.te_athlete_map_id,
                                    key: "medal",
                                    valueObj: athlete?.value,
                                  });
                                }}
                              />
                            ) : null}
                            <span className="tbl-viewdata img-type-wrp">
                              {athlete?.value?.medal && medalsImgSrc[athlete?.value?.medal] ? (
                                <img
                                  src={process.env.PUBLIC_URL + "/images/icons/" + medalsImgSrc[athlete?.value?.medal]}
                                  alt="medal icon"
                                  style={{ width: "21px" }}
                                />
                              ) : null}
                              <span className="medal-type">
                                {athlete?.value?.medal && medalMaster?.length
                                  ? medalMaster.filter((medals) => medals.medal_id === athlete?.value?.medal)[0]?.medal
                                  : "-"}
                              </span>
                            </span>
                          </td>
                          <td>
                            {!viewModeOnly && !viewMode ? (
                              <span
                                className="icon-close"
                                onClick={() => {
                                  setShowDeletePopup({
                                    show: true,
                                    callback: () => deleteAthlete({ athlete }),
                                  });
                                }}
                              ></span>
                            ) : null}
                          </td>
                        </tr>
                      </React.Fragment>
                    </tbody>
                  );
                })
            ) : (
              <tbody className="nodata-tbody">
                <tr>
                  <td>
                    <div className="nodata-img-wrp">
                      <img src={process.env.PUBLIC_URL + "/images/nodata-found.svg"} alt="no data found img" />
                    </div>
                    <div>No data found</div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
      {/* ) : null} */}
      <ConfirmationPopup
        isActive={showDeletePopup?.show}
        isActionBtns={true}
        warning={"Are you sure you want to delete this athlete record?"}
        okHandler={deleteOkHandler}
        cancelHandler={cancelHandler}
      />
    </>
  );
}

export default AthletePerformanceListing;
