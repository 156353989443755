import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import Templates from '../components/RightPannel/Snc/TemplateListing/TemplateListing';
import TemplateCreationForm from '../components/RightPannel/Snc/TemplateCreationForm/TemplateCreationForm';
import TemplateCalendar from '../components/RightPannel/Snc/TemplateCalendar/TemplateCalendar';
import SelectExerciseList from '../components/RightPannel/Snc/SelectExerciseList/SelectExerciseList';
import SelectedExerciseList from '../components/RightPannel/Snc/SelectedExerciseList/SelectedExerciseList';
import ExerciseMasterListing from '../components/RightPannel/Snc/ExerciseMasterListing/ExerciseListing';
import Programs from '../components/RightPannel/Snc/ProgramListing/ProgramListing';
import CompletedPrograms from '../components/RightPannel/Snc/ProgramListing/CompletedProgramListing';
import ProgramTemplates from '../components/RightPannel/Snc/ProgramSelectList/ProgramSelectList';
import ProgramCalendar from '../components/RightPannel/Snc/ProgramCalendar/ProgramCalendar';
import AthleteProgramListing from '../components/RightPannel/Snc/AthleteProgramListing/AthleteProgramListing';
import CompletedAthleteProgramListing from '../components/RightPannel/Snc/AthleteProgramListing/CompletedAthleteProgramListing';
import ProgramProgressCalendar from '../components/RightPannel/Snc/ProgramProgressCalendar/ProgramProgressCalendar';
import AthleteProfile from '../components/RightPannel/Athletes/AthleteProfile/AthleteProfile';
import Auth from '../components/Auth';
import Dashboard from '../components/Dashboard/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import AthleteListing from '../components/RightPannel/Athletes/AthleteListing/AthleteListing';
import AthleteReportListing from '../components/RightPannel/Common/AthleteReportListing/AthleteReportListing';
import { ATHELETE_ROLE_ID, ATHLETE_MODULE_ROUTE, NUTRITION_MODULE_ROUTE } from '../utility/constants';
import AddNewRecord from '../components/RightPannel/Tournament/PerformanceTracker/AddNewRecord';
import InjuryReportLogDetails from '../components/RightPannel/InjuryTracker/InjuryReportLogDetails';
import AddNewNutritionLog from '../components/RightPannel/Nutrition/AddNewNutritionLog';
import AthleteProfileNutrition from '../components/RightPannel/Nutrition/AthleteProfile/AthleteProfileNutrition';

function Routing() {
    const userData = useSelector(state => state?.auth?.userData);

    return (
        <Routes>
            <Route
                exact
                path="/"
                element={
                    <ProtectedRoute Component={Dashboard} />
                }
            />
            <Route
                exact
                path="/login"
                element={
                    <Auth />
                }
            />
            <Route
                exact
                path="/snc/templates"
                element={
                    <ProtectedRoute Component={Templates} />
                }
            />
            <Route
                exact
                path="/snc/templates/new"
                element={
                    <ProtectedRoute Component={TemplateCreationForm} />
                }
            />
            <Route
                exact
                path="/snc/templates/:id/calendar"
                element={
                    <ProtectedRoute Component={TemplateCalendar} />
                }
            />
            <Route
                exact
                path="/snc/templates/:id/calendar/exercisemaster"
                element={
                    <ProtectedRoute Component={SelectExerciseList} type={1} />
                }
            />
            <Route
                exact
                path="/snc/templates/:id/calendar/exercise"
                element={
                    <ProtectedRoute Component={SelectedExerciseList} type={1} />
                }
            />
            {/* <Route 
                exact 
                path="/programtemplates/editexercise" 
                element={ 
                    <ProtectedRoute Component={EditExercise}/>
                }
            /> */}
            <Route
                exact
                path="/snc/programs"
                element={
                    <ProtectedRoute Component={Programs} />
                }
            />
            <Route
                exact
                path="/snc/completedprograms"
                element={
                    userData?.role_id == ATHELETE_ROLE_ID
                        ? <CompletedAthleteProgramListing />
                        : <CompletedPrograms />
                }
            />
            <Route
                exact
                path="/snc/completedprograms/:id/progressCalendar/:userId"
                element={
                    <ProtectedRoute Component={ProgramProgressCalendar} />
                }
            />
            <Route
                exact
                path="/snc/completedprograms/:id/calendar"
                element={
                    <ProtectedRoute Component={ProgramCalendar} />
                }
            />
            <Route
                exact
                path="/snc/programs/templates"
                element={
                    <ProtectedRoute Component={ProgramTemplates} />
                }
            />
            <Route
                exact
                path="/snc/programs/:id/calendar"
                element={
                    <ProtectedRoute Component={ProgramCalendar} />
                }
            />
            <Route
                exact
                path="/snc/programs/:id/calendar/exercisemaster"
                element={
                    <ProtectedRoute Component={SelectExerciseList} type={2} />
                }
            />
            <Route
                exact
                path="/snc/programs/:id/calendar/exercise"
                element={
                    <ProtectedRoute Component={SelectedExerciseList} type={2} />
                }
            />
            <Route
                exact
                path="/snc/assignedprograms"
                element={
                    <ProtectedRoute Component={AthleteProgramListing} />
                }
            />
            <Route
                exact
                path="/snc/assignedprograms/:id/progressCalendar/:userId"
                element={
                    <ProtectedRoute Component={ProgramProgressCalendar} />
                }
            />
            <Route
                exact
                path="/snc/programs/:id/calendar/progressCalendar/:userId"
                element={
                    <ProtectedRoute Component={ProgramProgressCalendar} />
                }
            />
            <Route exact path='/snc/exercisemaster'
                element={
                    <ProtectedRoute Component={ExerciseMasterListing} />
                }
            />
            <Route exact path={`/${ATHLETE_MODULE_ROUTE}`}
                element={
                    <ProtectedRoute Component={AthleteListing} />
                }
            />

            <Route exact path='/profile'
                element={
                    <ProtectedRoute Component={AthleteProfile} />
                }
            />

            <Route exact path='/athleteprofile/:id'
                element={
                    <ProtectedRoute Component={AthleteProfile} />
                }
            />
            <Route exact path={`/athleteprofile/${NUTRITION_MODULE_ROUTE}/:typeId/:logId`} 
                element={
                    <ProtectedRoute Component={AddNewNutritionLog}/>
                }
            />
            <Route exact path={`/athleteprofile/${NUTRITION_MODULE_ROUTE}/:typeId/:logId/:athleteId`} 
                element={
                    <ProtectedRoute Component={AthleteProfileNutrition}/>
                }
            />
            <Route exact path={`/athleteprofile/:performanceId/:sportsId/:eventId`} element={
                <ProtectedRoute Component={AddNewRecord}/>
            }  />

            <Route exact path={`/athleteprofile/injuryDetails/:injuryId`} element={
                <ProtectedRoute Component={InjuryReportLogDetails}/>
            }  />
            <Route
                exact
                path="/athleteprofile/:id/reports"
                element={
                    <ProtectedRoute
                        Component={AthleteReportListing}
                        from="profile"
                    />
                }
            />

        </Routes>

    )
};

export default Routing;