import { TableBody, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loaderActions } from '../../../../reducer/loader';
import { fetchTechnicalAthletePrograms } from '../../../../utility/technicalProgramApiActions';
import ListingTable from '../../Common/ListingTableMUI';
import SubHeader from '../../Common/SubHeader';
import moment from 'moment';
import { ATHELETE_ROLE_ID } from '../../../../utility/constants';

export default function TechnicalTraining({activeTab}) {
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [userDetails, setUserDetails] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedProgramStatus, setSelectedProgramStatus] = useState("");
    const [selectedDateRangeIds, setSelectedDateRangeIds] = useState([]);
    const isAuth = useSelector(state => state?.auth?.isAuthenticated);
    const athletePrograms = useSelector(state => state?.technicalPrograms?.athleteProgramsListing);
    const paginationObject = useSelector(state => state?.technicalPrograms?.paginationObject);
    const userData = useSelector((state) => state?.auth?.userData);
    let filterOptions = {
        is_program_status_filter: true,
        program_filter_text: "program Status",
        is_search_filter: true,
        is_date_range_filter:false,
        is_tag_filter:false,
        is_tab:false
    };
    useEffect(() => {
        if (userData && userData?.role_id == ATHELETE_ROLE_ID) {
            setUserDetails({id:userData?.user_id,guid:userData?.user_guid});
        }else{
            setUserDetails({id:id,guid:localStorage?.getItem("guid")});
        }
      }, [userData]);

    useEffect(() => {
        if(isAuth && userDetails && searchTerm != null) {
            let timeOut = searchTerm!==null ? 1000 : 0;
            let programListTimeOut = setTimeout(() => {
                dispatch(loaderActions?.showLoader());           
                dispatch(
                    fetchTechnicalAthletePrograms({
                        pageNo,
                        activeTab: selectedProgramStatus,
                        searchTerm,
                        userguid : userDetails?.guid,
                        callback: () => setTimeout(() =>{
                            dispatch(loaderActions?.hideLoader());
                        })
                    })
                );
            }, timeOut);

            return () => clearTimeout(programListTimeOut);
        };
    }, [searchTerm]);

    useEffect(() => {
        if(isAuth && userDetails && pageNo) {
            dispatch(loaderActions?.showLoader());           
            dispatch(
                fetchTechnicalAthletePrograms({
                    pageNo,
                    activeTab: selectedProgramStatus,
                    searchTerm,
                    userguid : userDetails?.guid,
                    callback: () => setTimeout(() =>{
                        dispatch(loaderActions?.hideLoader());
                    })
                })
            );
        };
    }, [isAuth, pageNo, selectedProgramStatus,userDetails]);
    
    const tableHeaders = [
        {
            label: "Program Name",
            align: "left"
        },
        {
            label: "Status",
            align: "left"
        },
        {
            label: "Duration",
            align: "left"
        },
        {
            label: "Start - End Date",
            align: "center"
        },
        // {
        //     label: "Created by ",
        //     align: "left"
        // },
        {
            label: "Overall Performance",
            align: "center"
        },
        {
            label: "",
            align: "center",
            stickyClass: "stickyCell-right"
        }
    ];
    const handleTrackPerformance = (e,program_id)=>{
        e?.stopPropagation();
        navigate(`/technical/programs/${program_id}/calendar/progressCalendar/${userDetails.id}?tab=${activeTab}`,{ state: { backUrl : location.pathname+'?tab=technical' } });
    };

    const getTableBody = () => {
        return (
            <TableBody>
                {
                    athletePrograms?.length
                    ?
                    athletePrograms?.map((program, idx) => {
                        return (
                            <TableRow 
                                key={idx}
                            >
                                <TableCell align={"left"} style={{cursor: 'pointer'}} onClick={(e) => handleTrackPerformance(e, program?.program_id)}>
                                    {program?.program_name}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {program?.status ? program?.status : '-'}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {program?.duration ? program?.duration : '-'}
                                    {program?.duration > 1 ? ' Weeks' : ' Week'}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {program?.start_date ? `${moment(program?.start_date).format("DD/MM/yyyy")} - ${moment(program?.end_date).format("DD/MM/yyyy")}`: '-'}
                                </TableCell>
                                {/* <TableCell align={"left"}>
                                    {program?.created_by_role ? program?.created_by_role : '-'}
                                </TableCell> */}
                                <TableCell align={"center"}>
                                    {program?.progress_val ? program?.progress_val + '%' : '0%'}
                                </TableCell>
                                <TableCell align={"center"} className='stickyCell-right'>
                                    <button 
                                            type="button" 
                                            className="si-viewBtn"
                                            onClick={(e) => handleTrackPerformance(e, program?.program_id)}
                                    >
                                        <span className="si-icon__view"></span>
                                        <span className="si-txt">View Program</span>
                                    </button>
                                       
                                </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={tableHeaders.length}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };
  return (
    <div className="si-tab-pane si-tab-pane__Training">
        <SubHeader
            filterOptions={filterOptions}
            OtherClass={"athelete-profile-subheader"}
            searchTerm={ searchTerm }
            setSearchTerm={ setSearchTerm }
            setSelectedProgramStatus={setSelectedProgramStatus}
            setSelectedDateRangeIds={setSelectedDateRangeIds}
            pageNo={ pageNo }
            setPageNo={ setPageNo }
            isBtn={false}
        />
        <div className="si-main-content myprofile-maincontent">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={paginationObject}
                perPage={20}
            />
        </div>
    </div>
  )
}
