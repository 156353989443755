import React from 'react';

function AthleteDetailsHeader({ details }) {
    let name = details?.athlete_name ? details?.athlete_name : "";
    console.log(details);
    return (
        <div className="si-Performanceheader si-reports">
            
            <div className="si-Left">
                <div className="si-infoWrp">
                    <div className="si-Image">
                        <img src={`../../images/profile/${details?.athlete_id}.png`} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src="../../images/profile/default.png";
                        }}/>
                    </div>
                    <div className="si-infoDetails">
                        <div className="si-name">{ name }</div>
                        <div className="si-otherInfo">
                            {/* <div className="si-profession">
                                <span>Athlete  </span>
                                <span>| </span>
                                <span>{details?.sport_name ? details?.sport_name : ""}</span>
                            </div> */}
                            <div className="si-groupWrp" style={{marginRight: "10px"}}>
                                <div className="si-group">{`KID000${details?.athlete_id ? details?.athlete_id : "0"}`}</div>
                            </div>
                            <div className="si-profession">
                                <span>|</span>
                            </div>
                            <div className="si-groupWrp">
                                {
                                    details?.group_name?.map((name, idx) => {
                                        return <div className="si-group" key={idx}>{name}</div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AthleteDetailsHeader;