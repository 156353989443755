import React from 'react'
import SubHeader from '../../Common/SubHeader';

function TournamentListingSubHeader({searchTerm, setSearchTerm, selectedSports,setSelectedSports,pageNo, setPageNo, onClick}) {
  let filterOptions = {
    is_sport_filter: true,
    is_search_filter: true,
    is_tab: false,
    is_group_filter: false,
};
  return (
    <SubHeader 
    filterOptions={filterOptions}
    searchTerm={ searchTerm }
    setSearchTerm={ setSearchTerm }
    selectedSports={ selectedSports }
    setSelectedSportIds={ setSelectedSports }
    pageNo={ pageNo }
    setPageNo={ setPageNo }
    isBtn={true}
    btnName="Add Tournament"
    onBtnClick={onClick}
/>
  )
}

export default TournamentListingSubHeader