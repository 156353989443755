import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  fetchAthleteDetails,
  fetchInternalNotesListing,
  fetchNotesDetails,
  fetchNutritionReportLogListing,
  fetchNutritionSportsList,
  getAthleteProfileNutritionListing,
  getAthleteWiseReportListing,
  getNutritionLogDetails,
  getSelectedAthleteDetails,
  insertUpdateInternalNote,
  updateLogDetails,
} from "../utility/nutritionApiAction";

const initialState = {
  sportsList: false,
  reportLogListing: false,
  reportLogListingPagination: false,
  nutritionLogDetails: false,
  logDetailsUpdated: false,
  athleteReportListing:false,
  athleteDetails:false,
  internalNotesListing:false,
  internalNotesListingPagination:false,
  noteDetails:false,
  noteUpdated:false,
  selectedAthleteDetails:false,
  selectedAthleteGuid:false,
  athleteProfileNutritionListing:false,
  athleteProfileNutritionPagination:false,
  loading: false,
};

export const nutritionSlice = createSlice({
  name: "nutrition",
  initialState,
  reducers: {
    clearNutritionLogDetails: (state, action) => {
      state.nutritionLogDetails = false;
      return state;
    },
    resetLogDetailsUpdated : (state)=>{
      state.logDetailsUpdated = false
      return state
    },
    resetNoteDetailsUpdated: (state)=>{
      state.noteUpdated = false
      return state
    },
    resetNoteDetails:(state)=>{
      state.noteDetails = false
      return state
    },
    setSelectedAthleteGuid:(state,action)=>{
      state.selectedAthleteGuid = action.payload.guid
      return state
    }
  },
  extraReducers: {
    [getAthleteProfileNutritionListing.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [getAthleteProfileNutritionListing.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.listingData) {
          state.athleteProfileNutritionListing = action.payload?.listingData?.data
          state.athleteProfileNutritionPagination = action.payload?.listingData?.pagination
        } else {
          state.athleteProfileNutritionListing = false;
          state.athleteProfileNutritionPagination = false
        }
      return state;
    },
    [getAthleteProfileNutritionListing.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to retrieve nutrition list. Please try again : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [getSelectedAthleteDetails.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [getSelectedAthleteDetails.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.athleteDetails) {
          state.selectedAthleteDetails = action.payload?.athleteDetails
        } else {
          state.selectedAthleteDetails = false;
          toast?.error(
            `Unable to retrieve athlete details. Please try again later.`,
            { toastId: "apiErrorToast" }
          );
        }
      return state;
    },
    [getSelectedAthleteDetails.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to retrieve athlete details. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [insertUpdateInternalNote.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [insertUpdateInternalNote.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.noteUpdated && action.payload?.noteUpdated?.note_id ) {
          state.noteUpdated = action.payload?.noteUpdated?.note_id
          state.noteDetails = action.payload?.noteUpdated
        } else {
          state.noteUpdated = false;
          toast?.error(
            `Unable to ${action.meta.arg?.noteId ? "update":"add"} notes details. Please try again.`,
            { toastId: "apiErrorToast" }
          );
        }
      return state;
    },
    [insertUpdateInternalNote.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch notes details. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [fetchNotesDetails.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchNotesDetails.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.noteDetails) {
          state.noteDetails = action.payload?.noteDetails
        } else {
          state.noteDetails = false;
        }
      return state;
    },
    [fetchNotesDetails.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch notes details. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [fetchInternalNotesListing.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchInternalNotesListing.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.notesListing?.data) {
          state.internalNotesListing = action.payload?.notesListing?.data;
        } else {
          state.internalNotesListing = false;
        }
        if (action.payload?.notesListing?.pagination) {
          state.internalNotesListingPagination = action.payload?.notesListing?.pagination;
        } else {
          state.internalNotesListingPagination = false;
        }
      return state;
    },
    [fetchInternalNotesListing.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch notes listing. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [fetchAthleteDetails.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchAthleteDetails.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.athleteDetails) {
          state.athleteDetails = action.payload?.athleteDetails;
        } else {
          state.athleteDetails = false;
        }
      return state;
    },
    [fetchAthleteDetails.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch athlete details. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [getAthleteWiseReportListing.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [getAthleteWiseReportListing.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.athleteReportListing) {
        state.athleteReportListing = action.payload?.athleteReportListing;
      } else {
        state.athleteReportListing = false;
      }
      if (action.payload?.athleteDetails?.data) {
        state.athleteDetails = action.payload?.athleteDetails.data;
      } else {
        state.athleteDetails = false;
      }
      return state;
    },
    [getAthleteWiseReportListing.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch reports list. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [updateLogDetails.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [updateLogDetails.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.updatedLogDetailsRes !== "-1") {
        state.logDetailsUpdated = true;
        if (action.payload?.nutritionLogDetails) {
          state.nutritionLogDetails = action.payload?.nutritionLogDetails;
        } else {
          state.nutritionLogDetails = false;
        }
      } else {
        state.logDetailsUpdated = false;
        toast?.error(
          `Unable to update log details. Please try again.`,
          { toastId: "apiErrorToast" }
        );
      }
      return state;
    },
    [updateLogDetails.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch log details list. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [getNutritionLogDetails.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [getNutritionLogDetails.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.nutritionLogDetails) {
        state.nutritionLogDetails = action.payload?.nutritionLogDetails;
      } else {
        state.nutritionLogDetails = false;
      }
      return state;
    },
    [getNutritionLogDetails.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch log list. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [fetchNutritionReportLogListing.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchNutritionReportLogListing.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.nutritionReportLogRes) {
        state.reportLogListing = action.payload?.nutritionReportLogRes?.data;
        state.reportLogListingPagination = action.payload?.nutritionReportLogRes?.pagination;
      } else {
        state.reportLogListing = false;
        state.reportLogListingPagination = false;
      }
      return state;
    },
    [fetchNutritionReportLogListing.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch log list. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [fetchNutritionSportsList.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchNutritionSportsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.sportsList = action.payload?.sportsList ? action.payload?.sportsList : false;
      return state;
    },
    [fetchNutritionSportsList.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch sports list. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
  },
});

export const { clearNutritionLogDetails, resetLogDetailsUpdated, resetNoteDetails,resetNoteDetailsUpdated,setSelectedAthleteGuid } = nutritionSlice.actions;
export default nutritionSlice.reducer;
