import { Radio, TableBody, TableCell, TableRow } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ListingTable from "../Common/ListingTableMUI";
import { useDispatch } from "react-redux";
import Footer from "../Common/Footer";
import { selectAthlete } from "../../../reducer/injury.reducer";

function InjuryAthleteContent({ pageNo, setPageNo, pagination, perPage }) {
  const navigate = useNavigate();
  const athleteList = useSelector((state) => state?.injury?.injuryAthleteList);
  let [selectedAthlete, setSelectedAthlete] = useState();
const dispatch = useDispatch()
  const tableHeaders = [
    {
      label: "",
      align: "center",
      stickyClass: "stickyCell-left"
    },
    {
      label: "Athlete Name",
      align: "left",
    },
    {
      label: "Sport",
      align: "left",
    },
    {
      label: "Group",
      align: "left",
    },
  ];

  const handleIndividualSelect = (e, athlete) => {
    e?.stopPropagation();
    setSelectedAthlete({
      ...athlete,
    });
  };

  const getTableBody = () => {
    return (
      <TableBody>
        {athleteList?.length ? (
          athleteList?.map((athlete, idx) => {
            let name = `${athlete?.first_name ? athlete?.first_name : ""} ${
              athlete?.last_name ? athlete?.last_name : ""
            }`;
            let sports = athlete?.sport_name;
            let sportsCount = sports?.length;
            let sportName = sportsCount > 2 ? `${sports?.slice(0, 2)?.join(", ")}` : sports?.join(", ");
            let otherCount = sportsCount > 2 ? `+${sportsCount - 2}` : "";
            let group = athlete?.group_name;
            let groupCount = group?.length;
            let groupName = groupCount > 2 ? `${group?.slice(0, 2)?.join(", ")}` : group?.join(", ");
            let groupOtherCount = groupCount > 2 ? `+${groupCount - 2}` : "";
            return (
              <TableRow
                key={idx}
                selected={selectedAthlete ? selectedAthlete.athlete_id === athlete.athlete_id : false}
              >
                <TableCell
                  align={"left"}
                  className="stickyCell-left"
                  padding="checkbox"
                  // onClick={(e) => handleEdit(e, program.program_id)}
                >
                  <Radio
                    color="warning"
                    // checked={isSelected ? true : false}
                    sx={{ cursor: "pointer"}}
                    checked={selectedAthlete ? selectedAthlete.athlete_id === athlete.athlete_id : false}
                    onClick={(e) => handleIndividualSelect(e, athlete)}
                  />
                </TableCell>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => handleIndividualSelect(e, athlete)}
                >
                  {name ? name : ""}
                </TableCell>
                <TableCell align={"left"}>
                  {sportName ? sportName : "-"}
                  {otherCount && <span className="si-custom-table si-count">{otherCount}</span>}
                </TableCell>
                <TableCell align={"left"}>
                  {groupName ? groupName : "-"}
                  {groupOtherCount && <span className="si-custom-table si-count">{groupOtherCount}</span>}
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  const handleNext = (selectAthlete)=>{
    dispatch(selectAthlete({details:selectedAthlete}))
    navigate(`${selectedAthlete.athlete_id}`)
  }
  return (
    <>
      <div className="si-main-content">
        <ListingTable
          tableHeaders={tableHeaders}
          getTableBody={getTableBody}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pagination={pagination}
          perPage={perPage}
        />
      </div>
      <Footer
        // pageNo={pageNo}
        // setPageNo={setPageNo}
        // pagination={paginationObject}
        // perPage={20}
        isActionBtn={true}
        btnClass={`si-button si-button--green`}
        // btnClass={`si-button ${selectedExerciseList?.length ? "si-button--green" : "si-button--green"}`}
        btnDisabled={selectedAthlete?.athlete_id ? false : true}
        // btnDisabled={!selectedExerciseList?.length ? true : false }
        actionBtnText={"Next"}
        actionBtnHandler={() => handleNext(selectAthlete)}
      />
    </>
  );
}
export default InjuryAthleteContent;
