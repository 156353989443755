import React from 'react'

export default function FixedButton({btnName,onBtnClick,iconClass,btnClass}) {
  return (
    <div className="si-xs-fixed-button">
              <div className="content-wrapper">
              <button className={`si-button si-button--green`} onClick={onBtnClick}>
                <span className={iconClass ? `icon-${iconClass}` : ''}></span>{btnName}</button>
              </div>
    </div>
  )
}
