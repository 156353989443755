import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loaderActions } from '../../../../reducer/loader';
import { fetchAthletePrograms } from '../../../../utility/programApiActions';
import Content from './Content';
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import SubHeader from '../../Common/SubHeader';

function CompletedAthleteProgramListing() {
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const paginationObject = useSelector(state => state?.programs?.paginationObject);
    const userDetails = useSelector(state => state?.auth?.userData);
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    let filterOptions = {
        is_search_filter: false,
        is_search_filter_for_athlete: true,
        is_tab: false
    };

    useEffect(() => {
        const timeOut = searchTerm===null ? 0 : 1000;
        let programListTimeOut = setTimeout(() => {
            dispatch(loaderActions?.showLoader());
            dispatch(
                fetchAthletePrograms({
                    pageNo,
                    activeTab: 3,
                    searchTerm,
                    userguid: userDetails?.user_guid,
                    callback: () => setTimeout(() =>{
                        dispatch(loaderActions?.hideLoader());
                    })
                })
            );
        }, timeOut);

        return () => clearTimeout(programListTimeOut);
    }, [searchTerm]);

    return (
        <Fragment>
            <Header 
                headerData={{
                    heading: "Completed Programs",
                    isHeadingEditable: false
                }}
            />
            <SubHeader 
                filterOptions={filterOptions}
                searchTerm={ searchTerm }
                setSearchTerm={ setSearchTerm }
                pageNo={ pageNo }
                setPageNo={ setPageNo }
                navigate={ navigate }
            />
            <Content 
                pageNo={ pageNo } 
                setPageNo={ setPageNo } 
                pagination={ paginationObject }
                perPage={ 20 }
                searchTerm={ searchTerm }
                activeTab={3}
                subModule='completedprograms'
                backUrl='/snc/completedprograms'
            />
            {/* <Footer 
                pageNo={pageNo} 
                setPageNo={setPageNo} 
                pagination={paginationObject}
                perPage={20}
            /> */}
        </Fragment>
    )
};

export default CompletedAthleteProgramListing;