import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dropdown from '../Dropdown';
import { WithContext as ReactTags } from 'react-tag-input';
import MultiSelectDropdown from '../MultiSelectDropdown';
import { v4 as uuidv4 } from 'uuid';
import { fetchTemoList, fetchUnitsList, fetchTagsList } from '../../../../utility/templateApiActions';

const KeyCodes = {
    comma: 188,
    enter: 13,
    tab: 9
};

function SpecsTab({ activeTab, exerciseToEdit, setExerciseToEdit, isViewOnly, isExerciseMaster, sportSetError, setSportSetError }) {
    const tempoList = useSelector(state => state?.templates?.tempoList);
    const unitsList = useSelector(state => state?.templates?.unitsList);
    const dispatch = useDispatch();

    /**Sports Tags */
    const [selectedSports ,setSelectedSports] = useState([]);
    const [tags, setTags] = useState([]);
    const sportList = useSelector(state => state?.templates?.sportList);
    const tagList = useSelector(state => state?.templates?.tagList);
    const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab];
    const [alreadySelectedOptions , setAlreadySelectedOptions] = useState([]);
    const exerciseNew = {...exerciseToEdit};
    const exerciseTags = exerciseNew?.activity_tags ? exerciseNew?.activity_tags : [];
    const suggestions = tagList?.map((tag) => {
            return {
              id: tag.tag,
              text: tag.tag,
           };
        });

   
    const sportOptions = sportList?.map(obj => {
        return {
            value: obj?.sport_id,
            label: obj?.sport_name
        };
    });

    const getAlreadySelectedSports = ()=>{
        if(exerciseToEdit?.sport_id?.length){
            setAlreadySelectedOptions(sportOptions?.filter((obj)=> exerciseToEdit?.sport_id?.indexOf(obj.value) > -1));
        }
    }

    const getAlreadySelectedTags = ()=>{
        if(exerciseToEdit?.activity_tags?.length){
            setTags(exerciseTags?.map((tag)=>{
                return {
                    id : tag,
                    text:tag
                }
            }));
        }
    }
    
    const sportChangeHandler = (data) => {
        let sport_id = data?.map(obj => obj?.value);
        setExerciseToEdit((prevState)=>{
            return { ...prevState, sport_id}
        });
        setSelectedSports(sport_id);
        setAlreadySelectedOptions([]);
        setSportSetError({
            is_error : false,
            msg:''
        });
    };

    const handleChange = ()=>{
        console.log("change");
    }

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };
    
    const handleAddition = (tag) => {
       setTags((oldtags)=>[...oldtags, tag]);
    };
    
    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setTags(newTags);
    };
    
    const handleTagClick = (index) => {
      console.log('The tag at index ' + index + ' was clicked');
    };

    useEffect(() => {
        dispatch(fetchTagsList(selectedSports));
    }, [selectedSports]);

    useEffect(()=>{
        getAlreadySelectedSports();
    }, []);

    useEffect(()=>{
        if(exerciseToEdit?.activity_tags?.length && !tags?.length){
            getAlreadySelectedTags();
        }
    },[exerciseToEdit?.activity_tags])

    useEffect(()=>{
            let activity_tags = tags?.map(obj => obj?.text);
            console.log("activity_tags: ",activity_tags);
            setExerciseToEdit((prevState)=>{
                return { ...prevState, activity_tags}
            });
    },[tags]);
    /**Sports Tags */

    useEffect(() => {
        if(!tempoList?.length) dispatch(fetchTemoList());
        if(!unitsList?.length) dispatch(fetchUnitsList());
    }, []);

    const tempoChangeHandlerbkp = (data) => {
        console.log("Tempo:",data.value);
        setExerciseToEdit(prevState => { 
            return { ...prevState, tempo: data?.value }
        });
    };

    
    const isPositiveInteger = useMemo(() => (num) => {
        return num > 0 && Number.isInteger(num);
    }, []);
    
    const setsChangeHandler = (e) => {
        setExerciseToEdit(prevState => {
            let value = parseInt(e?.target?.value);
            let sets = isPositiveInteger(value) && value < 1000
                    ? value 
                    : isPositiveInteger(value) && value >= 1000
                    ? prevState?.sets
                    : "";
            return { ...prevState, sets }
        });
        setSportSetError({
            is_error : false,
            msg:''
        });
    };

    const metricUnitsChangeHandler = (type, metricId, unitId, e) => {
        setExerciseToEdit(prevState => {
            let updatedMetricsUnits = prevState?.metrics_units?.map(obj => {
                if(obj?.metric_id === metricId) {
                    if(type==="metric") {
                        var value = parseInt(e?.target?.value);
                        var metricValue = isPositiveInteger(value) && value < 1000
                                ? value 
                                : isPositiveInteger(value) && value >= 1000
                                ? obj?.value
                                : null;
                        return { ...obj, value: metricValue };
                    };
                    return { ...obj, unit_id: parseInt(unitId) };
                };
                return obj;
            });

            return { ...prevState, metrics_units: [ ...updatedMetricsUnits ] }
        });
    };

    const metricUnitChangebkp = (data, metricId) => {
        setExerciseToEdit(prevState => {
            let updatedMetricsUnits = prevState?.metrics_units?.map(obj => {
                if(obj?.metric_id === metricId) {
                    return { ...obj, unit_id: parseInt(data?.value) };
                };
                return obj;
            });

            return { ...prevState, metrics_units: [ ...updatedMetricsUnits ] }
        });
    };

    const metricIsEnableHandler = (metricId, e) => {
        e?.preventDefault();
        setExerciseToEdit(prevState => {
            let updatedMetricsUnits = prevState?.metrics_units?.map(obj => {
                if(obj?.metric_id === metricId) {
                    return { ...obj, is_enabled: obj?.is_enabled === 0 ? 1 : 0 };
                };
                return obj;
            });
            return { ...prevState, metrics_units: [ ...updatedMetricsUnits ] }
        });
    };

    const getMetricValueById = useMemo(() => (id) => {
        let metricObj = exerciseToEdit?.metrics_units?.find(obj => obj?.metric_id==id);
        return metricObj?.value ? metricObj?.value : "";
    }, [exerciseToEdit]);

    const isDisabled = useMemo(() => (id) => {
        let metricObj = exerciseToEdit?.metrics_units?.find(obj => obj?.metric_id==id);
        return metricObj ? !metricObj?.is_enabled : true;
    }, [exerciseToEdit]);

    const getMetricUnitById = useMemo(() => (id) => {
        let metricObj = exerciseToEdit?.metrics_units?.find(obj => obj?.metric_id==id);
        let defaultUnitId = unitsList?.find(obj => obj?.metric_id==id)?.unit?.[0]?.unit_id;
        return metricObj?.unit_id ? metricObj?.unit_id : defaultUnitId;
    }, [exerciseToEdit]);

    console.log(tempoList, unitsList, exerciseToEdit);

    const unitsOptions = (metricId) => {
        let units = unitsList
                        ?.find(obj => obj?.metric_id == metricId)?.unit
                        ?.map(obj => { return { value: obj?.unit_id, label: obj?.unit } });
        return units;
    };

    const tempoOptions = tempoList?.map(obj => {
        return {
            value: obj?.tempo_id,
            label: obj?.name
        };
    });

    const tempoChangeHandler = (data) => {
        setExerciseToEdit(prevState => { 
            return { ...prevState, tempo: data?.value}
        });
    };

    const getReps = () => {
        let repsMetric = exerciseToEdit?.metrics_units?.find(metric => metric?.metric_id==9);
        console.log(isExerciseMaster, exerciseToEdit, repsMetric, "<===============")
        if(isExerciseMaster) return repsMetric ? repsMetric : null;
        return (repsMetric && repsMetric?.is_enabled) ? repsMetric : null;
    };

    return (
        <div className={`si-tab-pane si-tab-pane__editspecs ${ activeTab !== "specs" ? "d-none" : "" }`}>
            {
                !isViewOnly 
                ?
                <>
                {
                    isExerciseMaster && 
                    <div className="content-wrapper si-sportstags">
                        <span className="si-title">Sports &amp; Tags</span>
                        <span class="si-info-text">Fill in the required details</span>

                        <div 
                            className={`custom-label si-sports ${sportSetError?.type===1 && sportSetError?.is_error ? "si-error" : ""}`}
                        >
                            <label htmlFor="sport" className="si-label"><span>*</span>Select Sports</label>
                            <MultiSelectDropdown
                                className="custom-select"
                                placeholder={"Select Sports"}
                                alreadySelectedValues={sportOptions?.filter((obj)=> exerciseToEdit?.sport_id?.indexOf(obj.value) > -1)}
                                options={sportOptions}
                                changeHandler={sportChangeHandler} 
                                dropdownfor="sports"
                            />
                            <span className="si-error-message">{sportSetError?.msg}</span>
                        </div>
                        <div className="form-control si-tagControl">
                            <label htmlFor="tags" className="si-label">Tags</label>
                            <span className="custom-label__text" style={{display:"none"}}>
                                Add multiple tags to define your exercise. eg.- Arms,Abs,Chest
                            </span>
                            <ReactTags
                                tags={tags}
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                // handleDrag={handleDrag}
                                // handleTagClick={handleTagClick}
                                inputFieldPosition="bottom"
                                autocomplete
                                allowDragDrop={false}
                                allowDeleteFromEmptyInput={false}
                                placeholder="Add tag"
                                autofocus={false}
                                // editable
                            />
                        </div>
                    </div>
                }

                <div className="content-wrapper">
                    <div className="si-custom-row">
                        <div className="si-custom-col">
                            <div className="si-label-wrap">
                                <h5 className="label-head">Specifications</h5>
                                <span className="label-info">Fill in the required details</span>
                            </div>
                            <div className="si-wrp">
                                <div className="form-group">
                                    <div 
                                        className={`form-control si-sets 
                                        ${sportSetError?.type===2 && sportSetError?.is_error ? "si-error" : ""}`}
                                    >
                                        <label htmlFor="" className="si-label">sets</label>
                                        <input 
                                            className="si-input si-input-sets" 
                                            type="text"
                                            placeholder="-"
                                            value={exerciseToEdit?.sets ? exerciseToEdit?.sets : ""}
                                            onChange={setsChangeHandler}
                                            disabled={isViewOnly}
                                        />
                                        <span className="si-errormsg">{sportSetError?.msg}</span>
                                    </div>

                                    {
                                        getReps() && 
                                        <div 
                                            className={`form-control si-reps 
                                            ${isDisabled(9) ? "si-Disable" : ""}`}
                                        >
                                            <div className="si-labelwrp">
                                                <label htmlFor="" className="si-label">reps</label>
                                                {
                                                    isExerciseMaster && 
                                                    <span 
                                                        className="si-view" 
                                                        onClick={(e) => metricIsEnableHandler(9, e)}
                                                    ></span>
                                                }
                                            </div>
                                            <input 
                                                id={getReps()?.metric_id}
                                                className="si-input si-input-sets" 
                                                type="text"
                                                placeholder="-"
                                                value={isDisabled(getReps()?.metric_id) ? "" : getMetricValueById(getReps()?.metric_id)}
                                                onChange={(e) => {
                                                    metricUnitsChangeHandler("metric", getReps()?.metric_id, "", e);
                                                }}
                                                disabled={isDisabled(9)}
                                            />
                                        </div>
                                    }
                                    
                                    {
                                        exerciseToEdit?.metrics_units?.filter(obj => (obj?.metric_id!=9 && isExerciseMaster) || (obj?.metric_id!=9 && obj?.is_enabled))?.map((metric,index)=>{
                                            return (
                                                <div className={`form-control ${isDisabled(metric.metric_id) ? "si-Disable" : ""}`} key={metric.id}>
                                                    <div className="si-labelwrp">
                                                        <label htmlFor="" className="si-label">{metric.label}</label>
                                                        {
                                                            isExerciseMaster && 
                                                            <span 
                                                                className="si-view" 
                                                                onClick={(e) => metricIsEnableHandler(metric.metric_id, e)}
                                                            ></span>
                                                        }
                                                    </div>
                                                    <div className="form-control-wrap" disabled={isDisabled(metric.metric_id)}>
                                                        <input 
                                                            id={metric.metric_id}
                                                            className="si-input" 
                                                            type="text"
                                                            placeholder="-" 
                                                            value={isDisabled(metric.metric_id) ? "" : getMetricValueById(metric.metric_id)}
                                                            onChange={(e) => metricUnitsChangeHandler("metric", metric.metric_id, "", e)}
                                                            disabled={isViewOnly}
                                                        />
                                                        <Dropdown 
                                                            value={
                                                                getMetricUnitById(metric.metric_id)
                                                                ? 
                                                                unitsOptions(metric?.metric_id)?.find(obj => obj?.value==getMetricUnitById(metric.metric_id))
                                                                :
                                                                unitsOptions(metric?.metric_id)?.[0]
                                                            }
                                                            options={unitsOptions(metric.metric_id)}
                                                            changeHandler={(data) => metricUnitChangebkp(data, metric?.metric_id)}
                                                            menuPlacement={isExerciseMaster ? "top" : "bottom" }
                                                            isDisabled={isDisabled(metric.metric_id)}
                                                        />
                                                        {/* <select 
                                                            className="custom-select"
                                                            value={getMetricUnitById(metric.metric_id)}
                                                            onChange={(e) => metricUnitsChangeHandler("unit", metric.metric_id, e?.target?.value)}
                                                            disabled={isViewOnly}
                                                        >
                                                            {
                                                                unitsList?.find(unit => unit?.metric_id == metric.metric_id)?.unit?.map(obj => {
                                                                    return <option key={uuidv4()} value={obj?.unit_id}>{obj?.unit}</option>
                                                                })
                                                            }
                                                        </select> */}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className={`form-control si-tempo ${isViewOnly && !exerciseToEdit?.tempo ? "d-none" : ""}`}>
                                <label htmlFor="" className="si-label">tempo</label>
                                <Dropdown 
                                    placeholder={"Select Tempo"}
                                    value={ (tempoOptions && tempoOptions?.find(obj => obj?.value == exerciseToEdit?.tempo)) 
                                            ? tempoOptions?.find(obj => obj?.value == exerciseToEdit?.tempo): null}
                                    options={tempoOptions}
                                    changeHandler={tempoChangeHandler}
                                    isDisabled={isViewOnly}
                                    menuPlacement={isExerciseMaster ? "top" : "bottom" }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="content-wrapper">
                    <div className="si-custom-row">
                        <div className="si-custom-col">
                            <div className='si-label-wrap'>
                                <h5 className="label-head">specs</h5>
                                <span className="label-info">Fill in the required specs info</span>
                            </div>
                            <div className="si-form">
                                <div className="form-control">
                                    <label htmlFor="" className='si-label'>sets</label>
                                    <input 
                                        className="si-input si-input-sets" 
                                        type="text"
                                        value={exerciseToEdit?.sets ? exerciseToEdit?.sets : ""}
                                        onChange={setsChangeHandler}
                                        disabled={isViewOnly}
                                    />
                                </div>

                                <div className={`form-control ${!exerciseToEdit?.tempo ? "d-none" : ""}`}>
                                    <label htmlFor="" className='si-label'>tempo</label>
                                    <Dropdown 
                                        placeholder={"Select Tempo"}
                                        value={tempoOptions?.find(obj => obj?.value == exerciseToEdit?.tempo)}
                                        options={tempoOptions}
                                        changeHandler={tempoChangeHandler}
                                        isDisabled={isViewOnly}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="si-custom-col">
                            <div className="si-label-wrap">
                                <h5 className="label-head">metric and units</h5>
                                <span className="label-info">Fill in the required measurement units info</span>
                            </div>
                            <div className="form-group">
                                {
                                    exerciseToEdit?.metrics_units?.map((metric,index)=>{
                                        return <div className="form-control" key={metric.id}>
                                        <label htmlFor="" className='si-label'>{metric.label}</label>
                                        <div className="form-control-wrap" disabled={isDisabled(metric.metric_id)}>
                                            <input 
                                                id={metric.metric_id}
                                                className="custom-input" 
                                                type="text"
                                                value={isDisabled(metric.metric_id) ? "-" : getMetricValueById(metric.metric_id)}
                                                onChange={(e) => metricUnitsChangeHandler("metric", metric.metric_id, "", e)}
                                                disabled={isViewOnly}
                                            />
                                            <select 
                                                className="custom-select"
                                                value={getMetricUnitById(metric.metric_id)}
                                                onChange={(e) => metricUnitsChangeHandler("unit", metric.metric_id, e?.target?.value)}
                                                disabled={isViewOnly}
                                            >
                                                {
                                                    unitsList?.find(unit => unit?.metric_id == metric.metric_id)?.unit?.map(obj => {
                                                        return <option key={uuidv4()} value={obj?.unit_id}>{obj?.unit}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
                </>
                :
                <div className="content-wrapper si-viewmode">
                    <div className="si-custom-row">
                        <div className="si-custom-col">
                            <div className="si-label-wrap">
                            <h5 className="label-head">specs</h5>
                            {/* <span className="label-info">Fill in the required specs info</span> */}
                            </div>
                            <div className="si-wrp">
                                <div className="form-group">
                                    {
                                        exerciseToEdit?.sets && 
                                        <div className="form-control">
                                            <label htmlFor="" className="si-label">sets</label>
                                            <div className="si-value">{exerciseToEdit?.sets ? exerciseToEdit?.sets : "-"}</div>
                                        </div>
                                    }
                                    {
                                        exerciseToEdit?.metrics_units?.length && 
                                        exerciseToEdit?.metrics_units?.filter(obj => obj?.is_enabled)?.map(metric => {
                                            let metricObj = unitsList?.filter?.(data => data?.metric_id==metric?.metric_id)[0];
                                            let unitObj = metricObj?.unit?.filter?.(data => data?.unit_id==metric?.unit_id)[0];
                                            return (
                                                <div className="form-control" key={metric?.metric_id}>
                                                    <div className="si-labelwrp">
                                                        <label htmlFor="" className="si-label">{metric.label}</label>
                                                    </div>
                                                    <div className="si-value">{`${metric?.value ? metric?.value : "-"} ${unitObj?.unit}`}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className={`form-control si-tempo ${!exerciseToEdit?.tempo ? "d-none" : ""}`}>
                                <label htmlFor="" className="si-label">tempo</label>
                                <div className="si-value">
                                    {tempoOptions?.find(obj => obj?.value == exerciseToEdit?.tempo)?.label}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default SpecsTab;