import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { getSessionName } from '../../../../utility/PsychologyApiActions';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import Header from '../../Common/Header';
import ProgressNotesContent from './ProgressNotesContent';
import Footer from '../../Common/Footer';
import { PSYCHOLOGY_MODULE_ROUTE } from '../../../../utility/constants';
import { toast } from 'react-toastify';
import { loaderActions } from '../../../../reducer/loader';

export default function NewProgressNote() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuth = useSelector((state) => state?.auth?.isAuthenticated);
    const sessionName = useSelector((state) => state?.psychology?.sessionName);
    const sessionDisplayName = useSelector((state) => state?.psychology?.sessionDisplayName);
    const selectedPsychologySessionId = useSelector((state) => state?.psychology?.selectedPsychologySessionId);
    const [sessionDetails, setSessionDetails] = useState({});
    const loading = useSelector((state) => state?.psychology?.loading);
    useEffect(() => {
        if (loading) {
          dispatch(loaderActions.showLoader());
        } else {
          dispatch(loaderActions.hideLoader());
        }
      }, [loading])
    useEffect(() => {
        if (isAuth && id) {
            let payload = {
                case_id: id
            }
            dispatch(getSessionName({ payload }));
        }
    }, [isAuth, id]);

    useEffect(() => {
        if (sessionName) {
            let data = {
                "case_id": id,
                "case_date": new Date(),
                "user_guid": "",
                "notes": "",
                "session_log_id": null,
                "session_name": sessionName
            }
            setSessionDetails(data);
        }
    }, [sessionName]);

    // useEffect(() => {
    //     if (selectedPsychologySessionId) {
    //         toast?.success("Case session addedd successfully.");
    //         navigate(`/${PSYCHOLOGY_MODULE_ROUTE}/case/${id}/progressnotes/${selectedPsychologySessionId}`);
    //     }
    // }, [selectedPsychologySessionId])

    const saveHandler = (session_id)=>{
        if(session_id){
            toast?.success("Case session added successfully.");
            navigate(`/${PSYCHOLOGY_MODULE_ROUTE}/case/${id}/progressnotes/${session_id}`);
        } else {
            toast?.error("Error while saving psychology session details")
        }
      }

      const cancelHandler = ()=>{
        navigate(`/${PSYCHOLOGY_MODULE_ROUTE}/case/${id}/progressnotes`);
      }

    return (
        <Fragment>
            <Header
                headerData={{
                    heading: sessionDisplayName,
                    isHeadingEditable: false,
                    isbackBtn: true,
                    backBtnAction: () => navigate(-1)
                }}
            />
            <ProgressNotesContent isEditMode={true} sessionDetails={sessionDetails} 
            setSessionDetails={setSessionDetails} saveHandler={saveHandler} 
            cancelHandler={cancelHandler}/>
        </Fragment>
    )
}
