import React from 'react'
import { useEffect } from 'react';
import { Fragment } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userManagementActions } from '../../../../../reducer/userManagement';
import { fetchSportsList } from '../../../../../utility/templateApiActions';
import { insertUpdateAthleteGroup } from '../../../../../utility/userManagementApiActions';
import CreateBtn from '../../../Common/CreateBtn';
import Dropdown from '../../../Common/Dropdown';
import Header from '../../../Common/Header'

export default function CreateGroup() {
    const sportList = useSelector(state => state?.templates?.sportList);
  const groupName = useSelector(state => state?.userManagement?.groupName);
  const selectedSportId = useSelector(state => state?.userManagement?.selectedSportId);
  const groupNameError = useSelector(state => state?.userManagement?.groupNameError);
  const selectedSportError = useSelector(state => state?.userManagement?.selectedSportError);
  const groupNameErrorMsg = useSelector(state => state?.userManagement?.groupNameErrorMsg);
  const selectedSportErrorMsg = useSelector(state => state?.userManagement?.selectedSportErrorMsg);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userManagementActions?.setDefault());
    if(!sportList?.length) dispatch(fetchSportsList());

    return () => dispatch(userManagementActions?.setDefault());
  }, []);

  const sportOptions = sportList?.map(obj => {
    return {
        value: obj?.sport_id,
        label: obj?.sport_name
    };
  });

  const changeHandler = (data) => {
      dispatch(userManagementActions?.setSelectedSportId({sportId: data?.value?.toString()}));
  };

  const clickHandler = () => {
    if(groupName && selectedSportId) {
      dispatch(
        insertUpdateAthleteGroup({
          optType: 1,
          isNewGroup: true,
          callback: (groupId) => {
            if(groupId){
              navigate(`/athletegroups/${groupId}`);
              toast.success("Group created successfully.");
            }else{
              toast.error("Unable to create a group. Please try again later.")
            }
          }
        })
      );
    } else {
      console.log("Post Group Errors");
      dispatch(userManagementActions?.setErrors());
    };
  };
  
  return (
    <Fragment>
      <Header 
        headerData={{
          heading: "Athlete Group",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate("/athletegroups")
        }}
      />
      <div className="si-main-content">
        <div className="si-secondStep si-align-center">
          <div className="si-form">
            <div className={`form-control ${groupNameError ? "si-error" : ""}`}>
              <div className="form-group">
                <label htmlFor="name" className="si-label"><span>*</span>Group Name</label>
                <input 
                  className="si-input"
                  type="text" 
                  placeholder="Add group name" 
                  value={ groupName }
                  onChange={ (e) => dispatch(userManagementActions?.setGroupName({name: e.target.value})) }
                />
              </div>
              <span className={ groupNameError ? "si-error-message" : "" }>{ groupNameErrorMsg }</span>
            </div>
            <div className={`form-control ${selectedSportError ? "si-error" : ""}`}>
              <div className="form-group">
                <label htmlFor="selectSport" className="si-label"><span>*</span>Sport</label>
                <Dropdown 
                  placeholder={"Select Sport"} 
                  options={sportOptions}
                  changeHandler={changeHandler}
                />
              </div>
              <span className={ selectedSportError ? "si-error-message" : "" }>{ selectedSportErrorMsg }</span>
            </div>
            <div className="form-control">
                <CreateBtn
                name="Create Group"
                onBtnClick={clickHandler}/>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
