import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../Common/Header';
import Content from './Content';
import Footer from '../../Common/Footer';
import { postTemplateExercises } from '../../../../utility/templateApiActions';
import { templatesActions } from '../../../../reducer/templates';
import { toast } from 'react-toastify';
import FixedButton from '../../Common/FixedButton';
import { useEffect } from 'react';
import { loaderActions } from '../../../../reducer/loader';

function SelectedExerciseList({type}) {
    let { id } = useParams();
    const selectedExerciseList = useSelector(state => type===2 ? state?.programs?.selectedExerciseList : state?.templates?.selectedExerciseList);
    const [pageDetails, setPageDetails] = useState();
    const [headerData, setHeaderData] = useState({
        heading : `Edit ${selectedExerciseList?.length} Exercises`,
        isHeadingEditable :false,
        isbackBtn : true,
        backBtnAction: () => {}
    });
    const { setIsUpdateDisabled } = templatesActions;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        let data = {};
        switch(type){
            case 1:
                data.navigateTo = `/snc/templates`;
                break;
            case 2:
                data.navigateTo = `/snc/programs`;
                break;
        }
        setPageDetails(data);
        window.addEventListener("load", () => navigate(`${data?.navigateTo}/${id}/calendar`));
        return () => window.removeEventListener("load", () => navigate(`${data?.navigateTo}/${id}/calendar`));
    },[type]);

    useEffect(()=>{
        if(pageDetails?.navigateTo){
            setHeaderData({...headerData, backBtnAction:() => navigate(`${pageDetails?.navigateTo}/${id}/calendar/exercisemaster`)})
        }
    },[pageDetails]);

    const actionBtnHandler = () => {
        dispatch(loaderActions?.showLoader());
        dispatch(
            postTemplateExercises({
                id,
                type: type, 
                selectedExerciseList,
                callback: () => {
                    setTimeout(() => {
                        navigate(`${pageDetails.navigateTo}/${id}/calendar`);
                        dispatch(loaderActions?.hideLoader());
                        dispatch(setIsUpdateDisabled(false));
                        toast.success("Exercise added successfully.");
                    });
                }
            })
        );
    };

    return (
        <Fragment>
            <Header headerData={headerData} />
            <Content id={id} type={type} pageDetails={pageDetails} />
            <Footer 
                isActionBtn={true} 
                btnClass={"si-button si-button--green"} 
                actionBtnText={"Add To Calendar"}
                actionBtnHandler={actionBtnHandler}
            />
            {/* <FixedButton btnName="Add To Calendar"
                        onBtnClick={actionBtnHandler}/> */}
        </Fragment>
    );
};

export default SelectedExerciseList;