import React from 'react';
import CircularProgressBar from '../../RightPannel/Common/CircularProgressBar';

export default function ProgressCard({data,goal}) {
  // console.log(data,"<<<data")
  // console.log(goal,"<<<goal")
  return (
    <div className="si-card-maincontent">
    <span className="si-card-percentage si-per-left">
        <span>{data?.pending_rexercises ? data?.pending_rexercises : 0}%</span>
        Pending<br />
        Exercises
    </span>
    <div className="circle">
				<div className="text">
          {console.log(goal?.completed_exercises,"goals")}
					<span className="obtain">{goal?.completed_exercises ? goal?.completed_exercises : 0}</span><span className="slash">/</span><span className="total">{goal?.total_exercises ? goal?.total_exercises : 0}</span>
					<div className="progress-text">
						Today's<br />
						Exercises
					</div>
				</div>
				<div className="CircularProgressbar-wrp">
				   <CircularProgressBar percentage={data?.completed_exercises ? data?.completed_exercises : 0} />
			   </div>
			</div>
    <span className="si-card-percentage si-per-right">
        <span>{data?.completed_exercises ? data?.completed_exercises : 0}%</span>
        Completed<br />
        Exercises
    </span>
</div>
  )
}
