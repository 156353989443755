import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { setPerformanceUpdateAthleteSuccessfulFalse } from "../../../../reducer/tournamentsPerformance.reducer";
import {
  addPerformanceAthletePopup,
  fetchPerformanceAthletePopup,
} from "../../../../utility/tournamentPerformanceApiAction";
import PopupFooter from "../../Common/PopupFooter";
import SubHeader from "../../Common/SubHeader";
import AthletePopupContent from "./AthletePopupContent";

function AddAthletePopup({ closePopup, selectedSports, selectedEvent, setViewMode }) {
  const [searchTerm, setSearchTerm] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const loading = useSelector((state) => state?.tournamentPerformance?.loading);
  const genderLists = useSelector((state) => state?.tournamentEvents?.allGenderList);
  const userData = useSelector((state) => state?.auth?.userData);
  const mappedEvents = useSelector((state) => state?.tournamentPerformance?.mappedEvents);
  const athleteListing = useSelector((state) => state?.tournamentPerformance?.athleteList?.data);
  const athleteListingPagination = useSelector((state) => state?.tournamentPerformance?.athleteList?.pagination);
  const groupList = useSelector((state) => state?.tournamentPerformance?.groupList?.group);
  const updated = useSelector((state) => state?.tournamentPerformance?.updateAthleteSuccessful);

  let dispatch = useDispatch();
  const { tournamentId, performanceId } = useParams();

  useEffect(() => {
    if (updated) {
      setViewMode(false)
      closePopup(false);
      dispatch(setPerformanceUpdateAthleteSuccessfulFalse());
    }
  }, [closePopup, dispatch, setViewMode, updated]);

  useEffect(() => {
    let payload = {
      page_no: pageNo ? pageNo : 1,
      item_number: 20,
      performance_record_id: performanceId,
      sport_id: selectedSports.value,
      group_filter: null,
      // tournament_id: tournamentId,
      event_id: selectedEvent?.event_id,
      user_guid:userData?.user_guid
    };
    dispatch(
      fetchPerformanceAthletePopup({
        payload,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let payload = {
      page_no: pageNo ? pageNo : 1,
      item_number: 20,
      performance_record_id: performanceId,
      sport_id: selectedSports.value,
      group_filter: selectedGroups?.length ? selectedGroups.map((ids) => String(ids)) : null,
      // tournament_id: tournamentId,
      event_id: selectedEvent?.event_id,
      user_guid:userData?.user_guid
    };
    console.log("selectedGroupsIds", selectedGroups, payload);
    dispatch(
      fetchPerformanceAthletePopup({
        payload,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  useEffect(() => {
    if(searchTerm !== null){
      let timeout = null;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        let payload = {
          page_no: pageNo ? pageNo : 1,
        item_number: 20,
        performance_record_id: performanceId,
        sport_id: selectedSports.value,
        group_filter: selectedGroups?.length ? selectedGroups.map((ids) => String(ids)) : null,
        // tournament_id: tournamentId,
        event_id: selectedEvent?.event_id,
        user_guid:userData?.user_guid,
        search: searchTerm
      };
      console.log("selectedGroupsIds", selectedGroups, payload);
      dispatch(
        fetchPerformanceAthletePopup({
          payload,
        })
        );
      }, 1000);
      return () => clearTimeout(timeout);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);
    
    const saveAthlete = (athleteArrays) => {
    let payload = {
      opt_type: 1,
      performance_record_id: Number(performanceId),
      event_id: selectedEvent?.event_id,
      // tournament_id: Number(tournamentId),
      sport_id: selectedSports?.value,
      athlete_guid: athleteArrays?.map((athlete) => athlete?.athlete_guid),
      user_guid: userData?.user_guid,
      te_athlete_map_id: null,
      value: null,
    };
    dispatch(addPerformanceAthletePopup({ payload,id:performanceId }));
  };

  let filterOptions = {
    is_sport_filter: false,
    is_search_filter: true,
    is_tab: false,
    is_group_filter: true,
    is_gender_filter: false,
    is_event_type_filter: false,
    is_desableSport: true,
  };
  return (
    <div className={`si-custom-popup si-popup active `}>
      {loading && <div className="spin"></div>}
      <div className="si-popup-content si-Athletes">
        <div className="si-popup-head">
          <div className="si-popup__title">
            <div className="form-control">
              <input type="text" value="Athletes" className="si-input" placeholder="Athletes" disabled={true} />
            </div>
            <button type="button" className="si-icon__button si-button__close" onClick={() => closePopup(false)}>
              <span className="icon-close"></span>
            </button>
          </div>
          <SubHeader
            filterOptions={filterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectedSports={selectedSports}
            // setSelectedSportIds={ setSelectedSports }
            groupListProps={groupList}
            genderList={genderLists}
            // setSelectedGenderIds={setSelectedGenderIds}
            selectedGroupsIds={selectedGroups}
            setSelectedGroupIds={setSelectedGroups}
            pageNo={pageNo}
            setPageNo={setPageNo}
          />
        </div>

        {/* <div className="si-popup-body"> */}
        <AthletePopupContent
          pageNo={pageNo}
          setPageNo={setPageNo}
          pagination={athleteListingPagination}
          perPage={20}
          // eventListing={eventListing}
          athleteListing={athleteListing}
          athleteListingPagination={athleteListingPagination}
          selectedAthletes={selectedAthletes}
          setSelectedAthletes={setSelectedAthletes}
          mappedEvents={mappedEvents}
          userData={userData}
        />
        <PopupFooter
          isActionBtn={true}
          btnClass={`si-button ${selectedAthletes?.length ? "si-button--green" : "si-button--green"}`}
          isBtnDisabled={!selectedAthletes?.length ? true : false}
          actionBtnText={"Add"}
          actionBtnHandler={() => saveAthlete(selectedAthletes)}
        />
        {/* </div> */}
      </div>
    </div>
  );
}

export default AddAthletePopup;
