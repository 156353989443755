import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { resetSelectedPerformanceId, setPerformanceTournamentDetails } from "../../../../reducer/tournamentsPerformance.reducer";
import { ADMIN_ROLE_ID } from "../../../../utility/constants";
import { fetchSportsList } from "../../../../utility/templateApiActions";
import { getperformancerecordlisting } from "../../../../utility/tournamentPerformanceApiAction";
import FixedButton from "../../Common/FixedButton";
import Header from "../../Common/Header";
import PerformanceListingSubHeader from "./PerformanceListingSubHeader";
import PerformanceTrackerContent from "./PerformanceTrackerContent";

function PerformanceTrackerListing() {
  let [selectedSports, setSelectedSports] = useState([]);
  let [searchTerm, setSearchTerm] = useState(null);
  let [pageNo, setPageNo] = useState(1);
  let performanceLists = useSelector((state) => state?.tournamentPerformance?.performanceLists);
  let paginationObject = useSelector((state) => state?.tournamentPerformance?.performanceListPagination);
  let userData = useSelector((state) => state?.auth?.userData);
  const loading = useSelector((state) => state?.tournamentPerformance?.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSportsList());
    dispatch(
      getperformancerecordlisting({
        sport_id: selectedSports.length ? selectedSports : null,
        page_no: pageNo,
        item_number: 20,
        search: searchTerm ? searchTerm : null,
        athlete_guid: null
      })
    );
    dispatch(resetSelectedPerformanceId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getperformancerecordlisting({
        sport_id: selectedSports.length ? selectedSports : null,
        page_no: pageNo,
        item_number: 20,
        search: searchTerm ? searchTerm : null,
        athlete_guid: null
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSports, dispatch, pageNo]);

  useEffect(() => {
    if(searchTerm !== null){
      let timeout = null;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        dispatch(
          getperformancerecordlisting({
          sport_id: selectedSports.length ? selectedSports : null,
          page_no: pageNo,
          item_number: 20,
          search: searchTerm ? searchTerm : null,
          athlete_guid: null
        })
      );
    }, 1000);
    return () => clearTimeout(timeout);
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleTrackPerformance = (event, tournamentDetails)=>{
   dispatch(setPerformanceTournamentDetails(tournamentDetails))
   navigate(`${tournamentDetails.performance_record_id}`)
  }

  const isAdmin = ()=>{
    return Number(userData?.role_id) === ADMIN_ROLE_ID ? true : false
  }
  return (
    <>
      {loading ? <div className="spin"></div> : null}
      <Header
        headerData={{
          heading: "Tournament Results",
          isHeadingEditable: false,
        }}
      />

      <PerformanceListingSubHeader
        selectedSports={selectedSports}
        setSelectedSports={setSelectedSports}
        pageNo={pageNo}
        setPageNo={setPageNo}
        showBtn = {isAdmin() ? true : false}
        onClick={() => navigate(`selectTournament`)}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div class="si-main-content">
        <PerformanceTrackerContent
          pagination={paginationObject}
          performanceLists={performanceLists}
          isAdmin={isAdmin}
          setPageNo={setPageNo}
          pageNo={pageNo}
          handleTrackPerformance={handleTrackPerformance}
        />
      </div>
      {isAdmin() ? 
      <FixedButton btnName="Record Results" onBtnClick={() => navigate(`selectTournament`)} iconClass="add" />
      :null}
    </>
  );
}

export default PerformanceTrackerListing;
