import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelectDropdown from '../../../Common/MultiSelectDropdown';
import { WithContext as ReactTags } from 'react-tag-input';
import { fetchTechnicalTagsList } from '../../../../../utility/technicalTemplateApiActions';

const KeyCodes = {
    comma: 188,
    enter: 13,
  };
function SportsTagsTab({ activeTab, exerciseToEdit, setExerciseToEdit }) {
    const dispatch = useDispatch();
    const [selectedSports ,setSelectedSports] = useState([]);
    const [tags, setTags] = useState([]);
    const sportList = useSelector(state => state?.technicalTemplates?.sportList);
    const tagList = useSelector(state => state?.technicalTemplates?.tagList);
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const [alreadySelectedOptions , setAlreadySelectedOptions] = useState([]);
    const exerciseNew = {...exerciseToEdit};
    const exerciseTags = exerciseNew?.activity_tags ? exerciseNew?.activity_tags : [];
    const suggestions = tagList?.map((tag) => {
            return {
              id: tag.tag,
              text: tag.tag,
           };
        });

   
    const sportOptions = sportList?.map(obj => {
        return {
            value: obj?.sport_id,
            label: obj?.sport_name
        };
    });

    const getAlreadySelectedSports = ()=>{
        if(exerciseToEdit?.sport_id?.length){
            setAlreadySelectedOptions(sportOptions?.filter((obj)=> exerciseToEdit?.sport_id?.indexOf(obj.value) > -1));
        }
    }

    const getAlreadySelectedTags = ()=>{
        if(exerciseToEdit?.activity_tags?.length){
            setTags(exerciseTags?.map((tag)=>{
                return {
                    id : tag,
                    text:tag
                }
            }));
        }
    }
    
    const sportChangeHandler = (data) => {
        let sport_id = data?.map(obj => obj?.value);
        setExerciseToEdit((prevState)=>{
            return { ...prevState, sport_id}
        });
        setSelectedSports(sport_id);
        setAlreadySelectedOptions([]);
    };

    const handleChange = ()=>{
        console.log("change");
    }

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };
    
    const handleAddition = (tag) => {
       setTags([...tags, tag]);
    };
    
    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setTags(newTags);
    };
    
    const handleTagClick = (index) => {
      console.log('The tag at index ' + index + ' was clicked');
    };

    useEffect(() => {
        dispatch(fetchTechnicalTagsList(selectedSports));
    }, [selectedSports]);

    useEffect(()=>{
        getAlreadySelectedSports();
        getAlreadySelectedTags();
    },[]);

    useEffect(()=>{
            let activity_tags = tags?.map(obj => obj?.text);
            console.log("activity_tags: ",activity_tags);
            setExerciseToEdit((prevState)=>{
                return { ...prevState, activity_tags}
            });
    },[tags]);
    return (
        <div className={`si-tab-pane si-tab-pane__sportstags ${ activeTab !== "sports_tags" ? "d-none" : "" }`} id="">
            <div className="content-wrapper">
                          <div className="custom-label si-sports">
                            <label htmlFor="sports" className="si-label">Select Sports</label>
                          </div>
                          <div className='form-control'>
                            <MultiSelectDropdown
                                className="si-sports"
                                    placeholder={"Select Sports"}
                                    alreadySelectedValues={alreadySelectedOptions}
                                    options={sportOptions}
                                    changeHandler={sportChangeHandler} 
                                    dropdownfor="sports"
                                />
                          </div>
                          <div className="custom-label">
                            <label htmlFor="tags" className="si-label">Tags</label>
                            <span className="custom-label__text">Add multiple tags to define your exercise. eg.- Arms,Abs,Chest</span>
                        </div>
                          <div className="form-control si-tagControl">
                             <div>
                                <ReactTags
                                tags={tags}
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                // handleDrag={handleDrag}
                                // handleTagClick={handleTagClick}
                                inputFieldPosition="bottom"
                                autocomplete
                                allowDragDrop={false}
                                placeholder="Add tag"
                                // editable
                                />
                            </div>  
                          </div>
                        </div>
        </div>
    );
};

export default SportsTagsTab;