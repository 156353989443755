import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchInternalNotesListing } from "../../../../../utility/nutritionApiAction";
import FixedButton from "../../../Common/FixedButton";
import AddViewInternalNote from "./AddViewInternalNote";
import InternalNotesListingContent from "./InternalNotesListingContent";

function InternalNotesNutrition({ athlete_guid, athleteId }) {
  let [pageNo, setPageNo] = useState(1);
  let [searchTerm, setSearchTerm] = useState(null);
  let [notesAddViewMode, setNotesAddViewMode] = useState({ addViewMode: false, noteId: false });
  let loading = useSelector((state) => state.nutrition.loading);
  let notesListing = useSelector((state) => state.nutrition.internalNotesListing);
  let notesListingPagination = useSelector((state) => state.nutrition.internalNotesListingPagination);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let payload = {
      page_no: pageNo,
      item_number: 20,
      user_guid: athlete_guid,
      search: searchTerm,
    };
    dispatch(fetchInternalNotesListing({ payload }));
  }, [pageNo, dispatch, athlete_guid]);

  //   for search
  useEffect(() => {
    if (searchTerm !== null) {
      let notesListTimeOut = setTimeout(() => {
        let payload = {
          page_no: pageNo,
          item_number: 20,
          user_guid: athlete_guid,
          search: searchTerm,
        };
        dispatch(fetchInternalNotesListing({ payload }));
      }, 500);
      return () => clearTimeout(notesListTimeOut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, dispatch]);
  return (
    <>
      {loading ? <div className="spin"></div> : null}
      {notesAddViewMode.addViewMode ? (
        <AddViewInternalNote
          notesAddViewMode={notesAddViewMode}
          athleteId={athleteId}
          setNotesAddViewMode={setNotesAddViewMode}
        />
      ) : !notesListing?.length && searchTerm === null ? (
        <section className="internal-note-section  add-note-section">
          <div className="add-note--title">
            <h3 className="title-text">Notes for Nutritionist</h3>
            <span className="title-note">(These notes will be visible only to nutritionists)</span>
          </div>
          <div className="new-note">
            <button
              type="button"
              className="si-button si-add add-new-report"
              onClick={() => navigate("newNote")}
              // onClick={() => setNotesAddViewMode({ addViewMode: true, noteId: null })}
            >
              <span className="icon-add"></span>ADD NEW NOTE
            </button>
          </div>
        </section>
      ) : (
        <>
          <section className="internal-note-section note-listing-section">
            <div className="add-note--title">
              <h3 className="title-text">Notes for Nutritionist</h3>
              <span className="title-note">(These notes will be visible only to nutritionists)</span>
            </div>
            <div className="si-subHeader ">
              <div className="form-control si-searchbox completed-search">
                <input
                  type="text"
                  placeholder="Search"
                  className="si-input "
                  onChange={(e) => setSearchTerm(e.target.value ? e.target.value.trim() : e.target.value)}
                />
                <span className="si-icon icon-search"></span>
              </div>
              <div className="form-control-wrap"></div>
              <button
                type="button"
                className="si-button si-add add-new-report"
                onClick={() => navigate("newNote")}
                // onClick={() => setNotesAddViewMode({ addViewMode: true, noteId: null })}
              >
                <span className="icon-add"></span>ADD NEW NOTE
              </button>
            </div>
          </section>
          <div className="main-content">
            <InternalNotesListingContent
              notesListing={notesListing}
              pageNo={pageNo}
              setPageNo={setPageNo}
              pagination={notesListingPagination}
              setNotesAddViewMode={setNotesAddViewMode}
            />
          </div>
          <div className="si-xs-fixed-button btn-with-table">
            <div className="content-wrapper">
              <button type="button" className="si-button si-add add-new-report" onClick={() => navigate("newNote")}>
                <span className="icon-add"></span>ADD NEW NOTE
              </button>
            </div>
          </div>
        </>
      )}
      {/* TODO: Need to update footer button */}
      {/* <FixedButton btnName="ADD NEW REPORT" onBtnClick={() => navigate("newNote")} iconclassName="add" /> */}
    </>
  );
}

export default InternalNotesNutrition;
