import { TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loaderActions } from '../../../../../reducer/loader';
import ConfirmationPopup from '../../../Common/ConfirmationPopup';
import ListingTable from '../../../Common/ListingTableMUI';

export default function Content({confirmationPopup,setConfirmationPopup, pageNo, setPageNo, pagination, perPage,
     searchTerm, selectedSports, setIsFiltered,deleteGroupHandler}) {
  const groupList = useSelector(state => state?.userManagement?.groupList);
    const [isActionActive, setIsActionActive] = useState(Array(groupList?.length)?.fill(false));
    const dispatch = useDispatch();
    const navigate = useNavigate();

  useEffect(() => {
    window?.addEventListener("click", handleKeydownEvent);

    return () => window?.removeEventListener("click", handleKeydownEvent);
}, []);

useEffect(() => {
  setIsActionActive(Array(groupList?.length)?.fill(false));
}, [groupList]);

const handleKeydownEvent = (event) => {
  setIsActionActive(Array(groupList?.length)?.fill(false));
};

const handleAction = (e, idx) => {
  e?.stopPropagation();
  setIsActionActive(prevState => {
      let newState = Array(groupList?.length)?.fill(false);
      newState[idx] = !prevState[idx];
      return [ ...newState ];
  });
};

const handleEdit = (e, id) => {
  e?.stopPropagation();
  navigate(`/athletegroups/${id}`)
};

const handleDelete = (e, id) => {
    e?.stopPropagation();
    setConfirmationPopup({
        isShow: true,
        isActionBtns: true,
        warning: "Are you sure you want to delete this group?",
        warningMsg: "",
        cancelHandler: () => setConfirmationPopup({ isShow: false }),
        okHandler: () => {
          dispatch(loaderActions?.showLoader());
          setConfirmationPopup({ isShow: false });
          deleteGroupHandler(id);
        },
      });
};

const tableHeaders = [
  {
      label: "Group Name",
      align: "left"
  },
  {
      label: "Sport",
      align: "left"
  },
  {
      label: "Athletes",
      align: "center"
  },
  {
      label: "",
      align: "center",
      stickyClass: "stickyCell-right" 
  }
];

const getTableBody = () => {
  return (
      <TableBody sx={{backgroundColor:"#181818"}}>
          {
              groupList?.length
              ?
              groupList?.map((group, idx) => {
                  return (
                      <TableRow 
                          key={idx}
                      >
                          <TableCell 
                              align={"left"} 
                              sx={{cursor: "pointer"}}
                              onClick={(e) => handleEdit(e, group.group_id)}
                          >
                              {group?.group_name}
                          </TableCell>
                          <TableCell align={"left"}>
                              {group?.sport_name ? group?.sport_name : '-'}
                          </TableCell>
                          <TableCell align={"center"}>
                              {group?.athletes_count ? group?.athletes_count : 0}
                          </TableCell>
                          <TableCell align={"center"} className="stickyCell-right" sx={{maxWidth:"30px"}}>
                              <div className="si-custom-table__cell si-wrap" style={{margin:"auto"}}>
                                  <button 
                                      type="button" 
                                      className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                                      onClick={(e) => handleAction(e, idx)}
                                  ></button>
                                  <div className="si-action-icons">
                                      <button 
                                          type="button" 
                                          className="si-icon-button"
                                          onClick={(e) => handleEdit(e, group.group_id)}
                                      >
                                          <span className="icon-edit"></span>edit
                                      </button>
                                      <button 
                                          type="button" 
                                          className="si-icon-button"
                                          onClick={(e) => handleDelete(e, group?.group_id)}
                                      >
                                          <span className="icon-delete"></span>delete
                                      </button>
                                  </div>
                              </div>
                          </TableCell>
                      </TableRow>
                  )
              })
              :
              <TableRow className="nodata-tableRow">
                  <TableCell align={"center"} colSpan={tableHeaders.length}>
                      No data found
                  </TableCell>
              </TableRow>
          }
      </TableBody>
  )
};

  return (
    <div className="si-main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={pagination}
                perPage={perPage}
            />
            <ConfirmationPopup 
                isActive={confirmationPopup?.isShow}
                isActionBtn={confirmationPopup?.isActionBtn}
                isActionBtns={confirmationPopup?.isActionBtns}
                warning={confirmationPopup?.warning}
                warningMsg={confirmationPopup?.warningMsg}
                cancelHandler={confirmationPopup?.cancelHandler}
                okHandler={confirmationPopup?.okHandler}
            />
        </div>
  )
}
