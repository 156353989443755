import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Header from '../../Common/Header';
import Tabs from './Tabs';
import { SNC_COACH_ROLE_ID, TECHNICAL_COACH_ROLE_ID,PROFIE_MODULE_ID, ATHELETE_ROLE_ID, ATHLETE_MODULE_ROUTE, PSYCHOLOGY_ROLE_ID} from '../../../../utility/constants';
import { fetchAthleteDetails } from '../../../../utility/userManagementApiActions';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../../reducer/loader';

const tabListOptions = [
    { 
        "name":"General Info",
        "id":"general"
    },
    { 
        "name":"S&C Training",
        "id":"snc"
    },
    { 
        "name":"Technical Training",
        "id":"technical"
    },
    { 
        "name":"Tournaments",
        "id":"tournaments"
    },
    { 
        "name":"Tests & Assessments",
        "id":"test"
    } ,
    { 
        "name":"Psychology",
        "id":"psychology"
    } ,
    { 
        "name":"Injury Tracker",
        "id":"injury"
    } ,
    {
        "name":"Nutrition",
        "id":"nutrition"
    }
];

function AthleteProfile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const urlTab = searchParams?.get("tab");
    const [activeTab, setActiveTab] = useState(null);
    const [tabList, setTabList] = useState(tabListOptions)
    const [userguid, setUserguid] = useState('');
    // const athlete_guid = localStorage?.getItem("guid");
    const isAuth = useSelector(state => state?.auth?.isAuthenticated);
    const athleteDetails = useSelector(state => state?.userManagement?.athleteDetails);
    const userData = useSelector((state) => state?.auth?.userData);
    const loading = useSelector((state) => state?.tournamentPerformance?.loading);

    const athleteList = useSelector(state => (state?.auth?.userData?.role_id==SNC_COACH_ROLE_ID || state?.auth?.userData?.role_id==TECHNICAL_COACH_ROLE_ID)
        ? state?.programs?.athleteListing 
        : state?.templates?.athleteListing);
  
        useEffect(() => {
            if (userData && userData?.role_id == ATHELETE_ROLE_ID) {
              setUserguid(userData?.user_guid);
            }else{
                setUserguid(localStorage?.getItem("guid") ? localStorage?.getItem("guid") : "");
            }
            if(userData && userData?.role_id === String(PSYCHOLOGY_ROLE_ID)){
                setTabList(tabListOptions
                    // .filter((tab)=> tab.id === "general" || tab.id ==="psychology")
                    )
            } else 
            if(userData && userData?.role_id === String(ATHELETE_ROLE_ID)){
                setTabList(tabListOptions)
            }
            else {
                setTabList(tabListOptions.filter((tab)=> tab.id !=="psychology"))
            }
          }, [ userData]);

        useEffect(()=>{
            if(urlTab){
                setActiveTab(urlTab)
            } else
            if(tabList && tabList.length ){
                setActiveTab(tab => tab ? tab : tabList[0].id)
            } 
        }, [tabList, urlTab])


    useEffect(() => {
        if (isAuth && userguid) {
          dispatch(fetchAthleteDetails({
                id : userguid,
            callback: () => setTimeout(() => {
              dispatch(loaderActions?.hideLoader());
            }, 0)
          }));
        };
      }, [isAuth,userguid]);

    // to save active tab when coming back to profile tab
    useEffect(()=>{
        if(activeTab){
            navigate(`?tab=${activeTab}`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeTab])

    return (
        <Fragment>
                  {loading ? <div className="spin"></div> : null}

            <Header 
                headerData={{
                    heading: userData.role_id == ATHELETE_ROLE_ID ? "My Profile" : "Athlete Profile",
                    isHeadingEditable: false,
                    isbackBtn :userData.role_id == ATHELETE_ROLE_ID ? false : true,  
                    backBtnAction: () => navigate(`/${ATHLETE_MODULE_ROUTE}`),    
                }}
            />
            <Tabs 
                details={athleteDetails}
                tabList={tabList}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
        </Fragment>
    );
};

export default AthleteProfile;