import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loaderActions } from '../../../../reducer/loader';
import { fetchAllTests } from '../../../../utility/testAssessmentApiActions';
import FunctionListing from '../../Common/FunctionListing';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import { testAssessmentActions } from '../../../../reducer/testAssessment';

function TestAssessment() {
    const testsList = useSelector((state) => state?.testAssessment?.testsList);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchTestsTimeOut = setTimeout(() => {
            dispatch(loaderActions?.showLoader());
            dispatch(fetchAllTests({
                callback : () => setTimeout(() => {
                    dispatch(loaderActions?.hideLoader());
                })
            }));
        });

        return () => clearTimeout(fetchTestsTimeOut);
    }, []);

    const handleClick = (e, id) => {
        e?.preventDefault();
        dispatch(testAssessmentActions.setAthleteFunction({ id }));
        navigate(`/athleteprofile/${id}/reports`,{ state: { backUrl : location.pathname+'?tab=test' } });
    };

    return (
        <FunctionListing 
            testsList={testsList} 
            handleClick={handleClick}  
            btnText="View Reports"
        />
    );
};

export default TestAssessment;