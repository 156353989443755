import { createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "../reducer/auth";
import API from "./api";

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchSportsList = createAsyncThunk(
  "tournamentListing/fetchSportsList",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      return await getAPIDetails("exercise/getsportslisting");
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchTournamentLevelMaster = createAsyncThunk(
  "tournamentListing/fetchTournamentLevelMaster",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      return await getAPIDetails("common/getournamentlevelmaster");
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getTournamentLists = createAsyncThunk(
  "tournamentListing/getTournamentLists",
  async ({ page_no, searchText, sport_id, item_number, user_id }, { rejectWithValue, dispatch }) => {
    let payloadToSend = {
      page_no: page_no ? page_no : 1,
      search: searchText ? searchText : null,
      sport_id: sport_id ? sport_id : null,
      item_number: item_number ? item_number : 20,
      user_id: user_id ? user_id : null,
    };
    try {
      return await postAPIDetails("common/gettournamentlisting", payloadToSend);
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const updateTournament = createAsyncThunk(
  "tournamentListing/updateTournament",
  async ({ id, startDate, endDate, location, sport_id, name, user_guid, level_id }, { rejectWithValue, dispatch }) => {
    let payloadToSend = {
      id: id ? id : null,
      sport_id: sport_id?.length ? sport_id : [],
      user_guid: user_guid ? user_guid : null,
      tournament_name: name ? name : null,
      location: location ? location : null,
      start_date: startDate ? startDate : null,
      end_date: endDate ? endDate : null,
      level_id: level_id ? level_id : null,
    };
    try {
      return await postAPIDetails("common/insertupdatetournamentdata", payloadToSend);
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const deleteTournament = createAsyncThunk(
  "tournamentListing/deleteTournament",
  async ({ tournament_id, user_guid }, { rejectWithValue, dispatch }) => {
    let payloadToSend = {
      tournament_id: tournament_id ? tournament_id : null,
      userguid: user_guid ? user_guid : null,
    };
    try {
      return await postAPIDetails(
        `/common/deletetournament?tournament_id=${tournament_id}&userguid=${user_guid}`,
        payloadToSend
      );
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);
