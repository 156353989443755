import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { fetchInjruyList } from "../../../../utility/injuryApiActions";
import ListingTable from "../../Common/ListingTableMUI";
import SubHeader from "../../Common/SubHeader";

function InjuryTracker({ activeTab }) {
  let [pageNo, setPageNo] = useState(1);
  let [searchTerm, setSearchTerm] = useState(null);
  let [selectedInjuryStatus, setSelectedInjuryStatus] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const injuryList = useSelector((state) => state?.injury?.injuryList?.data);
  const paginationObject = useSelector((state) => state?.injury?.injuryList?.pagination);
  const athleteDetails = useSelector((state) => state?.userManagement?.athleteDetails);
  const loading = useSelector((state) => state.injury.loading);
  
  let filterOptions = {
    is_program_status_filter: false,
    is_search_filter: true,
    is_search_filter_for_athlete: false,
    is_injury_status_filter: true,
    is_date_range_filter: false,
    is_tag_filter: false,
    is_tab: false,
  };
  useEffect(() => {
    if (athleteDetails?.athlete_guid) {
      let payload = {
        page_no: pageNo,
        item_number: 20,
        search: searchTerm,
        user_guid: athleteDetails?.athlete_guid,
        from_date: null,
        to_date: null,
        is_recovered: selectedInjuryStatus,
      };
      dispatch(fetchInjruyList({ payload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedInjuryStatus, pageNo, athleteDetails]);

  useEffect(() => {
    if (searchTerm !== null) {
      let injuryListTimeOut = setTimeout(() => {
        let payload = {
          page_no: 1,
          item_number: 20,
          search: searchTerm,
          user_guid: athleteDetails?.athlete_guid,
          from_date: null,
          to_date: null,
          is_recovered: selectedInjuryStatus,
        };
        dispatch(fetchInjruyList({ payload }));
      }, 500);
      return () => clearTimeout(injuryListTimeOut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, dispatch]);

  const tableHeaders = [
    {
      label: "Injury Name",
      align: "left",
    },
    
    {
        label: "Injured on",
        align: "left",
    },
    
    {
        label: "Injury Duration",
        align: "left",
    },
    {
        label: "Body Part",
        align: "left",
    },
    {
      label: "Injury Type",
      align: "left",
    },
    {
      label: "",
      align: "right",
      stickyClass: "stickyCell-right",
    },
  ];

  const formatDate = (date) => {
    let formatedDate = date?.split("T")[0]?.split("-").reverse().join("/");
    return formatedDate;
  };
  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {injuryList?.length ? (
          injuryList?.map((injury, idx) => {
            return (
              <TableRow key={uuidv4()}>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                  onClick={(e) => navigate(`/athleteprofile/injuryDetails/${injury?.athlete_injury_id}`)}
                  >
                  {injury?.injury_name}
                </TableCell>

                <TableCell align={"left"}>{injury?.injury_date ? formatDate(injury.injury_date) : "-"}</TableCell>
                <TableCell align={"left"}>
                {(()=>{
                    let textString = "";
                    if(injury?.duration || injury?.duration == "0"){
                      let days = Number(String((injury?.duration % 7))?.split(".")[0])
                      let weeks = Number(String((injury?.duration / 7))?.split(".")[0])
                      if(weeks && injury?.duration > 7){
                        if(weeks > 1){
                          textString += (weeks + " Weeks ")
                        } else {
                          textString += (weeks + " Week ")
                        }
                      }
                      if(days){
                        if(days > 1){
                          textString += (days + " Days")
                        } else {
                          textString += (days + " Day")
                        }
                      }
                      return textString
                    } else {
                      return "-"
                    }
                  })()}
                </TableCell>
                <TableCell align={"left"}>{injury?.body_part_name ? injury?.body_part_name : "-"}</TableCell>
                <TableCell align={"left"}>{injury?.injury_type_name ? injury?.injury_type_name : "-"}</TableCell>

                <TableCell align={"right"} className="stickyCell-right">
                  <button
                    type="button"
                    className="si-viewBtn"
                    onClick={(e) => navigate(`/athleteprofile/injuryDetails/${injury?.athlete_injury_id}`)}
                  >
                    <span className="si-icon__view"></span>
                    <span className="si-txt">View details</span>
                  </button>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  return (
    <>
      {loading ? <div className="spin"></div> : null}
      <div className="si-tab-pane si-tab-pane__Training">
        <SubHeader
          filterOptions={filterOptions}
          OtherClass={"athelete-profile-subheader"}
          pageNo={pageNo}
          setPageNo={setPageNo}
          isBtn={false}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setSelectedInjuryStatus={setSelectedInjuryStatus}
        />
        <div className="si-main-content myprofile-maincontent">
          <ListingTable
            tableHeaders={tableHeaders}
            getTableBody={getTableBody}
            pageNo={pageNo}
            setPageNo={setPageNo}
            pagination={paginationObject}
            perPage={20}
          />
        </div>
      </div>
    </>
  );
}

export default InjuryTracker;
