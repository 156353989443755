import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import InjuryAthleteContent from "./InjuryAthleteContent";
import Header from "../Common/Header";
import SubHeader from "../Common/SubHeader";
import { fetchAllAthletesList, fetchSportsList } from "../../../utility/templateApiActions";
import { fetchCoachAthletesList, fetchGroupListing } from "../../../utility/programApiActions";
import { SNC_COACH_ROLE_ID, TECHNICAL_COACH_ROLE_ID } from "../../../utility/constants";
import { loaderActions } from "../../../reducer/loader";
import { getInjruyAthleteList } from "../../../utility/injuryApiActions";
import { resetSelectedAthlete } from "../../../reducer/injury.reducer";

function SelectAthlete() {
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const isAuth = useSelector((state) => state?.auth?.isAuthenticated);
  const userDetails = useSelector((state) => state?.auth?.userData);
  const paginationObject = useSelector((state) => state?.injury?.injuryAthleteListPagination);
  const loading = useSelector((state) => state?.injury?.loading);
  const dispatch = useDispatch();
  let filterOptions = {
    is_tab: false,
    is_sport_filter: true,
    is_search_filter: true,
    is_group_filter: true,
  };
  useEffect(() => {
    if (isAuth) {
      let timeOut = searchTerm !== null ? 1000 : 0;
      let athleteListTimeOut = setTimeout(() => {
        let payload = {
          page_no: pageNo,
          item_number: 20,
          search: searchTerm,
          opt_type: 1,
          group_filter: selectedGroups?.length ? selectedGroups : null,
          user_guid: userDetails?.user_guid,
          sport_id: selectedSports?.length ? selectedSports : null,
        };
        dispatch(getInjruyAthleteList({ payload }));
      }, timeOut);

      return () => clearTimeout(athleteListTimeOut);
    }
  }, [isAuth, searchTerm, pageNo, selectedGroups, selectedSports, dispatch, userDetails]);

  useEffect(() => {
    dispatch(fetchSportsList());
    dispatch(fetchGroupListing());
    resetSelectedAthlete()
  }, []);

  return (
    <Fragment>
        {loading && <div className="spin"></div>}
      <Header
        headerData={{
          heading: "Select An Athlete",
          isHeadingEditable: false,
          isbackBtn:true,
          backBtnAction:()=>navigate(-1)

        }}
      />
      <SubHeader
        OtherClass="si-completed-subheader"
        filterOptions={filterOptions}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedSports={selectedSports}
        setSelectedSportIds={setSelectedSports}
        selectedGroupsIds={selectedGroups}
        setSelectedGroupIds={setSelectedGroups}
        pageNo={pageNo}
        setPageNo={setPageNo}
        navigate={navigate}
        isBtn={false}
      />
      {/* <div class="si-main-content"> */}
        <InjuryAthleteContent pageNo={pageNo} setPageNo={setPageNo} pagination={paginationObject} perPage={20} />
      {/* </div> */}
    </Fragment>
  );
}

export default SelectAthlete;
