import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { fetchAthleteDashboard } from "../utility/dashboardApiActions";
import {fetchCoachDashboard} from "../utility/dashboardApiActions";
import { fetchCoachProgramPerformanceData } from "../utility/dashboardApiActions";

const initialState = {
   data:{},
   prgramPerformance:{}
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAthleteDashboard?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.data = apiResponse?.data?.data?.value?.data;
            if(callback) callback(true);
        });
        builder.addCase(fetchAthleteDashboard.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            toast?.error(`Unable to retrieve athlete dashboard. Please try again later: \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch Athlete Dashboard Error: ", error);
            if(callback) callback(true);
        });

        builder.addCase(fetchCoachDashboard?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.data = apiResponse?.data?.data?.value?.data;
            if(callback) callback(true);
            return state;
        });

        builder.addCase(fetchCoachDashboard.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            toast?.error(`Unable to retrieve coach dashboard. Please try again later: \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch CaochDashboard Error: ", error);   
            if(callback) callback(true)
        });

        builder.addCase(fetchCoachProgramPerformanceData?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.prgramPerformance = apiResponse?.data?.data?.value?.data;
            if(callback) callback(true);
            return state;
        });

        builder.addCase(fetchCoachProgramPerformanceData.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            toast?.error(`Unable to fetch program performance data. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch CaochDashboard program performance Error: ", error);   
            if(callback) callback(true);
        });
       
    }
});

export const dashboardActions = dashboardSlice?.actions;
export default dashboardSlice?.reducer;  