import React, {useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { format, addWeeks, addDays } from 'date-fns';
import Slider from 'react-rangeslider';
import { fetchTechnicalAthleteNotes } from '../../../../../utility/technicalProgramApiActions';
import { ATHELETE_ROLE_ID } from '../../../../../utility/constants';

const dayShortName = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun"
};

function CalenderHeader({ activeDay, setActiveDay , weekWiseCalendarData, startDate, selectedWeek, setNotesPopup }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { id, userId } = useParams();
    const userData = useSelector(state => state?.auth?.userData);
    let day_progress_mobile = weekWiseCalendarData?.find(obj => obj?.day_no==(activeDay || 1))?.day_progress;

    useEffect(() => {
        if(startDate) {
            let dayToSet = 1;
            Object?.keys(dayShortName)?.forEach(day => {
                let addDay = addDays(new Date(startDate), day - 1);
                let addWeek = addWeeks(addDay, selectedWeek - 1);
                if(format(addWeek, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
                    dayToSet = day;
                };
            });
            setActiveDay(dayToSet);
        };
    }, [startDate, selectedWeek]);

    const notesPopupHandler = (day) => {
        let isEditable = false;
        let addDay = addDays(new Date(startDate), day - 1);
        let addWeek = addWeeks(addDay, selectedWeek - 1);
        if(format(addWeek, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd") && userData?.role_id == ATHELETE_ROLE_ID) {
            isEditable = true;
        };
        dispatch(
            fetchTechnicalAthleteNotes({
                userId, 
                programId: id, 
                week: selectedWeek, 
                day, 
                callback: (data) => {
                    setNotesPopup({
                        isShow: true, 
                        isEditable, 
                        day, 
                        week: selectedWeek,
                        notes: data?.selected_range?.notes,
                        noteId: data?.selected_range?.note_id,
                        emotionId: data?.selected_range?.review_id,
                        optType: (data?.selected_range?.review_id || data?.selected_range?.notes) ? 2 : 1,
                        subtitle: userData?.role_id == ATHELETE_ROLE_ID ? "Add notes for coach to view" : "Notes by Athlete",
                        btnText: userData?.role_id == ATHELETE_ROLE_ID ? "Save" : "Ok"
                    });
                }
            })
        );
    };

    return (
        <div className="si-calheader">
            <div className="si-weekcalender">
                <div className="si-tblheader">
                    {
                        weekWiseCalendarData?.length && 
                        weekWiseCalendarData?.map(({day_no, day_progress, is_note}) => {
                            let currentActiveDay = activeDay ? activeDay : 1; 
                            let isNote = is_note;
                            let day = day_no;
                            let weekDay, date, month, year = null;
                            let isCurrentDay = false;
                            if(startDate) {
                                let addDay = addDays(new Date(startDate), day - 1);
                                let addWeek = addWeeks(addDay, selectedWeek - 1);
                                weekDay = format(addWeek, 'EEE');
                                date = format(addWeek, "dd");
                                month = format(addWeek, "MMM");
                                year = format(addWeek, "yy");
                                if(format(addWeek, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
                                    isCurrentDay = true;
                                };
                            };
                            return (
                                <div 
                                    key={ uuidv4() }
                                    className={
                                        `si-column 
                                        ${currentActiveDay == day ? "active" : ""} 
                                        ${isCurrentDay ? "activeday" : ""}`
                                    }
                                    onClick={ () => setActiveDay(day) }
                                >
                                    {
                                        date 
                                        ? 
                                        <span className="si-day si-fullName">
                                            <span className="si-Daytxt">{weekDay},&nbsp;</span>
                                            <div className="si-daywrp">
                                                <span className="si-dateno">{date} </span>
                                                <span className="si-month">{month}</span>
                                            </div>
                                            <span className="si-year">{year}</span>
                                        </span>
                                        : <span className="si-day si-fullName">Day <span className="si-no">{ day }</span></span>
                                    }
                                    <span className="si-day si-shortName">{weekDay}</span>
                                    <div className={`si-progressbar ${day_progress && day_progress==100 ? "si-green" : day_progress>0 ? "si-red" : ""}`}>
                                        <div className="si-progressInfo">
                                            <div className="si-percent">{day_progress ? day_progress + "%" : "0%"}</div>
                                            <div 
                                                className="si-Addnotes" 
                                                onClick={() => notesPopupHandler(day)}
                                            >
                                                <span className="si-notesicon"></span>
                                                <span className="si-addtxt">Notes</span>
                                            </div>
                                        </div>
                                        <Slider 
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={day_progress ? day_progress : 0}
                                            orientation="horizontal"
                                        />
                                    </div>
                                    {
                                        isCurrentDay && 
                                        <span className="si-indication"></span>
                                    }
                                </div>
                            )
                        })
                    }

                    {/* mobile view */}
                    <div className="si-mobileview">
                        <div className={`si-progressbar ${day_progress_mobile && day_progress_mobile==100 ? "si-green" : day_progress_mobile > 0 ? "si-red" : ""}`}>
                            <div className="si-progressInfo">
                                <div className="si-percent">{day_progress_mobile ? day_progress_mobile + "%" : "0%"}</div>
                                <div 
                                    className="si-Addnotes" 
                                    onClick={() => notesPopupHandler(activeDay || 1)}
                                >
                                    <span className="si-notesicon"></span>
                                    <span className="si-addtxt">Notes</span>
                                </div>
                            </div>
                            <Slider 
                                min={0}
                                max={100}
                                step={1}
                                value={day_progress_mobile ? day_progress_mobile : 0}
                                orientation="horizontal"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CalenderHeader;