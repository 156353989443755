import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../Common/Header';
import SubHeader from '../Common/SubHeader';
import { useDispatch } from 'react-redux';
import { fetchCoachAthletesList, fetchGroupListing } from '../../../utility/programApiActions';
import { fetchSportsList } from '../../../utility/templateApiActions';
import { resetSelectedAthlete, selectAthlete } from '../../../reducer/psychology';
import SelectAthleteContent from './SelectAthleteContent';
import Footer from '../Common/Footer';
import { PSYCHOLOGY_MODULE_ROUTE } from '../../../utility/constants';
import { loaderActions } from '../../../reducer/loader';

export default function SelectAthlete() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const isAuth = useSelector((state) => state?.auth?.isAuthenticated);
  const userDetails = useSelector((state) => state?.auth?.userData);
  const paginationObject = useSelector((state) => state?.programs?.paginationObject);
  const loading = useSelector((state) => state?.programs?.loading);
  let [selectedAthlete, setSelectedAthlete] = useState();
  let filterOptions = {
    is_tab: false,
    is_sport_filter: true,
    is_search_filter: true,
    is_group_filter: true,
  };
  useEffect(() => {
    dispatch(fetchSportsList());
    dispatch(fetchGroupListing());
    resetSelectedAthlete();
  }, []);
  useEffect(() => {
    if (isAuth) {
      let timeOut = searchTerm !== null ? 1000 : 0;
      let athleteListTimeOut = setTimeout(() => {
        let payload = {
          pageNo,
          item_number: 20,
          searchTerm,
          type: 1,
          groupFilter: selectedGroups?.length ? selectedGroups : null,
          userguid: userDetails?.user_guid,
          selectedSports,
        };
        dispatch(fetchCoachAthletesList(payload));
      }, timeOut);

      return () => clearTimeout(athleteListTimeOut);
    }
  }, [isAuth, searchTerm, pageNo, selectedGroups, selectedSports, dispatch, userDetails]);

  useEffect(() => {
    if (loading) {
      dispatch(loaderActions.showLoader());
    } else {
      dispatch(loaderActions.hideLoader());
    }
  }, [loading])

  const handleNext = (selectAthlete) => {
    // dispatch(selectAthlete({ details: selectedAthlete }))
    localStorage.setItem("guid", selectedAthlete.user_guid);
    // navigate(`${selectedAthlete.athlete_id}`)
    navigate(`/${PSYCHOLOGY_MODULE_ROUTE}/${selectedAthlete.athlete_id}/caselog/new`);
  }
  return (
    <Fragment>
      <Header
        headerData={{
          heading: "Select an Athlete",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate(-1)

        }}
      />
      <SubHeader
        OtherClass="si-completed-subheader"
        filterOptions={filterOptions}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedSports={selectedSports}
        setSelectedSportIds={setSelectedSports}
        selectedGroupsIds={selectedGroups}
        setSelectedGroupIds={setSelectedGroups}
        pageNo={pageNo}
        setPageNo={setPageNo}
        navigate={navigate}
        isBtn={false}
      />
      <SelectAthleteContent pageNo={pageNo} setPageNo={setPageNo} pagination={paginationObject} perPage={20} selectedAthlete={selectedAthlete} setSelectedAthlete={setSelectedAthlete} />
      <Footer
        isActionBtn={true}
        btnClass={`si-button si-button--green`}
        btnDisabled={selectedAthlete?.athlete_id ? false : true}
        actionBtnText={"Add"}
        actionBtnHandler={() => handleNext(selectAthlete)}
      />
    </Fragment>
  )
}
