import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchNotesDetails, insertUpdateInternalNote } from "../../../../../utility/nutritionApiAction";
import DatePickerComponent from "../../../Common/DatePickerComponent";
import moment from "moment";
import { resetNoteDetailsUpdated } from "../../../../../reducer/nutrition.reducer";
import { inserUpdateAthleteNotes } from "../../../../../utility/programApiActions";
import { useNavigate } from "react-router-dom";

function AddViewInternalNote({ notesAddViewMode, athleteId, setNotesAddViewMode }) {
  let [viewMode, setViewMode] = useState(false);
  let [noteDetails, setNoteDetails] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  let noteDetailsRedux = useSelector((state) => state.nutrition.noteDetails);
  let noteDetailsUpdated = useSelector((state) => state.nutrition.noteUpdated);
  let userData = useSelector((state) => state?.auth?.userData);

  useEffect(() => {
    if (notesAddViewMode?.noteId && userData?.user_guid) {
      console.log("notesAddViewModeUse", notesAddViewMode);
      dispatch(fetchNotesDetails({ noteId: notesAddViewMode.noteId, user_guid:userData?.user_guid }));
    }
  }, [dispatch, notesAddViewMode, userData]);

  useEffect(() => {
    if (noteDetailsRedux) {
      setNoteDetails(noteDetailsRedux);
    }
  }, [noteDetailsRedux]);

  useEffect(() => {
    if (noteDetailsUpdated) {
      dispatch(resetNoteDetailsUpdated());
      setViewMode(true);
      setNotesAddViewMode({ addViewMode: false, noteId: null })
    }
  }, [noteDetailsUpdated, dispatch, setNotesAddViewMode]);

  const updateNoteDetails = ({ event, key }) => {
    if (key === "date") {
      setNoteDetails((old) => ({ ...old, note_date: moment(event).format("YYYY-MM-DD") }));
    } else if (key === "input") {
      setNoteDetails((old) => ({ ...old, note_data: event.target.value }));
    }
  };

  const submitNoteDetails = () => {
    console.log("noteDetails", noteDetails);
    let payload = {
      note_id: notesAddViewMode.noteId ? notesAddViewMode.noteId : null,
      note_name: noteDetails.note_name,
      user_guid: userData?.user_guid ? userData?.user_guid : null,
      note_data: noteDetails?.note_data,
      athlete_id: athleteId ? athleteId : null,
    };
    console.log("payload", payload);
    dispatch(insertUpdateInternalNote({ payload, noteId: notesAddViewMode.noteId }));
  };
  console.log("notesAddViewMode", notesAddViewMode, noteDetails, noteDetails?.note_data);
  return (
    <>
      <section className="internal-note-section">
        <div className={`editView-mode--wrp ${viewMode ? "view-mode-active" : "edit-mode-active"}`}>
          <div className="note-heading">
            <h3 className="note-heading--text">{noteDetails?.note_name ? noteDetails.note_name : ""}</h3>
            <span className="icon-edit" onClick={() => setViewMode(false)}></span>
          </div>
          <div className="note-subheading">
            <div className="date-wrp">
              <div className="form-group">
                <label className="si-label">Date</label>
                <div className="form-group-wrap  date-form-control">
                  <DatePickerComponent
                    isEditMode={viewMode ? true : false}
                    selectedDate={noteDetails?.note_date ? noteDetails.note_date : new Date()}
                    dateChangeHandler={(event) => {
                      updateNoteDetails({ event, key: "date" });
                    }}
                    // minDate={new Date()}
                  />
                  {/* <input type="date" placeholder="" id="start-date" className="si-input" /> */}
                </div>
              </div>
            </div>
            <span className="writer-name">By: -</span>
          </div>
          <div className="form-control ">
            <label className="si-label">*Notes</label>
            <textarea
              key={noteDetails?.note_id}
              id=""
              name=""
              rows=""
              className="si-input "
              placeholder="Add notes"
              defaultValue={noteDetails?.note_data}
              onChange={(event) => updateNoteDetails({ event: event, key: "input" })}
            />
            <div className="view-notes">{noteDetails?.note_data}</div>
            <span className="si-error-message"></span>
          </div>
        </div>
      </section>
      {viewMode ? null : (
        <div className=" nutrition-footer">
          <button type="button" className="si-button si-icon--add si-clearbtn" onClick={() => {setNotesAddViewMode({ addViewMode: false, noteId: null })}}>
            cancel
          </button>
          <button type="button" className="si-button si-button--green" onClick={submitNoteDetails}>
            save
          </button>
        </div>
      )}
    </>
  );
}

export default AddViewInternalNote;
