import React, { useEffect, useRef, useState } from "react";

function InjuryReportLogCommentUi({
  date,
  isDeleting,
  showConfirmDeletePopup,
  data,
  isRecovered,
  userName,
  time,
  userImgLink,
  textMsg,
  showOptions,
  setShowOptions,
  index,
  onePageMsgCount,
  authUserName,
  authUserGuid
}) {
  const onDelete = (e) => {
    showConfirmDeletePopup({ show: true, data });
  };
  const tagRef = useRef(null);

  useEffect(() => {
      const handleKeydownEvent = () => {
      setShowOptions(false);
    };
    if (index + 1 === onePageMsgCount && tagRef?.current) {
      // tagRef.current.focus()
      tagRef.current.scrollIntoView({ behavior: "instant", block: "start" });
    }
    window?.addEventListener("click", handleKeydownEvent);
    return () => window?.removeEventListener("click", handleKeydownEvent);
  }, [tagRef]);

  return (
    <div className="comment-container" ref={tagRef} tabIndex="0">
      {date || isRecovered ? (
        <div className="comment-date-wrp">
          <div className={`comment-date ${isRecovered ? "marked-recover" : ""}`}>
            {isRecovered ? <span className="marked-recover-text">Marked as recovered, </span> : null}
            <span className="comment-date--day">{date} </span>
            {/* <span className="comment-date--num">, {date?.split(",")[1]}</span> */}
          </div>
        </div>
      ) : null}
      <div className="comment-wrap">
        <div className="athlete-image">
          <img src={`${userImgLink ? userImgLink : "../../images/person.png"}`} />
        </div>
        <div className="comment-inner-wrp">
          <div className="name-time-wrp">
            <div className="atheletes-name">{userName ? userName : "User Name"}</div>
            <span className="comment-time">{isDeleting ? "Deleting" : time ? time : ""}</span>
          </div>
          <div className="comment-action-wrp">
            <div className="comment">{textMsg ? textMsg : ""}</div>
            {isDeleting || !data.sender_guid || !authUserGuid || data.sender_guid !== authUserGuid ? null : (
              <div className="si-custom-table__cell si-wrap">
                <button
                  type="button"
                  className={`si-icon__button icon-options ${
                    showOptions && showOptions === data.injury_log_id ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setShowOptions(data.injury_log_id);
                  }}
                ></button>
                <div className="si-action-icons">
                  {/* <button type="button" className="si-icon-button" onClick={() => onDelete()}>
                    <span className="icon-edit"></span>edit
                  </button> */}
                  <button type="button" className="si-icon-button" onClick={(e) => onDelete(e)}>
                    <span className="icon-delete"></span>delete
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const isEqual = (prevPros, nextProps) => {
  if (prevPros?.date !== nextProps?.date) return false;
  if (prevPros?.showOptions === prevPros?.data?.injury_log_id) return false;
  if (nextProps?.showOptions === nextProps?.data?.injury_log_id) return false;
  if (prevPros?.isDeleting !== nextProps?.isDeleting) return false;
  return true;
};

export default React.memo(InjuryReportLogCommentUi, isEqual);
