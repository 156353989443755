import React, { useEffect, useState } from "react";
import { templatesActions } from "../reducer/templates";
import { useDispatch, useSelector } from "react-redux";
import Logo from "./Heading/Logo";
import Profile from "./Heading/Profile";
import SideMenu from "./LeftPannel/SideMenu";
import Routing from "../routes/Routing";
import TechnicalRouting from "../routes/TechnicalRouting";
import UserManagementRouting from "../routes/UserManagementRouting";
import TestAssessmentRouting from "../routes/TestAssessmentRoute";
import TournamentRouting from "../routes/TournamentRouting";
import {useLocation, useNavigate } from "react-router";
import Cookies from "js-cookie";
import InjuryRoutes from "../routes/InjuryRoutes";
import PsychologyRoutes from "../routes/PsychologyRoutes";
import NutritionRouting from "../routes/NutritionRouting";

function Dashboard() {
  const dispatch = useDispatch();
  let navigate = useNavigate()
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const isActiveOnMob = useSelector((state) => state?.templates?.isActiveOnMob);
  const isLoader = useSelector((state) => state.loader.isLoader);
  const userGuidRedux = useSelector((state)=> state?.auth?.userData?.user_guid);
  let [userGuid, setUserGuid] = useState(null);

  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0,0)
    })
  },[])

  
  useEffect(()=>{
    if(!isAuth && !Cookies.get("userdetails")){
      navigate("/login")
    }
    setUserGuid(userGuidRedux)
  },[isAuth,navigate, userGuidRedux])

  return (
    <div className="si-page-bg">
      <div className="si-main__container si-Program">
        {isLoader && <div className="spin zindex-10005"></div>}
        <Logo />
        {/* mobile view activeIn Leftpanel open,activeOut Leftpanel Out */}
        <div
          className={`si-mobilemenu ${ isActiveOnMob ? "activeIn" : "activeOut"}`}
        >
          {/* mobile view replace si-menuicon into si-close for close icon */}
          <span
            className={isActiveOnMob ? "si-close" : "si-menuicon"}
            onClick={() => dispatch(templatesActions?.setIsActiveOnMob())}
          ></span>
          <Profile />
          <SideMenu userGuid={userGuid} navigate={navigate}/>
        </div>
        <div className="si-rightPart">
          <Routing />
          <TechnicalRouting />
          <UserManagementRouting/>
          <TestAssessmentRouting />
          <TournamentRouting/>
          <InjuryRoutes/>
          <PsychologyRoutes/>
          <NutritionRouting/>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
