import React, { useEffect, memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { fetchSideMenu, sideMenuActions } from '../../reducer/sidemenu';
import { HOME_MODULE_ID} from '../../utility/constants';
import MainMenu from './MainMenu';

function SideMenu({ userGuid, navigate }) {
    const [activeMenu, setActiveMenu] = useState({ isActive: false });
    const isAuth = useSelector(state => state.auth.isAuthenticated);
    const menuList = useSelector((state) => {
        return state?.sideMenu?.menuList
    });

    const isRredirectToHome = useSelector((state) => {
        return state?.sideMenu?.redirectToHome
    });


    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuth && userGuid) {
            dispatch(fetchSideMenu());
        }
    }, [isAuth, userGuid, dispatch]);

    useEffect(() => {
        if (menuList?.length && activeMenu?.menuId) {
            setActiveMenu({ isActive: true, menuId: HOME_MODULE_ID })
            navigate("/")
        }
    }, [menuList]);

    useEffect(() => {
        if (isRredirectToHome) {
            setActiveMenu({ isActive: true, menuId: HOME_MODULE_ID});
            navigate("/");
            dispatch(sideMenuActions?.setHomeRedirect(false));
        }
    }, [isRredirectToHome]);

    useEffect(()=>{
        let menu = menuList.filter((m)=> m.module_id == activeMenu?.menuId)[0];
        console.log(activeMenu,menu);
        if(menu && !menu?.sub_menu_action){
                dispatch(
                sideMenuActions?.setSelectedMenu({
                    id: menu?.menu_id,
                    moduleId: menu?.module_id,
                    subMenuId: '',
                    routeName: ''
                })
            );
        }
    },[activeMenu]);


    return (
        <div className="si-leftPanel">
            <div className="si-menuWrp">
                {
                    menuList?.map((obj, idx) => {
                        return (
                            <MainMenu
                                key={uuidv4()}
                                menuObj={obj}
                                activeMenu={activeMenu}
                                setActiveMenu={setActiveMenu}
                                index={idx}
                                userGuid={userGuid}
                            />

                        )

                    })

                }
            </div>

        </div>
    );
};

const areUserEqual = (prevProps, nextProps) => {
    return prevProps.userGuid === nextProps.userGuid
};

export default memo(SideMenu, areUserEqual);