import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  deleteInjuryLogMsg,
  fetchInjruyList,
  fetchInjruyMasters,
  getInjruyAthleteList,
  getInjruyReportLogs,
  sendInjuryLogMsg,
  updateInjruyDetails,
} from "../utility/injuryApiActions";

const initialState = {
  loading: false,
  bodyParts: null,
  icdInjuryCodes: null,
  injuryTypes: null,
  selectedInjuryDetails: null,
  injuryDetailsUpdated: false,
  injuryAthleteList: null,
  injuryAthleteListPagination: null,
  selectedAthlete: null,
  injuryReportLogs: {
    loading: false,
    logsObj: null,
    pagination: null,
  },
};

export const injurySlice = createSlice({
  name: "injury",
  initialState,
  reducers: {
    resetSelectedInjuryDetails: (state) => {
      state.selectedInjuryDetails = null;
      return state;
    },
    resetInjuryDetailsUpdate: (state) => {
      state.injuryDetailsUpdated = false;
      return state;
    },
    selectAthlete: (state, action) => {
      state.selectedAthlete = action.payload.details;
      return state;
    },
    resetSelectedAthlete: (state, action) => {
      state.selectedAthlete = null;
      return state;
    },
    clearInjuryReportLogs: (state)=>{
      state.injuryReportLogs = initialState.injuryReportLogs
      return state
    }
  },
  extraReducers: {
    [deleteInjuryLogMsg.pending]:(state,action)=>{
      let injuryReportLogId = action.meta.arg?.payload?.injury_log_id
      if(injuryReportLogId && state.injuryReportLogs.logsObj){
        state.injuryReportLogs.logsObj[injuryReportLogId] = {...state.injuryReportLogs.logsObj[injuryReportLogId], isDeleting:true}
        return state
      }
    },
    [deleteInjuryLogMsg.fulfilled]:(state,action)=>{
      let injuryReportLogId = action.payload?.newInjuryLogsMsg?.injury_log_id
      if(injuryReportLogId && state.injuryReportLogs.logsObj){
        delete state.injuryReportLogs.logsObj[injuryReportLogId]
        // let newObj = {...state.injuryReportLogs.logsObj[injuryReportLogId], isDeleting:false}
        return state
      }
    },
    [deleteInjuryLogMsg.rejected]:(state,action)=>{
      let injuryReportLogId = action.meta.arg?.payload?.injury_log_id
      if(injuryReportLogId && state.injuryReportLogs.logsObj){
        state.injuryReportLogs.logsObj[injuryReportLogId] = {...state.injuryReportLogs.logsObj[injuryReportLogId], isDeleting:false}
        toast?.error(`Unable to delete report log. Please try again : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" })
        console.log('injruyReportLogId', injuryReportLogId)
        return state
      }
    },
    [sendInjuryLogMsg.pending]: (state, action) => {
      if(action?.meta?.arg?.is_recovered){
        state.loading = true
      }
      state.injuryReportLogs.logsObj = state.injuryReportLogs.logsObj
        ? { ...state.injuryReportLogs.logsObj, ...action.meta.arg.payload }
        : { ...action.meta.arg.payload };
      return state;
    },
    [sendInjuryLogMsg.fulfilled]: (state, action) => {
      let localMsgKey = action.meta.arg.jsTimeStamp
      let localMsgObj = state.injuryReportLogs.logsObj[localMsgKey];
      let newMsgObj = { ...localMsgObj, ...action.payload.newInjuryLogsMsg };
      delete state.injuryReportLogs.logsObj[localMsgKey];
      state.injuryReportLogs.logsObj = {
        ...state.injuryReportLogs.logsObj,
        [action.payload.newInjuryLogsMsg.injury_log_id]: newMsgObj,
      };
      if (!action.payload?.injuryDetails?.data?.value?.data && action?.meta?.arg?.is_recovered) {
        state.injuryDetailsUpdated = true;
        toast?.error(
          `Unable to fetch injury details. Please try again later: \n${
            action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
          }`,
          { toastId: "apiErrorToast" }
        );
      } else if(action?.meta?.arg?.is_recovered) {
        state.selectedInjuryDetails = action.payload?.injuryDetails?.data?.value?.data;
      }
      if(action?.meta?.arg?.is_recovered){
        state.injuryDetailsUpdated = true;
        state.loading = false
      }
      return state
    },
    [sendInjuryLogMsg.rejected]: (state, action) => {
      let localMsgKey = action.meta.arg.jsTimeStamp
      delete state.injuryReportLogs.logsObj[localMsgKey];
      toast?.error(
        `Unable to send report log. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      if(action?.meta?.arg?.is_recovered){
        state.loading = false
      }
      return state
    },
    [getInjruyReportLogs.pending]: (state, action) => {
      state.injuryReportLogs.loading = true;
      return state;
    },
    [getInjruyReportLogs.fulfilled]: (state, action) => {
      state.injuryReportLogs.loading = false;
      let reportLogsObject = {};
      if (action.payload?.injuryLogs?.data) {
        action.payload?.injuryLogs?.data?.forEach((msgs) => {
          let injuryLogId = msgs.injury_log_id;
          reportLogsObject = {
            [injuryLogId]: { ...msgs },
            ...reportLogsObject,
          };
        });
      }
      if(state.injuryReportLogs.logsObj ){
        reportLogsObject = {...reportLogsObject, ...state.injuryReportLogs.logsObj}
      }
      state.injuryReportLogs.logsObj =  reportLogsObject
      state.injuryReportLogs.pagination = action.payload?.injuryLogs?.pagination
        ? action.payload?.injuryLogs?.pagination
        : false;
      return state;
    },
    [getInjruyReportLogs.rejected]: (state, action) => {
      state.injuryReportLogs.loading = false;
      toast?.error(
        `Unable to fetch report logs. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [getInjruyAthleteList.pending]: (state, action) => {
      state.loading = true;
    },
    [getInjruyAthleteList.fulfilled]: (state, action) => {
      state.loading = false;
      state.injuryAthleteList = action.payload?.athleteList?.value?.data;
      state.injuryAthleteListPagination = action.payload?.athleteList?.value?.pagination;
    },
    [getInjruyAthleteList.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to retrieve athlete list. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [fetchInjruyMasters.pending]: (state, actions) => {
      state.loading = true;
      return state;
    },
    [fetchInjruyMasters.fulfilled]: (state, action) => {
      state.loading = false;
      state.bodyParts = action.payload?.bodyParts?.data?.value?.data;
      state.icdInjuryCodes = action.payload?.icdInjuryCodes?.data?.value?.data;
      state.injuryTypes = action.payload?.injuryTypes?.data?.value?.data;
      if (!action.payload?.injuryDetails?.data?.value?.data && action?.meta?.arg) {
        toast?.error(
          `Unable to fetch injury details. Please try again later : \n${
            action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
          }`,
          { toastId: "apiErrorToast" }
        );
      } else {
        state.selectedInjuryDetails = action.payload?.injuryDetails?.data?.value?.data;
      }
      return state;
    },
    [fetchInjruyMasters.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch injury details. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [updateInjruyDetails.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [updateInjruyDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.injuryDetailsUpdated = true;
      state.selectedInjuryId = action;
      if (!action.payload?.injuryDetails?.data?.value?.data && action?.meta?.arg?.injuryId) {
        toast?.error(
          `Unable to fetch injury details. Please try again later : \n${
            action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
          }`,
          { toastId: "apiErrorToast" }
        );
      } else {
        state.selectedInjuryDetails = action.payload?.injuryDetails?.data?.value?.data;
      }
      return state;
    },
    [updateInjruyDetails.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to save injury details. Please try again. : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
      return state;
    },
    [fetchInjruyList.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchInjruyList.fulfilled]: (state, action) => {
      state.loading = false;
      state.injuryList = action?.payload?.injuryList?.data?.value;
      return state;
    },
    [fetchInjruyList.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(
        `Unable to fetch injury list. Please try again later : \n${
          action?.payload?.error ? action?.payload?.error?.message : action?.error?.message
        }`,
        { toastId: "apiErrorToast" }
      );
    },
  },
});

export const { resetSelectedInjuryDetails,clearInjuryReportLogs, selectAthlete, resetInjuryDetailsUpdate, resetSelectedAthlete } =
  injurySlice.actions;
export default injurySlice.reducer;
