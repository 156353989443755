import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { resetSelectedAthlete } from "../../../reducer/injury.reducer";
import { ATHELETE_ROLE_ID } from "../../../utility/constants";
import { fetchInjruyList } from "../../../utility/injuryApiActions";
import FixedButton from "../Common/FixedButton";
import Header from "../Common/Header";
import SubHeader from "../Common/SubHeader";
import InjuryListingContaint from "./InjuryListingContaint";
import { useSearchParams } from "react-router-dom";

function InjuryListing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchTerm, setSearchTerm] = useState(null);
  let [pageNo, setPageNo] = useState(1);
  const [activeTab, setActiveTab] = useState(null);
  const loading = useSelector((state) => state.injury.loading);
  const userData = useSelector((state) => state?.auth?.userData);
  const injuryList = useSelector((state) => state?.injury?.injuryList?.data);
  const paginationObject = useSelector((state) => state?.injury?.injuryList?.pagination);
  let [searchParams] = useSearchParams();
  let activeTabUrl = searchParams?.get("tab");


  useEffect(() => {
    if (!activeTab && activeTabUrl) {
      setActiveTab(Number(activeTabUrl));
    } else if (!activeTab && !activeTabUrl) {
      setActiveTab(1);
    }
  }, [activeTabUrl, activeTab]);

  useEffect(() => {
    if (activeTab) {
      navigate(`?tab=${activeTab}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (userData?.user_guid && activeTab) {
      let payload = {
        page_no: pageNo,
        item_number: 20,
        search: searchTerm,
        user_guid: userData?.user_guid,
        from_date: null,
        to_date: null,
        is_recovered: activeTab ? activeTab - 1 : null,
      };
      dispatch(fetchInjruyList({ payload }));
      resetSelectedAthlete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, activeTab, pageNo, userData]);

  useEffect(() => {
    if (searchTerm !== null) {
      let injuryListTimeOut = setTimeout(() => {
        let payload = {
          page_no: pageNo,
          item_number: 20,
          search: searchTerm,
          user_guid: userData?.user_guid,
          from_date: null,
          to_date: null,
          is_recovered: activeTab ? activeTab - 1 : null,
        };
        dispatch(fetchInjruyList({ payload }));
      }, 500);
      return () => clearTimeout(injuryListTimeOut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, dispatch]);

  let filterOptions = {
    is_sport_filter: false,
    is_search_filter: true,
    is_tab: true,
    is_group_filter: false,
  };
  return (
    <>
      {loading ? <div className="spin"></div> : null}
      <Header
        headerData={{
          heading: "Injury Tracker",
          isHeadingEditable: false,
        }}
      />
      <SubHeader
        filterOptions={filterOptions}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        // selectedSportIds={ selectedSports }
        // setSelectedSportIds={ setSelectedSports }
        pageNo={pageNo}
        setPageNo={setPageNo}
        navigate={navigate}
        isBtn={true}
        btnName="report an Injury"
        // onBtnClick={() => navigate(`athletes`)}
        onBtnClick={() => Number(userData?.role_id) === ATHELETE_ROLE_ID ? navigate(`details`) : navigate(`athletes`)}
        tab1Text={"Open"}
        tab2Text={"Closed"}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="si-main-content">
        <InjuryListingContaint
          injuryList={injuryList}
          pageNo={pageNo}
          setPageNo={setPageNo}
          userData={userData}
          pagination={paginationObject}
        />
      </div>
      {/* <div className="si-main-content">
        <div className="si-firstStep si-align-center">
          <div className="si-text">
            <span>Click on Report an Injury button to add an injury</span>
          </div>
          <button type="button" className="si-button si-button--green si-icon--add" onClick={() => navigate(`details`)}>
            <span className="icon-add"></span>report an Injury
          </button>
        </div>
      </div> */}
      <FixedButton btnName="report an Injury"         
      onBtnClick={() => Number(userData?.role_id) === ATHELETE_ROLE_ID ? navigate(`details`) : navigate(`athletes`)}
      iconClass="add" />

      {/* <SubHeader
                OtherClass='si-completed-subheader'
                filterOptions={filterOptions}
                searchTerm={ searchTerm }
                setSearchTerm={ setSearchTerm }
                selectedSports={ selectedSports }
                setSelectedSportIds={ setSelectedSports }
                selectedGroupsIds={ selectedGroups }
                setSelectedGroupIds={ setSelectedGroups }
                pageNo={ pageNo }
                setPageNo={ setPageNo }
                navigate={ navigate }
                isBtn={false}
                /> */}
    </>
  );
}

export default InjuryListing;
