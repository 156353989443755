import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AthleteReportListing from '../components/RightPannel/Common/AthleteReportListing/AthleteReportListing';
import ProtectedRoute from './ProtectedRoute';
import FunctionListingData from '../components/RightPannel/Test&Assesment/FunctionListingData';
import AthleteListing from '../components/RightPannel/Test&Assesment/AthleteListing';
import AddNewReport from '../components/RightPannel/Test&Assesment/NewReport/AddNewReport';
import ViewReport from '../components/RightPannel/Test&Assesment/ViewReport/ViewReport';

export default function TestAssessmentRoute() {
    return (
        <Routes>
            <Route 
                exact 
                path="/testfunctions/:id/athletereports" 
                element={ 
                    <ProtectedRoute 
                        Component={AthleteReportListing} 
                        type={3} 
                    />
                }
            />
            <Route 
                    exact 
                    path="/testfunctions" 
                    element={ 
                        <ProtectedRoute 
                            Component={FunctionListingData} 
                        />
                    }
                />
            <Route 
                exact 
                path="/testfunctions/:id/athletelisting" 
                element={ 
                    <ProtectedRoute 
                        Component={AthleteListing} 
                    />
                }
            />
            <Route 
                exact 
                path="/test/athletereport/:id" 
                element={ 
                    <ProtectedRoute 
                        Component={AthleteReportListing} 
                    />
                }
            />
         <Route 
                exact 
                path="/testfunctions/:id/newreport" 
                element={ 
                    <ProtectedRoute 
                        Component={AddNewReport} 
                    />
                }
            />
            <Route 
                exact 
                path="/testfunctions/:id/report/:reportid" 
                element={ 
                    <ProtectedRoute 
                        Component={ViewReport} 
                    />
                }
            />
            <Route 
                exact 
                path="/testfunctions/:id/report/:reportid/edit" 
                element={ 
                    <ProtectedRoute 
                        Component={AddNewReport} 
                    />
                }
            />
            <Route 
                exact 
                path="/testfunctions/:id/report/:reportid/:athleteId" 
                element={ 
                    <ProtectedRoute 
                        Component={ViewReport} 
                    />
                }
            />
        </Routes>
        
    );
};