import React from 'react';
import SubHeader from './SubHeader';
import Content from './Content';
import { v4 as uuidv4 } from 'uuid';

function Tabs({details, tabList, activeTab, setActiveTab}) {

    return (
        <div className="si-tabs si-athleteprofile-tab">
            <SubHeader 
                details={details} 
            />

            <div className="si-tab-list si-athleteprofile">
                {
                    tabList?.map(tab => {
                        return (
                            <div 
                                key={uuidv4()}
                                className={`si-tab-item ${activeTab===tab.id ? "active" : ""}`}
                                onClick={() => setActiveTab(tab.id)}
                            >
                                <span>{tab.name}</span>
                            </div>
                        )
                    })
                }
            </div>
            <Content activeTab={activeTab} details={details} />
        </div>
    );
};

export default Tabs;