import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import CalenderExercies from './CalendarExercies';
import CalenderHeader from './CalendarHeader';
import { format, addWeeks, addDays } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

function ProgressCalendarView({calendarClass, handleDragEnd, navigateTo, startDate, selectedWeek, 
    weekWiseCalendarData, setDayToAddExercise, showExerciseDetails, setNotesPopup, setFeedbackPopup,tempoList,unitsList}) {
    const [activeDay, setActiveDay] = useState(null);

    return (
        <div className={`si-calender ${calendarClass}`}>
            <div className="si-calenderTop">
                <CalenderHeader 
                    activeDay={activeDay} 
                    setActiveDay={setActiveDay} 
                    setDayToAddExercise={setDayToAddExercise}
                    navigateTo={navigateTo}
                    startDate={startDate}
                    selectedWeek={selectedWeek}
                    weekWiseCalendarData={weekWiseCalendarData}
                    setNotesPopup={setNotesPopup}
                />
                <div className="si-calenderBottom">
                    <div className="scroll-pane">
                        <div className="si-tableBox">
                            {
                                weekWiseCalendarData?.length 
                                ?
                                weekWiseCalendarData?.slice()?.sort((obj1, obj2) => obj1.day_no - obj2.day_no)?.map(obj => {
                                    let currentActiveDay = activeDay ? activeDay : 1 ; 
                                    let addDay, addWeek;
                                    let isCurrentDay = false;
                                    if(startDate) {
                                        addDay = addDays(new Date(startDate), obj?.day_no - 1);
                                        addWeek = addWeeks(addDay, selectedWeek - 1);
                                        if(format(addWeek, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd")) {
                                            isCurrentDay = true;
                                        };
                                    };

                                    return (
                                        <DragDropContext key={uuidv4()} onDragEnd={(data) => handleDragEnd(data, obj?.week_no, obj?.day_no)}>
                                            <Droppable droppableId={`calendarExercises_${obj?.week_no}_${obj?.day_no}`}>
                                                {
                                                    (provided) => (
                                                        <div 
                                                            className={
                                                                `si-card__list 
                                                                ${obj?.day_no == currentActiveDay ? "active" : ""}`
                                                            } 
                                                            {...provided?.droppableProps} 
                                                            ref={provided?.innerRef}
                                                        >
                                                            <CalenderExercies 
                                                                day={obj?.day_no}
                                                                exercies={obj?.exercises} 
                                                                tempoList={tempoList}
                                                                unitsList={unitsList}
                                                                showExerciseDetails={showExerciseDetails}
                                                                startDate={startDate}
                                                                selectedWeek={selectedWeek}
                                                                setFeedbackPopup={setFeedbackPopup}
                                                                isCurrentDay={isCurrentDay}
                                                                reviewId={obj?.review_id}
                                                                review={obj?.review}
                                                            />
                                                            {provided?.placeholder}
                                                        </div>
                                                    )
                                                }
                                            </Droppable>
                                        </DragDropContext>
                                    )
                                })
                                :
                                Array(7)?.fill("")?.map(obj => {
                                    return (
                                        <div 
                                            key={uuidv4()} 
                                            className={`si-card__list ${obj?.day_no == activeDay ? "active" : ""}`}
                                        >
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressCalendarView;