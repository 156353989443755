import { TableBody, TableCell, TableRow } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ListingTable from '../../Common/ListingTableMUI';
import { ADMIN_ROLE_ID, SNC_COACH_ROLE_ID, TECHNICAL_COACH_ROLE_ID } from '../../../../utility/constants';

function Content({ pageNo, setPageNo, pagination, perPage }) {
    const navigate = useNavigate();
    const athleteList = useSelector(state => (state?.auth?.userData?.role_id==ADMIN_ROLE_ID)
                                                ?  state?.templates?.athleteListing
                                                : state?.programs?.athleteListing );

    const handleTrackPerformance = (e, id, guid) => {
        e?.stopPropagation();
        localStorage.setItem("guid", guid);
        navigate(`/athleteprofile/${id}`);
    };

    useEffect(()=>{
        localStorage.removeItem("guid");
    },[]);

    // let list = [
    //     {
    //         'user_guid':1,
    //         'name':'Karan Verma',
    //         'sport_name':'Shooting',
    //         'group_name':['Under 18']
    //     },{
    //         'user_guid':2,
    //         'name':'Mayuri Kalokhe',
    //         'sport_name':'Hookey',
    //         'group_name':['Junior']
    //     },{
    //         'user_guid':3,
    //         'name':'Isha Mone',
    //         'sport_name':'Shooting',
    //         'group_name':['Junior']
    //     },{
    //         'user_guid':4,
    //         'name':'Sameer Divate',
    //         'sport_name':'Hookey',
    //         'group_name':['Under 18']
    //     },
    // ];

    const tableHeaders = [
        {
            label: "Athlete Name",
            align: "left"
        },
        {
            label: "Sport",
            align: "left"
        },
        {
            label: "Group",
            align: "left"
        },
        {
            label: "",
            align: "center",
            stickyClass: "stickyCell-right"
        }
    ];

    const getTableBody = () => {
        return (
            <TableBody>
                {
                    athleteList?.length
                    ?
                    athleteList?.map((athlete, idx) => {
                        let name = `${athlete?.first_name ? athlete?.first_name : ""} ${athlete?.last_name ? athlete?.last_name : ""}`;
                        let sports = athlete?.sport_name;
                        let sportsCount = sports?.length;
                        let sportName = sportsCount > 2 ? `${sports?.slice(0, 2)?.join(", ")}` : sports?.join(", ");
                        let otherCount = sportsCount > 2 ? `+${sportsCount - 2}` : "";
                        let group = athlete?.group_name;
                        let groupCount = group?.length;
                        let groupName = groupCount > 2 ? `${group?.slice(0, 2)?.join(", ")}` : group?.join(", ");
                        let groupOtherCount = groupCount > 2 ? `+${groupCount - 2}` : "";
                        return (
                            <TableRow 
                                key={idx}
                            >
                                <TableCell 
                                    align={"left"}
                                    sx={{cursor: "pointer"}}
                                    onClick={(e) => handleTrackPerformance(e, athlete?.athlete_id, athlete?.user_guid)}
                                >
                                    {name ? name : ""}
                                </TableCell>
                                <TableCell align={"left"}>
                                    { sportName ? sportName : "-" }
                                    {
                                        otherCount && 
                                        <span className="si-custom-table si-count">{ otherCount }</span>
                                    }
                                </TableCell>
                                <TableCell align={"left"}>
                                    { groupName ? groupName : "-" }
                                    {
                                        groupOtherCount && 
                                        <span className="si-custom-table si-count">{ groupOtherCount }</span>
                                    }
                                </TableCell>
                                <TableCell align={"right"} className="stickyCell-right">
                                    <button 
                                            type="button" 
                                            className="si-viewBtn"
                                            onClick={(e) => handleTrackPerformance(e, athlete?.athlete_id, athlete?.user_guid)}>
                                        <span className="si-icon__view"></span>
                                        <span className="si-txt">View Profile</span>
                                    </button>
                                       
                                </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={tableHeaders.length}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };
  return (
    <div className="si-main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={pagination}
                perPage={perPage}
            />
        </div>
  )
}
export default memo(Content);