import React, { memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {TableBody, TableRow, TableCell} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListingTable from '../../../Common/ListingTableMUI';

function Content({athleteListing,allreadyAddedAthleteIds, athleteIds, changeHandler, pageNo, setPageNo, pagination, perPage}) {
    const tableHeaders = [
        {
            // label: "checkboxInput",
            align: "left",
            stickyClass: "stickyCell-left"
        },
        {
            label: "Athlete Name",
            align: "left"
        },
        {
            label: "Sport",
            align: "center"
        },
        {
            label: "Group",
            align: "center"
        }
    ];

    const getTableBody = () => {
        return (
            <TableBody sx={{backgroundColor:"#181818"}}>
                {
                    // athleteListing?.filter(obj => obj?.is_selected)?.length
                    // ?
                    // athleteListing?.filter(obj => obj?.is_selected)?.map(athlete => {
                        athleteListing?.length
                        ?
                        athleteListing?.map(athlete => {
                        let name = `${athlete?.first_name ? athlete?.first_name : ""} ${athlete?.last_name ? athlete?.last_name : ""}`;
                        let isSelected = athleteIds?.includes(athlete?.athlete_id);
                        console.log(isSelected,athlete?.athlete_id);
                        // let isSelected = allreadyAddedAthleteIds?.includes(athlete?.athlete_id);
                        let isDisabled = allreadyAddedAthleteIds?.includes(athlete?.athlete_id);
                        console.log(isDisabled,athlete?.athlete_id);
                        return (
                            <TableRow 
                                hover
                                key={uuidv4()}
                                selected={isSelected || isDisabled ? true : false}
                            >
                                <TableCell padding="checkbox" className="stickyCell-left">
                                    <Checkbox 
                                        color="warning"
                                        checked={isSelected || isDisabled ? true : false}    
                                        disabled={isDisabled}
                                        onClick={(e) => changeHandler(e, !isSelected, athlete?.athlete_id)}
                                    />
                                </TableCell>
                                <TableCell 
                                    align={"left"} 
                                    sx={{color: "#fff", minWidth: 150, cursor: "pointer"}}
                                    onClick={(e) => changeHandler(e, !isSelected, athlete?.athlete_id)}
                                >
                                    {name ? name : ""}
                                </TableCell>
                                <TableCell align={"center"} sx={{color: "#fff"}}>
                                    {athlete?.sport_name ? athlete?.sport_name.join(", ") : "-"}
                                </TableCell>
                                <TableCell align={"center"} sx={{color: "#fff"}}>
                                    {athlete?.group_name ? athlete?.group_name.join(", ") : "-"}
                                </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={5} sx={{color: "#fff"}}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };

    return (
        <div className="si-main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={pagination}
                perPage={perPage}
            />
        </div>
    );
};

export default memo(Content);