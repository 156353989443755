import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchTemplateList, fetchSportsList } from '../../../../utility/templateApiActions';
import { createProgramByTemplateId } from '../../../../utility/programApiActions';
import Header from '../../Common/Header';
import SubHeader from '../../Common/SubHeader';
import Content from './Content';
import Footer from '../../Common/Footer';
import { loaderActions } from '../../../../reducer/loader';
import FixedButton from '../../Common/FixedButton';
import { toast } from 'react-toastify';

function SelectExerciseList() {
    const paginationObject = useSelector(state => state?.templates?.paginationObject);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedMenuId = useSelector(state => state?.sideMenu?.selectedMenuId);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedSports, setSelectedSports] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [pageNo, setPageNo] = useState(1);
    const [headerData, setHeaderData] = useState({
        heading: "Select a Program Template",
        isHeadingEditable: false,
        isbackBtn: true,
        backBtnAction: () => navigate("/snc/programs")
    });
    let filterOptions = {
        is_sport_filter: true,
        is_search_filter: true
    };

    useEffect(() => {
        dispatch(fetchSportsList());
    }, []);

    useEffect(() => {
        const searchTimeOut = setTimeout(() => {
            if(searchTerm!==null) dispatch(loaderActions?.showLoader());            
            dispatch(
                fetchTemplateList({
                    pageNo,
                    searchTerm,
                    selectedSports,
                    moduleId: selectedMenuId?.id,
                    callback: () => setTimeout(() =>{
                        dispatch(loaderActions?.hideLoader());
                    })
                })
            );
        }, 1000);

        return () => clearTimeout(searchTimeOut);
    }, [searchTerm]);

    useEffect(() => {
        const searchTimeOut = setTimeout(() => {
            dispatch(loaderActions?.showLoader());
            if(selectedMenuId?.id){
                dispatch(
                    fetchTemplateList({
                        pageNo,
                        searchTerm,
                        selectedSports,
                        moduleId: selectedMenuId?.id,
                        callback: () => setTimeout(() =>{
                            dispatch(loaderActions?.hideLoader());
                        })
                    })
                );
            }
        }, 0);

        return () => clearTimeout(searchTimeOut);
    }, [selectedMenuId,pageNo, selectedSports]);

    const actionBtnHandler = (e) => {
        e.preventDefault();
        console.log(selectedTemplate);
        if(selectedTemplate) {
            // dispatch(loaderActions?.showLoader());
            dispatch(
                createProgramByTemplateId({
                    templateId: selectedTemplate?.program_templateid,
                    callback: (data) => {
                        console.log(data);
                        if(data){
                            let programId = data?.program_id;
                            if(programId){
                                toast?.success("Program created successfully.", {toastId: "program_create_success"});
                                navigate(`/snc/programs/${programId}/calendar`);  
                            }
                        } else{
                            toast.warn("Something went wrong. Please try again later.");
                        };
                        // dispatch(loaderActions?.hideLoader());
                    }
                })
            );
        };
    };

    return (
        <Fragment>
            <Header 
                headerData={headerData}
            />
        
            <SubHeader 
                filterOptions={filterOptions}
                searchTerm={searchTerm} 
                setSearchTerm={setSearchTerm} 
                pageNo={pageNo}
                setPageNo={setPageNo}
                selectedSportIds={selectedSports}
                setSelectedSportIds={setSelectedSports}
                isBtn= {false}
            />
            <Content 
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                pageNo={pageNo} 
                setPageNo={setPageNo} 
                pagination={paginationObject}
                perPage={20}
            />
            <Footer 
                isActionBtn={true}
                btnClass={`si-button ${selectedTemplate ? "si-button--green" : "si-button--green"}`}
                btnDisabled={!selectedTemplate ? true : false }
                actionBtnText={"Next"}
                actionBtnHandler={actionBtnHandler}
            />
        </Fragment>
    );
};

export default SelectExerciseList;