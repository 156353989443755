import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getNutritionLogDetails } from "../../../../utility/nutritionApiAction";

function GroupNutritionPlan({ athleteId, userData }) {
  const dispatch = useDispatch();
  let logDetails = useSelector((state) => state?.nutrition?.nutritionLogDetails);
  let loading = useSelector((state) => state.nutrition.loading);

  useEffect(() => {
    if (athleteId && userData) {
      dispatch(
        getNutritionLogDetails({
          id: null,
          group_id: null,
          type: "1",
          athlete_id: athleteId,
          user_guid: userData?.user_guid,
        })
      );
    }
  }, [dispatch, athleteId, userData]);

  return (
    <>
      {loading ? <div className="spin"></div> : null}
      <div className="groupNutrition-plan-main">
        {logDetails && logDetails?.length
          ? logDetails.map((log) => {
              return (
                <div key={log.group_id} className="groupNutrition-plan-wrp">
                  <div className="groupNutrition-content">
                    <h4 className="groupNutrition-content--title">
                      <span className="title-text">Dietary Recommendations </span>
                      <span className="additional-text">({log.group_name})</span>
                    </h4>
                    <div className="groupNutrition-innercontent">
                      <div className="date-name-wrp">
                        <div className="form-group">
                          <div className="form-group-wrap si-location date-form-control">
                            <label className="si-label">Recommended on</label>
                          <div class="view-date">
                            <span class="icon-calender"></span>
                            <span class="date">{log?.log_date?.split("T")[0]?.split("-").reverse()?.join("/")}</span>
                          </div>
                            {/* <input type="date" placeholder="Add Location" id="start-date" className="si-input" /> */}
                          </div>
                        </div>
                        <div className="Recommended-wrp">
                          <div className=" icon-label-wrp">
                            <span className="icon-doctor-bag"></span>
                            <label className="si-label">Recommended by</label>
                          </div>
                          <span className="doctor-name  doctor-name-view">{log?.updated_by ? log.updated_by : ""}</span>
                        </div>
                      </div>
                      <span className="si-label">Recommendation</span>
                      <div className="groupNutrition-textarea">{log?.log_data}</div>
                    </div>
                  </div>
                </div>
              );
            })
          : <div className="nodata-active">
              <div className="nodata-img-wrp">
                  <img src={process.env.PUBLIC_URL + "/images/nodata-found.svg"} alt="no data found img"/>
              </div>
            <div className="nodata-text">No data found</div>
            </div>}
      </div>
    </>
  );
}

export default GroupNutritionPlan;
