import React, { useEffect, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SubMenuTitle from './SubMenuTitle';

function SubMenu({subMenu, menuId, moduleId, index, activeMenu}) {
  
  return (
    <div className="si-submenu">
        <ul>
            {
              subMenu?.map(obj => {
                    return <SubMenuTitle 
                              key={uuidv4()} 
                              subMenuObj={obj} 
                              menuId={menuId} 
                              moduleId={moduleId}
                              index={index}
                              activeMenu={activeMenu}
                            />
                })
            }
        </ul>
    </div>
  );
};

export default memo(SubMenu);