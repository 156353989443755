import React from 'react';
import { useSelector } from 'react-redux';
import NotesMediaTab from '../../../TechnicalTraining/Common/EditExercise/NotesMediaTab';
import RecurrenceTab from '../../../TechnicalTraining/Common/EditExercise/RecurrenceTab';
import SpecsTab from '../../../TechnicalTraining/Common/EditExercise/SpecsTab';
import SportsTagsTab from '../../../TechnicalTraining/Common/EditExercise/SportsTagsTab';
import Tablists from '../../../TechnicalTraining/Common/EditExercise/Tablists';

function ExerciseBody({ activeTab, setActiveTab, startDate, exerciseToEdit, setExerciseToEdit, setWarningPopup, tabLists , type, isViewOnly, isExerciseMaster, sportSetError, setSportSetError }) {
    const selectedWeek = useSelector(state => state?.technicalTemplates?.selectedWeek);
    const selectedProgramWeek = useSelector(state => state?.technicalPrograms?.selectedWeek);
    return (
        <div className="si-popup-body">
            <div className="si-custom-tabs">
                <Tablists 
                    activeTab={ activeTab } 
                    setActiveTab={ setActiveTab }
                    exerciseToEdit={ exerciseToEdit }
                    setWarningPopup={ setWarningPopup }
                    tabLists={ tabLists }
                    isViewOnly={isViewOnly}
                />
                <div className="si-tab-content">
                    {activeTab && activeTab === 'specs' &&
                    <SpecsTab 
                        activeTab={activeTab} 
                        exerciseToEdit={exerciseToEdit}
                        setExerciseToEdit={setExerciseToEdit}
                        isViewOnly={isViewOnly}
                        isExerciseMaster={isExerciseMaster}
                        sportSetError={sportSetError}
                        setSportSetError={setSportSetError}
                    />
                    }
                     {activeTab && activeTab === 'recurrence' &&
                    <RecurrenceTab 
                        activeTab={activeTab} 
                        exerciseToEdit={exerciseToEdit}
                        setExerciseToEdit={setExerciseToEdit}
                        selectedWeek={type === 1 ? selectedWeek : selectedProgramWeek}
                        startDate={startDate}
                        type={type}
                    />}
                     {activeTab && activeTab === 'notes_media' &&
                    <NotesMediaTab 
                        activeTab={activeTab} 
                        exerciseToEdit={exerciseToEdit}
                        setExerciseToEdit={setExerciseToEdit}
                        isViewOnly={isViewOnly}
                    />}
                    {activeTab && activeTab === 'sports_tags' &&
                    <SportsTagsTab 
                        activeTab={activeTab} 
                        exerciseToEdit={exerciseToEdit}
                        setExerciseToEdit={setExerciseToEdit}
                    />}
                </div>
            </div>
        </div>
    );
};

export default ExerciseBody;