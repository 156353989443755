import React, { useEffect, useState } from 'react'
import PopupFooter from './PopupFooter';

function InjuryRecoveredTextBoxPopup({
    isActive,
    isEditable,
    title,
    subtitle,
    showCloseIcon,
    noteDescription,
    isActionBtns,
    actionBtnText,
    actionBtnHandler,
    closeHandler,
  }) {
    const [notes, setNotes] = useState(null);
  
    useEffect(() => {
      setNotes(noteDescription?.trim() ? noteDescription?.trim() : null);
    }, [isActive, noteDescription]);
  
    return (
      <div className={`si-popup ${isActive ? "active" : ""}`}>
        <div className="si-popup-content si-notes marked-recovered-popup">
         {showCloseIcon ?  <button type="button" className="si-icon__button si-button__close" onClick={closeHandler}>
            <span className="icon-close"></span>
          </button>
          :
          null}
          <div className="si-popup-body">
            <div className="content-wrapper">
              <div className="content-title">
                <h5>{title}</h5>
              </div>
              <div className="si-subTitle">{subtitle}</div>
  
              <div className="content-description">
                <textarea
                  className="si-txtArea"
                  disabled={!isEditable}
                  value={notes ? notes : ""}
                  onChange={(e) => setNotes(e?.target?.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <PopupFooter
            isActionBtns={isActionBtns}
            btn1Class={"si-action-btn"}
            actionBtn1Text={"Cancel"}
            actionBtn1Handler={closeHandler}
            btn2Class={"si-action-btn"}
            actionBtn2Text={actionBtnText}
            actionBtn2Handler={() => actionBtnHandler(notes?.trim())}
          />
        </div>
      </div>
    );
  }

export default InjuryRecoveredTextBoxPopup