import React from "react";
import { Fragment } from "react";
import Header from "../../Common/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import TableHeader from "./TableHeader";
import Test from "./Test";
import { loaderActions } from "../../../../reducer/loader";
import { getReportTemplate, getSubmittedReport, insertUpdateNewReport } from "../../../../utility/testAssessmentApiActions";
import { fetchAthleteDetails } from "../../../../utility/userManagementApiActions";
import { current } from "@reduxjs/toolkit";
import ReportHeader from "./ReportHeader";
import UploadMediaPopup from "../../Common/UploadMediaPopup";
import ConfirmationPopup from "../../Common/ConfirmationPopup";
import moment from "moment";
import { REMARK_ID } from "../../../../utility/constants";
import { toast } from "react-toastify";
import DatePickerComponent from "../../Common/DatePickerComponent";
import { testAssessmentActions } from "../../../../reducer/testAssessment";
import { userManagementActions } from "../../../../reducer/userManagement";

const AddNewReport = (from) => {
  const { id } = useParams();
  const { reportid } = useParams();
  const { state } = useLocation();
  const [heading, setHeading] = useState("");
  const [reportDate, setReportDate] = useState('');
  const [newReport, setNewReport] = useState();
  const [isShow, setIsShow] = useState(false);
  const [isEditReport, setIsEditReport] = useState(reportid ? true : false);
  const [confirmationPopup, setConfirmationPopup] = useState({ isShow: false });
  const [error, setError] = useState();
  // for upload files
  const [file, setFile] = useState([]);
  let [isTemplateNumber, setIsTemplateNumber] = useState(false);
  const [additionalKey, setAdditionalKey] = useState('');
  const [additionalValue, setAdditionalValue] = useState('');
  const { backUrl } = state ? state : '';
  // console.log(backUrl);
  const athlete_guid = localStorage?.getItem("guid");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state?.auth?.isAuthenticated);
  const athleteDetails = useSelector(state => state?.userManagement?.athleteDetails);
  const reportDetails = useSelector((state) => state?.testAssessment?.reportDetails);
  const [reportTemplateDetails, setReportTemplateDetails] = useState(null)
  const reportTemplateDetailsRedux = useSelector(
    (state) => state?.testAssessment?.reportTemplateDetails
  );

  const getNavigation = (from) => {
    let navigateTo = backUrl ? backUrl : `/testfunctions/${id}/athletereports`;
    if (from === "profile") {
      navigateTo = `/testfunctions/${id}/athletelisting`;
    }
    dispatch(testAssessmentActions.clearReportTemplateDetails())
    dispatch(userManagementActions.resetAthleteDetails())
    navigate(navigateTo);
  };

  useEffect(()=>{
    if(reportid && reportDetails){
      setReportTemplateDetails(reportDetails)
      if(reportDetails?.file_name){
        setFile(reportDetails.file_name)
      }
      if(reportDetails?.additional_information?.value){
        setAdditionalValue(reportDetails?.additional_information?.value)
      }
    } else if(reportTemplateDetailsRedux){
      setReportTemplateDetails(reportTemplateDetailsRedux)
    }
  },[reportDetails, reportTemplateDetailsRedux, reportid])

  useEffect(() => {
    // if (reportTemplateDetails) {
    setHeading(
          reportTemplateDetails?.report_name 
              ? reportTemplateDetails?.report_name 
              : reportTemplateDetails?.test_name 
              ? reportTemplateDetails?.test_name 
              : ""
            );
    setReportDate(
          reportTemplateDetails?.conducted_date 
            ? moment(reportTemplateDetails?.conducted_date).format("YYYY-MM-DD") 
            : moment(new Date()).format("YYYY-MM-DD")
          );
    console.log("reportTemplateDetails--->", reportTemplateDetails);
    setNewReport({
      ...newReport,
      "test_name": reportTemplateDetails?.test_name ? reportTemplateDetails?.test_name : heading ? heading : '',
      "conducted_date": reportTemplateDetails?.conducted_date 
                          ? reportTemplateDetails?.conducted_date
                          : reportDate ? reportDate : "",
      "athlete_guid": athlete_guid,
      "function_id": id,
      "file_name": reportTemplateDetails?.file_name ? reportTemplateDetails?.file_name :[],
      "opt_type": 1,
      "sport_id": reportTemplateDetails?.sport_id 
                    ? reportTemplateDetails?.sport_id
                    : athleteDetails?.sport_id 
                    ? athleteDetails?.sport_id[0] 
                  : '',
      "test_reports_id": reportTemplateDetails?.test_reports_id 
                          ? reportTemplateDetails?.test_reports_id
                          : 0,
                         ... reportTemplateDetails
    });
    setTimeout(() => {
      createNewReportObject();
    }, 1000);

    // }
  }, [reportTemplateDetails]);

  const createNewReportObject = () => {
    if(!reportid){
      // setNewReport(prevState => {
      //   return { ...prevState, 'tests': subTest, 'additional_information': { key: '', value: '' } }
      //   // return { ...prevState, 'tests': subTest}
      // });
    let subTest = [];
    reportTemplateDetails?.tests?.map((report) => {
      let test = {
        id: report.id,
        tests_parameter: [],
      };
      report?.tests_parameter?.map((param) => {
        let parameters = {
          parameter_id: param.parameter_id
        }
        reportTemplateDetails?.test_headers.map((header) => {
          if (header.type != 'label' && header.id != REMARK_ID) parameters[header.key] = param[header.key] ? param[header.key] : "";
        });
        test.tests_parameter.push(parameters);
      });
      subTest.push(test);
    });
    setNewReport(prevState => {
      return { ...prevState, 'tests': subTest, 'additional_information': { key: '', value: '' } }
      // return { ...prevState, 'tests': subTest}
    });
  }
  }

  useEffect(() => {
    setNewReport(prevState => {
      return { ...prevState, 'test_name': heading ? heading : "", 'conducted_date': reportDate }
    });
  }, [heading, reportDate]);

  //third step
  useEffect(() => {
    if (athlete_guid) {
      dispatch(fetchAthleteDetails({
        id: athlete_guid,
        callback: () => setTimeout(() => {
          dispatch(loaderActions?.hideLoader());
        }, 0)
      }));
    };
  }, [athlete_guid]);

  useEffect(() => {
    if (athleteDetails && !isTemplateNumber) {
      // console.log("hittt-----------",reportTemplateDetails, athleteDetails)
      let addNewReportTimeout = setTimeout(() => {
        getReportTemplateDetails();
        setIsTemplateNumber(true)
        return () => clearTimeout(addNewReportTimeout);
      }, 0);
    }
  }, [athleteDetails, isTemplateNumber]);

  const getReportTemplateDetails = () => {
    dispatch(loaderActions?.showLoader());
    if(reportid){
      dispatch(
        getSubmittedReport({
          sportId: [],
          athlete_guid: "",
          functionId: id,
          reportId: reportid,
          callback: () =>
            setTimeout(() => {
              dispatch(loaderActions?.hideLoader());
            }),
        })
      );
    } else{
      dispatch(
        getReportTemplate({
        sportId: athleteDetails?.sport_id ? athleteDetails?.sport_id : [],
        athlete_guid: athleteDetails?.athlete_guid,
        functionId: id,
        reportId: null,
        callback: () => setTimeout(() => {
          dispatch(loaderActions?.hideLoader());
        })
      })
      );
    }
  }

  useEffect(() => {
    if (newReport) {
      console.log("New Report->", newReport);
    }
  }, [newReport]);

  useEffect(() => {
    setNewReport(prevState => {
      console.log(prevState);
      return { ...prevState, 'additional_information': { key: additionalKey, value: additionalValue } }
    })
  }, [additionalKey, additionalValue])

  const clearReport = () => {
    setConfirmationPopup({
      isShow: true,
      isActionBtns: true,
      warning: "Are you sure you want to clear this data?",
      warningMsg: "",
      cancelHandler: () => setConfirmationPopup({ isShow: false }),
      okHandler: () => {
        setConfirmationPopup({ isShow: false });
        createNewReportObject();
      }
    });
  }

  const openPopupHandler = () => {
    setIsShow(!isShow);
  }

  const closePopupHandler = (event) => {
    setIsShow(current => !current);
  }

  const addNewReport = (newReport) => {
    if (!(newReport.athlete_guid)) return toast.error("Athlete ID is missing")
    if (!(newReport?.test_name.trim())) return toast.error("Please add test name")
    if (!(newReport.conducted_date)) return toast.error("Please select test date")
    if (Number(newReport.conducted_date.split("-").join("")) > Number(moment(new Date()).format("YYYY-MM-DD").split("-").join(""))) return toast.error("Future date is not allowed.")
    newReport.file_name = file && file.length ? file.map((files) => files.name ? files.name : files? files :null).filter((fileNames)=> fileNames != null) : []
    // console.log("filename", newReport)
    if(reportid){
      newReport = {...newReport, report_id: reportid}
    }
    setConfirmationPopup({
      isShow: true,
      isActionBtns: true,
      warning: "Are you sure you want to Save this report?",
      warningMsg: "",
      cancelHandler: () => setConfirmationPopup({ isShow: false }),
      okHandler: () => {
        setConfirmationPopup({ isShow: false });
        dispatch(loaderActions?.showLoader());
        dispatch(
          insertUpdateNewReport({
            newReport,
            callback: () => setTimeout(() => {
              dispatch(testAssessmentActions.clearReportTemplateDetails())
              navigate(backUrl);
              dispatch(loaderActions?.hideLoader());
            })
          })
        );
      }
    });
  }

  const confirmBackBtn = ({ from }) => {
    setConfirmationPopup({
      isShow: true,
      isActionBtns: true,
      warning: "Are you sure you want to discard this data?",
      warningMsg: "",
      cancelHandler: () => setConfirmationPopup({ isShow: false }),
      okHandler: () => {
        getNavigation(from)
      }
    });
  }

  return (
    <Fragment>
      <ReportHeader
        headerData={{
          heading,
          setHeading,
          isHeadingEditable: true,
          isbackBtn: true,
          backBtnAction: () => confirmBackBtn({ from }),
          error,
          setError
        }}
      />
      <div className="si-subHeader">
        <div className="si-testcover">
          <div className="si-namewrp">
            <div className="si-Image">
              <img src={`../../images/profile/${athleteDetails?.athlete_id}.png`} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "../../images/profile/default.png";
              }} />
            </div>
            <div className="si-name">{athleteDetails?.athlete_name}</div>
          </div>
          <div className="si-conductdate">
            <div className="si-text">
              <span className="form-control si-start-date  edit-date">
                {console.log('reportDate', reportDate)}
                  <DatePickerComponent
                      isEditMode={false}
                      selectedDate={reportDate ? reportDate : ""}
                      dateChangeHandler={(event) => {
                        if(event){
                          let date = moment(event).format("YYYY-MM-DD");
                          setReportDate(date)
                        }
                      }}
                      maxDate={new Date()}
                    />
                {/* <input type="date"
                  max={new Date().toISOString().split("T")[0]}
                  className="si-input test-date-input " 
                  value={reportDate} 
                  onChange={(e) => setReportDate(e?.target?.value)}></input> */}
                {/* <div className="form-control-group">
                          <span className="si-icon icon-calender"></span>  */}
                {/* <input type="date" className="si-input"  /></div> */}
              </span>
            </div>

            <button type="button" className="si-button si-download" onClick={openPopupHandler}>
              <span className="icon-upload" />
              <span> Upload Pdf</span>
            </button>
            {file && file.length ? (
              <span className="si-download-count">
                {file && file.length ? file.length : 0}{" "}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="si-main-content si-reportdetails-wrapper">
        <div className="si-reportdetails">
          <div className="si-tbls">
            {reportTemplateDetails?.test_headers ?
              <TableHeader headers={reportTemplateDetails?.test_headers} /> : null
            }
            <div className="si-tbls__body">
              <div className="si-resultwrp">
                {reportTemplateDetails?.tests?.map((test) => {
                  return <Test testDetails={test}
                    headers={reportTemplateDetails?.test_headers}
                    key={test.id}
                    newReport={newReport}
                    setNewReport={setNewReport} />
                })}
                {newReport?.additional_information &&
                  <Fragment>
                    <div className="si-head">Additional Information</div>
                    <div className="si-tblwrp">
                      <div className="si-tableleft">
                        <div className="si-tbls__row ">
                          {/* <div class="si-tbls__cell">
                            <input type="text" class="si-score" placeholder="" value={additionalKey} onChange={(e) => setAdditionalKey(e?.target?.value)} />
                          </div> */}
                          <div className="si-tbls__cell">
                            <textarea class="si-textarea other-comments" placeholder="Add any additional information" value={additionalValue} onChange={(e) => setAdditionalValue(e?.target?.value)}></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>}
              </div>
            </div>
          </div>
        </div>
        <ConfirmationPopup
          isActive={confirmationPopup?.isShow}
          isActionBtns={confirmationPopup?.isActionBtns}
          warning={confirmationPopup?.warning}
          warningMsg={confirmationPopup?.warningMsg}
          cancelHandler={confirmationPopup?.cancelHandler}
          okHandler={confirmationPopup?.okHandler}
        />
      </div>
      <div className="si-tablefooter">
        {/* <div className="si-left">Conducted on 03/02/23 </div> */}
        <div className="si-footer-right">
          <div className="si-btn-wrap">
            {!isEditReport && <button
              type="button"
              className="si-button si-icon--add si-clearbtn" disabled={!reportTemplateDetails ? true : false} onClick={() => clearReport()}
            >
              Clear all
            </button>
            }

            <button
              type="button"
              className="si-button si-button--green si-icon--add si-savebtn" disabled={!reportTemplateDetails ? true : false} onClick={() => addNewReport(newReport)}
            >
              save
            </button>
          </div>
        </div>
        <div className="si-mob-footer">
          <button
            type="button"
            className="si-button si-button--green si-icon--add"
          >
            Save
          </button>
        </div>
      </div>
      {/* upload File popup */}
      {isShow &&
        <UploadMediaPopup isShow={isShow}
          closePopupHandler={closePopupHandler}
          file={file}
          setFile={setFile}
        />
      }

    </Fragment>
  );
};

export default AddNewReport;
