import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoader: false
};

const loaderSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        showLoader: (state) => {
            state.isLoader = true;
        },
        hideLoader: (state) => {
            state.isLoader = false
        },
    }
});

export const loaderActions = loaderSlice.actions;
export default loaderSlice.reducer;