import React, { Fragment } from 'react';
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchCoachDashboard } from "../../../utility/dashboardApiActions";
import { fetchCoachProgramPerformanceData } from "../../../utility/dashboardApiActions";
import DailyQuotes from '../DailyQuotes';
import { loaderActions } from '../../../reducer/loader';
import UpcomingCompetition from '../CoachDashboard/UpcomingCompetition';
import OverallPerformace from './OverallPerformace';
import { useState } from 'react';
import ProgramPerformanceGraph from './ProgramPerformanceGraph';
// import ProgramPerformanceGraphC from './ProgramPerformanceGraphC';
import NoCurrentProgram from './NoCurrentProgram';

function CoachHomePageContent() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state?.auth?.isAuthenticated);
  const dashboardData = useSelector((state) => state?.dashboard?.data);
  const prgramPerformanceData = useSelector((state) => state?.dashboard?.prgramPerformance);
  const userguid = useSelector((state) => state?.dashboard?.data?.user_guid)
  const [selectedProgram, setSelectedProgram] = useState('');

  
  useEffect(() => {
    if (isAuth) {
      dispatch(loaderActions?.showLoader());
      dispatch(fetchCoachDashboard((res) => setTimeout(() => {
        dispatch(loaderActions?.hideLoader());
      })
      ));
    }
  }, [isAuth]);

  useEffect(() => {
    if (isAuth && selectedProgram) {
      console.log("selectedProgram---->", selectedProgram);
      dispatch(loaderActions?.showLoader());
      dispatch(fetchCoachProgramPerformanceData({
        program_id: selectedProgram,
        callback: () => setTimeout(() => {
          dispatch(loaderActions?.hideLoader());
        })

      }));
    }
  }, [isAuth, selectedProgram]);

  useEffect(() => {
    if (dashboardData) {
      console.log("dashboardData: ", dashboardData);
      if (dashboardData?.current_programs) {
        setSelectedProgram(dashboardData?.current_programs[0]?.program_id);
      }
    }
  }, [dashboardData]);

  useEffect(() => {
    if (prgramPerformanceData) {
      console.log("prgramPerformanceData: ", prgramPerformanceData);
    }
  }, [prgramPerformanceData]);

  return (
    <Fragment>
      <div className="si-main-content si-dashboard-new si-coach-dashboard">
        <div class={`${dashboardData?.upcoming_competition?.length ? 'upcoming-competition-active' : 'upcoming-competition'}`}>
          {
              dashboardData?.upcoming_competition?.length ?
              <UpcomingCompetition competitons={dashboardData?.upcoming_competition} /> :
              dashboardData?.quotes ? <DailyQuotes quotes={dashboardData.quotes} /> : ''
          }

        </div>
        <div class={`${dashboardData?.current_programs ? '' : 'si-coach-dashboard-nodata-active'}`}>
          <OverallPerformace programsList={dashboardData?.current_programs} selectedProgram={selectedProgram} setSelectedProgram={setSelectedProgram} />
            <ProgramPerformanceGraph progrmaDetails={prgramPerformanceData?.rpe_details} />
          {/* <ProgramPerformanceGraphC progrmaDetails={prgramPerformanceData?.rpe_details} /> */}
          <NoCurrentProgram />
        </div>
      </div>
    </Fragment>
  );
};

export default CoachHomePageContent;