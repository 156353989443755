import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCaseList } from '../../../../utility/PsychologyApiActions';
import SubHeader from '../../Common/SubHeader';
import ListingTable from '../../Common/ListingTableMUI';
import { TableBody, TableCell, TableRow } from '@mui/material';
import {ATHELETE_ROLE_ID, PSYCHOLOGY_MODULE_ROUTE} from '../../../../utility/constants';

export default function Psychology({ activeTab }) {
    const { id } = useParams();
    const athleteDetails = useSelector((state) => state?.userManagement?.athleteDetails);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    let loading = useSelector(state => state?.psychology?.loading)
    const userDetails = useSelector(state => state?.auth?.userData);
    const caseList = useSelector((state) => state?.psychology?.caseList?.data);
    const paginationObject = useSelector((state) => state?.psychology?.caseList?.pagination);
    let filterOptions = {
        is_program_status_filter: false,
        is_search_filter: false,
        is_search_filter_for_athlete: true,
        is_date_range_filter: false,
        is_tag_filter: false,
        is_tab: false
    };

    useEffect(() => {
        if (activeTab === "psychology" && athleteDetails?.athlete_guid && pageNo) {
            console.log("athleteDetails", athleteDetails);
            let payload = {
                page_no: pageNo,
                item_number: 20,
                search: searchTerm,
                user_guid: athleteDetails?.athlete_guid ? athleteDetails?.athlete_guid : null,
                from_date: null,
                to_date: null,
            };
            dispatch(fetchCaseList({ payload }));
        }
    }, [activeTab, athleteDetails, dispatch, pageNo]);


    useEffect(() => {
        if (activeTab === "psychology" && searchTerm != null && athleteDetails?.athlete_guid) {
            let timeOut = searchTerm!==null ? 1000 : 0;
            let listTimeOut = setTimeout(() => {
            let payload = {
                page_no: pageNo,
                item_number: 20,
                search: searchTerm,
                user_guid: athleteDetails?.athlete_guid ? athleteDetails?.athlete_guid : null,
                from_date: null,
                to_date: null,
            };
            dispatch(fetchCaseList({ payload }));
        }, timeOut);
        return () => clearTimeout(listTimeOut);
        }
    }, [searchTerm]);

    const formatDate = (date) => {
        let objectDate = new Date(date);
    
        let day = objectDate.getDate();
    
        let month = objectDate.getMonth() + 1;
    
        let year = objectDate.getFullYear();
        return `${day}/${month}/${year}`;
      };

    const tableHeaders = [
        {
            label: "Case Name",
            align: "left",
            width:'30'
        },
        {
            label: "Conducted on",
            align: "left",
            width:'30'
        },
        {
            label: "",
            align: "right",
            stickyClass: "stickyCell-right",
            width:'40'
        }
    ];

    const handleClick = (caseD) => {
        localStorage.setItem("guid", caseD.athlete_guid);
        if(userDetails?.role_id == ATHELETE_ROLE_ID){
            navigate(`/${PSYCHOLOGY_MODULE_ROUTE}/${userDetails?.user_id}/case/${caseD?.case_id}`);
        }else{
            navigate(`/${PSYCHOLOGY_MODULE_ROUTE}/${id}/case/${caseD?.case_id}`)
        }
    }

    const getTableBody = () => {
        return (
            <TableBody>
                {
                    caseList?.length
                        ?
                        caseList?.map((caseD, idx) => {
                            return (
                                <TableRow
                                    key={idx}
                                >
                                    <TableCell align={"left"} width='30' style={{cursor:'pointer'}} onClick={(e)=>handleClick(caseD)}>
                                        {caseD?.case_name}
                                    </TableCell>
                                    <TableCell align={"left"} width='30'>
                                        {caseD?.case_opened_on ? formatDate(caseD?.case_opened_on) : "-"}
                                    </TableCell>
                                    <TableCell align={"right"} className='stickyCell-right' width='40'>
                                        <button
                                            type="button"
                                            className="si-viewBtn"
                                        onClick={(e) => handleClick(caseD)}
                                        >
                                            <span className="si-icon__view"></span>
                                            <span className="si-txt">View Details</span>
                                        </button>

                                    </TableCell>
                                </TableRow>
                            )
                        })
                        :
                        <TableRow className="nodata-tableRow">
                            <TableCell align={"center"} colSpan={tableHeaders.length}>
                                No data found
                            </TableCell>
                        </TableRow>
                }
            </TableBody>
        )
    };
    return (
        <>
        {loading ? <div className="spin"></div> : null}
        <div className="si-tab-pane si-tab-pane__Training">
            <SubHeader
                filterOptions={filterOptions}
                OtherClass={"athelete-profile-subheader"}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                pageNo={pageNo}
                setPageNo={setPageNo}
                isBtn={false}
                />
            <div className="si-main-content myprofile-maincontent">
                <ListingTable
                    tableHeaders={tableHeaders}
                    getTableBody={getTableBody}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    pagination={paginationObject}
                    perPage={20}
                    />
            </div>
        </div>
        </>
    )
}
