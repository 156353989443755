import React from 'react';
import { useSelector } from 'react-redux';
import CoachHomePageContent from './CoachDashboard/CoachHomePageContent';
import AthleteHomePage from './AthleteDashboard/AthleteHomePage';
import { ATHELETE_ROLE_ID, SNC_COACH_ROLE_ID, TECHNICAL_COACH_ROLE_ID } from '../../utility/constants';
import DefaultDashboard from './DefaultDashboard';

function Content() {
    const userData = useSelector(state => state?.auth?.userData);
    return (
        <>
            {
                userData?.role_id == ATHELETE_ROLE_ID 
                ?
                <AthleteHomePage />
                :
                (userData?.role_id == SNC_COACH_ROLE_ID || userData?.role_id == TECHNICAL_COACH_ROLE_ID ) 
                ?
                <CoachHomePageContent /> 
                :
                <DefaultDashboard/>
                // <CoachHomePageBackup/>
            }
        </>
    );
};

export default Content;