import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addPerformanceAthletePopup,
  addTournamentPerformanceEvents,
  deleteTournamentPerformanceEvents,
  fetchMedalMaster,
  fetchPerformanceAthletePopup,
  fetchTournamentEventDetails,
  fetchTournamentSportsList,
  getEventAthleteData,
  getEventListsPerformance,
  getEventsPerformanceRecordAthleteListing,
  getperformancerecordlisting,
  getTournamentDetailsById,
  getTournamentPerformanceEvents,
  updateAthleteRecordApi,
  updatePerformaceRecord,
} from "../utility/tournamentPerformanceApiAction";

const initialState = {
  selectedTournament: null,
  loading: false,
  performanceLists: null,
  performanceListPagination: null,
  selectedPerformaceRecordId: false,
  mappedEvents: null,
  groupList: null,
  athleteDetails: null,
  athleteList: null,
  performanceRecordDetails: null,
  medalMaster: null,
  updateEventSuccessful: false,
  recordUpdated: false,
  sportsList:false,
  updateAthleteSuccessful: false,
  eventAthleteData:null,
  athleteEventRecords:null,
  athleteEventRecordsPagination:null
};

const TournamentPerformanceSlice = createSlice({
  name: "TournamentPerformance",
  initialState,
  reducers: {
    setPerformanceUpdateEventSuccessfulFalse: (state, action) => {
      state.updateEventSuccessful = false;
    },
    setPerformanceUpdateAthleteSuccessfulFalse: (state, action) => {
      state.updateAthleteSuccessful = false;
    },
    setRecordUpdatedFalse: (state) => {
      state.recordUpdated = false;
    },
    setPerformanceTournamentDetails :(state, action)=>{
      state.selectedTournament = action.payload
      return state
    },
    setSelectedTournament: (state, action) => {
      state.selectedTournament = action.payload.data;
      return state;
    },
    resetSelectedPerformanceId: (state, action) => {
      state.selectedPerformaceRecordId = false;
      state.selectedTournament = false;
      return state;
    },
    resetSportsList: (state)=>{
      state.sportsList = false;
      return state
    }
  },
  extraReducers: {
    [fetchTournamentSportsList.pending]:(state,action)=>{
      state.loading = false
      return state
    },
    [fetchTournamentSportsList.fulfilled]:(state,action)=>{
      state.loading = false;
      state.sportsList = action?.payload?.sportsList?.data;
      return state;
    },
    [fetchTournamentSportsList.rejected]:(state,action)=>{
      state.loading = false
      toast?.error(`Unable to fetch sport list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while fetching sports list:", action?.error);
      return state;
    },
    [getEventsPerformanceRecordAthleteListing.pending]:(state,action)=>{
      state.loading = false
      return state
    },
    [getEventsPerformanceRecordAthleteListing.fulfilled]:(state,action)=>{
      state.loading = false;
      state.athleteEventRecords = action?.payload?.data?.data?.value?.data;
      state.athleteEventRecordsPagination = action?.payload?.data?.data?.value?.pagination;
      return state;
    },
    [getEventsPerformanceRecordAthleteListing.rejected]:(state,action)=>{
      state.loading = false
      toast?.error(`Unable to fetch events details. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while fetching events details:", action?.error);
      return state;
    },
    [getEventAthleteData.pending]:(state,action)=>{
      state.loading = true
      return state
    },
    [getEventAthleteData.fulfilled]:(state,action)=>{
      state.loading = false
      state.eventAthleteData = action.payload.eventAthleteData
      return state
    },
    [getEventAthleteData.rejected]:(state,action)=>{
      state.loading = false
      toast?.error(`Unable to fetch athlete details. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while fetching athlete details:", action?.error);
      return state;
    },
    [getTournamentDetailsById.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [getTournamentDetailsById.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedTournament = action.payload?.selectedTournament?.data;
      return state;
    },
    [getTournamentDetailsById.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch tournament details. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while fetching tournament details:", action?.error);
      return state;
    },
    [getperformancerecordlisting.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [getperformancerecordlisting.fulfilled]: (state, action) => {
      state.loading = false;
      state.performanceLists = action?.payload?.data?.data?.value?.data;
      state.performanceListPagination = action?.payload?.data?.data?.value?.pagination;
      return state;
    },
    [getperformancerecordlisting.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch tournament list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while fetching list:", action?.error);
      return state;
    },
    [updatePerformaceRecord.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [updatePerformaceRecord.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedPerformaceRecordId = action?.payload?.data?.data?.value;
      return state;
    },
    [updatePerformaceRecord.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to select tournament. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while selecting tournament:", action?.error);
      return state;
    },
    [getTournamentPerformanceEvents.pending]: (state, action) => {
      state.loading = true;
      state.mappedEvents = null;
    },
    [getTournamentPerformanceEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.mappedEvents = action?.payload?.data?.data?.value?.data;
      state.selectedTournament = action?.payload?.data?.data?.value?.tournament_record_details[0]      ;
      return state;
    },
    [getTournamentPerformanceEvents.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch events. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while fetching events:", action?.error);
      return state;
    },
    [addTournamentPerformanceEvents.pending]: (state, action) => {
      state.loading = true;
      state.updateEventSuccessful = false;
    },
    [addTournamentPerformanceEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.updateEventSuccessful = true;
      if (action?.payload?.events) {
        state.mappedEvents = action?.payload?.events?.data?.data?.value?.data;
      }
      return state;
    },
    [addTournamentPerformanceEvents.rejected]: (state, action) => {
      state.loading = false;
      state.updateEventSuccessful = false;
      toast?.error(`Unable to add events. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while adding events:", action?.error);
      return state;
    },
    [deleteTournamentPerformanceEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTournamentPerformanceEvents.fulfilled]: (state, action) => {
      state.loading = false;
      let details = {};
      if (action.payload.eventDetails?.data?.length) {
        action.payload.eventDetails?.data?.forEach((events) => {
          let event = events.event[0];
          let sportId = events.event[0].sport_id;
          if (details[sportId]) {
            details = { ...details, [sportId]: [...details[sportId], event] };
          } else {
            details = { ...details, [sportId]: [event] };
          }
        });
        state.performanceRecordDetails = details;
      }
      if (action?.payload?.events) {
        state.mappedEvents = action?.payload?.events?.data?.data?.value?.data;
      }
      return state;
    },
    [deleteTournamentPerformanceEvents.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to delete event. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while deleting events:", action?.error);
      return state;
    },
    [fetchPerformanceAthletePopup.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchPerformanceAthletePopup.fulfilled]: (state, action) => {
      state.loading = false;
      state.groupList = action.payload?.groupList;
      state.athleteList = action.payload?.athleteDetails;
      return state;
    },
    [fetchPerformanceAthletePopup.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch athletes. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while fetching athletes:", action?.error);
      return state;
    },
    [addPerformanceAthletePopup.pending]: (state, action) => {
      state.loading = true;
      state.updateAthleteSuccessful = false;
      return state;
    },
    [addPerformanceAthletePopup.fulfilled]: (state, action) => {
      state.loading = false;
      state.updateAthleteSuccessful = true;

      // let details = {};
      // if (action.payload.eventDetails?.data?.length) {
      //   action.payload.eventDetails?.data?.forEach((events) => {
      //     let event = events.event[0];
      //     let sportId = events.event[0].sport_id;
      //     if (details[sportId]) {
      //       details = { ...details, [sportId]: [...details[sportId], event] };
      //     } else {
      //       details = { ...details, [sportId]: [event] };
      //     }
      //   });
      //   state.performanceRecordDetails = details;
      // }
      state.eventAthleteData = action.payload.eventAthleteData
      return state;
    },
    [addPerformanceAthletePopup.rejected]: (state, action) => {
      state.loading = false;
      state.updateAthleteSuccessful = false;
      toast?.error(`Unable to add athletes. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while adding athletes:", action?.error);
      return state;
    },
    [fetchTournamentEventDetails.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchTournamentEventDetails.fulfilled]: (state, action) => {
      state.loading = false;
      let details = {};
      // let response = action.payload.eventDetails
      action.payload.eventDetails?.data?.forEach((events) => {
        let event = events.event[0];
        let sportId = events.event[0].sport_id;
        if (details[sportId]) {
          details = { ...details, [sportId]: [...details[sportId], event] };
        } else {
          details = { ...details, [sportId]: [event] };
        }
      });
      state.performanceRecordDetails = details;
      let medalMaster = action.payload?.medalMaster?.data;
      state.medalMaster = medalMaster;
      return state;
    },
    [fetchTournamentEventDetails.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch events details. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while fetching details:", action?.error);
      return state;
    },
    [fetchMedalMaster.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchMedalMaster.fulfilled]: (state, action) => {
      state.loading = false;
      let medalMaster = action.payload?.medalMaster?.data;
      state.medalMaster = medalMaster;
      return state;
    },
    [fetchMedalMaster.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch medals. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while fetching details:", action?.error);
      return state;
    },
    [updateAthleteRecordApi.pending]: (state, action) => {
      state.loading = true;
      state.recordUpdated = false;
      return state;
    },
    [updateAthleteRecordApi.fulfilled]: (state, action) => {
      state.loading = false;
      // let details = {};
      // if (action.payload.eventDetails?.data?.length) {
      //   action.payload.eventDetails?.data?.forEach((events) => {
      //     let event = events.event[0];
      //     let sportId = events.event[0].sport_id;
      //     if (details[sportId]) {
      //       details = { ...details, [sportId]: [...details[sportId], event] };
      //     } else {
      //       details = { ...details, [sportId]: [event] };
      //     }
      //   });
      //   state.performanceRecordDetails = details;
      // }
      state.eventAthleteData = action.payload.eventAthleteData
      state.recordUpdated = true;
      return state;
    },
    [updateAthleteRecordApi.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to update records. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while updating records:", action?.error);
      return state;
    },
    [getEventListsPerformance.pending]: (state, action) => {
      state.updateEventLoading = true;
      state.loading = true;
      return state;
    },
    [getEventListsPerformance.fulfilled]: (state, action) => {
      state.eventsList = action?.payload?.response?.data?.data?.value?.data;
      state.paginationObject = action?.payload?.response?.data?.data?.value?.pagination;
      state.genderList = action?.payload?.response?.data?.data?.value?.gender;
      state.allGenderList = action?.payload?.genderMaster?.data?.data?.value?.data;
      state.updateEventLoading = false;
      state.loading = false;
      return state;
    },
    [getEventListsPerformance.rejected]: (state, action) => {
      toast?.error(`Unable to fetch events. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      console.log("Error while fetching events list: ", action?.error);
      state.updateEventLoading = false;
      state.loading = false;
      return state;
    },
  },
});

export const {
  setRecordUpdatedFalse,
  setPerformanceUpdateEventSuccessfulFalse,
  setPerformanceUpdateAthleteSuccessfulFalse,
  setSelectedTournament,
  setPerformanceTournamentDetails,
  resetSelectedPerformanceId,
  resetSportsList
} = TournamentPerformanceSlice.actions;
export default TournamentPerformanceSlice.reducer;
