import React, { Fragment } from 'react';
import Header from '../RightPannel/Common/Header';
import Content from './Content';
import { useSearchParams } from 'react-router-dom';

export default function Dashboard() {
  const [searchParams, setSearchParams] = useSearchParams();
    const session = searchParams?.get("session");
    // console.log(session);
  return (
    <Fragment>
      <Header
        headerData={{
            heading: "Home",
            isHeadingEditable: false
        }}
      />
      <Content />
    </Fragment>
  )
}
