import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ExerciseHead from './ExerciseHead';
import ExerciseBody from './ExerciseBody';
import Footer from '../../../Common/PopupFooter';
import ConfirmationPopup from '../../../Common/ConfirmationPopup';
import { technicalProgramsActions } from '../../../../../reducer/technicalPrograms';
import { technicalTemplatesActions } from '../../../../../reducer/technicalTemplates';

function EditExercisePopup({ isActive, isActionBtn=true, cancelAction, saveAction, tabLists , isEditable, type, startDate, isViewOnly=false, isExerciseMaster}) {
    const selectedExerciseList = useSelector(state => type===2 ? state?.technicalPrograms?.selectedExerciseList : state?.technicalTemplates?.selectedExerciseList);
    const editExercise = useSelector(state => type===2 ? state?.technicalPrograms?.editExercise : state?.technicalTemplates?.editExercise);
    const saveEditedExercise = type===2
                                ? technicalProgramsActions?.saveEditedExercise
                                : technicalTemplatesActions?.saveEditedExercise;
    const [warningPopup, setWarningPopup] = useState({isShow: false});
    const [ activeTab, setActiveTab ] = useState("specs");
    const [exerciseToEdit, setExerciseToEdit] = useState(null);
    const [selectedExerciseNameError , setSelectedExerciseNameError] = useState({
        is_error : false,
        msg:''
    });
    const [sportSetError, setSportSetError] = useState({
        is_error : false,
        msg:''
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // useEffect(()=>{
    //     if(selectedExerciseNameError.is_error){
    //         toast.warn(selectedExerciseNameError.msg)
    //     };
    // },[selectedExerciseNameError])

    useEffect(() => {
        setExerciseToEdit({ 
            ...selectedExerciseList?.find(exercise => exercise?.exercise_id === editExercise?.id) 
        });
        // setExerciseToEdit(exerciseToEditData);
        setActiveTab(tabLists[0]);
    }, [isActive]);

    const saveExercise = () => {
        console.log("Save exercise:",exerciseToEdit);
        dispatch(saveEditedExercise({
            selectedExercise: selectedExerciseList,
            editedExercise: exerciseToEdit,
            callback: () => setTimeout(() => {
                saveAction(exerciseToEdit);
            })
        }));
    };

    const actionBtnHandler = (e,type) => {
        e.preventDefault();
        if(!exerciseToEdit?.exercise_name) {
            setSelectedExerciseNameError({
                is_error: true,
                msg: "Please add exercise name"
            });
        } else if(!exerciseToEdit?.sport_id?.length) {
            setSportSetError({
                type: 1,
                is_error: true,
                msg: "Please select sport for exercise"
            })
        } else if(!exerciseToEdit?.sets) {
            setSportSetError({
                type: 2,
                is_error: true,
                msg: "Please add exercise sets"
            })
        } else if(!selectedExerciseNameError.is_error) {
            saveExercise();
        };
    };

    console.log("edit popup",isActive,editExercise,selectedExerciseList);

    return (
        <div className={`si-custom-popup si-popup zindex-10000 ${isActive ? "active" : ""}`}>
            <div className={`si-popup-content ${!isEditable ? "input-view-popup":""}`}>
                <ExerciseHead 
                    exerciseToEdit={ exerciseToEdit }
                    setExerciseToEdit={setExerciseToEdit}
                    onCancel={cancelAction}
                    isEditable={isEditable}
                    isViewOnly={isViewOnly}
                    selectedExerciseNameError={selectedExerciseNameError}
                    setSelectedExerciseNameError={setSelectedExerciseNameError}
                />

                <ExerciseBody 
                    activeTab={activeTab} 
                    setActiveTab={setActiveTab} 
                    exerciseToEdit={exerciseToEdit}
                    setExerciseToEdit={setExerciseToEdit}
                    setWarningPopup={setWarningPopup}
                    tabLists={tabLists}
                    type={type}
                    startDate={startDate}
                    isViewOnly={isViewOnly}
                    isExerciseMaster={isExerciseMaster}
                    sportSetError={sportSetError}
                    setSportSetError={setSportSetError}
                />

                <Footer 
                    isEditExercisePopup={true}
                    isActionBtn={isActionBtn}
                    btnClass={
                        `si-button 
                        ${activeTab==="recurrence" && !exerciseToEdit?.recurrence?.length 
                        ? "si-button--green" 
                        : "si-button--green"}`
                    }
                    actionBtnText={"Save"}
                    actionBtnHandler={(e)=>actionBtnHandler(e,activeTab===tabLists[tabLists?.length - 1] ? 1 : 2)}
                    selectedTab={activeTab}
                    isViewOnly={isViewOnly}
                />
                {
                    warningPopup && 
                    <ConfirmationPopup 
                        isActive={warningPopup?.isShow}
                        isActionBtn={true}
                        warning={warningPopup?.warning}
                        warningMsg={warningPopup?.warningMsg}
                        okHandler={warningPopup?.okHandler}
                    />
                }
            </div>
        </div>
    );
};

export default EditExercisePopup;