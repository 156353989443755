import moment from 'moment';
import React from 'react'
import { Fragment } from 'react'
import RPECard from './RPECard';

export default function RPEDetails({ rangeClickHandler,rpeTabs, lastSession, athleteList, selectedRange }) {
    let lastSessionDay = lastSession ? moment(lastSession).format('Do') : '';
    let lastSessionDate = lastSession ? moment(lastSession).format('MMM YY') : '';
    
    return (
        <Fragment>
            <div style={{marginTop:"25px"}}>
                <div class="rpe-date">Last Session RPE - {lastSessionDay} {lastSessionDate} </div>
            </div>
            <ul class="performance-level-list">
                {rpeTabs?.map((tab, index) => {
                    let rangeLabel = tab.label
                    // let rangeLabel = tab?.rangeFrom && tab?.rangeTo ? `${tab?.rangeFrom}-${tab?.rangeTo}` : tab?.rangeFrom;
                    return <li class={`performance-level-list__item ${selectedRange.id === tab?.id ? 'active' : ''}`} 
                    key={index} onClick={() => rangeClickHandler(tab)}>
                            <div class={`performance-level ${tab.class}`}>
                            <span class={`performance-level__num ${tab.class}`}>{rangeLabel}</span>
                            <span class="performance-level__text">{tab?.name}</span>
                        </div>
                        <span class="level-indicator"></span>
                    </li>
                })}
            </ul>
            <div class={`players-list-wrp ${!athleteList?.length ? 'no-player-active' : ''}`}>
                {athleteList && athleteList?.length ?
                    <ul class="players-list">
                        {athleteList?.map((athlete, index) => {
                            return <RPECard athlete={athlete} key={index} />
                        })}
                    </ul> :
                    <div class="no-player">
                        <img src="../../images/no-player-data-img.svg" alt="no-player" />
                    </div>
                }
            </div>
        </Fragment>
    )
}
