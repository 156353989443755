import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { deleteTournament, fetchSportsList, fetchTournamentLevelMaster, getTournamentLists, updateTournament } from "../utility/tournamentsApiActions"

const initialState = {
    loading:false,
    tournamentLevelMaster:null,
    tournamentList: null,
    paginationObject: null,
    sportList: null,
    selectedTournament:null,
    updateTournamentLoading:false,
    updateTournamentSuccessful:false,
    tournamentErrorMsg: null
}

const tournamentListingSlice = createSlice({
    name:"tournamentListing",
    initialState,
    reducers:{
        setUpdateTournamentSuccessfulFalse : (state, action) =>{
            state.updateTournamentSuccessful = false
        },
        resetTournamentErrorMsg:(state, action)=>{
            state.tournamentErrorMsg = null
            
        }
    },
    extraReducers:{
        [fetchTournamentLevelMaster.pending] : (state, action)=>{
            state.loading = true
            
        },
        [fetchTournamentLevelMaster.fulfilled]:(state, action)=>{
            state.loading = false
            state.tournamentLevelMaster = action?.payload?.data?.data?.value?.data;
        },
        [fetchTournamentLevelMaster.rejected] : (state, action) =>{
            state.loading = false
            toast?.error(`Unable to fetch tournament levels list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching tournament levels list: ", action?.error);
        },
        [fetchSportsList.pending] : (state, action)=>{
            
        },
        [fetchSportsList.fulfilled]:(state, action)=>{
            state.sportList = action?.payload?.data?.data?.value?.sport;
        },
        [fetchSportsList.rejected] : (state, action) =>{
            toast?.error(`Unable to fetch sport list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching sport list: ", action?.error);
        },
        [getTournamentLists.pending] : (state,action)=>{
            state.updateTournamentLoading = true
            return state
        },
        [getTournamentLists.fulfilled] : (state,action)=>{
            state.tournamentList =  action?.payload?.data?.data?.value?.data
            state.paginationObject = action?.payload?.data?.data?.value?.pagination
            state.updateTournamentLoading = false
            return state
        },
        [getTournamentLists.rejected] : (state,action) => {
            toast?.error(`Unable to fetch tournaments. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Error while fetching tournaments list: ", action?.error);
            state.updateTournamentLoading = false
            return state
        },
        [updateTournament.pending]:(state, action) => {
           state.updateTournamentLoading = true
           state.updateTournamentSuccessful = false
           return state
        },
        [updateTournament.fulfilled]:(state,action) =>{
            if(action?.payload?.data?.status === 201){
                state.tournamentErrorMsg = action?.payload?.data?.detail
                state.updateTournamentLoading = false
            } else {
                state.updateTournamentLoading = false
                state.updateTournamentSuccessful = true
            }
            return state
        },
        [updateTournament.rejected]:(state,action) =>{
            state.updateTournamentLoading = false
            state.updateTournamentSuccessful = false
            if(action.meta.arg.id){
                toast?.error(`Unable to update tournament. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
                console.log("Error while updating tournament: ", action?.error);
            }else {
                toast?.error(`Unable to add tournament. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
                console.log("Error while updating tournament: ", action?.error);
            }
        },
        [deleteTournament.pending] :(state)=>{
            state.updateTournamentLoading = true
            return state
        },
        [deleteTournament.fulfilled] :(state)=>{
            state.updateTournamentLoading = false
            state.updateTournamentSuccessful = true
            return state
        },
        [deleteTournament.rejected] : (state,action)=>{
            state.updateTournamentLoading = false
                toast?.error(`Unable to delete tournament. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
                console.log("Error while updating tournament: ", action?.payload?.error);
        }
    }
})

export const { setUpdateTournamentSuccessfulFalse, resetTournamentErrorMsg } = tournamentListingSlice.actions
export default tournamentListingSlice.reducer