import React from 'react'
import { useSelector } from 'react-redux'
import { ATHELETE_ROLE_ID } from '../../../utility/constants'
import AthleteProfileNutrition from './AthleteProfile/AthleteProfileNutrition'
import NutritionSprotsListing from './NutritionSprotsListing'

function Nutrition() {
    let userData = useSelector(state=> state.auth.userData)
  return (
    Number(userData?.role_id) === ATHELETE_ROLE_ID ? 
    <AthleteProfileNutrition/>
    :
    <NutritionSprotsListing/>
  )
}

export default Nutrition