import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchAthleteDashboard } from '../../../utility/dashboardApiActions';
import DailyQuotes from '../DailyQuotes';
import UpcomingCompetition from './UpcomingCompetition';
import DailyGoals from './DailyGoals';
import { loaderActions } from '../../../reducer/loader';
import NoDailyGoals from './NoDailyGoals';

function AthleteHomePage() {
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state?.auth?.isAuthenticated);
    const dashboardData = useSelector((state) => state?.dashboard?.data);

    useEffect(() => {
        if(isAuth){
            dispatch(loaderActions?.showLoader());
            dispatch(fetchAthleteDashboard((res)=> setTimeout(()=>{
                    dispatch(loaderActions?.hideLoader());
                })
            ));
        }
     }, [isAuth]);

     useEffect(()=>{
        if(dashboardData){
            console.log("dashboardData: ",dashboardData);
        }
     },[dashboardData]);

    return (
        <div className="si-main-content si-dashboard-new">
            {dashboardData?.quotes && <DailyQuotes quotes={dashboardData.quotes}/>}
            {dashboardData?.upcoming_competition && <UpcomingCompetition competitons={dashboardData.upcoming_competition}/>}
            {dashboardData?.daily_goal && <DailyGoals goal={dashboardData.daily_goal}/>}
            {/* {dashboardData?.daily_goal?.goals == null ?  dashboardData?.daily_goal && <DailyGoals goals={dashboardData.daily_goal}/> : <NoDailyGoals/> }  */}
        </div>
    );
};

export default AthleteHomePage;