import React, {useState, useEffect, memo} from 'react';
import Slider from 'react-rangeslider';

function RangeSlider({id, progressValue, isDragging, isEditable, changeHandler, changeStartHandler, changeCompleteHandler, step=10}) {
    const [range, setRange] = useState(progressValue ? Number(progressValue) : 0);

    useEffect(() => {
        let progressTimeOut = setTimeout(() => {
            changeHandler(id, range);
        }, 0);
        return () => clearTimeout(progressTimeOut);
    }, [range, isDragging]);

    return (
        <Slider 
            min={0}
            max={100}
            step={step}
            tooltip={false}
            value={range ? range : progressValue ? progressValue : 0}
            orientation="horizontal"
            onChangeStart={() => {if(isEditable) changeStartHandler(id)}}
            onChange={(value, event) => {if(isEditable && (event?.type==="mousemove" || event?.type==="touchmove")) setRange(value)}}
            onChangeComplete={() => {if(isEditable) changeCompleteHandler(id, range)}}
        />
    );
};

export default memo(RangeSlider);