import React from 'react';
import GeneralInfo from './GeneralInfo';
import Snc from './Snc';
import TechnicalTraining from './TechnicalTraining';
import TestAssessment from './TestAssessment';
import Competitions from './Competitions';
import Tournaments from './Tournaments';
import Psychology from './Psychology';
import InjuryTracker from './InjuryTracker';
import NutritionTab from './NutritionTab';

function Content({ activeTab, details }) {

    return (
        <div className="si-main-content">
            <div className="si-tab-content">
                {
                    activeTab && activeTab == 'general' && <GeneralInfo details={details} />
                }

                {
                    activeTab && activeTab == 'snc' && <Snc activeTab={activeTab} />
                }
                {
                    activeTab && activeTab == 'technical' && <TechnicalTraining activeTab={activeTab} />
                }
                {
                    activeTab && activeTab == 'competitions' && <Competitions activeTab={activeTab} />
                }
                {
                    activeTab && activeTab == 'tournaments' && <Tournaments activeTab={activeTab} />
                }
                {
                    activeTab && activeTab == 'test' && <TestAssessment />
                }
                {
                    activeTab && activeTab == 'psychology' && <Psychology activeTab={activeTab} />
                }
                {
                    activeTab && activeTab == 'injury' && <InjuryTracker activeTab={activeTab} />
                }
                {
                    activeTab && activeTab == "nutrition" && <NutritionTab activeTab={activeTab} />
                }
            </div>
        </div>
    );
};

export default Content;