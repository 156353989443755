import React from 'react'
import { Fragment } from 'react'

export default function Competitions() {
  return (
    <div class="comming-soon">
      <img src="../../images/comingsoon-img.svg" />
    </div>
  )
}
