import React from "react";
import { NO_PROGRMA_TEXT } from "../../../utility/constants";

const NoDailyGoals = (props) => {
  return (
      <div className="si-card si-snc-card">
        <p className="si-card-title ">{props.title}</p>
        <p className="si-card-subtitle si-card-subtitle-emptyCard">{NO_PROGRMA_TEXT}</p>
        <div className="si-card-maincontent">
          <div className="si-img-wrapper">
            <img src="../../images/nodata-dashboard-img.svg" alt="nodata" />
          </div>
        </div>
        <p className="msg-for-nodata">{props.message}</p>
    </div>
  );
};

export default NoDailyGoals;
