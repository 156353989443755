import React from 'react'
import SubHeader from '../../Common/SubHeader';

function EventListingSubHeader(
    {
        searchTerm, setSearchTerm, 
        selectedSports,setSelectedSports,
        pageNo, setPageNo, 
        onClick, eventTypes, genderLists,
        setSelectedEventTypeIds, setSelectedGenderIds
    }) {
  let filterOptions = {
    is_sport_filter: true,
    is_search_filter: true,
    is_tab: false,
    is_group_filter: false,
    is_gender_filter:true,
    is_event_type_filter:false
};
  return (
    <SubHeader 
    filterOptions={filterOptions}
    searchTerm={ searchTerm }
    setSearchTerm={ setSearchTerm }
    selectedSports={ selectedSports }
    setSelectedSportIds={ setSelectedSports }
    genderList = {genderLists}
    setSelectedGenderIds={setSelectedGenderIds}
    eventList = {eventTypes}
    setSelectedEventTypeIds = {setSelectedEventTypeIds}
    pageNo={ pageNo }
    setPageNo={ setPageNo }
    isBtn={true}
    btnName="Add Event"
    onBtnClick={onClick}
    OtherClass={"si-completed-subheader"}
/>
  )
}

export default EventListingSubHeader