import React, { useRef } from 'react'
import RPEDetails from './RPEDetails';
import { useState } from 'react';
import { useEffect } from 'react';
import PerformanceChart from './PerformanceChart';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

export default function ProgramPerformanceGraph({progrmaDetails}) {
    const [athleteList ,setAthleteList]= useState([]);
    const [selectedRange , setSelectedRange] = useState('');
    const chartParentRef = useRef(null)
    const chartWrapperRef = useRef(null)
    const prgramPerformanceData = useSelector((state) => state?.dashboard?.prgramPerformance);

    // need to use 
    useLayoutEffect(()=>{
        if(chartParentRef?.current && chartWrapperRef?.current && prgramPerformanceData?.athlete_performance?.length){
            let parentRefWidth = chartParentRef.current.offsetWidth
            let totalAthletes = prgramPerformanceData?.athlete_performance?.length
            let totalWidth = totalAthletes*100
            if(parentRefWidth < totalWidth){
                chartWrapperRef.current.style.width = totalWidth + "px"
            }else {
                chartWrapperRef.current.style.width =  "100%"
            }
        }
    },[prgramPerformanceData])

    useEffect(()=>{
        let run = ()=>{
            if(chartParentRef?.current && chartWrapperRef?.current && prgramPerformanceData?.athlete_performance?.length){
                let parentRefWidth = chartParentRef.current.offsetWidth
                let totalAthletes = prgramPerformanceData?.athlete_performance?.length
                let totalWidth = totalAthletes*100
                // console.log('first', parentRefWidth, totalWidth)
                if(parentRefWidth < totalWidth){
                    chartWrapperRef.current.style.width = totalWidth + "px"
                }else {
                    chartWrapperRef.current.style.width =  "100%"
                }
            }
        }
        // hit when window resize
        window.onresize = run
        // document.addEventListener("resize", run)
        return () => window.onresize = undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    let rpeTabs = [
        {
            'id':1,
            'name': 'Very Light',
            'rangeFrom': 1,
            'rangeTo': 1,
            'class': 'very-light',
            'label':"1",
        },
        {
            'id':2,
            'name': 'Light',
            'rangeFrom': 2,
            'rangeTo': 3,
            'class': 'light',
            'label':"2-3",
        },
        {
            'id':3,
            'name': 'Moderate',
            'rangeFrom': 4,
            'rangeTo': 6,
            'class': 'moderate',
            'label':"4-6",
        },
        {
            'id':4,
            'name': 'Vigorous',
            'rangeFrom': 7,
            'rangeTo': 8,
            'class': 'vigorous',
            'label':"7-8",
        },
        {
            'id':5,
            'name': 'Very Hard',
            'rangeFrom': 9,
            'rangeTo': 9,
            'class': 'very-hard',
            'label':"9",
        },
        {
            'id':6,
            'name': 'Max Efforts',
            'rangeFrom': 10,
            'rangeTo': 10,
            'class': 'max-effort',
            'label':"10",
        }
    ]
    const rangeClickHandler = (tab)=>{
        setSelectedRange(tab);
    }

    useEffect(()=>{
        if(progrmaDetails){
            setSelectedRange(rpeTabs[0]);
            setAthleteList([]);
        }
    },[progrmaDetails]);

    useEffect(()=>{
        let tab = selectedRange;
        console.log(tab,progrmaDetails,"tab");
        let list = progrmaDetails?.rpe_details?.filter((data)=>{
            let rangeFrom = tab?.rangeFrom ? tab?.rangeFrom : 0;
            let rangeTo = tab.rangeTo ? tab.rangeTo : rangeFrom;
            console.log(rangeFrom,rangeTo,data?.rpe_id,"datata")
            if(rangeFrom <= data?.rpe_id && rangeTo >=  data?.rpe_id){
                return data;
            }
        });
        setAthleteList(list);
    },[selectedRange])
    
    return (
        <div className="si-coach-data-container">
                <div ref={chartParentRef} style={{position:"relative",height:"400px", overflowX: "scroll"}}>
                    <div ref={chartWrapperRef} className="si-chart-wrapper" style={{width: "100%",height:"100%", marginBottom:"0px"}}>
                        <PerformanceChart />
                    </div>
            </div>
            <RPEDetails rangeClickHandler={rangeClickHandler} rpeTabs={rpeTabs} lastSession={progrmaDetails?.last_session_date} athleteList={athleteList} selectedRange={selectedRange}/>
        </div>
    )
}
