import React from 'react'
import ProgressCard from './ProgressCard';
import CompletedCard from './CompletedCard';

export default function GoalsCard({goal,onCardClickHandler}) {
  console.log(goal,"goallll")
    let completed_exercises = goal?.total_exercises ? ((goal?.completed_exercises/goal?.total_exercises)*100).toFixed() : '';
    let pending_rexercises = completed_exercises ? 100 - completed_exercises : '';
 
    console.log(goal?.total_exercises,goal?.completed_exercises,completed_exercises,pending_rexercises);
    let data = {
        completed_exercises,
        pending_rexercises
    }
  return (    
    <div className={`si-card ${goal?.module_id == 1 ? 'si-snc-card' : 'si-technical-card'} clickable-card`} key={goal?.program_id} onClick={()=>{onCardClickHandler(goal)}}>
                    <p className="si-card-title">{goal?.module_name}</p> 
                    <p className="si-card-subtitle">{goal?.program_name}</p>
                    {completed_exercises != 100 ? <ProgressCard data={data} goal={goal}/> : <CompletedCard data={goal}/> }
                </div>
  )
}
