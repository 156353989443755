import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Dropdown from './Dropdown';

function Header(props) {
  let {headerClass,heading,isHeadingEditable,isbackBtn,backBtnAction,
    isCount,isCountEditable,dataCountHeading,isCalendar,isDropdownEditable} = props.headerData;
  let { isViewOnly=false } = props;
  let {resetHeadingEdit, setResetHeadingEdit} = props
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [dropdown1, setDropdown1] = useState("");
  const [error, setError] = useState({isShow: false});
  const [headingWidth , setHeadingWidth] = useState('40');
  const [sportDDWidth , setSportDDWidth] = useState('12');

  const getHeadingWidth = (heading)=>{
    let count = heading ? heading.trim().length : '';
    let width = count;
    if(count <= 10){
        width = count + (count + 2);
    }else if(count > 10){
        width = width + 9;
    }
    if(!isCalendar){
      width = "100";
    }
    setHeadingWidth(width);
  }

  const getSportDDWidth = ()=>{
    if(isDropdownEditable){
      setSportDDWidth('12');
    }else if(dropdown1?.label){
      let length = dropdown1?.label.length;
      setSportDDWidth(length + 2);
    }
  }

  const isPositiveInteger = (num) => {
    return num > 0 && Number.isInteger(num);
  };

  useEffect(() => {
    setInput1(heading);
  }, [heading]);

  useEffect(()=>{
    getHeadingWidth(input1);
  },[input1,isHeadingEditable])

  useEffect(()=>{
    getSportDDWidth();
  },[dropdown1,isDropdownEditable])

  useEffect(() => {
    setInput2(props.dataCount);
  }, [props.dataCount, isHeadingEditable]);

  useEffect(() => {
    setDropdown1(props.dropdownValue);
  }, [props.dropdownValue, isHeadingEditable]);

  const dropdown1ChangeHandler = (data) => {
    setDropdown1({...data});
  };

  const saveHandler = (e) => {
    if(!input1) {
      setError({isShow: true, message: "Name is required."});
      // toast.warn("Name is required.");
      return;
    };
    if(dataCountHeading && !input2) {
      setError({isShow: true, message: "Duration is required."});
      // toast.warn("Duration is required.");
      return;
    };     
    if(dataCountHeading){
      props.saveAction(e, input1, input2, dropdown1);
    }else{
      props.saveAction(e, input1, dropdown1);
    }      
  };

  const cancelHandler = (e) => {
    e?.preventDefault();
    setInput1(heading);
    props.cancelAction();
  };

  useEffect(()=>{
    if(resetHeadingEdit){
      setInput1(heading)
      setResetHeadingEdit(false)
    }
  },[resetHeadingEdit])
 
  return (
    <div className={`si-headwrp ${headerClass ? headerClass : ''}`}>
      {
        isbackBtn && 
        <button 
            type="button" 
            className="si-icon__button icon-back"
            onClick={backBtnAction}
        >
            <span className="icon-chev-left"></span>
        </button>
      }
      <div className="si-headcover">
        <div className="si-heading">
          {
            isHeadingEditable
            ?
            <div className="si-Disable">
              <div 
                className={`form-control form-control__title ${error?.isShow ? "si-error" : ""}`} 
                style={{width: '35%'}}
              >
                <input 
                  type="text" 
                  className="si-input" 
                  placeholder="Add template name"
                  value={input1 ? input1 : ""} 
                  onChange={(e) => {
                    if(e?.target?.value?.trim()?.length > 50) return;
                    setInput1(e?.target?.value);
                    setError({isShow: false});
                  }}
                  disabled = {!isHeadingEditable}
                />
                <span className={ error?.isShow ? "si-error-message" : "" }>{ error?.message }</span>
              </div>
              {
                (isCount || isCalendar) &&
                <div className="si-wrp">
                 {dataCountHeading &&  <div className="form-control si-weekwrp">
                    <div className="form-control-group">
                      <input 
                        type="text" 
                        className="si-input si-week"
                        value={input2}
                        disabled={!isCountEditable}
                        onChange={(e) => {
                          let value = parseInt(e?.target?.value);
                          setInput2(
                            isPositiveInteger(value) && value < 100
                            ? value 
                            : isPositiveInteger(value) && value >= 100
                            ? input2
                            : ""
                          );
                          setError({isShow: false});
                        }}
                      />
                      <span className="si-label-append">{dataCountHeading}</span>
                    </div>
                  </div>
                }
                  {
                    isCalendar && 
                    <div className="form-control si-sportsdrp">
                      <Dropdown 
                          placeholder={props.dropdownPlaceHolder} 
                          value={dropdown1}
                          options={props.dropdownOptions}
                          changeHandler={dropdown1ChangeHandler}
                          isDisabled={!isDropdownEditable}
                      />
                    </div>
                  }
                </div>
              }
              {
                isCalendar && !isViewOnly && 
                <div className="form-control">
                  <div className="btn-group-wrap activ">
                    <div className="si-button-group">
                      <button 
                        type="button" 
                        className="si-icon__button color-active-1"
                        onClick={saveHandler}
                      >
                        <span className="icon-check"></span>
                      </button>
                      <button 
                        type="button" 
                        className="si-icon__button color-primary-1"
                        onClick={cancelHandler}
                      >
                        <span className="icon-close"></span>
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
            :
            <div className="si-Disabled">
              <div 
                className="si-Title">
                {input1 ? input1 : ""}
              </div>
              {
                (isCount || isCalendar) &&  
                <div className="si-wrp">
                  {dataCountHeading && <div className="si-weeks"><span>{props.dataCount}</span>{dataCountHeading}</div>}
                  {
                    isCalendar && 
                    <div className="si-sports">
                      {dropdown1?.label}
                    </div>
                  }
                </div>
              }
              {
                isCalendar && !isViewOnly && 
                <div className="form-control">
                  <div className="btn-group-wrap active">
                    <button 
                      type="button" 
                      className="si-icon__button"
                      onClick={props.editAction}
                    >
                        <span className="icon-edit"></span>
                    </button>
                  </div>
                </div>
              }
            </div>
          }
        </div>      
      </div>
    </div>
  )
}

export default Header;
