import React from 'react'
import Dropdown from '../../RightPannel/Common/Dropdown'
import { useEffect } from 'react';


const OverallPerformace = ({ programsList, selectedProgram, setSelectedProgram }) => {
  const programsOptions = programsList?.map(obj => {
    return {
      value: obj?.program_id,
      label: obj?.program_name
    };
  });

const programChangeHandler = (data) => {
    console.log("selected program:", data);
    setSelectedProgram(data?.value);
  };
  
  return (
    <div className="si-chart-header">
      <span className="si-chart-header__title">
        Program Performance
      </span>
      <div className="form-control ">

        <Dropdown
          placeholder='Select Program'
          isDisabled={!programsOptions ? true : false}
          value={ programsOptions ? programsOptions?.find(func => func?.value == selectedProgram) :  null}
          options={programsOptions}
          changeHandler={programChangeHandler}
        />

      </div>
    </div>
  )
}

export default OverallPerformace

