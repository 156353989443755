import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchNutritionReportLogListing, fetchNutritionSportsList } from "../../../utility/nutritionApiAction";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import SubHeader from "../Common/SubHeader";
import NutritionReportLogListingContent from "./NutritionReportLogListingContent";
import FixedButton from "../Common/FixedButton";

function NutritionLogListing() {
  const userData = useSelector((state) => state?.auth?.userData);
  let loading = useSelector((state) => state.nutrition.loading);
  let sports = useSelector((state) => state.nutrition.sportsList);
  let reportLogListing = useSelector((state) => state.nutrition.reportLogListing);
  let reportLogListingPagination = useSelector((state) => state.nutrition.reportLogListingPagination);
  let location = useLocation();
  let SELECT_LOG = location?.pathname?.split("/")[3] ? (location?.pathname?.split("/")[3] === "selectLog" ? true : false) : false;
  let [searchTerm, setSearchTerm] = useState(null);
  let [pageNo, setPageNo] = useState(1);
  let { sportId } = useParams();
  let [searchParams] = useSearchParams();
  let activeTabUrl = searchParams?.get("tab");
  let [activeTab, setActiveTab] = useState(null);
  let [selectedRow, SetSelectedRow] = useState(null);
  let [redirectUrl , setRedirectUrl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeTab && activeTabUrl) {
      setActiveTab(Number(activeTabUrl));
    } else if (!activeTab && !activeTabUrl) {
      setActiveTab(1);
    }
  }, [activeTabUrl, activeTab]);

  // useEffect(()=>{
  //   if(SELECT_LOG){
  //     setFilterOptions((old)=> ({...old, is_group_filter: activeTab === 2 ? true: false}))
  //   } else {
  //     setFilterOptions((old)=> ({...old, is_group_filter: false}))
  //   }
  // },[SELECT_LOG, activeTab])

  useEffect(() => {
    if (userData?.user_guid && activeTab) {
      let payload = {
        page_no: pageNo,
        item_number: 20,
        search: searchTerm,
        user_guid: userData?.user_guid,
        type: activeTab,
        sport_id: sportId,
        group_id: null,
      };
      dispatch(fetchNutritionReportLogListing({ payload }));
      //   resetSelectedAthlete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, activeTab, pageNo, userData]);

  useEffect(() => {
    if (!sports?.length && userData) {
      dispatch(fetchNutritionSportsList({ user_guid: userData?.user_guid }));
    }
  }, [dispatch, sports, userData]);

  useEffect(() => {
    if (searchTerm !== null) {
      let injuryListTimeOut = setTimeout(() => {
        let payload = {
          page_no: pageNo,
          item_number: 20,
          search: searchTerm,
          user_guid: userData?.user_guid,
          type: activeTab,
          sport_id: sportId,
          group_id: null,
        };
        dispatch(fetchNutritionReportLogListing({ payload }));
      }, 500);
      return () => clearTimeout(injuryListTimeOut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, dispatch]);

  useEffect(() => {
    if (activeTab) {
      navigate(`?tab=${activeTab}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  let filterOptions = {
    is_search_filter: true,
    is_tab: true,
  };
  return (
    <>
      {loading ? <div className="spin"></div> : null}
      <Header
        headerData={{
          heading: SELECT_LOG ? "Select an Athlete or a Group" : sports?.length
            ? sports.filter((sport) => sport.sport_id === Number(sportId))[0]?.sport_name + " Nutrition Log"
            : "Nutrition Log",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate(-1),
        }}
      />
      <SubHeader
        filterOptions={filterOptions}
        isBtn={SELECT_LOG ? false: true}
        btnName="Add new log"
        onBtnClick={() => navigate("selectLog")}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        // selectedGroupsIds={selectedGroups}
        // setSelectedGroupIds={setSelectedGroups}
        pageNo={pageNo}
        setPageNo={setPageNo}
        tab1Text={"Groups"}
        tab2Text={"Athletes"}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="si-main-content">
        <NutritionReportLogListingContent
          reportList={!SELECT_LOG && reportLogListing ? reportLogListing?.filter(report => report?.nutrition_id) : reportLogListing }
          pagination={reportLogListingPagination}
          SELECT_LOG={SELECT_LOG}
          pageNo={pageNo}
          setPageNo={setPageNo}
          activeTab={activeTab}
          selectedRow={selectedRow}
          SetSelectedRow={SetSelectedRow}
          setRedirectUrl={setRedirectUrl}
        />
      </div>
      {SELECT_LOG ? 
      <Footer
        isActionBtn={true}
        btnClass={`si-button si-button--green`}
        btnDisabled={ !selectedRow ? true :false}
        actionBtnText={"Next"}
        actionBtnHandler={() => redirectUrl ? navigate(redirectUrl) : null}
      /> :       
      <FixedButton btnName="Add new log"         
      onBtnClick={() => navigate("selectLog")}
      iconClass="add" /> }
    </>
  );
}

export default NutritionLogListing;
