import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { resetInjuryDetailsUpdate, resetSelectedInjuryDetails } from "../../../reducer/injury.reducer";
import { ATHELETE_ROLE_ID, INJURY_MODULE_ROUTE } from "../../../utility/constants";
import { fetchInjruyMasters, updateInjruyDetails } from "../../../utility/injuryApiActions";
import Header from "../Common/Header";
import InjuryDetailsAthleteSection from "./InjuryDetailsAthleteSection";
import InjuryDetailsCoachSection from "./InjuryDetailsCoachSection";

function InjuryDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { injuryId, athleteId } = useParams();
  const bodyParts = useSelector((state) => state?.injury?.bodyParts);
  const icdInjuryCodes = useSelector((state) => state?.injury?.icdInjuryCodes);
  const injuryTypes = useSelector((state) => state?.injury?.injuryTypes);
  const selectedInjuryDetails = useSelector((state) => state?.injury?.selectedInjuryDetails);
  const selectedAthleteDetails = useSelector((state) => state?.injury?.selectedAthlete);
  const injuryDetailsUpdated = useSelector((state) => state?.injury?.injuryDetailsUpdated);
  const loading = useSelector((state) => state?.injury?.loading);
  const userData = useSelector((state) => state?.auth?.userData);
  const [file, setFile] = useState([]);
  let [injuryDetails, setInjuryDetails] = useState({
    injuryDate: moment(new Date()).format("YYYY-MM-DD"),
    injuryName: null,
    selectedBodyPartId: null,
    selectedBodyPartName: null,
    injuryDescription: null,
    selectedIcdInjuryCodeId: null,
    selectedIcdInjuryCodeName: null,
    selectedInjuryTypeName: null,
    selectedInjuryTypeId: null,
    coachDescription: null,
  });

  let [showError, setShowErrors] = useState();
  useEffect(() => {
    if (athleteId && !selectedAthleteDetails) {
      navigate(`/${INJURY_MODULE_ROUTE}/athletes`);
    }
  }, [athleteId, navigate, selectedAthleteDetails]);

  useEffect(() => {
    if (!(bodyParts && icdInjuryCodes && injuryTypes) && !injuryId) {
      dispatch(fetchInjruyMasters());
    }
  }, [icdInjuryCodes, bodyParts, injuryTypes, dispatch, injuryId]);

  useEffect(() => {
    if (injuryDetailsUpdated) {
      dispatch(resetInjuryDetailsUpdate());
      if(athleteId){
        navigate(`/${INJURY_MODULE_ROUTE}`)
      } else{
        navigate(-1);
      }
    }
  }, [dispatch, injuryDetailsUpdated, navigate, athleteId]);

  useEffect(() => {
    if (injuryId && userData?.user_guid) {
      dispatch(resetSelectedInjuryDetails());
      dispatch(fetchInjruyMasters({ injuryId, user_guid: userData?.user_guid }));
    } else if (!injuryId) {
      dispatch(resetSelectedInjuryDetails());
    }
  }, [dispatch, injuryId, userData]);

  useEffect(() => {
    if (selectedInjuryDetails?.length) {
      setInjuryDetails(() => ({
        athlete_name: selectedInjuryDetails[0]?.athlete_name ? selectedInjuryDetails[0]?.athlete_name : "",
        injuryDate: selectedInjuryDetails[0]?.injury_date ? selectedInjuryDetails[0]?.injury_date : null,
        injuryName: selectedInjuryDetails[0]?.injury_name ? selectedInjuryDetails[0]?.injury_name : null,
        selectedBodyPartId: selectedInjuryDetails[0]?.body_part_id ? selectedInjuryDetails[0]?.body_part_id : null,
        selectedBodyPartName: selectedInjuryDetails[0]?.body_part_name
          ? selectedInjuryDetails[0]?.body_part_name
          : null,
        injuryDescription: selectedInjuryDetails[0]?.injury_desc ? selectedInjuryDetails[0]?.injury_desc : null,
        selectedIcdInjuryCodeId: selectedInjuryDetails[0]?.icd_injury_code_id
          ? selectedInjuryDetails[0]?.icd_injury_code_id
          : null,
        selectedIcdInjuryCodeName: selectedInjuryDetails[0]?.icd_injury_code
          ? selectedInjuryDetails[0]?.icd_injury_code
          : null,
        selectedInjuryTypeName: selectedInjuryDetails[0]?.injury_type_name
          ? selectedInjuryDetails[0]?.injury_type_name
          : null,
        selectedInjuryTypeId: selectedInjuryDetails[0]?.injury_type_id
          ? selectedInjuryDetails[0]?.injury_type_id
          : null,
        coachDescription: selectedInjuryDetails[0]?.injury_cause ? selectedInjuryDetails[0]?.injury_cause : null,
      }));
      setFile(
        selectedInjuryDetails[0].file_name ? selectedInjuryDetails[0].file_name?.map((file) => ({ name: file })) : []
      );
    }
    return () => {
      setInjuryDetails({
        injuryDate: moment(new Date()).format("YYYY-MM-DD"),
        injuryName: null,
        selectedBodyPartId: null,
        selectedBodyPartName: null,
        injuryDescription: null,
        selectedIcdInjuryCodeId: null,
        selectedIcdInjuryCodeName: null,
        selectedInjuryTypeName: null,
        selectedInjuryTypeId: null,
        coachDescription: null,
      });
      setFile(null);
    };
  }, [dispatch, selectedInjuryDetails]);

  const handleSubmit = (e) => {
    console.log("injuryDetails", injuryDetails);
    if (!injuryDetails?.injuryDate)
      return setShowErrors({ injuryDate: { show: true, msg: "Please enter a vaild date" } });
    if (!injuryDetails?.injuryName || !injuryDetails?.injuryName?.trim())
      return setShowErrors({ injuryName: { show: true, msg: "Please enter injury name" } });
    if (injuryDetails?.injuryName && injuryDetails?.injuryName?.length >= 50)
      return setShowErrors({ injuryName: { show: true, msg: "Injury name should be of 50 characters or less." } });
    if (!injuryDetails?.selectedBodyPartId) return setShowErrors({ selectedBodyPartId: { show: true, msg: "Please select body part" } });
    if (!injuryDetails?.injuryDescription || !injuryDetails?.injuryDescription?.trim()) return setShowErrors({ injuryDescription: { show: true, msg: "Please enter description" } });
    if(Number(userData?.role_id) !== ATHELETE_ROLE_ID && athleteId){
      if (!injuryDetails?.selectedIcdInjuryCodeId && Number(userData?.role_id) !== ATHELETE_ROLE_ID)
      return setShowErrors({ selectedIcdInjuryCodeId: { show: true, msg: "Please select injury code" } });
    }
    // if(!injuryDetails?.injuryName) return setShowErrors({injuryName:{show:true, text:"error"}})
    // if(!injuryDetails?.injuryName) return setShowErrors({injuryName:{show:true, text:"error"}})
    // if(!injuryDetails?.injuryName) return setShowErrors({injuryName:{show:true, text:"error"}})

    let payload = {
      injury_name: injuryDetails?.injuryName,
      injury_type_id: injuryDetails?.selectedInjuryTypeId,
      body_part_id: injuryDetails?.selectedBodyPartId,
      icd_injury_code_id: injuryDetails?.selectedIcdInjuryCodeId,
      //   check here for desc and cause
      injury_desc: injuryDetails?.injuryDescription,
      injury_cause: injuryDetails?.coachDescription,
      injury_date: injuryDetails?.injuryDate,
      file_name: file?.map((item) => item.name),
      is_recovered: 0,
      user_guid: userData?.user_guid,
      athlete_guid:
        Number(userData?.role_id) === ATHELETE_ROLE_ID
          ? userData?.user_guid
          : selectedAthleteDetails && athleteId
          ? selectedAthleteDetails.user_guid
          : injuryDetails?.athlete_guid,
      athlete_injury_id: injuryId ? injuryId : null,
    };
    dispatch(updateInjruyDetails({ payload }));
  };
  return (
    <>
      {loading && <div className="spin"></div>}
      <Header
        headerData={{
          heading: "Injury Details",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate(-1, { replace: true }),
        }}
      />
      <div className="si-main-content injury-details-main">
        <div className="injury-athelet-profile">
          <div className="injury-athelet-profile-img">
            <img src="../../images/person.png" alt="" />
          </div>
          <h3 className="injury-athelet-profile-name">
            {Number(userData?.role_id) === ATHELETE_ROLE_ID
              ? `${userData?.first_name ? userData?.first_name : ""} ${
                  userData?.middle_name ? userData?.middle_name : ""
                } ${userData?.last_name ? userData?.last_name : ""}`
              : selectedAthleteDetails
              ? `${selectedAthleteDetails?.first_name ? selectedAthleteDetails?.first_name : ""} ${
                  selectedAthleteDetails?.middle_name ? selectedAthleteDetails?.middle_name : ""
                } ${selectedAthleteDetails?.last_name ? selectedAthleteDetails?.last_name : ""}`
              : injuryDetails?.athlete_name}{" "}
          </h3>
        </div>
        <div className="injury-details-form-content">
          <InjuryDetailsAthleteSection
            bodyParts={bodyParts}
            injuryDetails={injuryDetails}
            setInjuryDetails={setInjuryDetails}
            setShowErrors={setShowErrors}
            showError={showError}
            file={file}
            setFile={setFile}
          />
          <InjuryDetailsCoachSection
            injuryTypes={injuryTypes}
            icdInjuryCodes={icdInjuryCodes}
            injuryDetails={injuryDetails}
            setInjuryDetails={setInjuryDetails}
            setShowErrors={setShowErrors}
            showError={showError}
            userData={userData}
          />
        </div>
        <div className="injury-details-footer">
          <button type="button" className="si-button si-button--green" onClick={(e) => handleSubmit(e)}>
            save
          </button>
        </div>
      </div>
    </>
  );
}

export default InjuryDetails;
