import React, { useEffect } from 'react'
import { Fragment } from 'react'
import Header from '../Common/Header'
import SubHeader from '../Common/SubHeader'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PsychologyCaseListContent from './PsychologyCaseListContent';
import { useSelector } from 'react-redux';
import { fetchCaseList } from '../../../utility/PsychologyApiActions';
import { resetCaseDetails, resetSelectedAthlete, resetSelectedPsychologyCase } from '../../../reducer/psychology';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../../reducer/loader';
import FixedButton from '../Common/FixedButton';
import { userManagementActions } from '../../../reducer/userManagement';
import { ATHELETE_ROLE_ID } from '../../../utility/constants';

export default function PsychologCaseListing() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let [searchTerm, setSearchTerm] = useState(null);
    let [pageNo, setPageNo] = useState(1);
    const [isAddBtn, setIsAddBtn] = useState(true);
    const loading = useSelector((state) => state.psychology?.loading);
    const userData = useSelector((state) => state?.auth?.userData);
    const caseList = useSelector((state) => state?.psychology?.caseList?.data);
    const paginationObject = useSelector((state) => state?.psychology?.caseList?.pagination);
    let filterOptions = {
        is_sport_filter: false,
        is_search_filter_for_athlete:true,
        is_tab: false,
        is_group_filter: false,
    };

    useEffect(() => {
        if (userData && userData?.role_id == ATHELETE_ROLE_ID) {
            setIsAddBtn(false);
        }
    }, [userData]);

    useEffect(()=>{
        localStorage.removeItem("guid");
        dispatch(userManagementActions.resetAthleteDetails());
        dispatch(resetSelectedPsychologyCase());
        dispatch(resetCaseDetails());
    },[])

    useEffect(() => {
        if (userData?.user_guid) {
            let payload = {
                page_no: pageNo,
                item_number: 20,
                search: searchTerm,
                user_guid: userData?.user_guid,
                from_date: null,
                to_date: null,
            };
            dispatch(fetchCaseList({ payload }));
            resetSelectedAthlete();
        }
    }, [dispatch, pageNo, userData]);

    useEffect(() => {
        if (searchTerm !== null) {
            let caseListTimeout = setTimeout(() => {
                let payload = {
                    page_no: pageNo,
                    item_number: 20,
                    search: searchTerm,
                    user_guid: userData?.user_guid,
                    from_date: null,
                    to_date: null,
                };
                dispatch(fetchCaseList({ payload }));
            }, 1000);
            return () => clearTimeout(caseListTimeout);
        }
    }, [searchTerm, dispatch]);

    useEffect(() => {
        if (loading) {
            dispatch(loaderActions?.showLoader());
        } else {
            dispatch(loaderActions?.hideLoader());
        }
    }, [loading]);

    return (
        <Fragment>
            <Header
                headerData={{
                    heading: "Psychology Cases",
                    isHeadingEditable: false,
                }}
            />

            <SubHeader
                filterOptions={filterOptions}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                pageNo={pageNo}
                setPageNo={setPageNo}
                navigate={navigate}
                isBtn={isAddBtn}
                btnName='Add New Case'
                onBtnClick={()=>navigate("athletes")}
            />

            <div className="si-main-content">
                <PsychologyCaseListContent
                    caseList={caseList}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    pagination={paginationObject}
                    userData={userData}
                />
            </div>
            {isAddBtn ?  <FixedButton btnName="Add new case" onBtnClick={() => navigate(`athletes`)} iconClass="add" /> : null}
        </Fragment>
    )
}
