import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loaderActions } from '../../../../reducer/loader';
import { templatesActions } from '../../../../reducer/templates';
import { fetchSportsList, fetchTemplateList } from '../../../../utility/templateApiActions';
import Content from './Content';
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import SubHeader from '../../Common/SubHeader';
import FixedButton from '../../Common/FixedButton';

function CreateProgram() {
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const [isFiltered, setIsFiltered] = useState(false);
    const [selectedSports, setSelectedSports] = useState([]);
    const [isFetchFulfilled, setIsFetchFulfilled] = useState(false);
    const templateListing = useSelector(state => state?.templates?.templateListing);
    const paginationObject = useSelector(state => state?.templates?.paginationObject);
    const selectedMenuId = useSelector(state => state?.sideMenu?.selectedMenuId);
    let filterOptions = {
        is_sport_filter:true,
        is_search_filter:true
    };
    const { setIsUpdateDisabled } = templatesActions;
    const dispatch = useDispatch();
    const navigate = useNavigate(); 

    useEffect(() => {
        
        let templateListTimeOut = setTimeout(() => {
            if(searchTerm!==null) dispatch(loaderActions?.showLoader());            
            if(selectedMenuId?.moduleId) {
                dispatch(
                    fetchTemplateList({
                        pageNo,
                        searchTerm,
                        selectedSports,
                        moduleId: selectedMenuId?.moduleId,
                        callback: () => setTimeout(() =>{
                            dispatch(loaderActions?.hideLoader());
                            setIsFetchFulfilled(true);
                        })
                    })
                );
            };
        }, 1000);

        if(pageNo!==1 || searchTerm || selectedSports?.length) setIsFiltered(true);

        return () => clearTimeout(templateListTimeOut);
    }, [selectedMenuId, searchTerm]);

    useEffect(() => {
        let templateListTimeOut = setTimeout(() => {
            if(selectedMenuId?.moduleId){
                dispatch(loaderActions?.showLoader());
                dispatch(
                    fetchTemplateList({
                        pageNo,
                        searchTerm,
                        selectedSports,
                        moduleId: selectedMenuId?.moduleId,
                        callback: () => setTimeout(() => {
                            dispatch(loaderActions?.hideLoader());
                            setIsFetchFulfilled(true);
                        })
                    })
                );
            }
        }, 0);

        if(pageNo!==1 || selectedSports?.length) setIsFiltered(true);

        return () => clearTimeout(templateListTimeOut);
    }, [selectedMenuId, pageNo, selectedSports]);

    useEffect(() => {
        dispatch(fetchSportsList());
        dispatch(setIsUpdateDisabled(true));
    }, []);

    const createBtnClickHandler = (id)=>{
        navigate("/snc/templates/new")
    }
    // console.log(templateListing?.length, isFiltered, templateListing?.length || isFiltered)

    return (
        <Fragment>
            <Header 
                headerData={{
                    heading: "Program Templates",
                    isHeadingEditable: false
                }}
            />
            {
                templateListing?.length || isFiltered
                ?
                <>
                    <SubHeader 
                        filterOptions={filterOptions}
                        searchTerm={ searchTerm }
                        setSearchTerm={ setSearchTerm }
                        selectedSports={ selectedSports }
                        setSelectedSportIds={ setSelectedSports }
                        pageNo={ pageNo }
                        setPageNo={ setPageNo }
                        navigate={ navigate }
                        isBtn={true}
                        btnName="Create Template"
                        onBtnClick={createBtnClickHandler}
                    />
                    <Content 
                        pageNo={ pageNo }
                        setPageNo={setPageNo} 
                        pagination={paginationObject}
                        perPage={20}
                        searchTerm={ searchTerm }
                        selectedSports={ selectedSports }
                        setIsFiltered={ setIsFiltered }
                    />
                    {/* <Footer 
                        pageNo={pageNo} 
                        setPageNo={setPageNo} 
                        pagination={paginationObject}
                        perPage={20}
                    /> */}
                    <FixedButton btnName="Create Template"
                        onBtnClick={createBtnClickHandler} iconClass='add'/>
                </>
                :
                isFetchFulfilled && 
                <div className="si-main-content">
                    <div className="si-firstStep si-align-center">
                        <div className="si-text">
                            <span>Click here to create new program template</span>
                        </div>
                        <button 
                            type="button" 
                            className="si-button si-button--green si-icon--add"
                            onClick={() => navigate("/snc/templates/new")}
                        >
                            <span className="icon-add"></span>Create Template
                        </button>
                    </div>
                </div>
            }
        </Fragment>
    )
};

export default CreateProgram;