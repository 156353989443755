import { createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "../reducer/auth";
import API from "./api";

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchCaseList = createAsyncThunk(
  "psychology/getpsychcaseloglisting",
  async ({ payload }, { dispatch, rejectWithValue }) => {
    try {
      let caseList = await (await postAPIDetails("common/getpsychcaseloglisting", payload)).data;
      return { caseList };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getCaseLog = createAsyncThunk(
  "psychology/getpsychcaselog",
  async ({ payload }, { getState, dispatch, rejectWithValue }) => {
    try {
      let state = getState();
      let userData = state?.auth?.userData;
      let caseFileDetails = await (await getAPIDetails(`common/getpsychcaselog?case_id=${payload?.case_id}&athlete_guid=${payload?.athlete_guid}&user_guid=${userData?.user_guid}`)).data;
      return { caseFileDetails };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchEducationList = createAsyncThunk(
  "psychology/geteducationmaster",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      return await getAPIDetails("/common/geteducationmaster");
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchMaritalStatusList = createAsyncThunk(
  "psychology/getmaritalstatusmaster",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      return await getAPIDetails("/common/getmaritalstatusmaster");
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchInformantList = createAsyncThunk(
  "psychology/getinformantmaster",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      return await getAPIDetails("/common/getinformantmaster");
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const insertUpdatePsychologyCase = createAsyncThunk(
  "psychology/insertupdatepsychcaselog",
  async ({ payload,callback }, { dispatch, rejectWithValue }) => {
    try {
      let caseId = await (await postAPIDetails("common/insertupdatepsychcaselog", payload)).data;
      return { caseId , callback };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchCaseLogList = createAsyncThunk(
  "psychology/getpsychsessionloglisting",
  async ({ payload }, { dispatch, rejectWithValue }) => {
    try {
      let caseLogList = await (await postAPIDetails("common/getpsychsessionloglisting", payload)).data;
      return { caseLogList };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getSessionName = createAsyncThunk(
  "psychology/getpsychsessionname",
  async ({ payload }, { getState, dispatch, rejectWithValue }) => {
    try {
      let state = getState();
      let userData = state?.auth?.userData;
      payload.user_guid = userData?.user_guid;
      let sessionName = await (await postAPIDetails("common/getpsychsessionname", payload)).data;
      return { sessionName };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const insertUpdatePsychologySession = createAsyncThunk(
  "psychology/insertupdatepsychsessionlog",
  async ({ payload, callback }, {getState, dispatch, rejectWithValue }) => {
    try {
      let state = getState();
      let userData = state?.auth?.userData;
      payload.user_guid = userData?.user_guid;
      let sessionId = await (await postAPIDetails("common/insertupdatepsychsessionlog", payload)).data;
      if(!sessionId){
        return rejectWithValue({ error : {message:"Error while saving psychology session details"}})
      }
      return { sessionId ,callback };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const getSessionLog = createAsyncThunk(
  "psychology/getpsychsessionlog",
  async ({ payload }, { getState, dispatch, rejectWithValue }) => {
    try {
      let state = getState();
      let userData = state?.auth?.userData;
      let sessionDetails = await (await getAPIDetails(`common/getpsychsessionlog?sessionId=${payload?.session_id}&user_guid=${payload?.user_guid}`)).data;
      return { sessionDetails };
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

