import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {TableBody, TableRow, TableCell} from '@mui/material';
import ListingTable from '../../Common/ListingTableMUI';

function Content({ activeTab, pageNo, setPageNo, pagination, perPage,subModule }) {
    const athleteProgramsListing = useSelector(state => state?.programs?.athleteProgramsListing);
    const userData = useSelector(state => state?.auth?.userData);
    const [athletePrograms, setAthletePrograms] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setAthletePrograms(athleteProgramsListing);
    }, [athleteProgramsListing]);
    
    const handleClick = (e, id) => {
        e?.stopPropagation();
        navigate(`/snc/${subModule}/${id}/progressCalendar/${userData?.user_id}`,{state:{backUrl:`/snc/${subModule}`}})
    };

    const tableHeaders = activeTab === 1 ? 
        [
            {
                label: "Program Name",
                align: "left"
            },
            {
                label: "Duration",
                align: "center"
            },
            {
                label: "Start Date",
                align: "center"
            },
            {
                label: "Overall Performance",
                align: "center"
            },
            {
                label: "Action",
                align: "center",
                stickyClass: "stickyCell-right" 
            }
        ] : 
        activeTab === 2 ? 
        [
            {
                label: "Program Name",
                align: "left",
                stickyClass: "stickyCell-left" 
            },
            {
                label: "Duration",
                align: "center"
            },
            {
                label: "Start Date",
                align: "center"
            }
        ] : 
        [
            {
                label: "Program Name",
                align: "left",
                stickyClass: "stickyCell-left",
            },
            {
                label: "Duration",
                align: "center"
            },
            {
                label: "Start Date",
                align: "center"
            },
            {
                label: "Overall Completion",
                align: "center"
            }
        ];

    const formatDate = (date)=>{
        let objectDate = new Date(date);
        let day = objectDate.getDate();
        let month = objectDate.getMonth() + 1;
        let year = objectDate.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const getTableBody = () => {
        return (
            <TableBody sx={{backgroundColor:"#181818"}}>
                {
                    athletePrograms?.length
                    ?
                    athletePrograms
                        ?.map((program, idx) => {
                        return (
                            <TableRow 
                                key={uuidv4()}
                            >
                                <TableCell 
                                    align={"left"} 
                                    className={activeTab === 2 || activeTab === 3 ? "stickyCell-left":""}
                                    sx={{color:"#fff", cursor: "pointer", minWidth: 150}}
                                    onClick={(e) => handleClick(e, program?.program_id)}
                                >
                                    {program?.program_name}
                                </TableCell>
                                {/* <TableCell align={"center"} sx={{color: "#fff"}}>
                                    {program?.sport_name ? program?.sport_name : '-'}
                                </TableCell> */}
                                <TableCell align={"center"} sx={{color: "#fff"}}>
                                    { 
                                        `${program?.duration > 1 
                                        ? (program?.duration + " Weeks") 
                                        : (program?.duration + " Week") }` 
                                    }
                                </TableCell>
                                <TableCell align={"center"} sx={{color: "#fff"}}>
                                    {program?.start_date ? formatDate(program?.start_date) : '-'}
                                </TableCell>
                                {
                                    (activeTab === 1 || activeTab === 3) && 
                                    <>
                                    <TableCell align={"center"} sx={{color: "#fff"}}>
                                        {program?.progress_val ? program?.progress_val + "%" : 0 + "%"}
                                    </TableCell>
                                    {
                                        activeTab !== 3 &&
                                        <TableCell 
                                            align={"center"} 
                                            sx={{color: "#fff"}}
                                            className="stickyCell-right"
                                        >
                                            <button 
                                                type="button" 
                                                className="si-logBtn"
                                                disabled={activeTab!==1 ? true : false}
                                                onClick={(e) => handleClick(e, program?.program_id)}
                                            >
                                                <span className="si-txt">Log Progress</span>
                                            </button>
                                        </TableCell>
                                    }
                                    </>
                                }
                               
                            </TableRow>
                            
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={tableHeaders.length} sx={{color: "#fff"}}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    };

    return (
        <div className="si-main-content">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={pagination}
                perPage={perPage}
            />
        </div>
    );
};

export default memo(Content);