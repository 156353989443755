import React from 'react';
import {TablePagination} from '@mui/material';

function PopupFooter({isActionBtn, isBtnDisabled, btnClass, actionBtnText, actionBtnHandler,
    isActionBtns, actionBtn1Text, btn1Class, actionBtn1Handler, isViewOnly, isExerciseMaster,
    actionBtn2Text, btn2Class, actionBtn2Handler, isEditExercisePopup, selectedTab,
    pagination, pageNo, setPageNo, perPage}) {

    const handleChangePage = (e, newPage) => {
        setPageNo(newPage+1);
    };

    return (
        <div 
            className="si-popup-footer" 
        >
            {
                pagination && 
                <div className="si-pagination">
                    <TablePagination
                        component="div"
                        className="si-tbl-pagination"
                        count={pagination?.total}
                        rowsPerPage={perPage}
                        page={pageNo-1}
                        onPageChange={handleChangePage}
                    />
                </div>
            }
            {
                isEditExercisePopup && 
                <div className="si-footer-warning">
                    {
                        selectedTab==="specs" && !isViewOnly && !isExerciseMaster &&
                        <>
                            <span className="icon-info"></span>
                            <span>These are the default specs.<br/>
                            You can change them as per your requirements.</span>
                        </>
                    }
                    
                    {
                        selectedTab==="specs" && isExerciseMaster && 
                        <>
                            <span className="icon-info"></span>
                            <span>These are the default number of specs.<br/>
                            Coaches can change the numbers as per their requirements.</span>
                        </>
                    }
                </div>
            }
            {
                isActionBtn && 
                <div className="si-action-buttons">
                    <button 
                        type="button" 
                        className={btnClass}
                        onClick={actionBtnHandler}
                        disabled={isBtnDisabled}
                    >
                        {actionBtnText}
                    </button>
                </div>
            }
            {
                isActionBtns && 
                <div className="si-action-buttons">
                    <button 
                        type="button" 
                        className={btn1Class}
                        onClick={actionBtn1Handler}
                    >{actionBtn1Text}</button>

                    <button 
                        type="button" 
                        className={`${btn2Class} ${actionBtn2Text == 'Ok' ? 'active' : ''}`}
                        onClick={actionBtn2Handler}
                    >{actionBtn2Text}</button>
                </div>
            }
        </div>
    );
};

export default PopupFooter;