import { createSlice } from "@reduxjs/toolkit";
import { fetchAthleteGroupList , insertUpdateAthleteGroup ,
    fetchGroupDetailsById ,fetchGroupAthletes,deleteAthleteFromGroup, deleteGroupofAthlete,fetchAllAthletes, fetchAthleteDetails} from "../utility/userManagementApiActions";
import { toast } from 'react-toastify';

const initialState = {
    isUpdateDisabled: true,
    groupName: "",
    selectedSportId: "",
    groupNameError: false,
    groupNameErrorMsg: "",
    selectedSportError:false,
    selectedSportErrorMsg:"",
    selectedGroup:null,
    paginationObject:null,
    allAthletePaginationObject:null,
    athleteListing: null,
    groupList: null,
    groupAthleteListing: null,
    selectedWeek: 1,
    addExerciseDay: null,
    selectedExerciseList: null,
    editExercise: null,
    athleteDetails:null
};

const userManagementSlice = createSlice({
    name: "userManagement",
    initialState,
    reducers: {
        setIsUpdateDisabled: (state, action) => {
            state.isUpdateDisabled = action?.payload;
        },
        setGroupName: (state, action) => {
            state.groupName = action?.payload?.name?.trim()?.length && action?.payload?.name?.trim()?.length <= 50
                                    ? action?.payload?.name
                                    : action?.payload?.name?.trim()?.length && action?.payload?.name?.trim()?.length > 50
                                    ? state?.groupName
                                    : "";
            state.groupNameError = action?.payload?.name?.trim()?.length && action?.payload?.name?.trim()?.length <= 50 
                                    ? false 
                                    : true;
            state.groupNameErrorMsg = action?.payload?.name?.trim()?.length && action?.payload?.name?.trim()?.length > 50 
                                    ? "Group name should be of 50 characters or less."
                                    : !action?.payload?.name?.trim()?.length
                                    ? "Group name is required."
                                    : "";
            if(action?.payload?.callback) action?.payload?.callback();
        },
        setSelectedSportId: (state, action) => {
            state.selectedSportId = action?.payload?.sportId;
            state.selectedSportError = action?.payload?.sportId?.trim() ? false : true;
            state.selectedSportErrorMsg = !action?.payload?.sportId?.trim() ? "Sport is required." : "";
            if(action?.payload?.callback) action?.payload?.callback();
        },
        setErrors: (state) => {
            if(!state?.groupName) {
                state.groupNameError = true;
                state.groupNameErrorMsg = "Group name is required.";
            };
            if(!state?.selectedSportId) {
                state.selectedSportError = true;
                state.selectedSportErrorMsg = "Sport is required.";
            };
        },
        setDefault: (state) => {
            state.groupName = "";
            state.selectedSportId = "";
            state.groupNameError = false;
            state.selectedSportError = false;
            state.groupNameErrorMsg = '';
            state.selectedSportErrorMsg = '';
        },
        resetAthleteDetails : (state)=>{
            state.athleteDetails = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAthleteGroupList?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.groupList = apiResponse?.data?.data?.value?.data;
            state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            if(callback) callback();
        });
        builder.addCase(fetchAthleteGroupList.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            state.groupList = null;
            toast?.error(`Unable to fetch athlete groups. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch athlete groups Error: ", error);
            if(callback) callback();
        });
        builder.addCase(insertUpdateAthleteGroup.fulfilled, (state, action) => {
            const { callback, apiResponse } = action?.payload;
            if(apiResponse?.data?.data?.value) {
                let data = apiResponse?.data?.data?.value?.data[0];
                state.selectedGroup = { 
                    ...data
                };
            };  
            if(action?.payload?.isNewGroup) state.isNewGroup = action?.payload?.isNewGroup;
            if(callback) callback(apiResponse?.data?.data?.value?.data[0].group_id);
            // console.log(apiResponse?.data);
        });
        builder.addCase(insertUpdateAthleteGroup.rejected, (state, action) => {
            state.groupNameError = true;
            state.groupNameErrorMsg = "Group with the same name already exist.";
            // toast?.error(`Error while creating template: \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Post Group Error: ", action?.error);
        });
        builder.addCase(fetchGroupDetailsById.fulfilled, (state, action) => {
            let { apiResponse, callback } = action?.payload;
            if(apiResponse?.data?.data?.value?.data?.[0]) {
                let data = apiResponse?.data?.data?.value?.data?.[0];
                state.selectedGroup = {
                    ...data,
                };
            };
            if(callback) callback(apiResponse?.data?.data?.value?.data?.[0]);
        });
        builder.addCase(fetchGroupDetailsById.rejected, (state, action) => {
            toast?.error(`Unable to fetch group. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch Group Error: ", action?.error);
        });
        builder.addCase(fetchGroupAthletes.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
                state.groupAthleteListing = apiResponse?.data?.data?.value?.data;
                state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            if(callback) callback();
        });
        builder.addCase(fetchGroupAthletes.rejected, (state, action) => {
            toast?.error(`Unable to fetch group athlete data. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch group athlete Error: ", action?.error);
        });
        builder.addCase(deleteAthleteFromGroup?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            if(apiResponse?.data?.data?.value?.data) {
                let athlete = apiResponse?.data?.data?.value?.data[0].athlete_ids;
                state.selectedGroup = {
                    ...state.selectedGroup,
                    athlete_ids : athlete
                };
            };
            if(apiResponse?.data?.meta?.success) toast.success("Athlete removed successfully.", {toastId: `athlete_remove_success ${action.meta.arg.athleteId}`});
            else toast.warn("Unable to remove athlete from the group. Please try again.");
            if(callback) callback();
        });
        builder.addCase(deleteAthleteFromGroup.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            toast?.error(`Unable to remove athletes. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Remove Athletes Error: ", error);
            if(callback) callback();
        });
        builder.addCase(deleteGroupofAthlete?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            if(apiResponse?.data?.data?.value) {
                state.groupList = apiResponse?.data?.data?.value?.data;
                state.paginationObject = apiResponse?.data?.data?.value?.pagination;
            };
            if(apiResponse?.data?.meta?.success) toast.success("Group deleted successfully.", {toastId: `athlete_remove_success ${action.meta.arg.groupId}`});
            else toast.warn("Unable to delete group. Please try again.");
            if(callback) callback();
        });
        builder.addCase(deleteGroupofAthlete.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            toast?.error(`Unable to delete group. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Delete groups Error: ", error);
            if(callback) callback();
        });
        builder.addCase(fetchAllAthletes?.fulfilled, (state, action) => {
            let {callback, apiResponse} = action?.payload;
            state.athleteListing = apiResponse?.data?.data?.value?.data;
            state.allAthletePaginationObject = apiResponse?.data?.data?.value?.pagination;
            if(callback) callback();
        });
        builder.addCase(fetchAllAthletes.rejected, (state, action) => {
            let {callback, error} = action?.payload;
            toast?.error(`Unable to fetch athletes. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : error?.message}`,{toastId:"apiErrorToast"});
            console.log("Fetch Athletes Error: ", error);
            if(callback) callback();
        });
        builder.addCase(fetchAthleteDetails.fulfilled, (state, action) => {
            let { apiResponse, callback } = action?.payload;
            state.athleteDetails = apiResponse?.data?.data?.value?.data;
            if(callback) callback(true);
        });
        builder.addCase(fetchAthleteDetails.rejected, (state, action) => {
            // toast?.error(`Error while fetching athlete details: \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
            toast?.error(`Unable to fetch athlete details. Please try again later.`,{toastId:"apiErrorToast"});
            state.athleteDetails = '';
            console.log("Fetch Athlete Details Error: ", action?.error);
        });
    }
});

export const userManagementActions = userManagementSlice?.actions;
export default userManagementSlice?.reducer;