import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loaderActions } from '../../../../reducer/loader';
import { templatesActions } from '../../../../reducer/templates';
import { fetchProgramsList } from '../../../../utility/programApiActions';
import Content from './Content';
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import SubHeader from '../../Common/SubHeader';
import FixedButton from '../../Common/FixedButton';
import { fetchSportsList } from '../../../../utility/templateApiActions';

function ProgramListing() {
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const [isFiltered, setIsFiltered] = useState(false);
    const [selectedSports, setSelectedSports] = useState([]);
    const [isFetchFulfilled, setIsFetchFulfilled] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const programListing = useSelector(state => state?.programs?.programListing);
    const paginationObject = useSelector(state => state?.programs?.paginationObject);
    const isAuth = useSelector(state => state?.auth?.isAuthenticated);
    const { setIsUpdateDisabled } = templatesActions;
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    let filterOptions = {
        is_sport_filter: true,
        is_search_filter: true,
        is_tab:true,
        is_group_filter: false
    };

    useEffect(() => {
       dispatch(fetchSportsList());
       dispatch(setIsUpdateDisabled(true));
    }, []);

    useEffect(() => {
        let programListTimeOut = setTimeout(() => {
            if(searchTerm!==null) dispatch(loaderActions?.showLoader());            
            dispatch(
                fetchProgramsList({
                    pageNo,
                    activeTab,
                    searchTerm,
                    selectedSports,
                    callback: () => setTimeout(() =>{
                        dispatch(loaderActions?.hideLoader());
                        setIsFetchFulfilled(true);
                    })
                })
            );
        }, 1000);

        if(pageNo!==1 || searchTerm || selectedSports?.length) setIsFiltered(true);

        return () => clearTimeout(programListTimeOut);
    }, [searchTerm]);

    useEffect(() => {
        if(isAuth){
            let programListTimeOut = setTimeout(() => {
                dispatch(loaderActions?.showLoader());
                dispatch(
                    fetchProgramsList({
                        pageNo,
                        activeTab,
                        searchTerm,
                        selectedSports,
                        callback: () => setTimeout(() => {
                            dispatch(loaderActions?.hideLoader());
                            setIsFetchFulfilled(true);
                        })
                    })
                );
            }, 0);

            if(pageNo!==1 || selectedSports?.length) setIsFiltered(true);

            return () => clearTimeout(programListTimeOut);
        }
    }, [isAuth,pageNo, selectedSports, activeTab]);

    // useEffect(()=>{
    //     setPageNo(1);
    //     setSearchTerm('');
    //     setSelectedSports([]);
    // },[activeTab]);

    const getProgramlisting = (callback)=>{
        dispatch(
            fetchProgramsList({
                pageNo,
                activeTab,
                searchTerm,
                selectedSports,
                callback: () => setTimeout(() => {
                    callback(true);
                })
            })
        );
    }

    const createBtnClickHandler = (id)=> {
        navigate("/snc/programs/templates")
    };

    return (
        <Fragment>
            <Header 
                headerData={{
                    heading: "Current Programs",
                    isHeadingEditable: false
                }}
            />
            {
                // programListing?.length || isFiltered
                // ?
                <>
                    <SubHeader 
                        filterOptions={filterOptions}
                        searchTerm={ searchTerm }
                        setSearchTerm={ setSearchTerm }
                        selectedSportIds={ selectedSports }
                        setSelectedSportIds={ setSelectedSports }
                        pageNo={ pageNo }
                        setPageNo={ setPageNo }
                        navigate={ navigate }
                        isBtn={true}
                        btnName="Create Program"
                        onBtnClick={createBtnClickHandler}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                    <Content 
                        pageNo={ pageNo }
                        setPageNo={ setPageNo } 
                        pagination={ paginationObject }
                        perPage={ 20 }
                        searchTerm={ searchTerm }
                        selectedSports={ selectedSports }
                        activeTab={activeTab}
                        subModule={'programs'}
                    />
                    <FixedButton btnName="Create Program"
                        onBtnClick={createBtnClickHandler} iconClass='add'/>
                </>
            }
        </Fragment>
    )
};

export default ProgramListing;