import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ListingTable from "../Common/ListingTableMUI";
import moment from "moment/moment";
import { ATHELETE_ROLE_ID } from "../../../utility/constants";

export default function PsychologyCaseListContent({ caseList, pageNo, setPageNo, pagination, userData }) {
  const navigate = useNavigate();

  const tableHeaders = [
    {
      label: "Athlete Name",
      align: "left",
    },
    {
      label: "Case Name",
      align: "left",
    },
    {
      label: "Case Opened on",
      align: "left",
    },
    {
      label: "",
      align: "right",
      stickyClass: "stickyCell-right",
    },
  ];

  const athleteTableHeaders = [
    {
      label: "Case Name",
      align: "left",
    },
    {
      label: "Case Opened on",
      align: "left",
    },
    {
      label: "",
      align: "right",
      stickyClass: "stickyCell-right",
    },
  ];

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  const handleClick = (caseD) => {
    localStorage.setItem("guid", caseD.athlete_guid);
    navigate(`case/${caseD?.case_id}`);
  };

  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {caseList?.length ? (
          caseList?.map((caseD, idx) => {
            return (
              <TableRow key={idx}>
                {Number(userData?.role_id) === ATHELETE_ROLE_ID ? null : (
                  <TableCell align={"left"} style={{ cursor: "pointer" }} onClick={(e) => handleClick(caseD)}>
                    {caseD?.athlete_name ? caseD?.athlete_name : "-"}
                  </TableCell>
                )}
                <TableCell align={"left"} 
                  style={Number(userData?.role_id) === ATHELETE_ROLE_ID ?  {cursor:"pointer"}: null} 
                  onClick={(e)=> Number(userData?.role_id) === ATHELETE_ROLE_ID ? handleClick(caseD): null}
                  >
                    {caseD?.case_name}
                </TableCell>
                <TableCell align={"left"}>{caseD?.case_opened_on ? formatDate(caseD?.case_opened_on) : "-"}</TableCell>
                <TableCell align={"right"} className="stickyCell-right">
                  <button type="button" className="si-viewBtn" onClick={(e) => handleClick(caseD)}>
                    <span className="si-icon__view"></span>
                    <span className="si-txt">View details</span>
                  </button>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  useEffect(() => {
    getTableBody();
  }, [caseList]);

  return (
    <>
      <ListingTable
        tableHeaders={Number(userData?.role_id) === ATHELETE_ROLE_ID ? athleteTableHeaders : tableHeaders}
        getTableBody={getTableBody}
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={pagination}
        perPage={20}
      />
    </>
  );
}
