import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chartv4";
import Annotation from "chartjs-plugin-annotation";
import { useSelector } from 'react-redux';
import {
  annotationX1, annotationX2, annotationImgBorder1, annotationImg1,
  annotationY1, annotationY2, annotationLabel, value
} from "../Config";

function PerformanceChart() {
  let newChart = '';
  const prgramPerformanceData = useSelector((state) => state?.dashboard?.prgramPerformance);

  useEffect(() => {
    if (prgramPerformanceData) {
      let dataLabel = prgramPerformanceData?.athlete_performance?.map((athlete) => {
        const text = athlete?.athlete_name;
       let  athleteName = text.split(" ");
       let athleteFirstName = athleteName?.[0];
       let athleteLastName = '';
       athleteName?.map((name,index)=>{
       if(index != 0){
       athleteLastName += ` ${name}`; 
       }})
        let name= [athleteFirstName,athleteLastName];
        return name;
      }  
      );
     
      let dataValue = prgramPerformanceData?.athlete_performance?.map((athlete) => {
        return athlete.overall_performance ? athlete.overall_performance : 0;
      });
     
    
      createChart(dataLabel,dataValue);
      return () => {
        newChart.destroy();
      };
    }
  },[prgramPerformanceData])


  const createChart = (dataLabel, dataValue) => {
    let canvas = document.getElementById("myChart");
    let ctx = canvas.getContext("2d");

    let greenGradient = ctx.createLinearGradient(0, 0, 0, 600);
    greenGradient.addColorStop(0, "#00D943");
    greenGradient.addColorStop(1, "rgba(128, 254, 235, 0)");

    let redGradient = ctx.createLinearGradient(0, 0, 0, 800);
    redGradient.addColorStop(0, "#E10000");
    redGradient.addColorStop(1, "rgba(225, 0, 0, 0)");

    const gradientGet = (value, start, end) => {
      let height = canvas.height;
      let styleHeight = canvas.style.height;
      let gradienRed = ctx.createLinearGradient(0, 0, 0, height * 2);
      gradienRed.addColorStop(0, "rgba(255, 0, 0, 1)");
      gradienRed.addColorStop(1, "rgba(255, 0, 0, 0)");
      return gradienRed;
    };


    let backgroundColorData = dataValue?.map((data) => {
      if (data <= 50) {
        return redGradient
      } else {
        return greenGradient;
      }
    })
    
    const data = {
      labels: dataLabel,
      datasets: [
        {
          label: prgramPerformanceData?.program_name,
          data: dataValue,
          barThickness: 15,
          backgroundColor: backgroundColorData,
        },

      ]
    };

    let annotationObj = {
      annotationX2,
      annotationY1,
      annotationY2,
      annotationX1,
    };

    const addCircle = () => {
      prgramPerformanceData?.athlete_performance?.forEach((athlete, index) => {
        let valueD = athlete.overall_performance ? athlete.overall_performance : 0;
        const annotationImg13 = {
          content: () => {
            const img = new Image();
            img.src = `images/user/${athlete.athlete_id}.png`;
            if (!img.width) {
              img.src = "images/user/default_athlete.png";
            }
            return img;
          },
          type: "label",
          drawTime: "afterDraw",
          width: 47,
          height: 47,
          borderColor: valueD >= 50 ? "#00D943" : "#E10000",
          borderWidth: 4,
          padding:0,
          // backgroundColor: valueD >= 50 ? "#00D943" : "#E10000",
          borderRadius: 100,
          xValue: index,
          yValue: valueD > 1 ? valueD + 11 : valueD == 1 ? valueD + 13 : valueD + 14,
          xAdjust: 0
        };

        const annotationPerformance = {
          type: "label",
          drawTime: "afterDraw",
          width: 47,
          height: 47,
          xValue: index + 0.001,
          yValue: valueD > 1 ? valueD + 25 : valueD == 1 ? valueD + 27 : valueD + 28,
          xAdjust: 0,
          content: [`${valueD}%`],
          font: {
            size: 11,
            family: 'Poppins',
            weight: 800,
          },
          color: valueD > 50 ? '#01D944' : '#FF5046',
        };


        annotationObj = {
          ...annotationObj,
          [String(index + 1) + (valueD + 1) + "img"]: annotationImg13,
          [String(index + 1) + (valueD + 1) + "perc"]: annotationPerformance
        };
      });
      return annotationObj;
    };


    const plugin = {
      id: "customCanvasBackgroundColor",
      beforeDraw: (chart, args, options) => {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = options.color || "#090C6A";
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      }
    };

    const config = {
      type: "bar",
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
              borderWidth:0,
            },
            border:{
              display:false
            },
            min: 0,
            max: 128,
            ticks: {
              color: '#ffffff',
              padding: 5,
              font: {
                size: 14,
                weight: 300,
                family: 'Poppins'
              },
              stepSize: 50,
              callback: function(label, index, labels) {
                switch (label) {
                    case 128:
                        return '';
                    default:
                      return label
                }
            }
            },
            title: {
              display: true,
              text: "Overall Performance(in %)",
              color: '#ffffff',
              font: {
                size: 12,
                weight:400, 
                family: 'Poppins'
              },
              padding:{
                top:2
              }
            }
          },
          x: {
            grid: {
              display: false,
            },
            beginAtZero: false,
            ticks: {
              color: '#ffffff',
              padding: 5,
              // align:"Left",
              font: {
                size: 10,
                weight: 500,
                family: 'Poppins',
                // align:"Right",
              },
              display : data?.labels?.length ? true : false
            }
          }
        },
        plugins: {
          customCanvasBackgroundColor: {
            color: "#090C6A",
          },
          tooltip: {
            enabled: true
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            color: '#FFFFFF',
            text: prgramPerformanceData?.program_name,
            padding: {
              top: 0,
              bottom:0,
              left: -10,
            },
            align: "start",
            font: {
              size: 14,
              weight: 500,
              lineHeight: 2.1,
              family: 'Poppins'
            }
          },
          annotation: {
            annotations: addCircle(),
          }
        }
      }
    };

    Chart.register(...registerables);
    Chart.register(Annotation);
    // Chart.register(plugin);
    newChart = new Chart(ctx, config);
    // return () => {
    //   newChart.destroy();
    // };
  }

  return (
    <canvas id="myChart"    style={{position:"absolute", left:"0", top:"0", pointerEvents:"none"}}></canvas>
  );
}

export default PerformanceChart;
