import {configureStore} from '@reduxjs/toolkit';
import loaderReducer from '../reducer/loader';
import authReducer from '../reducer/auth';
import templatesReducer from '../reducer/templates';
import technicalTemplatesReducer from '../reducer/technicalTemplates';
import technicalProgramsReducer from '../reducer/technicalPrograms';
import tournamentsReducer from '../reducer/tournaments.reducer';
import sideMenuReducer from '../reducer/sidemenu';
import programsReducer from '../reducer/programs';
import userManagementReducer from '../reducer/userManagement';
import testAssessmentReducer from '../reducer/testAssessment';
import dashboardReducer from "../reducer/dashboard";
import tournamentsEventReducer from '../reducer/tournamentsEvent.reducer';
import tournamentsPerformanceReducer from '../reducer/tournamentsPerformance.reducer';
import injuryReducer from '../reducer/injury.reducer';
import psychologyReducer from '../reducer/psychology';
import nutritionReducer from '../reducer/nutrition.reducer';

const store = configureStore({
    reducer :  {
        loader: loaderReducer,
        auth : authReducer,
        templates: templatesReducer,
        sideMenu: sideMenuReducer,
        programs: programsReducer,
        technicalTemplates: technicalTemplatesReducer,
        technicalPrograms: technicalProgramsReducer,
        userManagement: userManagementReducer,
        testAssessment: testAssessmentReducer,
        tournaments: tournamentsReducer,
        tournamentEvents:tournamentsEventReducer,
        tournamentPerformance:tournamentsPerformanceReducer,
        injury:injuryReducer,
        dashboard : dashboardReducer,
        psychology : psychologyReducer,
        nutrition:nutritionReducer
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: false,
        })
});

export default store;
