import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Dropdown from '../Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { loaderActions } from '../../../../reducer/loader';
import { fetchAthleteReportList } from '../../../../utility/testAssessmentApiActions';
import { testAssessmentActions } from '../../../../reducer/testAssessment';

function SubHeader({ nextConductedDate, userguid, isBtn, btnName, onBtnClick}) {
    const dispatch = useDispatch();
    const selectedAthleteFunction = useSelector(state => state?.testAssessment?.selectedAthleteFunction);
    const functionList = useSelector(state => state?.testAssessment?.testsList);
    let [functionOptions, setFunctionOptions] = useState([]);

    useEffect(() => {
        if (functionList && functionList.length) {
            const acessibleFunctions = functionList?.filter((func) => func.is_acessible);
            let options = acessibleFunctions?.map(func => {
                return {
                    label: `${func?.function_name} Reports`,
                    value: func?.function_id
                };
            });
            setFunctionOptions(options);
        }
    }, [functionList]);

    const funcChangeHandler = (data) => {
        // setChangedFunctionId(data?.value);
        dispatch(testAssessmentActions.setAthleteFunction({'id':data?.value}));
        dispatch(loaderActions?.showLoader());
        dispatch(fetchAthleteReportList({
            pageNo: 1,
            perPage: 20,
            userguid,
            functionId: data?.value,
            callback: () => setTimeout(() => {
                dispatch(loaderActions?.hideLoader());
            })
        }));
    };

    return (
        <div className="si-subHeader si-subheader-testAsst">
            <div className="si-reportwrp">
                <div className="si-reportdrp">
                    <div className="si-testimg">
                        {/* <img src="../../images/laboratory-test.png" /> */}
                      {
                       selectedAthleteFunction? 
                       <img src={`../../images/test-assesment-icons/${selectedAthleteFunction}.svg`}/>  : 
                       <img src="../../images/laboratory-test.png" /> 
                      }
                        </div>
                       {functionOptions?.length > 1 ? 
                        <div className="si-dropdown">
                            <div className="form-control ">
                                <Dropdown 
                                    placeholder="Select Function"
                                    value={functionOptions?.find(func => func?.value==selectedAthleteFunction)}
                                    options={functionOptions}
                                    changeHandler={funcChangeHandler}
                                />
                                {/* <select className="search_tes si-input">
                                    <option>Biochemistry Tests Report</option>
                                    <option>Week 2</option>
                                    <option>Week 3</option>
                                    <option>Week 4</option>
                                    <option>Week 5</option>
                                </select> */}
                            </div>
                        </div> 
                        :
                        <span class="test-report__name-text">{functionOptions?.filter(func => func?.value==selectedAthleteFunction)[0]?.label}</span>
                    }

                    {
                        isBtn &&
                        <button type="button" className="si-button si-add add-new-report" onClick={onBtnClick}>
                        <span className="icon-add"></span>{btnName}
                        </button>
                    }
                </div>
                { nextConductedDate &&
                <div className="si-nexttest">
                    <span className="si-icon icon-test"></span>
                    <div className="si-cover">
                        {/* <span className="si-nexttxt">The next test</span> */}
                        <div className="si-wrp">
                            <span className="si-conducttxt">The next test will be conducted on   </span>
                            <span className="si-date">{ moment(nextConductedDate)?.format("DD/MM/YYYY") }</span>
                        </div>
                    </div>
                </div>} 
            </div>
        </div>
    );
};

export default SubHeader;