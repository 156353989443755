import React from 'react'

export default function DailyQuotes({quotes}) {
  return (
    <div className="si-quotes-container">
                <p className="si-quotes"><span className="dbl-comma">“</span>{quotes?.text}<span className="dbl-comma">”</span></p>
                <span className="si-writer">{quotes.writer}</span>
            </div>
  )
}
