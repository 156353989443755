import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loaderActions } from '../../../../reducer/loader';
import { fetchProgramsList } from '../../../../utility/programApiActions';
import Content from './Content';
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import SubHeader from '../../Common/SubHeader';
import FixedButton from '../../Common/FixedButton';
import { fetchSportsList } from '../../../../utility/templateApiActions';

function CompletedProgramListing() {
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedSports, setSelectedSports] = useState([]);
    const paginationObject = useSelector(state => state?.programs?.paginationObject);
    const isAuth = useSelector(state => state?.auth?.isAuthenticated);
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    let filterOptions = {
        is_sport_filter: true,
        is_search_filter: true,
        is_tab: false,
        is_group_filter: false
    };

    useEffect(() => {
       dispatch(fetchSportsList());
    }, []);

    useEffect(() => {
        let programListTimeOut = setTimeout(() => {
            dispatch(
                fetchProgramsList({
                    pageNo,
                    activeTab: 3,
                    searchTerm,
                    selectedSports
                })
            );
        }, 1000);

        return () => clearTimeout(programListTimeOut);
    }, [searchTerm]);

    useEffect(() => {
        if(isAuth){
            let programListTimeOut = setTimeout(() => {
                dispatch(loaderActions?.showLoader());
                dispatch(
                    fetchProgramsList({
                        pageNo,
                        activeTab: 3,
                        searchTerm,
                        selectedSports,
                        callback: () => setTimeout(() => {
                            dispatch(loaderActions?.hideLoader());
                        })
                    })
                );
            }, 0);

            return () => clearTimeout(programListTimeOut);
        }
    }, [isAuth,pageNo, selectedSports]);

    const getProgramlisting = (callback)=>{
        dispatch(
            fetchProgramsList({
                pageNo,
                activeTab: 3,
                searchTerm,
                selectedSports,
                callback: () => setTimeout(() => {
                    callback(true);
                })
            })
        );
    }

    const createBtnClickHandler = (id)=> {
        navigate("/snc/programs/templates")
    };

    return (
        <Fragment>
            <Header 
                headerData={{
                    heading: "Completed Programs",
                    isHeadingEditable: false
                }}
            />
            <SubHeader 
                OtherClass='si-completed-subheader'
                filterOptions={filterOptions}
                searchTerm={ searchTerm }
                setSearchTerm={ setSearchTerm }
                selectedSportIds={ selectedSports }
                setSelectedSportIds={ setSelectedSports }
                pageNo={ pageNo }
                setPageNo={ setPageNo }
                navigate={ navigate }
            />
            <Content 
                pageNo={ pageNo }
                setPageNo={ setPageNo } 
                pagination={ paginationObject }
                perPage={ 20 }
                searchTerm={ searchTerm }
                selectedSports={ selectedSports }
                activeTab={3}
                subModule={'completedprograms'}
            />
            {/* <Footer 
                pageNo={pageNo} 
                setPageNo={setPageNo} 
                pagination={paginationObject}
                perPage={20}
            /> */}
        </Fragment>
    );
};

export default CompletedProgramListing;