import { addDays, addWeeks, format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

function RecurrenceTab({ activeTab, startDate, exerciseToEdit, setExerciseToEdit, selectedWeek, type }) {
    const duration = useSelector(state => type===2 ? state?.programs?.selectedProgram?.program_duration : state?.templates?.selectedTemplate?.duration);
    const startDateRedux = useSelector(state => type===2 ? state?.programs?.selectedProgram?.start_date : state?.templates?.selectedTemplate?.start_date);
    const addExerciseDay = useSelector(state => type===2 ? state?.programs?.addExerciseDay : state?.templates?.addExerciseDay);
    const [copiedObj, setCopiedObj] = useState({
        week_no: null,
        data: null
    });
    const [pastedOnWeek, setPastedOnWeek] = useState([]);
    const days = 7;

    const checkBoxHandler = (e, week) => {
        if(!e?.target?.checked && exerciseToEdit?.recurrence?.length===1) return;
        setExerciseToEdit(prevState => {
            let recurrenceObj = {
                day_no: parseInt(e?.target?.value),
                id: null,
                optype: "1",
                week_no: week
            };
            let newRecurrence = [ ...prevState?.recurrence ];
            if(e?.target?.checked) newRecurrence?.push(recurrenceObj);
            else {
                newRecurrence = newRecurrence?.filter(obj => !(obj?.week_no==week && obj?.day_no==e?.target?.value));
            };
            return { ...prevState, recurrence: newRecurrence };
        });
        setCopiedObj({
            week_no: null,
            data: null
        });
        setPastedOnWeek([]);
    };

    const copyHandler = (week) => {
        let data = [ ...exerciseToEdit?.recurrence ]?.filter(obj => obj?.week_no == week);
        setCopiedObj({
            ...copiedObj,
            week_no: week,
            data
        });
        setPastedOnWeek([]);
        toast.success(`Week ${week} data copied successfully.`,{toastId:week});
    };

    const pasteHandler = (week) => {
        if(!pastedOnWeek?.includes(week) && copiedObj?.week_no && 
            copiedObj?.week_no!=week) {
            setExerciseToEdit(prevState => {
                let newObj = copiedObj?.data?.map(obj => {
                    return { ...obj, week_no: week }
                });
                let stateRecurrence = [ ...prevState?.recurrence ]?.filter(obj => obj?.week_no != week);

                newObj?.forEach(obj => {
                    let includesObj = false;
                    stateRecurrence?.forEach(stateObj => {
                        if(stateObj?.week_no==obj?.week_no && 
                            stateObj?.day_no==obj?.day_no) includesObj = true;
                    });
                    if(!includesObj) stateRecurrence?.push({ ...obj, optype: "1" });
                });

                return { ...prevState, recurrence: [ ...stateRecurrence ] }
            });
            setPastedOnWeek([ ...pastedOnWeek, week ]);
        };
    };

    const isAddExercise = (startDate, day, week) => {
        if((startDate || startDateRedux) && day && week) {
            let addDay = startDate ? addDays(new Date(startDate), day - 1) : addDays(new Date(startDateRedux), day - 1)
            let addWeek = addWeeks(addDay, week - 1);
            return format(addWeek, "yyyy-MM-dd") > format(new Date(), "yyyy-MM-dd");
        };
        return true;
    };
    
    return (
        <div className={`si-tab-pane si-tab-pane__setrecurrence ${ activeTab !== "recurrence" ? "d-none" : "" }`}>
            <div className="content-wrapper">
                <div className="si-program-duration">
                    <div className="si-program-head">
                        <div className="si-program-row">
                            <div className="si-program-col col-1">
                                <span className="program-duration">Program Duration </span>
                                <span className="label">{duration ? duration : 1} weeks</span>
                            </div>
                            <div className="si-program-col col-3"></div>
                            <div className="si-program-col col-2 d-sm-none">
                            </div>
                        </div>
                        <div className="si-program-row si-program-row-head d-sm-none">
                            <div className="si-program-col col-1">
                                <span>week</span>
                            </div>
                            <div className="si-program-col col-3">
                                <span></span>
                            </div>
                            <div className="si-program-col col-2">
                                <div className="si-program-list">
                                    {
                                        Array(days)?.fill(null)?.map((val, dayId) => {
                                            let day = dayId + 1;
                                            return (
                                                <div key={uuidv4()} className="si-program-item">
                                                    <span>day {day}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="si-program-weeks">
                        {
                            Array(Number(duration))?.fill(null)?.map((val, weekId) => {
                                let week = weekId + 1;
                                let isCopyActive = false;
                                let isCopyDisabled = true;
                                let isPasteActive = false;
                                let isPasteDisabled = true;
                                let isCurrentDayInWeek = false;
                                Array(days)?.fill(null)?.forEach((val, day)=>{
                                    if(!isAddExercise(startDate, day+1, week)){
                                        isCurrentDayInWeek = true
                                    }
                                })
                                if(copiedObj?.week_no==week) isCopyActive = true;
                                let weekData = exerciseToEdit?.recurrence?.filter(obj => obj?.week_no===week);
                                if(weekData?.length) isCopyDisabled = false;
                                if(pastedOnWeek?.includes(week) >= 1) isPasteActive = true;
                                if(copiedObj?.week_no && copiedObj?.week_no!=week) isPasteDisabled = false;
                                return (
                                    <div key={uuidv4()} className="si-program-row">
                                        <div className="si-program-col col-1">
                                            <span>week {week}</span>
                                        </div>
                                        <div className="si-program-col col-3">
                                            <div className="si-action-buttons">
                                                <button 
                                                    type="button" 
                                                    className={`si-action-btn ${!isCopyDisabled && isCopyActive ? "active" : ""}`}
                                                    onClick={() => copyHandler(week)}
                                                    disabled={isCopyDisabled}
                                                >copy</button>
                                                <button 
                                                    type="button" 
                                                    className={`si-action-btn ${!isPasteDisabled && isPasteActive ? "active" : ""}`}
                                                    onClick={() => pasteHandler(week)}
                                                    disabled={isPasteDisabled || isCurrentDayInWeek}
                                                >paste</button>
                                            </div>
                                        </div>
                                        <div className="si-program-col col-2">
                                            <div className="si-program-list">
                                                {
                                                    Array(days)?.fill(null)?.map((val, dayId) => {
                                                        let disabled = false;
                                                        let day = dayId + 1;
                                                        let isChecked = exerciseToEdit?.recurrence?.find(obj => {
                                                            // avoid showing checked if optype is 3 (deleted)
                                                            return obj?.day_no == day && obj?.week_no == week && obj?.optype != "3";
                                                        });
                                                        if(
                                                            isChecked && 
                                                            exerciseToEdit?.recurrence?.length === 1 &&
                                                            isChecked?.day_no==addExerciseDay && 
                                                            isChecked?.week_no==selectedWeek
                                                        ) disabled = true;

                                                        return (
                                                            <div key={uuidv4()} className="si-program-item">
                                                                <input 
                                                                    type="checkbox" 
                                                                    name="" id="" 
                                                                    className={`si-checkBox ${!isAddExercise(startDate, dayId+1, weekId+1) ? "checkbox-disabled": ""}`} 
                                                                    value={day}
                                                                    checked={isChecked ? true : false}
                                                                    disabled={disabled}
                                                                    onChange={(e) => checkBoxHandler(e, week)}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecurrenceTab;