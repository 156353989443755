export const ADMIN_ROLE_ID = 1;
export const SNC_COACH_ROLE_ID = 2;
export const TECHNICAL_COACH_ROLE_ID = 3;
export const ATHELETE_ROLE_ID = 4;
export const PHYSIOTHERAPIST_ROLE_ID = 11;
export const REMARK_ID = 22;
export const HOME_MODULE_ID = 7;
export const SNC_MODULE_ID = 1;
export const TECHNICAL_MODULE_ID = 2;
export const ATHLETES_MODULE_ID = 4;
export const TEST_AND_ASSESSMENT_MODULE_ID = 5;
export const PROFIE_MODULE_ID=8;
export const TOURNAMENT_MODULE_ID = 9;
export const INJURY_MODULE_ID = 10
export const PSYCHOLOGY_MODULE_ID = 11
export const NUTRITION_MODULE_ID = 12;
export const PSYCHOLOGY_ROLE_ID = 10;



// route strings 

export const TOURNAMENT_MODULE_ROUTE = "tournaments"
export const TOURNAMENT_LISTING_ROUTE = "tournamentslist" 
export const TOURNAMENT_PERFORMANECE_LISTING_ROUTE = "tournamentresults" 
export const TOURNAMENT_EVENTS_LISTING_ROUTE = "eventslist" 


//route string
export const ATHLETE_PROFILE_ROUTE = "athleteprofile"
export const PROFILE_MODULE_ROUTE="profile"
export const INJURY_MODULE_ROUTE = "injury"
export const NUTRITION_MODULE_ROUTE = "nutrition"
export const PSYCHOLOGY_MODULE_ROUTE="psychology"
export const ATHLETE_MODULE_ROUTE = "athletes"


//Common Msg
export const NO_PROGRMA_TEXT = "No program assigned yet"
export const NO_SNC_MSG = "Please speak with your SnC Coach to assign a training program for you."
export const NO_TECHNICAL_MSG = "Please speak with your Technical Coach to assign a training program for you."
export const ATHELET_RPE_MSG = "Rate your RPE for today's session"
export const COACH_RPE_MSG = "RPE for today's session"
export const ATHELET_BTN_MSG = "View Reports"
export const COACH_BTN_MSG = "View Athletes"   

export const AGE_LIMIT = 60;
export const SIBLING_LIMIT = 11;