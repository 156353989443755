import React from "react";
import { Fragment } from "react";

export default function CompletedCard({data}) {
  return (
    <Fragment>
      <div className="si-like-img">
        <img src="../../images/like-img.svg" alt="" />
      </div>
      <div className="si-cardscore">
        <span className="si-obtainscore">{data.completed_exercises}</span>/
        <span className="si-totalscore">{data.total_exercises}</span>
      </div>
      <p className="si-task-status">Exercises Completed Today</p>
      <p className="si-task-feedback">You are doing great Today</p>
    </Fragment>
  );
}
