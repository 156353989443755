import React from 'react'
import { Fragment } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../Common/Header';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchEducationList, fetchInformantList, fetchMaritalStatusList, getCaseLog, insertUpdatePsychologyCase } from '../../../../utility/PsychologyApiActions';
import CaseLogContent from './CaseLogContent';
import { fetchGenderList } from '../../../../utility/tournamentEventsApiAction';
import moment from 'moment/moment';
import Footer from '../../Common/Footer';
import { PSYCHOLOGY_MODULE_ROUTE } from '../../../../utility/constants';
import { toast } from 'react-toastify';
import { fetchAthleteDetails } from '../../../../utility/userManagementApiActions';
import { loaderActions } from '../../../../reducer/loader';

export default function NewCaseLog() {
  // const {athleteId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const athlete_guid = localStorage.getItem("guid") ? localStorage.getItem("guid") : '';
  const [caseLogDetails, setCaseLogDetails] = useState();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const userData = useSelector((state) => state?.auth?.userData);
  const [isError, setIsError] = useState(false);
  const selectedAthlete = useSelector((state) => state?.psychology?.selectedAthlete);
  const casefileDetails = useSelector((state) => state?.psychology?.casefileDetails);
  const selectedPsychologyCaseId = useSelector((state) => state?.psychology?.selectedPsychologyCaseId);
  const athleteDetails = useSelector(state => state?.userManagement?.athleteDetails);
  const loading = useSelector((state) => state?.psychology?.loading);
  
  useEffect(() => {
    if (loading) {
      dispatch(loaderActions.showLoader());
    } else {
      dispatch(loaderActions.hideLoader());
    }
  }, [loading]);
  useEffect(()=>{
    if(isAuth && !athleteDetails){
      if(athlete_guid){
        dispatch(fetchAthleteDetails({
          id: athlete_guid
        }));
      }else{
        navigate(-1);
      }
    }
  },[isAuth,athlete_guid,athleteDetails]);

  useEffect(()=>{
    if(isAuth && athleteDetails){
      let payload = {
        athlete_guid: athleteDetails.athlete_guid,
        case_id: 0
      }
      dispatch(getCaseLog({ payload }));
      dispatch(fetchGenderList());
      dispatch(fetchEducationList());
      dispatch(fetchMaritalStatusList());
      dispatch(fetchInformantList());
    }
  },[isAuth,athleteDetails])

  useEffect(() => {
    if (casefileDetails) {
      let data = {
        "case_id": null,
        "case_file_no": casefileDetails?.case_file_no ? casefileDetails?.case_file_no : '-',
        "case_date": moment(new Date()).format("YYYY-MM-DD"),
        "user_guid": userData?.user_guid,
        "athlete_name": `${athleteDetails?.athlete_name}`,
        "athlete_guid": athleteDetails?.athlete_guid,
        "education_id": null,
        "siblings_cnt": null,
        "marital_status_id": null,
        "informant_id": null,
        "phone": '',
        "reason": "",
        "age": casefileDetails?.age,
        "gender": casefileDetails?.gender
      };
      setCaseLogDetails(data);
    }
  }, [casefileDetails]);

  // useEffect(() => {
  //   if (selectedPsychologyCaseId) {
  //     toast?.success("Case Log addedd successfully.");
  //     navigate(`/${PSYCHOLOGY_MODULE_ROUTE}/case/${selectedPsychologyCaseId}`);
  //   }
  // }, [selectedPsychologyCaseId]);

  const saveHandler = (id)=>{
    toast?.success("Case log added successfully.");
    // toast?.success("Case Log Updated Successfully.");
    navigate(`/${PSYCHOLOGY_MODULE_ROUTE}/case/${id}`);
  }

  const cancelHandler = ()=>{
    navigate(`/${PSYCHOLOGY_MODULE_ROUTE}`);
  }

  return (
    <Fragment>
      <Header
        headerData={{
          heading: "Psychology Case Log",
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate(-1)
        }}
      />
      <CaseLogContent athleteDetails={athleteDetails} isEditOption={true} caseLogDetails={caseLogDetails} setCaseLogDetails={setCaseLogDetails} isError={isError} 
      setIsError={setIsError} isEditMode={true} saveHandler={saveHandler} cancelHandler={cancelHandler} 
      />
    </Fragment>
  )
}
