import React, { useState, useEffect } from 'react';
import Select from 'react-select';

function Dropdown({ placeholder = "", value, options, changeHandler, isSearchable = false,
    isDisabled = false, menuPlacement = "bottom", isDefaultOption, preSelectedValue= {value:null, label:null} }) {
    const [selectedValues, setSelectedValues] = useState("");
    const [optionList, setOptionList] = useState([]);
    const onChangeHandler = (data) => {
        setSelectedValues({ ...data });
        changeHandler(data);
    };

    useEffect(() => {
        if (value) setSelectedValues(value);
        else if (value=== null) setSelectedValues("")
    }, [value]);

    useEffect(() => {
        if (options) {
            if (isDefaultOption) {
                let data = {
                    value: '',
                    label: placeholder ? placeholder : 'Select Option'
                };
                setOptionList([data, ...options]);
            }else{
                setOptionList([...options]);
            }
        }
    }, [options, placeholder, isDefaultOption])

    return (
        <Select
            placeholder={placeholder}
            value={selectedValues}
            options={optionList}
            onChange={onChangeHandler}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            autoFocus={false}
            menuPlacement={menuPlacement}
            classNamePrefix="custom-select"
            className="custom-single-select"
            styles={{option:(styles, data)=>{
                if(data.hasValue && data.value === preSelectedValue?.value && !data.isSelected){
                    return {...styles, color:'#e10000 !important'}
                } else {
                    return styles
                }
            }}}
        />
    );
};

export default Dropdown;