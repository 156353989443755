import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import EditExercisePopup from '../../Common/EditExercise/EditExercisePopup';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import NotesPopup from '../../Common/NotesPopup';
import FeedbackPopup from '../../Common/FeedbackPopup';
import { programsActions } from '../../../../reducer/programs';
import { fetchExerciseDetails, fetchTemoList, fetchUnitsList } from '../../../../utility/templateApiActions';
import { inserUpdateAthleteNotes, fetchWeekWiseProgressCalendarData } from '../../../../utility/programApiActions';
import ProgressCalendarView from '../../Common/ProgressCalendar/ProgressCalendarView';
import { loaderActions } from '../../../../reducer/loader';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { ATHELETE_ROLE_ID } from '../../../../utility/constants';

function CalenderContent({confirmationPopup, startDate}) {
    const [isPopupActive, setIsPopupActive] = useState(false);
    const [notesPopup, setNotesPopup] = useState({isShow: false});
    const [feedbackPopup, setFeedbackPopup] = useState({isShow: false});
    const userData = useSelector(state => state?.auth?.userData);
    const selectedWeek = useSelector(state => state?.programs?.selectedWeek);
    const weekWiseProgressCalendarData = useSelector(state => state?.programs?.weekWiseProgressCalendarData);
    const tempoList = useSelector(state => state?.tTemplates?.tempoList);
    const unitsList = useSelector(state => state?.templates?.unitsList);
    const { id, userId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(!tempoList) dispatch(fetchTemoList());
        if(!unitsList) dispatch(fetchUnitsList());
    }, []);

    const exerciseDetailHandler = (e, id, day, exerciseMasterId) => {
        e?.stopPropagation();
        dispatch(loaderActions?.showLoader());
        dispatch(
            fetchExerciseDetails({
                id,
                type: 2,
                callback: (data) => setTimeout(() => {
                    data = { ...data, athlete_notes: data?.notes, tempo: data?.selected_tempo }
                    delete data["selected_tempo"];
                    dispatch(
                        programsActions?.seletedExercise({
                            type: "calendar",
                            action: "view",
                            data,
                            callback: () => setTimeout(() => {
                                dispatch(
                                    programsActions?.setEditExerciseId({
                                        day,
                                        exerciseId: exerciseMasterId,
                                        callback: () => setTimeout(() => {
                                            dispatch(loaderActions?.hideLoader());
                                            setIsPopupActive(true);
                                        })
                                    })
                                );
                            })
                        })
                    )
                })
            })
        );
    };

    const notesActionHandler = (notes) => {
        if(userData?.role_id == ATHELETE_ROLE_ID && notes) {
            dispatch(loaderActions?.showLoader());
            dispatch(
                inserUpdateAthleteNotes({
                    optType: notesPopup?.optType, 
                    week: notesPopup?.week, 
                    day: notesPopup?.day, 
                    notes, 
                    noteId: notesPopup?.noteId,
                    reviewId: notesPopup?.emotionId, 
                    programId: id, 
                    userId, 
                    callback: () => setTimeout(() => {
                        dispatch(
                            fetchWeekWiseProgressCalendarData({
                                id,
                                week: selectedWeek ? selectedWeek : 1,
                                userId,
                                callback: () => setTimeout(() => {
                                    setNotesPopup({isShow: false});
                                    dispatch(loaderActions?.hideLoader()); 
                                    toast?.success("Notes added successfully.", {toastId: "add_notes_success"});
                                })
                            })
                        );
                    })
                })
            );
        };
    };

    const feedbackActionHandler = (emotionId) => {
        if(userData?.role_id == ATHELETE_ROLE_ID) {
            dispatch(loaderActions?.showLoader());
            dispatch(
                inserUpdateAthleteNotes({
                    optType: feedbackPopup?.optType, 
                    week: feedbackPopup?.week, 
                    day: feedbackPopup?.day, 
                    notes: feedbackPopup?.notes, 
                    noteId: feedbackPopup?.noteId,
                    reviewId: emotionId, 
                    programId: id, 
                    userId, 
                    callback: () => setTimeout(() => {
                        dispatch(
                            fetchWeekWiseProgressCalendarData({
                                id,
                                week: selectedWeek ? selectedWeek : 1,
                                userId,
                                callback: () => setTimeout(() => {
                                    setFeedbackPopup({isShow: false});
                                    dispatch(loaderActions?.hideLoader());
                                    toast?.success("Feedback added successfully.", {toastId: "add_feedback_success"});
                                })
                            })
                        );
                    })
                })
            );
        } else {
            setFeedbackPopup({isShow: false});
        };
    };
    
    return (
        <div className="si-main-content">
            {
                isPopupActive &&
                <EditExercisePopup 
                    isActive={isPopupActive} 
                    cancelAction={() => setIsPopupActive(false)}
                    isActionBtn={false} 
                    tabLists={["specs", "notes_media"]}
                    type={2}
                    isViewOnly={true}
                />
            }

            <ProgressCalendarView 
                calendarClass={userData?.role_id == ATHELETE_ROLE_ID ? "si-logperformance" : "si-coachview"}
                navigateTo={`/snc/programs/`}
                startDate={startDate}
                selectedWeek={selectedWeek}
                showExerciseDetails={exerciseDetailHandler}
                weekWiseCalendarData={weekWiseProgressCalendarData}
                setNotesPopup={setNotesPopup}
                setFeedbackPopup={setFeedbackPopup}
                tempoList={tempoList}
                unitsList={unitsList}
            />

            <ConfirmationPopup 
                isActive={confirmationPopup?.isShow}
                isActionBtn={confirmationPopup?.isActionBtn}
                isActionBtns={confirmationPopup?.isActionBtns}
                warning={confirmationPopup?.warning}
                warningMsg={confirmationPopup?.warningMsg}
                cancelHandler={confirmationPopup?.cancelHandler}
                okHandler={confirmationPopup?.okHandler}
            />

            <NotesPopup 
                isActive={notesPopup?.isShow} 
                isEditable={notesPopup?.isEditable} 
                subtitle={notesPopup?.subtitle} 
                noteDescription={notesPopup?.notes} 
                isActionBtn={notesPopup?.isEditable ? true : false} 
                actionBtnText={notesPopup?.btnText}
                actionBtnHandler={notesActionHandler}
                closeHandler={() => setNotesPopup({isShow: false})}
            />

            <FeedbackPopup 
                isActive={feedbackPopup?.isShow}
                isEditable={feedbackPopup?.isEditable}
                isReason={feedbackPopup?.isReason}
                id={feedbackPopup?.emotionId}
                isActionBtn={feedbackPopup?.isEditable ? true : false}
                actionBtnText={feedbackPopup?.btnText}
                actionBtnHandler={feedbackActionHandler}
                closeHandler={() => setFeedbackPopup({isShow: false})}
            />
        </div>
    )
};

export default CalenderContent;