import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import EditExercisePopup from '../../Common/EditExercise/EditExercisePopup';
import CalendarView from '../../Common/Calendar/CalendarView';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import { templatesActions } from '../../../../reducer/templates';
import { toast } from 'react-toastify';
import { postTemplateExercises, fetchWeekWiseData, fetchTemoList, fetchWholeCalendarData, fetchExerciseDetails, fetchUnitsList, publishCalendarSequence } from '../../../../utility/templateApiActions';
import ExerciseActionPopup from '../../Common/CalendarActionPopup';
import { loaderActions } from '../../../../reducer/loader';

function CalenderContent({confirmationPopup, setConfirmationPopup, headerData, setHeaderData, setIsUpdateDisabled}) {
    const [isPopupActive, setIsPopupActive] = useState(false);
    const [exerciseActionPopup, setExerciseActionPopup] = useState({isShow: false});
    const tempoList = useSelector(state => state?.templates?.tempoList);
    const unitList = useSelector(state => state?.templates?.unitsList);
    const selectedWeek = useSelector(state => state?.templates?.selectedWeek);
    const weekWiseCalendarData = useSelector(state => state?.templates?.weekWiseCalendarData);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(!tempoList) dispatch(fetchTemoList());
        if(!unitList) dispatch(fetchUnitsList());
    }, []);

    const popupSaveHandler = (data) => {
        let isRecurred = data?.recurrence?.find(obj => obj?.week_no!=exerciseActionPopup?.week || obj?.day_no!=exerciseActionPopup?.day);
        if(isRecurred) {
            setExerciseActionPopup({
                ...exerciseActionPopup, 
                isShow: true,  
                data,
                isRecurred
            });
        } 
        else {
            setTimeout(() => {
                dispatch(loaderActions?.showLoader());
                dispatch(
                    postTemplateExercises({
                        id,
                        type: 1, 
                        callback: () => {
                            setTimeout(() => {
                                dispatch(fetchWeekWiseData({ 
                                    id,
                                    week: selectedWeek ? selectedWeek : 1,
                                    callback: () => setTimeout(() =>{
                                        dispatch(loaderActions?.hideLoader());
                                        setIsPopupActive(false);
                                        toast.success("Exercise updated successfully.", {
                                            toastId: "template-calendar-exercise-update-success"
                                        });
                                    })
                                }));
                                dispatch(fetchWholeCalendarData({id}));
                                dispatch(setIsUpdateDisabled(false));
                            });
                        }
                    })
                );
            });
        };
        // dispatch(loaderActions?.showLoader());
        // dispatch(
        //     postTemplateExercises({
        //         id,
        //         type: 1, 
        //         callback: () => {
        //             setTimeout(() => {
        //                 dispatch(fetchWeekWiseData({ 
        //                     id,
        //                     week: 1,
        //                     callback: () => setTimeout(() =>{
        //                         dispatch(loaderActions?.hideLoader());
        //                         setIsPopupActive(false);
        //                         toast.success("Exercise Updated Successfully.", {
        //                                  toastId: "template-calendar-exercise-update-success"
        //                              });
        //                     })
        //                 }));
        //                 dispatch(fetchWholeCalendarData({id}));
        //             });
        //         }
        //     })
        // );
    };

    const editConfirmationHandler = (selectedAction) => {
        dispatch(loaderActions?.showLoader());
        dispatch(
            templatesActions?.seletedExercise({
                type: "calendar",
                action: "edit",
                week: exerciseActionPopup?.week, 
                day: exerciseActionPopup?.day,
                selectedAction,
                data: exerciseActionPopup?.data,
                callback: () => setTimeout(() => {
                    dispatch(
                        postTemplateExercises({
                            id,
                            type: 1, 
                            callback: () => {
                                setTimeout(() => {  
                                    setExerciseActionPopup({isShow: false});
                                    dispatch(fetchWeekWiseData({ 
                                        id,
                                        week: selectedWeek,
                                        callback: () => setTimeout(() =>{
                                            dispatch(loaderActions?.hideLoader());
                                            setIsPopupActive(false);
                                            toast.success("Exercise updated successfully.", {
                                                toastId: "template-calendar-exercise-update-success"
                                            });
                                        })
                                    }));
                                    dispatch(fetchWholeCalendarData({id}));
                                    dispatch(setIsUpdateDisabled(false));
                                });
                            }
                        })
                    );
                })
            })
        );
    };

    const deleteConfirmationHandler = (selectedAction) => {
        dispatch(loaderActions?.showLoader());
        setExerciseActionPopup({isShow: false});
        dispatch(
            fetchExerciseDetails({
                id: exerciseActionPopup?.exerciseId,
                type: 1,
                callback: (data) => setTimeout(() => {
                    data = { ...data, athlete_notes: data?.notes, tempo: data?.selected_tempo }
                    delete data["selected_tempo"];
                    dispatch(
                        templatesActions?.seletedExercise({
                            type: "calendar",
                            action: "delete",
                            week: exerciseActionPopup?.week, 
                            day: exerciseActionPopup?.day,
                            selectedAction,
                            data,
                            callback: () => setTimeout(() => {
                                dispatch(
                                    postTemplateExercises({
                                        id,
                                        type: 1,
                                        callback: () => setTimeout(() => {
                                            dispatch(
                                                fetchWeekWiseData({
                                                    id,
                                                    week: selectedWeek,
                                                    callback: () => setTimeout(() => {
                                                        dispatch(loaderActions?.hideLoader());
                                                        toast.success("Exercise removed successfully.", {
                                                            toastId: "template-calendar-exercise-delete-success"
                                                        });
                                                    })
                                                })
                                            );
                                            dispatch(fetchWholeCalendarData({id}));
                                            dispatch(setIsUpdateDisabled(false));
                                        })
                                    })
                                );
                            })
                        })
                    )
                })
            })
        )
    };

    const editExerciseHandler = (id, week, day, exerciseMasterId) => {
        setExerciseActionPopup({
            type: "edit", 
            isShow: false, 
            exerciseId: id, 
            exerciseMasterId,
            week, 
            day
        });
        dispatch(loaderActions?.showLoader());
        dispatch(
            fetchExerciseDetails({
                id,
                type: 1,
                callback: (data) => setTimeout(() => {
                    data = { ...data, athlete_notes: data?.notes, tempo: data?.selected_tempo }
                    delete data["selected_tempo"];
                    dispatch(
                        templatesActions?.seletedExercise({
                            type: "calendar",
                            action: "edit",
                            week, 
                            day,
                            data,
                            callback: () => setTimeout(() => {
                                dispatch(
                                    templatesActions?.setEditExerciseId({
                                        day,
                                        exerciseId: exerciseMasterId,
                                        callback: () => setTimeout(() => {
                                            dispatch(loaderActions?.hideLoader());
                                            setIsPopupActive(true);
                                        })
                                    })
                                );
                            })
                        })
                    )
                })
            })
        );
    };

    const deleteExerciseHandler = (id, week, day) => {
        dispatch(
            fetchExerciseDetails({
                id,
                type: 1,
                callback: (data) => {
                    let isRecurred = data?.recurrence?.find(obj => obj?.week_no!=week || obj?.day_no!=day);
                    setExerciseActionPopup({type: "delete", isShow: true, exerciseId: id, week, day, isRecurred});
                }
            })
        );
    };

    const handleDragEnd = (data, week, day) => {
        if(data?.destination?.droppableId !== data?.source?.droppableId) return;
        // setHeaderData({
        //     ...headerData,
        //     backBtnAction: () => {
        //         setConfirmationPopup({
        //             isShow: true,
        //             isActionBtns: true,
        //             warning: "Are you sure you want to cancel?",
        //             warningMsg: "Your have not published your changes. if you close this page, your changes will be lost.",
        //             cancelHandler: () => setConfirmationPopup({isShow: false}),
        //             okHandler: () => {
        //                 setConfirmationPopup({isShow: false});
        //                 navigate("/snc/templates");
        //             }
        //         });
        //     }
        // });
        dispatch(templatesActions?.setExerciseSequence({week,day,data}));
        dispatch(setIsUpdateDisabled(false));
        dispatch(
            publishCalendarSequence({
              id, 
              type: 1
            })
        );
    };
    
    return (
        <div className="si-main-content">
            {isPopupActive &&
            <EditExercisePopup 
                isActive={isPopupActive} 
                cancelAction={() => setIsPopupActive(false)}
                saveAction={popupSaveHandler} 
                tabLists={["specs", "recurrence", "notes_media"]}
                type={1}
            />}

            <CalendarView 
                handleDragEnd={handleDragEnd}
                editExerciseHandler={editExerciseHandler}
                deleteExerciseHandler={deleteExerciseHandler}
                weekWiseCalendarData={weekWiseCalendarData}
                setDayToAddExercise={templatesActions?.setDayToAddExercise}
                tempoList={tempoList}
                unitsList={unitList}
            />

            <ExerciseActionPopup 
                type={exerciseActionPopup?.type}
                isActive={exerciseActionPopup?.isShow}
                isActionBtns={true}
                isRecurred={exerciseActionPopup?.isRecurred}
                cancelHandler={() => setExerciseActionPopup({isShow: false})}
                okHandler={exerciseActionPopup?.type === "edit" ? editConfirmationHandler : deleteConfirmationHandler}
            />

            <ConfirmationPopup 
                isActive={confirmationPopup?.isShow}
                isActionBtns={confirmationPopup?.isActionBtns}
                warning={confirmationPopup?.warning}
                warningMsg={confirmationPopup?.warningMsg}
                cancelHandler={confirmationPopup?.cancelHandler}
                okHandler={confirmationPopup?.okHandler}
            />
        </div>
    )
};

export default CalenderContent;