import { TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ListingTable from "../../../Common/ListingTableMUI";

function InternalNotesListingContent({ notesListing, pageNo, setPageNo, pagination,setNotesAddViewMode }) {
  const navigate = useNavigate()
  const tableHeaders = [
    // {
    //   label: "Injury Name/ID",
    //   align: "left",
    // },
    {
      label: "Note",
      align: "left",
    },

    {
      label: "Created By",
      align: "left",
    },

    {
      label: "Updated On",
      align: "left",
    },
    {
      label: "",
      align: "right",
      stickyClass: "stickyCell-right",
    },
  ];

  const formatDate = (date) => {
    let formatedDate = date?.split("T")[0]?.split("-").reverse().join("/");
    return formatedDate;
  };

//   const getHeaders = () => {
//     return Number(userData.role_id) === ATHELETE_ROLE_ID
//       ? [athleteHeaderCol, ...tableHeaders]
//       : [coachHeaderCol, ...tableHeaders];
//   };

  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {notesListing?.length ? (
          notesListing?.map((notes, idx) => {
            return (
              <TableRow key={uuidv4()}>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth:100 }}
                  // onClick={(e) => notes?.note_id  ? setNotesAddViewMode({addViewMode:true, noteId:notes?.note_id}) :""}
                  onClick={(e) => notes?.note_id  ? navigate(String(notes?.note_id)) :""}
                
                >
                  {notes?.note_name}
                </TableCell>
                <TableCell align={"left"}>{notes?.updated_by ? notes?.updated_by : "-"}</TableCell>

                <TableCell align={"left"}>{notes?.updated_date ? formatDate(notes?.updated_date) : "-"}</TableCell>

                <TableCell align={"right"} className="stickyCell-right">
                  <button
                    type="button"
                    className="si-viewBtn"
                    onClick={(e) => notes?.note_id  ? navigate(String(notes?.note_id)) :""}
                    // onClick={(e) => notes?.note_id ? setNotesAddViewMode({addViewMode:true, noteId:notes?.note_id}) :  ""}
                  >
                    <span className="si-icon__view"></span>
                    <span className="si-txt">View details</span>
                  </button>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  useEffect(() => {
    getTableBody();
  }, [notesListing]);
  return (
    <>
      <ListingTable
        tableHeaders={tableHeaders}
        getTableBody={getTableBody}
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={pagination}
        perPage={20}
      />
    </>
  );
}

export default InternalNotesListingContent;
