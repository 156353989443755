import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { sideMenuActions } from '../../reducer/sidemenu';
import { templatesActions } from '../../reducer/templates';
import { ATHLETES_MODULE_ID, ATHLETE_MODULE_ROUTE, SNC_MODULE_ID, TECHNICAL_MODULE_ID, TOURNAMENT_MODULE_ID, TOURNAMENT_MODULE_ROUTE } from '../../utility/constants';
let accessJson = require('../../utility/moduleWiseAccess.json'); 

const getCurrentActiveMenu = (moduleId, location) => {
  let currentActiveMenu = "";
  switch(moduleId) {
    case 1:
    case 2:
    case 9:
      currentActiveMenu = location?.pathname?.split("/")?.[2];
      break;
    default:
      currentActiveMenu = location?.pathname?.split("/")?.[1];
      break;
  };
  return currentActiveMenu;
};

function SideMenuTitle({subMenuObj, menuId, moduleId, index, activeMenu}) {
  const userData = useSelector(state => state?.auth?.userData);
  const selectedMenu = useSelector(state => state?.sideMenu?.selectedMenuId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let currentActiveMenu = getCurrentActiveMenu(moduleId, location);

  useEffect(() => {
    let routeMenu = location?.pathname?.split("/")?.[1]?.toLowerCase();
    let navigateTo = !routeMenu ? getNavigation(subMenuObj) : location?.href?.split("#")?.[1];
    let defaultModuleId;
    if(!routeMenu && index===0) defaultModuleId = moduleId;
    else if(routeMenu=="snc") defaultModuleId = SNC_MODULE_ID;
    else if(routeMenu=="technical") defaultModuleId = TECHNICAL_MODULE_ID;
    else if(routeMenu==ATHLETE_MODULE_ROUTE) defaultModuleId = ATHLETES_MODULE_ID;
    else if (routeMenu == TOURNAMENT_MODULE_ROUTE) defaultModuleId = TOURNAMENT_MODULE_ID;
    if(!selectedMenu && defaultModuleId==moduleId && menuId && subMenuObj && currentActiveMenu && currentActiveMenu==subMenuObj?.route_name) {
      dispatch(
        sideMenuActions?.setSelectedMenu({ 
          id: menuId, 
          moduleId,
          subMenuId: subMenuObj?.sub_menu_id,
          routeName: subMenuObj?.route_name,
          callback: () => navigate(navigateTo)
        })
      );
    };
  }, []);

  // useEffect(() => {
  //   if(menuId && subMenuObj && currentActiveMenu && currentActiveMenu==subMenuObj?.route_name) {
  //     dispatch(
  //       sideMenuActions?.setUserAccessData(
  //         accessJson[menuId]?.roles[userData?.role_id]?.access
  //       )
  //     );
  //   };
  // }, [menuId, subMenuObj]);

  const getNavigation = (subMenuObj)=>{
    let navPath = `/${subMenuObj?.route_name}`;
    if(moduleId == SNC_MODULE_ID){
      navPath =`/snc/${subMenuObj?.route_name}`;
    }else if(moduleId == TECHNICAL_MODULE_ID){
      navPath = `/technical/${subMenuObj?.route_name}`;
    } else if (moduleId == TOURNAMENT_MODULE_ID){
      navPath = `/${TOURNAMENT_MODULE_ROUTE}/${subMenuObj?.route_name}`
    }
    return navPath;
  };

  return (
    <li 
      className={ 
        (selectedMenu?.subMenuId && subMenuObj?.sub_menu_id ? selectedMenu.subMenuId == subMenuObj.sub_menu_id && selectedMenu?.moduleId == activeMenu?.menuId ? "active" : "" 
        : 
          selectedMenu?.moduleId==moduleId && currentActiveMenu==subMenuObj?.route_name && selectedMenu?.moduleId == activeMenu?.menuId) 
        ? "active" 
        : "" 
      }
      onClick={
        () => {
          dispatch(templatesActions?.setIsActiveOnMob());
          dispatch(
            sideMenuActions?.setSelectedMenu({ 
              id: menuId, 
              moduleId,
              subMenuId: subMenuObj?.sub_menu_id,
              routeName: subMenuObj?.route_name,
              callback: () => navigate(getNavigation(subMenuObj))
            })
          );
        }
      }
    >
      {subMenuObj?.sub_menu_name}
    </li>
  );
};

export default memo(SideMenuTitle);