import React, { useState } from 'react';
import {Box, Paper, TableContainer, Table, TablePagination} from '@mui/material';
import ListingTableHeader from './ListingTableHeaderMUI';

export default function ListingTable({tableHeaders, getTableBody, rowCount, selectedCount, checkboxHandler,
    pageNo, setPageNo, perPage, pagination}) {

    const handleChangePage = (e, newPage) => {
        setPageNo(newPage+1);
    };

    return (
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%'}}>
                <TableContainer className='si-custom-table-container'>
                        <Table
                            size={'small'}
                            stickyHeader
                            className="si-custom-table"
                            // style={{ tableLayout: "fixed" }}
                            // sx={{minWidth: 650}}
                        >
                            <ListingTableHeader 
                                tableHeaders={tableHeaders}
                                rowCount={rowCount}
                                selectedCount={selectedCount}
                                checkboxHandler={checkboxHandler}
                            />

                            
                            {getTableBody()}
                        </Table>
                    </TableContainer>
                </Paper>
                <div className="si-pagination">
                    {
                        pagination && 
                        <TablePagination
                            component="div"
                            className="si-tbl-pagination"
                            count={pagination?.total}
                            rowsPerPage={perPage}
                            page={pageNo-1}
                            onPageChange={handleChangePage}
                        />
                    }
                </div>
            </Box>  
        
    );
};
