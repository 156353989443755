import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../reducer/auth';
import { useRef } from 'react';

function Profile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const userData = useSelector(state => state.auth.userData);
    const isActiveOnMob = useSelector((state) => state?.templates?.isActiveOnMob);
    
    const ref = useRef();
    const logoutHandler = ()=>{
        dispatch(authActions.logout({callback: () => navigate("/login")}));
    };

    const closeAuthHandler = ()=>{
        setIsActive(false);
    }

    useEffect(() => {
        const checkIfClickedOutside = e => {
          if (ref.current && !ref.current.contains(e.target)) {
            closeAuthHandler();
          }
        }
        document.addEventListener("click", checkIfClickedOutside)
        return () => {
          document.removeEventListener("click", checkIfClickedOutside)
        }
      }, [closeAuthHandler])
                                   
    return (
        <div className="si-right">
            <div className={`si-profileBox ${isActive ? "active" : ""}`} ref={ref}>
                <div  className="si-profile" onClick={()=> setIsActive(!isActive)}> 
                 {/* <img src="../../images/admin.png" /> */}
                 <img src="images/user/default_athlete.png"/>
                </div>
                <span className="icon-carret" onClick={()=> setIsActive(!isActive)}></span>
                <div className="si-profileInfo">
                    <span className="si-profileName">
                        <span className="si-firstName">
                            { userData.first_name}&nbsp;
                        </span> 
                        <span className="si-lastName">
                            {userData.last_name}  
                            <span className="si-arrow"></span>
                        </span>        
                    </span>

                    <div className="si-otherDetails">
                        <span>{userData.role_name} </span>
                    </div>

                    <div className="si-logout" onClick={logoutHandler}>
                        <span className="si-logicon"></span>
                        <button>Sign out</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;