import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { loaderActions } from "../../../../../reducer/loader";
import { userManagementActions } from "../../../../../reducer/userManagement";
import { fetchSportsList } from "../../../../../utility/templateApiActions";
import {
  deleteAthleteFromGroup,
  fetchGroupAthletes,
  fetchGroupDetailsById,
  insertUpdateAthleteGroup,
} from "../../../../../utility/userManagementApiActions";
import AddAthletePopup from "../AddAthlete/AddAthletePopup";
import FixedButton from "../../../Common/FixedButton";
import Header from "../../../Common/Header";
import SubHeader from "../../../Common/SubHeader";
import Content from "./Content";
import ConfirmationPopup from "../../../Common/ConfirmationPopup";

export default function Group() {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popupIsActive, setPopupIsActive] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedSports, setSelectedSports] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState({ isShow: false });
  const [resetHeadingEdit, setResetHeadingEdit] = useState(false)
  const isAuth = useSelector((state) => state?.auth?.isAuthenticated);
  const selectedGroup = useSelector(
    (state) => state?.userManagement?.selectedGroup
  );
  const groupAthleteListing = useSelector(
    (state) => state?.userManagement?.groupAthleteListing
  );
  const sportList = useSelector((state) => state?.templates?.sportList);
  const { setIsUpdateDisabled } = userManagementActions;
  const paginationObject = useSelector(
    (state) => state?.userManagement?.paginationObject
  );
  const [headerData, setHeaderData] = useState({
    headerClass: "si-margin si-calheader",
    heading: selectedGroup?.group_name,
    isHeadingEditable: false,
    isbackBtn: true,
    backBtnAction: () => navigate("/athletegroups"),
    isCalendar: true,
    isDropdownEditable: false,
  });

  let filterOptions = {
    is_search_filter: false,
    is_search_filter_for_athlete: true,
  };

  useEffect(() => {
    if (selectedGroup) {
      console.log("selectedGroup--->", selectedGroup);
      setHeaderData({
        ...headerData,
        heading: selectedGroup?.group_name,
      });
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (isAuth) {
      dispatch(loaderActions?.showLoader());
      dispatch(
        fetchGroupDetailsById({
          id,
          callback: (data) =>
            setTimeout(() => {
              if (data) {
                fetchGroupAthleteListing();
              } else {
                toast.warn("No group data.");
                navigate("/athletegroups");
              }
            }),
        })
      );

      if (!sportList?.length) dispatch(fetchSportsList());
    }
  }, [isAuth]);

  // useEffect(() => {
  //   if (isAuth) {
  //     let athleteListTimeOut = setTimeout(() => {
  //       dispatch(loaderActions?.showLoader());
  //       fetchGroupAthleteListing();
  //     }, 0);

  //     if (pageNo !== 1 || selectedSports?.length) setIsFiltered(true);

  //     return () => clearTimeout(athleteListTimeOut);
  //   }
  // }, [isAuth,pageNo, selectedSports]);



  useEffect(() => {
    if (isAuth) {
      let athleteListTimeOut = setTimeout(() => {
        dispatch(loaderActions?.showLoader());
        fetchGroupAthleteListing();
      },0);

      if (pageNo !== 1 || selectedSports?.length) setIsFiltered(true);

      return () => clearTimeout(athleteListTimeOut);
    }
  }, [isAuth, searchTerm,pageNo, selectedSports]);




  const fetchGroupAthleteListing = (callback) => {
    dispatch(
      fetchGroupAthletes({
        id,
        pageNo,
        searchTerm,
        selectedSports,
        callback: () =>
          setTimeout(() => {
            dispatch(loaderActions?.hideLoader());
            if (callback) callback(true);
          }),
      })
    );
  };

  const sportOptions = sportList?.map((obj) => {
    return {
      value: obj?.sport_id,
      label: obj?.sport_name,
    };
  });

  const editAction = (e) => {
    e?.preventDefault();
    setHeaderData({
      ...headerData,
      isHeadingEditable: true,
      isDropdownEditable: true,
    });
    setResetHeadingEdit(false)
  };

  const saveAction = (e, groupName, selectedSport) => {
    e?.preventDefault();
    dispatch(userManagementActions.setDefault());
    if (groupName && selectedSport?.value) {
      setConfirmationPopup({
        isShow: true,
        isActionBtns: true,
        warning: "Are you sure you want to save the data?",
        warningMsg: "",
        cancelHandler: () => setConfirmationPopup({ isShow: false }),
        okHandler: () => {
          dispatch(loaderActions?.showLoader());
          setConfirmationPopup({ isShow: false });
          dispatch(
            insertUpdateAthleteGroup({
              optType: 2,
              name: groupName,
              assignedAthleteIds: selectedGroup?.athlete_ids,
              sportId: selectedSport?.value,
              callback: () =>
                setTimeout(() => {
                  setHeaderData({
                    ...headerData,
                    isHeadingEditable: false,
                    isDropdownEditable: false,
                  });
                  dispatch(loaderActions?.hideLoader());
                  toast.success("Group updated successfully.", {
                    toastId: "template-calendar-details-update-success",
                  });
                }),
            })
          );
        },
      });
    } else {
      console.log("Post Template Error");
    }
  };

  const cancelAction = () => {
    setConfirmationPopup({
      isShow: true,
      isActionBtns: true,
      warning: "Are you sure you want to discard your changes?",
      warningMsg: "",
      cancelHandler: () => setConfirmationPopup({ isShow: false }),
      okHandler: () => {
        setConfirmationPopup({ isShow: false });
        setHeaderData({
          ...headerData,
          heading: selectedGroup?.group_name,
          isHeadingEditable: false,
          isDropdownEditable: false,
        });
        setResetHeadingEdit(true)
        // setHeaderData({
        //   headerClass:"si-margin si-calheader",
        //   heading: selectedGroup?.group_name,
        //   isHeadingEditable: false,
        //   isbackBtn: true,
        //   backBtnAction: () => navigate("/snc/templates"),
        //   isCalendar: true,
        //   isCountEditable: false,
        //   isDropdownEditable: false
        // });
      },
    });
  };

  const addBtnClickHandler = () => {
    if(headerData.isHeadingEditable){
      cancelAction()
    } else {
      setPopupIsActive(true);
    }
  };

  const addAthleteHandler = (athleteIds) => {
    console.log("add athlete handler", athleteIds);
    dispatch(
      insertUpdateAthleteGroup({
        optType: 2,
        name: selectedGroup?.group_name,
        assignedAthleteIds: athleteIds,
        sportId: selectedGroup?.sport_id,
        callback: (res) =>
          setTimeout(() => {
            fetchGroupAthleteListing();
            if (res)
              toast.success("Athletes added to group successfully.", {
                toastId: "athlete-added-success",
              });
            else
              toast.warn("Something went wrong.", {
                toastId: "athlete-added-warning",
              });
            setPopupIsActive(false);
            dispatch(setIsUpdateDisabled(false));
          }),
      })
    );
  };

  const deleteAthleteHandler = (AthleteId) => {
    dispatch(
      deleteAthleteFromGroup({
        athleteId: AthleteId,
        groupId: id,
        callback: () => {
          setTimeout(() => {
            fetchGroupAthleteListing();
            // dispatch(
            //   fetchGroupDetailsById({
            //     id,
            //     callback: (data) =>
            //       setTimeout(() => {
            //         if (data) {
            //           fetchGroupAthleteListing();
            //         }
            //       }),
            //   })
            // );
          });
        },
      })
    );
  };

  return (
    <Fragment>
      <Header
        headerData={headerData}
        dropdownPlaceHolder={"Select Sport"}
        dropdownValue={sportOptions?.find(
          (obj) =>
            obj?.label?.toLowerCase() ===
              selectedGroup?.sport_name?.toLowerCase() ||
            obj?.value == selectedGroup?.sport_id
        )}
        dropdownOptions={sportOptions}
        editAction={editAction}
        saveAction={saveAction}
        cancelAction={cancelAction}
        resetHeadingEdit= {resetHeadingEdit}
        setResetHeadingEdit = {setResetHeadingEdit}
      />
      {/* {groupAthleteListing?.length ? (
        <> */}
          <SubHeader
            filterOptions={filterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            // setSelectedSportIds={setSelectedSports}
            pageNo={pageNo}
            setPageNo={setPageNo}
            // navigate={navigate}
            isBtn={true}
            btnName="Add Athletes"
            onBtnClick={addBtnClickHandler}
          />
          <Content
            pageNo={pageNo}
            setPageNo={setPageNo}
            pagination={paginationObject}
            perPage={20}
            searchTerm={searchTerm}
            setConfirmationPopup={setConfirmationPopup}
            deleteAthleteHandler={deleteAthleteHandler}
          />
          <FixedButton
            btnName="Add Atheletes"
            onBtnClick={addBtnClickHandler}
            iconClass="add"
          />
        {/* </>
      ) : (
        <div className="si-firstStep si-align-center">
          <div className="si-text">
            <span>Add Athletes</span>
          </div>
          <button
            type="button"
            className="si-button si-button--green si-icon--add"
            onClick={() => setPopupIsActive(true)}
          >
            <span className="icon-add"></span>Add Athletes
          </button>
        </div>
      )} */}
      {popupIsActive && (
        <AddAthletePopup
          isActive={popupIsActive}
          setIsActive={setPopupIsActive}
          actionBtnHandler={addAthleteHandler}
          setIsFiltered={setIsFiltered}
          alreadyAddedAthletes={groupAthleteListing}
        />
      )}
      <ConfirmationPopup
        isActive={confirmationPopup?.isShow}
        isActionBtn={confirmationPopup?.isActionBtn}
        isActionBtns={confirmationPopup?.isActionBtns}
        warning={confirmationPopup?.warning}
        warningMsg={confirmationPopup?.warningMsg}
        cancelHandler={confirmationPopup?.cancelHandler}
        okHandler={confirmationPopup?.okHandler}
      />
    </Fragment>
  );
}
