import React, { memo } from 'react';
import {TablePagination} from '@mui/material';
import { useSelector } from 'react-redux';

function Footer({pagination, pageNo, setPageNo, perPage, 
    isActionBtn, btnClass, btnDisabled=false, btn1Disabled=false, actionBtnText, actionBtnHandler,
    isActionBtns, actionBtn1Text, btn1Class, actionBtn1Handler,
    actionBtn2Text, btn2Class, btn2Disabled=false, actionBtn2Handler}) {

    // const handleChangePage = (e, newPage) => {
    //     setPageNo(newPage+1);
    // };
        
    return (
        <div className="si-content-footer">
            <div className="si-footer-wrapper">
                {/* <div className="si-pagination">
                    {   
                        pagination && 
                        <TablePagination
                            component="div"
                            className="si-tbl-pagination"
                            count={pagination?.total}
                            rowsPerPage={perPage}
                            page={pageNo-1}
                            onPageChange={handleChangePage}
                        />
                    }
                </div> */}
                {
                    isActionBtn && 
                    <button 
                        type="button" 
                        className={btnClass}
                        onClick={actionBtnHandler}
                        disabled={btnDisabled}
                    >
                        {actionBtnText}
                    </button>
                }
                {
                    isActionBtns && 
                    <div className="si-action-buttons">
                        <button 
                            type="button" 
                            className={btn1Class}
                            onClick={actionBtn1Handler}
                            disabled={btn1Disabled}
                        >{actionBtn1Text}</button>

                        <button 
                            type="button" 
                            className={btn2Class}
                            onClick={actionBtn2Handler}
                            disabled={btn2Disabled}
                        >{actionBtn2Text}</button>
                    </div>
                }
            </div>
        </div>
    );
};

export default memo(Footer);