import React, { useEffect, useState } from "react";
import Dropdown from "../../Common/Dropdown";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Footer from "../../Common/Footer";
import { insertUpdatePsychologyCase } from "../../../../utility/PsychologyApiActions";
import { useNavigate } from "react-router-dom";
import DatePickerComponent from "../../Common/DatePickerComponent";
import { AGE_LIMIT, SIBLING_LIMIT, ATHELETE_ROLE_ID } from "../../../../utility/constants";
import FixedButton from "../../Common/FixedButton";
import ConfirmationPopup from "../../Common/ConfirmationPopup";
import { loaderActions } from "../../../../reducer/loader";
import moment from "moment/moment";

export default function CaseLogContent({
  athleteDetails,
  isEditOption,
  caseLogDetails,
  setCaseLogDetails,
  isError,
  setIsError,
  isEditMode,
  setIsEditMode,
  saveHandler,
  cancelHandler,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [age, setAge] = useState("");
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const userData = useSelector((state) => state?.auth?.userData);
  const [confirmationPopup, setConfirmationPopup] = useState({ isShow: false });
  const allMaritalStatusList = useSelector((state) => state?.psychology?.allMaritalStatusList);
  const allInformantList = useSelector((state) => state?.psychology?.allInformantList);
  const allEducationList = useSelector((state) => state?.psychology?.allEducationList);
  // const athleteDetails = useSelector(state => state?.userManagement?.athleteDetails);
  const loading = useSelector((state) => state?.psychology?.loading);
  const [matchAthRoleId, setMatchAthRoleId] = useState(false);
  useEffect(() => {
    if (loading) {
      dispatch(loaderActions.showLoader());
    } else {
      dispatch(loaderActions.hideLoader());
    }
  }, [loading]);

  const maritalOptions = allMaritalStatusList?.map((a) => {
    return {
      value: a.marital_status_id,
      label: a.status,
    };
  });
  const informantOptions = allInformantList?.map((a) => {
    return {
      value: a.informant_id,
      label: a.informant,
    };
  });
  const educationOptions = allEducationList?.map((a) => {
    return {
      value: a.education_id,
      label: a.education,
    };
  });
  const ageOptions = [...Array(AGE_LIMIT - 1)].map((_, i) => {
    i = i + 1;
    return {
      value: i,
      label: `${i} ${i > 1 ? "Years" : "Year"}`,
    };
  });

  const genderOptions = [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];

  const siblingsOptions = [...Array(SIBLING_LIMIT)].map((_, i) => {
    i = i;
    return {
      value: i,
      label: i,
    };
  });

  const ageChangeHandler = (data) => {
    setCaseLogDetails((prevState) => {
      return { ...prevState, age: data?.value };
    });
  };

  const genderChangeHandler = (data) => {
    setCaseLogDetails((prevState) => {
      return { ...prevState, gender: data?.value };
    });
  };

  const educationChangeHandler = (data) => {
    setCaseLogDetails((prevState) => {
      return { ...prevState, education_id: data?.value };
    });
    if (isError?.show && isError?.type == "education") {
      setIsError({ type: "education", show: false });
    }
  };

  const siblingsChangeHandler = (data) => {
    setCaseLogDetails((prevState) => {
      return { ...prevState, siblings_cnt: data?.value };
    });
    if (isError?.show && isError?.type == "siblings_cnt") {
      setIsError({ type: "siblings_cnt", show: false });
    }
  };

  const maritalStatusChangeHandler = (data) => {
    setCaseLogDetails((prevState) => {
      return { ...prevState, marital_status_id: data?.value };
    });
    if (isError?.show && isError?.type == "marital_status") {
      setIsError({ type: "marital_status", show: false });
    }
  };

  const informantChangeHandler = (data) => {
    setCaseLogDetails((prevState) => {
      return { ...prevState, informant_id: data?.value };
    });
    if (isError?.show && isError?.type == "informant") {
      setIsError({ type: "informant", show: false });
    }
  };

  const contactChangeHandler = (e) => {
    setCaseLogDetails((prevState) => {
      return { ...prevState, phone: e?.target?.value };
    });
    if (isError?.show && isError?.type == "phone") {
      setIsError({ type: "phone", show: false });
    }
  };

  const caseDateChangeHandler = (date) => {
    let case_date = moment(date).format("MM/DD/YYYY");
    setCaseLogDetails((prevState) => {
      return { ...prevState, case_date: case_date };
    });
    if (isError?.show && isError?.type == "case_date") {
      setIsError({ type: "case_date", show: false });
    }
  };

  const reasonChangeHandler = (e) => {
    setCaseLogDetails((prevState) => {
      return { ...prevState, reason: e?.target?.value };
    });
    if (isError?.show && isError?.type == "reason") {
      setIsError({ type: "reason", show: false });
    }
  };

  useEffect(() => {
    if (caseLogDetails && caseLogDetails?.age) {
      setAge(`${caseLogDetails?.age}${caseLogDetails?.age > 1 ? " years" : " year"}`);
    }
  }, [caseLogDetails]);

  const isValidPhoneNumber = (phonenumber) => {
    // Define the regular expression pattern using RegExp object
    // const pattern = new RegExp("^\\+[1-9]{1,3}-[0-9]{10}$");
    const pattern = new RegExp("^[0-9]{10}$");

    // Test the phone number against the pattern
    if (pattern.test(phonenumber)) {
      return true;
    } else {
      return false;
    }
  };

  const saveCaseHandler = () => {
    console.log("save:", caseLogDetails);
    setIsError({});
    if (!caseLogDetails?.athlete_name) {
      return setIsError({ type: "athlete_name", show: true, error: "Athlete name is required" });
    }
    // if (!caseLogDetails?.education_id) {
    //   return setIsError({ type: 'education', show: true, error: "Education is required" });
    // }
    // if (!caseLogDetails?.siblings_cnt) {
    //   return setIsError({ type: 'siblings_cnt', show: true, error: "No of siblings is required" });
    // }
    // if (!caseLogDetails?.marital_status_id) {
    //   return setIsError({ type: 'marital_status', show: true, error: "Marital status is required" });
    // }
    if (!caseLogDetails?.informant_id) {
      return setIsError({ type: "informant", show: true, error: "Informant is required" });
    }
    if (!caseLogDetails?.reason) {
      return setIsError({ type: "reason", show: true, error: "Reason is required" });
    }

    if (!caseLogDetails?.phone) {
      return setIsError({ type: "phone", show: true, error: "Phone number is required" });
    } else {
      if (!isValidPhoneNumber(caseLogDetails?.phone)) {
        return setIsError({ type: "phone", show: true, error: "Please add a valid mobile number" });
      }
    }
    if (!caseLogDetails?.case_date) {
      return setIsError({ type: "case_date", show: true, error: "Case date is required" });
    }
    // if (!caseLogDetails?.reason) {
    //   return setIsError({ type: 'reason', show: true, error: "Reason is required" });
    // }
    dispatch(
      insertUpdatePsychologyCase({
        payload: { ...caseLogDetails, athlete_guid: athleteDetails?.athlete_guid, user_guid: userData?.user_guid },
        callback: (id) => {
          saveHandler(id);
        },
      })
    );
  };

  const cancelCaseHandler = (e) => {
    e?.stopPropagation();
    setConfirmationPopup({
      isShow: true,
      isActionBtns: true,
      warning: "Are you sure you want to cancel?",
      warningMsg: "",
      cancelHandler: () => setConfirmationPopup({ isShow: false }),
      okHandler: () => {
        setConfirmationPopup({ isShow: false });
        setIsError(false);
        cancelHandler();
      },
    });
  };
  useEffect(() => {
    let matchAthRole = userData && userData?.role_id == ATHELETE_ROLE_ID;
    setMatchAthRoleId(matchAthRole);
  }, [userData]);

  const progressNoteHandler = () => {
    navigate(`progressnotes`);
  };
  return (
    <>
      <div class={`si-main-content psychology-case-log ${isEditMode ? "" : "si-view"}`}>
        <div class="si-headsession">
          <div class="si-clientInfo">
            <div class="si-image">
              <img src="../../images/person.png" />
            </div>
            <div class="si-clientname">{caseLogDetails?.athlete_name}</div>
          </div>
          {!isEditMode && (
            <button
              type="button"
              class="si-button si-button--green si-icon-progress"
              onClick={() => progressNoteHandler()}
            >
              <span class="icon-progress"></span>
              {"Progress Notes"}
              {/* <span class="icon-progress"></span>{matchAthRoleId ? "Session Details" : "Progress Notes"}  */}
            </button>
          )}
        </div>
        <form class="case-log-content">
          <h4 class="si-title">
            Case File No : <span class="file-no">{caseLogDetails?.case_file_no}</span>
            {isEditOption && <span class="icon-edit" onClick={() => setIsEditMode(true)}></span>}
          </h4>
          <div class="content-row-wrp">
            <div class="content-row">
              <div class="inner-content-wrp">
                <div class={`form-control ${isError?.show && isError?.type == "case_date" ? "si-error" : ""}`}>
                  <div class="form-group">
                    <label for="start-date" class="si-label">
                      <span>*</span>Case Opened On
                    </label>
                    <div class="form-group-wrap si-location date-form-control">
                      {/* <input type="date" id="start-date" value={caseLogDetails?.case_date}
                        class={`si-input ${!isEditMode ? 'si-disable' : ''}`} onChange={(e) => caseDateChangeHandler(e)} /> */}
                      {/* <DatePicker
                        calendarClassName="si-calendar"
                        className="si-datepicker"
                        showIcon
                        disabled={isEditMode ? false : true}
                        selected={caseLogDetails?.case_date ? new Date(caseLogDetails?.case_date) : new Date()}
                        onChange={(date) => caseDateChangeHandler(date)} /> */}
                      <DatePickerComponent
                        isEditMode={isEditMode ? false : true}
                        selectedDate={caseLogDetails?.case_date}
                        maxDate={new Date()}
                        dateChangeHandler={caseDateChangeHandler}
                      />
                    </div>
                  </div>
                  <span class="si-error-message">{isError?.type == "case_date" ? isError?.error : ""}</span>
                </div>
              </div>
              <div class="inner-content-wrp">
                <div
                  class={`form-control reffered-by ${isError?.show && isError?.type == "informant" ? "si-error" : ""}`}
                >
                  <div class="form-group">
                    <label for="selectInformant" class="si-label">
                      *Referred By
                    </label>
                    <Dropdown
                      placeholder={"Select Referred By"}
                      options={informantOptions}
                      value={informantOptions?.find((func) => func?.value == caseLogDetails?.informant_id)}
                      changeHandler={informantChangeHandler}
                      isDisabled={!isEditMode ? true : false}
                      isDefaultOption={true}
                    />
                  </div>
                  <span class="si-error-message">{isError?.type == "informant" ? isError?.error : ""}</span>
                </div>
              </div>
            </div>
            <div class="content-row">
              <div class={`form-control reason-desc ${isError?.show && isError?.type == "reason" ? "si-error" : ""}`}>
                <label for="" class="si-label">
                  *Reason for consulting
                </label>
                <textarea
                  readOnly={!isEditMode ? true : false}
                  id=""
                  name=""
                  rows="5"
                  cols=""
                  class={`si-input `}
                  value={caseLogDetails?.reason}
                  placeholder="Add a reason for consultation"
                  onChange={(e) => reasonChangeHandler(e)}
                ></textarea>
                <span class="si-error-message">{isError?.type == "reason" ? isError?.error : ""}</span>
              </div>
            </div>
            <div class="si-additionalInfo">Additional Information</div>
            <div class="content-row">
              <div class="inner-content-wrp">
                <div class="form-control selectSport-form-control">
                  <div class="form-group">
                    <label for="selectAge" class="si-label">
                      Age
                    </label>
                    <input type="text" class="si-input si-default" value={age}></input>
                    {/* <Dropdown
                      placeholder={"Select Age"}
                      value={ageOptions?.find(func => func?.value == caseLogDetails?.age)}
                      options={ageOptions}
                      isSearchable={true}
                      changeHandler={ageChangeHandler}
                      isDisabled={true}
                      isDefaultOption={true}
                    /> */}
                  </div>
                  {/* <span class="si-error-message">age is required</span> */}
                </div>
                <div class="form-control selectSport-form-control">
                  <div class="form-group">
                    <label class="si-label">Gender</label>
                    <input type="text" class="si-input si-disable" value={caseLogDetails?.gender}></input>
                    {/* <Dropdown
                      placeholder={"Select Gender"}
                      value={genderOptions?.find(func => func?.value == caseLogDetails?.gender)}
                      options={genderOptions}
                      isSearchable={true}
                      changeHandler={genderChangeHandler}
                      isDisabled={true}
                      isDefaultOption={true}
                    /> */}
                  </div>
                  {/* <span class="si-error-message">type name is required</span> */}
                </div>
              </div>
              <div class="inner-content-wrp">
                <div class={`form-control ${isError?.show && isError?.type == "marital_status" ? "si-error" : ""}`}>
                  <div class="form-group">
                    <label for="selectMarital" class="si-label">
                      Marital status
                    </label>
                    <Dropdown
                      placeholder={"Select Marital Status"}
                      options={maritalOptions}
                      value={maritalOptions?.find((func) => func?.value == caseLogDetails?.marital_status_id)}
                      changeHandler={maritalStatusChangeHandler}
                      isDisabled={!isEditMode ? true : false}
                      isDefaultOption={true}
                      menuPlacement="top"
                    />
                  </div>
                  <span class="si-error-message">{isError?.type == "marital_status" ? isError?.error : ""}</span>
                </div>
                <div class={`form-control ${isError?.show && isError?.type == "siblings_cnt" ? "si-error" : ""}`}>
                  <div class="form-group">
                    <label for="selectSiblings" class="si-label">
                      No of siblings
                    </label>
                    <Dropdown
                      placeholder={"Select Siblings"}
                      options={siblingsOptions}
                      value={siblingsOptions?.find((func) => func?.value == caseLogDetails?.siblings_cnt)}
                      changeHandler={siblingsChangeHandler}
                      isDisabled={!isEditMode ? true : false}
                      isDefaultOption={true}
                      menuPlacement="top"
                    />
                  </div>
                  <span class="si-error-message">{isError?.type == "siblings_cnt" ? isError?.error : ""}</span>
                </div>
              </div>
              <div class="inner-content-wrp">
                <div class={`form-control ${isError?.show && isError?.type == "education" ? "si-error" : ""}`}>
                  <div class="form-group">
                    <label for="selectEducation" class="si-label">
                      Education
                    </label>
                    <Dropdown
                      placeholder={"Select Education"}
                      options={educationOptions}
                      value={educationOptions?.find((func) => func?.value == caseLogDetails?.education_id)}
                      changeHandler={educationChangeHandler}
                      isDisabled={!isEditMode ? true : false}
                      isDefaultOption={true}
                      menuPlacement="top"
                    />
                  </div>
                  <span class="si-error-message">{isError?.type == "education" ? isError?.error : ""}</span>
                </div>
                <div class={`form-control ${isError?.show && isError?.type == "phone" ? "si-error" : ""}`}>
                  <div class="form-group">
                    <label for="contact" class="si-label">
                      <span>*</span>Mobile Number
                    </label>
                    <input
                      class={`si-input contact ${!isEditMode ? "si-disable" : ""}`}
                      type="number"
                      placeholder="10 Digit Number"
                      value={caseLogDetails?.phone}
                      onChange={(e) => contactChangeHandler(e)}
                    />
                  </div>
                  <span class="si-error-message">{isError?.type == "phone" ? isError?.error : ""}</span>
                </div>
              </div>
            </div>
          </div>
        </form>
        <ConfirmationPopup
          isActive={confirmationPopup?.isShow}
          isActionBtn={confirmationPopup?.isActionBtn}
          isActionBtns={confirmationPopup?.isActionBtns}
          warning={confirmationPopup?.warning}
          warningMsg={confirmationPopup?.warningMsg}
          cancelHandler={confirmationPopup?.cancelHandler}
          okHandler={confirmationPopup?.okHandler}
        />
      </div>
      {isEditMode && (
        <footer>
          <button type="button" class="si-button  si-icon--add  si-clearbtn" onClick={(e) => cancelCaseHandler(e)}>
            cancel
          </button>
          <button
            type="button"
            class="si-button si-button--green si-icon--add si-savebtn"
            onClick={() => saveCaseHandler()}
          >
            save
          </button>
        </footer>
      )}
      {!isEditMode && (
        <FixedButton
          btnName={"Progress Notes"}
          // btnName={userData && userData?.role_id === String(ATHELETE_ROLE_ID) ? "Session Details" : "Progress Notes"}
          onBtnClick={() => progressNoteHandler()}
          iconClass="progress"
        />
      )}
    </>
  );
}
