import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPerformanceTournamentDetails } from "../../../../reducer/tournamentsPerformance.reducer";
import { getEventsPerformanceRecordAthleteListing, getperformancerecordlisting } from "../../../../utility/tournamentPerformanceApiAction";
import TournamentListContent from "./TournamentListContent";

function Tournaments({ activeTab }) {
  const athleteDetails = useSelector((state) => state?.userManagement?.athleteDetails);
  const dispatch = useDispatch();
  let [pageNo, setPageNo] = useState(1);
  let performanceLists = useSelector((state) => state?.tournamentPerformance?.athleteEventRecords);
  let paginationObject = useSelector((state) => state?.tournamentPerformance?.athleteEventRecordsPagination);
  const navigate = useNavigate();
  useEffect(() => {
    if (activeTab === "tournaments") {
      console.log("athleteDetails", athleteDetails);
      dispatch(
        getEventsPerformanceRecordAthleteListing({
          sport_id: athleteDetails?.sport_id ? athleteDetails?.sport_id : null,
          page_no: pageNo,
          item_number: 20,
          search: null,
          athlete_guid: athleteDetails?.athlete_guid ? athleteDetails?.athlete_guid : null,
        })
      );
    }
  }, [activeTab, athleteDetails, dispatch, pageNo]);
  const handleTrackPerformance = (event, tournamentDetails) => {
    dispatch(setPerformanceTournamentDetails(tournamentDetails));
    navigate(`/athleteprofile/${tournamentDetails.performance_record_id}/${tournamentDetails?.sport_id[0]}/${tournamentDetails.event_id}`);
  };

  return (
    <>
      <div className="si-tab-pane si-tab-pane__Training">
        <div className="si-main-content myprofile-maincontent">
          <TournamentListContent
            pagination={paginationObject}
            performanceLists={performanceLists}
            setPageNo={setPageNo}
            pageNo={pageNo}
            handleTrackPerformance={handleTrackPerformance}
          />
        </div>
      </div>
    </>
  );
}

export default Tournaments;
