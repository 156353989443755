import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { fetchCaseList, getCaseLog, fetchEducationList, fetchInformantList, fetchMaritalStatusList,
   insertUpdatePsychologyCase, fetchCaseLogList, getSessionName, insertUpdatePsychologySession,getSessionLog } from "../utility/PsychologyApiActions";

const initialState = {
  loading: false,
  caseList: null,
  caseLogList:null,
  sessionName:null,
  sessionDisplayName:null,
  selectedAthlete: null,
  casefileDetails: null,
  allEducationList: null,
  allInformantList: null,
  allMaritalStatusList: null,
  selectedPsychologyCaseId:null,
  selectedPsychologySessionId:null,
  sessionDetails:null
};

export const psychologySlice = createSlice({
  name: "psychology",
  initialState,
  reducers: {
    // resetSelectedInjuryDetails: (state) => {
    //   state.selectedInjuryDetails = null;
    //   return state;
    // },
    // resetInjuryDetailsUpdate : (state)=>{
    //   state.injuryDetailsUpdated = false
    //   return state
    // },
    resetCaseDetails : (state)=>{
      state.casefileDetails = null;
    },
    resetCaseSessionDetails : (state)=>{
      state.sessionDetails = null;
    },
    selectAthlete: (state, action) => {
      state.selectedAthlete = action.payload.details
      return state
    },
    resetSelectedAthlete: (state, action) => {
      state.selectedAthlete = null
      return state
    },
    resetSelectedPsychologyCase : (state,action)=>{
      state.selectedPsychologyCaseId = null;
      return state;
    },
    resetSelectedPsychologySession : (state,action)=>{
      state.selectedPsychologySessionId = null;
      return state;
    }
  },
  extraReducers: {
    [fetchCaseList.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchCaseList.fulfilled]: (state, action) => {
      state.loading = false;
      state.caseList = action?.payload?.caseList?.data?.value;
      return state;
    },
    [fetchCaseList.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch psychology case list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
    },
    [getCaseLog.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [getCaseLog.fulfilled]: (state, action) => {
      state.loading = false;
      state.casefileDetails = action?.payload?.caseFileDetails?.data?.value?.data;
      return state;
    },
    [getCaseLog.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch case file number. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
    },
    [fetchEducationList.fulfilled]: (state, action) => {
      state.allEducationList = action?.payload?.data?.data?.value?.data;
    },
    [fetchEducationList.rejected]: (state, action) => {
      toast?.error(`Unable to fetch gender list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
      console.log("Error while fetching gender list: ", action?.error);
    },
    [fetchMaritalStatusList.fulfilled]: (state, action) => {
      state.allMaritalStatusList = action?.payload?.data?.data?.value?.data;
    },
    [fetchMaritalStatusList.rejected]: (state, action) => {
      toast?.error(`Unable to fetch gender list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
      console.log("Error while fetching gender list: ", action?.error);
    },
    [fetchInformantList.fulfilled]: (state, action) => {
      state.allInformantList = action?.payload?.data?.data?.value?.data;
    },
    [fetchInformantList.rejected]: (state, action) => {
      toast?.error(`Unable to fetch gender list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
      console.log("Error while fetching gender list: ", action?.error);
    },
    [insertUpdatePsychologyCase.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [insertUpdatePsychologyCase.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedPsychologyCaseId = action?.payload?.caseId?.data?.value;
      if(action?.payload?.callback) action?.payload?.callback(state.selectedPsychologyCaseId);
      return state;
    },
    [insertUpdatePsychologyCase.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to save psychology case details. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      return state;
    },
    [fetchCaseLogList.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [fetchCaseLogList.fulfilled]: (state, action) => {
      state.loading = false;
      state.caseLogList = action?.payload?.caseLogList?.data?.value;
      return state;
    },
    [fetchCaseLogList.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch psychology case log list. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
    },
    [getSessionName.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [getSessionName.fulfilled]: (state, action) => {
      state.loading = false;
      state.sessionName = action?.payload?.sessionName?.data?.value?.session_name;
      state.sessionDisplayName = action?.payload?.sessionName?.data?.value?.display_name;
      return state;
    },
    [getSessionName.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch session name. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
    },
    [insertUpdatePsychologySession.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [insertUpdatePsychologySession.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedPsychologySessionId = action?.payload?.sessionId?.data?.value;
      if(action?.payload?.callback) action?.payload?.callback(state.selectedPsychologySessionId);
      return state;
    },
    [insertUpdatePsychologySession.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to save psychology session details. Please try again : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`,{toastId:"apiErrorToast"});
      return state;
    },
    [getSessionLog.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [getSessionLog.fulfilled]: (state, action) => {
      state.loading = false;
      state.sessionDetails = action?.payload?.sessionDetails?.data?.value?.data;
      return state;
    },
    [getSessionLog.rejected]: (state, action) => {
      state.loading = false;
      toast?.error(`Unable to fetch session details. Please try again later : \n${action?.payload?.error ? action?.payload?.error?.message : action?.error?.message}`, { toastId: "apiErrorToast" });
    },
  },
});

export const { selectAthlete, resetSelectedAthlete ,resetSelectedPsychologyCase,resetSelectedPsychologySession, resetCaseDetails, resetCaseSessionDetails } = psychologySlice.actions;
export default psychologySlice.reducer;
