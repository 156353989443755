import React from 'react';
import Button from '../Button';
import { format, addDays, addWeeks, subDays, isWeekend } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import moment from 'moment/moment';

export default function StartDatePopup({startDate,startDatePopup,setStartDatePopup, saveStartDateHandler,week}) {
    const [range, setRange] = useState([
        {
            startDate: startDate ? new Date(startDate) : addDays(new Date(), 1),
            endDate: startDate ? subDays(addWeeks(new Date(startDate), week), 1) : addWeeks(new Date(), week),
            key: 'selection'
        }
    ]);
    const [open, setOpen] = useState(false);
    let [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
    const datePickerReference = useRef(null);

    useEffect(() => {
        window?.addEventListener("keydown", hideOnEscape, true);
        window?.addEventListener("click", hideOnOutsideClick, true);

        return () => {
            window?.removeEventListener("keydown", hideOnEscape, true);
            window?.removeEventListener("click", hideOnOutsideClick, true);
        };
    }, []);

    const hideOnEscape = (e) => {
        if (e?.key === "Escape") {
            setOpen(false);
        };
    };

    const hideOnOutsideClick = (e) => {
        if (datePickerReference?.current && !datePickerReference?.current?.contains(e?.target)) {
            setOpen(false);
        };
    };

    const changeHandler = (data) => {
        console.log(data?.selection, "<====Range data");
        let startDate = data?.selection.startDate;
        let endDate = subDays(addWeeks(startDate, week), 1);
        console.log(startDate,endDate);
        setRange([
            {
                startDate: new Date(data?.selection.startDate),
                endDate,
                key: 'selection'
            }
        ]);
    };

    const saveAction = (e)=>{
        e.preventDefault();
        let data = {
            start_date:moment(range?.[0]?.startDate).format("M/DD/yyyy"),
            end_date:moment(range?.[0]?.endDate).format("M/DD/yyyy")
        };
        saveStartDateHandler(data);
    };

    return (
        <div className={`si-custom-popup si-popup ${startDatePopup ? "active" : ""}`}>
            <div className="si-popup-content">
                <div className="si-popup-head">
                    <div className="si-popup__title">
                        <div className="si-label-wrap">
                            <h5 className="label-head">Select Start Date</h5>
                            <span className="label-info si-datelabel">You cannot change the start date once the program begins</span>
                        </div>
                        <button type="button" className="si-icon__button si-button__close"  onClick={() => setStartDatePopup(false)}>
                        <span className="icon-close"></span>
                        </button>
                    </div>
                    {/* <div className="si-subHeader">
                
                    </div> */}
                </div>
                <div className="si-popup-body">
                    <div className='si-date-range-picke'>
                        <div className="si-date-picker">
                            <div className="si-input-wrap">
                                <input
                                    value={moment(range?.[0]?.startDate).format("DD/M/yyyy")}
                                    readOnly
                                    onClick={() => setOpen(!open)}
                                    className="select-date select-start-date"
                                />
                                <span className='separator'>-</span>
                                <input
                                    value={moment(range?.[0]?.endDate).format("DD/M/yyyy")}
                                    disabled
                                    // onClick={() => setOpen(!open)}
                                    className="select-date select-end-date"
                                />
                            </div>
                            <div
                                ref={datePickerReference}
                                className={`si-date-picker-container ${currentMonth === new Date().getMonth() ? "rdrtPrevButton-disabled" : "" }`}>
                                {/* className="si-date-picker-container rdrtPrevButton-disabled rdrtNextButton-disabled"> */}
                                    <DateRange
                                        className="si-date-range-picker si-dekstopcalendareview"
                                        ranges={range}
                                        onChange={changeHandler}
                                        minDate={addDays(new Date(), 1)}
                                        editableDateInputs={false}
                                        dragSelectionEnabled={false}
                                        moveRangeOnFirstSelection={true}
                                        retainEndDateOnFirstSelection={true}
                                        months={2}
                                        onShownDateChange={(date)=> {setCurrentMonth(new Date(date).getMonth())}}
                                        // scroll={{enabled: true,calendarWidth:}}
                                        direction="horizontal"
                                    />
                                    <DateRange
                                        className="si-date-range-picker si-mobilcalendareview"
                                        ranges={range}
                                        onChange={changeHandler}
                                        minDate={addDays(new Date(), 1)}
                                        editableDateInputs={false}
                                        dragSelectionEnabled={false}
                                        moveRangeOnFirstSelection={true}
                                        retainEndDateOnFirstSelection={true}
                                        months={1}
                                        onShownDateChange={(date)=> {setCurrentMonth(new Date(date).getMonth())}}
                                        // scroll={{enabled: true,calendarWidth:}}
                                        direction="horizontal"
                                    />
                            
                                <div className="si-form-radio d-none">
                                    <div className="form-control">
                                    <input type="radio" id="test1" name="radio-group" checked=""/>
                                        <label htmlFor="test1">Every Weekday(Monday to Friday)</label>
                                    </div>
                                    <div className="form-control">
                                    <input type="radio" id="test2" name="radio-group"/>
                                        <label htmlFor="test2">Daily</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="si-popup-footer">
                    <div className="si-footer-warning"></div>
                    <div className="si-action-buttons">
                        <Button btnName='Save' onClickAction={saveAction}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
