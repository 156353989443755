import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SNC_COACH_ROLE_ID, TECHNICAL_COACH_ROLE_ID } from '../../../utility/constants';

export default function NoCurrentProgram() {
    const navigate = useNavigate();
    const userData = useSelector(state => state?.auth?.userData); 

    const createBtnClickHandler = ()=> {
        if(userData?.role_id == SNC_COACH_ROLE_ID){
            navigate("/snc/programs/templates");
        }else if( userData?.role_id == TECHNICAL_COACH_ROLE_ID){
            navigate("/technical/programs/templates");
        }
    };
    return (
        <div class="si-coach-nodata-container">
            <div class="nodata-img">
                <img src="../../images/coach-nodata-img.svg" alt="nodat" />
            </div>
            <p class="nodata-para">Go to programs to create and assign a program to athletes</p>
            <div class="si-btn-wrp">
                <button class="si-button si-button--green si-icon--add" onClick={createBtnClickHandler}>
                    <span class="icon-add"></span>Create Program
                </button>
            </div>
        </div>
    )
}
