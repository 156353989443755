import React, { useState} from 'react';
import { MultiSelect } from "react-multi-select-component";

function MultiSelectDropdown({ className="",placeholder="", options=[], changeHandler, dropdownfor ,alreadySelectedValues=[] }) {
    const [selectedValues, setSelectedValues] = useState([]);
    const customValueRenderer = (selected, options) => {
        return selected?.length === options?.length
          ? (dropdownfor==="sports" ? "All Sports" : `All ${capitalizeFirstLetter(dropdownfor)}`)
          :  selected?.length > 1
          ? `${selected?.length} Selected`
          : selected?.length 
          ? selected[0]?.label
          : (dropdownfor==="sports" ? "Select Sports" : `Select ${capitalizeFirstLetter(dropdownfor)}`);
    };

    function capitalizeFirstLetter(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const onChangeHandler = (data) => {
        setSelectedValues([...data]);
        changeHandler(data);
    };
    
    return (
        <MultiSelect 
            value={ selectedValues.length ? selectedValues : alreadySelectedValues.length ? alreadySelectedValues : [] }
            options={ options }
            onChange={ onChangeHandler }
            valueRenderer={ customValueRenderer }
            labelledBy={placeholder}
            className={`custom-multi-select ${className}`}
        />
    );
};

export default MultiSelectDropdown;