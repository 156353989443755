import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {  useParams } from "react-router";
import { setPerformanceUpdateEventSuccessfulFalse } from "../../../../reducer/tournamentsPerformance.reducer";
import { addTournamentPerformanceEvents, getEventListsPerformance } from "../../../../utility/tournamentPerformanceApiAction";
import PopupFooter from "../../Common/PopupFooter";
import SubHeader from "../../Common/SubHeader";
import EventPopupContent from "./EventPopupContent";

function AddEventPopup({ closePopup, selectedSports,changeSelectedEvent }) {
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedGenderIds, setSelectedGenderIds] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [eventIds, setEventIds] = useState([]);
  const genderLists = useSelector((state) => state?.tournamentPerformance?.allGenderList);
  const userData = useSelector((state) => state?.auth?.userData);
  const eventListing = useSelector((state) => state?.tournamentPerformance?.eventsList);
  const paginationObject = useSelector((state) => state?.tournamentPerformance?.paginationObject);
  const mappedEvents = useSelector((state) => state?.tournamentPerformance?.mappedEvents);
  const loading = useSelector((state) => state?.tournamentPerformance?.loading);
  const updated = useSelector((state) => state?.tournamentPerformance?.updateEventSuccessful);
  let dispatch = useDispatch();
  const { tournamentId, performanceId } = useParams();
  
  useEffect(() => {
    dispatch(
      getEventListsPerformance({
        user_id: userData?.user_id ? userData.user_id : null,
        sport_id: [selectedSports.value],
      })
    );
  }, [dispatch, selectedSports, userData]);
  useEffect(()=>{
    if(updated){
      closePopup(false)
      changeSelectedEvent({ event: mappedEvents[0] });
      dispatch(setPerformanceUpdateEventSuccessfulFalse())
    }
  },[closePopup, dispatch, updated])
  
  useEffect(() => {
    dispatch(
      getEventListsPerformance({
        page_no: pageNo ? pageNo : 1,
        user_id: userData?.user_id ? userData.user_id : null,
        sport_id: [selectedSports.value],
        gender: selectedGenderIds?.length ? selectedGenderIds : null,
        search: searchTerm ? searchTerm : null,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGenderIds, selectedSports, userData, dispatch, pageNo]);

  useEffect(() => {
    if(searchTerm !== null){
      let timeout = null;
      if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      dispatch(
        getEventListsPerformance({
          user_id: userData?.user_id ? userData.user_id : null,
          sport_id: [selectedSports.value],
          gender: selectedGenderIds?.length ? selectedGenderIds : null,
          searchText: searchTerm ? searchTerm : null,
        })
        );
      }, 1000);
      return () => clearTimeout(timeout);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    
  const saveEvent = (eventIds) => {
    let payload = {
      opt_type: 1,
      // tournament_id: Number(tournamentId),
      event_id: eventIds?.map((ids) => String(ids)),
      sport_id: selectedSports?.value,
      te_map_id: null,
      performance_record_id: Number(performanceId),
      user_guid: userData?.user_guid,
    };
    let getPayload = {
      performance_record_id:  Number(performanceId),
      sport_id: selectedSports?.value,
    }
    dispatch(addTournamentPerformanceEvents({ payload,getPayload }));
  };

  let filterOptions = {
    is_sport_filter: false,
    is_search_filter: true,
    is_tab: false,
    is_group_filter: false,
    is_gender_filter: true,
    is_event_type_filter: false,
    is_desableSport: true,
  };
  return (
    <>

      <div className={`si-custom-popup si-popup active`}>
      {loading && <div className="spin"></div>}
        <div className="si-popup-content si-Athletes">
          <div className="si-popup-head">
            <div className="si-popup__title">
              <div className="form-control">
                <input type="text" value="Events List" className="si-input" placeholder="Events List" disabled={true} />
              </div>
              <button type="button" className="si-icon__button si-button__close" onClick={() => closePopup(false)}>
                <span className="icon-close"></span>
              </button>
            </div>
            <SubHeader
              filterOptions={filterOptions}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              selectedSports={selectedSports}
              // setSelectedSportIds={ setSelectedSports }
              genderList={genderLists}
              setSelectedGenderIds={setSelectedGenderIds}
              // selectedGroupsIds={ selectedGroups }
              // setSelectedGroupIds={ setSelectedGroups }
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          </div>

          {/* <div className="si-popup-body"> */}
          <EventPopupContent
            pageNo={pageNo}
            setPageNo={setPageNo}
            pagination={paginationObject}
            perPage={20}
            eventListing={eventListing}
            eventIds={eventIds}
            setEventIds={setEventIds}
            mappedEvents={mappedEvents}
            userData={userData}
          />
          <PopupFooter
            isActionBtn={true}
            btnClass={`si-button ${eventIds?.length ? "si-button--green" : "si-button--green"}`}
            isBtnDisabled={!eventIds?.length ? true : false}
            actionBtnText={"Add"}
            actionBtnHandler={() => saveEvent(eventIds)}
          />
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default AddEventPopup;
