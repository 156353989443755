import React from "react";

function NutritionProfileTabs({ tabListOptions, ATHLETE_PROFILE_VIEW, VIEWMODEONLY, activeTab, setActiveTab }) {
  return (
    <div className="si-tab-list">
      {Object.keys(tabListOptions).map((key) => {
        if(VIEWMODEONLY && (key === "internalNotes" || (ATHLETE_PROFILE_VIEW && key === "groupNutritionPlan"))){
          return null
        } 
        return (
          <div key={key} className={`si-tab-item ${activeTab === key ? "active" : ""}`} onClick={()=>setActiveTab(key)}>
            <span>{tabListOptions[key].name}</span>
          </div>
        );
      })}
    </div>
  );
}

export default NutritionProfileTabs;
