import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loaderActions } from '../../../../reducer/loader';
import Dropdown from '../../Common/Dropdown';
import { fetchTechnicalWeekWiseProgressCalendarData } from '../../../../utility/technicalProgramApiActions';
import moment from 'moment';

function CalenderDropdown({weeks, defaultSelectedWeek, selectedProgram}) {
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [preSelectedValue, setPreSelectedValue] = useState(null)
    const dispatch = useDispatch();
    const {id, userId} = useParams();
    const selectOptions = Array(Number(weeks))?.fill("")?.map((val,idx) => {
        return {
            label: `Week ${idx + 1}`,
            value: idx + 1
        };
    });

    useEffect(() => {
        if(weeks) setSelectedWeek(selectOptions?.find(obj => obj?.value==defaultSelectedWeek));
    }, [weeks, defaultSelectedWeek]);

    
    useEffect(() => {
        if (selectOptions?.length && defaultSelectedWeek && selectedProgram?.program_id) {
          if (
            selectedProgram?.start_date &&
            moment(selectedProgram?.start_date) <= moment(new Date()) &&
            selectedProgram?.end_date &&
            moment(selectedProgram?.end_date) >= moment(new Date())
          ) {
            let preSelected = selectOptions.filter((option) => option.value === defaultSelectedWeek);
            if (preSelected.length) {
              setPreSelectedValue(preSelected[0]);
            }
          }
        }
        return () => {
          setPreSelectedValue(null);
        };
      }, [defaultSelectedWeek, selectOptions?.length, selectedProgram]);

    const changeHandler = (data) => {
        setSelectedWeek({...data});
        dispatch(loaderActions?.showLoader());
        dispatch(
            fetchTechnicalWeekWiseProgressCalendarData({
                id,
                week: data?.value,
                userId,
                callback: () => setTimeout(() => {
                    dispatch(loaderActions?.hideLoader());
                })
            })
        );
    };
    
    return (
        <div className="si-subHeader">
            <div className="form-control si-calender-week">
                <Dropdown 
                    value={selectedWeek ? selectedWeek : selectOptions?.find(obj => obj?.value==defaultSelectedWeek)}
                    options={selectOptions}
                    changeHandler={changeHandler}
                    preSelectedValue={preSelectedValue}

                />
            </div>
        </div>
    )
};

export default CalenderDropdown;