import React, { Fragment, useEffect, useState } from "react";
import { REMARK_ID } from "../../../../utility/constants";

export default function Test({ testDetails, headers, newReport, setNewReport }) {
  const [remark, setRemark] = useState('');
  useEffect(() => {
    if (headers) {
      let data = headers.filter((h) => h.is_grouped);
      if (data && data.length) {
        setRemark(data);
      }
    }
  }, [headers]);
  return (
    <Fragment>
      <div className="si-head">{testDetails?.test_name}</div>
      <div className="si-tblwrp">
        <div className="si-tableleft">
          {testDetails?.tests_parameter.map((param) => {
            // console.log(param);
            return <div className="si-tbls__row" key={param.parameter_id}>
              {headers?.map((header) => {
                // if (header.id != REMARK_ID) {
              if (!header.is_grouped){
                if (header.type == 'label') {
                return <div className="si-tbls__cell" key={header.id}>
                      <span>{param?.parameter_name} ({param?.units})</span>
                    </div>
                  } 
                  
                else if (header.type == 'input') {
                    return <div class="si-tbls__cell si-29">
                              <span class="si-vscore">{param[header.key] ? param[header.key] : '-'}</span>
                            </div>
                  }

                else if (header.type == 'textarea') {
                    return <div className="si-tbls__cell" key={header.id}>
                      <span class="si-interpretation">{param[header.key] ? param[header.key] : '-'}</span>
                              {/* <span class="si-viewtext">{param[header.key]} </span> */}
                            </div>
                  }

                else if (header.type == 'span') {
                    return <div className="si-tbls__cell" key={header.id}>
                      <span>{param[header.key] ? param[header.key] : 0}</span>
                    </div>
                  }
                }
              })}
            </div>
          })}
        </div>
        {remark &&
          <div className="si-tableright">
            {remark.map((r)=>{
              return <div className="si-tbls__cell">
              <span class="si-viewtext">{testDetails?.remarks ? testDetails?.remarks : '-'} </span>
              </div>
            })} 
          </div>
        }
      </div>     
    </Fragment>
  );
}
