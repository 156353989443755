import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchWeekWiseProgramCalendarData } from "../../../../utility/programApiActions";
import Dropdown from "../../Common/Dropdown";
import CurrentProgramPdfButton from "../../Common/GeneratePdf/CurrentProgramPdfButton";

function CalenderSubheader({ weeks, defaultSelectedWeek, startDate, isActive, onStartDateClick, showExportPdfBtn }) {
    const [selectedWeek, setSelectedWeek] = useState(null);
    let programDetails = useSelector((state) => state.programs.wholeProgramCalendarData);
    let selectedProgramDetails = useSelector((state) => state.programs.selectedProgram);
    let unitList = useSelector((state) => state?.templates?.unitsList);
    let userData = useSelector((state) => state.auth.userData);
    const [preSelectedValue, setPreSelectedValue] = useState(null);
    const dispatch = useDispatch();
    const { id } = useParams();
    const selectOptions = Array(Number(weeks))
        ?.fill("")
        ?.map((val, idx) => {
            return {
                label: `Week ${idx + 1}`,
                value: idx + 1,
            };
        });
    useEffect(() => {
        if (selectOptions?.length && defaultSelectedWeek && selectedProgramDetails?.program_id) {
            if (
                startDate &&
                moment(startDate) <= moment(new Date()) &&
                selectedProgramDetails?.end_date &&
                moment(selectedProgramDetails?.end_date) >= moment(new Date())
            ) {
                let preSelected = selectOptions.filter((option) => option.value === defaultSelectedWeek);
                if (preSelected.length) {
                    setPreSelectedValue(preSelected[0]);
                }
            }
        }
        return () => {
            setPreSelectedValue(null);
        };
    }, [defaultSelectedWeek, selectOptions?.length, selectedProgramDetails]);

    useEffect(() => {
        if (weeks) setSelectedWeek(selectOptions?.find((obj) => obj?.value == defaultSelectedWeek));
    }, [weeks, defaultSelectedWeek]);

    const changeHandler = (data) => {
        setSelectedWeek({ ...data });
        dispatch(
            fetchWeekWiseProgramCalendarData({
                id,
                week: data?.value,
            })
        );
    };

    return (
        <div className="si-subHeader">
            <div className="form-control si-calender-week">
                <Dropdown
                    value={
                        selectedWeek ? selectedWeek : selectOptions?.find((obj) => obj?.value == defaultSelectedWeek)
                    }
                    options={selectOptions}
                    changeHandler={changeHandler}
                    preSelectedValue={preSelectedValue}
                />
            </div>
            <div className="form-control si-start-date" disabled={isActive}>
                {showExportPdfBtn && (isActive || startDate) ? (
                    <CurrentProgramPdfButton
                        programDetails={programDetails}
                        startDate={startDate}
                        unitList={unitList}
                        userData={userData}
                        selectedProgramDetails={selectedProgramDetails}
                    />
                ) : null}
                <label htmlFor="" className="si-label">
                    start date
                </label>
                <div className="form-control-group" onClick={() => onStartDateClick()}>
                    <span className="si-icon icon-calender"></span>
                    <span className="date-text">
                        {startDate ? moment(startDate).format("DD/MM/yyyy") : "dd/mm/yyyy"}
                    </span>
                    {/* <input 
                        type="text" 
                        className="si-input" 
                        value={startDate ? moment(startDate).format("DD/MM/yyyy") : "dd/mm/yyyy"} 
                        disabled
                    /> */}
                </div>
            </div>
        </div>
    );
}

export default CalenderSubheader;
