import React from 'react'
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Group from '../components/RightPannel/UserManagement/AthleteGroups/Group/Group';
import CreateGroup from '../components/RightPannel/UserManagement/AthleteGroups/GroupListing/CreateGroup';
import GroupListing from '../components/RightPannel/UserManagement/AthleteGroups/GroupListing/GroupListing';
import ProtectedRoute from './ProtectedRoute';
import ViewReport from '../components/RightPannel/Test&Assesment/ViewReport/ViewReport';

export default function UserManagementRouting() {
      const userData = useSelector(state => state?.auth?.userData);
  return (
    <Routes>
        <Route 
                exact 
                path="/athletegroups" 
                element={ 
                    <ProtectedRoute Component={GroupListing}/>
                }
            />
            <Route 
                exact 
                path="/athletegroups/new" 
                element={ 
                    <ProtectedRoute Component={CreateGroup}/>
                }
            />
            <Route 
                exact 
                path="/athletegroups/:id" 
                element={ 
                    <ProtectedRoute Component={Group}/>
                }
            />
            <Route 
                exact 
                path="/athleteprofile/:id/report/:reportid" 
                element={ 
                    <ProtectedRoute 
                        Component={ViewReport} 
                    />
                }
            />
    </Routes>
  )
}
