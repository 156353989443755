import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUnitsList } from "../../../../utility/templateApiActions";
import { Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { addDays, addWeeks, format } from "date-fns";

function CalenderExercies({
  day,
  week,
  exercies,
  tempoList,
  unitsList,
  editCallback,
  deleteCallback,
  IS_PROGRAM_COMPLETED,
  startDate
}) {
  const [isActionActive, setIsActionActive] = useState(
    Array(exercies?.length)?.fill(false)
  );
  // const unitsList = useSelector(state => state?.templates?.unitsList);
  const dispatch = useDispatch();

  useEffect(() => {
    window?.addEventListener("click", handleKeydownEvent);
    // if(!unitsList?.length) dispatch(fetchUnitsList());
    return () => window?.removeEventListener("click", handleKeydownEvent);
  }, []);

  const handleKeydownEvent = (event) => {
    setIsActionActive(Array(exercies?.length)?.fill(false));
  };

  const handleAction = (e, idx) => {
    e?.stopPropagation();
    setIsActionActive((prevState) => {
      let newState = Array(exercies?.length)?.fill(false);
      newState[idx] = !prevState[idx];
      return [...newState];
    });
  };


  const isAddExercise = (day, week) => {
    if(startDate && day && week) {
      let addDay = addDays(new Date(startDate), day - 1);
      let addWeek = addWeeks(addDay, week - 1);
        return format(addWeek, "yyyy-MM-dd") > format(new Date(), "yyyy-MM-dd");
    };
    return true;
};

  return (
    <Fragment>
      {exercies?.map((obj, idx) => {
        let tempo = tempoList?.find((tempo) => tempo?.tempo_id == obj?.tempo);

        return (
          <Draggable
            draggableId={obj?.id?.toString()}
            index={idx}
            key={uuidv4()}
          >
            {(provided, { isDragging }) => (
              <div
                className={`si-card__box ${
                  isDragging ? "si-card-dragging" : ""
                }`}
                {...provided?.draggableProps}
                {...provided?.dragHandleProps}
                ref={provided?.innerRef}
              >
                <div className="si-card__container">
                  <div className="si-Left">
                    <div
                      className="si-exeriseName"
                      onClick={() =>
                        editCallback(obj?.id, week, day, obj?.exercise_id)
                      }
                    >
                      <span>{obj?.exercise_name}</span>
                    </div>
                    <div className="si-exerise">
                      <div className="text-wrap">
                        {obj?.sets && <span>{`${obj?.sets} sets `}</span>}
                        {obj?.metrics_units
                          ?.filter(
                            (metric) => metric?.is_enabled && metric?.value
                          )
                          ?.map((obj) => {
                            let metricObj = unitsList?.filter?.(
                              (data) => data?.metric_id == obj?.metric_id
                            )[0];
                            let unitObj = metricObj?.unit?.filter?.(
                              (data) => data?.unit_id == obj?.unit_id
                            )[0];
                            return (
                              <span
                                key={uuidv4()}
                              >{`${obj?.value} ${unitObj?.unit}`}</span>
                            );
                          })}
                      </div>
                    </div>
                    <div className="si-Controlled">
                      <span>
                        {tempo ? tempo?.name : ""}
                      </span>
                    </div>
                  </div>
                  {IS_PROGRAM_COMPLETED || !isAddExercise(day,week) ? null :
                  <div
                    className={`si-Right ${
                      isActionActive[idx] ? "active" : ""
                    }`}
                    onClick={(e) => handleAction(e, idx)}
                  >
                    <button
                      type="button"
                      className="si-icon__button icon-options"
                    ></button>
                    <div className="si-action">
                      <button
                        type="button"
                        className="si-icon-button"
                        onClick={() =>
                          editCallback(obj?.id, week, day, obj?.exercise_id)
                        }
                      >
                        <span className="icon-edit"></span>
                        Edit
                      </button>
                      <button
                        type="button"
                        className="si-icon-button"
                        onClick={() => deleteCallback(obj?.id, week, day)}
                      >
                        <span className="icon-delete"></span>
                        Delete
                      </button>
                    </div>
                  </div>
                  }
                </div>
              </div>
            )}
          </Draggable>
        );
      })}
    </Fragment>
  );
}

export default CalenderExercies;
