import moment from 'moment'
import React, { Fragment } from 'react'

export default function UpcomingCompetition({competitons}) {
  return (
    <Fragment>
        {competitons?.length ? <div className="si-upcoming-heading">Upcoming Competition</div> : ''}
        {competitons?.map((comp)=>{
            let competitionDay = comp.date ? moment(comp?.date).format('Do') : '';
            let competitionDate = comp.date ? moment(comp?.date).format('MMM YY') : '';
            return <div className="si-upcoming-wrapper" key={comp?.competition_id}>
            <div className="si-date-wrap">
                <div className="si-upcoming-date">
                    <span className="specific-date">{competitionDay}</span>{competitionDate}</div>
                <div className="si-upcoming-daysleft">{comp?.days_left} {comp?.days_left === 1 ? "Day": "Days"} left</div>
            </div>
            <div className="si-status-wrap">
                <p className="si-upcoming-status">{comp?.competition_name}</p>
            </div>
        </div>
        })}
            
    </Fragment>
  )
}
