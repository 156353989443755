import React, {Fragment, useState, useEffect, useMemo} from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loaderActions } from '../../../../reducer/loader';
import { toast } from 'react-toastify';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import CalendarTabs from './CalendarTabs';
import CalenderContent from './CalendarContent';
import CalendarSubheader from './CalendarSubheader';
import { technicalTemplatesActions } from '../../../../reducer/technicalTemplates';
import FixedButton from '../../Common/FixedButton';
import StartDatePopup from '../../Common/StartDate/StartDatePopup';
import moment from 'moment';
import { isAfter, differenceInDays } from 'date-fns';
import { fetchSportsList, publishTechnicalCalendarSequence } from '../../../../utility/technicalTemplateApiActions';
import { fetchTechnicalWeekWiseProgramCalendarData, updateTechnicalProgramDetails,fetchTechnicalProgramDetailsById, fetchTechnicalWholeProgramCalendarData } from '../../../../utility/technicalProgramApiActions';

function ProgramCalender() {
  let { id } = useParams();
  const {state} = useLocation();
  const {backUrl} = state ? state : '';
  const navigate = useNavigate();
  const location = useLocation()
  const IS_PROGRAM_COMPLETED = location?.pathname?.split("/")[2] === "completedprograms" ? true : false
  const [searchParams, setSearchParams] = useSearchParams();
  const isPerformance = searchParams?.get("performance") === "true" ? true : false;
  const selectedWeek = useSelector(state => state?.technicalPrograms?.selectedWeek);
  const isAuth = useSelector(state=> state.auth.isAuthenticated);
  const programNameErrorMsg = useSelector(state => state?.technicalTemplates?.programNameErrorMsg);
  const programNameError = useSelector(state => state?.technicalTemplates?.programNameError);
  const wholeCalendarData = useSelector(state => state?.technicalPrograms?.wholeProgramCalendarData);
  const selectedProgram = useSelector(state => state?.technicalPrograms?.selectedProgram);
  const sportList = useSelector(state => state?.technicalTemplates?.sportList);
  const isUpdateDisabled = useSelector(state => state?.technicalTemplates?.isUpdateDisabled);
  const { setIsUpdateDisabled } = technicalTemplatesActions;
  const [headerData, setHeaderData] = useState({
      headerClass:"si-margin si-calheader",
      heading: selectedProgram?.program_name,
      isHeadingEditable: false,
      isbackBtn: true,
      backBtnAction: () => navigate(backUrl ? backUrl :"/technical/programs"),
      isCalendar: true,
      isCountEditable: false,
      dataCountHeading: selectedProgram?.program_duration > 1 ? "Weeks" : "Week",
      isDropdownEditable: false
  });
  const [confirmationPopup, setConfirmationPopup] = useState({isShow: false});
  const [activeTab, setActiveTab] = useState(isPerformance ? "athletes" : "schedule");
  const [startDatePopup, setStartDatePopup] = useState(false);
  const dispatch = useDispatch();
  // console.log("selectedProgram---------->",selectedProgram, id);

  useEffect(() => {
    if(isAuth){
      dispatch(loaderActions?.showLoader());
      dispatch(
        fetchTechnicalProgramDetailsById({
          id, 
          type:2,
          callback: (data) => setTimeout(() => {
            if(data) {
              let week = getDefaultSelectedWeek(data?.start_date, data?.program_duration);
              dispatch(
                fetchTechnicalWeekWiseProgramCalendarData({
                  id,
                  week,
                  callback: () => setTimeout(() => {
                    dispatch(
                      fetchTechnicalWholeProgramCalendarData({id})
                    );
                    dispatch(loaderActions?.hideLoader());
                  })
                })
              );
            }
            else {
              toast.warn("No program data.");
              navigate("/technical/programs");
            };
          })
        })
      );
      if(!sportList?.length) dispatch(fetchSportsList());
    };
  }, [isAuth]);

  useEffect(()=>{
    if(programNameError){
      dispatch(loaderActions?.hideLoader());
      toast.warn(programNameErrorMsg);
    }
  },[programNameError,programNameErrorMsg]);

  useEffect(() => {
    setHeaderData({
      ...headerData,
      heading: selectedProgram?.program_name,
      dataCountHeading: selectedProgram?.program_duration > 1 ? "Weeks" : "Week",
    });
  }, [selectedProgram]);

  
  const getDefaultSelectedWeek = (date, duration) => {
    let week = 1;
    let startDate = date ? new Date(date) : null;
    let currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    if(startDate && currentDate>=startDate) {
      let diff = differenceInDays(currentDate, startDate);
      let newWeek = parseInt(diff/7) + 1;
      if(diff > 6 && newWeek<=duration) week = newWeek;
    };
    return week;
  };

  const isPublish = useMemo(() => () => {
    let isExercise = false;
    for(let i=0; i<wholeCalendarData?.length; i++) {
      for(let j=0; j<wholeCalendarData[i]?.days?.length; j++) {
        if(
          wholeCalendarData[i]?.days[j]?.exercises?.length && 
          selectedProgram?.start_date && 
          selectedProgram?.athlete_id?.length
        ) {
          isExercise = true;
          break;
        };
      };
      if(isExercise) break;
    };

    return isExercise;
  }, [wholeCalendarData, selectedProgram]);

  const sportOptions = sportList?.map(obj => {
    return {
        value: obj?.sport_id,
        label: obj?.sport_name
    };
  });

  const actionBtn1Handler = () => navigate("/technical/programs");
  const actionBtn2Handler = () => {
    // if(weekWiseCalendarData?.filter(obj => obj?.exercises)?.length){
    if(isPublish()){
      setConfirmationPopup({
        isShow: true,
        isActionBtns: true,
        warning: "Are you sure you want to publish program?",
        warningMsg: "",
        cancelHandler: () => setConfirmationPopup({isShow: false}),
        okHandler: () => {
          setConfirmationPopup({isShow: false});
          dispatch(
            publishTechnicalCalendarSequence({
              id, 
              type: 2,
              callback: () => setTimeout(() => {
                dispatch(
                  updateTechnicalProgramDetails({
                    programId: id,
                    name: selectedProgram?.program_name,
                    duration: selectedProgram?.program_duration,
                    sportId: selectedProgram?.sport_id,
                    startDate: selectedProgram?.start_date,
                    endDate: selectedProgram?.end_date,
                    assignedAthletes: selectedProgram?.athlete_id,
                    isPublish: 1
                  })
                )
              })
            })
          );
          toast.success(`Program ${selectedProgram?.is_publish ? "updated" : "published"} successfully`);
          navigate("/technical/programs");
        }
      })
    } 
    else {
      toast.warning("Please add an exercise to publish the program.")
    }
  };

  const editAction = (e) => {
    e?.preventDefault();
    setHeaderData({
      ...headerData, 
      isHeadingEditable: true,
      isCountEditable: true,
      isDropdownEditable: false
    });
  };

  const saveAction = (e, programName, duration, selectedSport) => {
    let durationDiff = duration - selectedProgram.program_duration
    let newEndDate = selectedProgram?.end_date ? moment(selectedProgram?.end_date).add(durationDiff,'w').format("YYYY-MM-DD") : null
    e?.preventDefault();
    if(programName && duration && selectedSport?.value) {
      setConfirmationPopup({
        isShow: true,
        isActionBtns: true,
        warning: "Are you sure you want to save the data?",
        warningMsg: "",
        cancelHandler: () => setConfirmationPopup({isShow: false}),
        okHandler: () => {
          dispatch(loaderActions?.showLoader());
          setConfirmationPopup({isShow: false});
          dispatch(updateTechnicalProgramDetails({
            programId:id,
            name: programName,
            duration,
            sportId: selectedSport?.value,
            startDate:selectedProgram?.start_date,
            endDate:newEndDate,
            assignedAthletes:selectedProgram?.athlete_id,
            isPublish: selectedProgram?.is_publish,
            callback: (data) => setTimeout(() => {
              dispatch(fetchTechnicalWeekWiseProgramCalendarData({
                  id,
                  week: selectedWeek ? selectedWeek : "1",
                  callback: () => setTimeout(() => {
                    dispatch(loaderActions?.hideLoader());
                    if(data) toast.success("Program updated successfully.", {
                      toastId: "program-calendar-details-update-success"
                  });
                  })
              })
              );
              setHeaderData({
                ...headerData, 
                heading: data?.program_name,
                isHeadingEditable: false,
                isCountEditable: false,
                isDropdownEditable: false
              });
              dispatch(setIsUpdateDisabled(false));
            })
          }));
        }
      });
    } else {
      console.log("Post Program Error");
    };
  };

  const cancelAction = () => {
    setConfirmationPopup({
      isShow: true,
      isActionBtns: true,
      warning: "Are you sure you want to discard your changes?",
      warningMsg: "",
      cancelHandler: () => setConfirmationPopup({isShow: false}),
      okHandler: () => {
        setConfirmationPopup({isShow: false});
        setHeaderData({
          headerClass:"si-margin si-calheader",
          heading: selectedProgram?.program_name,
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => navigate("/technical/programs"),
          isCalendar: true,
          isCountEditable: false,
          dataCountHeading: selectedProgram?.program_duration > 1 ? "Weeks" : "Week",
          isDropdownEditable: false
        });
      }
    });
  };

  const saveStartDateHandler = (data)=>{
    console.log("saveStartDateHandler: ",data);
    if(data.start_date && data.end_date){
      dispatch(loaderActions?.showLoader());
          dispatch(updateTechnicalProgramDetails({
            programId:id,
            name: selectedProgram?.program_name,
            duration:selectedProgram?.program_duration,
            sportId: selectedProgram?.sport_id,
            startDate: data.start_date,
            endDate:data. end_date,
            assignedAthletes: selectedProgram?.athlete_id,
            isPublish: selectedProgram?.is_publish,
            callback: (data) => setTimeout(() => {
              setHeaderData({
                ...headerData, 
                isHeadingEditable: false,
                isCountEditable: false,
                isDropdownEditable: false
              });
              dispatch(loaderActions?.hideLoader());
              dispatch(setIsUpdateDisabled(false));
              if(data) toast.success("Program start date added successfully.", {
                          toastId: "program-calendar-details-update-success"
                      });
            })
          }));
          setStartDatePopup(false);
    }
  };

  const isActiveProgram = (date) => {
    if(date) {
      let startDate = moment(date).format("M/DD/yyyy");
      let today = moment().format("M/DD/yyyy");
      return !moment(startDate).isAfter(today);
    };
    return false;
  };

  const isCompletedProgram = (date) => {
    if(date) {
      let endDate = moment(date).format("M/DD/yyyy");
      let today = moment().format("M/DD/yyyy");
      return moment(endDate).isBefore(today);
    };
    return false;
  };

  const starDateClickHandler = ()=>{
    if(selectedProgram?.start_date){
      let isActive = isActiveProgram(selectedProgram?.start_date);
      if(!isActive) {
        setStartDatePopup(true);
      } else {
        setConfirmationPopup({
          isShow: true,
          isActionBtn: true,
          warning: IS_PROGRAM_COMPLETED ? "Program already completed you cannot change the start date":"Program already started you cannot change the start date.",
          okHandler: () => {
            setConfirmationPopup({isShow: false});
          }
        });
      };
    } else {
      setStartDatePopup(true);
    };
  }
  // console.log(weekWiseCalendarData, wholeCalendarData, selectedProgram, isNewTemplate, "<====weekwiseData")
  // console.log(selectedProgram);

  return (
    <Fragment>
        <Header
            headerData={headerData}
            dataCount={selectedProgram?.program_duration ? selectedProgram?.program_duration : ""}
            dropdownPlaceHolder={"Select Sport"}
            dropdownValue={sportOptions?.find(obj => (obj?.label?.toLowerCase() === selectedProgram?.sport_name?.toLowerCase()) || obj?.value == selectedProgram?.sport_id)}
            dropdownOptions={sportOptions}
            editAction={editAction}
            saveAction={saveAction}
            cancelAction={cancelAction}
            isViewOnly={Boolean(IS_PROGRAM_COMPLETED || isActiveProgram(selectedProgram?.start_date))}
        />

        <div className="si-tabs">
            <CalendarTabs 
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            {
                activeTab==="schedule" && 
                <CalendarSubheader 
                    weeks={selectedProgram?.program_duration ? selectedProgram?.program_duration : ""} 
                    defaultSelectedWeek={getDefaultSelectedWeek(selectedProgram?.start_date, selectedProgram?.program_duration)}
                    isActive={isActiveProgram(selectedProgram?.start_date)}
                    startDate={selectedProgram?.start_date}
                    onStartDateClick = {starDateClickHandler}
                    showExportPdfBtn = {true}
                />
            }
            <CalenderContent 
                confirmationPopup={confirmationPopup} 
                setConfirmationPopup={setConfirmationPopup}
                headerData={headerData}
                setHeaderData={setHeaderData}
                activeTab={activeTab}
                startDate={selectedProgram?.start_date}
                isPerformance={isPerformance}
                setIsUpdateDisabled={setIsUpdateDisabled}
                IS_PROGRAM_COMPLETED={IS_PROGRAM_COMPLETED}
            />
        </div>

        {/* <Footer 
            isActionBtns={!isCompletedProgram(selectedProgram?.end_date)}
            btn1Class={"si-button si-button--transparent d-none"}
            actionBtn1Text={"Cancel"}
            actionBtn1Handler={actionBtn1Handler}
            btn2Class={"si-button si-button--green"}
            btn2Disabled= {selectedProgram?.is_publish ? isUpdateDisabled : !isPublish()}
            actionBtn2Text={!selectedProgram?.is_publish ? "publish" : "update"}
            actionBtn2Handler={actionBtn2Handler}
        /> */}
        {/* <FixedButton btnName="Publish"
                            onBtnClick={actionBtn2Handler}/> */}
        {startDatePopup && <StartDatePopup setStartDatePopup={setStartDatePopup} startDatePopup={startDatePopup} week={selectedProgram?.program_duration} startDate={selectedProgram?.start_date} saveStartDateHandler={saveStartDateHandler}/>}
    </Fragment>
  );
};

export default ProgramCalender;