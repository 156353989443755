import { createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "../reducer/auth";
import API from "./api";

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const fetchSportsList = createAsyncThunk(
  "EventsListing/fetchSportsList",
  async (data, { dispatch, rejectWithValue }) => {
    // dispatch(loaderActions?.showLoader());
    try {
      return await getAPIDetails("exercise/getsportslisting");
    } catch (error) {
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchGenderList = createAsyncThunk(
  "EventsListing/fetchGenderList",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      return await getAPIDetails("/common/getgendermaster");
    } catch (error) {
      console.log("rejected", error);
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const fetchEventType = createAsyncThunk("EventsListing/fetchEventType", async (data, { dispatch }) => {
  // dispatch(loaderActions?.showLoader());
  return await getAPIDetails("common/geteventtypemaster");
});

export const getEventLists = createAsyncThunk(
  "EventsListing/getEventLists",
  async (
    { page_no, searchText, sport_id, item_number, user_id, gender, event_type },
    { dispatch, rejectWithValue }
  ) => {
    let payloadToSend = {
      page_no: page_no ? page_no : 1,
      item_number: item_number ? item_number : 20,
      search: searchText ? searchText : null,
      user_id: user_id ? user_id : null,
      sport_id: sport_id ? sport_id : null,
      gender: gender ? gender : null,
      event_type: event_type ? event_type : null,
    };
    try {
      return await postAPIDetails("/common/geteventlisting", payloadToSend);
    } catch (error) {
      console.log("rejected", error);
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const updateEvents = createAsyncThunk(
  "EventsListing/updateEvents",
  async ({ id, sport_id, name, user_guid, gender, event_type }, { rejectWithValue, dispatch }) => {
    let payloadToSend = {
      id: id ? id : null,
      sport_id: sport_id ? sport_id : null,
      user_guid: user_guid ? user_guid : null,
      event_name: name ? name : null,
      gender: gender ? gender : null,
      event_type: event_type ? event_type : null,
    };
    try {
      return await postAPIDetails("/common/insertupdateevent", payloadToSend);
    } catch (error) {
      console.log("rejected", error);
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);

export const deleteEvents = createAsyncThunk(
  "EventsListing/deleteEvents",
  async ({ event_id, user_guid }, { dispatch, rejectWithValue }) => {
    let payloadToSend = {
      event_id: event_id ? event_id : null,
      userguid: user_guid ? user_guid : null,
    };
    try {
      return await postAPIDetails(`/common/deleteevent?event_id=${event_id}&userguid=${user_guid}`, payloadToSend);
    } catch (error) {
      console.log("rejected", error);
      let statusCode = error?.response?.status;
      if (statusCode === 401) {
        dispatch(authActions.logout());
        error.message = error?.response?.data?.title && error?.response?.data?.title;
        return rejectWithValue({ error });
      } else {
        return rejectWithValue({ error });
      }
    }
  }
);
