import { createAsyncThunk } from "@reduxjs/toolkit";
import { authActions } from "../reducer/auth";
import API from './api';

let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const createTechnicalProgramByTemplateId = createAsyncThunk("program/createTechnicalProgramByTemplateId", async (data, {getState, rejectWithValue, dispatch}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData ? userData?.user_guid : null;
    let {templateId, callback} = data;
    console.log(data);
    try{
        return {
            callback,
            apiResponse: await getAPIDetails(`program/gettechnicalcoachprogramtemplatedetail?p_template_id=${templateId}&user_guid=${userguid}`)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchTechnicalProgramsList = createAsyncThunk("program/fetchTechnicalProgramList", async (data, {getState,rejectWithValue, dispatch}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let { pageNo,activeTab, searchTerm, selectedSports, callback } = data;
    let body = {
        "page_no": pageNo,
        "item_number": 20,
        "type": activeTab ? activeTab : 1,/**0-all , 1- currently active ,2-upcoming, 3-completed */
        "search": searchTerm,
        "user_guid": userData ? userData?.user_guid : null,
        "sport_id": selectedSports?.length ? selectedSports : null
    };

    try {
        return {
            callback,
            activeTab,
            apiResponse: await postAPIDetails("program/gettechnicalprogramlisting", body)
        };  
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };

});

export const fetchTechnicalAthletesList = createAsyncThunk("program/fetchTechnicalAthletesList", async (data, {getState, rejectWithValue, dispatch}) => {
    let state = getState();
    let {pageNo, searchTerm, programId,userId, selectedSports, userguid ,groupFilter,callback} = data;
    let userData = state?.auth?.userData; 
    let body = {
        "page_no": pageNo,
        "item_number": 20,
        "search": searchTerm,
        "user_guid": userData ? userData?.user_guid : null, /**For all athlete irrespective of user guid */
        "program_id": programId,
        "sport_id": selectedSports?.length ? selectedSports : null,
        "group_filter": groupFilter?.length ? groupFilter : null
    };
    try {
        return {
            callback,
            apiResponse: await postAPIDetails("program/gettechnicalatheletlisting", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchGroupListing = createAsyncThunk("programs/fetchGroupListing", async (data, {rejectWithValue, dispatch}) => {
    try {
        return await getAPIDetails("program/getgrouplisting");
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({ error})
        } else {
            return rejectWithValue({ error});
        }
    };
});

export const fetchTechnicalProgramAthletesList = createAsyncThunk("program/fetchTechnicalProgramAthletesList", async (data, {getState, rejectWithValue, dispatch}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let {pageNo, searchTerm, programId, userId, selectedSports, userguid,groupFilter ,callback} = data;

    let body = {
        "page_no": pageNo,
        "item_number": 20,
        "search": searchTerm,
        "user_guid": userData ? userData?.user_guid : null, /**For all athlete irrespective of user guid */
        "program_id": programId,
        "sport_id": selectedSports?.length ? selectedSports : null,
        "group_filter": groupFilter?.length ? groupFilter : null
    };
    try {
        return {
            callback,
            apiResponse: await postAPIDetails("program/gettechnicalatheletlisting", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const deleteTechnicalAthleteFromProgram = createAsyncThunk("program/deleteTechnicalAthleteFromProgram", async (data, {getState, rejectWithValue, dispatch}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData ? userData?.user_guid : null;
    let {athleteId, programId, callback} = data;
    try {
        return {
            callback,
            apiResponse: await getAPIDetails(`program/deletetechnicalatheletefromprogram?p_athlete_id=${athleteId}&p_program_id=${programId}&p_user_guid=${userguid}`)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const deleteTechnicalProgram = createAsyncThunk("program/deleteTechnicalProgram", async ({id, callback}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let userguid = userData ? userData?.user_guid : null;
    try{
        return {
            callback,
            apiResponse: await getAPIDetails(`program/deletetechnicalprogram?p_program_id=${id}&user_guid=${userguid}`)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const updateTechnicalProgramDetails = createAsyncThunk("program/insertupdatetechnicalprogramdetails", async (data, {getState, rejectWithValue, dispatch}) => {
    let {programId,name, duration,startDate,endDate ,moduleId, sportId,assignedAthletes, callback, isPublish} = data;
    let state = getState();
    // name = optType === 1 ? state?.templates?.programName : name;
    // duration = optType === 1 ? state?.templates?.duration : duration;
    let userData = state?.auth?.userData;
    try {
        let body = {
            // "user_id": userData ? userData?.user_id : null,
            "user_guid": userData ? userData?.user_guid : null,
            "sport_id": sportId,
            "program_id": programId,
            "program_name": name.trim(),
            "start_date": startDate ? startDate : null,
            "end_date": endDate ? endDate : null,
            "program_duration": duration,
            "schedule_type_id": 0,
            "assigned_athlete_ids": assignedAthletes?.length ? assignedAthletes : null,
            "is_publish": isPublish
          };
          console.log(body);
        return {
            callback,
            apiResponse: await postAPIDetails("program/insertupdatetechnicalprogramdetails", body)
        }; 
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchTechnicalProgramDetailsById = createAsyncThunk("templates/fetchTechnicalProgramDetails", async ({id,type ,callback}, {getState,dispatch, rejectWithValue}) => {
    // dispatch(loaderActions?.showLoader());
    let state = getState();
    console.log(state);
    let userData = state?.auth?.userData;
    let userguid = userData ? userData?.user_guid : null;
    try{
        return {
            callback,
            apiResponse: await getAPIDetails(`template/gettechnicaltemplatedetails?templateid=${id}&opttype=${type}&user_guid=${userguid}`)
        };
    }catch (error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchTechnicalWeekWiseProgramCalendarData = createAsyncThunk("programs/fetchTechnicalWeekWiseProgramCalendarData", async ({id, week, callback}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            week,
            callback,
            apiResponse: await getAPIDetails(`program/gettechnicalcoachprogramcalendardetails?p_program_id=${id}&p_week_id=${week}&user_guid=${userData?.user_guid}`)
        };
    } catch (error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const fetchTechnicalWholeProgramCalendarData = createAsyncThunk("programs/fetchTechnicalWholeProgramCalendarData", async ({callback, id}, {getState, dispatch, rejectWithValue}) => {
    let state = getState();
    let userData = state?.auth?.userData;

    try{
        if(state?.technicalPrograms?.selectedProgram) {
            return {
                callback,
                apiResponse: await getAPIDetails(`program/gettechnicalcoachprogramcalendardetails?p_program_id=${id}&user_guid=${userData?.user_guid}`)
            };
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchTechnicalAthletePrograms = createAsyncThunk("programs/fetchTechnicalAthletePrograms", async (data, {getState, rejectWithValue, dispatch}) => {
    let state = getState();
    let userData = state?.auth?.userData;
    let { pageNo, searchTerm, activeTab, userguid, callback } = data;
    try {
        let body = {
            "page_no": pageNo,
            // "user_id": userData ? userData?.user_id : null,
            "user_guid": userguid ? userguid : null,
            "item_number": 20,
            "search": searchTerm,
            "type": activeTab ? activeTab : 0
        };
        return {
            callback,
            apiResponse: await postAPIDetails("program/gettechnicalatheletprogramprogresslisting", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchTechnicalWeekWiseProgressCalendarData = createAsyncThunk("programs/fetchTechnicalWeekwiseProgressCalendarData", async (data, {getState,rejectWithValue, dispatch}) => {
    let {week, id, userId, callback} = data;
    let state = getState();
    let userData = state?.auth?.userData;
    try {
        let body = {
            "week_no": week,
            "user_guid": userData ? userData?.user_guid : null,
            "user_id": userId ? userId : null,
            "program_id": id
        };

        return {
            week,
            callback,
            apiResponse: await postAPIDetails("program/gettechnicalatheletprogramscheduledetail", body)
        };
    }
    catch (error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const insertUpdateTechnicalAthleteNotes = createAsyncThunk("programs/inserUpdateTechnicalAthleteNotes", async (data, {getState,rejectWithValue, dispatch}) => {
    let { optType, week, day, notes, noteId, reviewId, programId, userId, callback } = data;
    let state = getState();
    let userData = state?.auth?.userData;
    try {
        let body = {
            "athlete_notes": {
                "opt_type": optType,
                "user_guid": userData ? userData?.user_guid : null,
                "week_no": week,
                "day_no": day,
                "note_id": noteId ? noteId : null,
                "notes": notes,
                "review_id": reviewId ? reviewId : null,
                "program_id": programId
            }
        };
        return {
            callback,
            apiResponse: await postAPIDetails("program/insertupdatetechnicalathleteprogressnotes", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const fetchTechnicalAthleteNotes = createAsyncThunk("programs/fetchTechnicalAthleteNotes", async (data, {getState,rejectWithValue, dispatch}) => {
    let { userId, programId, week, day, callback } = data;
    let state = getState();
    let userData = state?.auth?.userData;
    try {
        let body = {
            "program_id": programId,
            "user_guid": userData ? userData?.user_guid : null,
            "user_id": userId ? userId : null,
            "week_no": week,
            "day_no": day
        };
        return {
            callback,
            apiResponse: await postAPIDetails("program/gettechnicalatheletprogressnotes", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});

export const insertUpdateTechnicalAthleteProgress = createAsyncThunk("programs/insertUpdateTechnicalAthleteProgress", async (data, {getState,rejectWithValue, dispatch}) => {
    let {optType, programId, userId, scheduleId, progressValue, callback} = data;
    let state = getState();
    let userData = state?.auth?.userData;
    try {
        let body = {
            "athlete_progress": {
              "opt_type": optType,
              "program_id": programId,
              "user_guid": userData ? userData?.user_guid : null,
              "program_scheduleid": scheduleId,
              "progress_value": progressValue
            }
        };
        return {
            callback,
            apiResponse: await postAPIDetails("program/insertupdatetechnicalathleteprogramprogress", body)
        };
    }
    catch(error) {
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    };
});