import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Footer from "../../Common/Footer";
import { insertUpdatePsychologySession } from "../../../../utility/PsychologyApiActions";
import { useDispatch } from "react-redux";
import DatePickerComponent from "../../Common/DatePickerComponent";
import moment from "moment/moment";
import ConfirmationPopup from "../../Common/ConfirmationPopup";
import { loaderActions } from "../../../../reducer/loader";
import { useSelector } from "react-redux";

export default function ProgressNotesContent({ isEditOption, isEditMode, setIsEditMode, sessionDetails,
    setSessionDetails, saveHandler, cancelHandler }) {
    const dispatch = useDispatch();
    const [confirmationPopup, setConfirmationPopup] = useState({ isShow: false });
    const [isError, setIsError] = useState(false);
    const loading = useSelector((state) => state?.psychology?.loading);
    useEffect(() => {
        if (loading) {
            dispatch(loaderActions.showLoader());
        } else {
            dispatch(loaderActions.hideLoader());
        }
    }, [loading]);
    const dateChangeHandler = (date) => {
        let case_date = moment(date).format("MM/DD/YYYY");
        setSessionDetails((prevState) => {
            return { ...prevState, 'case_date': case_date }
        });
        if (isError?.show && isError?.type == 'date') {
            setIsError({ type: 'date', show: false });
        }
    }

    const notesChangeHandler = (e) => {
        setSessionDetails((prevState) => {
            return { ...prevState, 'notes': e?.target?.value }
        });
        if (isError?.show && isError?.type == 'notes') {
            setIsError({ type: 'notes', show: false });
        }
    }

    const saveNoteHandler = () => {
        if (!sessionDetails?.case_date) {
            return setIsError({ type: 'date', show: true, error: "Case date is required" });
        }
        if (!sessionDetails?.notes) {
            return setIsError({ type: 'notes', show: true, error: "Note is required" });
        }
        setIsError();
        dispatch(insertUpdatePsychologySession(
            {
                payload: sessionDetails,
                callback: (id) => {
                    saveHandler(id);
                }
            }
        ));
    }

    const cancelNoteHandler = (e) => {
        e?.stopPropagation();
        setConfirmationPopup({
            isShow: true,
            isActionBtns: true,
            warning: "Are you sure you want to cancel?",
            warningMsg: "",
            cancelHandler: () => setConfirmationPopup({ isShow: false }),
            okHandler: () => {
                setConfirmationPopup({ isShow: false });
                cancelHandler();
            },
        });
    }

    return (
        <>
            <div class={`si-main-content psychology-session ${!isEditMode ? 'si-view' : ''}`}>
                <form class="session-content">
                    <h4 class="si-title">Session Details
                        {isEditOption &&
                            <span class="icon-edit" onClick={() => setIsEditMode(true)}></span>
                        }
                    </h4>
                    <div class="content-row-wrp">
                        <div class="content-row">
                            <div class="inner-content-wrp">

                                <div class={`form-control  ${isError?.show && isError?.type == 'date' ? 'si-error' : ''}`}>
                                    <div class="form-group">
                                        <label for="start-date" class="si-label"><span>*</span>Conducted On</label>
                                        <div class="form-group-wrap si-location date-form-control">
                                            {/* <input type="text" placeholder="mm/dd/yyyy" id="start-date"
                                            class="si-input" /> */}
                                            {/* <DatePicker
                                                calendarClassName="rasta-stripes"
                                                showIcon
                                                disabled={isEditMode ? false : true}
                                                selected={sessionDetails?.case_date ? new Date(sessionDetails?.case_date) : new Date()}
                                                onChange={(date) => dateChangeHandler(date)} /> */}
                                            <DatePickerComponent
                                                isEditMode={isEditMode ? false : true}
                                                selectedDate={sessionDetails?.case_date}
                                                maxDate={new Date()}
                                                dateChangeHandler={dateChangeHandler} />
                                        </div>
                                    </div>
                                    <span class="si-error-message">{isError?.type == 'date' ? isError?.error : ''}</span>
                                </div>

                            </div>
                        </div>
                        <div class="content-row">
                            <div class={`form-control reason-desc ${isError?.show && isError?.type == 'notes' ? 'si-error' : ''}`}>
                                <label for="" class="si-label"><span>*</span>Notes</label>
                                <textarea id="" name="" rows="8" cols=""
                                    readOnly={!isEditMode ? true : false}
                                    class={`si-input`} value={sessionDetails?.notes}
                                    placeholder="Add notes" onChange={(e) => notesChangeHandler(e)}>
                                </textarea>
                                <span class="si-error-message">{isError?.type == 'notes' ? isError?.error : ''}</span>
                                {/* <span class="textarea" role="textbox" contenteditable></span> */}

                            </div>
                        </div>
                    </div>
                </form>
                <ConfirmationPopup
                    isActive={confirmationPopup?.isShow}
                    isActionBtn={confirmationPopup?.isActionBtn}
                    isActionBtns={confirmationPopup?.isActionBtns}
                    warning={confirmationPopup?.warning}
                    warningMsg={confirmationPopup?.warningMsg}
                    cancelHandler={confirmationPopup?.cancelHandler}
                    okHandler={confirmationPopup?.okHandler}
                />
            </div>
            {isEditMode &&
                <footer>
                    <button type="button"
                        class="si-button  si-icon--add  si-clearbtn" onClick={(e) => cancelNoteHandler(e)}>cancel</button>
                    <button type="button"
                        class="si-button si-button--green si-icon--add si-savebtn" onClick={() => saveNoteHandler()}>save</button>
                </footer>
                // <Footer
                //     isActionBtn={true}
                //     btnClass={`si-button si-button--green`}
                //     actionBtnText={"Save"}
                //     actionBtnHandler={() => saveNoteHandler()}
                // />
            }
        </>
    )
}
