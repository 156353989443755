import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ListingTable from '../../Common/ListingTableMUI';
import {TableBody, TableRow, TableCell} from '@mui/material';
import { loaderActions } from '../../../../reducer/loader';
import { fetchAthleteReportList } from '../../../../utility/testAssessmentApiActions';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ATHELETE_ROLE_ID } from '../../../../utility/constants';

function ReportListingTable({userguid, functionId,viewReportclickHandler}) {
    const [pageNo, setPageNo] = useState(1);
    const navigate = useNavigate();
    const isAuth = useSelector(state => state?.auth?.isAuthenticated);
    const userData = useSelector(state => state?.auth?.userData)
    const athleteReportList = useSelector(state => state?.testAssessment?.athleteReportList);
    const paginationObject = useSelector(state => state?.testAssessment?.paginationObject);
    const [isActionActive, setIsActionActive] = useState(Array(athleteReportList?.length)?.fill(false));
    const dispatch = useDispatch();
    
    const handleAction = (e, idx) => {
        e?.stopPropagation();
        setIsActionActive(prevState => {
            let newState = Array(athleteReportList?.length)?.fill(false);
            newState[idx] = !prevState[idx];
            return [ ...newState ];
        });
      };

      useEffect(() => {
        window?.addEventListener("click", handleKeydownEvent);
    
        return () => window?.removeEventListener("click", handleKeydownEvent);
    }, []);
    
      useEffect(() => {
        setIsActionActive(Array(athleteReportList?.length)?.fill(false));
      }, [athleteReportList]);
      
      const handleKeydownEvent = (event) => {
        setIsActionActive(Array(athleteReportList?.length)?.fill(false));
      };

    const tableHeaders = [
        {
            label: "Test Name",
            align: "left"
        },
        {
            label: "Report Date",
            align: "center"
        },
        {
            label: "",
            align: "center",
            stickyClass: "stickyCell-right",
            width:"10%"
        }
    ];
    const getTableBody = () => {
        return (
            <TableBody>
                {
                    athleteReportList?.length
                    ?
                    athleteReportList?.map((report, idx) => {
                        return (
                            <TableRow 
                                key={idx}
                            > 
                                <TableCell align={"left"}  
                                onClick={(e)=> viewReportclickHandler(e,report)}
                                sx={{ cursor: "pointer" }}
                                >
                                    {report?.test_name}
                                </TableCell>
                                <TableCell align={"center"}>
                                    {report?.conducted_date ? moment(report?.conducted_date)?.format("DD/MM/yyyy") : '-'}
                                </TableCell>
                                <TableCell align={"right"} className='stickyCell-right' width={"10%"}>
                                    {Number(userData?.role_id) !== ATHELETE_ROLE_ID ? 
                                        <div className="si-custom-table__cell si-wrap" style={{margin:"auto"}}>
                                            <button 
                                                type="button" 
                                                className={`si-icon__button icon-options ${isActionActive[idx] ? "active" : ""}`}
                                                onClick={(e) => handleAction(e, idx)}
                                            ></button>
                                            <div className="si-action-icons">
                                                <button 
                                                    type="button" 
                                                    className="si-icon-button"
                                                    onClick={(e)=> viewReportclickHandler(e,report,true)}
                                                    >
                                                    <span className="icon-edit"></span>edit
                                                </button>
                                            </div>
                                        </div> : 
                                        <button 
                                                type="button" 
                                                className="si-viewBtn"
                                                // style={{'display': 'inline-block'}}
                                                onClick={(e)=> viewReportclickHandler(e,report)}
                                        >
                                            <span className="si-icon__view"></span>
                                            <span className="si-txt">View Report</span>
                                        </button>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    })
                    :
                    <TableRow className="nodata-tableRow">
                        <TableCell align={"center"} colSpan={tableHeaders.length}>
                            No data found
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        );
    };

    useEffect(() => {
        if(isAuth && functionId && userguid) {
            dispatch(loaderActions?.showLoader());
            dispatch(fetchAthleteReportList({
                pageNo,
                perPage: 20,
                userguid,
                functionId,
                callback: () => setTimeout(() => {
                    dispatch(loaderActions?.hideLoader());
                })
            }));
        };
    }, [isAuth, functionId, pageNo,userguid]);

    return (
        // <div className="si-main-content report-main-table">
        <div className="si-main-content report-main-table   add-report-table ">
            <ListingTable 
                tableHeaders={tableHeaders}
                getTableBody={getTableBody}
                pageNo={pageNo}
                setPageNo={setPageNo} 
                pagination={paginationObject}
                perPage={20}
            />
        </div>
    );
};

export default ReportListingTable;