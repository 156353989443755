import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { REMARK_ID } from "../../../../utility/constants";

export default function TableHeader({ headers }) {
  const [remark, setRemark] = useState('');
  useEffect(() => {
    if (headers) {
      // let data = headers.filter((h) => h.id == REMARK_ID);
      let data = headers.filter((h) => h.is_grouped);
      if (data) {
        setRemark(data[0]);
      }
    }
  }, [headers]);
  return (
    <div className="si-tbls__head">
      <div className="si-tableleft">
        <div className="si-tbls__row ">
          {headers?.map((header) => {
            // if (header.id != REMARK_ID) {
            if (!header.is_grouped) {
              return <div className="si-tbls__cell si-27" key={header.id}>
                <span>{header.label}</span>
              </div>
            }
          })}
        </div>
      </div>
      {remark &&
        <div className="si-tableright">
          <div className="si-tbls__cell si-suggstion">
            <span>{remark.label}</span>
          </div>
        </div>
      }
    </div>
  );
}
