import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    body: {
        paddingTop: 0,
        paddingBottom:10,
        backgroundColor: '#FEE5E6',
        width:"100%",
       
    },
   
    pageNumber: {
        position: 'absolute',
        fontSize: 9,
        bottom: 10,
        left: 0,
        right: 20,
        textAlign: 'right',
        color: 'grey',
    },
    headerWrap:{
        backgroundColor: "#E10000", 
        padding: "8px 10px",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center", 
        color: "#ffffff" 
    },
    headerRightWrp:{
        display: 'flex',
         flexDirection: "row",
          alignItems: "center"
    },
    headerLeftWrp:{
        display: 'flex',
         flexDirection: "row", 
         fontSize: "10px", 
         alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.2)", 
          padding: "3px 13px 5px 13px", 
          borderRadius: "5px", 
    },
    mainContentWrp:{
        display: 'flex', 
        flexDirection: "row", 
        justifyContent: "space-between", 
        padding: "0px 20px 0px 20px",
        backgroundColor: "#FEE5E6",
        gap: "10px" 
    },
    dateCardWrp:{
        backgroundColor: "#fff", 
        borderBottom: "3px solid #E10000", 
        borderRadius: "4px",
        padding: "6px 11px", 
        marginTop:"10px",
        fontSize: "12px",
        color: "#E10000",
        marginBottom: "10px",
        display:"flex",
        flexDirection: "row",
        
    },
    contentCardWrp:{
        display: 'flex',
        flexDirection: "column",
        padding: "5px",
        backgroundColor: "#fff",
        borderRadius: "2px",
        marginBottom: "7px",
    },
    contentTitle:{
        fontSize: "10px",
         fontWeight: "500",
          color: "#282828",
          overflow:"wrap",
          marginBottom:"3"
    },
    contentText:{
        fontSize: "10px",
         fontWeight: "400",
          color: "#6E6E6E",
          marginBottom:"3"
    }
});

//   ReactPDF.render(<Quixote />);
// for weeks
// {/* <Text style={{ fontWeight: "900", fontSize: "15px" }}>
//         {weeksDateArray.length ? 
//         `Tournament Preparation Program: Week ${programWeeksIndex+1} of ${weeksDateArray?.length} (${weeksDateArray[0][0].date}/${weeksDateArray[0][0].monthNumber}/${weeksDateArray[0][0].year} - ${weeksDateArray[weeksDateArray.length-1][weeksDateArray[weeksDateArray.length-1].length-1].date}/${weeksDateArray[weeksDateArray.length-1][weeksDateArray[weeksDateArray.length-1].length-1].monthNumber}/${weeksDateArray[weeksDateArray.length-1][weeksDateArray[weeksDateArray.length-1].length-1].year})`
//         :"Tournament Preparation Program: Week 0 of 0 (-/-/-- - -/-/-)"
//     }
//     </Text> */}

const PdfDocument = ({ weekDetails,programDetails,weeksDateArray, unitList, userData, selectedProgramDetails}) => {
    console.log( weekDetails,programDetails,weeksDateArray, unitList)
    return (
        <Document >
            {programDetails?.map((weeks,programWeeksIndex)=>{
            return <Page key={"programWeeksIndex" + programWeeksIndex} style={styles.body} orientation='landscape' size="A4">
                        <View style={styles.section}>
                            <View style={styles.headerWrap} fixed>
                                <View style={styles.headerRightWrp}>
                                    <View style={{marginRight:"7px"}}>
                                    <Image style={{width:"20px"}}
                                        src={process.env.PUBLIC_URL + '/images/d11.jpg'}
                                    />
                                    </View>
                                    {/* <Text style={{ fontWeight: "900", fontSize: "13px" }}>
                                        {selectedProgramDetails.program_name ? 
                                        selectedProgramDetails.program_name
                                        :"Tournament Preparation Program"
                                    }
                                    </Text> */}
                                    <Text style={{ fontWeight: "900", fontSize: "13px" }}>
                                        {weeksDateArray.length ? 
                                        `${selectedProgramDetails.program_name ? selectedProgramDetails.program_name:"Tournament Preparation Program"}: Week ${programWeeksIndex+1} of ${weeksDateArray?.length} `
                                        :"Tournament Preparation Program: Week 0 of 0"
                                    }
                                    </Text>
                                </View>
                                <View style={styles.headerLeftWrp}>
                                    <Text style={{ fontWeight: "700" }}>Created by:</Text>
                                    <Text style={{ fontWeight: "400", marginLeft: "2px", textTransform: "capitalize" }}>{`${userData?.first_name ? userData.first_name : ""} ${userData?.middle_name ? userData.middle_name : ""} ${userData?.last_name ? userData.last_name : ""}`}</Text>
                                </View>
                            </View>
                            <View style={styles.mainContentWrp}>
                                {weeks?.days?.map((day,daysProgramIndex)=>{
                                 return  ( <View key={"daysProgrameIndex"+daysProgramIndex} style={{ display: 'flex', flexDirection: "column",marginRight:"1",width:"100" }}>
                                                <View style={styles.dateCardWrp} fixed>
                                                    <Text style={{ fontWeight: "700", }}>{weeksDateArray?.[programWeeksIndex]?.[daysProgramIndex]?.dateDay}, </Text>
                                                    <Text style={{fontWeight:"400",}}>{`${weeksDateArray?.[programWeeksIndex]?.[daysProgramIndex]?.date} ${weeksDateArray?.[programWeeksIndex]?.[daysProgramIndex]?.month}`}</Text>
                                                </View>
                                                {day?.exercises?.map((exercises,exercisesProgramIndex)=>{
                                                    return (
                                                        <View key={"exercisesProgramIndex"+exercisesProgramIndex} style={styles.contentCardWrp} wrap={false}>
                                                            <Text style={styles.contentTitle} wrap={false}>{exercises.exercise_name ? exercises.exercise_name : ""}</Text>
                                                            <Text style={styles.contentText} wrap={false}>
                                                                {exercises?.sets ? `Sets ${exercises?.sets} ` : ""}  
                                                                {exercises?.metrics_units
                                                                    ?.filter(
                                                                        (metric) => metric?.is_enabled && metric?.value
                                                                    )
                                                                    ?.map((exercises) => {
                                                                        let metricObj = unitList?.filter?.(
                                                                        (data) => data?.metric_id == exercises?.metric_id
                                                                        )[0];
                                                                        let unitObj = metricObj?.unit?.filter?.(
                                                                        (data) => data?.unit_id == exercises?.unit_id
                                                                        )[0];
                                                                        if(exercises?.label === "Reps"){
                                                                        return (
                                                                            `| ${exercises?.label} ${exercises?.value} `
                                                                            );
                                                                        } else {
                                                                            return (
                                                                            `| ${exercises?.label} ${exercises?.value} ${unitObj?.unit} `
                                                                            );
                                                                        }
                                                                })}
                                                            </Text>
                                                        </View>
                                                    )
                                                })}
                                            </View>)
                                })}
                              
                            </View>
                        </View>
                            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                                `Page ${pageNumber} of ${totalPages}`
                            )} fixed />
                    </Page>
                        })}
        </Document>
    )


}

export default PdfDocument;