import React, {Fragment, useState, useEffect, useMemo} from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loaderActions } from '../../../../reducer/loader';
import { toast } from 'react-toastify';
import Header from '../../Common/Header';
import AthletePerformanceDetails from './AthletePerformanceDetails';
import CalenderDropdown from './CalendarDropdown';
import CalenderContent from './CalendarContent';
import { fetchTechnicalProgramDetailsById, fetchTechnicalWeekWiseProgressCalendarData, updateTechnicalProgramDetails } from '../../../../utility/technicalProgramApiActions';
import { fetchSportsList } from '../../../../utility/technicalTemplateApiActions';
import { differenceInDays } from 'date-fns';
import { ATHELETE_ROLE_ID, TECHNICAL_COACH_ROLE_ID } from '../../../../utility/constants';

function ProgramProgressCalender() {
  const {state} = useLocation();
  const {backUrl} = state ? state : '';
  let { id, userId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams?.get("tab");
  const selectedWeek = useSelector(state => state?.technicalPrograms?.selectedWeek);
  const programNameErrorMsg = useSelector(state => state?.technicalTemplates?.programNameErrorMsg);
  const programNameError = useSelector(state => state?.technicalTemplates?.programNameError);
  const selectedProgram = useSelector(state => state?.technicalPrograms?.selectedProgram);
  const athleteOverallProgressData = useSelector(state => state?.technicalPrograms?.athleteOverallProgressData);
  const sportList = useSelector(state => state?.technicalTemplates?.sportList);
  const userData = useSelector(state => state?.auth?.userData);
  const isAuth = useSelector(state => state?.auth?.isAuthenticated);
  const [headerData, setHeaderData] = useState({
      headerClass:"si-margin si-calheader",
      heading: selectedProgram?.program_name,
      isHeadingEditable: false,
      isbackBtn: true,
      backBtnAction: () => {
        getBackBtnNavigate();
      },
      isCalendar: true,
      isCountEditable: false,
      dataCountHeading: selectedProgram?.program_duration > 1 ? "Weeks" : "Week",
      isDropdownEditable: false
  });
  const [confirmationPopup, setConfirmationPopup] = useState({isShow: false});
  const dispatch = useDispatch();

  useEffect(()=>{
    if(userData.role_id){
      setHeaderData({
        ...headerData,
        backBtnAction: () => {
          getBackBtnNavigate();
        },
      });
    }
  },[userData]);

  useEffect(() => {
    if(isAuth){
      dispatch(loaderActions?.showLoader());
      dispatch(
        fetchTechnicalProgramDetailsById({
          id, 
          type:2,
          callback: (data) => setTimeout(() => {
              if(data) {
                let week = getDefaultSelectedWeek(data?.start_date, data?.program_duration);
                  dispatch(
                      fetchTechnicalWeekWiseProgressCalendarData({
                          id,
                          week,
                          userId,
                          callback: () => setTimeout(() => {
                              dispatch(loaderActions?.hideLoader());
                          })
                      })
                  );
              }
              else {
                  toast.warn("No program data.");
                  getBackBtnNavigate();
              };
          })
        })
      );
      if(!sportList?.length) dispatch(fetchSportsList());
    }
  }, [isAuth]);

  useEffect(()=>{
    if(programNameError){
      dispatch(loaderActions?.hideLoader());
      toast.warn(programNameErrorMsg);
    }
  },[programNameError,programNameErrorMsg]);

  useEffect(() => {
    setHeaderData({
      ...headerData,
      heading: selectedProgram?.program_name,
      dataCountHeading: selectedProgram?.program_duration > 1 ? "Weeks" : "Week"
    });
  }, [selectedProgram]);

  const getBackBtnNavigate = ()=>{
    if(backUrl) !isNaN(Number(backUrl)) ? navigate(Number(backUrl)) : navigate(backUrl);
    else if(tab) navigate(`/athleteprofile/${userId}?tab=${tab}`);
    else if(userData?.role_id == TECHNICAL_COACH_ROLE_ID) navigate(`/technical/programs/${id}/calendar?performance=true`);
    else if(userData?.role_id == ATHELETE_ROLE_ID) navigate("/technical/assignedPrograms");
  }
  
  const getDefaultSelectedWeek = (date, duration) => {
    let week = 1;
    let startDate = date ? new Date(date) : null;
    let currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    if(startDate && currentDate>=startDate) {
      let diff = differenceInDays(currentDate, startDate);
      let newWeek = parseInt(diff/7) + 1;
      if(diff > 6 && newWeek<=duration) week = newWeek;
    };
    return week;
  };

  const sportOptions = sportList?.map(obj => {
    return {
        value: obj?.sport_id,
        label: obj?.sport_name
    };
  });

  const editAction = (e) => {
    e?.preventDefault();
    setHeaderData({
      ...headerData, 
      isHeadingEditable: true,
      isCountEditable: true,
      isDropdownEditable: true
    });
  };

  const saveAction = (e, programName, duration, selectedSport) => {
    e?.preventDefault();
    // dispatch(templatesActions.setDefault());
    if(programName && duration && selectedSport?.value) {
      setConfirmationPopup({
        isShow: true,
        isActionBtns: true,
        warning: "Are you sure you want to save the data?",
        warningMsg: "",
        cancelHandler: () => setConfirmationPopup({isShow: false}),
        okHandler: () => {
          dispatch(loaderActions?.showLoader());
          setConfirmationPopup({isShow: false});
          dispatch(updateTechnicalProgramDetails({
            programId:id,
            name: programName,
            duration,
            sportId: selectedSport?.value,
            startDate:selectedProgram?.start_date,
            endDate:selectedProgram?.end_date,
            assignedAthletes:selectedProgram?.athlete_id,
            callback: (data) => setTimeout(() => {
              dispatch(fetchTechnicalWeekWiseProgressCalendarData({
                  id,
                  week: selectedWeek ? selectedWeek : "1",
                  userId,
                  callback: () => setTimeout(() => {
                    dispatch(loaderActions?.hideLoader());
                  })
              })
              );
              setHeaderData({
                ...headerData, 
                heading: data?.program_name,
                isHeadingEditable: false,
                isCountEditable: false,
                isDropdownEditable: false
              });
            })
          }));
        }
      });
    } else {
      console.log("Post Program Error");
    };
  };

  const cancelAction = () => {
    setConfirmationPopup({
      isShow: true,
      isActionBtns: true,
      warning: "Are you sure you want to discard your changes?",
      warningMsg: "",
      cancelHandler: () => setConfirmationPopup({isShow: false}),
      okHandler: () => {
        setConfirmationPopup({isShow: false});
        setHeaderData({
          heading: selectedProgram?.program_name,
          isHeadingEditable: false,
          isbackBtn: true,
          backBtnAction: () => {
            getBackBtnNavigate();
          },
          isCalendar: true,
          isCountEditable: false,
          dataCountHeading: selectedProgram?.program_duration > 1 ? "Weeks" : "Week",
          isDropdownEditable: false
        });
      }
    });
  };
  console.log(athleteOverallProgressData, selectedProgram, userData?.role_id, "<====weekwiseData");
  return (
    <Fragment>
        <Header
            headerData={headerData}
            dataCount={selectedProgram?.program_duration ? selectedProgram?.program_duration : ""}
            dropdownPlaceHolder={"Select Sport"}
            dropdownValue={sportOptions?.find(obj => (obj?.label?.toLowerCase() === selectedProgram?.sport_name?.toLowerCase()) || obj?.value == selectedProgram?.sport_id)}
            dropdownOptions={sportOptions}
            editAction={editAction}
            saveAction={saveAction}
            cancelAction={cancelAction}
            isViewOnly={true}
        />
        {
          userData?.role_id && userData?.role_id != ATHELETE_ROLE_ID && 
          <AthletePerformanceDetails 
            details={athleteOverallProgressData}
          />
        }
        <CalenderDropdown 
            weeks={selectedProgram?.program_duration ? selectedProgram?.program_duration : ""} 
            selectedProgram = {selectedProgram}
            defaultSelectedWeek={getDefaultSelectedWeek(selectedProgram?.start_date, selectedProgram?.program_duration)}
        />
        <CalenderContent 
            confirmationPopup={confirmationPopup} 
            setConfirmationPopup={setConfirmationPopup}
            headerData={headerData}
            setHeaderData={setHeaderData}
            startDate={selectedProgram?.start_date}
        />
    </Fragment>
  );
};

export default ProgramProgressCalender;