import { createAsyncThunk } from '@reduxjs/toolkit';
import { authActions } from '../reducer/auth';
import API from './api';

// let getAPIDetails = new API()?.getAPIDetails;
let postAPIDetails = new API()?.postAPIDetails;

export const updateTechnicalExerciseMaster = createAsyncThunk("template/updateTechnicalExerciseMaster",async({exerciseData,callback}, {getState, dispatch, rejectWithValue}) =>{
    console.log("add/updateExerciseMaster:",exerciseData);
    let state = getState();
    let userData = state?.auth?.userData;
    let body = {
        "add_exercise": {...exerciseData , user_guid : userData.user_guid}
    };
    try{
        return {
            callback,
            apiResponse: await postAPIDetails(`exercise/insertupdatetechnicalexercisemaster`, body)
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
});

export const deleteTechnicalExerciseMaster = createAsyncThunk("template/deleteTechnicalExerciseMaster",async({id,callback}, {getState, dispatch, rejectWithValue}) =>{
    let state = getState();
    let userData = state?.auth?.userData;
    try{
        return {
            callback,
            apiResponse: await postAPIDetails(`exercise/deletetechnicalexercisemasterbyid?exercise_id=${id}&user_guid=${userData.user_guid}`, {})
        };
    } catch(error){
        let statusCode = error?.response?.status
        if(statusCode === 401){
            dispatch(authActions.logout())
            error.message = error?.response?.data?.title && error?.response?.data?.title
            return rejectWithValue({callback, error})
        } else {
            return rejectWithValue({callback, error});
        }
    }
})