export const annotationX2 = {
    type: "line",
    scaleID: "y",
    borderWidth: 1,
    opecity : 0.5,
    borderColor: "#ffffff80",
    borderDash: [2.5],
    value: 50,
    label: {
      content: "Line annotation at x=0.05",
      display: false
    }
  };
  
  export const annotationX1 = {
    type: "line",
    // scaleID: 'y',
    borderWidth: 10,
    border: "0.5px solid rgba(255, 255, 255, 0.3)",
    borderRadius: 5,
    borderColor: "#4348F0",
    borderShadowColor:"0px 0px 8px rgba(199, 228, 255, 0.7)",
    shadowBlur:2,
    // value: 0,
    xMin: -1,
    yMin: 0,
    yMax: 0,
  };
  
  export const annotationY2 = {
    type: "line",
    borderWidth: 3,
    borderColor: "green",
    position: "start",
    borderDash:[2.5],
    yMin: 50,
    yMax: 100,
    xMin: -0.5,
    xMax: -0.5,
    label: {
      rotation: "auto",
      position: "start",
      backgroundColor: "red",
      // content: "Line at x=Label 5",
      display: false
    }
  };

  export const annotationY1 = {
    type: "line",
    borderWidth: 3,
    borderColor: "red",
    position: "start",
    borderDash:[2.5],
    yMin: 0,
    yMax: 50,
    xMin: -0.5,
    xMax: -0.5,
    label: {
      rotation: "auto",
      position: "start",
      backgroundColor: "black",
      content: "Line at x=Label 5",
      display: false
    }
  };
  
  export const annotationImgBorder1 = {
    type: "ellipse",
    xMin: 0.6,
    xMax: 1.4,
    yMin: 45,
    yMax: 75,
    font: {
      size: 40,
      family: "Arial",
      weight: "bold"
    },
    backgroundColor: "rgba(0, 176, 68, 0.9)"
  };
  
  export function value(ctx, datasetIndex, index, prop) {
    const meta = ctx.chart.getDatasetMeta(datasetIndex);
    const parsed = meta.controller.getParsed(index);
    console.log("leff", parsed ? parsed[prop] : NaN, prop);
    return parsed ? parsed[prop] : NaN;
  }
  export const annotationImg1 = {
    // type: 'label',
    // color: 'white',
    content: () => {
      const img = new Image();
      img.src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
      return img;
    },
    // font: {
    //   size: 24
    // },
    // xValue: (ctx) => value(ctx, 0, 2, 'x'),
    // yValue: (ctx) => value(ctx, 0, 10, 'y'),
    type: "label",
    drawTime: "afterDraw",
    // content: Utils.getImage(),
    width: 100,
    height: 100,
    xValue: 0.65,
    yValue: 60,
    xAdjust: 50,
    borderRadius : "10px 10px solid green "
    // yAdjust: 90,
    // borderWidth: 1,
    // borderDash: [6, 6],
  };



  // export default Config;   