import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DailyQuotes from "./DailyQuotes";
import UpcomingCompetition from "../Dashboard/CoachDashboard/UpcomingCompetition";
import { loaderActions } from "../../reducer/loader";
import { fetchCoachDashboard } from "../../utility/dashboardApiActions";

export default function DefaultDashboard() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state?.auth?.isAuthenticated);
  const dashboardData = useSelector((state) => state?.dashboard?.data);

  useEffect(() => {
    if (isAuth) {
      dispatch(loaderActions?.showLoader());
      dispatch(
        fetchCoachDashboard((res) =>
          setTimeout(() => {
            dispatch(loaderActions?.hideLoader());
          })
        )
      );
    }
  }, [isAuth]);

  return (
    <Fragment>
      <div className="si-main-content si-dashboard-new si-coach-dashboard">
        {/* <div
          class={`${
            dashboardData?.upcoming_competition?.length ? "upcoming-competition-active" : "upcoming-competition"
          }`}
        >
          {dashboardData?.upcoming_competition?.length ? (
            <UpcomingCompetition competitons={dashboardData?.upcoming_competition} />
          ) : dashboardData?.quotes ? (
            <DailyQuotes quotes={dashboardData.quotes} />
          ) : (
            ""
          )}
        </div> */}
        <div className="homepage">
          <div className="homepage-content">
            <h2 className="title">Athlete Management System</h2>
            <p className="para">
              "Select a module from the menu to get started!"
            </p>
            <div className="img-wrp">
              <img src={process.env.PUBLIC_URL + "/images/homePage-img.svg"} alt="homepage-img" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="si-main-content">
        
      </div> */}
    </Fragment>
  );
}
