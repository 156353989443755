import React from 'react';
import PopupFooter from './PopupFooter';

function ConfirmationPopup({isActive, warning, warningMsg="", isActionBtn, isActionBtns, cancelHandler, okHandler}) {

    return (
        <div className={`si-popup ${isActive ? "active" : ""}`}>
            <div className="si-popup-content">
                <div className="si-popup-body">
                    <div className="content-wrapper">
                    <div className="content-title">
                        <h5>{warning}</h5>
                    </div>
                    <div className="contnt-description">
                        <p>{warningMsg}</p>
                    </div>
                    </div>
                </div>
                
                <PopupFooter 
                    isActionBtn={isActionBtn}
                    btnClass={"si-action-btn"}
                    actionBtnHandler={okHandler}
                    actionBtnText={"Ok"}
                    isActionBtns={isActionBtns}
                    btn1Class={"si-action-btn"}
                    actionBtn1Text={"Cancel"}
                    actionBtn1Handler={cancelHandler}
                    btn2Class={"si-action-btn"}
                    actionBtn2Text={"Ok"}
                    actionBtn2Handler={okHandler}
                />
            </div>
        </div>
    );
};

export default ConfirmationPopup;