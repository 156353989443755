import React, { memo, useEffect, useState, useRef } from 'react';
import CreateBtn from './CreateBtn';
import Select from 'react-select';
import MultiSelectDropdown from './MultiSelectDropdown';
import { useSelector } from 'react-redux';
import Dropdown from '../Common/Dropdown';


function SubHeader({OtherClass, filterOptions, searchTerm, setSearchTerm, 
  setSelectedTag , setSelectedSportIds, setSelectedGroupIds, pageNo, 
  setPageNo, isBtn, btnName, onBtnClick,
  selectedSports , groupListProps,
  setSelectedInjuryStatus,
  activeTab, setActiveTab , tab1Text, tab2Text,genderList,
  eventList,setSelectedEventTypeIds, setSelectedGenderIds, 
  setSelectedProgramStatus ,setSelectedDateRangeIds,
  moduleType = 1 }) {
    const searchReference = useRef(null);
    const [searchAnimateIn, setSearchAnimateIn] = useState(false);
    const sportList = useSelector(state => state?.templates?.sportList);
    const tagList = useSelector(state => moduleType == 1 ? state?.templates?.tagList : state?.technicalTemplates?.tagList);
    const groupList = useSelector(state => state?.programs?.groupList);
    const inputRef = useRef();

    useEffect(() => {
      window?.addEventListener("click", animateOutOnOutsideClick);
      return () => window?.removeEventListener("click", animateOutOnOutsideClick);
    }, []);

    const animateOutOnOutsideClick = (e) => {
      if (searchReference?.current && !searchReference?.current?.contains(e?.target)) {
        setSearchAnimateIn(false);
      }; 
    };

    const programStatusOptions = [
      {
        value: 0,
        label: 'All Program Status'
      },
      {
        value: 1,
        label: 'Active'
      },
      {
        value: 2,
        label: 'Upcoming'
      },
      {
        value: 3,
        label: 'Completed'
      }
    ];

    const injuryStatusOptions = [
      {
        value: null,
        label:"All Injuries"
      },
      {
        value: 0,
        label: 'Open'
      },
      {
        value: 1,
        label: 'Closed'
      }
    ];

    const dateRangeOptions = [
      {
        value: 1,
        label: '27/11/2022 - 13/02/2023'
      },
      {
        value: 2,
        label: '27/12/2022 - 23/02/2023'
      }
    ];

    const sportOptions = sportList?.map(obj => {
      return {
          value: obj?.sport_id,
          label: obj?.sport_name
      };
    });
    const groupOptions = groupListProps ? groupListProps?.map(obj => {
      return {
          value: obj?.group_id,
          label: obj?.name
      }}) :  groupList?.map(obj => {
        return {
            value: obj?.group_id,
            label: obj?.name
        };
      });

    const genderOptions = genderList?.map(obj => {
      return {
          value: obj?.gender_id,
          label: obj?.gender
      };
    });
    const eventOptions = eventList?.map(obj => {
      return {
          value: obj?.event_type_id,
          label: obj?.event_type
      };
    });

    const sportChangeHandler = (data) => {
      let selectedOpt = data?.map(obj => obj?.value);
      setSelectedSportIds([...selectedOpt]);
      if(pageNo !== 1) setPageNo(1);
    };

    const genderChangeHandler = (data) => {
      let selectedOpt = data?.map(obj => obj?.value);
      setSelectedGenderIds([...selectedOpt]);
      if(pageNo !== 1) setPageNo(1);
    };

    const eventChangeHandler = (data) => {
      let selectedOpt = data?.map(obj => obj?.value);
      setSelectedEventTypeIds([...selectedOpt]);
      if(pageNo !== 1) setPageNo(1);
    };

    const groupChangeHandler = (data) => {
      let selectedOpt = data?.map(obj => obj?.value);
      setSelectedGroupIds([...selectedOpt]);
      if(pageNo !== 1) setPageNo(1);
    };

    const programStatusChangeHandler = (data) => {
      // let selectedOpt = data?.map(obj => obj?.value);
      // setSelectedProgramStatus([...selectedOpt]);
      setSelectedProgramStatus(data?.value);
      if(pageNo !== 1) setPageNo(1);
    };

    const injuryStatusChangeHandler = (data) => {
      setSelectedInjuryStatus(data?.value);
      if(pageNo !== 1) setPageNo(1);
    };

    const dateRangeChangeHandler = (data) => {
      let selectedOpt = data?.map(obj => obj?.value);
      setSelectedDateRangeIds([...selectedOpt]);
      if(pageNo !== 1) setPageNo(1);
    };
    
    const tagOptions = tagList?.map(obj => {
      return {
          value: obj?.tag,
          label: obj?.tag
      };
    });

    const tagChangeHandler = (data) => {
      let selectedOpt = data?.map(obj => obj?.value);
      setSelectedTag([...selectedOpt]);
      if(pageNo !== 1) setPageNo(1);
    };

    const closeClickHandler = (type)=>{
      if(type === 1){
        if(pageNo !== 1) setPageNo(1);
        setSearchTerm('');
      };
      setSearchAnimateIn(!searchAnimateIn);
    }
    
      useEffect(() => {
       if (searchAnimateIn){ 
           inputRef.current.focus();
      }
      },[searchAnimateIn]);
    return (
        <div className={`si-subHeader ${OtherClass ? OtherClass : ''}`}>
           {filterOptions?.is_search_filter && 
            <div className="form-control si-searchbox" ref={searchReference}>
                  <input 
                      type="text" 
                      name="" id="" 
                      placeholder="Search" 
                      className={`si-input ${searchAnimateIn ? "animateIn":""}`}
                      value={ searchTerm ? searchTerm : "" }
                      ref={inputRef}
                      onChange={(e) => {
                          if(pageNo !== 1) setPageNo(1);
                          setSearchTerm(e?.target?.value)
                      }}
                  />
                  <span className={`si-icon ${searchTerm?.length > 0 ? 'icon-close' : 'icon-search'}`} onClick={()=>closeClickHandler(searchTerm?.length > 0 ? 1 :2)}></span>
              </div>
            }
            {filterOptions?.is_search_filter_for_athlete && 
            <div className="form-control si-searchbox completed-search" ref={searchReference} onClick={()=>closeClickHandler(searchTerm?.length > 0 ? 1 :2)}>
                  <input 
                      type="text" 
                      name="" id="" 
                      placeholder="Search" 
                      className={`si-input ${searchAnimateIn ? "animateIn":""}`}
                      value={ searchTerm ? searchTerm : "" }
                      ref={inputRef}
                      onChange={(e) => {
                          if(pageNo !== 1) setPageNo(1);
                          setSearchTerm(e?.target?.value)
                      }}
                  />
                  <span className={`si-icon ${searchTerm?.length > 0 ? 'icon-close' : 'icon-search'}`}></span>
              </div>
            }
              <div className="form-control-wrap">
                {filterOptions?.is_sport_filter &&
                      <MultiSelectDropdown
                            placeholder={"Select Sports"}
                            options={sportOptions}
                            changeHandler={sportChangeHandler} 
                            dropdownfor="sports"
                            
                      />
                }
                {filterOptions?.is_desableSport && 
                <Dropdown
                value={{
                  label: selectedSports?.label ,
                  value: selectedSports?.value,
                }}
                options={sportList?.map((obj) => {
                  return {
                    value: obj?.sport_id,
                    label: obj?.sport_name,
                  };
                })}
                isDisabled={true}
              />
                }
                {filterOptions?.is_gender_filter &&
                      <MultiSelectDropdown
                            placeholder={"Select gender"}
                            options={genderOptions}
                            changeHandler={genderChangeHandler} 
                            dropdownfor="gender"
                      />
                }
                {filterOptions?.is_event_type_filter &&
                      <MultiSelectDropdown
                            placeholder={"Select event type"}
                            options={eventOptions}
                            changeHandler={eventChangeHandler} 
                            dropdownfor="event"
                      />
                }
                {filterOptions?.is_group_filter &&
                      <MultiSelectDropdown
                            placeholder={"Select Groups"}
                            options={groupOptions}
                            changeHandler={groupChangeHandler} 
                            dropdownfor="groups"
                      />
                }
                {filterOptions?.is_program_status_filter &&
                      <Dropdown 
                              placeholder={"Select Program Status"} 
                              options={programStatusOptions}
                              changeHandler={programStatusChangeHandler}
                      />
                }
                {filterOptions?.is_injury_status_filter &&
                      <Dropdown 
                              placeholder={"All Injuries"} 
                              options={injuryStatusOptions}
                              changeHandler={injuryStatusChangeHandler}
                      />
                }
                {filterOptions?.is_date_range_filter &&
                      <MultiSelectDropdown 
                              placeholder={"Select Date Range"} 
                              options={dateRangeOptions}
                              changeHandler={dateRangeChangeHandler}
                              dropdownfor="date-range"
                      />
                }
                {filterOptions?.is_tag_filter &&
                      <MultiSelectDropdown 
                              placeholder={"All Tags"} 
                              options={tagOptions}
                              changeHandler={tagChangeHandler}
                              dropdownfor="tags"
                      />
                }
                {filterOptions?.is_tab &&
                <div className="form-control si-tab">
                <div className="toggle-radio" key={activeTab}>
                  <input 
                      type="radio" 
                      name="rdo" 
                      id="yes" 
                      defaultChecked={activeTab===1 ? true : false}
                  />
                  <input 
                      type="radio" 
                      name="rdo" 
                      id="no" 
                      defaultChecked={activeTab===2 ? true : false}
                  />
                  <div className="switch">
                      <label 
                          htmlFor="yes"
                          onClick={() => setActiveTab(1)}
                      >
                          {tab1Text ? tab1Text : "active"}
                      </label>
                      <label 
                          htmlFor="no"
                          onClick={() => setActiveTab(2)}
                      >
                          {tab2Text ? tab2Text : "upcoming"}
                      </label>
                  </div>
                </div>
              </div>}
              </div>
            {
              isBtn &&
              <CreateBtn name={btnName} onBtnClick={onBtnClick}/>
            }
        </div>
    );
};

export default memo(SubHeader);