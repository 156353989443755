import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import ListingTable from "../../Common/ListingTableMUI";

function NutritionListContent({ pagination, nutritionList, setPageNo, pageNo, handleNutritionLog }) {
  const tableHeaders = [
    {
      label: "Nutrition Log",
      align: "left",
    },
    {
      label: "Created by",
      align: "left",
    },
    {
      label: "Created on",
      align: "left",
    },
    {
      label: "",
      align: "right",
      stickyClass: "stickyCell-right",
    },
  ];
  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {nutritionList?.length ? (
          nutritionList?.map((log, idx) => {
            return (
              <TableRow key={uuidv4()}>
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                  onClick={(e) => handleNutritionLog(e, log)}
                >
                  {log?.log_name}
                </TableCell>

                <TableCell align={"left"}>{log?.updated_by ? log.updated_by : "-"}</TableCell>
                <TableCell align={"left"}>
                  {log?.updated_date ? log.updated_date.split("T")[0]?.split("-").reverse().join("/") : "-"}
                </TableCell>

                <TableCell align={"right"} className="stickyCell-right">
                  <button type="button" className="si-viewBtn" onClick={(e) => handleNutritionLog(e, log)}>
                    <span className="si-icon__view"></span>
                    <span className="si-txt">View Details</span>
                  </button>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={tableHeaders.length}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <ListingTable
      tableHeaders={tableHeaders}
      getTableBody={getTableBody}
      pageNo={pageNo}
      setPageNo={setPageNo}
      pagination={pagination}
      perPage={20}
    />
  );
}

export default NutritionListContent;
