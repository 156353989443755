import { Radio, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import ListingTable from "../Common/ListingTableMUI";
import { v4 as uuidv4 } from "uuid";
import { NUTRITION_MODULE_ROUTE } from "../../../utility/constants";
import { useDispatch } from "react-redux";
import { setSelectedAthleteGuid } from "../../../reducer/nutrition.reducer";

function NutritionReportLogListingContent({setRedirectUrl, selectedRow, SetSelectedRow, reportList, activeTab, pageNo, setPageNo, pagination, SELECT_LOG }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {sportId} = useParams();
  const coachHeaders = [
    {
      label: "Group Name",
      align: "left",
    },

    {
      label: "No of Athletes",
      align: "center",
    },

    {
      label: "Updated by",
      align: "left",
    },
    {
      label: "Updated on",
      align: "left",
    },
    {
      label: "",
      align: "right",
      stickyClass: "stickyCell-right",
    },
  ];
  const athleteHeaders = [
    {
      label: "Athlete Name",
      align: "left",
    },

    {
      label: "Groups",
      align: "left",
    },

    {
      label: "Updated by",
      align: "left",
    },
    {
      label: "Updated on",
      align: "left",
    },
    {
      label: "",
      align: "right",
      stickyClass: "stickyCell-right",
    },
  ];

  const SelectOptionHeader = [
    {
      label: "",
      align: "left",
      stickyClass: "stickyCell-left",
    },
  ];
  const formatDate = (date) => {
    let formatedDate = date?.split("T")[0]?.split("-").reverse().join("/");
    return formatedDate;
  };

  const isAthlete = () => {
    return activeTab === 2;
  };

  useEffect(() => {
    SetSelectedRow(null);
  }, [activeTab]);

  const handleSelected = (report) => {
    if (activeTab === 2) {
      SetSelectedRow({ atheleId: report.athlete_id });
    } else {
      SetSelectedRow({ groupId: report.group_id });
    }
    // update redirect url
    if (activeTab === 2) {
      dispatch(setSelectedAthleteGuid({ guid: report.athlete_guid }));
      setRedirectUrl(`/${NUTRITION_MODULE_ROUTE}/athlete/${report.athlete_id}/${activeTab}`)
    } else {
      if (report?.nutrition_id) setRedirectUrl(`/nutrition/${sportId}/${activeTab}/${report?.nutrition_id}`);
      else setRedirectUrl(`/${NUTRITION_MODULE_ROUTE}/${report.group_id}/${activeTab}/new`);
    }
  };

  const getTableBody = () => {
    return (
      <TableBody sx={{ backgroundColor: "#181818" }}>
        {reportList?.length ? (
          reportList?.map((report, idx) => {
            return (
              <TableRow
                key={uuidv4()}
                selected={
                  SELECT_LOG
                    ? activeTab === 2
                      ? selectedRow?.atheleId === report?.athlete_id
                        ? true
                        : false
                      : selectedRow?.groupId === report?.group_id
                      ? true
                      : false
                    : false
                }
              >
                {SELECT_LOG ? (
                  <TableCell
                    align={"left"}
                    className="stickyCell-left"
                    padding="checkbox"
                    sx={{ cursor: "pointer"}}
                    // onClick={(e) => handleEdit(e, program.program_id)}
                  >
                    <Radio
                      color="warning"
                      checked={
                        activeTab === 2
                          ? selectedRow?.atheleId === report?.athlete_id
                            ? true
                            : false
                          : selectedRow?.groupId === report?.group_id
                          ? true
                          : false
                      }
                      onClick={(e) => handleSelected(report)}
                    />
                  </TableCell>
                ) : null}
                <TableCell
                  align={"left"}
                  sx={{ cursor: "pointer", minWidth: 150 }}
                  onClick={(e) => {
                    if(SELECT_LOG){
                      handleSelected(report);
                    } else {
                      if (activeTab === 2) {
                        dispatch(setSelectedAthleteGuid({ guid: report.athlete_guid }));
                        navigate(`/${NUTRITION_MODULE_ROUTE}/athlete/${report.athlete_id}/${activeTab}`);
                      } else {
                        if (report?.nutrition_id) navigate(`${activeTab}/${report?.nutrition_id}`);
                        else navigate(`/${NUTRITION_MODULE_ROUTE}/${report.group_id}/${activeTab}/new`);
                      }
                    }
                  }}
                >
                  {isAthlete() ? report?.athlete_name : report?.group_name}
                </TableCell>
                <TableCell align={isAthlete() ? "left" : "center"}>
                  {isAthlete() ? (
                    report?.group_name?.length ? (
                      //   report?.group_name
                      report?.group_name?.length > 2 ? (
                        <>
                          {`${report?.group_name[0]}, ${report?.group_name[1]}`}
                          <span className="si-custom-table si-count"> +{report?.group_name?.length - 2}</span>
                        </>
                      ) : (
                        report?.group_name
                          ?.map((name, index) => (index + 1 === report.group_name.length ? name : name + ","))
                          .join(" ")
                      )
                    ) : (
                      "-"
                    )
                  ) : report?.athlete_count ? (
                    report?.athlete_count
                  ) : (
                    "-"
                  )}
                </TableCell>

                <TableCell align={"left"}>{report?.log_updated_by ? formatDate(report.log_updated_by) : "-"}</TableCell>
                <TableCell align={"left"}>
                  {report?.log_updated_date ? formatDate(report?.log_updated_date) : "-"}
                </TableCell>
                {SELECT_LOG ? null : (
                  <TableCell align={"right"} className="stickyCell-right">
                    <button
                      type="button"
                      className="si-viewBtn"
                      onClick={(e) => {
                        if (activeTab === 2) {
                          dispatch(setSelectedAthleteGuid({ guid: report.athlete_guid }));
                          navigate(`/${NUTRITION_MODULE_ROUTE}/athlete/${report.athlete_id}/${activeTab}`, {
                            state: { athlete_guid: report.athlete_guid },
                          });
                        } else {
                          if (report?.nutrition_id) navigate(`${activeTab}/${report?.nutrition_id}`);
                          else navigate(`/${NUTRITION_MODULE_ROUTE}/${report.group_id}/${activeTab}/new`);
                        }
                      }}
                    >
                      <span className="si-icon__view"></span>
                      <span className="si-txt">View details</span>
                    </button>
                  </TableCell>
                )}
              </TableRow>
            );
          })
        ) : (
          <TableRow className="nodata-tableRow">
            <TableCell align={"center"} colSpan={5}>
              No data found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };
  useEffect(() => {
    getTableBody();
  }, [reportList]);

  return (
    <>
      <ListingTable
        tableHeaders={
          activeTab === 1
            ? SELECT_LOG
              ? [...SelectOptionHeader, ...coachHeaders.slice(0, -1)]
              : [...coachHeaders]
            : SELECT_LOG
            ? [...SelectOptionHeader, ...athleteHeaders.slice(0, -1)]
            : [...athleteHeaders]
        }
        getTableBody={getTableBody}
        pageNo={pageNo}
        setPageNo={setPageNo}
        pagination={pagination}
        perPage={20}
      />
    </>
  );
}

export default NutritionReportLogListingContent;
