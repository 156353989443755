import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { resetTournamentErrorMsg } from "../../../../reducer/tournaments.reducer";
import { updateTournament } from "../../../../utility/tournamentsApiActions";
import DatePickerComponent from "../../Common/DatePickerComponent";
import Dropdown from "../../Common/Dropdown";
import MultiSelectDropdown from "../../Common/MultiSelectDropdown";

function TournamentListingAddNew() {
  const navigate = useNavigate();
  let [tournamentObj, setTournament] = useState();
  let [changeHandle, setChangeHandle] = useState({});
  let [showErrors, setShowErrors] = useState({
    tournament_name: { show: false, msg: "" },
    tournament_level: { show: false, msg: "" },
    sports: { show: false, msg: "" },
    start_date: { show: false, msg: "" },
    end_date: { show: false, msg: "" },
    location: { show: false, msg: "" },
  });
  let [preSelectedSports, setPreSelectedSports] = useState([]);
  const sportList = useSelector((state) => state?.templates?.sportList);
  const userData = useSelector((state) => state?.auth?.userData);
  const updateSuccessfull = useSelector((state) => state?.tournaments?.updateTournamentSuccessful);
  const updateLoading = useSelector((state) => state?.tournaments?.updateTournamentLoading);
  const tournamentLevelMaster = useSelector((state) => state?.tournaments?.tournamentLevelMaster);
  const tournamentListing = useSelector((state) => state?.tournaments?.tournamentList);
  const tournamentErrorMsg = useSelector((state) => state?.tournaments?.tournamentErrorMsg);
  let dispatch = useDispatch();
  let { id } = useParams();
  useEffect(() => {
    if (tournamentErrorMsg) {
      let msgString = tournamentErrorMsg?.split(":")[1];
      setShowErrors((old) => ({
        ...old,
        tournament_name: { show: true, msg: msgString },
      }));
      dispatch(resetTournamentErrorMsg());
    }
  }, [dispatch, tournamentErrorMsg]);

  useEffect(() => {
    if (tournamentListing?.length && id) {
      let tournamentObj = tournamentListing.filter((tournament) => Number(tournament.tournament_id) === Number(id))[0];
      let sportsObj = {};
      sportList?.forEach((obj) => {
        sportsObj = { ...sportsObj, [obj.sport_id]: obj };
      });
      let selectedSports = tournamentObj?.sport_id?.map((ids) => ({ value: ids, label: sportsObj[ids].sport_name }));
      tournamentObj = { ...tournamentObj, sport_id: selectedSports };
      setTournament(tournamentObj);
      setPreSelectedSports(selectedSports);
    }
  }, [tournamentListing, id, sportList]);
  const handleChange = ({ event, key }) => {
    if (key === "start_date") {
      let selected_date = moment(event).format("YYYY-MM-DD");
      setChangeHandle((preState) => ({
        ...preState,
        [key]: selected_date,
        end_date:
          preState?.start_date && preState?.end_date
            ? moment(preState?.end_date)
                .add(moment(selected_date).diff(moment(preState?.start_date).format("YYYY-MM-DD"), "days"), "days")
                .format("YYYY-MM-DD")
            : tournamentObj?.start_date && tournamentObj?.end_date
            ? moment(tournamentObj?.end_date)
                .add(moment(selected_date).diff(moment(tournamentObj?.start_date).format("YYYY-MM-DD"), "days"), "days")
                .format("YYYY-MM-DD")
            : "",
      }));
      setShowErrors((old) => ({
        ...old,
        [key]: {
          show: false,
          msg: "",
        },
      }));
    } else if (key === "end_date") {
      let selected_date = moment(event).format("YYYY-MM-DD");
      setChangeHandle((preState) => ({
        ...preState,
        [key]: selected_date,
      }));
      setShowErrors((old) => ({
        ...old,
        [key]: {
          show: false,
          msg: "",
        },
      }));
    } else if (key === "tournament_level") {
      setChangeHandle((preState) => ({
        ...preState,
        level_id: event.value,
        level_val: event.label,
      }));
      setShowErrors((old) => ({
        ...old,
        [key]: {
          show: false,
          msg: "",
        },
      }));
    } else if (key === "tournament_name") {
      if(event.target?.value?.length >= 75){
        setShowErrors((old) => ({
          ...old,
          tournament_name: { show: true, msg: "Tournament name should be of 75 characters or less" },
        }));

      } else {
        setChangeHandle((preState) => ({
          ...preState,
          [key]: event.target.value?.trim(),
        }));
        setShowErrors((old) => ({
          ...old,
          [key]: {
            show: false,
            msg: "",
          },
        }));
      }
    }
     else {
      setChangeHandle((preState) => ({
        ...preState,
        [key]: event.target.value?.trim(),
      }));
      setShowErrors((old) => ({
        ...old,
        [key]: {
          show: false,
          msg: "",
        },
      }));
    }
  };

  const handleSportsChange = (event) => {
    setPreSelectedSports([]);
    setChangeHandle((preState) => ({
      ...preState,
      sport_id: event,
    }));
    setShowErrors((old) => ({
      ...old,
      sports: {
        show: false,
        msg: "",
      },
    }));
  };

  const handleSaveTournament = ({ tournamentObj, id }) => {
    if (!tournamentObj?.tournament_name?.trim())
      return setShowErrors((old) => ({
        ...old,
        tournament_name: { show: true, msg: "Tournament name is required." },
      }));
    if (!tournamentObj?.sport_id || !tournamentObj.sport_id?.length)
      return setShowErrors((old) => ({ ...old, sports: { show: true, msg: "Sport is required" } }));
    if (!tournamentObj?.level_id)
      return setShowErrors((old) => ({
        ...old,
        tournament_level: { show: true, msg: "Tournament level is required" },
      }));
    if (!tournamentObj?.start_date)
      return setShowErrors((old) => ({ ...old, start_date: { show: true, msg: "Please enter a valid Date" } }));
    if (!tournamentObj?.end_date)
      return setShowErrors((old) => ({ ...old, end_date: { show: true, msg: "Please enter a valid Date" } }));
    if (!tournamentObj?.location || !tournamentObj?.location?.trim())
      return setShowErrors((old) => ({ ...old, location: { show: true, msg: "Location is required" } }));
    let payload = {
      id: id ? id : null,
      name: tournamentObj.tournament_name?.trim(),
      sport_id: tournamentObj?.sport_id?.map((sport) => sport.value),
      user_guid: userData?.user_guid ? userData?.user_guid : null,
      location: tournamentObj.location?.trim(),
      startDate: tournamentObj.start_date,
      endDate: tournamentObj.end_date,
      level_id: tournamentObj.level_id,
    };
    dispatch(updateTournament({ ...payload }));
  };
  useEffect(() => {
    if (changeHandle && Object.keys(changeHandle).length) {
      setTournament((old) => ({ ...old, ...changeHandle }));
    }
  }, [changeHandle]);

  useEffect(() => {
    if (updateSuccessfull) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccessfull]);
  return (
    <div className="si-popup active ">
      {updateLoading && <div className="spin"></div>}
      <div className="tournament-popup">
        <div className="tournament-popup__header">
          <span className="header-title">{id ? "Tournament Details" : "New Tournament"} </span>
          <button type="button" className="si-icon__button si-button__close" onClick={() => navigate(-1)}>
            <span className="icon-close"></span>
          </button>
        </div>
        <div className="tournament-popup__body">
          <div className="si-form">
            <div className={showErrors.tournament_name.show ? "form-control si-error" : "form-control"}>
              <div className="form-group">
                <label htmlFor="name" className="si-label">
                  <span>*</span>Tournament Name
                </label>
                <input
                  defaultValue={tournamentObj?.tournament_name}
                  maxLength="75"
                  onChange={(event) => handleChange({ event, key: "tournament_name" })}
                  className="si-input"
                  type="text"
                  placeholder="Tournament Name"
                />
              </div>
              {showErrors.tournament_name.show ? (
                <span className="si-error-message">
                  {showErrors.tournament_name.msg ? showErrors.tournament_name.msg : "Tournament name is required."}
                </span>
              ) : null}
            </div>
            <div className="form-control-outer-wrap">
              <div
                className={
                  showErrors.sports.show
                    ? "form-control si-error selectSport-form-control"
                    : "form-control selectSport-form-control"
                }
              >
                <div className="form-group">
                  <div className="custom-label si-sports">
                    <label htmlFor="sports" className="si-label">
                      *Sports
                    </label>
                  </div>
                  {/* <div className='form-control si-error'> */}
                  <MultiSelectDropdown
                    className="si-sports"
                    placeholder={"Select Sports"}
                    options={sportList?.map((obj) => {
                      return {
                        value: obj?.sport_id,
                        label: obj?.sport_name,
                      };
                    })}
                    changeHandler={handleSportsChange}
                    alreadySelectedValues={preSelectedSports}
                    dropdownfor="sports"
                  />
                  {/* </div> */}
                </div>
                {showErrors.sports.show ? <span className="si-error-message">Sport is required</span> : null}
              </div>
              {/* Tournament Level start*/}
              <div
                className={
                  showErrors.tournament_level.show
                    ? "form-control si-error selectSport-form-control"
                    : "form-control selectSport-form-control"
                }
              >
                <div className="form-group">
                  <div className="custom-label si-sports">
                    <label htmlFor="sports" className="si-label">
                      *Tournament Level
                    </label>
                  </div>
                  <Dropdown
                    value={{
                      label: tournamentObj?.level_val ? tournamentObj?.level_val : "Select Level",
                      value: tournamentObj?.level_id ? tournamentObj?.level_id : "",
                    }}
                    options={tournamentLevelMaster?.map((obj) => {
                      return {
                        value: obj?.level_id,
                        label: obj?.level_val,
                      };
                    })}
                    changeHandler={(data) => {
                      handleChange({ event: { value: data.value, label: data.label }, key: "tournament_level" });
                    }}
                  />
                </div>
                {showErrors.tournament_level.show ? (
                  <span className="si-error-message">tournament Level is required</span>
                ) : null}
              </div>
              <div className="form-control-inner-wrap">
                <div className={showErrors.start_date.show ? "form-control si-error" : "form-control"}>
                  <div className="form-group">
                    <label htmlFor="start-date" className="si-label">
                      <span>*</span>Start Date
                    </label>
                    <div className="form-group-wrap si-location date-form-control">
                      {/* <input
                        type="date"
                        placeholder="Add Location"
                        id="start-date"
                        className="si-input"
                        defaultValue={tournamentObj?.start_date?.split("T")[0]}
                        max={tournamentObj?.end_date ? tournamentObj?.end_date : null}
                        onChange={(event) => handleChange({ event, key: "start_date" })}
                      /> */}
                      <DatePickerComponent
                        isEditMode={false}
                        selectedDate={tournamentObj?.start_date ? tournamentObj?.start_date?.split("T")[0] : ""}
                        dateChangeHandler={(event) => {
                          handleChange({ event, key: "start_date" });
                        }}
                        // maxDate={
                        //   tournamentObj?.end_date && tournamentObj?.end_date?.split("T")[0]
                        //     ? new Date(tournamentObj?.end_date?.split("T")[0])
                        //     : null
                        // }
                      />
                    </div>
                  </div>
                  {showErrors.start_date.show ? (
                    <span className="si-error-message">Please enter a valid Date</span>
                  ) : null}
                </div>
                <div
                  className={
                    showErrors.end_date.show
                      ? "form-control si-error date-form-control"
                      : "form-control date-form-control"
                  }
                >
                  <div className="form-group">
                    <label htmlFor="end-date" className="si-label">
                      <span>*</span>End Date
                    </label>
                    <div className="form-group-wrap si-location">
                      {/* <input
                        type="date"
                        placeholder="Add Location"
                        id="end-date"
                        min={tournamentObj?.start_date ? tournamentObj?.start_date : null}
                        className="si-input"
                        defaultValue={tournamentObj?.end_date?.split("T")[0]}
                        onChange={(event) => handleChange({ event, key: "end_date" })}
                      /> */}
                      <DatePickerComponent
                        isEditMode={false}
                        selectedDate={tournamentObj?.end_date ? tournamentObj?.end_date?.split("T")[0] : ""}
                        dateChangeHandler={(event) => {
                          handleChange({ event, key: "end_date" });
                        }}
                        minDate={
                          tournamentObj?.start_date && tournamentObj?.start_date?.split("T")[0]
                            ? new Date(tournamentObj?.start_date?.split("T")[0])
                            : null
                        }
                      />
                    </div>
                  </div>
                  {showErrors.end_date.show ? (
                    <span className="si-error-message">Please enter a valid Date</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className={
                showErrors.location.show
                  ? "form-control si-error location-form-control"
                  : "form-control location-form-control"
              }
            >
              <div className="form-group">
                <label htmlFor="location" className="si-label">
                  <span>*</span>Location
                </label>
                <div className="form-group-wrap si-location">
                  <input
                    type="text"
                    placeholder="Add Location"
                    id="location"
                    maxLength="50"
                    className="si-input"
                    defaultValue={tournamentObj?.location}
                    onChange={(event) => handleChange({ event, key: "location" })}
                  />
                </div>
              </div>
              {showErrors.location.show ? <span className="si-error-message">Location is required</span> : null}
            </div>

            <div className="form-control popup-btn-wrp">
              <button
                type="button"
                className="si-button si-button--green si-icon--add"
                onClick={() => handleSaveTournament({ tournamentObj, id })}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TournamentListingAddNew;
