import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loaderActions } from '../../../../reducer/loader';
import Content from './AthleteContent';
import SubHeader from '../../Common/SubHeader';
import Footer from '../../Common/Footer';
import FixedButton from '../../Common/FixedButton';
import AddAthletePopup from '../../TechnicalTraining/Common/AddAthlete/AddAthletePopup';
import { toast } from 'react-toastify';
import { fetchGroupListing,fetchTechnicalProgramAthletesList, updateTechnicalProgramDetails } from '../../../../utility/technicalProgramApiActions';
import { fetchSportsList } from '../../../../utility/technicalTemplateApiActions';

function AthleteView({isPerformance, setIsUpdateDisabled}) {
    const {id} = useParams();
    const [pageNo, setPageNo] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [selectedSports, setSelectedSports] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isFetchFulfilled, setIsFetchFulfilled] = useState(false);
    const programAthleteListing = useSelector(state => state?.technicalPrograms?.programAthleteListing);
    const paginationObject = useSelector(state => state?.technicalPrograms?.paginationObject);
    const userDetails = useSelector(state => state?.auth?.userData);
    const selectedProgram = useSelector(state => state?.technicalPrograms?.selectedProgram);
    let filterOptions = {
        is_search_filter: true,
        is_group_filter: true,
        is_sport_filter: true
    };
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const location = useLocation()

    useEffect(() => {
        let athleteListTimeOut = setTimeout(() => {
            if(searchTerm!==null) dispatch(loaderActions?.showLoader());
            dispatch(
                fetchTechnicalProgramAthletesList({
                    pageNo,
                    searchTerm,
                    userguid:null,
                    programId: id,
                    selectedSports,
                    groupFilter: selectedGroups,
                    callback: () => setTimeout(() =>{
                        dispatch(loaderActions?.hideLoader());
                        setIsFetchFulfilled(true);
                    })
                })
            );
        }, 1000);

        if(pageNo!==1 || searchTerm || selectedGroups?.length || selectedSports?.length) setIsFiltered(true);

        return () => clearTimeout(athleteListTimeOut);
    }, [searchTerm]);

    useEffect(() => {
        let athleteListTimeOut = setTimeout(() => {
            dispatch(
                fetchTechnicalProgramAthletesList({
                    pageNo,
                    searchTerm,
                    userguid:null,
                    programId: id,
                    selectedSports,
                    groupFilter: selectedGroups,
                    callback: () => setTimeout(() => {
                        setIsFetchFulfilled(true);
                    })
                })
            );
        }, 0);

        if(pageNo!==1 || selectedGroups?.length || selectedSports?.length) setIsFiltered(true);

        return () => clearTimeout(athleteListTimeOut);
    }, [pageNo, selectedGroups, selectedSports]);

    useEffect(() => {
        dispatch(fetchSportsList());
        dispatch(fetchGroupListing());
    }, []);

    const createBtnClickHandler = (id)=>{
        setIsActive(true);
    }
   // console.log(programAthleteListing, programAthleteListing?.length, isFiltered, programAthleteListing?.length || isFiltered)

    const addAthleteHandler = (athleteIds)=>{
        console.log("add athlete handler",athleteIds);
        dispatch(updateTechnicalProgramDetails({
            programId:id,
            name: selectedProgram?.program_name,
            duration:selectedProgram?.program_duration,
            sportId: selectedProgram?.sport_id,
            startDate:selectedProgram?.start_date,
            endDate:selectedProgram?.end_date,
            assignedAthletes:athleteIds,
            isPublish: selectedProgram?.is_publish,
            callback: (res) => setTimeout(() => {
                dispatch(fetchTechnicalProgramAthletesList({
                    pageNo,
                    searchTerm,
                    userguid:null,
                    programId: id,
                    groupFilter: selectedGroups,
                    callback: (data) => setTimeout(() => {
                        console.log("resp---->",data);
                    })
                }));
                if(res) toast.success("Athletes added to program successfully.", {toastId:"athlete-added-success"});
                else toast.warn("Something went wrong.", {toastId:"athlete-added-warning"});
                setIsActive(false);
                dispatch(setIsUpdateDisabled(false));
            })
          }));
    }

    return (
        <Fragment>
            {
                // programAthleteListing?.filter(obj => obj?.is_selected)?.length || isFiltered
                programAthleteListing?.length || isFiltered
                ?
                <>
                    <SubHeader 
                        filterOptions={filterOptions}
                        searchTerm={ searchTerm }
                        setSearchTerm={ setSearchTerm }
                        selectedSports={ selectedSports }
                        setSelectedSportIds={ setSelectedSports }
                        selectedGroupsIds={ selectedGroups }
                        setSelectedGroupIds={ setSelectedGroups }
                        pageNo={ pageNo }
                        setPageNo={ setPageNo }
                        navigate={ navigate }
                        isBtn={location.pathname.split("/")[2] === "completedprograms" ? false : true}
                        btnName="Add Athletes"
                        onBtnClick={createBtnClickHandler}
                    />
                    <Content 
                        pageNo={ pageNo }
                        searchTerm={ searchTerm }
                        selectedGroups={ selectedGroups }
                        setIsFiltered={ setIsFiltered }
                        isPerformance= { isPerformance }
                    />
                    <Footer 
                        pageNo={pageNo} 
                        setPageNo={setPageNo} 
                        pagination={paginationObject}
                        perPage={20}
                    />
                    {location.pathname.split("/")[2] === "completedprograms" ? 
                        null : 
                        <FixedButton btnName="Add Athletes"
                            onBtnClick={createBtnClickHandler} iconClass='add'/>
                    }
                </>
                :
                isFetchFulfilled && 
                    <div className="si-firstStep si-align-center">
                        <div className="si-text">
                            <span>Add Athletes to a program</span>
                        </div>
                        <button 
                            type="button" 
                            className="si-button si-button--green si-icon--add"
                            onClick={() => setIsActive(true)}
                        >
                            <span className="icon-add"></span>Add Athletes
                        </button>
                    </div>
            }
            {
                isActive && 
                <AddAthletePopup 
                    isActive={isActive}
                    setIsActive={setIsActive}
                    actionBtnHandler={addAthleteHandler}
                    setIsFiltered={setIsFiltered}
                    alreadyAddedAthletes={programAthleteListing}
                />
            }
        </Fragment>
    )
};

export default AthleteView;